import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-exameconclusao',
  templateUrl: './exameconclusao.component.html',
  styleUrls: ['./exameconclusao.component.scss'],
})
export class ExameconclusaoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;

  exameconclusaoForm: any
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0
  Exameconclusao:number= -1;
  Botao:string="Cadastrar"
  conclusaoColection:any=[];
  collectionExameTipo:any=[];
  collectionExameRelacaoTipo:any=[];
  Exametipo:number=0;
  Examerelacao:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService:ChecklistService
) {
    this.exameconclusaoForm = formBuilder.group({
      Exametipo: ['', ''],
      Examerelacao: ['', ''],
      Exameconclusao: ['', ''],
      Nome: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() { 

    this.Exametipo = this.navParams.get('Exametipo');
    this.Examerelacao = this.navParams.get('Examerelacao');


  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.selecionarExameTipo();
    this.limpaDados();

  }

  async selecionarExameTipo() {
    this.exameService.selecionarExameTipo().then((response:any)=>{
      this.collectionExameTipo= response;
    })
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

   async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
   
  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.exameconclusaoForm.controls['Datalancamento'].setValue(Datalancamento);

  
  }

  async selecionarExameRelacaoTipo(exametipo:number) {
    this.Exametipo = exametipo;
     this.exameService.selecionarExameRelacaoTipo(exametipo).then((response:any)=>{
       this.collectionExameRelacaoTipo= response;
     })

  }

  async testaDados() {

    if (this.exameconclusaoForm.value.Examerelacao == null || this.exameconclusaoForm.value.Examerelacao == undefined || this.exameconclusaoForm.value.Examerelacao == '') {
      this.utilService.showAlertCallback("Informe o EXAME para continuar" , ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }
    
    if (this.exameconclusaoForm.value.Nome == null || this.exameconclusaoForm.value.Nome == undefined || this.exameconclusaoForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DA CONCLUÃO para continuar", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    let form = {
      Id: this.exameconclusaoForm.value.Exameconclusao,
      Examerelacao: this.exameconclusaoForm.value.Examerelacao,
      Nome: this.exameconclusaoForm.value.Nome,
      Usuario: this.Usuario
    }


    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

 

  async presentAlertConfirm(form: any) {

    let tipo = this.exameconclusaoForm.value.Exameconclusao;

    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "

    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'CONCLUSÃO',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


   async cadastrar(form: any) {

    if (this.exameconclusaoForm.value.Exameconclusao ==  -1) {
      this.exameService.adicionarExameConclusao(form).then((response) => {
        this.utilService.showAlertCallback("Conclusao ADICIONADO com sucesso", null);
        this.selecionarExameConclusaoRelacao(this.exameconclusaoForm.value.Examerelacao);
        setTimeout(() => {
          this.Exameconclusao = response[0].Id;
          this.exameconclusaoForm.controls['Exameconclusao'].setValue(this.Exameconclusao);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR a conclusão", null);

      });
    } else {
      this.exameService.atualizarExameConclusao(form).then((response:any) => {
        this.utilService.showAlertCallback("Conclusão ATUALIZADO com sucesso", null);
         this.selecionarExameConclusaoRelacao(this.exameconclusaoForm.value.Examerelacao);
        setTimeout(() => {
          this.Exameconclusao = response[0].Id;
          this.exameconclusaoForm.controls['Exameconclusao'].setValue(this.Exameconclusao);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR a conclusão", null);

      });
    }

  }

  async selecionarExameConclusaoRelacao(id:number) {
    this.exameService.selecionarExameConclusaoRelacao(id, this.Usuario).then((response:any) => {
     this.conclusaoColection= response;
    }).catch((response) => {

    });

  }
  async selecionarExameConclusaoUnico(Id:number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.exameService.selecionarExameConclusaoUnico(Id).then((response:any) => {
      this.getexameconclusao(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar a CONCLUSÃO")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.Exameconclusao = -1;
    this.exameconclusaoForm.controls['Exameconclusao'].setValue(-1);
    this.exameconclusaoForm.controls['Nome'].setValue('');
    this.utilService.focusInput(this.ancoraNome);
    setTimeout(()=>{
      this.exameconclusaoForm.controls['Exametipo'].setValue(this.Exametipo);
      setTimeout(()=>{
        this.exameconclusaoForm.controls['Examerelacao'].setValue(this.Examerelacao);
  
      }, 250)
    }, 250)




  }

  async getexameconclusao(response: any) {
    if (response.length == 0) {
      return;
    }

      this.Botao = "Alterar";
      this.exameconclusaoForm.controls['Nome'].setValue(response[0].Nome);
     // this.exameconclusaoForm.controls['examerelacao'].setValue(response[0].examerelacao);


  }


}
