import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-chadsvasc',
  templateUrl: './chadsvasc.component.html',
  styleUrls: ['./chadsvasc.component.scss'],
})

export class ChadsvascComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  Id: number = -1;
  lancamentosCollection: any = [];
  Cardiaco: number = 0;
  Hipertensao: number = 0;
  Idade: number = 0; //A2 IDADE
  Diabetes: number = 0;
 // A colocar junto com o A2
  Sexo:number=0;
  Aitavc:number=0;
  Vascular:number=0;
  Logado: number = 0;
  Usuario:number=0;
  Sigla: string = "";
  chadsvascForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number=0;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Dias: string = "";
  itensColection: any = [];
  NomePaciente: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Altura: any;
  Peso: any;
  Bsa: any;
  Sistema:number=0;
  Comentario:string="";
  Calculo:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController:LoadingController,
  ) {
    this.chadsvascForm= formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Idade: ['', ''],
      Cardiaco: ['', ''],
      Hipertensao: ['', ''],
      Diabetes: ['', ''],
      Aitavc: ['', ''],
      Vascular: ['', ''],
      Sexo: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Sistema = this.navParams.get('Sistema');

    if (this.Sexo == 1) { // pontuação para CHADSVASC
      this.Sexo = 0;
     } else if (this.Sexo == 2) {
        this.Sexo = 1;
     }
  }
  ngOnInit() {
    this.Calculo =14;
  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
    


  }
  
  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(45)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showToastCallBack('Não foi possível seleciona os dados da calculadora', 2000, null)
    }).finally(() => {
      loading.dismiss();
    });
   
  }

  


  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }
      this.limpaDados();
    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;
     


    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível acessar os lançamento", 2000, null)
    });
  }





  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularChadsvasc() {
    

    let Cardiaco: number = this.chadsvascForm.value.Cardiaco
;
    if (Cardiaco == null || Cardiaco  == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CARDÍACO", null)
      return;

    }

    this.Cardiaco  = Cardiaco;

    let Hipertensao: number = this.chadsvascForm.value.Hipertensao;
    if (Hipertensao == null || Hipertensao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de HIPERTENSÃO", null)
      return;
    }

    this.Hipertensao = Hipertensao;

    let Idade: number = this.chadsvascForm.value.Idade;
    if (Idade == null || Idade == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de IDADE II", null)
      return;

    }

    this.Idade = Idade;

    let Diabetes: number = this.chadsvascForm.value.Diabetes;
    if (Diabetes == null || Diabetes == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de DIABETES", null)
      return;

    }

    this.Diabetes = Diabetes;

    let Aitavc: number = this.chadsvascForm.value.Aitavc;
    if (Aitavc == null || Aitavc == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de AIT ou AVC", null)
      return;

    }
    this.Aitavc = Aitavc;

    let Vascular: number = this.chadsvascForm.value.Vascular;
    if (Vascular == null || Vascular == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem VASCULAR", null)
      return;

    }
    this.Vascular = Vascular;

    let Sexo: number = this.chadsvascForm.value.Sexo;
    if (Sexo == null || Sexo == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de INTERLEUCINA", null)
      return;

    }

    this.Sexo = Sexo;

    this.Valor =  Cardiaco  + Hipertensao + Idade + Diabetes + Aitavc + + Vascular + Sexo;
    //console.log("Cardiaco " + Cardiaco)
    //console.log("Hipertensao " + Hipertensao)
    //console.log("Hipertensao " + Hipertensao)
    //console.log("Idade " + Idade)
    //console.log("Diabetes " + Diabetes)
    //console.log("Aitavc " + Aitavc)
    //console.log("Vascular " + Vascular)
    //console.log("Sexo " + Sexo)


    let Range:string= ""
    if (this.Valor == 0) {
      Range= " (BAIXO RISCO - antiagregação com AAS ou observar)"
    }else if (this.Valor == 1) {
      Range= " (INTERMEDIÁRIO RISCO - anticogulação plena ou antiagregação 75 a 325 mg AAS)"
    }else if (this.Valor >= 2) {
      Range= " (ALTO RISCO - anticoagulação plena para RNI entre 2 e 3)"
    }
    this.Valor = this.utilService.formateNumberNew(this.Valor, 0);
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    if (this.Valor == null || this.Valor == undefined) {
      this.Valor = 0;
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Internacao > 0 &&  this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.chadsvascForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];
  
    Relacao = Relacao + this.Cardiaco + '*';
    Relacao = Relacao + this.Hipertensao + '*';
    Relacao = Relacao + this.Idade + '*';
    Relacao = Relacao + this.Diabetes + '*'; 
    Relacao = Relacao + this.Aitavc + '*';
    Relacao = Relacao + this.Vascular + '*';
    Relacao = Relacao + this.Sexo;
    let Datalancamento:any = this.chadsvascForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.chadsvascForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.chadsvascForm.value.Comentario

    }


    if (this.chadsvascForm.value.Id == null || this.chadsvascForm.value.Id == undefined || this.chadsvascForm.value.Id == '-1' || this.chadsvascForm.value.Id == -1 || this.chadsvascForm.value.Id == '') {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " INCLUIDO com sucesso",  null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.chadsvascForm.controls['Id'].setValue(this.Id);
        }, 1000);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível INCLUIR o " + this.Nome,  null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ALTERADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);
      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async limpaDados() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
   
    this.Valor = 0;
    this.Id= -1;
    this.chadsvascForm.controls['Idade'].setValue(0);
    this.chadsvascForm.controls['Cardiaco'].setValue(0);
    this.chadsvascForm.controls['Hipertensao'].setValue(0);
    this.chadsvascForm.controls['Diabetes'].setValue(0);
    this.chadsvascForm.controls['Aitavc'].setValue(0);
    this.chadsvascForm.controls['Vascular'].setValue(0);
    this.chadsvascForm.controls['Sexo'].setValue(0);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }
    this.Datalancamento =Datalancamento
    this.chadsvascForm.controls['Datalancamento'].setValue(Datalancamento);


  }

  

  async mostrarDados(response: any) {
    if (response.length == 0){
      return;
    }
    
    this.Botao = "ALTERAR";
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Cardiaco = parseInt(Relacao[0]); //C
    this.Hipertensao = parseInt(Relacao[1]); //H
    this.Idade = parseInt(Relacao[2]); //A2
    this.Diabetes = parseInt(Relacao[3]); //D
    this.Aitavc = parseInt(Relacao[4]); //S2
    this.Vascular = parseInt(Relacao[5]); //V
    // A inserido no A2
    this.Sexo = parseInt(Relacao[6]); // Sc
    //1*0*1*0*1*0
    this.chadsvascForm.controls['Datalancamento'].setValue(response[0].Datalancamento);
    this.chadsvascForm.controls['Cardiaco'].setValue(this.Cardiaco);
    this.chadsvascForm.controls['Hipertensao'].setValue(this.Hipertensao);
    this.chadsvascForm.controls['Idade'].setValue(this.Idade);
    this.chadsvascForm.controls['Diabetes'].setValue(this.Diabetes);
    this.chadsvascForm.controls['Aitavc'].setValue(this.Aitavc);
    this.chadsvascForm.controls['Vascular'].setValue(this.Vascular);
    this.chadsvascForm.controls['Sexo'].setValue(this.Sexo);
    this.Comentario = response[0].Comentario;
    this.chadsvascForm.controls['Comentario'].setValue(this.Comentario);
  }

}

