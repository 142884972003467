import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-imunologia',
  templateUrl: './imunologia.component.html',
  styleUrls: ['./imunologia.component.scss'],
})
export class ImunologiaComponent implements OnInit {
  @ViewChild('ancoraIdimunologia', null) ancoraIdimunologia: ElementRef;
  @ViewChild('ancoraDatalancamentoimunologia', null) ancoraDatalancamentoimunologia: ElementRef;
  @ViewChild('ancoraAntihbcigm', null) ancoraAntihbcigm: ElementRef;
  @ViewChild('ancoraHbeag', null) ancoraHbeag: ElementRef;
  @ViewChild('ancoraAntihbe', null) ancoraAntihbe: ElementRef;
  @ViewChild('ancoraAntihavigg', null) ancoraAntihavigg: ElementRef;
  @ViewChild('ancoraAntihavigm', null) ancoraAntihavigm: ElementRef;
  @ViewChild('ancoraAntihcv', null) ancorAntihcv: ElementRef;
  @ViewChild('ancoraLincd4', null) ancoraLincd4: ElementRef;
  @ViewChild('ancoraLincd8', null) ancoraLincd8: ElementRef;
  @ViewChild('ancoraFtaabs', null) ancoraFtaabs: ElementRef;
  @ViewChild('ancoraTpha', null) ancoraTpha: ElementRef;
  @ViewChild('ancoraAntihiv', null) ancoraAntihiv: ElementRef;
  @ViewChild('ancoraWestemblothiv', null) ancoraWestemblothiv: ElementRef;
  @ViewChild('ancoraPaulbunnell', null) ancoraPaulbunnell: ElementRef;
  @ViewChild('ancoraHgsag', null) ancoraHgsag: ElementRef;
  @ViewChild('ancoraFatorantinuclear', null) ancoraFatorantinuclear: ElementRef;
  @ViewChild('ancoraChagasifihma', null) ancoraChagasifihma: ElementRef;
  @ViewChild('ancoraAslo', null) ancoraAslo: ElementRef;
  @ViewChild('ancoraPrcultrassensivel', null) ancoraPrcultrassensivel: ElementRef;
  @ViewChild('ancoraFatorreumatoide', null) ancoraFatorreumatoide: ElementRef;
  @ViewChild('ancoraAlfa1glicoproteinaacida', null) ancoraAlfa1glicoproteinaacida: ElementRef;
  @ViewChild('ancoraC3', null) ancoraC3: ElementRef;
  @ViewChild('ancoraC4', null) ancoraC4: ElementRef;
  @ViewChild('Iggtotal', null) ancoraIggtotal: ElementRef;
  @ViewChild('ancoraIgmtotal', null) ancoraIgmtotal: ElementRef;
  @ViewChild('ancoraIgatotal', null) ancoraIgatotal: ElementRef;
	@ViewChild('ancoraIgetotal', null) ancoraIgetotal: ElementRef;
  @ViewChild('ancoraAntiperoxidase', null) ancoraAntiperoxidase: ElementRef;
  @ViewChild('ancoraAntitireoglobulina', null) ancoraAntitireoglobulina: ElementRef;
  @ViewChild('ancoraAntihbs', null) ancoraAntihbs: ElementRef;
  @ViewChild('ancoraAntihbctotal', null) ancoraAntihbctotal: ElementRef;
  @ViewChild('ancoraCitomegalovirusigg', null) ancoraCitomegalovirusigg: ElementRef;
  @ViewChild('ancoraCitomegalovirusigm', null) ancorCitomegalovirusigm: ElementRef;
  @ViewChild('ancoraVdrl', null) ancoraVdrl: ElementRef;
  @ViewChild('ancoraRubeolaigg', null) ancoraRubeolaigg: ElementRef;
  @ViewChild('ancoraRubeolaigm', null) ancorRubeolaigm: ElementRef;
  @ViewChild('ancoraToxoplasmoseigg', null) ancoraToxoplasmoseigg: ElementRef;
  @ViewChild('ancoraToxoplasmoseigm', null) ancoraToxoplasmoseigm: ElementRef;
  
  
  
  private IdGeral:number = 0;
  private Exames:any;
  private Datalancamentoimunologia:string="";
  private Usuario:number = 0;
  private Paciente: number = 0;
  private Idade:string = "";
  private Nome:string = "";
  private Internacao: number = 0;
  private Historiaclinica:string = "";
  private Setor:string = "";
  private Leito:any = "";
  public imunologiaForm: FormGroup;
  public Botaoimunologia:string = "Cadastrar";
  private Datainternacao:string = "";
  private Dias:string = "";
  private imunologiaCollection: any = [];
  public dadosListMed:any;
  private Logado:number=0;
  public Fatorantinuclear:number=0;
  public Aslo:number=0;
  public Tempocoagulacao:number=0;
  public Prcultrassensivel:number=0;
  public Fatorreumatoide:number=0;
  public Alfa1glicoproteinaacida:number=0;
  public C3:number=0;
  public C4:number=0;
  public Iggtotal:number=0;
  public Igmtotal:number=0;
  public igatotal:number=0;
  public Igetotal:number=0;
  public Antiperoxidase:number=0;
  public Antitireoglobulina:number=0;
  public Antihbctotal:number=0;
  public Hgsag:number=0;
  public Antihbs:number=0;
	public Antihbcigm:number=0;
	public Hbeag:number=0;
	public Antihbe:number=0;
	public Antihavigg:number=0;
  public Antihavigm:number=0;
	public Antihcv:number=0;
	public Lincd4:number=0;
  public Lincd8:number=0;
	public Citomegalovirusiga:number=0;
	public Citomegalovirusigm:number=0;
	public Vdrl:number=0;
	public Ftaabs:number=0;
	public Tpha:number=0;
	public Antihiv:number=0;
	public Westemblothiv:number=0;
	public Paulbunnell:number=0;
	public Rubeolaigg:number=0;
	public Rubeolaigm:number=0;
	public Toxoplasmoseigg:number=0;
	public Toxoplasmoseigm:number=0;
	public Chagasifihma:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService : LaboratorioService,
    private alertController:AlertController,
    ) {

      this.imunologiaForm = formBuilder.group({
        Idimunologia: ['', ''],
        Datalancamentoimunologia: ['', ''],
        Antihbcigm: ['', ''],
	      Hbeag: ['', ''],
	      Antihbe: ['', ''],
	      Antihavigg: ['', ''],
	      Antihavigm: ['', ''],
	      Antihcv: ['', ''],
	      Lincd4: ['', ''],
	      Lincd8: ['', ''],
	      Ftaabs: ['', ''],
	      Tpha: ['', ''],
	      Antihiv: ['', ''],
	      Westemblothiv: ['', ''],
	      Paulbunnell: ['', ''],
	      Hgsag: ['', ''],
	      Fatorantinuclear: ['', ''],
	      Chagasifihma: ['', ''],
	      Aslo: ['', ''],
	      Prcultrassensivel: ['', ''],
	      Fatorreumatoide: ['', ''],
	      Alfa1glicoproteinaacida: ['', ''],
	      C3: ['', ''],
	      C4: ['', ''],
	      Iggtotal: ['', ''],
	      Igmtotal: ['', ''],
	      Igatotal: ['', ''],
	      Igetotal: ['', ''],
	      Antiperoxidase: ['', ''],
	      Antitireoglobulina: ['', ''],
	      Antihbs: ['', ''],
	      Antihbctotal: ['', ''],
	      Citomegalovirusigg: ['', ''],
	      Citomegalovirusigm: ['', ''],
	      Vdrl: ['', ''],
	      Rubeolaigg: ['', ''],
	      Rubeolaigm: ['', ''],
	      Toxoplasmoseigg: ['', ''],
	      Toxoplasmoseigm: ['', ''],
        Usuario:['', ''],
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
     
    }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarImunologia(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao=  this.dadosListMed.Internacao.Id;
    this.Usuario =this.dadosListMed.Internacao[0].Usuario;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosImunologia();
  
  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = this.Exames[0].nome;
    for (let i = 0; i < this.Exames.length; i++) {
     let valor:number = eval('this.imunologiaForm.value.' + this.Exames[i].nome);
     if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax ) {
      x = x + 1
      if (x == 1) {
        cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
        parametro = this.Exames[i].nome;
      }
     // this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg , 2000, null)
      this.imunologiaForm.controls[this.Exames[i].nome].setValue(valor);
      cValores =  cValores + '-> '+ this.Exames[i].msg + ": " + valor +  ' (' + this.Exames[i].vmin + '-' +  this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
     }
  }

  
    let tipo = parseInt(this.imunologiaForm.value.Idimunologia);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'IMUNOLOGIA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroImunologia();
          }
        }
      ]
    });

    await alert.present();
  }

  cadastroImunologia() {
    let form = {
      Id: this.imunologiaForm.value.Idimunologia,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.imunologiaForm.value.Datalancamentoimunologia),
      Antihbctotal:this.Antihbctotal,
      Antihbcigm: this.Antihbcigm,
      Hbeag: this.Antihbcigm,
      Antihbe: this.Hbeag,
      Antihavigg: this.Antihavigg,
      Antihavigm: this.Antihavigm,
      Antihcv: this.Antihcv,
      Lincd4: this.Lincd4,
      Lincd8: this.Lincd8,
      Ftaabs: this.Ftaabs,
      Tpha: this.Tpha,
      Antihiv: this.Antihiv,
      Westemblothiv: this.Westemblothiv,
      Paulbunnell: this.Paulbunnell,
      Hgsag: this.Hgsag,
      Fatorantinuclear: this.Fatorantinuclear,
      Chagasifihma: this.Chagasifihma,
      Vdrl: this.Vdrl,
      Aslo:this.imunologiaForm.value.Aslo,
      Prcultrassensivel:this.imunologiaForm.value.Prcultrassensivel,
      Fatorreumatoide:this.imunologiaForm.value.Fatorreumatoide,
      Alfa1glicoproteinaacida:this.imunologiaForm.value.Alfa1glicoproteinaacida,
      C3:this.imunologiaForm.value.C3,
      C4:this.imunologiaForm.value.C4,
      Iggtotal:this.imunologiaForm.value.Iggtotal,
      Igmtotal:this.imunologiaForm.value.Igmtotal,
      Igatotal:this.imunologiaForm.value.Igatotal,
      Igetotal:this.imunologiaForm.value.Igetotal,
      Antiperoxidase:this.imunologiaForm.value.Antiperoxidase,
      Antitireoglobulina:this.imunologiaForm.value.Antitireoglobulina,
      Antihbs:this.imunologiaForm.value.Antihbs,
      Citomegalovirusigg:this.imunologiaForm.value.Citomegalovirusigg,
      Citomegalovirusigm:this.imunologiaForm.value.Citomegalovirusigm,
      Rubeolaigg:this.imunologiaForm.value.Rubeolaigg,
      Rubeolaigm:this.imunologiaForm.value.Rubeolaigm,
      Toxoplasmoseigg:this.imunologiaForm.value.Toxoplasmoseigg,
      Toxoplasmoseigm:this.imunologiaForm.value.Toxoplasmoseigm,
      Usuario: this.Usuario,
    }

    if (this.imunologiaForm.value.Idimunologia == 0 || this.imunologiaForm.value.Idimunologia == undefined || this.imunologiaForm.value.Idimunologia == null || this.imunologiaForm.value.Idimunologia == '') {
      this.laboratorioService.adicionarImunologia(form).then((response) => {
        this.selecionarImunologia(response[0].Id);
        this.utilService.showToastCallBack("Imunologia incluido com sucesso", 2000, null);
      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Imunologia", 2000, null);
      });
    } else {
      this.laboratorioService.atualizarImunologia(form).then((response) => {
        this.utilService.showToastCallBack("Imunologia atualizado com sucesso", 2000, null);
      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Imunologia", 2000, null);
      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresImunologia()
    .then((response) => {
      this.Exames = response;
    })
    .catch((response) => {
      this.Exames = [];
    });
  }
  async selecionarImunologiaUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosImunologia();
      return ;
    }
    this.laboratorioService.selecionarImunologiaUnico(Id).then((response) => {
      this.getBalancoImunologia(response);
    }).catch((response) => {

    });
  }

    async selecionaAntihbctotal() {
    if (this.imunologiaForm.value.Antihbctotal == true) {
      this.Antihbctotal = 1;
    } else {
      this.Antihbctotal = 0;
    }
  }

  async selecionaAntihbcigm() {
    if (this.imunologiaForm.value.Antihbcigm == true) {
      this.Antihbcigm = 1;
    } else {
      this.Antihbcigm = 0;
    }
  }

 

  async selecionaHbeag() {
    if (this.imunologiaForm.value.Hbeag == true) {
      this.Hbeag = 1;
    } else {
      this.Hbeag = 0;
    }
  }

  async selecionaAntihbe() {
    if (this.imunologiaForm.value.Antihbe == true) {
      this.Antihbe = 1;
    } else {
      this.Antihbe = 0;
    }

  }

  async selecionaAntihavigg() {
    if (this.imunologiaForm.value.Antihavigg == true) {
      this.Antihavigg = 1;
    } else {
      this.Antihavigg = 0;
    }

  }

  async selecionaAntihavigm() {
    if (this.imunologiaForm.value.Antihavigm == true) {
      this.Antihavigm = 1;
    } else {
      this.Antihavigm = 0;
    }

  }

  async selecionaAntihcv() {
    if (this.imunologiaForm.value.Antihcv == true) {
      this.Antihcv = 1;
    } else {
      this.Antihcv = 0;
    }

  }

  async selecionaLincd4() {
    if (this.imunologiaForm.value.Lincd4 == true) {
      this.Lincd4 = 1;
    } else {
      this.Lincd4 = 0;
    }

  }

  async selecionaLincd8() {
    if (this.imunologiaForm.value.Lincd8 == true) {
      this.Lincd8 = 1;
    } else {
      this.Lincd8 = 0;
    }

  }

  async selecionaFtaabs() {
    if (this.imunologiaForm.value.Ftaabs == true) {
      this.Ftaabs = 1;
    } else {
      this.Ftaabs = 0;
    }

  }

  async selecionaTpha() {
    if (this.imunologiaForm.value.Tpha == true) {
      this.Tpha = 1;
    } else {
      this.Tpha = 0;
    }

  }

  async selecionaAntihiv() {
    if (this.imunologiaForm.value.Antihiv == true) {
      this.Antihiv = 1;
    } else {
      this.Antihiv = 0;
    }

  }

  async selecionaWestemblothiv() {
    if (this.imunologiaForm.value.Westemblothiv == true) {
      this.Westemblothiv = 1;
    } else {
      this.Westemblothiv = 0;
    }

  }

  async selecionaPaulbunnell() {
    if (this.imunologiaForm.value.Paulbunnell == true) {
      this.Paulbunnell = 1;
    } else {
      this.Paulbunnell = 0;
    }

  }

  async selecionaHgsag() {
    if (this.imunologiaForm.value.Hgsag == true) {
      this.Hgsag = 1;
    } else {
      this.Hgsag = 0;
    }

  }

  async selecionaFatorantinuclear() {
    if (this.imunologiaForm.value.Fatorantinuclear == true) {
      this.Fatorantinuclear = 1;
    } else {
      this.Fatorantinuclear = 0;
    }

  }

  async selecionaChagasifihma() {
    if (this.imunologiaForm.value.Chagasifihma == true) {
      this.Chagasifihma = 1;
    } else {
      this.Chagasifihma = 0;
    }

  }

  async selecionaVdrl() {
    if (this.imunologiaForm.value.Vdrl == true) {
      this.Vdrl = 1;
    } else {
      this.Vdrl = 0;
    }

  }

  async limpaDadosImunologia() {
    this.Botaoimunologia = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentoimunologia = dtHoje;
    this.imunologiaForm.controls['Datalancamentoimunologia'].setValue(dtHoje);
    this.imunologiaForm.controls['Antihbctotal'].setValue(false);
    this.imunologiaForm.controls['Antihbcigm'].setValue(false);
    this.imunologiaForm.controls['Hbeag'].setValue(false);
    this.imunologiaForm.controls['Antihbe'].setValue(false);
    this.imunologiaForm.controls['Antihavigg'].setValue(false);
    this.imunologiaForm.controls['Antihavigm'].setValue(false);
    this.imunologiaForm.controls['Antihcv'].setValue(false);
    this.imunologiaForm.controls['Lincd4'].setValue(false);
    this.imunologiaForm.controls['Lincd8'].setValue(false);
    this.imunologiaForm.controls['Ftaabs'].setValue(false);
    this.imunologiaForm.controls['Tpha'].setValue(false);
    this.imunologiaForm.controls['Antihiv'].setValue(false);
    this.imunologiaForm.controls['Westemblothiv'].setValue(false);
    this.imunologiaForm.controls['Paulbunnell'].setValue(false);
    this.imunologiaForm.controls['Hgsag'].setValue(false);
    this.imunologiaForm.controls['Fatorantinuclear'].setValue(false);
    this.imunologiaForm.controls['Chagasifihma'].setValue(false);
    this.imunologiaForm.controls['Vdrl'].setValue(false);

    this.imunologiaForm.controls['Aslo'].setValue(0);
    this.imunologiaForm.controls['Prcultrassensivel'].setValue(0);
    this.imunologiaForm.controls['Fatorreumatoide'].setValue(0);
    this.imunologiaForm.controls['Alfa1glicoproteinaacida'].setValue(0);
    this.imunologiaForm.controls['C3'].setValue(0);
    this.imunologiaForm.controls['C4'].setValue(0);
    this.imunologiaForm.controls['Iggtotal'].setValue(0);
    this.imunologiaForm.controls['Igmtotal'].setValue(0);
    this.imunologiaForm.controls['Igatotal'].setValue(0);
    this.imunologiaForm.controls['Igetotal'].setValue(0);
    this.imunologiaForm.controls['Antiperoxidase'].setValue(0);
    this.imunologiaForm.controls['Antitireoglobulina'].setValue(0);
    this.imunologiaForm.controls['Antihbs'].setValue(0);
   
    this.imunologiaForm.controls['Citomegalovirusigg'].setValue(0);
    this.imunologiaForm.controls['Citomegalovirusigm'].setValue(0);
    this.imunologiaForm.controls['Rubeolaigg'].setValue(0);
    this.imunologiaForm.controls['Rubeolaigm'].setValue(0);
    this.imunologiaForm.controls['Toxoplasmoseigg'].setValue(0);
    this.imunologiaForm.controls['Toxoplasmoseigm'].setValue(0);

    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraAslo'));
  }

  async getBalancoImunologia(response: any) {
    this.Botaoimunologia = "Alterar";
    let Datalancamentoimunologia = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.imunologiaForm.controls['Datalancamentoimunologia'].setValue(Datalancamentoimunologia);
    this.imunologiaForm.controls['Antihbctotal'].setValue(response[0].Antihbctotal);
    this.imunologiaForm.controls['Antihbcigm'].setValue(response[0].Antihbcigm);
    this.imunologiaForm.controls['Hbeag'].setValue(response[0].Hbeag);
    this.imunologiaForm.controls['Antihbe'].setValue(response[0].Antihbe);
    this.imunologiaForm.controls['Antihavigg'].setValue(response[0].Antihavigg);
    this.imunologiaForm.controls['Antihavigm'].setValue(response[0].Antihavigm);
    this.imunologiaForm.controls['Antihcv'].setValue(response[0].Antihcv);
    this.imunologiaForm.controls['Lincd4'].setValue(response[0].Lincd4);
    this.imunologiaForm.controls['Lincd8'].setValue(response[0].Lincd8);
    this.imunologiaForm.controls['Ftaabs'].setValue(response[0].Ftaabs);
    this.imunologiaForm.controls['Tpha'].setValue(response[0].Tpha);
    this.imunologiaForm.controls['Antihiv'].setValue(response[0].Antihiv);
    this.imunologiaForm.controls['Westemblothiv'].setValue(response[0].Chagasifihma);
    this.imunologiaForm.controls['Paulbunnell'].setValue(response[0].Chagasifihma);
    this.imunologiaForm.controls['Hgsag'].setValue(response[0].Chagasifihma);
    this.imunologiaForm.controls['Fatorantinuclear'].setValue(response[0].Fatorantinuclear);
    this.imunologiaForm.controls['Chagasifihma'].setValue(response[0].Chagasifihma);
    this.imunologiaForm.controls['Vdrl'].setValue(response[0].Vdrl);

    this.imunologiaForm.controls['Aslo'].setValue(response[0].Aslo.replace(",", "."));
    this.imunologiaForm.controls['Prcultrassensivel'].setValue(response[0].Prcultrassensivel.replace(",", "."));
    this.imunologiaForm.controls['Fatorreumatoide'].setValue(response[0].Fatorreumatoide.replace(",", "."));
    this.imunologiaForm.controls['Alfa1glicoproteinaacida'].setValue(response[0].Alfa1glicoproteinaacida.replace(",", "."));
    this.imunologiaForm.controls['C3'].setValue(response[0].C3.replace(",", "."));
    this.imunologiaForm.controls['C4'].setValue(response[0].C4.replace(",", "."));
    this.imunologiaForm.controls['Iggtotal'].setValue(response[0].Iggtotal.replace(",", "."));
    this.imunologiaForm.controls['Igmtotal'].setValue(response[0].Igmtotal.replace(",", "."));
    this.imunologiaForm.controls['Igatotal'].setValue(response[0].Igatotal.replace(",", "."));
    this.imunologiaForm.controls['Igetotal'].setValue(response[0].Igetotal.replace(",", "."));
    this.imunologiaForm.controls['Antiperoxidase'].setValue(response[0].Antiperoxidase.replace(",", "."));
    this.imunologiaForm.controls['Antitireoglobulina'].setValue(response[0].Antitireoglobulina.replace(",", "."));
    this.imunologiaForm.controls['Antihbs'].setValue(response[0].Antihbs.replace(",", "."));
    this.imunologiaForm.controls['Citomegalovirusigg'].setValue(response[0].Citomegalovirusigg.replace(",", "."));
    this.imunologiaForm.controls['Citomegalovirusigm'].setValue(response[0].Citomegalovirusigm.replace(",", "."));
    this.imunologiaForm.controls['Rubeolaigg'].setValue(response[0].Rubeolaigg.replace(",", "."));
    this.imunologiaForm.controls['Rubeolaigm'].setValue(response[0].Rubeolaigm.replace(",", "."));
    this.imunologiaForm.controls['Toxoplasmoseigg'].setValue(response[0].Toxoplasmoseigg.replace(",", "."));
    this.imunologiaForm.controls['Toxoplasmoseigm'].setValue(response[0].Toxoplasmoseigm.replace(",", "."));

  }

  async selecionarImunologia(seek:number) {
    this.laboratorioService.selecionarImunologia(this.Internacao, this.Paciente).then((response) => {
      this.imunologiaCollection = response;
        this.IdGeral = seek;
      
    }).catch((response) => {

    });
  }



  async mostrarValor(campo:string) {
    let valor: any = eval('this.imunologiaForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == NaN || valor == '') {
      this.imunologiaForm.controls[campo].setValue(valor);
    }


   
    if (campo == "Aslo") {
      this.Aslo = valor;
    } else if (campo == "Prcultrassensivel") {
      this.Prcultrassensivel = valor;
    } else if (campo == "Fatorreumatoide") {
      this.Fatorreumatoide = valor;
    } else if (campo == "Fatorreumatoide") {
      this.Fatorreumatoide = valor;
    } else if (campo == "Alfa1glicoproteinaacida") {
      this.Alfa1glicoproteinaacida = valor;
    } else if (campo == "C3") {
      this.C3 = valor;
    } else if (campo == "C4") {
      this.C4 = valor;
    } else if (campo == "Iggtotal") {
      this.Iggtotal = valor;
    } else if (campo == "Igmtotal") {
      this.Igmtotal = valor;
    } else if (campo == "igatotal") {
      this.igatotal = valor;
    } else if (campo == "Igetotal") {
      this.Igetotal = valor;
    } else if (campo == "Antiperoxidase") {
      this.Antiperoxidase = valor;
    } else if (campo == "Antitireoglobulina") {
      this.Antitireoglobulina = valor;
    } else if (campo == "Antitireoglobulina") {
      this.Antitireoglobulina = valor;
    } else if (campo == "Antihbctotal") {
      this.Antihbctotal = valor;
    } else if (campo == "Antihbs") {
      this.Antihbs = valor;
    } else if (campo == "Antihbcigm") {
      this.Antihbcigm = valor;
    } else if (campo == "Hbeag") {
      this.Hbeag = valor;
    } else if (campo == "Antihbe") {
      this.Antihbe = valor;
    } else if (campo == "Antihavigg") {
      this.Antihavigg = valor;
    } else if (campo == "Antihavigm") {
      this.Antihavigm = valor;
    } else if (campo == "Antihcv") {
      this.Antihcv = valor;
    } else if (campo == "Hgsag") {
      this.Hgsag = valor;
    } else if (campo == "Citomegalovirusiga") {
      this.Citomegalovirusiga = valor;
    } else if (campo == "Citomegalovirusigm") {
      this.Citomegalovirusigm = valor;
    } else if (campo == "Ftaabs") {
      this.Ftaabs = valor;
    } else if (campo == "Tpha") {
      this.Tpha = valor;
    } else if (campo == "Hgsag") {
      this.Hgsag = valor;
    } else if (campo == "Antihiv") {
      this.Antihiv = valor;
    } else if (campo == "Westemblothiv") {
      this.Westemblothiv = valor;
    } else if (campo == "Rubeolaigm") {
      this.Rubeolaigm = valor;
    } else if (campo == "Toxoplasmoseigg") {
      this.Toxoplasmoseigg = valor;
    } else if (campo == "Toxoplasmoseigm") {
      this.Toxoplasmoseigm = valor;
   
    } 
  }


}


