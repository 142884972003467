import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams, IonThumbnail } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-cpis',
  templateUrl: './nutric.component.html',
  styleUrls: ['./nutric.component.scss'],
})
export class NutricComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  Id: number = -1;
  Comentario:string="";
  lancamentosCollection: any = [];
  Idade: number = 0;
  Apache: number = 0;
  Sofa: number = 0;
  Comorbidade: number = 0;
  Dia: number = 0;
  Interleucina: number = 0;
  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  nutricForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number=0;
  Calculo: number=0;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Dias: string = "";
  itensColection: any = [];
  NomePaciente: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Altura: any;
  Peso: any;
  Bsa: any;
  Sistema:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController:LoadingController
  ) {
    this.nutricForm = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Idade: ['', ''],
      Apache: ['', ''],
      Sofa: ['', ''],
      Comorbidade: ['', ''],
      Dia: ['', ''],
      Interleucina: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Sistema = this.navParams.get('Sistema');

  }
  ngOnInit() {
    this.Calculo = 33;
  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível seleciona as calculadoreas')
    }).finally(() => {
      loading.dismiss();
    });

  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }


 


  


  


  





  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularNutric() {
    let Idade: number = this.nutricForm.value.Idade;
    if (Idade == null || Idade == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de APACHE II", null)
      return;

    }

    this.Idade = Idade;

    let Apache: number = this.nutricForm.value.Apache;
    if (Apache == null || Apache == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de LEUCÓCITOS", null)
      return;

    }

    this.Apache = Apache;

    let Sofa: number = this.nutricForm.value.Sofa;
    if (Sofa == null || Sofa == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de SOFA", null)
      return;
    }

    this.Sofa = Sofa;

    let Comorbidade: number = this.nutricForm.value.Comorbidade;
    if (Comorbidade == null || Comorbidade == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de COMORBIDADE P/F", null)
      return;

    }

    this.Comorbidade = Comorbidade;

    let Dia: number = this.nutricForm.value.Dia;
    if (Dia == null || Dia == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de DIAS INTERNADO", null)
      return;

    }

    this.Dia = Dia;

    let Interleucina: number = this.nutricForm.value.Interleucina;
    if (Interleucina == null || Interleucina == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de INTERLEUCINA", null)
      return;

    }

    this.Interleucina = Interleucina;

    this.Valor = Idade + Apache + Sofa + Comorbidade + Dia + Interleucina
    let Range:string= ""
    if (this.Valor <=2) {
      Range= " (BAIXO RISCO)"
    }else if (this.Valor >= 3 && this.Valor <= 7) {
      Range= " (INTERMEDIÁRIO RISCO)"
    }else if (this.Valor >= 8) {
      Range= " (ALTO RISCO)"
    }
    this.Valor = this.utilService.formateNumber(this.Valor, 2);
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.nutricForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];


    Relacao = Relacao + this.Idade + '*';
    Relacao = Relacao + this.Apache + '*';
    Relacao = Relacao + this.Sofa + '*';
    Relacao = Relacao + this.Comorbidade + '*'; //p/f
    Relacao = Relacao + this.Dia + '*';
    Relacao = Relacao + this.Interleucina;
    let Datalancamento:any = this.nutricForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);


    let form = {
      Id: this.nutricForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.nutricForm.value.Comentario

    }

    //console.log("Form: " + JSON.stringify(form))

    if (this.nutricForm.value.Id == null || this.nutricForm.value.Id == undefined ||
       this.nutricForm.value.Id == -1 || this.nutricForm.value.Id == '') {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " INCLUIDO com sucesso",  null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.nutricForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome,  null);

      });
    }

  }

 

  async limpaDados() {
    this.Id= -1;
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.nutricForm.controls['Datalancamento'].setValue(Datalancamento);

    this.nutricForm.controls['Idade'].setValue(0);
    this.nutricForm.controls['Apache'].setValue(0);
    this.nutricForm.controls['Sofa'].setValue(0);
    this.nutricForm.controls['Comorbidade'].setValue(0);
    this.nutricForm.controls['Dia'].setValue(0);
    this.nutricForm.controls['Interleucina'].setValue(0);

    this.Botao = "CALCULAR"
    this.Valor = 0;
    this.utilService.showToastCallBack("Tudo pronto para ADICIONAR um novo registro", 2000, () => {
    });
    this.nutricForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }



  }



  async mostrarDados(response: any) {
    if (response.length == 0){
      return ;
    }
    this.Botao = "ALTERAR";
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Idade = parseInt(Relacao[0]);
    this.Apache = parseInt(Relacao[1]);
    this.Sofa = parseInt(Relacao[2]);
    this.Comorbidade = parseInt(Relacao[3]);
    this.Dia = parseInt(Relacao[4]);
    this.Interleucina = parseInt(Relacao[5]);
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    this.nutricForm.controls['Datalancamento'].setValue(Datalancamento);
    this.nutricForm.controls['Idade'].setValue(this.Idade);
    this.nutricForm.controls['Apache'].setValue(this.Apache);
    this.nutricForm.controls['Sofa'].setValue(this.Sofa);
    this.nutricForm.controls['Comorbidade'].setValue(this.Comorbidade);
    this.nutricForm.controls['Dia'].setValue(this.Dia);
    this.nutricForm.controls['Interleucina'].setValue(this.Interleucina);
    this.Comentario = response[0].Comentario;
    this.nutricForm.controls['Comentario'].setValue(this.Comentario);

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento",  null)
    });
  }


}

