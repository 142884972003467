import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BalancoService {

  constructor(private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController

    ) { }


    selecionarBalancotipo() {
      let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalancotipo' ;
      return this.http.get(url).toPromise();
    }


    

    selecionarBalancoHoje(Internacao: number, Paciente: number) {
      let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalancoHoje/' + Internacao + '/' + Paciente;
      return this.http.get(url).toPromise();
    }

    selecionarBalancoHojeHora(Internacao: number, Paciente: number) {
      let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalancoHojeHora/' + Internacao + '/' + Paciente;
      return this.http.get(url).toPromise();
    }

    selecionarBalancoHojeResumo(Internacao: number, Paciente: number) {
      let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalancoHojeResumo/' + Internacao + '/' + Paciente;
      return this.http.get(url).toPromise();
    }
  selecionarBalanco(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalanco/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

 selecionarBalancoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Balanco/selecionarBalancoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  deletarBalancoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Balanco/deletarBalancoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarBalanco(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Balanco/adicionarBalanco';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarBalanco(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Balanco/atualizarBalanco';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }
}
