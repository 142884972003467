import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-urina',
  templateUrl: './urina.component.html',
  styleUrls: ['./urina.component.scss'],
})
export class UrinaComponent implements OnInit {
  @ViewChild('ancoraIdurina', null) ancoraIdurina: ElementRef;
  @ViewChild('ancoraDatalancamentourina', null) ancoraDatalancamentourina: ElementRef;
  @ViewChild('ancoraUrinatipoI', null) ancoraUrinatipoI: ElementRef;
  @ViewChild('ancoraDimorfismoeritrocitario', null) ancoraDimorfismoeritrocitario: ElementRef;
  @ViewChild('ancoraProteinabencejones', null) ancoraProteinabencejones: ElementRef;

  private IdGeral:number = 0;
  private Exames:any;
  private Datalancamentourina:string="";
  private Usuario:number = 0;
  private Paciente: number = 0;
  private Idade:string = "";
  private Nome:string = "";
  private Internacao: number = 0;
  private Historiaclinica:string = "";
  private Setor:string = "";
  private Leito:any = "";
  public urinaForm: FormGroup;
  public Botaourina:string = "Cadastrar";
  private Datainternacao:string = "";
  private Dias:string = "";
  private urinaCollection: any = [];
  public dadosListMed:any;
  private Logado:number=0;
 
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService : LaboratorioService,
    private alertController:AlertController,
    ) {

      this.urinaForm = formBuilder.group({
        
        Idurina: ['', ''],
        Datalancamentourina: ['', ''],
        UrinatipoI: ['', ''],
        Dimorfismoeritrocitario:['', ''],
        Proteinabencejones:['', ''],
      
       
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
     
    }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarUrina(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao=  this.dadosListMed.Internacao.Id;
    this.Usuario =this.dadosListMed.Internacao[0].Usuario;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosUrina();
  
  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: string = eval('this.urinaForm.value.' + this.Exames[i].nome);
      if (valor =='' || valor == undefined || valor == null) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS SEM INFORMAÇÃO</strong><br>';
          parametro = this.Exames[i].nome;
        }
        this.urinaForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + '<br>'
      }
    }


    let tipo = parseInt(this.urinaForm.value.Idcoagulograma);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'URINA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroUrina();
          }
        }
      ]
    });

    await alert.present();
  }


  cadastroUrina() {
    let form = {
      Id: this.urinaForm.value.Idurina,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.urinaForm.value.Datalancamentourina),
      Usuario: this.Usuario,
      UrinatipoI: this.urinaForm.value.UrinatipoI,
      Dimorfismoeritrocitario: this.urinaForm.value.Dimorfismoeritrocitario,
      Proteinabencejones:this.urinaForm.value.Proteinabencejones,
    
    }

     
    
    
    if (this.urinaForm.value.Idurina == 0 || this.urinaForm.value.Idurina == undefined || this.urinaForm.value.Idurina == null || this.urinaForm.value.Idurina == '') {
      this.laboratorioService.adicionarUrina(form).then((response) => {
        this.selecionarUrina(response[0].Id);
        this.utilService.showToastCallBack("Urina incluido com sucesso", 2000, null);
      

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Urina", 2000, null);

      });
    } else {
      this.laboratorioService.atualizarUrina(form).then((response) => {
        this.utilService.showToastCallBack("Urina atualizado com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Urina", 2000, null);

      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresUrina()
    .then((response) => {
      this.Exames = response;

    })
    .catch((response) => {

      this.Exames = [];
    });
  }
  async selecionarUrinaUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosUrina();
      return ;
    }
    this.laboratorioService.selecionarUrinaUnico(Id).then((response) => {
      this.getBalancoUrina(response);
    }).catch((response) => {

    });
  }

   

  async limpaDadosUrina() {
    this.Botaourina = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentourina = dtHoje;
    this.urinaForm.controls['Datalancamentourina'].setValue(dtHoje);
    this.urinaForm.controls['UrinatipoI'].setValue('');
    this.urinaForm.controls['Dimorfismoeritrocitario'].setValue('');
    this.urinaForm.controls['Proteinabencejones'].setValue('');
   
    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraUrinatipoI'));
  }

  async getBalancoUrina(response: any) {
    this.Botaourina = "Alterar";
    let Datalancamentourina = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.urinaForm.controls['Datalancamentourina'].setValue(Datalancamentourina);
    this.urinaForm.controls['UrinatipoI'].setValue(response[0].UrinatipoI);
    this.urinaForm.controls['Dimorfismoeritrocitario'].setValue(response[0].Dimorfismoeritrocitario);
    this.urinaForm.controls['Proteinabencejones'].setValue(response[0].Proteinabencejones);
   
  }

  async selecionarUrina(seek:number) {
    this.laboratorioService.selecionarUrina(this.Internacao, this.Paciente).then((response) => {
      this.urinaCollection = response;
        this.IdGeral = seek;
      
    }).catch((response) => {

    });
  }



  


}