import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';



@Component({
  selector: 'app-patologiainfo',
  templateUrl: './patologiainfo.component.html',
  styleUrls: ['./patologiainfo.component.scss'],
})
export class PatologiainfoComponent implements OnInit {
  Patologia:number=0;
  Nome:string='';
  Sigla:string='';
  Epidemiologia:string='';
  Fisiopatologia:string='';
  Diagnostico:string='';
  Tratamento:string='';
  Referencia:string='';
  Usuario:number=0;

  constructor(private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private checklistService: ChecklistService,
    private alertController: AlertController,
    private modalController:ModalController) {

    this.Patologia = this.navParams.get('Patologia');
    this.Nome = this.navParams.get('Nome');
    this.Sigla= this.navParams.get('Sigla');
    this.Epidemiologia= this.navParams.get('Epidemiologia');
    this.Fisiopatologia= this.navParams.get('Fisiopatologia');
    this.Diagnostico= this.navParams.get('Diagnostico');
    this.Tratamento= this.navParams.get('Tratamento');
    this.Referencia= this.navParams.get('Referencia');

   }

  ngOnInit() {}

  async closeModal() {
    this.modalController.dismiss();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

}
