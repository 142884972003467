import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonThumbnail, LoadingController } from '@ionic/angular';
import { Chart } from 'chart.js';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { UtilService } from '../../services/util.service';
import { LancamentoService } from '../../services/lancamento.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LaboratorioService } from '../../services/laboratorio.service';
import { BalancoService } from '../../services/balanco.service';



@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.scss'],
})
export class GraficoComponent implements AfterViewInit {
  @ViewChild('lineChart', { static: false }) lineChart;
  @ViewChild('multilineChart', { static: false }) multilineChart;
  @ViewChild('multilineChart4', { static: false }) multilineChart4;

  drogaCollection: any = [];
  bars: any;
  Escoretipo: number = 0;
  NomeEscore: string = '';
  Rangeminimo: number = 0;
  Rangemaximo: number = 0;
  lRet: any;
  dadosListMed: any;
  escoreColection: any = [];
  Escore: number = 0;
  Valor: number = 0;
  Siglamedida: string = '';
  multiple: string = "false"
  Padrao: number = 0;
  Checklist: number;
  Internacao: number = 0;
  Paciente: number = 0;
  Datalancamento: any;
  Usuario: number = 0;
  Id: number = -1;
  Botao: string = 'CADASTRAR';
  lancamentosCollection: any = [];
  Logado: number = 0;
  NomePadrao: string = "";
  NomePaciente: string = "";
  FotoAtualPaciente: string = "";
  labelColection: any = [];
  valorColection: any = [];
  Droga: number = 1;
  NomeDroga: string = '';
  vitalCollection: any = [];
  aLabels: any = [];
  aPs: any = [];
  aPd: any = [];
  aFc: any = [];
  aFr: any = [];
  aTemp: any = [];
  aO2: any = [];
  aHgt: any = [];
  aPam: any = [];
  aPi: any = [];
  aPeep: any = [];
  aPlato: any = [];
  aFiO2: any = [];
  aVc: any = [];
  aValorSistema: any = [];
  aLabelSistema: any = [];
  aData: any = [];
  aLabel: any = [];
  aCor: any = [];
  aBorder: any = [];
  Total: number = 0;
  aCorItem: any = [];
  graficoForm: FormGroup;
  Sistema: number = 0;
  // gasometria
  aPh: any = [];
  aPaco2: any = [];
  aPao2: any = [];
  aLactato: any = [];
  aHco3: any = [];
  aBe: any = [];
  aFio2: any = [];
  aPf: any = [];
  aSato2: any = [];

  // bioquimica
  aGlicose: any = [];
  aUreia: any = [];
  aCreatinina: any = [];
  aBilirrubinatotal: any = [];
  aAlbumina: any = [];

  // eletrolitos

  aNa: any = [];
  aCl: any = [];
  aCa: any = [];
  aK: any = [];
  aMg: any = [];
  aP: any = [];

  // SOFA
  aSofa: any = [];
  aNeurologico: any = [];
  aCardiaco: any = [];
  aRespiratorio: any = [];
  aDigestivo: any = [];
  aRenal: any = [];
  aHematologico: any = [];

  // infeccao - hemograma

  aHemoglobina: any = [];
  aLeucocitos: any = [];
  aBastonetes: any = [];
  aSegmentados: any = [];
  aPlaquetas: any = [];

  // Balanco
  aEntradas: any = [];
  aSaidas: any = [];
  aMovimentacao: any = [];
  aBalanco: any = [];

  a7e: any = [];
  a8e: any = [];
  a9e: any = [];
  a10e: any = [];
  a11e: any = [];
  a12e: any = [];
  a13e: any = [];
  a14e: any = [];
  a15e: any = [];
  a16e: any = [];
  a17e: any = [];
  a18e: any = [];
  a19e: any = [];
  a20e: any = [];
  a21e: any = [];
  a22e: any = [];
  a23e: any = [];
  a24e: any = [];
  a1e: any = [];
  a2e: any = [];
  a3e: any = [];
  a4e: any = [];
  a5e: any = [];
  a6e: any = [];

  a7s: any = [];
  a8s: any = [];
  a9s: any = [];
  a10s: any = [];
  a11s: any = [];
  a12s: any = [];
  a13s: any = [];
  a14s: any = [];
  a15s: any = [];
  a16s: any = [];
  a17s: any = [];
  a18s: any = [];
  a19s: any = [];
  a20s: any = [];
  a21s: any = [];
  a22s: any = [];
  a23s: any = [];
  a24s: any = [];
  a1s: any = [];
  a2s: any = [];
  a3s: any = [];
  a4s: any = [];
  a5s: any = [];
  a6s: any = [];
  aH: any = ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00'];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private checklistService: ChecklistService,
    private utilService: UtilService,
    private lancamentoService: LancamentoService,
    private formBuilder: FormBuilder,
    private laboratorioService: LaboratorioService,
    private balancoService: BalancoService,
    private alertController:AlertController

  ) {

    this.graficoForm = formBuilder.group({

      Droga: ['', ''],

    })
    this.Escore = parseInt(this.navParams.get('Escore'));
    this.Escoretipo = parseInt(this.navParams.get('Escoretipo'));
    this.NomeEscore = this.navParams.get('NomeEscore').toUpperCase();
    this.Rangeminimo = parseInt(this.navParams.get('Rangeminimo'));
    this.Rangemaximo = parseInt(this.navParams.get('Rangemaximo'));
    this.Siglamedida = this.navParams.get('Siglamedida');
    this.Checklist = this.navParams.get('Internacao');
    this.Internacao = this.navParams.get('Checklist');
    this.Paciente = this.navParams.get('Paciente');
    this.Datalancamento = this.navParams.get('Datalancamento');
    this.Valor = this.navParams.get('Valor');
    this.Usuario = this.navParams.get('Usuario');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
    this.Droga = parseInt(this.navParams.get('Droga'));
    this.NomeDroga = this.navParams.get('NomeDroga');

    this.aLabel = this.navParams.get('aLabel');
    this.aData = this.navParams.get('aData');
    this.aCor = ["#228B22", "#FFD700", "#FF0000", "#0000FF", "#006666", "#CC0033", "#FFCC00", "#006666", "#CC6666", "#009966", "#CC9966", "#003399", "#9933FF", "#993300", "#0F00CD", "CCCCCC"];
    this.aBorder = ["#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "#000000", "000000"];

    // no caso dos array já vierem prontos
    this.aLabelSistema = this.navParams.get('aLabel');
    this.aValorSistema = this.navParams.get('aValor');
    this.aCorItem = this.utilService.montaCorItem(this.aData.length, 'light');
    for (let i = 0; i < this.aData.length; i++) {
      this.Total = this.Total + this.aData[i];
    }
  }
  ngAfterViewInit(): void {


  }

  ionViewDidEnter() {
    if (this.Escoretipo == 6) {
      this.selecionarDrogalancamentoInternacao();

    }

    setTimeout(() => {
      this.incio();
    }, 1000);



  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDrogalancamentoInternacao() {
    this.checklistService.selecionarDrogalancamentoInternacao(this.Internacao, this.Paciente).then((response: any) => {
      this.drogaCollection = response;
      this.Droga = this.drogaCollection[0].Id;
      this.NomeDroga = this.drogaCollection[0].Nome;


      // this.graficoForm.controls['Droga'].setValue( this.Droga);


    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar as drogas", null)
    });
  }

  async createMultiLineChartExame4() {
    this.bars = new Chart(this.multilineChart4.nativeElement, {
      type: 'doughnut',
      data: {
        fill: false,
        labels: this.aLabel,
        datasets: [{
          label: this.aLabel,
          fill: false,
          data: this.aData,
          backgroundColor: this.aCor, //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
          borderColor: this.aBorder,//'#4B0082',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
          borderWidth: 1
        },
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false,

            }
          }]
        }
      }
    });
  }

  async incio() {



    if (this.Escoretipo == 0) { // sistema já vem pronto
      this.createMultiLineChartExame4();
    } else if (this.Escoretipo == 6) { // drogas
      await this.selecionarInfusoesGrafico(this.Droga);
    } else if (this.Escoretipo == 7) { // dados vitais
      await this.selecionarVitalGrafico();
    } else if (this.Escoretipo == 8) { // ventilação
      await this.selecionarVentilacaoGrafico();
    } else if (this.Escoretipo == 9) { // obstetricia
      await this.selecionarVitalGrafico();
    } else if (this.Escoretipo == 11) { // bioquimica
      await this.selecionarBioquicaGrafico();
    } else if (this.Escoretipo == 12) { // eletrolitos
      await this.selecionarEletrolitosGrafico();
    } else if (this.Escoretipo == 13) { // gasometria
      await this.selecionarGasometriaGrafico();
    } else if (this.Escoretipo == 14) { // hemodinâmica
      await this.selecionarHemodinamicaGrafico();
    } else if (this.Escoretipo == 15) { // sofa
      await this.selecionarSofaGrafico();
    } else if (this.Escoretipo == 16) { // balanço
      await this.selecionarBalancoGrafico();
    } else if (this.Escoretipo == 17) { // infecção
      await this.selecionarInfeccaoGrafico();
    } else {
      await this.selecionarChecklistlancamentoGrafico();

    }

  }

  async selecionarVitalGrafico() {
    this.lancamentoService.selecionarVitalUltimos(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aPs.push(parseFloat(response[i].Ps));
        this.aPd.push(parseFloat(response[i].Pd));
        this.aFc.push(parseFloat(response[i].Fc));
        this.aFr.push(parseFloat(response[i].Fr));
        this.aTemp.push(parseFloat(response[i].Temp));
        this.aO2.push(parseFloat(response[i].O2));
        this.aHgt.push(parseFloat(response[i].Hgt));
        let Pam: any = (response[i].Ps + (2 * response[i].Pd)) / 3
        Pam = parseInt(Pam);
        this.aPam.push(Pam);

      }
      this.createMultiLineChartVital();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarSofaGrafico() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, 34).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      let relacao: any
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aSofa.push(parseFloat(response[i].Valor));
        relacao = response[i].Relacao.split("*");
        this.aNeurologico.push(parseFloat(relacao[0]));
        this.aCardiaco.push(parseFloat(relacao[1]));
        this.aRespiratorio.push(parseFloat(relacao[2]));
        this.aDigestivo.push(parseFloat(relacao[3]));
        this.aRenal.push(parseFloat(relacao[4]));
        this.aHematologico.push(parseFloat(relacao[5]));

      }
      this.createMultiLineChartSofa();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarBalancoGrafico2() {
    this.balancoService.selecionarBalancoHojeHora(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      // //console.log("Response: " + JSON.stringify(response))
      // let n7e: number = 0;
      // let n7s: number = 0;
      // for (let i = 0; i < this.aH.length; i++) {
      //   for (let z = 0; z < response.length; z++) {
      //     if (response[z].Datalancamento.indexOf(this.aH[i]) > -1) {
      //       if (this.aH[i] == '07:00' && response[z].Grupo == 1) {
      //         n7e= n7e + parseFloat(response[z].Valor)
      //       }
      //       if (this.aH[i] == '07:00' && response[z].Grupo == 2) {
      //         n7s = n7s + parseFloat(response[z].Valor)
      //       }
      //     }
      //   }
      // }

      //his.a7e.push(n7e);
      //this.a7s.push(n7s);

      ////console.log("this.a7e;" + JSON.stringify(this.a7e))
      ////console.log("this.a7s;" + JSON.stringify(this.a7s))

      setTimeout(()=>{
        this.createMultiLineChartBalanco2();

      },1000)

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar o Balanço Hídrico", null)
    });
  }

  async selecionarBalancoGrafico() {
    this.balancoService.selecionarBalancoHoje(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      let Entrada: number = 0;
      let Saida: number = 0;

      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));

        if (response[i].Grupo == 1) {
          Entrada += parseFloat(response[i].Valor);
          this.aMovimentacao.push(parseFloat(response[i].Valor));
        } else {
          this.aMovimentacao.push(parseFloat(response[i].Valor) * -1);
          Saida += parseFloat(response[i].Valor);
        }

        this.aBalanco.push(Entrada - Saida);

      }

      this.createMultiLineChartBalanco();
      this.aLabel = ["Entradas", "Saídas"];
      this.aData = [Entrada, Saida];
      this.aCor = ['#FF0000', '#FFFF00']

      this.createMultiLineChartExame4()

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar o Balanço Hídrico", null)
    });
  }

  async selecionarBioquicaGrafico() {
    this.laboratorioService.selecionarBioquimica(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aGlicose.push(parseFloat(response[i].Glicose));
        this.aUreia.push(parseFloat(response[i].Ureia));
        this.aCreatinina.push(parseFloat(response[i].Creatinina));
        this.aBilirrubinatotal.push(parseFloat(response[i].Bilirrubinatotal));
        this.aAlbumina.push(parseFloat(response[i].Albumina));


      }
      this.createMultiLineChartBioquimica();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarEletrolitosGrafico() {
    this.laboratorioService.selecionarBioquimica(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aNa.push(parseFloat(response[i].Sodio));
        this.aCl.push(parseFloat(response[i].Cloro));
        this.aCa.push(parseFloat(response[i].Calcio));

        this.aK.push(parseFloat(response[i].Potassio));
        this.aMg.push(parseFloat(response[i].Magnesio));
        this.aP.push(parseFloat(response[i].P));



      }
      this.createMultiLineChartEletrolito();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarHemodinamicaGrafico() {
    this.lancamentoService.selecionarVitalUltimos(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aPs.push(parseFloat(response[i].Ps));
        this.aPd.push(parseFloat(response[i].Pd));
        this.aFc.push(parseFloat(response[i].Fc));
        this.aFr.push(parseFloat(response[i].Fr));
        this.aTemp.push(parseFloat(response[i].Temp));
        this.aO2.push(parseFloat(response[i].O2));
        this.aHgt.push(parseFloat(response[i].Hgt));
        let Pam: any = (response[i].Ps + (2 * response[i].Pd)) / 3
        Pam = parseInt(Pam);
        this.aPam.push(Pam);

      }
      this.createMultiLineChartVital();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarInfeccaoGrafico() {
    this.laboratorioService.selecionarHemograma(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aHemoglobina.push(parseFloat(response[i].Hemoglobina));
        this.aLeucocitos.push(parseFloat(response[i].Leucocitos));
        this.aBastonetes.push(parseFloat(response[i].Bastonetes));
        this.aSegmentados.push(parseFloat(response[i].Segmentados));
        this.aPlaquetas.push(parseFloat(response[i].Plaquetas));


      }
      this.createMultiLineChartInfeccao();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados vitas", null)
    });
  }

  async selecionarGasometriaGrafico() {

    this.laboratorioService.selecionarGasometria(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      // this.aLabels.push("pH");
      // this.aLabels.push("PaCO2");
      // this.aLabels.push("PaO2");
      // this.aLabels.push("Lactato");
      // this.aLabels.push("HCO3");
      // this.aLabels.push("BE");
      // this.aLabels.push("FiO2");
      // this.aLabels.push("PF");
      // this.aLabels.push("SatO2");

      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));

        this.aPh.push(parseFloat(response[i].Ph));
        this.aPaco2.push(parseFloat(response[i].Paco2));
        this.aPao2.push(parseFloat(response[i].Pao2));
        this.aLactato.push(parseFloat(response[i].Lactato));
        this.aHco3.push(parseFloat(response[i].Hco3));
        this.aBe.push(parseFloat(response[i].Be));
        this.aFio2.push(parseFloat(response[i].Fio2));
        this.aPf.push(parseFloat(response[i].Pf));
        this.aSato2.push(parseFloat(response[i].Sato2));

      }
      this.createMultiLineChartGasometria();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar as GASOMETRIAS", null)
    });
  }


  async selecionarVentilacaoGrafico() {
    this.lancamentoService.selecionarVentilacaoUltimos(this.Internacao, this.Paciente).then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      // //console.log("Response: " + JSON.stringify(response))
      for (let i = 0; i < response.length; i++) {
        this.aLabels.push(response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3));
        this.aPi.push(parseFloat(response[i].Pressaoinspiratoria));
        this.aPeep.push(parseFloat(response[i].Peep));
        this.aPlato.push(parseFloat(response[i].Pressaoplato));
        this.aFr.push(parseFloat(response[i].Frpaciente));
        this.aFiO2.push(parseFloat(response[i].FiO2));
        this.aVc.push(parseFloat(response[i].Volumeexpiratorio));


      }
      this.createMultiLineChartVentilacao();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os dados da ventilação mecânica", null)
    });
  }



  async closeModal() {
    this.modalController.dismiss();
  }

  async selecionarInfusoesGrafico(Droga: number) {
    this.labelColection = [];
    this.valorColection = [];
    for (let z = 0; z < this.drogaCollection.length; z++) {
      if (this.drogaCollection[z].Id == Droga) {
        this.NomeDroga = this.drogaCollection[z].Nome + " (" +
          this.drogaCollection[z].SiglaUnidadeMedidaConcentracao + ")";
        break;
      }
    }

    this.checklistService.selecionarDrogalancamento(this.Internacao, this.Paciente, Droga)
      .then((response: any) => {
        for (let i = 0; i < response.length; i++) {
          this.labelColection.push({
            Data: response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3),
            Id: response[i].Id
          }
          )
          this.valorColection.push({
            Valor: parseFloat(response[i].Valor),
            Id: response[i].Id,
          }

          )
        }
        this.createLineChart();
      }).catch((response) => {
        this.utilService.showAlertCallback('Não foi possível acessar os dados desse checklist')
      });
  }

  async selecionarLancamentosSistemas() {
    this.labelColection = this.aLabelSistema;
    this.valorColection = this.aValorSistema;
    this.createLineChart();
  }

  async selecionarChecklistlancamentoGrafico() {
    this.checklistService.selecionarChecklistlancamentoGrafico(this.Internacao, this.Paciente, this.Checklist)
      .then((response: any) => {
        for (let i = 0; i < response.length; i++) {
          this.labelColection.push({
            Data: response[i].Datalancamento.substr(0, response[i].Datalancamento.length - 3),
            Id: response[i].Id
          }
          )
          this.valorColection.push({
            Valor: parseFloat(response[i].Valor),
            Id: response[i].Id,
          }

          )
        }
        this.createLineChart();
      }).catch((response) => {
        this.utilService.showAlertCallback('Não foi possível acessar os dados desse checklist')
      });
  }


  createLineChart() {
    this.labelColection = this.labelColection.sort((n1, n2) => n1.Id - n2.Id);
    this.valorColection = this.valorColection.sort((n1, n2) => n1.Id - n2.Id);
    let aLabel = [];
    let aValor = [];
    for (let i = 0; i < this.labelColection.length; i++) {
      aLabel.push(this.labelColection[i].Data);
    }
    for (let z = 0; z < this.labelColection.length; z++) {
      aValor.push(this.valorColection[z].Valor);
    }
    let Label: string = ""
    if (this.Escoretipo == 6) {
      Label = this.NomeDroga;
    } else {
      Label = this.Siglamedida;
    }
    this.bars = new Chart(this.lineChart.nativeElement, {
      type: 'line',
      data: {
        labels: aLabel,
        datasets: [{
          label: Label,
          data: aValor,
          // backgroundColor: '#989aa2', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
          borderColor: '#0071bc',   //'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
          borderWidth: 1.5
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  //

  createMultiLineChartSofa() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'SOFA',
            fill: false,
            data: this.aSofa,
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 3,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Neurológico',
            fill: false,
            data: this.aNeurologico,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Cardíaco',
            fill: false,
            data: this.aCardiaco,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Respiratório',
            fill: false,
            data: this.aRespiratorio,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Digestivo',
            fill: false,
            data: this.aDigestivo,
            backgroundColor: '#FF0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Renal',
            fill: false,
            data: this.aRenal,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Hematológico',
            fill: false,
            data: this.aHematologico,
            backgroundColor: '#CCAABB', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#CCAABB',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },

        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartBalanco() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'bar',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'Movimentação mL',
            fill: false,
            data: this.aMovimentacao,
            backgroundColor: '#008000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#008000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Balanço mL',
            fill: true,
            data: this.aBalanco,
            backgroundColor: '#0000FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '##0000FF', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2
          },

        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartBalanco2() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'bar',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        label: '',
        datasets: [
          {
            label: '7E',
            fill: false,
            data: [600],
            backgroundColor: '#008000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#008000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '7S',
            fill: false,
            data: [230],
            backgroundColor: '#FA0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#FA0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '7B',
            fill: false,
            data: [500],
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#000000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '8E',
            fill: false,
            data: [600],
            backgroundColor: '#008000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#008000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '8S',
            fill: false,
            data: [230],
            backgroundColor: '#FA0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#FA0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '8B',
            fill: false,
            data: [500],
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#000000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '9E',
            fill: false,
            data: [600],
            backgroundColor: '#008000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#008000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '9S',
            fill: false,
            data: [230],
            backgroundColor: '#FA0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#FA0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '9B',
            fill: false,
            data: [500],
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#000000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '10E',
            fill: false,
            data: [600],
            backgroundColor: '#008000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#008000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '10S',
            fill: false,
            data: [230],
            backgroundColor: '#FA0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#FA0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },
          {
            label: '10B',
            fill: false,
            data: [500],
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor:  '#000000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 2,
         
          },

       
         

        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartVital() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'PS mmHg',
            fill: false,
            data: this.aPs,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'PAM mmHg',
            fill: false,
            data: this.aPam,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'PD mmHg',
            fill: false,
            data: this.aPd,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'FC bpm',
            fill: false,
            data: this.aFc,
            backgroundColor: '#FF0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'FR ipm',
            fill: false,
            data: this.aFr,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'TEMP °C',
            fill: false,
            data: this.aTemp,
            backgroundColor: '#CCAABB', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#CCAABB',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'SatO2 %',
            fill: false,
            data: this.aO2,
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000000',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'HGT mg/dL',
            fill: false,
            data: this.aHgt,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartInfeccao() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'Hemoglobina',
            fill: false,
            data: this.aHemoglobina,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Leucocitos',
            fill: false,
            data: this.aLeucocitos,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Bastonetes',
            fill: false,
            data: this.aBastonetes,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Segmentados',
            fill: false,
            data: this.aBastonetes,
            backgroundColor: '#FF0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Segmentados',
            fill: false,
            data: this.aSegmentados,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Plaquetas',
            fill: false,
            data: this.aPlaquetas,
            backgroundColor: '#CCAABB', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#CCAABB',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },

        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }
  createMultiLineChartEletrolito() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'Na mmol/L',
            fill: false,
            data: this.aNa,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Cl mmol/L',
            fill: false,
            data: this.aCl,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Ca mmol/L',
            fill: false,
            data: this.aCa,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'K mmol/L',
            fill: false,
            data: this.aK,
            backgroundColor: '#FF0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'P mmol/L',
            fill: false,
            data: this.aP,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Mg mmol/L',
            fill: false,
            data: this.aMg,
            backgroundColor: '#CCAABB', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#CCAABB',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },

        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartBioquimica() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      //showInLegend: false,
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'Glicemia mg/dL',
            fill: false,
            data: this.aGlicose,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
            // yValueFormatString: "$#,###k",
            // showInLegend: true,
            //markerSize: 0,
            // onclick: this.utilService.showAlertCallback("aqui", null)
          },
          {
            label: 'Ureia mg/dL',
            fill: false,
            data: this.aUreia,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Creatinina mg/dL',
            fill: false,
            data: this.aCreatinina,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Bilirrubina total mg/dL',
            fill: false,
            data: this.aBilirrubinatotal,
            backgroundColor: '#FF0000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF0000', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'Albumina mg/dL',
            fill: false,
            data: this.aAlbumina,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },


        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: false
            }
          }]
        }
      }
    });
  }

  createMultiLineChartGasometria() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      data: {
        fill: true,
        labels: this.aLabels,
        datasets: [
          {
            label: 'pH',
            fill: false,
            data: this.aPh,
            backgroundColor: '#D2691E', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#D2691E', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },
          {
            label: 'PaCO2',
            fill: false,
            data: this.aPaco2,
            backgroundColor: '#8B008B', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#8B008B', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },
          {
            label: 'PaO2',
            fill: false,
            data: this.aPao2,
            backgroundColor: '#D2691E', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#D2691E', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },
          {
            label: 'Lactato',
            fill: false,
            data: this.aLactato,
            backgroundColor: '#ADFF2F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#ADFF2F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },

          {
            label: 'HCO3',
            fill: false,
            data: this.aHco3,
            backgroundColor: '#D2691E', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#D2691E', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },
          {
            label: 'BE',
            fill: false,
            data: this.aBe,
            backgroundColor: '#E9967A', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#E9967A', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },

          {
            label: 'FiO2',
            fill: false,
            data: this.aFio2,
            backgroundColor: '#4F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#4F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },

          {
            label: 'PF',
            fill: false,
            data: this.aPf,
            backgroundColor: '#9ACD32', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#9ACD32', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },

          {
            label: 'SatO2',
            fill: false,
            data: this.aSato2,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },


        ]
      },
      // options: {
      //   scales: {
      //     yAxes: [{
      //       ticks: {
      //         beginAtZero: false
      //       }
      //     }]
      //   }
      // }
    });
  }

  createMultiLineChartVentilacao() {
    this.bars = new Chart(this.multilineChart.nativeElement, {
      type: 'line',
      backgroundColor: '#000000',
      data: {
        fill: false,
        labels: this.aLabels,
        datasets: [
          {
            label: 'PI cmH2O',
            fill: false,
            data: this.aPi,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1,
          },
          {
            label: 'PEEP cmH2O',
            fill: false,
            data: this.aPeep,
            backgroundColor: '#000080', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000080', //'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'Platô cmH2O',
            fill: false,
            data: this.aPlato,
            backgroundColor: '#DAA520', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#DAA520',//'rgb(38, 194, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          }, {
            label: 'FR ipm',
            fill: false,
            data: this.aFr,
            backgroundColor: '#FF00FF', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#FF00FF',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'FiO2 %',
            fill: false,
            data: this.aFiO2,
            backgroundColor: '#000000', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#000000',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
          {
            label: 'VC mL',
            fill: false,
            data: this.aVc,
            backgroundColor: '#2F4F4F', //'rgb(38, 194, 129)', // array should have same number of elements as number of dataset
            borderColor: '#2F4F4F',//'rgb(38, 180, 129)',// array should have same number of elements as number of dataset
            borderWidth: 1
          },
        ]
      },
      // options: {
      //   scales: {
      //     yAxes: [{
      //       ticks: {
      //         beginAtZero: false
      //       }
      //     }]
      //   }
      // }
    });
  }



}
