import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-examemedida',
  templateUrl: './examemedida.component.html',
  styleUrls: ['./examemedida.component.scss'],
})
export class ExamemedidaComponent implements OnInit {
  @ViewChild('ancoraCampo0', null) ancoraCampo0: ElementRef;
  @ViewChild('ancoraCampo1', null) ancoraCampo1: ElementRef;
  @ViewChild('ancoraCampo2', null) ancoraCampo2: ElementRef;
  @ViewChild('ancoraCampo3', null) ancoraCampo3: ElementRef;
  @ViewChild('ancoraCampo4', null) ancoraCampo4: ElementRef;
  @ViewChild('ancoraCampo5', null) ancoraCampo5: ElementRef;
  @ViewChild('ancoraCampo6', null) ancoraCampo6: ElementRef;
  @ViewChild('ancoraCampo7', null) ancoraCampo7: ElementRef;
  @ViewChild('ancoraCampo8', null) ancoraCampo8: ElementRef;
  @ViewChild('ancoraCampo9', null) ancoraCampo9: ElementRef;
  @ViewChild('ancoraCampo10', null) ancoraCampo10: ElementRef;
  @ViewChild('ancoraCampo11', null) ancoraCampo11: ElementRef;
  @ViewChild('ancoraCampo12', null) ancoraCampo12: ElementRef;
  @ViewChild('ancoraCampo13', null) ancoraCampo13: ElementRef;
  @ViewChild('ancoraCampo14', null) ancoraCampo14: ElementRef;
  @ViewChild('ancoraCampo15', null) ancoraCampo15: ElementRef;
  @ViewChild('ancoraCampo16', null) ancoraCampo16: ElementRef;
  @ViewChild('ancoraCampo17', null) ancoraCampo17: ElementRef;
  @ViewChild('ancoraCampo18', null) ancoraCampo18: ElementRef;
  @ViewChild('ancoraCampo19', null) ancoraCampo19: ElementRef;

  examemedidaForm: any
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0
  Botao:string="Cadastrar"
  medidasCollection:any=[];
  relacaoCollection:any=[];
  medidasCollection2: Array<{
    Id:number,
    Nome:string,
    Valor:number,
    Unidademedida:number,
    SiglaUnidadeMedida:string,
    Valornormal:string}> = [];
  Examerelacao:number=0;
  Exameemissao:number=0;
  orgaoCollection:any=[];
  emissaoMedidas: Array<
  {
    NomeOrgao: string,
    Examemedida:any

  }> = [];
  nomeIcone:string="add-circle-sharp";
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService:ChecklistService) {
      this.examemedidaForm = formBuilder.group({
        Campo0: ['', ''],
        Campo1: ['', ''],
        Campo2: ['', ''],
        Campo3: ['', ''],
        Campo4: ['', ''],
        Campo5: ['', ''],
        Campo6: ['', ''],
        Campo7: ['', ''],
        Campo8: ['', ''],
        Campo9: ['', ''],
        Campo10: ['', ''],
        Campo11: ['', ''],
        Campo12: ['', ''],
        Campo13: ['', ''],
        Campo14: ['', ''],
        Campo15: ['', ''],
        Campo16: ['', ''],
        Campo17: ['', ''],
        Campo18: ['', ''],
        Campo19: ['', ''],
        Campo20: ['', ''],
        Campo21: ['', ''],
        Campo22: ['', ''],
        Campo23: ['', ''],
        Campo24: ['', ''],
        Campo25: ['', ''],
        Campo26: ['', ''],
        Campo27: ['', ''],
        Campo28: ['', ''],
        Campo29: ['', ''],
  
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();

     }

  ngOnInit() {
    this.Exameemissao = this.navParams.get('Exameemissao');
    this.relacaoCollection = this.navParams.get('Examerelacao');
  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.selecionarExameOrgao();
  

  }


 async mudarSatusDiv(valor:any, x:number) {
   if (valor == true) {
    this.utilService.mostrarDiv('div' + x.toString())
   }else{
    this.utilService.esconderDiv('div' + x.toString())
   }
 }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

  async closeModal() {
    this.utilService.publishEvent("Exameemissao",  this.Exameemissao);

    this.modalCtrl.dismiss();
  }

  async limpaDados(aMedidas:any) {
    this.Botao = "Cadastrar";
    for (let i=0; i<30; i++) {
      this.examemedidaForm.controls['Campo' + i.toString()].setValue('');

    }
    setTimeout(()=>{
     
      this.selecionarExameEmissaoUnico();
      this.utilService.focusInput(this.ancoraCampo0);
    },250)
  }

  async selecionarExameRelacaoUnico() {
    this.exameService.selecionarExameRelacaoUnico(this.Examerelacao).then((response:any)=>{
      this.relacaoCollection = response;

      if (this.relacaoCollection.length > 0) {
        let aOrgaos:any;
        if (this.relacaoCollection[0].Exameorgao.indexOf(',') > 0){
          aOrgaos = this.relacaoCollection[0].Exameorgao.split(',');
        }else{
          aOrgaos = this.relacaoCollection[0].Exameorgao
        }
        this.selecionarOrgaoUnico(aOrgaos)
      }
    }
    )
  }



  async selecionarOrgaoUnico(aOrgaos:any) {
    let aMedidas:any=[];
    for (let i=0; i< this.orgaoCollection.length; i++) {
      for (let z=0; z<aOrgaos.length; z++) {
        if (parseInt(aOrgaos[z]) ==   this.orgaoCollection[i].Id ) {
          aMedidas.push(
            {
            NomeOrgao: this.orgaoCollection[i].Nome,
            RelacaoIdMedidas: this.orgaoCollection[i].Examemedida,
            RelacaoNomeMedida: [],
            RelacaoValorMedida: [],
            RelacaoCampoMedida: [],
            RelacaoUnidadeMedida: [],
            }
          )

          break;
        }
       
      }
    }

    setTimeout(()=>{
      this. selecionarExameMedida(aMedidas)
    },500)
   
  }

  async selecionarExameMedida(aMedidas:any) {
    let w:number=0;
    this.exameService.selecionarExameMedida().then((response:any)=>{
      this.medidasCollection= response;
      if ( this.medidasCollection.length > 0) {
        for (let i=0; i<aMedidas.length; i++) {
          let aMed:any  = aMedidas[i].RelacaoIdMedidas;
          if (aMed.indexOf(',') > -1) {
            aMed =  aMedidas[i].RelacaoIdMedidas.split(',');
          }else{
            aMed =  [  aMedidas[i].RelacaoIdMedidas.toString()  ]
          }
           for (let z=0; z<this.medidasCollection.length; z++) {
              for (let x=0; x<aMed.length; x++) {
                if (this.medidasCollection[z].Id == parseInt(aMed[x])) {
                  aMedidas[i].RelacaoNomeMedida.push(this.medidasCollection[z].Nome)
                  aMedidas[i].RelacaoCampoMedida.push(w)
                  aMedidas[i].RelacaoUnidadeMedida.push(this.medidasCollection[z].SiglaUnidadeMedida)
                  w++
                  break;
                }

              }
           
            }     

        }
      }

      setTimeout(()=>{
        this.medidasCollection2 = aMedidas
        this.limpaDados(aMedidas);
      },500)

    })

  }

  async selecionarExameOrgao() {
    this.exameService.selecionarExameOrgao().then((response:any)=>{
      this.orgaoCollection= response;
      if ( this.orgaoCollection.length > 0) {
        this.selecionarExameRelacaoUnico();
      }

    })

  }

 //[{"O":"BAÇO","M":"Anteroposterior","V":9,"U":"cm","C":0},{"O":"","M":"Longitudinal","V":4,"U":"cm","C":1},{"O":"","M":"Transversal","V":6,"U":"cm","C":2},{"O":"","M":"Volume","V":112.32,"U":"cm³","C":3},{"O":"FÍGADO","M":"Lobo direito do fígado","V":14.2,"U":"cm","C":4},{"O":"","M":"Lobo esquerdo do fígado","V":10.6,"U":"cm","C":5},{"O":"RIM DIREITO","M":"Anteroposterior","V":12,"U":"cm","C":7},{"O":"","M":"Longitudinal","V":4.5,"U":"cm","C":8},{"O":"","M":"Parênquima ","V":1.7,"U":"cm","C":9}]
  async MontaMedidasNome(aMedidas:any){
    for (let i=0; i<aMedidas.length; i++) {
      for (let z=0; z<this.medidasCollection.length; z++) {
        if(parseInt(this.medidasCollection[z].Id) == parseInt(aMedidas[i])) {
          this.medidasCollection2.push(
              {
              Id: this.medidasCollection[z].Id,
              Nome:this.medidasCollection[z].Nome,
              Valor:0,
              Unidademedida:this.medidasCollection[z].Unidademedida,
              SiglaUnidadeMedida:this.medidasCollection[z].SiglaUnidadeMedida,
              Valornormal:this.medidasCollection[z].Valornormal,
              })
           break;     
        }
       
      }
    }
    if ( this.medidasCollection2.length == aMedidas.length) {
      this.limpaDados( aMedidas.length);

    }

  }

  async atualizarExameEmissaoMedidaApagar() {
    for (let i=0; i<30; i++) {
      this.examemedidaForm.controls['Campo' + i.toString()].setValue('');

    }
    let form={
      Id: this.Exameemissao,
      Examemedida: ''
    }
     setTimeout(()=>{
        this.exameService.atualizarExameEmissaoMedida(form).then((response:any)=>{
          this.utilService.showAlertCallback("Medidas APAGADAS com sucesso", ()=>{
          })
        }).catch(()=>{
          this.utilService.showAlertCallback("Não foi possível APAGAR as medidas")
        })
     }, 500)
     

  }
  

  async atualizarExameEmissaoMedida() {
  
   let aMf:any=[];
   for (let i=0; i<this.medidasCollection2.length; i++) {
    let aOrgao:any = [this.medidasCollection2[i]];
    let O:string= "";
    for (let z=0; z<aOrgao.length; z++) {
      let aCampos:any = aOrgao[z].RelacaoCampoMedida;
      let aMedidas:any = aOrgao[z].RelacaoNomeMedida;
      let aUnidades:any = aOrgao[z].RelacaoUnidadeMedida;
      for (let w=0; w<aCampos.length; w++) {
        let Valor:any = eval('this.examemedidaForm.value.Campo' + aCampos[w].toString() )
        if (Valor != '' && Valor != undefined && Valor != null) {
          Valor = this.utilService.formateNumberNew(Valor,2,1);
          if (Valor.toString().indexOf('.') == -1 && Valor.toString().indexOf(',') == -1) {
            Valor= Valor.toString() + '.00'
          }
          if (w==0) {
            O= aOrgao[z].NomeOrgao.toUpperCase();
          }else{
            O= "";
          }
          let M:string= aMedidas[w];
          let V:string= Valor;
          let U:string= aUnidades[w];
          let C:string= aCampos[w];
          aMf.push({
            O: O,
            M: M,
            V: V,
            U: U,
            C: C
          })
        }
      }

    }
   }

   let form={
    Id: this.Exameemissao,
    Examemedida: JSON.stringify(aMf)
  }
   setTimeout(()=>{
      this.exameService.atualizarExameEmissaoMedida(form).then((response:any)=>{
        this.utilService.showAlertCallback("Medidas ATUALIZADAS com sucesso", ()=>{
        })
      }).catch(()=>{
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR as medidas")
      })
   }, 500)
   


  }

  async selecionarExameEmissaoUnico() {
    this.exameService.selecionarExameEmissaoUnico(this.Exameemissao).then((response:any)=>{
      let C:string="";
      let V:number=0;
      let aVet:any=[];
      if (response[0].Examemedida == '' || response[0].Examemedida == undefined || response[0].Examemedida == null){
        return;
      }
      aVet = JSON.parse(response[0].Examemedida);
      for (let i=0; i<aVet.length; i++) {
        C= aVet[i].C;
        V= aVet[i].V;
        this.examemedidaForm.controls['Campo' + C].setValue(V);
      }
    })
  }

  async calculaVolume(campo:number) {

    // para avaliar os campos antes do volume
    let Campo0:number = campo -3;
    let Campo1:number = campo -2;
    let Campo2:number = campo -1;
    let x:number = eval('this.examemedidaForm.value.Campo' + Campo0.toString());
    let y:number =eval('this.examemedidaForm.value.Campo' + Campo1.toString());
    let z:number = eval('this.examemedidaForm.value.Campo' + Campo2.toString());
    let v:number= x*y*z*0.52;
    v = this.utilService.formateNumberNew(v,2);
    this.examemedidaForm.controls['Campo' + campo.toString()].setValue(v);


  }

}
