import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { EvolucaoService } from '../../services/evolucao.service';
import { LancamentoService } from '../../services/lancamento.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-ecocardio',
  templateUrl: './ecocardio.component.html',
  styleUrls: ['./ecocardio.component.scss'],
})
export class EcocardioComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDescricao', null) ancoraDescricao: ElementRef;
  @ViewChild('ancoraDvcii', null) ancoraDvcii: ElementRef;
  @ViewChild('ancoraDvcie', null) ancoraDvcie: ElementRef;
  @ViewChild('ancoraCvci', null) ancoraCvci: ElementRef;
  @ViewChild('ancoraDvsve', null) ancoraDvsve: ElementRef;
  @ViewChild('ancoraVtivsve', null) ancoraVtivsve: ElementRef;
  @ViewChild('ancoraVtiaorta', null) ancoraVtiaorta: ElementRef;
  @ViewChild('ancoraVtimitral', null) ancoraVtimitral: ElementRef;
  @ViewChild('ancoraEmitral', null) ancoraEmitral: ElementRef;
  @ViewChild('ancoraAmitral', null) ancoraAmitral: ElementRef;
  @ViewChild('ancoraRtricuspide', null) ancoraRtricuspide: ElementRef;
  @ViewChild('ancoraPeso', null) ancoraPeso: ElementRef;
  @ViewChild('ancoraAltura', null) ancoraAltura: ElementRef;
  @ViewChild('ancoraBsa', null) ancoraBsa: ElementRef;
  @ViewChild('ancoraVs', null) ancoraVs: ElementRef;
  @ViewChild('ancoraDae', null) ancoraDae: ElementRef;
  @ViewChild('ancoraDad', null) ancoraDad: ElementRef;
  @ViewChild('ancoraDved', null) ancoraDved: ElementRef;
  @ViewChild('ancoraDves', null) ancoraDves: ElementRef;
  @ViewChild('ancoraDao', null) ancoraDao: ElementRef;
  @ViewChild('ancoraSiv', null) ancoraSiv: ElementRef;
  @ViewChild('ancoraDpp', null) ancoraDpp: ElementRef;
  @ViewChild('ancoraVsfve', null) ancoraVsfve: ElementRef;
  @ViewChild('ancoraVdfve', null) ancoraVdfve: ElementRef;
  @ViewChild('ancoraFrequencia', null) ancoraFrequencia: ElementRef;
  @ViewChild('ancoraDc', null) ancoraDc: ElementRef;
  @ViewChild('ancoraIc', null) ancoraIc: ElementRef;
  @ViewChild('ancoraFracaoejecao', null) ancoraFracaoejecao: ElementRef;
  @ViewChild('ancoraPressaoarteriapulmonar ', null) ancoraPressaoarteriapulmonar: ElementRef;
  Header: string = "";
  Usuario: number = 0;
  Paciente: number = 0;
  Idade: string = "";
  Nome: string = "";
  Internacao: number = 0;
  Historiaclinica: string = "";
  Setor: string = "";
  Leito: any = "";
  ecocardioForm: FormGroup;
  Botao: string = "CALCULAR";
  ecocardioColection: any = [];
  Descricao: string = ""
  Dvcie: number = 0;
  Dvcii: number = 0;
  Cvci: number = 0
  Dvsve: number = 0;
  Vtivsve: number = 0;
  Vtiaorta: number = 0;
  Vtimitral: number = 0;
  Emitral: number = 0;
  Amitral: number = 0;
  Rtricuspide: number = 0;
  Datainternacao: string = "";
  Dias: string = "";
  dadosListMed: any;
  Logado: number = 0;
  Id: number = -1;
  NomePaciente: string = "";
  FotoAtualPaciente: string = ''
  Calculo: string = '';
  Sigla: string = '';
  Componente: string = '';
  Valornormal: string = '';
  Descricaounidade: string = '';
  Descricaocalculo: string = '';
  Observacao: string = '';
  Valorminimo: string = '';
  Valormaximo: string;
  Pressaoarteriapulmonar: any = 0;
  Fracaoejecao: any = 0;
  Trombo: any = 1;
  Lesaovalvar: any = 1;
  Disfuncao: any = 1;
  Volumecamara: any = 1;
  aTrombo: any;
  aLesaovalvar: any;
  aDisfuncao: any;
  aVolumecamara: any;
  Sexo: number;
  Peso: any;
  Altura: any;
  Bsa: any;

  Dae: any;
  Dad: any;
  Dved: any;
  Dves: any;
  Dao: any;
  Siv: any;
  Dpp: any;
  Vsfve: any;
  Vdfve: any;
  Frequencia: any;
  Dc: any;
  Ic: any;
  Vs: any;
  Pvci: number = 0;
  Pvcf: number = 0;
  Pvcm: number = 0;
  Idvci: any = 0;
  Avsve: any = 0;
  Ava: any = 0;
  Avm: any = 0;
  PAPmC: any = 0;
  PAPmS: any = 0;
  Rea: any = 0;
  Datalancamento: any;
  Raeao: any = 0;
  Resultado:string="";
  constructor(
    private evolucaoService: EvolucaoService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private alertController: AlertController,
    private checklistservice: ChecklistService,
    private checklistService:ChecklistService
  ) {
    this.ecocardioForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Cvci: ['', ''],
      Dvcie: ['', ''],
      Dvcii: ['', ''],
      Dvsve: ['', ''],
      Vtivsve: ['', ''],
      Vs: ['', ''],
      Vtiaorta: ['', ''],
      Vtimitral: ['', ''],
      Emitral: ['', ''],
      Amitral: ['', ''],
      Rtricuspide: ['', ''],
      Pressaoarteriapulmonar: ['', ''],
      Fracaoejecao: ['', ''],
      Trombo: ['', ''],
      Lesaovalvar: ['', ''],
      Disfuncao: ['', ''],
      Volumecamara: ['', ''],
      Dae: ['', ''],
      Dad: ['', ''],
      Dved: ['', ''],
      Dves: ['', ''],
      Dao: ['', ''],
      Siv: ['', ''],
      Dpp: ['', ''],
      Vsfve: ['', ''],
      Vdfve: ['', ''],
      Frequencia: ['', ''],
      Peso: ['', ''],
      Altura: ['', ''],
      Sexo: ['', ''],
      Bsa: ['', ''],
      Dc: ['', ''],
      Ic: ['', ''],
      Descricao: ['', ''],
      Idvci: ['', ''],
      Avsve: ['', ''],
      Raeao: ['', ''],
      Ava: ['', ''],
      Rea: ['', ''],
      Avm: ['', ''],
      PAPmC: ['', ''],
      PAPmS: ['', ''],
      Observacao: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.Internacao = this.navParams.get('Internacao');
    this.Paciente = this.navParams.get('Internacao');
    this.Calculo = this.navParams.get('Calculo');
    this.Sigla = this.navParams.get('Sigla');
    this.Nome = this.navParams.get('Nome');
    this.Componente = this.navParams.get('Componente');
    this.Valornormal = this.navParams.get('Valornormal');
    this.Descricaounidade = this.navParams.get('Internacao');
    this.Descricaocalculo = this.navParams.get('Internacao');
    this.Observacao = this.navParams.get('Observacao');
    this.Valorminimo = this.navParams.get('Valorminimo');
    this.Valormaximo = this.navParams.get('Valormaximo');
    this.Observacao = this.navParams.get('Observacao');
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Altura = this.navParams.get('Altura');
    this.Peso = this.navParams.get('Peso');
    this.Bsa = this.navParams.get('Bsa');
    this.Usuario = parseInt(this.navParams.get('Usuario'));
    this.Peso = this.utilService.formateNumberNew(this.Peso, 2,1);
    this.Bsa = this.utilService.formateNumberNew(this.Bsa, 2);
    if (this.Peso == '0.0') {
      this.Peso = '0'
    }
  }
  ngOnInit() {
    this.aTrombo = [
      { Id: 1, Nome: 'sem achados' },
      { Id: 2, Nome: 'Trombo em AD' },
      { Id: 3, Nome: 'Trombo em VD' },
      { Id: 4, Nome: 'Trombo em AE' },
      { Id: 5, Nome: 'Trombo em VE' },
      { Id: 6, Nome: 'Trombo na AP' },
      { Id: 7, Nome: 'Trombo na AO' },
      { Id: 8, Nome: 'Vegetação em AD' },
      { Id: 9, Nome: 'Vegetacao em VD' },
      { Id: 10, Nome: 'Vegetação em AE' },
      { Id: 11, Nome: 'Vegetação em VE' },
      { Id: 12, Nome: 'Vegetação na AP' },
      { Id: 13, Nome: 'Vegetação na AO' },
      { Id: 14, Nome: 'Vegetação em VM' },
      { Id: 15, Nome: 'Vegetação em VT' },
      { Id: 16, Nome: 'Vegetação na VA' },
      { Id: 17, Nome: 'Vegetação na VP' },
    ];

    this.aLesaovalvar = [
      { Id: 1, Nome: 'sem achados' },
      { Id: 2, Nome: 'Estenose mitral' },
      { Id: 3, Nome: 'Insuficiência mitral' },
      { Id: 4, Nome: 'Estenose tricúspide' },
      { Id: 5, Nome: 'Insuficiência tricúspide' },
      { Id: 6, Nome: 'Estenose aórtica' },
      { Id: 7, Nome: 'Insuficiência aórtica' },
      { Id: 8, Nome: 'Estenose pulmonar' },
      { Id: 9, Nome: 'Insuficiência pumonar' },
      { Id: 10, Nome: 'Estenose pumonar' },
    ];

    this.aDisfuncao = [
      { Id: 1, Nome: 'sem achados' },
      { Id: 2, Nome: 'Apical de VE' },
      { Id: 3, Nome: 'Parede anterior de VE' },
      { Id: 4, Nome: 'Parede lateral de VE' },
      { Id: 5, Nome: 'Parede septal de VE' },
      { Id: 6, Nome: 'Global de VD' },
      { Id: 7, Nome: 'Global de AD' },
      { Id: 8, Nome: 'Global de AE' },
      { Id: 9, Nome: 'Global de VE' },
      { Id: 10, Nome: 'Global de todas as câmaras' },
    ];

    this.aVolumecamara = [
      { Id: 1, Nome: 'Normais' },
      { Id: 2, Nome: '↑ de VE' },
      { Id: 3, Nome: '↑ de VD com VE/VD >0.6' },
      { Id: 4, Nome: '↑ de VD com septo paradoxal' },
      { Id: 5, Nome: '↑ de AD' },
      { Id: 6, Nome: '↑ de AE' },
      { Id: 7, Nome: '↑ de AO' },
      { Id: 8, Nome: '↑ de AP' },
    ]
  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.limpaDados();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

   async selecionarSexo(sexo:number) {
    this.Sexo = sexo
   }

   async inserirDados() {
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.presentAlertConfirm()
    }
    this.Resultado = "";
    let cSexo = "Não informado";
    if (this.Sexo ==1) {
      cSexo = "Masculino";
    }else if  (this.Sexo == 2){
      cSexo = "Feminino";
    }

    let cIdade = "Não informado";
    if (this.Idade != null) {
      cIdade = this.Idade + ' anos';
    }

    this.Resultado =   this.Resultado + "Sexo: " +cSexo + '\n';
    this.Resultado =   this.Resultado + "Idade: " + cIdade + '\n';
    this.Resultado =   this.Resultado + "Peso: " + this.Peso +' kg\n' ;
    this.Resultado =   this.Resultado + "Altura: " + this.Altura +' cm\n';
    this.Resultado =   this.Resultado + "Bsa: " + this.Bsa +' m²\n';
    this.Resultado =   this.Resultado + "IDVCI: " + this.Idvci +' %\n';
    this.Resultado =   this.Resultado + "PVC estimada: " + this.Pvcm +' mmHg\n';
    this.Resultado =   this.Resultado + "DVSVE: " + this.Dvsve +' cm\n';
    this.Resultado =   this.Resultado + "AVSVE: " + this.Avsve +' cm²\n';
    this.Resultado =   this.Resultado + "VTIVSVE: " + this.Vtivsve +' cm\n';
    this.Resultado =   this.Resultado + "VS: " + this.Vs +' mL\n';
    this.Resultado =   this.Resultado + "FC: " + this.Frequencia +' bpm\n';
    this.Resultado =   this.Resultado + "DC: " + this.Dc +'L/min\n';
    this.Resultado =   this.Resultado + "IC: " + this.Dc +' L/min/m²\n';
    this.Resultado =   this.Resultado + "FE: " + this.Fracaoejecao +' %\n';
    this.Resultado =   this.Resultado + "AVM: " + this.Avm +' cm²\n';
    this.Resultado =   this.Resultado + "Relação E/A: " + this.Rea +'\n';
    this.Resultado =   this.Resultado + "AVA: " + this.Ava +' cm²\n';

   }

   async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarEcocardio(-1);
        if (this.Paciente > 0 && this.Internacao > 0) {
          this.Botao = "CADASTRAR"
        } else {
          this.Botao = "CALCULAR"
        }
      }

    }
  }

  async limpaDados() {
    let dtHoje = this.utilService.dataHoje();
    this.ecocardioForm.controls['Datalancamento'].setValue(dtHoje);
    this.Datalancamento = dtHoje;
    this.Botao = "CALCULAR";
    let Datalancamento = this.utilService.getDataBrowser(dtHoje);
    this.ecocardioForm.controls['Datalancamento'].setValue(Datalancamento);
    this.ecocardioForm.controls['Descricao'].setValue('');
    this.ecocardioForm.controls['Dvcie'].setValue('');
    this.ecocardioForm.controls['Dvcii'].setValue('');
    this.ecocardioForm.controls['Cvci'].setValue('');
    this.ecocardioForm.controls['Dvsve'].setValue('');
    this.ecocardioForm.controls['Vtivsve'].setValue('');
    this.ecocardioForm.controls['Vtiaorta'].setValue('');
    this.ecocardioForm.controls['Vtimitral'].setValue('');
    this.ecocardioForm.controls['Emitral'].setValue('');
    this.ecocardioForm.controls['Amitral'].setValue('');
    this.ecocardioForm.controls['Rtricuspide'].setValue('');
    this.ecocardioForm.controls['Pressaoarteriapulmonar'].setValue('');
    this.ecocardioForm.controls['Fracaoejecao'].setValue('');
    this.ecocardioForm.controls['Trombo'].setValue(this.Trombo);
    this.ecocardioForm.controls['Lesaovalvar'].setValue(this.Lesaovalvar);
    this.ecocardioForm.controls['Disfuncao'].setValue(this.Disfuncao);
    this.ecocardioForm.controls['Volumecamara'].setValue(this.Volumecamara);
    this.Id = -1;
    this.Bsa = this.utilService.formateNumberNew(this.Bsa,2,1);
    this.Peso= this.utilService.formateNumberNew(this.Peso, 3,1);
    this.ecocardioForm.controls['Sexo'].setValue(this.Sexo);
    this.ecocardioForm.controls['Peso'].setValue(this.Peso);
    this.ecocardioForm.controls['Altura'].setValue(this.Altura);
    this.ecocardioForm.controls['Bsa'].setValue(this.Bsa);
    this.utilService.focusInput(this.ancoraDvcii);
  }

  async calculaBsa() {
    let Sexo: any = this.ecocardioForm.value.Sexo;
    if (Sexo == '' || Sexo == null || Sexo == undefined) {
      this.utilService.showAlertCallback('SEXO inválido',
        () => {
          this.utilService.focusInput(this.ancoraPeso)
        }
      );
      return false;
    }
    let Peso: number = this.ecocardioForm.value.Peso;
    Peso = this.utilService.formateNumberNew(Peso,3,1);
    if (Peso == 0 || Peso == null || Peso == undefined) {
      this.utilService.showAlertCallback('PESO inválido',
        () => {
          this.utilService.focusInput(this.ancoraPeso)
        }
      );
      return false;
    }
    let Altura: number = this.ecocardioForm.value.Altura;
    Altura = this.utilService.formateNumberNew(Altura,0);

    if (Altura == 0 || Altura == null || Altura == undefined) {
      this.utilService.showAlertCallback('ALTURA inválido',
        () => {
          this.utilService.focusInput(this.ancoraAltura)
        }
      );
      return false;
    }
    //let Bsa:any = ((Altura+Peso)-60)/100 // outras formula
    let a: any = Math.pow(Altura, 0.725);
    let b: any = Math.pow(Peso, 0.425);
    let Bsa: any = 0.007184 * a * b;
    Bsa = this.utilService.formateNumberNew(Bsa,2,1);
    this.Bsa = Bsa;
    this.ecocardioForm.controls['Bsa'].setValue(Bsa);

  }

  async calculaVolumeSistolico() {
    let Dvsve: any = this.ecocardioForm.value.Dvsve;
    Dvsve = this.utilService.formateNumberNew(Dvsve,2,1);
    if (Dvsve == 0 || Dvsve == null || Dvsve == undefined) {
      this.utilService.showAlertCallback('DVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraDvsve)
        }
      );
      return;
    }
    let Vtivsve: number = this.ecocardioForm.value.Vtivsve;
    Vtivsve = this.utilService.formateNumberNew(Vtivsve,2,1);
    if (Vtivsve == 0 || Vtivsve == null || Vtivsve == undefined) {
      this.utilService.showAlertCallback('VTIVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtivsve)
        }
      );
      return;
    }

    let AreaVsve: any = Math.pow(Dvsve, 2) * 0.785;
    AreaVsve = AreaVsve.toString().split('.');
    AreaVsve = AreaVsve[0] + '.' + AreaVsve[1].substring(0, 2);
    this.Avsve = parseFloat(AreaVsve);
    
    let Vs: any = AreaVsve * Vtivsve;
    if (Vs.toString().indexOf('.') > -1) {
      Vs = Vs.toString().split('.');
      Vs = Vs[0] + '.' + Vs[1].substring(0, 2);
    }
    this.Vs = parseFloat(Vs);
  }

  async calculaDebitoCardiaco() {
    this.Bsa = this.utilService.formateNumberNew(this.Bsa,2,1);
    
    if (this.ecocardioForm.value.Bsa == '' || this.Bsa == 0 || this.Bsa == '' || this.Bsa == null || this.Bsa == undefined) {
      this.utilService.showAlertCallback('BSA inválido', () => {
        this.utilService.focusInput(this.ancoraBsa);
        return;
      })
    }
    this.Vs = this.utilService.formateNumberNew(this.ecocardioForm.value.Vs,2,1);
    if (this.ecocardioForm.value.Vs == '' || this.Vs == 0 || this.Vs == '' || this.Vs == null || this.Bsa == undefined) {
      this.utilService.showAlertCallback('VS inválido', () => {
        this.utilService.focusInput(this.ancoraVs);
        return;
      })
    }
    let Frequencia: any = this.ecocardioForm.value.Frequencia;
    if (Frequencia == '' || Frequencia == 0 || Frequencia == null || Frequencia == undefined) {
      this.Frequencia = 0;
      this.utilService.showAlertCallback("FREQUENCIA CARDÍACA inválido",
        () => {
          this.utilService.focusInput(this.ancoraFrequencia);
        }
      );
      return;
    }
    let Dc: any = (this.Vs * Frequencia) / 1000;
    if (Dc.toString().indexOf('.') > -1) {
      Dc = Dc.toString().split('.');
      Dc = Dc[0] + '.' + Dc[1].substring(0, 2);
    }

    this.Dc = parseFloat(Dc);
    let Ic: any = this.Dc / this.Bsa;
    Ic = this.utilService.formateNumberNew(Ic,2,1);
    this.Ic = Ic;
  }

  async calculaFracaoejecao() {
    let Dves: any = this.ecocardioForm.value.Dves;
    Dves = this.utilService.formateNumberNew(Dves,2,1);
    if (Dves == '' || Dves == 0 || Dves == null || Dves == undefined) {
      this.Dves = 0;
      this.utilService.showAlertCallback("Diâmetro do VE na sistóle inválido",
        () => {
          this.utilService.focusInput(this.ancoraDves);
        }
      );
      return;
    }
    let Dved: any = this.ecocardioForm.value.Dved;
    Dved = this.utilService.formateNumberNew(Dved,2,1);
    if (Dved == '' || Dved == 0 || Dved == null || Dved == undefined) {
      this.Dved = 0;
      this.utilService.showAlertCallback("Diâmetro do VE na diástole inválido",
        () => {
          this.utilService.focusInput(this.ancoraDved);
        })
      return;
    }

    // VSFVE(ml) =  (7 X DVES³) / (2,4 + DVES)
    // VDFVE(ml) =  (7 X DVED³) / (2,4 + DVED)
    // FE(%) = [(VDFVE – VSFVE)/ VDFVE ] * 100
    Dves = parseFloat(Dves);
    Dved = parseFloat(Dved);
    let Vsfve: any = (7 * Math.pow(Dves, 3)) / (2.4 + Dves);
    let Vdfve: any = (7 * Math.pow(Dved, 3)) / (2.4 + Dved);
    let Fracaoejecao: any = ((Vdfve - Vsfve) / Vdfve) * 100
    this.Fracaoejecao = parseInt(Fracaoejecao);
    this.Vdfve = parseInt(Vdfve);
    this.Vsfve = parseInt(Vsfve);
  }

  async calculaAreaVA() {
    let Vtivsve: number = this.ecocardioForm.value.Vtivsve;
    Vtivsve = this.utilService.formateNumberNew(Vtivsve,2,1);
    if (Vtivsve == 0 || Vtivsve == null || Vtivsve == undefined) {
      this.utilService.showAlertCallback('VTIVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtivsve)
        }
      );
      return;
    }

    let Dao: number = this.ecocardioForm.value.Dao;
    Dao = this.utilService.formateNumberNew(Dao,2,1);
    if (Dao == 0 || Dao == null || Dao == undefined) {
      this.utilService.showAlertCallback('Diâmetro da AORTA inválido',
        () => {
          this.utilService.focusInput(this.ancoraDao)
        }
      );
      return;
    }

    let Vtiaorta: any = this.ecocardioForm.value.Vtiaorta;
    Vtiaorta = this.utilService.formateNumberNew(Vtiaorta,2,1);
    if (Vtiaorta == '' || Vtiaorta == 0 || Vtiaorta == null || Vtiaorta == undefined) {
      this.utilService.showAlertCallback('VTI AORTA inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtiaorta);
        }
      );
      return;
    }
    if (this.Avsve == 0) {
      this.utilService.showAlertCallback('Area da VSVE não calculado',
        () => {
          this.utilService.focusInput(this.ancoraDvsve);
        }
      );
      return;
    }

    let Ava: any = (this.Avsve * Vtivsve) / Vtiaorta

    if (Ava.toString().indexOf('.') > -1) {
      Ava = Ava.toString().split('.');
      Ava = Ava[0] + '.' + Ava[1].substring(0, 2);
    }
    this.Ava = parseFloat(Ava);
  }

  async calculaRelacaoEA() {
    let Emitral: any = this.ecocardioForm.value.Emitral;
    Emitral = this.utilService.formateNumberNew(Emitral,2,1);
    if (Emitral == '' || Emitral == 0 || Emitral == null || Emitral == undefined) {
      this.utilService.showAlertCallback('Onda E mitral inválido',
        () => {
          this.utilService.focusInput(this.ancoraEmitral);
        });
      return;
    }

    let Amitral: any = this.ecocardioForm.value.Amitral;
    Amitral = this.utilService.formateNumberNew(Amitral,2,1);
    if (Amitral == '' || Amitral == 0 || Amitral == null || Amitral == undefined) {
      this.utilService.showAlertCallback('Onda A mitral inválido',
        () => {
          this.utilService.focusInput(this.ancoraAmitral);
        });
      return;
    }
    let Rea: any = Emitral / Amitral;
    if (Rea.toString().indexOf('.') > -1) {
      Rea = Rea.toString().split('.');
      Rea = Rea[0] + '.' + Rea[1].substring(0, 2);
    }
    this.Rea = Rea;
  }

  async calculaAreaVM() {
    let Vtivsve: number = this.ecocardioForm.value.Vtivsve;
    Vtivsve = this.utilService.formateNumberNew(Vtivsve,2,1);
    if (Vtivsve == 0 || Vtivsve == null || Vtivsve == undefined) {
      this.utilService.showAlertCallback('VTIVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtivsve)
        }
      );
      return;
    }
    let Vtimitral: any = this.ecocardioForm.value.Vtimitral;
    Vtimitral = this.utilService.formateNumberNew(Vtimitral,2,1);
    if (Vtimitral == '' || Vtimitral == 0 || Vtimitral == null || Vtimitral == undefined) {
      this.utilService.showAlertCallback('VTI MITRAL inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtimitral);
        });
      return;
    }
    let Avm: any = (this.Avsve * Vtivsve) / Vtimitral
    if (Avm.toString().indexOf('.') > -1) {
      Avm = Avm.toString().split('.');
      Avm = Avm[0] + '.' + Avm[1].substring(0, 2);
    }
    this.Avm = parseFloat(Avm);
    this.calculaRelacaoEA();
  }

  async calculaPmAPS() {
    //PAPm=0,61*PAPs + 2
    // PAPs = refluxo tricúspide + PVC via VCI
    // Calculo pela PRESSAO SISTOLICA
    let Pressaoarteriapulmonar: any = this.ecocardioForm.value.Pressaoarteriapulmonar;
    Pressaoarteriapulmonar = this.utilService.formateNumberNew(Pressaoarteriapulmonar,2,1);
    if (Pressaoarteriapulmonar == '' || Pressaoarteriapulmonar == 0 || Pressaoarteriapulmonar == null || Pressaoarteriapulmonar == undefined) {
      this.Pressaoarteriapulmonar = 0;
      this.utilService.showAlertCallback("Pressao da ARTERIA PULMONAR inválido",
        () => {
          this.utilService.focusInput(this.ancoraPressaoarteriapulmonar);
        });
      return;
    }
    let PAPmS: any = (0.61 * Pressaoarteriapulmonar) + 2

    PAPmS = this.utilService.formateNumberNew(PAPmS,2,1);
    this.PAPmS = PAPmS;
  }

  async calculaPmAPC() {
    //PAPm=0,61*PAPs + 2
    // PAPs = refluxo tricúspide + PVC via VCI
    // Calculo pela Cava
    let Rtricuspide: any = this.ecocardioForm.value.Rtricuspide;
    Rtricuspide = this.utilService.formateNumberNew(Rtricuspide,2,1);
    if (Rtricuspide == '' || Rtricuspide == 0 || Rtricuspide == null || Rtricuspide == undefined) {
      this.utilService.showAlertCallback('Refluxo tricúspede inválido',
        () => {
          this.utilService.focusInput(this.ancoraRtricuspide);
        });
      return;
    }
    if (this.Pvcm == 0 || this.Pvcm == null || this.Pvcm == undefined) {
      this.utilService.showAlertCallback('PVC ainda não calculada. Faça isso agora',
        () => {
          this.utilService.focusInput(this.ancoraDvcii);
        });
      return;
    }
    let Pressaoarteriapulmonar: any = 0;
    Pressaoarteriapulmonar = Rtricuspide + this.Pvcm
    let PAPmC: any = (0.61 * Pressaoarteriapulmonar) + 2
    PAPmC = this.utilService.formateNumberNew(PAPmC,2,1);
    this.PAPmC = PAPmC;
  }

  async calculaRelacaoAeAo() {
    let Dae: any = this.ecocardioForm.value.Dae;
    Dae = this.utilService.formateNumberNew(Dae,2,1);
    if (Dae == '' || Dae == 0 || Dae == null || Dae == undefined) {
      this.Dae = 0;
      this.utilService.showAlertCallback("Diâmetro do ÁTRIO esquerdo inválido",
        () => {
          this.utilService.focusInput(this.ancoraDae);
        }
      );
      return;
    }
    let Dao: any = this.ecocardioForm.value.Dao;
    Dao = this.utilService.formateNumberNew(Dao,2,1);
    if (Dao == '' || Dao == 0 || Dao == null || Dao == undefined) {
      this.Dao = 0;
      this.utilService.showAlertCallback("Diâmetro da AORTA inválido",
        () => {
          this.utilService.focusInput(this.ancoraDao);
        }
      );
      return;
    }
    let Raeao: any = Dae / Dao;
    Raeao = this.utilService.formateNumberNew(Raeao,2,1);
    this.Raeao = Raeao;
  }
  async calculaCava() {
    let Cvci: any = this.ecocardioForm.value.Cvci;
    if (Cvci == null || Cvci == undefined ) {
      this.utilService.showAlertCallback('Percentual de colapso da VCI inválido. Escolha agora',
        () => {
          this.utilService.focusInput(this.ancoraDvcii)
        }
      );
      return;
    }
    let Dvcii: any = this.ecocardioForm.value.Dvcii;

    Dvcii = this.utilService.formateNumberNew(Dvcii,2,1);

    if (Dvcii == '' || Dvcii == null || Dvcii == undefined) {
      this.utilService.showAlertCallback('Diametro da VCI MENOR (VM na expiração.',
        () => {
          this.utilService.focusInput(this.ancoraDvcii)
        }
      );
      return;
    }
    let Dvcie: number = this.ecocardioForm.value.Dvcie;
    Dvcie = this.utilService.formateNumberNew(Dvcie,2,1);

    if (Dvcii > Dvcie) {
      this.utilService.showAlertCallback('Diametro da VCI INICIAL não pode ser MAIOR que o FINAL',
        () => {
          this.utilService.focusInput(this.ancoraDvcie)
        }
      );
      return;
    }

    if (Dvcie == 0 || Dvcie == null || Dvcie == undefined) {
      this.utilService.showAlertCallback('Diametro da VCI MAIOR (VM na inspiração',
        () => {
          this.utilService.focusInput(this.ancoraDvcie)
        }
      );
      return;
    }
    
    this.Idvci = ((Dvcie - Dvcii) / Dvcii) * 100
    this.Idvci = this.utilService.formateNumberNew(this.Idvci,2,1);
    let Pvc: string = '';
    if (Dvcie < 1.5) {
      if (Cvci == 100) {
        Pvc = "0 a 5 mmHg";
        this.Pvci = 0;
        this.Pvcf = 5;
        this.Pvcm = 2.5;
      } else {
        this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido 1',
          () => {
            this.utilService.focusInput(this.ancoraDvcie)
          }
        );
        return;
      }
    }
    if (Dvcie >= 1.5 && Dvcie <= 2.5) {
      if (Cvci == 50) {
        Pvc = "6 a 10 mmHg";
        this.Pvci = 6;
        this.Pvcf = 10;
        this.Pvcm = 8;
      }
      else if (Cvci == -50) {
        Pvc = "11 a 15 mmHg";
        this.Pvci = 11;
        this.Pvcf = 15;
        this.Pvcm = 13;
      } else {
        this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido 2',
          () => {
            this.utilService.focusInput(this.ancoraDvcie)
          }
        );
        return;
      }
    }
    if (Dvcie > 2.5) {
      if (Cvci == -50) {
        Pvc = "16 a 20 mmHg";
        this.Pvci = 16;
        this.Pvcf = 20;
        this.Pvcm = 18;
      }
      else if (Cvci == 0) {
        Pvc = "> 20 mmHg";
        this.Pvci = 21;
        this.Pvcf = 29;
        this.Pvcm = 25;
      } else {
        this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido 3',
          () => {
            this.utilService.focusInput(this.ancoraDvcie)
          }
        );
        return;
      }
    }
  }

  async selecionarEcocardio(Id:number) {
    this.lancamentoService.selecionarEcocardio(this.Internacao, this.Paciente).then((response) => {
      this.ecocardioColection = response;
      this.Id = Id;

    }).catch((response) => {
    });
  }
  async selecionarEcocardioUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }

    this.lancamentoService.selecionarEcocardioUnico(Id).then((response: any) => {
      if (response.length > 0) {
        this.getEcocardio(response);
      }
    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível selecionar o item", 2000, null);
    });
  }
  async getEcocardio(response: any) {
    this.Botao = "ALTERAR";
    this.Id = response[0].Id;
    let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.ecocardioForm.controls['Datalancamento'].setValue(Datalancamento);
  
    this.ecocardioForm.controls['Descricao'].setValue(response[0].Descricao);
    
    // inteiro
    this.ecocardioForm.controls['Frequencia'].setValue(response[0].Frequencia);

    //


    let Cvci: any = response[0].Cvci;
    
    Cvci = this.utilService.formateNumberNew(Cvci,2,1);
    this.Cvci = parseInt(Cvci);
   

    let Dvcie: any = response[0].Dvcie;
    Dvcie = this.utilService.formateNumberNew(Dvcie,2,1);
    this.Dvcie = Dvcie;
    
    let Dvcii: any = response[0].Dvcii;
    Dvcii = this.utilService.formateNumberNew(Dvcii,2,1);
    this.Dvcii = Dvcii;

    let Pvcm: any = response[0].Pvc;
    Pvcm = this.utilService.formateNumberNew(Pvcm,2,1);



    this.Pvcm = Pvcm;
    this.Pvci = 0;
    this.Pvcf = 30;

    let Dvsve: any = response[0].Dvsve;
    Dvsve = this.utilService.formateNumberNew(Dvsve,2,1);
    this.Dvsve = Dvsve;

    let Vtivsve: any = response[0].Vtivsve
    Vtivsve = this.utilService.formateNumberNew(Vtivsve,2,1);
    this.Vtivsve = Vtivsve;

    let Vtiaorta: any = response[0].Vtiaorta;
    Vtiaorta = this.utilService.formateNumberNew(Vtiaorta,2,1);
    this.Vtiaorta = Vtiaorta;

    let Vtimitral: any = response[0].Vtimitral;
    Vtimitral = this.utilService.formateNumberNew(Vtimitral,2,1);
    this.Vtimitral = Vtimitral;

    let Emitral: any = response[0].Emitral;
    Emitral = this.utilService.formateNumberNew(Emitral,2,1);
    this.Emitral = Emitral;
    
    let Amitral: any = response[0].Amitral;
    Amitral = this.utilService.formateNumberNew(Amitral,2,1);
    this.Amitral = Amitral;

    let Rtricuspide: any = response[0].Rtricuspide;
    Rtricuspide = this.utilService.formateNumberNew(Rtricuspide,2,1);
    this.Rtricuspide = Rtricuspide;

    let Pressaoarteriapulmonar: any = response[0].Pressaoarteriapulmonar;
    Pressaoarteriapulmonar = this.utilService.formateNumberNew(Pressaoarteriapulmonar,2,1);
    this.Pressaoarteriapulmonar = Pressaoarteriapulmonar;

    let Dae: any = response[0].Dae;
    Dae = this.utilService.formateNumberNew(Dae,2,1);
    this.Dae = Dae;

    let Dad: any = response[0].Dad;
    Dad = this.utilService.formateNumberNew(Dad,2,1);
    this.Dad = Dad;

    let Dved: any = response[0].Dved;
    Dved = this.utilService.formateNumberNew(Dved,2,1);
    this.Dved = Dved;

    let Dves: any = response[0].Dves;
    Dves = this.utilService.formateNumberNew(Dves,2,1);
    this.Dves = Dves;

    let Dao: any = response[0].Dao;
    Dao = this.utilService.formateNumberNew(Dao,2,1);
    this.Dao = Dao;

    let Siv: any = response[0].Siv;
    Siv = this.utilService.formateNumberNew(Siv,2,1);
    this.Siv = Siv;

    let Dpp: any = response[0].Dpp;
    Dpp = this.utilService.formateNumberNew(Dpp,2,1);
    this.Dpp = Dpp;

    let Vsfve: any = response[0].Vsfve;
    Vsfve = this.utilService.formateNumberNew(Vsfve,2,1);
    this.Vsfve = Vsfve;

    let Vdfve: any = response[0].Vdfve;
    Vdfve = this.utilService.formateNumberNew(Vdfve,2,1);
    this.Vdfve = Vdfve;

    let Peso: any = response[0].Peso;
    Peso = this.utilService.formateNumberNew(Peso,2,1);
    this.Peso = Peso;

    let Altura: any = response[0].Altura;
    Altura = this.utilService.formateNumberNew(Altura,0,1);
    this.Altura = Altura;

    let Bsa: any = response[0].Bsa;
    Bsa = this.utilService.formateNumberNew(Bsa,2,1);
    this.Bsa = Bsa;

    let Vs: any = response[0].Vs;
    Vs = this.utilService.formateNumberNew(Vs,2,1);
    this.Vs = Vs;

    let Dc: any = response[0].Dc;
    Dc = this.utilService.formateNumberNew(Dc,2,1);
    this.Dc = Dc;

    let Ic: any = response[0].Ic;
    Ic = this.utilService.formateNumberNew(Ic,2,1);
    this.Ic = Ic;

    let PAPmC: any = response[0].PAPmC;
    PAPmC = this.utilService.formateNumberNew(PAPmC,2,1);
    this.PAPmC = PAPmC;

    let PAPmS: any = response[0].PAPmS;
    PAPmS = this.utilService.formateNumberNew(PAPmS,2,1);
    this.PAPmS = PAPmS;

    let Rea: any = response[0].Rea;
    Rea = this.utilService.formateNumberNew(Rea,2,1);
    this.Rea = Rea;

    let Raeao: any = response[0].Raeao;
    Raeao = this.utilService.formateNumberNew(Raeao,2,1);
    this.Raeao = Raeao;

    let Avsve: any = response[0].Avsve;
    Avsve = this.utilService.formateNumberNew(Avsve,2,1);
    this.Avsve = Avsve;

    let Avm: any = response[0].Avm;
    Avm = this.utilService.formateNumberNew(Avm,2,1);
    this.Avm = Avm;

    let Ava: any = response[0].Ava;
    Ava = this.utilService.formateNumberNew(Ava,2,1);
    this.Ava = Ava;

    let Fracaoejecao: any = response[0].Fracaoejecao;
    Fracaoejecao = this.utilService.formateNumberNew(Fracaoejecao,2,1);
    this.Fracaoejecao = Fracaoejecao;


    //

    this.ecocardioForm.controls['Dvcie'].setValue(Cvci);
    this.ecocardioForm.controls['Dvcii'].setValue(Dvcie);
    this.ecocardioForm.controls['Dvsve'].setValue(Dvsve);
    this.ecocardioForm.controls['Vtivsve'].setValue(Vtivsve);
    this.ecocardioForm.controls['Vtiaorta'].setValue(Vtiaorta);
    this.ecocardioForm.controls['Vtimitral'].setValue(Vtimitral);
    this.ecocardioForm.controls['Emitral'].setValue(Emitral);
    this.ecocardioForm.controls['Amitral'].setValue(Amitral);
    this.ecocardioForm.controls['Rtricuspide'].setValue(Rtricuspide);
    this.ecocardioForm.controls['Pressaoarteriapulmonar'].setValue(Pressaoarteriapulmonar);
    this.ecocardioForm.controls['Dae'].setValue(Dae);
    this.ecocardioForm.controls['Dad'].setValue(Dad);
    this.ecocardioForm.controls['Dved'].setValue(Dved);
    this.ecocardioForm.controls['Dves'].setValue(Dves);
    this.ecocardioForm.controls['Dao'].setValue(Dao);
    this.ecocardioForm.controls['Siv'].setValue(Siv);
    this.ecocardioForm.controls['Dpp'].setValue(Dpp);
    this.ecocardioForm.controls['Vsfve'].setValue(Vsfve);
    this.ecocardioForm.controls['Vdfve'].setValue(Vdfve);
    this.ecocardioForm.controls['Peso'].setValue(Peso);
    this.ecocardioForm.controls['Altura'].setValue(Altura);
    this.ecocardioForm.controls['Bsa'].setValue(Bsa);
    this.ecocardioForm.controls['Vs'].setValue(Vs);
    this.ecocardioForm.controls['Dc'].setValue(Dc);
    this.ecocardioForm.controls['Ic'].setValue(Ic);
    
    // Calculos

    this.ecocardioForm.controls['Idvci'].setValue(response[0].Idvci);
    this.ecocardioForm.controls['PAPmS'].setValue(response[0].PAPmS);
    this.ecocardioForm.controls['PAPmC'].setValue(response[0].PAPmC);
    this.ecocardioForm.controls['Avm'].setValue(response[0].Avm);
    this.ecocardioForm.controls['Rea'].setValue(response[0].Rea);
    this.ecocardioForm.controls['Ava'].setValue(response[0].Ava);
    this.ecocardioForm.controls['Raeao'].setValue(response[0].Raeao);
    this.ecocardioForm.controls['Avsve'].setValue(response[0].Avsve);
    this.ecocardioForm.controls['Fracaoejecao'].setValue(response[0].Fracaoejecao);

    // Combos unicos

      
    this.ecocardioForm.controls['Sexo'].setValue(response[0].Sexo);
    this.ecocardioForm.controls['Cvci'].setValue(Cvci);
    
    // combos multiplos
    let Trombo:any = JSON.parse(response[0].Trombo);
    let Lesaovalvar:any = JSON.parse(response[0].Lesaovalvar);
    let Disfuncao:any = JSON.parse(response[0].Disfuncao);
    let Volumecamara:any = JSON.parse(response[0].Volumecamara);

    this.ecocardioForm.controls['Trombo'].setValue(Trombo);
    this.ecocardioForm.controls['Lesaovalvar'].setValue(Lesaovalvar);
    this.ecocardioForm.controls['Disfuncao'].setValue(Disfuncao);
    this.ecocardioForm.controls['Volumecamara'].setValue(Volumecamara);
    
   

  }
  async closeModal() {
    this.modalCtrl.dismiss();

  }

  async presentAlertConfirm() {
    let tipo = parseInt(this.ecocardioForm.value.Id);
    let msg = "Confirma a <strong>CALCULO desses dados</strong> desses dados? <br> "
    if (this.Paciente > 0 && this.Internacao > 0) {
      msg = "Confirma a <strong>INCLUSAO desses dados</strong> desses dados? <br> "
    }
    if (tipo > 0 && this.Paciente > 0 && this.Internacao > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'ECOCARDIO - HEMODINÂMICA',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
        
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraObservacao'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrarEcocardio();
          }
        }
      ]
    });
    await alert.present();
  }

  async cadastrarEcocardio() {
    // geral
    let Datalancamento = this.ecocardioForm.value.Datalancamento.toString();
    Datalancamento = this.utilService.checaData(Datalancamento);
    let Sexo: number = this.ecocardioForm.value.Sexo;
    if (Sexo == 0 || Sexo == null || Sexo == undefined) {
      this.utilService.showAlertCallback('SEXO inválido',
        () => {
          this.utilService.focusInput(this.ancoraPeso)
        }
      );
      return;
    }
    let Peso: number = this.ecocardioForm.value.Peso;
    Peso = this.utilService.formateNumberNew(Peso,3,1);
    if (isNaN(Peso/1) || Peso == 0 || Peso == null || Peso == undefined) {
      this.utilService.showAlertCallback('PESO inválido',
        () => {
          this.utilService.focusInput(this.ancoraPeso)
        }
      );
      return;
    }
    let Altura: number = this.ecocardioForm.value.Altura;
    Altura = this.utilService.formateNumberNew(Altura,0);

    if (isNaN(Altura/1) || Altura == 0 || Altura == null || Altura == undefined) {
      this.utilService.showAlertCallback('ALTURA inválido',
        () => {
          this.utilService.focusInput(this.ancoraAltura)
        }
      );
      return;
    }
    let Bsa: number = this.ecocardioForm.value.Bsa;
    Bsa = this.utilService.formateNumberNew(Bsa,2,1);

    if (isNaN(Bsa/1) || Bsa == 0 || Bsa == null || Bsa == undefined) {
      this.utilService.showAlertCallback('BSA inválido',
        () => {
          this.utilService.focusInput(this.ancoraBsa)
        }
      );
      return;
    }
    // cava
    let Cvci: number = this.ecocardioForm.value.Cvci;
    if (Cvci == null || Cvci == undefined) {
      this.utilService.showAlertCallback('Percentual de colabamento da VCI. Escolha agora',
        this.utilService.focusInput(this.ancoraDvcii)
      );
      return;
    }
    let Dvcii: number = this.ecocardioForm.value.Dvcii;
    Dvcii = this.utilService.formateNumberNew(Dvcii,2,1);
    if (isNaN(Dvcii/1) || Dvcii == 0 || Dvcii == null || Dvcii == undefined) {
      this.utilService.showAlertCallback('DVCI MENOR (VM na expiração) inválido',
        () => {
          this.utilService.focusInput(this.ancoraDvcii)
        }
      );
      return;
    }
    let Dvcie: number = this.ecocardioForm.value.Dvcie;
    Dvcie = this.utilService.formateNumberNew(Dvcie,2,1);
    if (isNaN(Dvcie/1) || Dvcie == 0 || Dvcie == null || Dvcie == undefined) {
      this.utilService.showAlertCallback('DVCI MAIOR (VM na inspiração) inválido',
        () => {
          this.utilService.focusInput(this.ancoraDvcie)
        }
      );
      return;
    }

    let Idvci: number = this.ecocardioForm.value.Idvci;
    Idvci = this.utilService.formateNumberNew(Idvci,2,1);
    if (isNaN(Idvci/1) || Idvci == 0 || Idvci == null || Idvci == undefined) {
      this.utilService.showAlertCallback('IDVCI não calculado',
        () => {
          this.utilService.focusInput(this.ancoraDvcie)
        }
      );
      return;
    }

    // débito cardíaco
    let Dvsve: number = this.ecocardioForm.value.Dvsve;
    Dvsve = this.utilService.formateNumberNew(Dvsve,2,1);
    if (isNaN(Dvsve/1) || Dvsve == 0 || Dvsve == null || Dvsve == undefined) {
      this.utilService.showAlertCallback('DVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraDvsve)
        }
      );
      return;
    }

    let Vtivsve: number = this.ecocardioForm.value.Vtivsve;
    Vtivsve = this.utilService.formateNumberNew(Vtivsve,2,1);
    if (isNaN(Vtivsve/1) || Vtivsve == 0 || Vtivsve == null || Vtivsve == undefined) {
      this.utilService.showAlertCallback('VTIVSVE inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtivsve)
        }
      );
      return;
    }

    let Vs: any = this.ecocardioForm.value.Vs;
    Vs = this.utilService.formateNumberNew(Vs,2,1);
    if (isNaN(Vs/1) || Vs == '' || Vs == 0 || Vs == null || Vs == undefined) {
      this.Vs = 0;
      this.utilService.showAlertCallback("VOLUME SISTÓLICO DE VE inválido",
        () => {
          this.utilService.focusInput(this.ancoraVs);
        }
      );
      return;
    }

    let Frequencia: any = this.ecocardioForm.value.Frequencia;
    Frequencia = this.utilService.formateNumberNew(Frequencia,0,1);
    if (isNaN(Frequencia/1) || Frequencia == '' || Frequencia == 0 || Frequencia == null || Frequencia == undefined) {
      this.Frequencia = 0;
      this.utilService.showAlertCallback("FREQUENCIA CARDÍACA inválido",
        () => {
          this.utilService.focusInput(this.ancoraFrequencia);
        }
      );
      return;
    }

    let Dc: any = this.ecocardioForm.value.Dc;
    Dc = this.utilService.formateNumberNew(Dc,2,1);
    if (isNaN(Dc/1) || Dc == '' || Dc == 0 || Dc == null || Dc == undefined) {
      this.Dc = 0;
      this.utilService.showAlertCallback("DÉBITO CARDÍACO inválido",
        () => {
          this.utilService.focusInput(this.ancoraDc);
        }
      );
      return;
    }

    let Ic: any = this.ecocardioForm.value.Ic;
    Ic = this.utilService.formateNumberNew(Ic,2,1);
    if (isNaN(Ic/1) || Ic == '' || Ic == 0 || Ic == null || Ic == undefined) {
      this.Ic = 0;
      this.utilService.showAlertCallback("ÍNDICE CARDÍACO inválido",
        () => {
          this.utilService.focusInput(this.ancoraIc);
        }
      );
      return;
    }
    // medidas de VE
    let Dves: any = this.ecocardioForm.value.Dves;
    Dves = this.utilService.formateNumberNew(Dves,2,1);
    if (isNaN(Dves/1) || Dves == '' || Dves == 0 || Dves == null || Dves == undefined) {
      this.Dves = 0;
      this.utilService.showAlertCallback("Diâmetro do VE na sistóle inválido",
        () => {
          this.utilService.focusInput(this.ancoraDves);
        }
      );
      return;
    }

    let Dved: any = this.ecocardioForm.value.Dved;
    Dved = this.utilService.formateNumberNew(Dved,2,1);
    if (isNaN(Dved/1) || Dved == '' || Dved == 0 || Dved == null || Dved == undefined) {
      this.Dved = 0;
      this.utilService.showAlertCallback("Diâmetro do VE na diástole inválido",
        () => {
          this.utilService.focusInput(this.ancoraDved);
        })
      return;
    }
    let Fracaoejecao: any = this.ecocardioForm.value.Fracaoejecao;
    Fracaoejecao = this.utilService.formateNumberNew(Fracaoejecao,0);
    if (isNaN(Fracaoejecao/1) || Fracaoejecao == '' || Fracaoejecao == 0 || Fracaoejecao == null || Fracaoejecao == undefined) {
      this.Fracaoejecao = 0;
      this.utilService.showAlertCallback("Fração de ejeção de VE inválido",
        () => {
          this.utilService.focusInput(this.ancoraFracaoejecao);
        }
      );
      return;
    }
    let Vdfve: any = this.ecocardioForm.value.Vdfve;
    Vdfve = this.utilService.formateNumberNew(Vdfve,2,1);
    if (isNaN(Vdfve/1)|| Vdfve == '' || Vdfve == 0 || Vdfve == null || Vdfve == undefined) {
      this.Vdfve = 0;
      this.utilService.showAlertCallback("VOLUME DIÁSTOLICO FINAL DE VE inválido",
        () => {
          this.utilService.focusInput(this.ancoraVdfve);
        }
      );
      return;
    }
    let Vsfve: any = this.ecocardioForm.value.Vsfve;
    Vsfve = this.utilService.formateNumberNew(Vsfve,2,1);
    if (isNaN(Vsfve/1) || Vsfve == '' || Vsfve == 0 || Vsfve == null || Vsfve == undefined) {
      this.Vsfve = 0;
      this.utilService.showAlertCallback("VOLUME SISTÓLICO FINAL DE VE inválido",
        () => {
          this.utilService.focusInput(this.ancoraVsfve);
        }
      );
      return;
    }

    let Siv: any = this.ecocardioForm.value.Siv;
    Siv = this.utilService.formateNumberNew(Siv,2,1);
    if (isNaN(Siv/1) || Siv == '' || Siv == 0 || Siv == null || Siv == undefined) {
      this.Siv = 0;
      this.utilService.showAlertCallback("Diâmetro da SEPTO INTRAVENTRICULAR inválido",
        () => {
          this.utilService.focusInput(this.ancoraSiv);
        }
      );
      return;
    }

    let Dpp: any = this.ecocardioForm.value.Dpp;
    Dpp = this.utilService.formateNumberNew(Dpp,2,1);
    if (isNaN(Dpp/1) || Dpp == '' || Dpp == 0 || Dpp == null || Dpp == undefined) {
      this.Dpp = 0;
      this.utilService.showAlertCallback("Diâmetro da PAREDE POSTERIOR inválido",
        () => {
          this.utilService.focusInput(this.ancoraDpp);
        }
      );
      return;
    }

    // atrios
    let Dae: any = this.ecocardioForm.value.Dae;
    Dae = this.utilService.formateNumberNew(Dae,2,1);
    if (isNaN(Dae/1) || Dae == '' || Dae == 0 || Dae == null || Dae == undefined) {
      this.Dae = 0;
      this.utilService.showAlertCallback("Diâmetro do ÁTRIO esquerdo inválido",
        () => {
          this.utilService.focusInput(this.ancoraDae);
        }
      );
      return;
    }

    let Dad: any = this.ecocardioForm.value.Dad;
    Dad = this.utilService.formateNumberNew(Dad,2,1);
    if (isNaN(Dad/1) || Dad == '' || Dad == 0 || Dad == null || Dad == undefined) {
      this.Dad = 0;
      this.utilService.showAlertCallback("Diâmetro do ÁTRIO direito inválido",
        () => {
          this.utilService.focusInput(this.ancoraDad);
        }
      );
      return;
    }

    // aorta
    let Dao: any = this.ecocardioForm.value.Dao;
    Dao = this.utilService.formateNumberNew(Dao,2,1);
    if (isNaN(Dao/1) || Dao == '' || Dao == 0 || Dao == null || Dao == undefined) {
      this.Dao = 0;
      this.utilService.showAlertCallback("Diâmetro da AORTA inválido",
        () => {
          this.utilService.focusInput(this.ancoraDao);
        }
      );
      return;
    }

    let Vtiaorta: any = this.ecocardioForm.value.Vtiaorta;
    Vtiaorta = this.utilService.formateNumberNew(Vtiaorta,2,1);
    if (isNaN(Vtiaorta/1) || Vtiaorta == '' || Vtiaorta == 0 || Vtiaorta == null || Vtiaorta == undefined) {
      this.utilService.showAlertCallback('VTI AORTA inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtiaorta);
        }
      );
      return;
    }
    // mitral
    let Vtimitral: any = this.ecocardioForm.value.Vtimitral;
    Vtimitral = this.utilService.formateNumberNew(Vtimitral,2,1);
    if (isNaN(Vtimitral/1) || Vtimitral == '' || Vtimitral == 0 || Vtimitral == null || Vtimitral == undefined) {
      this.utilService.showAlertCallback('VTI MITRAL inválido',
        () => {
          this.utilService.focusInput(this.ancoraVtimitral);
        });
      return;
    }

    let Emitral: any = this.ecocardioForm.value.Emitral;
    Emitral = this.utilService.formateNumberNew(Emitral,2,1);
    if (isNaN(Emitral/1) || Emitral == '' || Emitral == 0 || Emitral == null || Emitral == undefined) {
      this.utilService.showAlertCallback('Onda E mitral inválido',
        () => {
          this.utilService.focusInput(this.ancoraEmitral);
        });
      return;
    }
    let Amitral: any = this.ecocardioForm.value.Amitral;
    Amitral = this.utilService.formateNumberNew(Amitral,2,1);
    if (isNaN(Amitral/1) || Amitral == '' || Amitral == 0 || Amitral == null || Amitral == undefined) {
      this.utilService.showAlertCallback('Onda A mitral inválido',
        () => {
          this.utilService.focusInput(this.ancoraAmitral);
        });
      return;
    }

    // tricuspide e arteria pulmonar
    let Rtricuspide: any = this.ecocardioForm.value.Rtricuspide;
    Rtricuspide = this.utilService.formateNumberNew(Rtricuspide,2,1);
    if ( isNaN(Rtricuspide/1) || Rtricuspide == '' || Rtricuspide == 0 || Rtricuspide == null || Rtricuspide == undefined) {
      this.utilService.showAlertCallback('Refluxo tricúspede inválido',
        () => {
          this.utilService.focusInput(this.ancoraRtricuspide);
        });
      return;
    }

    let Pressaoarteriapulmonar: any = this.ecocardioForm.value.Pressaoarteriapulmonar;
    Pressaoarteriapulmonar = this.utilService.formateNumberNew(Pressaoarteriapulmonar,2,1);
    if (isNaN(Pressaoarteriapulmonar/1) || Pressaoarteriapulmonar == '' || Pressaoarteriapulmonar == 0 || Pressaoarteriapulmonar == null || Pressaoarteriapulmonar == undefined) {
      this.Pressaoarteriapulmonar = 0;
      this.utilService.showAlertCallback("Pressao da ARTERIA PULMONAR inválido",
        () => {
          this.utilService.focusInput(this.ancoraPressaoarteriapulmonar);
        });
      return;
    }
    // alterações morfologicas
    let Trombo = this.ecocardioForm.value.Trombo.toString();
    if ( Trombo == '' || Trombo == 0 || Trombo == null || Trombo == undefined) {
      this.Trombo = 0;
      this.utilService.showAlertCallback("Presença de trombos inválido")
      return;
    }
    Trombo = "[" + Trombo + "]";
    let Lesaovalvar = this.ecocardioForm.value.Lesaovalvar.toString();
    if (Lesaovalvar == '' || Lesaovalvar == 0 || Lesaovalvar == null || Fracaoejecao == undefined) {
      this.Lesaovalvar = 0;
      this.utilService.showAlertCallback("Presença de Lesão valvar inválido")
      return;
    }
    Lesaovalvar = "[" + Lesaovalvar + "]";

    let Disfuncao = this.ecocardioForm.value.Disfuncao.toString();
    if (Disfuncao == '' || Disfuncao == 0 || Disfuncao == null || Disfuncao == undefined) {
      this.Disfuncao = 0;
      this.utilService.showAlertCallback("Presença de Lesão valvar inválido")
      return;
    }
    Disfuncao = "[" + Disfuncao + "]";
    let Volumecamara = this.ecocardioForm.value.Volumecamara.toString();
    if (Volumecamara == '' || Volumecamara == 0 || Volumecamara == null || Volumecamara == undefined) {
      this.Volumecamara = 0;
      this.utilService.showAlertCallback("Presença Volume câmaras inválido")
      return;
    }

    Volumecamara = "[" + Volumecamara + "]";
    let form = {
      Id: this.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Cvci: Cvci,
      Dvcie: Dvcie,
      Dvcii: Dvcii,
      Dvsve: Dvsve,
      Idvci:Idvci,
      Vtivsve: Vtivsve,
      Vtiaorta: Vtiaorta,
      Vtimitral: Vtimitral,
      Emitral: Emitral,
      Amitral: Amitral,
      Rtricuspide: Rtricuspide,
      Pressaoarteriapulmonar: Pressaoarteriapulmonar,
      Fracaoejecao: Fracaoejecao,
      Trombo: Trombo,
      Lesaovalvar: Lesaovalvar,
      Disfuncao: Disfuncao,
      Volumecamara: Volumecamara,
      Dae: Dae,
      Dad: Dad,
      Dved: Dved,
      Dves: Dves,
      Dao: Dao,
      Siv: Siv,
      Dpp: Dpp,
      Vs: Vs,
      Vsfve: Vsfve,
      Vdfve: Vsfve,
      Frequencia: Frequencia,
      Peso: Peso,
      Altura: Altura,
      Sexo: Sexo,
      Bsa: Bsa,
      Dc: Dc,
      Ic: Ic,
      Avsve: this.Avsve,
      Avm: this.Avm,
      Ava: this.Ava,
      Pvc: this.Pvcm,
      PAPmC: this.PAPmC,
      PAPmS: this.PAPmS,
      Rea: this.Rea,
      Raeao: this.Raeao,
      Descricao: this.ecocardioForm.value.Descricao,
      Usuario: this.Usuario
    }
    if (this.ecocardioForm.value.Id == -1 || this.ecocardioForm.value.Id == null || this.ecocardioForm.value.Id == undefined || this.ecocardioForm.value.Id == '') {
      this.lancamentoService.adicionarEcocardio(form).then((response) => {
        this.utilService.showAlertCallback("Ecocardiograma ADICIONADO com sucesso");
        this.selecionarEcocardio(response[0].Id);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o ecocardiogrma");
      });
    } else {
      this.lancamentoService.atualizarEcocardio(form).then((response) => {
        this.utilService.showAlertCallback("Ecocardiograma ATUALIZADO com sucesso");
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o ecocardiogrma");
      });
    }
  }
}