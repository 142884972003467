import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { PacienteService } from '../../services/paciente.service';
import { TabelaService } from '../../services/tabela.service';
import { EvolucaoService } from '../../services/evolucao.service';

@Component({
  selector: 'app-fluxogramarelacao',
  templateUrl: './fluxogramarelacao.component.html',
  styleUrls: ['./fluxogramarelacao.component.scss'],
})
export class FluxogramarelacaoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraValor', null) ancoraValor: ElementRef;
  @ViewChild('ancoraValormaximo', null) ancoraValormaximo: ElementRef;
  @ViewChild('ancoraValorminimo', null) ancoraValorminimo: ElementRef;

  fluxogramarelacaoForm: any;
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0;
  Botao: string = "Cadastrar";
  chdAtivo: boolean = false;
  corAtivo: string = "primary";
  chdPadrao: boolean = false;
  corPadrao: string = "primary";
  sistemaCollection: any = [];
  escoreCollection: any = [];
  Escore: number = 0;
  unidadeCollection: any = [];
  escorerelacaoCollection: any = [];
  escoretipoCollection: any = [];
  Sistema:number=0;
  Escorerelacao:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private pacienteService: PacienteService,
    private tabelaService: TabelaService,
    private evolucaoService:EvolucaoService
  ) {
    this.fluxogramarelacaoForm = formBuilder.group({
      Escorerelacao: ['', ''],
      Escore: ['', ''],
      Sistema: ['', ''],
      Nome: ['', ''],
      Ativo: ['', ''],
      Padrao: ['', ''],
      Valor: ['', ''],
      Valorminimo: ['', ''],
      Valormaximo: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {
    this.Escore = this.navParams.get('Escore');
    this.Sistema  = this.navParams.get('Sistema');
    this.Escorerelacao  = this.navParams.get('Escorerelacao');

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.limpaDados();
    this.selecionarSistema();

  }

  async selecionarEscoreRelacao(Escore:number) {
    if (Escore == 0 || Escore == null || Escore == undefined){
      return; 
    }
    this.Escore = Escore
    this.checklistService.selecionarEscoreRelacao(this.Escore)
      .then((response: any) => {
        for (let i = 0; i<response.length; i++) {
          if (response[i].Padrao == 1) {
            response[i].Nome = response[i].Nome + ' (P)'
          }
        }
        this.escorerelacaoCollection = response
        if (this.Escorerelacao > 0) {
          this.selecionarEscoreRelacaoUnico(this.Escorerelacao);
        setTimeout(()=>{
          this.fluxogramarelacaoForm.controls['Escorerelacao'].setValue(this.Escorerelacao);
        }, 500)  
        }
        
      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore')
      });
  }

  async selecionarSistema() {
    this.pacienteService.selecionarSistema().then((response) => {
      this.sistemaCollection = response;
      this.selecionarEscoreSistema(this.Sistema);
      setTimeout(()=>{
        this.fluxogramarelacaoForm.controls['Sistema'].setValue(this.Sistema);
      }, 500)
    }).catch((response) => {
      //console.log("erro")
    });
  }

  async selecionarEscoreSistema(sistema: number) {
    if (sistema == 0 || sistema == null || sistema == undefined) {
      return;
    }
    this.checklistService.selecionarEscoreSistema(sistema).then((response: any) => {
      this.escoreCollection = response;
      this.selecionarEscoreRelacao(this.Escore)
      setTimeout(() => {
        this.fluxogramarelacaoForm.controls['Escore'].setValue(this.Escore);
      }, 500);
    }).catch((response) => {

    });
  }

  

  

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }

    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")

    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }





  async testaDados() {

    if (this.fluxogramarelacaoForm.value.Escore == '') {
      this.utilService.showAlertCallback("Escore não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraNome);
      })
    }

    if (this.fluxogramarelacaoForm.value.Nome == '') {
      this.utilService.showAlertCallback("Nome não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraNome);
      })
    }

    if (this.fluxogramarelacaoForm.value.Valor == undefined) {
      this.utilService.showAlertCallback("Valor não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraValor);
      })
      return;
    }

   

    if (this.fluxogramarelacaoForm.value.Valorminimo == undefined) {
      this.utilService.showAlertCallback("Valor minimo não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraValorminimo);
      })
    }

    if (this.fluxogramarelacaoForm.value.Valormaximo == undefined) {
      this.utilService.showAlertCallback("Valor maximo medida não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraValormaximo);
      })
      return;
    }

    
    let Ativo: number = 0;
    if (this.fluxogramarelacaoForm.value.Ativo == true) {
      Ativo = 1
    }

    let Padrao: number = 0;
    if (this.fluxogramarelacaoForm.value.Padrao == true) {
      Padrao = 1
    }

   

    let form = {
      Id: this.fluxogramarelacaoForm.value.Escorerelacao,
      Escore: this.fluxogramarelacaoForm.value.Escore,
      Nome: this.fluxogramarelacaoForm.value.Nome,
      Padrao: Padrao,
      Ativo: Ativo,
      Valor: this.fluxogramarelacaoForm.value.Valor,
      Valormaximo: this.fluxogramarelacaoForm.value.Valormaximo,
      Valorminimo: this.fluxogramarelacaoForm.value.Valorminimo,
      Usuario: this.Usuario
    }

    this.presentAlertConfirm(form);
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }




  async presentAlertConfirm(form: any) {
    let tipo = this.fluxogramarelacaoForm.value.Escorerelacao;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitante',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


  async cadastrar(form: any) {
    if (this.fluxogramarelacaoForm.value.Escorerelacao == -1) {
      this.evolucaoService.adicionarEscorerelacao(form).then((response) => {
        if (response[0].Id > 0) {
          this.utilService.showAlertCallback("Escore relação ADICIONADO com sucesso", null);
          this.selecionarEscoreRelacao(response[0].Escore);
          setTimeout(() => {
            this.fluxogramarelacaoForm.controls['Escorerelacao'].setValue(response[0].Id);
        }, 500);
        }

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o escore relação", null);
      });
    } else {
      this.evolucaoService.atualizarEscorerelacao(form).then((response: any) => {
        this.utilService.showAlertCallback("Escore relação ATUALIZADO com sucesso", null);
        this.selecionarEscoreRelacao(this.Escore);
        setTimeout(() => {
          this.fluxogramarelacaoForm.controls['Escorerelacao'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Escore relação", null);
      });
    }

  }

  a
  async selecionarEscoreRelacaoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.checklistService.selecionarEscoreRelacaoUnico(Id).then((response: any) => {
      if (response[0].Id > 0) {
        setTimeout(()=>{
          this.getEscoreRelacao(response);
        }, 500)
        
      }
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar o ÓRGÃO")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.chdAtivo = false;
    this.chdPadrao = false;
    this.fluxogramarelacaoForm.controls['Nome'].setValue('');
    this.fluxogramarelacaoForm.controls['Valor'].setValue('');
    this.fluxogramarelacaoForm.controls['Ativo'].setValue(this.chdAtivo);
    this.fluxogramarelacaoForm.controls['Padrao'].setValue(this.chdPadrao);
    this.fluxogramarelacaoForm.controls['Valormaximo'].setValue(0);
    this.fluxogramarelacaoForm.controls['Valorminimo'].setValue(0);
    this.utilService.focusInput(this.ancoraNome);

  }



  async getEscoreRelacao(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "Alterar";
    this.chdAtivo = false
    this.corAtivo = 'primary'
    if (response[0].Ativo == 1) {
      this.chdAtivo = true
      this.corAtivo = 'danger'

    }
    this.chdPadrao = false;
    this.corPadrao = 'primary';

    if (response[0].Padrao == 1) {
      this.chdPadrao = true;
      this.corPadrao = 'danger';
    }

    let Valor:number =0;
    Valor = response[0].Valor;
    Valor = this.utilService.formateNumberNew(Valor, 2);

    let Valorminimo:number =0;
    Valorminimo = response[0].Valorminimo;
    Valorminimo = this.utilService.formateNumberNew(Valorminimo, 2);

    let Valormaximo:number =0;
    Valormaximo = response[0].Valormaximo;
    Valormaximo = this.utilService.formateNumberNew(Valormaximo, 2);


    this.fluxogramarelacaoForm.controls['Nome'].setValue(response[0].Nome);
    this.fluxogramarelacaoForm.controls['Valor'].setValue(Valor);
    this.fluxogramarelacaoForm.controls['Valorminimo'].setValue(Valorminimo);
    this.fluxogramarelacaoForm.controls['Valormaximo'].setValue(Valormaximo);
    this.fluxogramarelacaoForm.controls['Ativo'].setValue(this.chdAtivo);
    this.fluxogramarelacaoForm.controls['Padrao'].setValue(this.chdPadrao);
    this.fluxogramarelacaoForm.controls['Escore'].setValue(response[0].Escore);
  }

  async selecionaAtivo() {
    if (this.fluxogramarelacaoForm.value.Ativo == true) {
      this.chdAtivo = true;
      this.corAtivo = 'danger';
    } else {
      this.chdAtivo = false;
      this.corAtivo = 'primary';
    }
  }

  async selecionaPadrao() {
    if (this.fluxogramarelacaoForm.value.Padrao == true) {
      this.chdPadrao = true;
      this.corPadrao = 'danger';
    } else {
      this.chdPadrao = false;
      this.corPadrao = 'primary';
    }
  }

 
}
