import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { ExameunidadeComponent } from 'src/app/componentes/exameunidade/exameunidade.component';
import { ExameorgaoComponent } from 'src/app/componentes/exameorgao/exameorgao.component';
import { VirtualTimeScheduler } from 'rxjs';


@Component({
  selector: 'app-exametopico',
  templateUrl: './exametopico.component.html',
  styleUrls: ['./exametopico.component.scss'],
})
export class ExametopicoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraTexto', null) ancoraTexto: ElementRef;

  exametopicoForm: any
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0
  Botao: string = "Cadastrar"
  collectionTopico: any = [];
  collectionTipo: any = [];
  collectionOrgao: any = [];
  Exametipo: number = 0;
  Examerelacao:number=0;
  Exameorgao:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private modalController:ModalController
  ) {
    this.exametopicoForm = formBuilder.group({
      Exametipo: ['', ''],
      Exameorgao: ['', ''],
      Exametopico: ['', ''],
      Nome: ['', ''],
      Texto: ['', ''],
      Padrao: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
   
  }
  ngOnInit() {
    this.Exametipo = this.navParams.get('Exametipo');
    this.Examerelacao = this.navParams.get('Examerelacao');
    this.Exameorgao= this.navParams.get('Exameorgao');

   
  }

  ionViewDidEnter() {
    this.limpaDados();
    this.testaAcessoSistema();
    this.selecionarExameTipo();
    this.selecionarExameOrgao();
   


  }

  

  async selecionarExameTopico(orgao) {
    let tipo:number= this.exametopicoForm.value.Exametipo;
    if (orgao == undefined || orgao == null || orgao == 0 || tipo == undefined || tipo == null || tipo == 0) {
      return;
    }
    this.exameService.selecionarExameTopico(tipo, orgao, this.Usuario).then((response: any) => {
      this.collectionTopico = response;
    }).catch((response) => {

    });

  }

  

  async selecionarExameTipo() {
    this.exameService.selecionarExameTipo().then((response: any) => {
      this.collectionTipo = response;
      setTimeout(()=>{
        this.exametopicoForm.controls['Exametipo'].setValue(this.Exametipo);
        
      },250)
    })
  }

  
  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

 

 

  async testaDados() {

    if (this.exametopicoForm.value.Exametipo == null || this.exametopicoForm.value.Exametipo == undefined || this.exametopicoForm.value.Exametipo == '') {
      this.utilService.showAlertCallback("Informe o TIPO DE EXAME para continuar", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exametopicoForm.value.Exameorgao == null || this.exametopicoForm.value.Exameorgao == undefined || this.exametopicoForm.value.Exameorgao == '') {
      this.utilService.showAlertCallback("Informe o ÓRGÃO para continuar", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exametopicoForm.value.Nome == null || this.exametopicoForm.value.Nome == undefined || this.exametopicoForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DO TÓPICO para continuar", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exametopicoForm.value.Texto == null || this.exametopicoForm.value.Texto == undefined || this.exametopicoForm.value.Texto == '') {
      this.utilService.showAlertCallback("Informe o TEXTO DO TÓPICO para continuar", () => {
        this.utilService.focusInput(this.ancoraTexto);
      });
      return;
    }
    
    let Padrao:number=0;
    if (this.exametopicoForm.value.Padrao == true) {
      Padrao= 1
    }
    
    
   

    let form = {
      Id: this.exametopicoForm.value.Exametopico,
      Exametipo: this.exametopicoForm.value.Exametipo,
      Exameorgao: this.exametopicoForm.value.Exameorgao,
      Nome: this.exametopicoForm.value.Nome,
      Texto: this.exametopicoForm.value.Texto,
      Padrao: Padrao,
      Usuario: this.Usuario
    }


    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }



  async presentAlertConfirm(form: any) {

    let tipo = this.exametopicoForm.value.Exametopico;

    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "

    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Relação',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }

  async selecionarPadrao(){
    for(let i=0; i<this.collectionTopico.length;i++) {
      if (this.collectionTopico[i].Padrao == 1) {
        this.exametopicoForm.controls['Nome'].setValue(this.collectionTopico[i].Nome);
        this.exametopicoForm.controls['Texto'].setValue(this.collectionTopico[i].Texto);
        break;
      }
    }
  }
  async cadastrar(form: any) {

    if (this.exametopicoForm.value.Exametopico == -1) {
      this.exameService.adicionarExameTopico(form).then((response) => {
        this.utilService.showAlertCallback("Tópico ADICIONADO com sucesso", null);
        this.selecionarExameTopico(this.exametopicoForm.value.Exameorgao);
        setTimeout(() => {
          this.exametopicoForm.controls['Exametopico'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o Exame", null);

      });
    } else {
      this.exameService.atualizarExameTopico(form).then((response: any) => {
        this.utilService.showAlertCallback("Tópico ATUALIZADO com sucesso", null);
        this.selecionarExameTopico(this.exametopicoForm.value.Exameorgao);

        setTimeout(() => {
          this.exametopicoForm.controls['Exametopico'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Exame", null);

      });
    }

  }


  async limpaDados() {
    this.Botao = "Cadastrar";
   // this.exametopicoForm.controls['Exametipo'].setValue(this.Exametipo);
   // this.exametopicoForm.controls['Exameorgao'].setValue(this.Exameorgao);
    this.exametopicoForm.controls['Exametopico'].setValue(-1);
    this.exametopicoForm.controls['Nome'].setValue('');
    this.exametopicoForm.controls['Texto'].setValue('');
    this.exametopicoForm.controls['Padrao'].setValue(false);
    this.utilService.focusInput(this.ancoraNome);
    

  }



  async selecionarExameTopicoUnico(id: number) {
    if (id == -1 ) {
      this.limpaDados();
      return;
    }
   
    this.exameService.selecionarExameTopicoUnico(id).then((response:any)=>{
      this.Botao = "Alterar";
      let Padrao:any= response[0].Padrao;
      if (Padrao == 1) {
        Padrao = true;
      }else{
        Padrao = false;
      }
      this.exametopicoForm.controls['Nome'].setValue(response[0].Nome);
      this.exametopicoForm.controls['Texto'].setValue(response[0].Texto);
      this.exametopicoForm.controls['Padrao'].setValue(Padrao);
    })
    
 
  }

  async selecionarExameOrgao() {
    this.exameService.selecionarExameOrgao().then((response: any) => {
      this.collectionOrgao = response;

      setTimeout(()=>{
        this.exametopicoForm.controls['Exameorgao'].setValue(this.Exameorgao);
        this.exametopicoForm.controls['Exametipo'].setValue(this.Exametipo);

      },1000)
      
    })

  }


}
