import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class EstudoService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController,

    ) { }

   async selecionarPesquisa() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarPesquisa';
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarPesquisaAtivo() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarPesquisaAtivo';
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarEvento() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEvento';
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarEventoValidacao(evento:number, validacao: number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEventoValidacao/' + evento + '/' + validacao;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarCursoEvento(evento:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarCursoEvento/' + evento ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarCurso() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarCurso' ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarCursoUnico(id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarCursoUnico/' + id ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarEventoUnico(id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEventoUnico/' + id;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarPesquisaUnico(Id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarPesquisaUnico/' + Id;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async adicionarPesquisa(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarPesquisa';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarCelular(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarCelular';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarCurso(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarCurso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarEvento(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarEvento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarEventoUsuario(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarEventoUsuario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarEventoUsuario2(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarEventoUsuario2';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  

  async selecionarCelular(Celular:string) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarCelular/' + Celular;
    loading.dismiss();
    return this.http.get(url).toPromise();
 }

 async selecionarEventoUsuarioUnico(id:number) {
  let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
  loading.present();
  let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEventoUsuarioUnico/' + id;
  loading.dismiss();
  return this.http.get(url).toPromise();
}

async atualizarCurso(form: any) {
  let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
  loading.present();
  let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarCurso';
  let headers: any = new Headers();
  headers.append('Content-Type', 'application/json');
  loading.dismiss();
  return this.http.put(url, form, { headers: headers }).toPromise();
}

async atualizarEvento(form: any) {
  let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
  loading.present();
  let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarEvento';
  let headers: any = new Headers();
  headers.append('Content-Type', 'application/json');
  loading.dismiss();
  return this.http.put(url, form, { headers: headers }).toPromise();
}

async atualizarEventoUsuario(form: any) {
  let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
  loading.present();
  let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarEventoUsuario';
  let headers: any = new Headers();
  headers.append('Content-Type', 'application/json');
  loading.dismiss();
  return this.http.put(url, form, { headers: headers }).toPromise();
}

async atualizarEventoUsuario2(form: any) {
  let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
  loading.present();
  let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarEventoUsuario2';
  let headers: any = new Headers();
  headers.append('Content-Type', 'application/json');
  loading.dismiss();
  return this.http.put(url, form, { headers: headers }).toPromise();
}


async atualizarPesquisa(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarPesquisa';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async atualizarPesquisaAcesso(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarPesquisaAcesso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // pergunta
  async selecionarPerguntaUnico(Id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarPerguntaUnico/' + Id;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async adicionarPergunta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarPergunta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarPergunta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarPergunta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }



  async selecionarPerguntaPesquisa(Pesquisa:number, Show:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarPerguntaPesquisa/' +    Pesquisa + '/' + Show;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }
  
  // resposta

  async selecionarRespostaUnico(Id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarRespostaUnico/' + Id;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

   async selecionarRespostaPergunta(Pergunta:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarRespostaPergunta/' +    Pergunta;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async  selecionarEventoUsuario(evento:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEventoUsuario/' +    evento;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async  selecionarEventoUsuarioConselho(evento:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarEventoUsuarioConselho/' +    evento;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }
  async adicionarResposta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarResposta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarResposta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarResposta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // questionario

  async adicionarQuestionario(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarQuestionario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarQuestionarioCelular(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarQuestionarioCelular';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async adicionarQuestionarioCelular2(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/adicionarQuestionarioCelular2';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async e_adicionarPergunta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_adicionarPergunta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async e_adicionarResposta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_adicionarResposta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  async atualizarQuestionario(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarQuestionario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async atualizarQuestionarioComentario(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarQuestionarioComentario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async atualizarQuestionarioAtivar(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/atualizarQuestionarioAtivar';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async e_atualizarPergunta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_atualizarPergunta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  async e_atualizarResposta(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_atualizarResposta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.put(url, form, { headers: headers }).toPromise();
  }
 
  async selecionarQuestionarioPesquisaCodigo(Pesquisa:number, Codigo:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarQuestionarioPesquisaCodigo/' +    Pesquisa +'/' + Codigo ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarQuestionarioManutencao() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarQuestionarioManutencao' ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async selecionarQuestionarioProducao() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/selecionarQuestionarioProducao' ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

   async deletarQuestionarioManutencao(Id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/deletarQuestionarioManutencao/' + Id ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

   // Miluh.com

   async e_selecionarPerguntaUnico(id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaUnico/' + id ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaUsuario(usuario:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaUsuario/' + usuario ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaArea(area:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaArea/' + area ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaSubArea(subarea:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaSubArea/' + subarea ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaNome(nome:string) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaNome/' + nome ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaComentario(comentario:string) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaComentario/' + comentario ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarPerguntaReferencia(referencia:string) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaReferencia/' + referencia ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarArea() {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarArea'  ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  
  async e_selecionarPerguntaValidacao(area:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarPerguntaValidacao/' + area ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }
  async e_selecionarSubArea(area:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarSubArea/' + area ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarRespostaUnico(id:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarRespostaUnico/' + id ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }

  async e_selecionarRespostaPergunta(pergunta:number) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_selecionarRespostaPergunta/' + pergunta ;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }
 
  async e_adicionarEstudo(form: any) {
    let loading = await this.loadingController.create({ message: "por favor aguarde......", mode: 'ios' });
    loading.present();
    let url = this.utilService.obterUrlDaApi() + 'Estudo/e_adicionarEstudo';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    loading.dismiss();
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

}
