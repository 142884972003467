import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';



@Component({
  selector: 'app-droga',
  templateUrl: './droga.component.html',
  styleUrls: ['./droga.component.scss'],
})
export class DrogaComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraPeso', null) ancoraPeso: ElementRef;
  @ViewChild('ancoraQtdampolapadrao', null) ancoraQtdampolapadrao: ElementRef;
  @ViewChild('ancoraDiluicaopadrao', null) ancoraDiluicaopadrao: ElementRef;
  @ViewChild('ancoraVazao', null) ancoraVazao: ElementRef;
  Sistema: number = 0;
  Qtdampolapadrao: number = 0;
  Posologia: number = 0;
  Intervalo: number = 0;
  Id: number = -1;
  Logado: number = 0;
  Usuario: number = 0;
  Paciente: number = 0;
  Nome: string = "";
  Internacao: number = 0;
  drogaForm: FormGroup;
  Botao: string = "CALCULAR";
  Datainternacao: string = "";
  Dias: string = "";
  Diasproposto: number = 0;
  Droga: number = 0;
  Ampola: number = 0;
  Solucao: number = 0;
  Vazao: number = 0;
  lRet: any;
  dadosListMed: any;
  Unidadedose: string = "";
  Unidadeconcentracao: string = "";
  Unidadeinfusao: string = "";
  Concentracao: number = 0;
  Fator: number = 0;
  Peso: any;
  Dose: number = 0;
  Doseminima: number = 0;
  Dosemaxima: number = 0;
  Dosepercentual: number = 0;
  Diluicaopadrao: number = 0;
  Concentracaopadrao: number = 0;
  Ampolaml: number;
  SiglaUnidadeMedidaAmpola: string = "";
  Ampoladoseml: number = 0;
  SiglaUnidadeMedidaInfusao: string = "";
  SiglaUnidadeMedidaConcentracao: string = "";
  drogaCollection: any = [];
  Kgbaseado: number = 0;
  Doseatual: any = 0;
  lancamentosCollection: any = [];
  Datalancamento: any;
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  Observacao:string="";
  aIntervalo:any=[];
  IdEscorerel:number=0;
  DescIntervalo:string='';
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private checklistService: ChecklistService,
    private alertController: AlertController) {
    
    
    this.dadosListMed= this.utilService.obterDadosDoSistema();
    
    this.Droga = this.navParams.get('Droga');
    this.Vazao = this.navParams.get('Vazao');

    this.Sistema = this.navParams.get('Sistema');
    this.Nome = this.navParams.get('Nome');
    this.Ampolaml = this.navParams.get('Ampolaml');
    this.Ampoladoseml = this.navParams.get('Ampoladoseml');
    this.Doseminima = this.navParams.get('Doseminima');
    this.Dosemaxima = this.navParams.get('Dosemaxima');
    this.Fator = this.navParams.get('Fator');
    this.SiglaUnidadeMedidaAmpola = this.navParams.get('SigaUnidadeMedidaAmpola');
    this.SiglaUnidadeMedidaInfusao = this.navParams.get('SigaUnidadeMedidaInfusao');
    this.SiglaUnidadeMedidaConcentracao = this.navParams.get('SigaUnidadeMedidaConcentracao');
    this.Qtdampolapadrao = this.navParams.get('Qtdampolapadrao');
    this.Diluicaopadrao = this.navParams.get('Diluicaopadrao');
    this.Concentracaopadrao = this.navParams.get('Concentracaopadrao');
    this.Kgbaseado = this.navParams.get('Kgbaseado');
    this.Observacao =  this.navParams.get('Observacao');
    this.Datalancamento = this.navParams.get('Dataevolucao');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
    this.Peso = parseFloat(this.navParams.get('Peso'));
    this.Peso = this.utilService.formateNumberNew(this.Peso,2);
    this.IdEscorerel =  this.navParams.get('IdEscorerel'); 
      console.log("IdEscorerel " + this.IdEscorerel)
    this.drogaForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Qtdampolapadrao: ['', ''],
      Solucao: ['', ''],
      Vazao: ['', ''],
      Peso: ['', ''],
      Diluicaopadrao: ['', ''],
      Doseatual: ['', ''],
      Droga: ['', ''],
      Intervalo: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {
    this.Droga = this.navParams.get('Droga');

    this.aIntervalo.push({Id: 1 , Nome: 'Continuo'})
    this.aIntervalo.push({Id: 2 , Nome: 'Agora'})
    this.aIntervalo.push({Id: 3 , Nome: 'A critério'})
    this.aIntervalo.push({Id: 4, Nome: 'Única'})
    this.aIntervalo.push({Id: 5, Nome: '1/1 hora'})
    this.aIntervalo.push({Id: 6, Nome: '2/2 hora'})
    this.aIntervalo.push({Id: 7, Nome: '4/4 hora'})
    this.aIntervalo.push({Id: 8, Nome: '6/6 hora'})
    this.aIntervalo.push({Id: 6, Nome: '8/8 hora'})
    this.aIntervalo.push({Id: 9, Nome: '12/12 hora'})


  }

  ionViewDidEnter() {
    this.selecionarDrogaSistema();

  }

  async selecionaInervalo(x:number) {
   this.DescIntervalo = this.aIntervalo[x].Nome

  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async calculaSolucao() {

    if (this.Droga == 0 || this.Droga == null || this.Droga == undefined) {
      this.lRet = this.utilService.validarCampo('Droga', this.drogaForm.value.Droga, () => {
        (this.ancoraPeso as any).setFocus(true);
      });
      if (this.lRet == false) {
        return;
      }
    }
    console.log("Peso: " + this.Peso)
    if (isNaN(this.Peso/1) || this.Peso == 0 || this.Peso == null || this.Peso == undefined) {
      this.lRet = this.utilService.validarCampo('Peso', this.drogaForm.value.Peso, () => {
        (this.ancoraPeso as any).setFocus(true);
      });
      if (this.lRet == false) {
        return;
      }
    }
    if (this.Vazao == 0 || this.Vazao == null || this.Vazao == undefined) {

      this.lRet = this.utilService.validarCampo('Vazao', this.drogaForm.value.Vazao, () => {
        (this.ancoraVazao as any).setFocus(true);
      });
      if (this.lRet == false) {
        return;
      }
    }
    this.lRet = this.utilService.validarCampo('Ampola', this.drogaForm.value.Qtdampolapadrao, () => {
      (this.ancoraQtdampolapadrao as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }
    this.lRet = this.utilService.validarCampo('Solucao', this.drogaForm.value.Diluicaopadrao, () => {
      (this.ancoraDiluicaopadrao as any).setFocus(true);
    });
    if (this.lRet == false) {
      return;
    }

    this.mostraVariaveis();

    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.presentAlertConfirm();
    }

  }

  async mostraVariaveis() {
    this.Peso = this.drogaForm.value.Peso;
    this.Qtdampolapadrao = this.drogaForm.value.Qtdampolapadrao;
    this.Diluicaopadrao = this.drogaForm.value.Diluicaopadrao;
    this.Vazao = this.drogaForm.value.Vazao;
    let Concentracaopadrao = this.Qtdampolapadrao * this.Fator * this.Ampolaml * this.Ampoladoseml / this.Diluicaopadrao;

    if (this.SiglaUnidadeMedidaInfusao.indexOf("min") > -1) {
      this.Doseatual = (Concentracaopadrao * this.Vazao) / 60;
      ;
    } else {
      this.Doseatual = Concentracaopadrao * this.Vazao

    }

    if (this.Kgbaseado == 1) {
      this.Doseatual = this.Doseatual / this.Peso;
    }
    this.Doseatual = this.utilService.formateNumber(this.Doseatual, 2);
    this.drogaForm.controls['Doseatual'].setValue(this.Doseatual);
    
   


  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Internacao > 0 && parseFloat(this.dadosListMed.Internacao.Peso) >0) {

        this.Peso = this.dadosListMed.Internacao.Peso;
        this.Peso = this.utilService.formateNumberNew(this.Peso, 2);
        
      }
     
      let Droga: number = this.drogaForm.value.Droga;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0 && Droga > 0) {
        this.selecionarDrogalancamento(-1)
      }

      this.limpaDados();


    }
  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.drogaForm.controls['Datalancamento'].setValue(Datalancamento);

    if (this.Logado > 0 && this.Paciente > 0) {
      this.utilService.focusInput(this.ancoraVazao);
    } else {
      this.utilService.focusInput(this.ancoraPeso);
    }


  }



  async selecionarDrogalancamento(Id: number) {
    let Droga: any = this.drogaForm.value.Droga;
    if (Droga == '' || Droga == 0 || Droga == undefined || Droga == null) {
      return;
    }
    this.checklistService.selecionarDrogalancamento(this.Internacao, this.Paciente, Droga).then((response: any) => {
      this.lancamentosCollection = response;
      if (response.length > 0) {
        this.Id = Id;
      } else {
        this.Id = -1;
      }


    }).catch((response) => {
      //this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }

  async selecionarDrogalancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarDrogalancamentoUnico(Id).then((response: any) => {
      this.getDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async limpaDados() {
   
    this.selecionarDataHoje();
    this.Botao = "CALCULAR";
    this.Id = -1;
    this.Doseatual = 0;
    this.drogaForm.controls['Id'].setValue(this.Id);
    this.drogaForm.controls['Peso'].setValue(parseFloat(this.Peso));
    this.drogaForm.controls['Vazao'].setValue(this.Vazao);
    this.drogaForm.controls['Qtdampolapadrao'].setValue(this.Qtdampolapadrao);
    this.drogaForm.controls['Diluicaopadrao'].setValue(this.Diluicaopadrao);
    this.drogaForm.controls['Doseatual'].setValue('');
    setTimeout(()=>{
      this.drogaForm.controls['Droga'].setValue(this.Droga);
      this.drogaForm.controls['Intervalo'].setValue(1);

      
    },500)
   

        
  }






  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.drogaForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarDroga()

          }
        }
      ]
    });
    await alert.present();
  }

  async getDados(response: any) {
    if (response.length == 0) {
      return;
    }

    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    this.drogaForm.controls['Datalancamento'].setValue(Datalancamento);
    this.Botao = "ALTERAR";

    let Relacao: any = response[0].Relacao;
    if (Relacao == '' || Relacao == null || Relacao == undefined) {
      this.utilService.showAlertCallback("Dados incompletos")
      return;
    }
    Relacao = Relacao.split('*')
    this.drogaForm.controls['Peso'].setValue(Relacao[0]);
    this.drogaForm.controls['Vazao'].setValue(Relacao[1]);
    this.drogaForm.controls['Qtdampolapadrao'].setValue(Relacao[2]);
    this.drogaForm.controls['Diluicaopadrao'].setValue(Relacao[3]);
    this.drogaForm.controls['Doseatual'].setValue(Relacao[4]);
    setTimeout(()=>{
      this.drogaForm.controls['Intervalo'].setValue('0') ;
    },1000)
    this.mostraVariaveis();
  }

  exibirPesoPaciente(Peso: number) {
    this.Peso = Peso
  }
  cadastrarDroga() {
    let Relacao = this.drogaForm.value.Peso + '*' +
      this.drogaForm.value.Vazao + '*' +
      this.drogaForm.value.Qtdampolapadrao + '*' +
      this.drogaForm.value.Diluicaopadrao + '*' +
      this.drogaForm.value.Doseatual
      this.lRet
      let Descricao:string="";
     
        Descricao= this.drogaForm.value.Vazao + ' mL/h =>' +
        this.drogaForm.value.Doseatual + ' ' + this.SiglaUnidadeMedidaInfusao +
        ' =>' + (this.Doseatual*100/this.Dosemaxima).toString().split('.')[0] + '% =>' + this.DescIntervalo 

    

    let Datalancamento: any = this.drogaForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);
    let form = {
      Id: this.drogaForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Droga: this.drogaForm.value.Droga,
      Valor: this.drogaForm.value.Doseatual,
      Usuario: this.Usuario,
      Relacao: Relacao,
      IdEscorerel: this.IdEscorerel, // numero de escorerelacao relacionado com a droga
      Descricao: Descricao
    }

    console.log(JSON.stringify(form))

    if (this.drogaForm.value.Id == null || this.drogaForm.value.Id == undefined ||
      this.drogaForm.value.Id == '-1' || this.drogaForm.value.Id == -1 || this.drogaForm.value.Id == '') {
      this.checklistService.adicionarDrogalancamento(form).then((response: any) => {
        this.selecionarDrogalancamento(response[0].Id);
        this.utilService.showAlertCallback(this.Nome + " INCLUIDO com sucesso", null);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível INCLUIR o " + this.Nome, null);
      });
    } else {
      this.checklistService.atualizarDrogalancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ALTERADO com sucesso", null);
        // this.selecionarDrogalancamento();
        // this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

      });
    }



  }






  async selecionarDrogaUnico(Id: number) {
    let x: number = 0;
    for (let i = 0; i < this.drogaCollection.length; i++) {
      if (this.drogaCollection[i].Id == Id) {
        x = i;
        break;
      }
    }

    this.utilService.focusInput(this.ancoraVazao);
    this.Nome = this.drogaCollection[x].Nome;
    this.Ampolaml = this.drogaCollection[x].Ampolaml;
    this.Ampoladoseml = this.drogaCollection[x].Ampoladoseml;
    this.Doseminima = this.drogaCollection[x].Doseminima;
    this.Dosemaxima = this.drogaCollection[x].Dosemaxima;
    this.Fator = this.drogaCollection[x].Fator;
    this.SiglaUnidadeMedidaAmpola = this.drogaCollection[x].SiglaUnidadeMedidaAmpola;
    this.SiglaUnidadeMedidaInfusao = this.drogaCollection[x].SiglaUnidadeMedidaInfusao;
    let suc:any = this.SiglaUnidadeMedidaInfusao.split('/');
    suc = suc[0];

    this.SiglaUnidadeMedidaConcentracao = suc
    this.Qtdampolapadrao = this.drogaCollection[x].Qtdampolapadrao;
    this.Diluicaopadrao = this.drogaCollection[x].Diluicaopadrao;
    this.Concentracaopadrao = this.drogaCollection[x].Concentracaopadrao;
    this.Kgbaseado = this.drogaCollection[x].Kgbaseado;
    this.Observacao= this.drogaCollection[x].Observacao;
    this.limpaDados();
    this.selecionarDrogalancamento(-1);
    this.Droga = Id;
  }
  async calcularDiasSuspensao(Datasuspensao: any) {
    Datasuspensao = new Date(Datasuspensao);
    let Datalancamento = new Date(this.drogaForm.value.Datalancamento);
    let diff = Math.abs(Datasuspensao.getTime() - Datalancamento.getTime());
    this.Diasproposto = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }

  async selecionarDrogaSistema() {
    this.Sistema = 0;
    this.checklistService.selecionarDrogaSistema(this.Sistema).then((response: any) => {
      this.drogaCollection = response;
      this.testaAcessoSistema();

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar as drogas", null)
    });
  }



}
