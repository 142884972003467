import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TabelaService {

  constructor(
    private http: HttpClient, 
    private utilService: UtilService,
    private loadingController: LoadingController
    ) { }

  selecionarTabelasSistema() {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/selecionarTabelasSistema';
    return this.http.get(url).toPromise();
  }

  selecionaColunasTabelaNome(tabela:string) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/selecionaColunasTabelaNome/' + tabela;
    return this.http.get(url).toPromise();
  }

  selecionarTabela(tabela:string, colunas:string) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/selecionarTabela/' + tabela + '/' + colunas;
    return this.http.get(url).toPromise();
  }

  selecionarTabelaId(tabela:string, colunas:string, id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/selecionarTabelaId/' + tabela + '/' + colunas + '/' + id;
    return this.http.get(url).toPromise();
  }

  selecionarTabelaNome(tabela:string, colunas:string, nome:string) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/selecionarTabelaNome/' + tabela + '/' + colunas + '/' + nome;
    return this.http.get(url).toPromise();
  }

  enviarFotoComprimida(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Util/enviarFotoComprimida';
    let httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    return this.http.post(url, form, httpOptions).toPromise();
  }
  enviarFotoSemComprimir(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Util/enviarFotoSemComprimir';
    let httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    return this.http.post(url, form, httpOptions).toPromise();
  }
  


  enviarImagemTabela(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Util/enviarImagemTabela';
    let httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA'
      })
    };
    return this.http.post(url, form, httpOptions).toPromise();
  }

  adicionarTabela(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/adicionarTabela';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }


  atualizarTabela(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/atualizarTabela';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarTabelaImagem(imagem: any) {
    let url = this.utilService.obterUrlDaApi() + 'Tabela/atualizarTabelaImagem';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(url, imagem, { headers: headers }).toPromise();
  }

}
