import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-coagulograma',
  templateUrl: './coagulograma.component.html',
  styleUrls: ['./coagulograma.component.scss'],
})
export class CoagulogramaComponent implements OnInit {
  @ViewChild('ancoraIdcoagulograma', null) ancoraIdcoagulograma: ElementRef;
  @ViewChild('ancoraDatalancamentocoagulograma', null) ancoraDatalancamentocoagulograma: ElementRef;
  @ViewChild('ancoraProvadolaco', null) ancoraProvadolaco: ElementRef;
  @ViewChild('ancoraTemposangramento', null) ancoraTemposangramento: ElementRef;
  @ViewChild('ancoraTempoprotrombina', null) ancoraTempoprotrombina: ElementRef;
  @ViewChild('ancoraRni', null) ancoraRni: ElementRef;
  @ViewChild('ancoraTempotromboplastina', null) ancoraTempotromboplastina: ElementRef;
  @ViewChild('ancoraTempotrombina', null) ancoraTempotrombina: ElementRef;
  @ViewChild('ancoraFibrinogenio', null) ancoraFibrinogenio: ElementRef;
  @ViewChild('ancoraAntitrombinaIII', null) ancoraAntitrombinaIII: ElementRef;
  @ViewChild('ancoraAgregacaoplaquetaria', null) ancoraAgregacaoplaquetaria: ElementRef;
  @ViewChild('ancoraTempocoaguloativado', null) ancoraDdimero: ElementRef;
  @ViewChild('ancoraDdimero', null) ancoraCalcio: ElementRef;
  @ViewChild('ancoraReticulocitos', null) ancoraReticulocitos: ElementRef;
  @ViewChild('ancoraVhs', null) ancoraVhs: ElementRef;
  @ViewChild('ancoraFalcizacao', null) ancoraFalcizacao: ElementRef;
  @ViewChild('ancoraPdf', null) ancoraPdf: ElementRef;

  private IdGeral:number = 0;
  private Exames:any;
  private Datalancamentocoagulograma:string="";
  private Usuario:number = 0;
  private Paciente: number = 0;
  private Idade:string = "";
  private Nome:string = "";
  private Internacao: number = 0;
  private Historiaclinica:string = "";
  private Setor:string = "";
  private Leito:any = "";
  public coagulogramaForm: FormGroup;
  public Botaocoagulograma:string = "Cadastrar";
  private Datainternacao:string = "";
  private Dias:string = "";
  private coagulogramaCollection: any = [];
  public dadosListMed:any;
  private Logado:number=0;
  public Provadolaco:number=0;
  public Temposangramento:number=0;
  public Tempocoagulacao:number=0;
  public Tempoprotrombina:number=0;
  public Rni:number=0;
  public Tempotromboplastina:number=0;
  public Tempotrombina:number=0;
  public Fibrinogenio:number=0;
  public AntitrombinaIII:number=0;
  public Agregacaoplaquetaria:number=0;
  public Tempocoaguloativado:number=0;
  public Ddimero:number=0;
  public Reticulocitos:number=0;
  public Vhs:number=0;
  public Falcizacao:number=0;
  public Pdf:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService : LaboratorioService,
    private alertController:AlertController,
    ) {

      this.coagulogramaForm = formBuilder.group({
        
        Idcoagulograma: ['', ''],
        Datalancamentocoagulograma: ['', ''],
        Provadolaco: ['', ''],
        Temposangramento:['', ''],
        Tempocoagulacao:['', ''],
        Tempoprotrombina:['', ''],
        Rni:['', ''],
        Tempotromboplastina:['', ''],
        Tempotrombina:['', ''],
        Fibrinogenio:['', ''],
        AntitrombinaIII:['', ''],
        Agregacaoplaquetaria:['', ''],
        Tempocoaguloativado:['', ''],
        Ddimero:['', ''],
        Reticulocitos:['', ''],
        Vhs:['', ''],
        Falcizacao:['', ''],
        Pdf:['', ''],
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
     
    }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarCoagulograma(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao=  this.dadosListMed.Internacao.Id;
    this.Usuario =this.dadosListMed.Internacao[0].Usuario;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosCoagulograma();
  
  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
     let valor:number = eval('this.coagulogramaForm.value.' + this.Exames[i].nome);
     if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax ) {
      x = x + 1
      if (x == 1) {
        cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
        parametro = this.Exames[i].nome;
      }
     // this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg , 2000, null)
      this.coagulogramaForm.controls[this.Exames[i].nome].setValue(valor);
      cValores =  cValores + '-> '+ this.Exames[i].msg + ": " + valor +  ' (' + this.Exames[i].vmin + '-' +  this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
     }
  }

  
    let tipo = parseInt(this.coagulogramaForm.value.Idcoagulograma);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'COAGULOGRAMA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroCoagulograma();
          }
        }
      ]
    });

    await alert.present();
  }

  cadastroCoagulograma() {
    let form = {
      Id: this.coagulogramaForm.value.Idcoagulograma,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.coagulogramaForm.value.Datalancamentocoagulograma),
      Usuario: this.Usuario,
      Provadolaco: this.Provadolaco,
      Temposangramento: this.coagulogramaForm.value.Temposangramento,
      Tempocoagulacao:this.coagulogramaForm.value.Tempocoagulacao,
      Tempoprotrombina:this.coagulogramaForm.value.Tempoprotrombina,
      Rni:this.coagulogramaForm.value.Rni,
      Tempotromboplastina:this.coagulogramaForm.value.Tempotromboplastina,
      Tempotrombina:this.coagulogramaForm.value.Tempotrombina,
      Fibrinogenio:this.coagulogramaForm.value.Fibrinogenio,
      AntitrombinaIII:this.coagulogramaForm.value.AntitrombinaIII,
      Agregacaoplaquetaria:this.coagulogramaForm.value.Agregacaoplaquetaria,
      Tempocoaguloativado:this.coagulogramaForm.value.Tempocoaguloativado,
      Ddimero:this.coagulogramaForm.value.Ddimero,
      Reticulocitos:this.coagulogramaForm.value.Reticulocitos,
      Vhs:this.coagulogramaForm.value.Vhs,
      Falcizacao:this.Falcizacao,
      Pdf:this.coagulogramaForm.value.Pdf,
    }

     
    
    
    if (this.coagulogramaForm.value.Idcoagulograma == 0 || this.coagulogramaForm.value.Idcoagulograma == undefined || this.coagulogramaForm.value.Idcoagulograma == null || this.coagulogramaForm.value.Idcoagulograma == '') {
      this.laboratorioService.adicionarCoagulograma(form).then((response) => {
        this.selecionarCoagulograma(response[0].Id);
        this.utilService.showToastCallBack("Coagulograma incluido com sucesso", 2000, null);
      

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Coagulograma", 2000, null);

      });
    } else {
      this.laboratorioService.atualizarCoagulograma(form).then((response) => {
        this.utilService.showToastCallBack("Coagulograma atualizado com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Coagulograma", 2000, null);

      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresCoagulograma()
    .then((response) => {
      this.Exames = response;

    })
    .catch((response) => {

      this.Exames = [];
    });
  }
  async selecionarCoagulogramaUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosCoagulograma();
      return ;
    }
    this.laboratorioService.selecionarCoagulogramaUnico(Id).then((response) => {
      this.getBalancoCoagulograma(response);
    }).catch((response) => {

    });
  }

  async selecionaProvadolaco() {
    if (this.coagulogramaForm.value.Provadolaco == true) {
      this.Provadolaco = 1;
    } else {
      this.Provadolaco = 0;
    }
  }

  async selecionaProvadoFalcizacao() {
    if (this.coagulogramaForm.value.Falcizacao == true) {
      this.Falcizacao = 1;
    } else {
      this.Falcizacao = 0;
    }
  }

  async limpaDadosCoagulograma() {
    this.Botaocoagulograma = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentocoagulograma = dtHoje;
    this.coagulogramaForm.controls['Datalancamentocoagulograma'].setValue(dtHoje);
    this.coagulogramaForm.controls['Provadolaco'].setValue(false);
    this.coagulogramaForm.controls['Falcizacao'].setValue(false);
    this.coagulogramaForm.controls['Temposangramento'].setValue(0);
    this.coagulogramaForm.controls['Tempocoagulacao'].setValue(0);
    this.coagulogramaForm.controls['Tempoprotrombina'].setValue(0);
    this.coagulogramaForm.controls['Rni'].setValue(0);
    this.coagulogramaForm.controls['Tempotromboplastina'].setValue(0);
    this.coagulogramaForm.controls['Tempotrombina'].setValue(0);
    this.coagulogramaForm.controls['Fibrinogenio'].setValue(0);
    this.coagulogramaForm.controls['AntitrombinaIII'].setValue(0);
    this.coagulogramaForm.controls['Agregacaoplaquetaria'].setValue(0);
    this.coagulogramaForm.controls['Tempocoaguloativado'].setValue(0);
    this.coagulogramaForm.controls['Ddimero'].setValue(0);
    this.coagulogramaForm.controls['Reticulocitos'].setValue(0);
    this.coagulogramaForm.controls['Vhs'].setValue(0);
    this.coagulogramaForm.controls['Falcizacao'].setValue(0);
    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraTemposangramento'));
  }

  async getBalancoCoagulograma(response: any) {
    this.Botaocoagulograma = "Alterar";
    let Datalancamentocoagulograma = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.coagulogramaForm.controls['Datalancamentocoagulograma'].setValue(Datalancamentocoagulograma);
    this.coagulogramaForm.controls['Provadolaco'].setValue(response[0].Provadolaco);
    this.coagulogramaForm.controls['Falcizacao'].setValue(response[0].Falcizacao);
    this.coagulogramaForm.controls['Temposangramento'].setValue(response[0].Temposangramento.replace(",", "."));
    this.coagulogramaForm.controls['Tempocoagulacao'].setValue(response[0].Tempocoagulacao.replace(",", "."));
    this.coagulogramaForm.controls['Tempoprotrombina'].setValue(response[0].Tempoprotrombina.replace(",", "."));
    this.coagulogramaForm.controls['Rni'].setValue(response[0].Rni.replace(",", "."));
    this.coagulogramaForm.controls['Tempotromboplastina'].setValue(response[0].Tempotromboplastina.replace(",", "."));
    this.coagulogramaForm.controls['Tempotrombina'].setValue(response[0].Tempotrombina.replace(",", "."));
    this.coagulogramaForm.controls['Fibrinogenio'].setValue(response[0].Fibrinogenio.replace(",", "."));
    this.coagulogramaForm.controls['AntitrombinaIII'].setValue(response[0].AntitrombinaIII.replace(",", "."));
    this.coagulogramaForm.controls['Agregacaoplaquetaria'].setValue(response[0].Agregacaoplaquetaria.replace(",", "."));
    this.coagulogramaForm.controls['Tempocoaguloativado'].setValue(response[0].Tempocoaguloativado.replace(",", "."));
    this.coagulogramaForm.controls['Ddimero'].setValue(response[0].Ddimero.replace(",", "."));
    this.coagulogramaForm.controls['Reticulocitos'].setValue(response[0].Reticulocitos.replace(",", "."));
    this.coagulogramaForm.controls['Vhs'].setValue(response[0].Vhs.replace(",", "."));
    this.coagulogramaForm.controls['Pdf'].setValue(response[0].Pdf.replace(",", "."));

  }

  async selecionarCoagulograma(seek:number) {
    this.laboratorioService.selecionarCoagulograma(this.Internacao, this.Paciente).then((response) => {
      this.coagulogramaCollection = response;
        this.IdGeral = seek;
      
    }).catch((response) => {

    });
  }



  async mostrarValor(campo:string) {
    let valor: any = eval('this.coagulogramaForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == NaN || valor == '') {
      this.coagulogramaForm.controls[campo].setValue(valor);
    }

   
    if (campo == "Temposangramento") {
      this.Temposangramento = valor;
    } else if (campo == "Tempocoagulacao") {
      this.Tempocoagulacao = valor;
    } else if (campo == "Tempoprotrombina") {
      this.Tempoprotrombina = valor;
    } else if (campo == "Tempotrombina") {
      this.Tempotrombina = valor;
    } else if (campo == "Rni") {
      this.Rni = valor;
    } else if (campo == "Tempotromboplastina") {
      this.Tempotromboplastina = valor;
    } else if (campo == "Fibrinogenio") {
      this.Fibrinogenio = valor;
    } else if (campo == "AntitrombinaIII") {
      this.AntitrombinaIII = valor;
    } else if (campo == "Agregacaoplaquetaria") {
      this.Agregacaoplaquetaria = valor;
    } else if (campo == "Tempocoaguloativado") {
      this.Tempocoaguloativado = valor;
    } else if (campo == "Ddimero") {
      this.Ddimero = valor;
    } else if (campo == "Reticulocitos") {
      this.Reticulocitos = valor;
    } else if (campo == "Vhs") {
      this.Vhs = valor;
    } else if (campo == "Pdf") {
      this.Pdf = valor;
    } 
  }


}

