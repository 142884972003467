import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, LoadingController, NavParams } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-fotousuario',
  templateUrl: './fotousuario.component.html',
  styleUrls: ['./fotousuario.component.scss'],
})
export class FotousuarioComponent implements OnInit {
  public IdUsuario: number= 0;
  public Logado:number=0;
  public NomeUsuario:string="";
  private DadosUsuario:any=[];
  private FotoAtualUsuario:string='';
  private DataNascimento:any;
  private EmailUsuario:string='';
  private NumeroConselhoUsuario:string='';
  private IdadeUsuario:string='';
  private SiglaConselho :string='';
  private CurriculoUsuario:string="";
  private CelularUsuario:string="";
  private Menu:string="";
  Usuario:number=0;
  constructor( 
    private modalController:ModalController,
    private navParams:NavParams,
    private checklistService:ChecklistService,
    private alertController:AlertController,
    private utilService:UtilService

) { 

  ;

      this.NomeUsuario= this.navParams.get('NomeUsuario');
      this.EmailUsuario= this.navParams.get('EmailUsuario');
      this.CelularUsuario= this.navParams.get('CelularUsuario');
      this.CurriculoUsuario= this.navParams.get('CurriculoUsuario');
      this.FotoAtualUsuario= this.navParams.get('FotoAtualUsuario');
      this.Menu= this.navParams.get('Menu')
  }

  ngOnInit() {}

  async closeModal() {

    this.modalController.dismiss();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

}
