import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-medicamento',
  templateUrl: './medicamento.component.html',
  styleUrls: ['./medicamento.component.scss'],
})
export class MedicamentoComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraDatasuspensao', null) ancoraDatasuspensao: ElementRef;
  @ViewChild('ancoraMedicamento', null) ancoraMedicamento: ElementRef;
  @ViewChild('ancoraAmpola', null) ancoraAmpola: ElementRef;
  @ViewChild('ancoraSolucao', null) ancoraSolucao: ElementRef;
  @ViewChild('ancoraPosicao', null) ancoraPosicao: ElementRef;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;
  @ViewChild('ancoraVazao', null) ancoraVazao: ElementRef;
  private Quantidade: number = 0;
  private Posologia: number = 0;
  private Intervalo: number = 0;
  private Id: number = 0
  private Logado: number = 0;
  private Header: string = "";
  private Usuario: number = 0;
  private Paciente: number = 0;
  private Idade: string = "";
  private Nome: string = "";
  private Internacao: number = 0;
  private Historiaclinica: string = "";
  private Setor: string = "";
  private Leito: string = "";
  public medicamentoForm: FormGroup;
  public Botao: string = "Cadastrar";
  public Datainternacao: string = "";
  public Dias: string = "";
  public Diasproposto: number = 0;
  private medicamento: number = 0;
  private Ampola: number = 0;
  private Solucao: number = 0;
  private Vazao: number = 0;
  private medicamentoColection: any = [];
  private medicamentousoColection: any = [];
  private posicaoColection: any = [];
  private acessoColection: any = [];
  private lRet: any;
  public dadosListMed: any;
  private Unidadedose: string = "";
  private Unidadeconcentracao: string = "";
  private Unidadeinfusao: string = "";
  private Concentracao: number = 0;
  private Fator: number = 0;
  private Peso: number = 0;
  private Dose: number = 0;
  private Doseminima: number = 0;
  private Dosemaxima: number = 0;
  private Dosepercentual: number = 0;
  private Observacao:string="";
  private Diluicaopadrao:number=0;
  private Qtdampolapadrao:number=0;
  private Concentracaopadrao:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private alertController: AlertController,
    private checklistService:ChecklistService) {
    this.medicamentoForm = formBuilder.group({
      Id: ['', ''],
      Medicamento: ['', ''],
      Datalancamento: ['', ''],
      Datasuspensao: ['', ''],
      Ampola: ['', ''],
      Solucao: ['', ''],
      Vazao: ['', ''],
      Posicao: ['', ''],
      Observacao: ['', ''],
      Diasproposto: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();


  }
  ngOnInit() {

    this.posicaoColection = [
      { Nome: 'Em curso', Id: 1 },
      { Nome: 'Contra-indicado', Id: 2 },
      { Nome: 'Suspenso temporariamento', Id: 3 },
      { Nome: 'Tratamento concluído', Id: 4 },
      { Nome: 'Excluido', Id: 5 },

    ]
  }

  ionViewDidEnter() {
    this.limpaDadosMedicamentoUso()
    this.testaAcessoSistema();
    this.selecionarDataHoje();
    this.selecionarMedicamento();
    this.selecionarMedicamentoUso(0);
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Idade = this.dadosListMed.Internacao.Idade;
      this.Nome =  this.dadosListMed.Internacao.Nome;
      this.Internacao = this.dadosListMed.Internacao.Id;;
      this.Setor =  (this.dadosListMed.Internacao[0].Setor);
      this.Leito =  (this.dadosListMed.Internacao[0].Leito);
      this.Datainternacao = this.dadosListMed.Internacao[0].Datainternacao;
      this.Dias = this.dadosListMed.Internacao[0].Dias;
    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pagina");
      return;

    }
  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    this.medicamentoForm.controls['Datalancamento'].setValue(dtHoje);

  }




  async selecionarMedicamentoUsoUnico(Id: number) {

    if (Id == 0) {
      this.limpaDadosMedicamentoUso();
      
      return;
    }

    this.lancamentoService.selecionarMedicamentoUsoUnico(Id).then((response) => {
      this.getMedicamento(response);
    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível selecionar o medicamento", 2000, null);
    });
  }


  async limpaDadosMedicamentoUso() {
    this.Botao = "Cadastrar";
    this.Id = 0;
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.getDataBrowser(dtHoje);
    let Datasuspensao = this.utilService.somaDiaData(dtHoje, this.Diasproposto) ;
    this.medicamentoForm.controls['Datalancamento'].setValue('');
    this.medicamentoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.medicamentoForm.controls['Datasuspensao'].setValue('');
    this.medicamentoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.medicamentoForm.controls['Ampola'].setValue(0);
    this.medicamentoForm.controls['Medicamento'].setValue(0);
    this.medicamentoForm.controls['Observacao'].setValue('');
    this.medicamentoForm.controls['Ampola'].setValue(0);
    this.medicamentoForm.controls['Solucao'].setValue(0);
    this.medicamentoForm.controls['Vazao'].setValue(0);
    this.medicamentoForm.controls['Posicao'].setValue(1);
    this.Unidadedose = '';
    this.Unidadeconcentracao = '';
    this.Unidadeinfusao = '';
    this.Doseminima = 0;
    this.Dosemaxima = 0;
    this.Dosepercentual = 0;
    this.Concentracao= 0;
    this.Solucao=0;
    this.Diluicaopadrao = 0;
    this.Qtdampolapadrao=0;
    this.Concentracaopadrao=0;
    return ;
  }

  async getMedicamento(response: any) {
    this.Botao = "Alterar";
    let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    let Datasuspensao = this.utilService.getDataBrowser(response[0].Datasuspensao);
  
    this.Solucao = parseFloat(response[0].Solucao.replace(".", ","));
    this.Ampola = parseFloat(response[0].Ampola.replace(".", ","));
    this.Vazao = parseFloat(response[0].Vazao.replace(".", ","));
    this.Fator = parseFloat(response[0].Fator);
    this.Peso = parseFloat(response[0].Peso);
    
    this.medicamentoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.medicamentoForm.controls['Datasuspensao'].setValue(Datasuspensao);

    this.medicamentoForm.controls['Ampola'].setValue(this.Ampola);
    this.medicamentoForm.controls['Observacao'].setValue(response[0].Observacao);
    this.medicamentoForm.controls['Posicao'].setValue(response[0].Posicao);
    this.medicamentoForm.controls['Solucao'].setValue(this.Solucao);
    this.medicamentoForm.controls['Vazao'].setValue(this.Vazao);
    this.medicamentoForm.controls['Medicamento'].setValue(response[0].Medicamento);
    this.Observacao = response[0].Observacao;
    this.selecionaDadosMedicamento(response[0].Medicamento);
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async presentAlertConfirm() {
    let tipo = parseInt(this.medicamentoForm.value.Id);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Medicamento',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraObservacao'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrarMedicamento();
          }
        }
      ]
    });

    await alert.present();
  }


  cadastrarMedicamento() {


    this.lRet = this.utilService.validarCampo('Medicamento', this.medicamentoForm.value.Medicamento, () => {
      (this.ancoraObservacao as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }

    this.lRet = this.utilService.validarCampo('Ampola', this.medicamentoForm.value.Ampola, () => {
      (this.ancoraAmpola as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }

    this.lRet = this.utilService.validarCampo('Solucao', this.medicamentoForm.value.Solucao, () => {
      (this.ancoraSolucao as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }

    this.lRet = this.utilService.validarCampo('Vazao', this.medicamentoForm.value.Vazao, () => {
      (this.ancoraVazao as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }

    this.lRet = this.utilService.validarCampo('Posicao', this.medicamentoForm.value.Posicao, () => {
      (this.ancoraObservacao as any).setFocus(true);

    });
    if (this.lRet == false) {
      return;
    }



      let form = {
      Id: this.medicamentoForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      medicamento: this.medicamentoForm.value.Medicamento,
      Datalancamento: new Date(this.medicamentoForm.value.Datalancamento),
      Datasuspensao: new Date(this.medicamentoForm.value.Datasuspensao),
      Usuario: this.Usuario,
      Ampola: this.medicamentoForm.value.Ampola,
      Observacao: this.medicamentoForm.value.Observacao,
      Posicao: this.medicamentoForm.value.Posicao,
      Solucao: this.medicamentoForm.value.Solucao,
      Vazao: this.medicamentoForm.value.Vazao
    }


    if (this.medicamentoForm.value.Id == '' || this.medicamentoForm.value.Id == null || this.medicamentoForm.value.Id == undefined) {
      this.lancamentoService.adicionarMedicamentoUso(form).then((response) => {
        this.utilService.showToastCallBack("Solução do medicamento incluido com sucesso", 2000, null);
        this.selecionarMedicamentoUso(response[0].Id);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o medicamento", 2000, null);

      });
    } else {
      this.lancamentoService.atualizarMedicamentoUso(form).then((response) => {
        this.utilService.showToastCallBack("Solução de medicamento atualizado com sucesso", 2000, null);
        this.selecionaDadosMedicamento(this.medicamentoForm.value.Medicamento);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o medicamento", 2000, null);

      });
    }

  }

  async selecionarMedicamento() {
    this.lancamentoService.selecionarMedicamento().then((response) => {
      this.medicamentoColection = response;

    }).catch((response) => {

    });
  }

  selecionaDadosMedicamento(Medicamento: number) {
    for (let i = 0; i < this.medicamentoColection.length; i++) {
      if (this.medicamentoColection[i].Id == Medicamento) {
        this.Unidadedose = this.medicamentoColection[i].Unidadedose;
        this.Unidadeconcentracao = this.medicamentoColection[i].Unidadeconcentracao;
        this.Unidadeinfusao = this.medicamentoColection[i].Unidadeinfusao;
        let Ampolaml = this.medicamentoColection[i].Ampolaml.replace(",", ".");
        let Ampoladoseml = this.medicamentoColection[i].Ampoladoseml.replace(",", ".");
        let Fator = this.medicamentoColection[i].Fator.replace(",", ".");
        let Kgbaseado = this.medicamentoColection[i].Kgbaseado
        this.Doseminima = parseFloat(this.medicamentoColection[i].Doseminima.replace(",", "."));
        this.Dosemaxima = parseFloat(this.medicamentoColection[i].Dosemaxima.replace(",", "."));
        let Droga = this.Ampola * parseFloat(Ampolaml) * parseFloat(Ampoladoseml) * parseFloat(Fator);
       
        if (this.Solucao > 0) {
          this.Concentracao = Droga / this.Solucao;
        }else{
          this.Concentracao = Ampoladoseml; // caso do propofol
          if (this.medicamentoForm.value.Ampola == 0 ){
            this.Concentracao =0;
            this.Dosepercentual = 0;
            this.Dose=0;
            this.Dosepercentual = 0;
          }
        }
        
        let fHora = 1
        if (this.Unidadedose.indexOf("min") > 0) {
          fHora = 60
        }
        this.Dose = (this.Concentracao * this.Vazao) / fHora;
        if (Kgbaseado == 1) {
          this.Dose = this.Dose / this.Peso;
        }

        this.Dosepercentual = this.Dose * 100 / this.Dosemaxima
        this.Diluicaopadrao = parseInt(this.medicamentoColection[i].Diluicaopadrao);
        this.Qtdampolapadrao = parseInt(this.medicamentoColection[i].Qtdampolapadrao);
        this.Concentracaopadrao = parseInt(this.medicamentoColection[i].Concentracaopadrao.replace(",", "."));
        
      }
    }

  }

  async selecionarMedicamentoUso(Id: number) {
    this.lancamentoService.selecionarMedicamentoUso(this.Internacao, this.Paciente).then((response) => {
      this.medicamentousoColection = response;
      setTimeout(() => {
       this.Id = Id;
    
      
      }, 2000); 
   

    }).catch((response) => {

    });
  }

  

  async mostrarValor(campo: string) {
    let valor: any = eval('this.medicamentoForm.value.' + campo);

    if (campo == "Ampola") {
      this.Ampola = valor;
    } else if (campo == "Solucao") {
      this.Solucao = valor;
    } else if (campo == "Vazao") {
      this.Vazao = valor;
    } else  if (campo == "Diasproposto") {
        this.Diasproposto = valor;
        let Datasuspensao = this.utilService.somaDiaData(this.medicamentoForm.value.Datalancamento, valor);
        this.medicamentoForm.controls['Datasuspensao'].setValue(Datasuspensao);
        this.Observacao = "Quantidade de Ampolas: " + this.Ampola +  ".\nSolução total: " + this.Solucao + " mL.\nVazão em bomba de infusão: " +this.Vazao + ' mL.' 
        this.selecionaDadosMedicamento(this.medicamentoForm.value.Medicamento);
    }

    

  }

  async calcularDiasSuspensao(Datasuspensao: any) {
    Datasuspensao = new Date(Datasuspensao);
    let Datalancamento = new Date(this.medicamentoForm.value.Datalancamento);
    let diff = Math.abs(Datasuspensao.getTime() - Datalancamento.getTime());
    this.Diasproposto = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }



}