import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { AlertController, ModalController, NavParams,LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';




@Component({
  selector: 'app-alta',
  templateUrl: './alta.component.html',
  styleUrls: ['./alta.component.scss'],
})
export class AltaComponent implements OnInit {
  Id:number=0;
  Paciente: number = 0;
  Usuario: number = 0;
  Idade: number = 0;
  Header: string = "";
  Nome: string = "";
  Internacao: number = 0;
  altaForm: FormGroup;
  Botao: string = "Cadastrar";
  Alta: any = [];
  Setor: string = "";
  Leito: string = "";
  Datainternacao: string = "";
  Dias: string = "";
  dadosListMed: any;
  Logado: number = 0;
  idUsuario: number = 0;
  DescricaoSetor:string='';
  Creatinina:number=0;
  DescricaoHospital:string='';
  Transferencia:boolean= false;
  Obito:boolean= false;
  Data:string="";
  @ViewChild('ancoraRelatorio', null) ancoraRelatorio: ElementRef;

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private loadingController:LoadingController,
    private alertController:AlertController,
    private checklistService:ChecklistService
    ) {
    this.altaForm = formBuilder.group({
    Id: ['', ''],
    Internacao: ['', ''],
    Paciente: ['', ''],
    Data: ['', ''],
    Transferencia: ['', ''],
    Obito: ['', ''],
    Relatorio: ['', ''],
    Usuario: ['', '']

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Header= this.navParams.get('header');
    this.idUsuario= this.navParams.get('usuario');
    this.Paciente= this.navParams.get('paciente');
    this.Idade= this.navParams.get('idade');
    this.Nome= this.navParams.get("Nome");
    this.Internacao= this.navParams.get('internacao')
    this.Creatinina= this.navParams.get('creatinina');
    this.Alta= this.navParams.get('alta');
    this.Setor= this.navParams.get('setor');
    this.Leito= this.navParams.get('leito');
    this.DescricaoSetor= this.navParams.get('descricaosetor');
    this.DescricaoHospital= this.navParams.get('descricaohospital')
    this.Nome= this.navParams.get('nome')
 
  }

  ngOnInit() {
    this.utilService.Descriptografar( this.Nome).then((nP:string)=>{
      this.Nome = nP;
    })
  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.selecionaAltaInternacao();
  }

  async presentAlertDelete() {
    let message: string = 'Deseja DELETAR esses ALTA?'
       const alert = await this.alertController.create({
     header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
             this.deletarAltaInternacao();

          }
        }
      ]
    });

    await alert.present();
  }

  async deletarAltaInternacao() {
    this.pacienteService.deletarAltaInternacao(this.Internacao).then((response:any) => {
      this.utilService.showAlertCallback("Alta DELETADA com sucesso")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possivel DELETAR a alta")
    })
  }

  async limpaDados() {
    let Data = this.utilService.dataHoje();
    this.altaForm.controls['Data'].setValue(Data);
    this.altaForm.controls['Usuario'].setValue(this.Usuario);
    this.altaForm.controls['Paciente'].setValue(this.Paciente);
    this.altaForm.controls['Internacao'].setValue(this.Internacao);
    this.altaForm.controls['Transferencia'].setValue(false);
    this.altaForm.controls['Obito'].setValue(false);
    this.altaForm.controls['Relatorio'].setValue('');
     this.Data = Data;
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
        {
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionaAltaInternacao() {
    this.utilService.focusInput(this.ancoraRelatorio);
    this.pacienteService.selecionarAltaInternacao(this.Internacao).then((response:any) => {
      if (response.length>0 ) {
        this.Botao = "ALTERAR"
        this.getDadosAlta(response);
      }else{
        this.Botao = "CADASTRAR"
        this.limpaDados();
      }
     
    }).catch((response) => {
      this.Botao = "CADASTRAR"
      this.limpaDados();
    });
  }

  exibirIdUsuario(usuario: number) {
    this.idUsuario = usuario;


  }

  async getDadosAlta(response:any) {
    let Data = this.utilService.getDataBrowser(response[0].Data);
    let Transferencia:any = response[0].Transferencia;
    if (Transferencia == 1) {
      Transferencia = true;
    }else{
      Transferencia = false;

    }
    let Obito:any = response[0].Obito;
    if (Obito == 1) {
      Obito = true;
    }else{
      Obito = false;

    }

    let Id:number = response[0].Id;
    let Usuario:number = response[0].Usuario;
    let Paciente:number = response[0].Paciente;
    let Internacao:number = response[0].Internacao;
    let Relatorio:string = response[0].Relatorio;

    this.utilService.Descriptografar( Relatorio).then((rl:string)=>{
      Relatorio= rl;
      this.altaForm.controls['Id'].setValue(Id);
      this.altaForm.controls['Internacao'].setValue(Internacao);
      this.altaForm.controls['Paciente'].setValue(Paciente);
      this.altaForm.controls['Usuario'].setValue(Usuario);
      this.altaForm.controls['Data'].setValue(Data);
      this.altaForm.controls['Transferencia'].setValue(Transferencia);
      this.altaForm.controls['Obito'].setValue(Obito);
      this.altaForm.controls['Relatorio'].setValue(Relatorio);
      this.Data = Data;
      this.Id = Id;
    })
   
  }
  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pacienterelacao/" +this.Usuario);
      return;

    }
  }
  async cadastarAlta() {
    let loading = await this.loadingController.create({
      message: 'Verificando dados da internacao...',
    });
    loading.present();

    if (this.altaForm.value.Relatorio == undefined ||
      this.altaForm.value.Relatorio == '') {
      this.utilService.showAlertCallback('Você precisa informar a relatório de alta',  () => {
        (this.ancoraRelatorio as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    let Relatorio:string= this.altaForm.value.Relatorio;

    

    if (this.altaForm.value.Data == undefined ||
      this.altaForm.value.Data == '' ||  this.altaForm.value.Data == null) {
      this.utilService.showAlertCallback('Você precisa informar o Data de alta',  () => {
        (this.ancoraRelatorio as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    if (this.altaForm.value.Paciente == undefined ||
      this.altaForm.value.Paciente == '' ||  this.altaForm.value.Paciente == null) {
      this.utilService.showAlertCallback('Você precisa informar o Paciente de alta', () => {
        (this.ancoraRelatorio as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    if (this.altaForm.value.Internacao == undefined ||
      this.altaForm.value.Internacao == '' ||  this.altaForm.value.Internacao == null) {
      this.utilService.showAlertCallback('Você precisa informar o Internação de alta',  () => {
        (this.ancoraRelatorio as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    if (this.altaForm.value.Usuario == undefined ||
      this.altaForm.value.Usuario == '' ||  this.altaForm.value.Usuario == null) {
      this.utilService.showAlertCallback('Você precisa informar o Usuário de alta',  () => {
        (this.ancoraRelatorio as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    


    let Id:number = this.altaForm.value.Id;
    let Internacao:number = this.altaForm.value.Internacao;
    let Paciente:number = this.altaForm.value.Paciente;
    let Usuario:number = this.altaForm.value.Usuario;
    let Obito:any = this.altaForm.value.Obito;
    let Transferencia:any = this.altaForm.value.Transferencia;
    let Data:string= this.altaForm.value.Data;

    Data = this.utilService.checaData(Data);

    if (Transferencia == true){
      Transferencia = 1
    }else{
      Transferencia = 0
    }

    if (Obito == true){
      Obito = 1
    }else{
      Obito = 0
    }

    this.utilService.Criptografar( Relatorio).then((rl:string)=>{
      Relatorio= rl;
      this.Alta = {
        Id: Id,
        Internacao: Internacao,
        Paciente: Paciente,
        Usuario: Usuario,
        Data: Data,
        Transferencia: Transferencia,
        Obito: Obito,
        Relatorio:  Relatorio
      }
    })

    

    loading.dismiss();

    
    this.presentAlertConfirm();
   
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados?'
    let Id: number = this.altaForm.value.Id
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados?'
    }

    const alert = await this.alertController.create({

      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            if (Id > 0) {
              this.atualizarAlta();
            } else {
              this.adicionarAlta();
            }

          }
        }
      ]
    });

    await alert.present();
  }

  async atualizarAlta() {
    let loading = await this.loadingController.create({
      message: 'Atualizando dados da alta...',
    });
    loading.present();

    this.pacienteService.atualizarAlta(this.Alta)
    .then((response) => {
      this.utilService.showAlertCallback('Alta atualizada com sucesso', null)
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível atualizar a Alta', null)
    }).finally(()=>{
      loading.dismiss();
    })

  }

  async adicionarAlta() {
    let loading = await this.loadingController.create({
      message: 'Adicionando dados da alta...',
    });
    this.pacienteService.adicionarAlta(this.Alta)
    .then((response) => {
      this.Id = response[0].Id;
      this.Botao= "ALTERAR"
      this.utilService.showAlertCallback('Alta adicionada com sucesso', null)
    })
    .catch((response) => {

      this.utilService.showAlertCallback('Não foi possível adicionar a Alta', null)
    }).finally(()=>{
      loading.dismiss();
    })

  }
}
