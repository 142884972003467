import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { ExameunidadeComponent } from 'src/app/componentes/exameunidade/exameunidade.component';
import { ExameorgaoComponent } from 'src/app/componentes/exameorgao/exameorgao.component';
import { ExametopicoComponent } from 'src/app/componentes/exametopico/exametopico.component';


@Component({
  selector: 'app-examerelacao',
  templateUrl: './examerelacao.component.html',
  styleUrls: ['./examerelacao.component.scss'],
})
export class ExamerelacaoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraTexto', null) ancoraTexto: ElementRef;

  examerelacaoForm: any
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0
  Examerelacao: number = -1;
  Botao: string = "Cadastrar"
  padraoColection: any = [];
  collectionExameTipo: any = [];
  collectionExameRelacaoTipo: any = [];
  Exametipo: number = 0;
  collectionOrgao: any = [];
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private modalController:ModalController
  ) {
    this.examerelacaoForm = formBuilder.group({
      Exametipo: ['', ''],
      Examerelacao: ['', ''],
      Nome: ['', ''],
      Examepadrao: ['', ''],
      Exameorgao: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {

    this.Exametipo = this.navParams.get('Exametipo');
    this.Examerelacao = this.navParams.get('Examerelacao');
  }

  ionViewDidEnter() {
    this.limpaDados();
    this.testaAcessoSistema();
    this.selecionarExameTipo();
    this.selecionarExameOrgao();

  }

  async selecionarExamepadraoRelacao(id: number) {
    this.exameService.selecionarExamePadraoRelacao(id, this.Usuario).then((response: any) => {
      this.padraoColection = response;
        if (response.length > 0) {
          this.getDadosExame(id);

        }
    
    
    }).catch((response) => {

    });

  }

  async getDadosExame(id:number=null) {

    if(id == null || id == undefined || id == 0) {
      return;
    }
    let Examerelacaounico: any = [];
    Examerelacaounico = this.collectionExameRelacaoTipo.filter((a: any) => {
      return a.Id == id;
    })

    this.Botao = "Alterar";
    this.examerelacaoForm.controls['Nome'].setValue(Examerelacaounico[0].Nome);
    setTimeout(() => {
      let aOrgaos: string = Examerelacaounico[0].Exameorgao;
      let aOrg: any;
      if (aOrgaos.indexOf(",") > -1) {
        aOrg = JSON.parse('[' + aOrgaos + ']')
      } else {
        aOrg = aOrgaos;
      }
      this.examerelacaoForm.controls['Exameorgao'].setValue(aOrg);
      this.examerelacaoForm.controls['Examepadrao'].setValue(Examerelacaounico[0].Examepadrao);

    }, 250)
  }

  async selecionarExameTipo() {
    this.exameService.selecionarExameTipo().then((response: any) => {
      this.collectionExameTipo = response;
      setTimeout(()=>{
        this.examerelacaoForm.controls['Exametipo'].setValue(this.Exametipo);
        
      },250)
    })
  }

  async ShowUnidadeComponent() {
    let modal = await this.modalController.create({
      component: ExameunidadeComponent,
      componentProps:
      {
        Exametipo: this.examerelacaoForm.value.Exametipo,
        Examerelacao: this.examerelacaoForm.value.Examerelacao,
        
      }
    });
    return await modal.present();

  } // fim showModal

  async ShowOrgaoComponent() {
    let modal = await this.modalController.create({
      component: ExameorgaoComponent,
      componentProps:
      {
        Exametipo: this.examerelacaoForm.value.Exametipo,
        Examerelacao: this.examerelacaoForm.value.Examerelacao,
        
      }
     
    });
    return await modal.present();

  } // fim showModal
  async ShowTopicoComponent() {
    let modal = await this.modalController.create({
      component: ExametopicoComponent,
      componentProps:
      {
        Exametipo: this.examerelacaoForm.value.Exametipo,
        Examerelacao: this.examerelacaoForm.value.Examerelacao,
        
      }
     
    });
    return await modal.present();

  } // fim showModal

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.examerelacaoForm.controls['Datalancamento'].setValue(Datalancamento);


  }

  async selecionarExameRelacaoTipo(exametipo: number) {
    this.Exametipo = exametipo;
    this.exameService.selecionarExameRelacaoTipo(exametipo).then((response: any) => {
      this.collectionExameRelacaoTipo = response;
      setTimeout(()=>{
        this.examerelacaoForm.controls['Examerelacao'].setValue(this.Examerelacao);
      },500)
    })

  }

  async testaDados() {

    if (this.examerelacaoForm.value.Exametipo == null || this.examerelacaoForm.value.Exametipo == undefined || this.examerelacaoForm.value.Exametipo == '') {
      this.utilService.showAlertCallback("Informe o TIPO DE EXAME para continuar", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.examerelacaoForm.value.Nome == null || this.examerelacaoForm.value.Nome == undefined || this.examerelacaoForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DO PADRÃO para continuar", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    // if (this.examerelacaoForm.value.Examepadrao == null || this.examerelacaoForm.value.Examepadrao == undefined || this.examerelacaoForm.value.Examepadrao == '') {
    //   this.utilService.showAlertCallback("Informe o PADRÃO PARA ESTE EXAME para continuar", () => {
    //     this.utilService.focusInput(this.ancoraNome);
    //   });
    //   return;
    // }

    if (this.examerelacaoForm.value.Exameorgao == null || this.examerelacaoForm.value.Exameorgao == undefined || this.examerelacaoForm.value.Exameorgao == '') {
      this.utilService.showAlertCallback("Informe os ORGÃOS para esse exame", () => {
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    let form = {
      Id: this.examerelacaoForm.value.Examerelacao,
      Exametipo: this.examerelacaoForm.value.Exametipo,
      Nome: this.examerelacaoForm.value.Nome,
      Examepadrao: this.examerelacaoForm.value.Examepadrao,
      Exameorgao: this.examerelacaoForm.value.Exameorgao.toString(),
    }


    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }



  async presentAlertConfirm(form: any) {

    let tipo = this.examerelacaoForm.value.Examerelacao;

    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "

    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }

    console.log("from: " + JSON.stringify(form))
    console.log("tipo: " + tipo)
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Relação',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


  async cadastrar(form: any) {

    if (this.examerelacaoForm.value.Examerelacao == -1) {
      this.exameService.adicionarExameRelacao(form).then((response) => {
        this.utilService.showAlertCallback("Exame ADICIONADO com sucesso", null);
        setTimeout(() => {
          this.Examerelacao = response[0].Id;
          this.examerelacaoForm.controls['Examerelacao'].setValue(this.Examerelacao);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o Exame", null);

      });
    } else {
      this.exameService.atualizarExameRelacao(form).then((response: any) => {
        this.utilService.showAlertCallback("Exame ATUALIZADO com sucesso", null);
        setTimeout(() => {
          this.Examerelacao = response[0].Id;
          this.examerelacaoForm.controls['Examerelacao'].setValue(this.Examerelacao);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Exame", null);

      });
    }

  }


  async limpaDados() {
    this.Botao = "Cadastrar";
   // this.examerelacaoForm.controls['Exametipo'].setValue('');
    this.examerelacaoForm.controls['Examerelacao'].setValue(-1);
    this.examerelacaoForm.controls['Nome'].setValue('');
    this.examerelacaoForm.controls['Exameorgao'].setValue('');

    this.utilService.focusInput(this.ancoraNome);
    

  }



  async selecionarExamerelacaoUnico(id: any) {
    if (id == -1 ) {
      this.limpaDados();
      return;
    }
    this.selecionarExamepadraoRelacao(id);
 
  }

  async selecionarExameOrgao() {
    this.exameService.selecionarExameOrgao().then((response: any) => {
      this.collectionOrgao = response
    })

  }


}
