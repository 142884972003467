import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import {TabelaService } from '../../services/tabela.service';


@Component({
  selector: 'app-exameunidade',
  templateUrl: './exameunidade.component.html',
  styleUrls: ['./exameunidade.component.scss'],
})
export class ExameunidadeComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraValornormal', null) ancoraValornormal: ElementRef;
  @ViewChild('ancoraOrdem', null) ancoraOrdem: ElementRef;

  exameunidadeForm: any
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0
  Botao: string = "Cadastrar"
  collectionUnidadeMedida: any = [];
  collectionExameMedida: any = [];
  Examemedida:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private tabelaService:TabelaService,

  ) {
    this.exameunidadeForm = formBuilder.group({
      Examemedida: ['', ''],
      Nome: ['', ''],
      Unidademedida: ['', ''],
      Valornormal: ['', ''],
      Ordem: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {

   }

  ionViewDidEnter() {
    this.limpaDados();
    this.testaAcessoSistema();
    this.selecionarExameMedida();
    this.selecionarUnidadeMedida();

  }

  

 

 

 

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

  async selecionarUnidadeMedida() {
    this.tabelaService.selecionarTabela('Unidademedida', 'Id-Sigla').then((response: any) => {
      this.collectionUnidadeMedida = response;
      
    })

  }

  async selecionarExameMedida() {
    this.exameService.selecionarExameMedida().then((response: any) => {
      this.collectionExameMedida = response;
      
    })

  }

  async testaDados() {

    if (this.exameunidadeForm.value.Nome == null || this.exameunidadeForm.value.Nome == undefined || this.exameunidadeForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DA UNIDADE para continuar", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exameunidadeForm.value.Examemedida == null || this.exameunidadeForm.value.Examemedida == undefined || this.exameunidadeForm.value.Examemedida == '') {
      this.utilService.showAlertCallback("Informe a UNIDADE DE MEDIDA para continuar", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exameunidadeForm.value.Ordem == null || this.exameunidadeForm.value.Ordem == undefined || this.exameunidadeForm.value.Ordem == '') {
      this.utilService.showAlertCallback("Informe a ORDEM DE MEDIDA para continuar", ()=>{
        this.utilService.focusInput(this.ancoraOrdem);
      });
      return;
    }

    let form = {
      Id: this.exameunidadeForm.value.Examemedida,
      Nome: this.exameunidadeForm.value.Nome,
      Unidademedida: this.exameunidadeForm.value.Unidademedida,
      Valornormal: this.exameunidadeForm.value.Valornormal,
      Ordem: this.exameunidadeForm.value.Ordem
    }

    this.presentAlertConfirm(form);

  }


  async closeModal() {
    this.modalCtrl.dismiss();
  }



  async presentAlertConfirm(form: any) {
    let tipo:number = this.exameunidadeForm.value.Examemedida;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Medidas',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


  async cadastrar(form: any) {
    if (this.exameunidadeForm.value.Examemedida == -1) {
      this.exameService.adicionarExameMedida(form).then((response) => {
        this.utilService.showAlertCallback("Exame ADICIONADO com sucesso", null);
        setTimeout(() => {
         // this.Examemedida = response[0].Id;
          this.exameunidadeForm.controls['Examemedida'].setValue(response[0].Id);

        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR a medida", null);

      });
    } else {
      this.exameService.atualizarExameMedida(form).then((response: any) => {
        this.utilService.showAlertCallback("Medida ATUALIZADO com sucesso", null);
          this.selecionarExameMedida();
          setTimeout(() => {
            this.exameunidadeForm.controls['Examemedida'].setValue(response[0].Id);
          } , 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Exame", null);

      });
    }

  }

  async selecionarExameMedidaUnico(id:number) {
    if (id== -1) {
      this.limpaDados();
      return;
    }
    let MedidaUnica:any = this.collectionExameMedida.filter((a:any)=>{
      return a.Id == id;
    })

    if (MedidaUnica.length == 0) {
      return;
    }

    this.exameunidadeForm.controls['Nome'].setValue(MedidaUnica[0].Nome);
    this.exameunidadeForm.controls['Valornormal'].setValue(MedidaUnica[0].Valornormal);
    this.exameunidadeForm.controls['Unidademedida'].setValue(MedidaUnica[0].Unidademedida);
    this.exameunidadeForm.controls['Ordem'].setValue(MedidaUnica[0].Ordem);

    this.Botao= "Alterar";

  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.exameunidadeForm.controls['Examemedida'].setValue(-1);
    this.exameunidadeForm.controls['Nome'].setValue('');
    this.exameunidadeForm.controls['Valornormal'].setValue('');
    this.exameunidadeForm.controls['Unidademedida'].setValue('');
    this.exameunidadeForm.controls['Ordem'].setValue('');

    this.utilService.focusInput(this.ancoraNome);
  }



  

  

}
