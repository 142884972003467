import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { PacienteService } from '../../services/paciente.service';
import { TabelaService } from '../../services/tabela.service';

@Component({
  selector: 'app-fluxogramaescore',
  templateUrl: './fluxogramaescore.component.html',
  styleUrls: ['./fluxogramaescore.component.scss'],
})
export class FluxogramaescoreComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraOrdem', null) ancoraOrdem: ElementRef;
  @ViewChild('ancoraDescricao', null) ancoraDescricao: ElementRef;

  fluxogramaescoreForm: any;
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0;
  Botao: string = "Cadastrar";
  chdAtivo: boolean = false;
  corAtivo: string = "primary";
  chdGrafico: boolean = false;
  corGrafico: string = "primary";
  chdCalculo: boolean = false;
  corCalculo: string = "primary";
  chdHelp: boolean = false;
  corHelp: string = "primary";
  chdMultiple: boolean = false;
  corMultiple: string = "primary";
  sistemaCollection: any = [];
  escoreCollection: any = [];
  Escore: number = 0;
  unidadeCollection: any = [];
  escorerelacaoCollection: any = [];
  escoretipoCollection: any = [];
  Sistema:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private pacienteService: PacienteService,
    private tabelaService: TabelaService

  ) {
    this.fluxogramaescoreForm = formBuilder.group({
      Escore: ['', ''],
      Sistema: ['', ''],
      Nome: ['', ''],
      Padrao: ['', ''],
      Descricao: ['', ''],
      Ordem: ['', ''],
      Ativo: ['', ''],
      Unidademedida: ['', ''],
      Escoretipo: ['', ''],
      Rangeminimo: ['', ''],
      Rangemaximo: ['', ''],
      Grafico: ['', ''],
      Help: ['', ''],
      Calculo: ['', ''],
      Multiple: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {
    this.Escore = this.navParams.get('Escore');
    this.Sistema  = this.navParams.get('Sistema');

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.selecionarSistema();
    this.selecionarEscoreSistema(this.Sistema);
    this.selecionarUnidadeMedida();
    this.selecionarEscoreTipo();
    this.selecionarEscoreRelacao()
    this.limpaDados();
  }

  async selecionarEscoreRelacao() {
    this.checklistService.selecionarEscoreRelacao(this.Escore)
      .then((response: any) => {
        this.escorerelacaoCollection = response
      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore')
      });
  }

  async selecionarSistema() {
    this.pacienteService.selecionarSistema().then((response) => {
      this.sistemaCollection = response;
     
    }).catch((response) => {
      //console.log("erro")
    });
  }

  async selecionarEscoreSistema(sistema: number) {
    this.checklistService.selecionarEscoreSistema(sistema).then((response: any) => {
      this.escoreCollection = response;
      setTimeout(() => {
        this.fluxogramaescoreForm.controls['Escore'].setValue(this.Escore);
        this.selecionarEscoreUnico(this.Escore);
      }, 500);
    }).catch((response) => {

    });
  }

  async selecionarUnidadeMedida() {
    this.tabelaService.selecionarTabela('Unidademedida', 'Id-Sigla').then((response: any) => {
      this.unidadeCollection = response;

    })

  }

  async selecionarEscoreTipo() {
    this.tabelaService.selecionarTabela('Escoretipo', 'Id-Nome').then((response: any) => {
      this.escoretipoCollection = response;
    })

  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }

    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")

    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }





  async testaDados() {

    if (this.fluxogramaescoreForm.value.Nome == '') {
      this.utilService.showAlertCallback("Nome não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraNome);
      })
    }

    if (this.fluxogramaescoreForm.value.Descricao == '') {
      this.utilService.showAlertCallback("Descrição não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraDescricao);
      })
    }

    if (this.fluxogramaescoreForm.value.Sistema == '') {
      this.utilService.showAlertCallback("Sistema não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraDescricao);
      })
    }

    if (this.fluxogramaescoreForm.value.Escoretipo == '') {
      this.utilService.showAlertCallback("Escore tipo não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraDescricao);
      })
    }

    if (this.fluxogramaescoreForm.value.Unidademedida == '') {
      this.utilService.showAlertCallback("Unidade medida não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraDescricao);
      })
    }

    if (this.fluxogramaescoreForm.value.Ordem == 0) {
      this.utilService.showAlertCallback("Ordem não pode ser em branco", () => {
        this.utilService.focusInput(this.ancoraOrdem);
      })
    }

    let Ativo: number = 0;
    if (this.fluxogramaescoreForm.value.Ativo == true) {
      Ativo = 1
    }

    let Grafico: number = 0;
    if (this.fluxogramaescoreForm.value.Grafico == true) {
      Grafico = 1
    }

    let Calculo: number = 0;
    if (this.fluxogramaescoreForm.value.Calculo == true) {
      Calculo = 1
    }

    let Help: number = 0;
    if (this.fluxogramaescoreForm.value.Help == true) {
      Help = 1
    }

    let Multiple: string = 'false';
    if (this.fluxogramaescoreForm.value.Multiple == true) {
      Multiple = 'true'
    }


    let form = {
      Id: this.fluxogramaescoreForm.value.Escore,
      Nome: this.fluxogramaescoreForm.value.Nome,
      Padrao: this.fluxogramaescoreForm.value.Padrao,
      Descricao: this.fluxogramaescoreForm.value.Descricao,
      Ordem: this.fluxogramaescoreForm.value.Ordem,
      Ativo: Ativo,
      Sistema: this.fluxogramaescoreForm.value.Sistema,
      Unidademedida: this.fluxogramaescoreForm.value.Unidademedida,
      Escoretipo: this.fluxogramaescoreForm.value.Escoretipo,
      Rangeminimo: this.fluxogramaescoreForm.value.Rangeminimo,
      Rangemaximo: this.fluxogramaescoreForm.value.Rangemaximo,
      Grafico: Grafico,
      Help: Help,
      Calculo: Calculo,
      Multiple: Multiple,
    }

    this.presentAlertConfirm(form);
  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }




  async presentAlertConfirm(form: any) {
    let tipo = this.fluxogramaescoreForm.value.Escore;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitante',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


  async cadastrar(form: any) {
    if (this.fluxogramaescoreForm.value.Escore == -1) {
      this.checklistService.adicionarEscore(form).then((response) => {
        if (response[0].Id > 0) {
          this.utilService.showAlertCallback("Escore ADICIONADO com sucesso", null);
          this.selecionarEscoreSistema(this.Sistema);
          setTimeout(() => {
            this.fluxogramaescoreForm.controls['Escore'].setValue(response[0].Id);
        }, 500);
        }

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o escore", null);
      });
    } else {
      this.checklistService.atualizarEscore(form).then((response: any) => {
        this.utilService.showAlertCallback("Escore ATUALIZADO com sucesso", null);
        this.selecionarEscoreSistema(this.Sistema);
        setTimeout(() => {
          this.fluxogramaescoreForm.controls['Escore'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Escore", null);
      });
    }

  }

  a
  async selecionarEscoreUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.checklistService.selecionarEscoreUnico(Id).then((response: any) => {
      if (response[0].Id > 0) {
       
        setTimeout(()=>{
          this.getEscore(response);
        }, 500)
        
      }
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar o ÓRGÃO")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.fluxogramaescoreForm.controls['Nome'].setValue('');
    this.fluxogramaescoreForm.controls['Padrao'].setValue('');
    this.fluxogramaescoreForm.controls['Ordem'].setValue('');
    this.fluxogramaescoreForm.controls['Ativo'].setValue(false);
    this.fluxogramaescoreForm.controls['Sistema'].setValue(-1);
    this.fluxogramaescoreForm.controls['Grafico'].setValue(false);
    this.fluxogramaescoreForm.controls['Help'].setValue(false);
    this.fluxogramaescoreForm.controls['Calculo'].setValue(false);
    this.fluxogramaescoreForm.controls['Multiple'].setValue(false);

    this.utilService.focusInput(this.ancoraNome);
    this.chdAtivo = true;
    this.corAtivo = 'primary';


  }



  async getEscore(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "Alterar";
    if (response[0].Ativo == 1) {
      this.chdAtivo = true
      this.corAtivo = 'danger'

    }
    if (response[0].Calculo == 1) {
      this.chdCalculo = true
      this.corCalculo = 'danger'

    }

    if (response[0].Grafico == 1) {
      this.chdGrafico = true
      this.corGrafico = 'danger'
    }

    if (response[0].Help == 1) {
      this.chdHelp = true
      this.corHelp = 'danger'
    }

    if (response[0].Multiple == 'true') {
      this.chdMultiple= true
      this.corMultiple = 'danger'
    }

    this.fluxogramaescoreForm.controls['Nome'].setValue(response[0].Nome);
    this.fluxogramaescoreForm.controls['Descricao'].setValue(response[0].Descricao);
    this.fluxogramaescoreForm.controls['Ordem'].setValue(response[0].Ordem);
    this.fluxogramaescoreForm.controls['Rangeminimo'].setValue(response[0].Rangeminimo);
    this.fluxogramaescoreForm.controls['Rangemaximo'].setValue(response[0].Rangemaximo);

    this.fluxogramaescoreForm.controls['Ativo'].setValue(this.chdAtivo);
    this.fluxogramaescoreForm.controls['Grafico'].setValue(this.chdGrafico);
    this.fluxogramaescoreForm.controls['Help'].setValue(this.chdHelp);
    this.fluxogramaescoreForm.controls['Calculo'].setValue(this.chdCalculo);
    this.fluxogramaescoreForm.controls['Multiple'].setValue(this.chdMultiple);
    this.fluxogramaescoreForm.controls['Padrao'].setValue(response[0].Padrao);
    this.fluxogramaescoreForm.controls['Unidademedida'].setValue(response[0].Unidademedida);
    this.fluxogramaescoreForm.controls['Escoretipo'].setValue(response[0].Escoretipo);
    this.fluxogramaescoreForm.controls['Padrao'].setValue(response[0].Padrao);
    this.fluxogramaescoreForm.controls['Sistema'].setValue(response[0].Sistema);

  }

  async selecionaAtivo() {
    if (this.fluxogramaescoreForm.value.Ativo == true) {
      this.chdAtivo = true;
      this.corAtivo = 'danger';
    } else {
      this.chdAtivo = false;
      this.corAtivo = 'primary';
    }
  }

  async selecionaGrafico() {
    if (this.fluxogramaescoreForm.value.Grafico == true) {
      this.chdGrafico = true;
      this.corGrafico = 'danger';
    } else {
      this.chdGrafico = false;
      this.corGrafico = 'primary';
    }
  }

  async selecionaHelp() {
    if (this.fluxogramaescoreForm.value.Help == true) {
      this.chdHelp = true;
      this.corHelp = 'danger';
    } else {
      this.chdHelp = false;
      this.corHelp = 'primary';
    }
  }

  async selecionaCalculo() {
    if (this.fluxogramaescoreForm.value.Calculo == true) {
      this.chdCalculo = true;
      this.corCalculo = 'danger';
    } else {
      this.chdCalculo = false;
      this.corCalculo = 'primary';
    }
  }

  async selecionaMultiple() {
    if (this.fluxogramaescoreForm.value.Calculo == true) {
      this.chdMultiple = true;
      this.corMultiple = 'danger';
    } else {
      this.chdMultiple = false;
      this.corMultiple = 'primary';
    }
  }

}
