import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { ExameunidadeComponent } from 'src/app/componentes/exameunidade/exameunidade.component';
import { ExameorgaoComponent } from 'src/app/componentes/exameorgao/exameorgao.component';
import { ExametopicoComponent } from 'src/app/componentes/exametopico/exametopico.component';


@Component({
  selector: 'app-exameformatar',
  templateUrl: './exameformatar.component.html',
  styleUrls: ['./exameformatar.component.scss'],
})
export class ExameformatarComponent implements OnInit {
  // @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  //@ViewChild('ancoraTexto', null) ancoraTexto: ElementRef;

  exameformatarForm: any
  dadosListMed: any = [];
  Logado: number = 0;
  Usuario: number = 0
  Examerelacao: number = -1;
  Botao: string = "Cadastrar"
  padraoColection: any = [];
  collectionExameTipo: any = [];
  collectionExameRelacaoTipo: any = [];
  Exametipo: number = 0;
  collectionOrgao: any = [];
  collectionOrgaoExame: any = [];
  Examenome: string = "";
  Exameemissao: number = 0;
  Datalancamento: string = ""
  Exametopico: any;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private modalController: ModalController
  ) {
    this.exameformatarForm = formBuilder.group({
      Exametipo: ['', ''],
      Examerelacao: ['', ''],
      Exameorgao0: ['', ''],
      Exameorgao1: ['', ''],
      Exameorgao2: ['', ''],
      Exameorgao3: ['', ''],
      Exameorgao4: ['', ''],
      Exameorgao5: ['', ''],
      Exameorgao6: ['', ''],
      Exameorgao7: ['', ''],
      Exameorgao8: ['', ''],
      Exameorgao9: ['', ''],
      Exameorgao10: ['', ''],
      Exameorgao11: ['', ''],
      Exameorgao12: ['', ''],
      Exameorgao13: ['', ''],
      Exameorgao14: ['', ''],
      Exameorgao15: ['', ''],
      Exameorgao16: ['', ''],
      Exameorgao17: ['', ''],
      Exameorgao18: ['', ''],
      Exameorgao19: ['', ''],

      Texto0: ['', ''],
      Texto1: ['', ''],
      Texto2: ['', ''],
      Texto3: ['', ''],
      Texto4: ['', ''],
      Texto5: ['', ''],
      Texto6: ['', ''],
      Texto7: ['', ''],
      Texto8: ['', ''],
      Texto9: ['', ''],
      Texto10: ['', ''],
      Texto11: ['', ''],
      Texto12: ['', ''],
      Texto13: ['', ''],
      Texto14: ['', ''],
      Texto15: ['', ''],
      Texto16: ['', ''],
      Texto17: ['', ''],
      Texto18: ['', ''],
      Texto19: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {

    this.Exametipo = this.navParams.get('Exametipo');
    this.Examerelacao = this.navParams.get('Examerelacao');
    this.Examenome = this.navParams.get('Examenome');
    this.Exameemissao = this.navParams.get('Exameemissao');
    this.Datalancamento = this.navParams.get('Datalancamento');
    this.Exametopico = this.navParams.get('Exametopico');
    this.selecionarExameEmissaoUnico();
  }

  ionViewDidEnter() {
    this.limpaDados();
    this.testaAcessoSistema();
    this.selecionarExameTipo();
    this.selecionarExameOrgao();
  }

  async mudarSatusDiv(valor:any, x:number) {
    if (valor == true) {
     this.utilService.mostrarDiv('div' + x.toString())
    }else{
     this.utilService.esconderDiv('div' + x.toString())
    }
  }

  async selecionarExamepadraoRelacao(id: number) {
    this.exameService.selecionarExamePadraoRelacao(id, this.Usuario).then((response: any) => {
      this.padraoColection = response;
      if (response.length > 0) {
        this.getDadosExame(id);

      }


    }).catch((response) => {

    });

  }

  async getDadosExame(id: number = null) {

    if (id == null || id == undefined || id == 0) {
      return;
    }
    let Examerelacaounico: any = [];
    Examerelacaounico = this.collectionExameRelacaoTipo.filter((a: any) => {
      return a.Id == id;
    })

    this.Botao = "Alterar";
    this.exameformatarForm.controls['Nome'].setValue(Examerelacaounico[0].Nome);
    setTimeout(() => {
      let aOrgaos: string = Examerelacaounico[0].Exameorgao;
      let aOrg: any;
      if (aOrgaos.indexOf(",") > -1) {
        aOrg = JSON.parse('[' + aOrgaos + ']')
      } else {
        aOrg = aOrgaos;
      }
      this.exameformatarForm.controls['Exameorgao'].setValue(aOrg);
      this.exameformatarForm.controls['Examepadrao'].setValue(Examerelacaounico[0].Examepadrao);

    }, 250)
  }

  async selecionarExameTipo() {
    this.exameService.selecionarExameTipo().then((response: any) => {
      this.collectionExameTipo = response;
      setTimeout(() => {
        this.exameformatarForm.controls['Exametipo'].setValue(this.Exametipo);

      }, 250)
    })
  }

  async ShowUnidadeComponent() {
    let modal = await this.modalController.create({
      component: ExameunidadeComponent,
      componentProps:
      {
        Exametipo: this.exameformatarForm.value.Exametipo,
        Examerelacao: this.exameformatarForm.value.Examerelacao,

      }
    });
    return await modal.present();

  } // fim showModal

  async ShowOrgaoComponent() {
    let modal = await this.modalController.create({
      component: ExameorgaoComponent,
      componentProps:
      {
        Exametipo: this.exameformatarForm.value.Exametipo,
        Examerelacao: this.exameformatarForm.value.Examerelacao,

      }

    });
    return await modal.present();

  } // fim showModal
  async ShowTopicoComponent(orgao:number) {
    let modal = await this.modalController.create({
      component: ExametopicoComponent,
      componentProps:
      {
        Exametipo: this.Exametipo,
        Exameorgao: orgao,

      }

    });
    return await modal.present();

  } // fim showModal

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.exameformatarForm.controls['Datalancamento'].setValue(Datalancamento);


  }

  async selecionarExameRelacaoTipo(exametipo: number) {
    this.Exametipo = exametipo;
    this.exameService.selecionarExameRelacaoTipo(exametipo).then((response: any) => {
      this.collectionExameRelacaoTipo = response;
      setTimeout(() => {
        this.exameformatarForm.controls['Examerelacao'].setValue(this.Examerelacao);
      }, 500)
    })

  }

  async selecionarExameEmissaoUnico() {
    this.exameService.selecionarExameEmissaoUnico(this.Exameemissao).then((response:any)=>{
      if (response.length > 0 && response[0].Exametopico != '') {
        this.Exametopico = response[0].Exametopico;
      }
    })
  }

  async testaDados() {

    let Exametopico: string = "";
    let Texto: string = "";
    let Orgao: string = "";
    let TextoPadrao: string = "";


    for (let i = 0; i < this.collectionOrgaoExame.length; i++) {
      Orgao = this.collectionOrgaoExame[i].Nome.toUpperCase() + ": ";
      TextoPadrao = eval('this.exameformatarForm.value.Texto' + i.toString());
      Texto = Texto + Orgao + TextoPadrao + '\n\n';
      Exametopico = Exametopico + eval('this.exameformatarForm.value.Exameorgao' + i.toString()) + ','
    }
    Exametopico = Exametopico.substring(0, Exametopico.length - 1);
  
    let form = {
      Id: this.Exameemissao,
      Exametopico: Exametopico,
      Texto: Texto,
    }
    this.presentAlertConfirm(form);
  }


  async closeModal() {
    this.modalCtrl.dismiss();
  }



  async presentAlertConfirm(form: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'LAUDO: ' + this.Exameemissao,
      message: "DESEJA ATUALIZAR ESSE LAUDO?",
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


  async cadastrar(form: any) {

    this.exameService.atualizarExameEmissaoTexto(form).then((response) => {
      this.utilService.showAlertCallback("Laudo ATUALIZADO com sucesso", null);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Laudo", null);

    });



  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    for (let i = 0; i < this.collectionOrgaoExame; i++) {
      this.exameformatarForm.controls['Exameorgao' + i.toString()].setValue('');

    }


  }



  async selecionarExameRelacaoUnico() {
    this.exameService.selecionarExameRelacaoUnico(this.Examerelacao).then((response: any) => {
      let Exameorgao: any = response[0].Exameorgao.split(',');
      for (let i = 0; i < this.collectionOrgao.length; i++) {
        for (let z = 0; z < Exameorgao.length; z++) {
          if (this.collectionOrgao[i].Id == Exameorgao[z]) {
            this.collectionOrgaoExame.push({
              Id: this.collectionOrgao[i].Id,
              Nome: this.collectionOrgao[i].Nome,
              RelacaoPadrao: [],
              IdPadrao: 0,
              Texto: '',
            })
            break;
          }
        }
      }
      setTimeout(() => {
        this.selecionarTopicos();
      }, 500)
    })

  }

  async selecionarTopicos() {
    let Orgao: number = 0;
    for (let i = 0; i < this.collectionOrgaoExame.length; i++) {
      Orgao = this.collectionOrgaoExame[i].Id;
      this.exameService.selecionarExameTopico(this.Exametipo, Orgao, this.Usuario).then((response: any) => {
        this.collectionOrgaoExame[i].RelacaoPadrao = response;
        // seta os padrões caso não exista nenhum cadastrado
        // em caso já existir dados cadastrados
        for (let z = 0; z < response.length; z++) {
          this.collectionOrgaoExame[i].Texto = response[z].Texto;
          if (response[z].Padrao == 1) {
            this.collectionOrgaoExame[i].IdPadrao = response[z].Id;
          }
        }

       

      })
    }

    setTimeout(()=>{
      this.atualizaTopicosCadastrados();
    }, 500)

  }

  async atualizaTopicosCadastrados() {
    
    if (this.Exametopico == '' || this.Exametopico == undefined || this.Exametopico == null ) {
      return;
    }

    this.Exametopico = this.Exametopico.toString().split(',');
    console.log('Exametopico:' + this.Exametopico)
   

    for (let i=0; i< this.collectionOrgaoExame.length; i++) {
      this.collectionOrgaoExame[i].IdPadrao = parseInt(this.Exametopico[i]);
      
    }

  }



  async selecionarExameOrgaoTopicoPadrao(Id: number, aVt: any, Campo: number) {
    let aVtUnico: any = [];
    aVtUnico = aVt.filter((a: any) => {
      return a.Id == Id
    })
    if (aVtUnico.length == 0) {
      return;
    }
    setTimeout(()=>{
      if (Campo != null && Campo != undefined) {
        this.exameformatarForm.controls['Texto' + Campo.toString()].setValue(aVtUnico[0].Texto);
        }
    },500)
   

  }

 

  async selecionarExameOrgao() {
    this.exameService.selecionarExameOrgao().then((response: any) => {
      this.collectionOrgao = response;
      this.selecionarExameRelacaoUnico();
    })

  }

  async selecionarExameTopicoOrgaoUsuario(orgao:number, posicao:number) {
    this.exameService.selecionarExameTopico(this.Exametipo, orgao, this.Usuario).then((response: any) => {
      if (response.length > 0) {
        this.collectionOrgaoExame[posicao].RelacaoPadrao= response;
      }
    })

  }


}
