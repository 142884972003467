import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-examesolicitante',
  templateUrl: './examesolicitante.component.html',
  styleUrls: ['./examesolicitante.component.scss'],
})
export class ExamesolicitanteComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraMatricula', null) ancoraMatricula: ElementRef;
  @ViewChild('ancoraEmail', null) ancoraEmail: ElementRef;
  @ViewChild('ancoraCelular', null) ancoraCelular: ElementRef;

  examesolicitanteForm: any
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0
  Examesolicitante:number= -1;
  Botao:string="Cadastrar"
  solicitanteColection:any=[];
  collectionExameTipo:any=[];
  collectionExameRelacaoTipo:any=[];
  Exametipo:number=0;
  Examerelacao:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService:ChecklistService
) {
    this.examesolicitanteForm = formBuilder.group({
      
      Examesolicitante: ['', ''],
      Nome: ['', ''],
      Texto: ['', ''],
      Matricula: ['', ''],
      Email: ['', ''],
      Celular: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
  
  }
  ngOnInit() { 

    this.Exametipo = this.navParams.get('Exametipo');
    this.Examerelacao = this.navParams.get('Examerelacao');
    this.Examesolicitante = this.navParams.get('Examesolicitante');

  }

  ionViewDidEnter() {
    this.limpaDados();
    this.testaAcessoSistema();

  }

  async selecionarExameSolicitante() {
    this.exameService.selecionarExameSolicitante(this.Usuario).then((response:any)=>{
      this.solicitanteColection = response;
      setTimeout(() => {
        this.examesolicitanteForm.controls['Examesolicitante'].setValue(this.Examesolicitante);
      }, 500);
    

    }).catch(()=>{
      this.utilService.showAlertCallback("Naõ existem ainda solicitantes cadastrados para você")
    });



  }

  

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
         
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

   async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      if (this.Logado == 0) {
        this.utilService.Movimentar('login');
        return;
      }
      setTimeout(()=>{
        this.selecionarExameSolicitante();

      }, 500)

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
   
  }

 

  

  async testaDados() {
    if (this.examesolicitanteForm.value.Nome == null || this.examesolicitanteForm.value.Nome == undefined || this.examesolicitanteForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DO SOLICITANTE para continuar", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    let Email:string= this.examesolicitanteForm.value.Email;
    let Celular:string= this.examesolicitanteForm.value.Celular;
    let lRet:boolean= true;
    if (Email.length > 0) {
      lRet = this.utilService.checkMail(Email, this.utilService.focusInput(this.ancoraEmail))
      if (lRet == false) {
        return;
      }
     
    }

    if (Celular.length > 0) {
      lRet = this.utilService.validarCampo("Celular", Celular, this.utilService.focusInput(this.ancoraCelular))
      if (lRet == false) {
        return;
      }
     
    }

   

    let form = {
      Id: this.examesolicitanteForm.value.Examesolicitante,
      Nome: this.examesolicitanteForm.value.Nome,
      Matricula: this.examesolicitanteForm.value.Matricula,
      Email: this.examesolicitanteForm.value.Email,
      Celular: this.examesolicitanteForm.value.Celular,
      Usuario: this.Usuario
    }


    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

 

  async presentAlertConfirm(form: any) {

    let tipo = this.examesolicitanteForm.value.Examesolicitante;

    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "

    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitante',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


   async cadastrar(form: any) {

    if (this.examesolicitanteForm.value.Examesolicitante ==  -1) {
      this.exameService.adicionarExameSolicitante(form).then((response) => {
        this.selecionarExameSolicitante();
        this.utilService.showAlertCallback("solicitante ADICIONADO com sucesso", null);
        setTimeout(() => {
          this.examesolicitanteForm.controls['Examesolicitante'].setValue(this.Examesolicitante);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR a Solicitante", null);

      });
    } else {
      this.exameService.atualizarExameSolicitante(form).then((response:any) => {
        this.selecionarExameSolicitante();
        this.utilService.showAlertCallback("Solicitante ATUALIZADO com sucesso", null);
        setTimeout(() => {
          this.Examesolicitante = response[0].Id;
          this.examesolicitanteForm.controls['Examesolicitante'].setValue(this.Examesolicitante);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR a Solicitante", null);

      });
    }

  }

  a
  async selecionarExameSolicitanteUnico(Id:number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.exameService.selecionarExameSolicitanteUnico(Id).then((response:any) => {
      this.getexamesolicitante(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar a Solicitante")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.examesolicitanteForm.controls['Examesolicitante'].setValue(-1);
    this.examesolicitanteForm.controls['Nome'].setValue('');
    this.examesolicitanteForm.controls['Matricula'].setValue('');
    this.examesolicitanteForm.controls['Email'].setValue('');
    this.examesolicitanteForm.controls['Celular'].setValue('');

    this.utilService.focusInput(this.ancoraNome);
  }

  async getexamesolicitante(response: any) {

    if (response.length == 0) {
      return;
    }

      this.Botao = "Alterar";
      this.examesolicitanteForm.controls['Nome'].setValue(response[0].Nome);
      this.examesolicitanteForm.controls['Matricula'].setValue(response[0].Matricula);
      this.examesolicitanteForm.controls['Email'].setValue(response[0].Email);
      this.examesolicitanteForm.controls['Celular'].setValue(response[0].Celular);


  }


}
