import { Component, OnInit } from '@angular/core';
import {NavParams, ModalController, LoadingController, NavController, PopoverController, AlertController } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { FasthugComponent } from '../fasthug/fasthug.component';
import { SofaComponent } from '../sofa/sofa.component';
import { AntibioticoComponent } from '../antibiotico/antibiotico.component';
import { BalancoComponent } from '../balanco/balanco.component';
import { LaboratorioComponent } from '../laboratorio/laboratorio.component';
import { CpisComponent } from '../cpis/cpis.component';
import { DispositivoComponent } from '../dispositivo/dispositivo.component';
import { EcocardioComponent } from '../ecocardio/ecocardio.component';
import { NutricComponent } from '../nutric/nutric.component';
import { VentilacaoComponent } from '../ventilacao/ventilacao.component';
import { VitalComponent } from '../vital/vital.component';
import { Saps3Component } from '../saps3/saps3.component';
import { ComorbidadeComponent } from '../comorbidade/comorbidade.component';
import { HistoriaclinicaComponent } from '../historiaclinica/historiaclinica.component';
import { DrogaComponent } from '../droga/droga.component';
import { ChadsvascComponent } from '../chadsvasc/chadsvasc.component';
import { GlasgowComponent } from '../glasgow/glasgow.component';
import { DeliriumComponent } from '../delirium/delirium.component';
import { ChecklistService } from '../../services/checklist.service';
import { EvolutionService } from '../../services/evolution.service';
import { AbstractEmitterVisitor } from '@angular/compiler/src/output/abstract_emitter';
import { PacienteService } from '../../services/paciente.service';
import { GraficoComponent } from '../../componentes/grafico/grafico.component';
import { GasometriaComponent } from '../../componentes/gasometria/gasometria.component';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  dadosListMed:any=[];
  FotoPaciente:string="";
  NomePaciente:string="";
  aComponentesProprio:any;
  aComponentesEscore:any;
  aCalculadoraCollection:any=[];
  Logado:number=0;
  Paciente:number=0;
  Idade:number=0;
  Sexo:number=0;
  Peso:number=0;
  Altura:number=0;
  Bsa:number=0;
  PesoIdeal:number=0;
  Dias:number=0;
  Internacao:number=0;
  Datainternacao:string="";
  DescricaoHospital:string="";
  DescricaoSetor:string="";
  Leito:string="";
  Retorno:string="";
  aRelatorio:any=[];
  Usuario:number=0;
  sistemaCollection:any=[];
  aGrafico:any=[];
  aCorProprio:any=[];
  aCorEscore:any=[];
  aCorGrafico:any=[];
  constructor(
      private navController:NavController,
      private popoverController:PopoverController,
      private utilService:UtilService,
      private checklistService:ChecklistService,
      private loadingController:LoadingController,
      private modalController:ModalController,
      private navParams: NavParams,
      private evolutionService:EvolutionService,
      private pacienteService:PacienteService
      ) { 

    this.dadosListMed = this.utilService.obterDadosDoSistema();
    let aInternacao = this.dadosListMed.Internacao;
    this.checaDadosInternacao(aInternacao)
    this.Retorno = this.navParams.get('Retorno');
   
  }

  ngOnInit() {
    this.aComponentesEscore = [
      { Componente:FasthugComponent, icon: "/assets/icone32x32.svg", texto: "FAST-HUG", Id: 0, cor:"light"},
      { Componente:ChadsvascComponent, icon: "/assets/icone32x32.svg", texto: "CHADSVASC", Id: 14 , cor:""},
      { Componente:CpisComponent, icon: "/assets/icone32x32.svg", texto: "CPIS", Id: 25, cor:"light"},
      { Componente:GlasgowComponent, icon: "/assets/icone32x32.svg", texto: "GLASGOW", Id: 26, cor:"" },
      { Componente:DeliriumComponent, icon: "/assets/icone32x32.svg", texto: "CAM-ICU", Id: 27, cor:"light" },
      { Componente:NutricComponent, icon: "/assets/icone32x32.svg", texto: "NUTRIC", Id: 33, cor:"light" },
      { Componente:SofaComponent, icon: "/assets/icone32x32.svg", texto: "SOFA", Id: 34, cor:"" },
      { Componente:Saps3Component, icon: "/assets/icone32x32.svg", texto: "SAPS3", Id: 35, cor:"light"}

      
    ];
    this.aComponentesProprio = [
      { Componente:VitalComponent, icon: "/assets/icone32x32.svg", texto: "Dados vitais", Id: 0, cor:"light" },
      { Componente:BalancoComponent, icon: "/assets/icone32x32.svg", texto: "Balanço Hídrico", Id: 0, cor:"" },
      { Componente:DrogaComponent, icon: "/assets/icone32x32.svg", texto: "Drogas e infusões", Id: 32 ,cor:"light"},
      { Componente:VentilacaoComponent, icon: "/assets/icone32x32.svg", texto: "Ventilação mecânica", Id: 0, cor:"" },
      { Componente:DispositivoComponent,  icon: "/assets/icone32x32.svg", texto: "Dispositivos", Id: 0,  cor:"light" },
      { Componente:LaboratorioComponent,  icon: "/assets/icone32x32.svg", texto: "Laboratório", Id: 0 , cor:""},
      { Componente:AntibioticoComponent, icon: "/assets/icone32x32.svg", texto: "Antibióticos", Id: 0 , cor:"light"},
      { Componente:EcocardioComponent, icon: "/assets/icone32x32.svg", texto: "Ecocardiogrma", Id: 28 , cor:""}, 
      { Componente:ComorbidadeComponent, icon: "/assets/icone32x32.svg", texto: "Comorbidades", Id: 0, cor:"" },
      { Componente:HistoriaclinicaComponent, icon: "/assets/icone32x32.svg", texto: "História Clínica", Id: 0, cor:"light" },
      { Componente:GasometriaComponent, icon: "/assets/icone32x32.svg", texto: "Gasometria", Id: 0, cor:"G" },

    ]

    this.aRelatorio = [
      { Nome:"evolucao", icon: "/assets/icone32x32.svg", texto: "Evolução", Id: 0, cor:"light"},
      { Nome:"exame", icon: "/assets/icone32x32.svg", texto: "Laudos", Id: 0, cor:""},
 
    ];

    
        this.aGrafico = [
          { Nome:"ventilacao", icon: "/assets/icone32x32.svg", texto: "Ventilação", escoretipo: 8, cor:"light"},
          { Nome:"vital", icon: "/assets/icone32x32.svg", texto: "Dados Vitais", escoretipo: 7, cor:""},
          { Nome:"droga", icon: "/assets/icone32x32.svg", texto: "Drogas", escoretipo: 6, cor:"light"},
          { Nome:"bioquimica", icon: "/assets/icone32x32.svg", texto: "Bioquimica", escoretipo: 11, cor:""},
          { Nome:"eletrólito", icon: "/assets/icone32x32.svg", texto: "Eletrólitos", escoretipo: 12, cor:"light"},
          { Nome:"gasometria", icon: "/assets/icone32x32.svg", texto: "Gasometria", escoretipo: 13, cor:""},
          { Nome:"hemodinamica", icon: "/assets/icone32x32.svg", texto: "Hemodinâmica", escoretipo: 14, cor:"light"},
          { Nome:"sofa", icon: "/assets/icone32x32.svg", texto: "SOFA", escoretipo: 15, cor:""},
          { Nome:"balanco", icon: "/assets/icone32x32.svg", texto: "Balanço Hidrico", escoretipo: 16, cor:"light"},
          { Nome:"infeccao", icon: "/assets/icone32x32.svg", texto: "Infecção", escoretipo: 17, cor:""},

        ];  


       

        this.aComponentesProprio =  this.aComponentesProprio.sort((n1, n2) => 
        {
         if (n1.texto.toUpperCase() < n2.texto.toUpperCase()) {
          return -1;
         };
        });
        this.aCorProprio = this.utilService.montaCorItem( this.aComponentesProprio.length,'light');
       
        this.aComponentesEscore =  this.aComponentesEscore.sort((n1, n2) => 
        {
         if (n1.texto.toUpperCase() < n2.texto.toUpperCase()) {
          return -1;
         };
        });
        this.aCorEscore = this.utilService.montaCorItem( this.aComponentesEscore.length,'light');
       
       
        this.aGrafico =  this.aGrafico.sort((n1, n2) => 
        {
         if (n1.texto.toUpperCase() < n2.texto.toUpperCase()) {
          return -1;
         };
        });
        this.aCorGrafico = this.utilService.montaCorItem( this.aGrafico.length,'light');
       
       
        this.selecionarCalculoSistema();

  }

  async chamaGrafico(x:number) {    
      this.popoverController.dismiss();

      let modal = await this.modalController.create({
      component: GraficoComponent,
      componentProps:
      {
        Escore: x,
        Escoretipo: this.aGrafico[x].escoretipo,  // para a evolução tipo pizza
        NomeEscore: this.aGrafico[x].texto,
        aLabel: [],
        aData: [],
        Paciente: this.Paciente,
        Internacao: this.Internacao,
        cssClass: 'modalEscore',
      }
    });
    return await modal.present();

  } // fim showModal

  async checaDadosInternacao(aInternacao: any) {
    this.NomePaciente = aInternacao.NomePaciente;
    this.FotoPaciente = aInternacao.Imagem;
    this.Usuario = aInternacao.Usuario;
    this.Idade = aInternacao.Idade;
    this.Dias = aInternacao.Dias;
    this.Internacao = aInternacao.Id;
    this.Peso = this.utilService.formateNumberNew(aInternacao.Peso,2 );
    this.Datainternacao = aInternacao.Datainternacao;
    this.DescricaoHospital = aInternacao.DescricaoHospital;
    this.DescricaoSetor = aInternacao.DescricaoSetor;
    this.Leito = aInternacao.Leito;
    this.Paciente = aInternacao.Paciente
    this.Sexo = aInternacao.Sexo;
    this.Altura = aInternacao.Altura;
    this.Bsa = aInternacao.Bsa;
    this.PesoIdeal = aInternacao.PesoIdeal;
    this.utilService.verificarArquivo(this.FotoPaciente, (achou) => {
      if (achou == false) {
        this.FotoPaciente = '/assets/icone32x32.svg';
      }
    });
  }

 

  async selecionarCalculoSistema() {
    if (this.aCalculadoraCollection.length > 0 ){
      return;
    }
    let loading = await this.loadingController.create(
      { message: "Selecionando as calculadoras..." ,
        mode:'ios'
    });
    loading.present();
    this.checklistService.selecionarCalculoSistema(0) // 0 para a calculadoras com componente
      .then((response: any) => {
        this.aCalculadoraCollection = response;
      
      })
      .catch((response) => {
        //this.utilService.showAlertCallback('Não foi possível seleciona as calculadoreas')
      }).finally(() => {
        loading.dismiss();
      });
  }

  mudaMenu(div1:string, div2:string, div3:string,  div4:string){
    this.utilService.mostrarDiv(div1); 
    this.utilService.esconderDiv(div2);
    this.utilService.esconderDiv(div3);
    this.utilService.esconderDiv(div4);

  }

  async ShowModalComponentGeral(Component: any, Id: number) {
    this.popoverController.dismiss();

    let Nome = '';
    let Sigla = '';
    let Calculo = '';
    let Valornormal = '';
    let Descricaounidade = '';
    let Descricaocalculo = '';
    let Observacao = '';
    let Valorminimo = '';
    let Valormaximo = '';
    let Cor = "primary";
    if (Id>0) {
      let x=-1;
      for (let i=0; i<this.aCalculadoraCollection.length; i++){
        if (this.aCalculadoraCollection[i].Id == Id){
          x = i;
          break;
        }
      }
      if (x>-1) {
        Nome = this.aCalculadoraCollection[x].Nome;
        Sigla = this.aCalculadoraCollection[x].Sigla;
        Calculo = this.aCalculadoraCollection[x].Id;
        Valornormal = this.aCalculadoraCollection[x].Valornormal;
        Descricaounidade = this.aCalculadoraCollection[x].Descricaounidade;
        Descricaocalculo = this.aCalculadoraCollection[x].Descricaocalculo;
        Observacao = this.aCalculadoraCollection[x].Observacao;
        Valorminimo = this.aCalculadoraCollection[x].Valorminimo;
        Valormaximo = this.aCalculadoraCollection[x].Valormaximo;
      }
      
    }
   
    let modal = await this.modalController.create({
      component: Component,
      mode: 'ios',
      componentProps:
      {
        Sistema: 0,
        Calculo: Calculo,
        Sigla: Sigla,
        Nome: Nome,
        //Componente: Component,
        Valornormal: Valornormal,
        Descricaounidade: Descricaounidade,
        Descricaocalculo: Descricaocalculo,
        Observacao: Observacao,
        Valormaximo: Valormaximo,
        Valorminimo: Valorminimo,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente: '',
        Logado: this.Logado,
        Paciente: this.Paciente,
        Internacao: this.Internacao,
        Sexo: this.Sexo,
        Peso: this.Peso,
        Altura: this.Altura,
        Bsa: this.Bsa,
        aRass: [],
        PesoIdeal: this.PesoIdeal,
        Retorno: this.Retorno,
      }
    });
  
    
    return await modal.present();
    
  } // fim showModal

  async chamaRelatorio(pagina) {
    this.popoverController.dismiss();
    this.utilService.Movimentar(pagina);
   
  }

  

  
  
  
}
