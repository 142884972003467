import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilService } from './services/util.service';
import {LoadingController } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  selectedIndex = 0;
  labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  
  Logado: number = 0;
  dadosListMed: any;
  Cor: string = "light"
  Usuario: number = 0;
  Paciente: number = 0;
  
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private utilService: UtilService,
    private loadingController:LoadingController
  ) {


    this.initializeApp();

  

  }

  async initializeApp() {
    let loading = await this.loadingController.create(
      { message: "Iniciando o LISTMED..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.platform.ready().then(() => {
      this.dadosListMed = this.utilService.obterDadosDoSistema();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.testaAcessoSistema()
      
    });
    loading.dismiss();
  }

  async testaAcessoSistema() {
    this.utilService.getEvent().subscribe(data=>{
      if (data.key=="Internacao"){
        if (data.value.Paciente > 0) {
          this.Paciente = data.value.Paciente
        }else{
          this.Paciente=0;
        }
       
      }
    });


    this.utilService.getEvent().subscribe(data=>{
      if (data.key=="Logado"){
        if (data.value.Logado > 0) {
          this.Logado = data.value.Logado
        }else{
          this.Logado=0;
        }
       
      }

      if (this.dadosListMed != null && this.dadosListMed.Usuario != undefined) {
        this.Logado = this.dadosListMed.Usuario[0].Logado;
        this.Usuario = this.dadosListMed.Usuario[0].Id;
      }

      if (this.dadosListMed != null && this.dadosListMed.Internacao != undefined && this.dadosListMed.Internacao.length > 0) {
        this.Paciente = this.dadosListMed.Internacao.Paciente;
      }
  
    });



   

  }

  




}
