import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-vital',
  templateUrl: './vital.component.html',
  styleUrls: ['./vital.component.scss'],
})
export class VitalComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraPs', null) ancoraPs: ElementRef;
  @ViewChild('ancoraPd', null) ancoraPd: ElementRef;
  @ViewChild('ancoraFc', null) ancoraFc: ElementRef;
  @ViewChild('ancoraFr', null) ancoraFr: ElementRef;
  @ViewChild('ancoraTemp', null) ancoraTemp: ElementRef;
  @ViewChild('ancoraO2', null) ancoraO2: ElementRef;
  @ViewChild('ancoraHgt', null) ancoraHgt: ElementRef;
  Id: number = 0;
  Exames: any;
  Datalancamento: string = "";
  Usuario: number = 0;
  Paciente: number = 0;
  Idade: string = "";
  Nome: string = "";
  Internacao: number = 0;
  Historiaclinica: string = "";
  Setor: string = "";
  Leito: string = "";
  vitalForm: FormGroup;
  Botaovital: string = "Cadastrar";
  Datainternacao: string = "";
  Dias: string = "";
  vitalCollection: any = [];
  dadosListMed: any;
  Logado: number = 0;
  Ps: number;
  Pd: number;
  Fc: number;
  Fr: number;
  Temp: number;
  O2: number;
  Hgt: number;
  NomePaciente: string = "";
  FotoAtualPaciente: string = "";
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private alertController: AlertController,
    private checklistService:ChecklistService,
    private utilService: UtilService,



  ) {

    this.vitalForm = formBuilder.group({

      Id: ['', ''],
      Datalancamento: ['', ''],
      Pd: ['', ''],
      Ps: ['', ''],
      Fc: ['', ''],
      Fr: ['', ''],
      Temp: ['', ''],
      O2: ['', ''],
      Hgt: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Datalancamento = this.navParams.get('Dataevolucao');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
  }

  ngOnInit() {
    this.testaAcessoSistema();
    this.selecionarVital(0);
    this.selecionaExamesValoresVital()
    this.limpaDados();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Idade = this.dadosListMed.Internacao.Idade;
      this.Nome = this.dadosListMed.Internacao[0].Nome;
      this.Internacao = this.dadosListMed.Internacao.Id;;
      this.Setor = (this.dadosListMed.Internacao[0].Setor);
      this.Leito = (this.dadosListMed.Internacao[0].Leito);
      this.Datainternacao = this.dadosListMed.Internacao[0].Datainternacao;
      this.Dias = this.dadosListMed.Internacao[0].Dias;

    }
  }

  async presentAlertConfirm(form:any) {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: any = eval('this.vitalForm.value.' + this.Exames[i].nome);
      if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax || valor == null) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
          parametro = this.Exames[i].nome;
        }
        if (valor == null) {
          this.utilService.showAlertCallback('Dado incorreto em ' + this.Exames[i].msg,  null)
          this.utilService.focusInput(eval('this.ancora' + parametro));
          valor = 0;
        }
        this.vitalForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + ": " + valor + ' (' + this.Exames[i].vmin + '-' + this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
      }
    }



    let tipo = parseInt(this.vitalForm.value.Id);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'vital',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroVital(form);
          }
        }
      ]
    });

    await alert.present();

  }

  cadastroVital(form:any) {

    if (this.vitalForm.value.Id == -1 || this.vitalForm.value.Id == undefined || this.vitalForm.value.Id == null || this.vitalForm.value.Id == '') {
      this.lancamentoService.adicionarVital(form).then((response) => {
        this.selecionarVital(response[0].Id);
        this.utilService.showAlertCallback("Dados vitais ADICIONADOS com sucesso",  null);
        setTimeout(() => {
          this.Id = response[0].Id;
          this.vitalForm.controls['Id'].setValue(this.Id);
        }, 1000);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR os Dados Vitais",  null);

      });
    } else {
      this.lancamentoService.atualizarVital(form).then((response) => {
        this.utilService.showAlertCallback("Dados vitais atualizado com sucesso", null);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível atualizar os Dados vitais", null);

      });
    }

  }

  async selecionaExamesValoresVital() {
    this.utilService.selecionaExamesValoresVital()
      .then((response) => {
        this.Exames = response;

      })
      .catch((response) => {

        this.Exames = [];
      });
  }
  async selecionarVitalUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.lancamentoService.selecionarVitalUnico(Id).then((response) => {
      this.getBalancoVital(response);
    }).catch((response) => {

    });
  }



  async limpaDados() {
    this.Botaovital = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.vitalForm.controls['Datalancamento'].setValue(Datalancamento);
    this.vitalForm.controls['Ps'].setValue('');
    this.vitalForm.controls['Pd'].setValue('');
    this.vitalForm.controls['Fc'].setValue('');
    this.vitalForm.controls['Fr'].setValue('');
    this.vitalForm.controls['Temp'].setValue('');
    this.vitalForm.controls['O2'].setValue('');
    this.vitalForm.controls['Hgt'].setValue('');
    this.Id = -1;
    this.utilService.focusInput(this.ancoraPs);
  }

  async getBalancoVital(response: any) {
    this.Botaovital = "Alterar";
    let temp:number = this.utilService.formateNumber(response[0].Temp, 1);
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    this.vitalForm.controls['Datalancamento'].setValue(Datalancamento);
    this.vitalForm.controls['Ps'].setValue(response[0].Ps);
    this.vitalForm.controls['Pd'].setValue(response[0].Pd);
    this.vitalForm.controls['Fr'].setValue(response[0].Fr);
    this.vitalForm.controls['Fc'].setValue(response[0].Fc);
    this.vitalForm.controls['Temp'].setValue(temp);
    this.vitalForm.controls['O2'].setValue(response[0].O2);
    this.vitalForm.controls['Hgt'].setValue(response[0].Hgt);

  }

  async selecionarVital(Id: number) {
    this.lancamentoService.selecionarVital(this.Internacao, this.Paciente).then((response) => {
      this.vitalCollection = response;
      this.Id = Id;

    }).catch((response) => {

    });
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async testaDados() {

    let Datalancamento = this.vitalForm.value.Datalancamento.toString();
    Datalancamento = this.utilService.checaData(Datalancamento);
    
    let Ps: number =  this.vitalForm.value.Ps;
    Ps = this.utilService.formateNumber(Ps,1);

    if ( isNaN(Ps/1) || Ps == 0 || Ps == null || Ps == undefined) {
      this.utilService.showAlertCallback('PRESSÃO SISTÓLICA inválida',
        () => {
          this.utilService.focusInput(this.ancoraPs)
        }
      );
      return;
    }

    let Pd: number =  this.vitalForm.value.Pd;
    Pd = this.utilService.formateNumber(Pd,1);

    if ( isNaN(Pd/1) || Pd == 0 || Pd == null || Pd == undefined) {
      this.utilService.showAlertCallback('PRESSÃO DIASTÓLICA inválida',
        () => {
          this.utilService.focusInput(this.ancoraPd)
        }
      );
      return;
    }

    let Fc: number =  this.vitalForm.value.Fc;
    Fc = this.utilService.formateNumber(Fc,1);

    if ( isNaN(Fc/1) || Fc == 0 || Fc == null || Fc == undefined) {
      this.utilService.showAlertCallback('FREQUÊNCIA CARDÍACA inválida',
        () => {
          this.utilService.focusInput(this.ancoraFc)
        }
      );
      return;
    }

    let Fr: number =  this.vitalForm.value.Fr;
    Fr = this.utilService.formateNumber(Fr,1);

    if ( isNaN(Fr/1) || Fr == 0 || Fr == null || Fr == undefined) {
      this.utilService.showAlertCallback('FREQUÊNCIA RESPIRATÓRIO inválida',
        () => {
          this.utilService.focusInput(this.ancoraFr)
        }
      );
      return;
    }


    let Temp: number =  this.vitalForm.value.Temp;
    Temp = this.utilService.formateNumber(Temp,1);

    if ( isNaN(Temp/1) || Temp == 0 || Temp == null || Temp == undefined) {
      this.utilService.showAlertCallback('TEMPERATURA inválida',
        () => {
          this.utilService.focusInput(this.ancoraTemp)
        }
      );
      return;
    }

    let O2: number =  this.vitalForm.value.O2;
    O2 = this.utilService.formateNumber(O2,1);

    if ( isNaN(O2/1) || O2 == 0 || O2 == null || O2 == undefined) {
      this.utilService.showAlertCallback('OXIMETRIA inválida',
        () => {
          this.utilService.focusInput(this.ancoraO2)
        }
      );
      return;
    }

    let Hgt: number =  this.vitalForm.value.Hgt;
    Hgt = this.utilService.formateNumber(Hgt,1);

    if (isNaN(Hgt/1) || Hgt == 0 || Hgt == null || Hgt == undefined) {
      this.utilService.showAlertCallback('GLICEMIA inválida',
        () => {
          this.utilService.focusInput(this.ancoraHgt)
        }
      );
      return;
    }

    let form = {
      Id: this.vitalForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Ps: Ps,
      Pd: Pd,
      Fc: Fc,
      Fr: Fr,
      Temp: Temp,
      O2: O2,
      Hgt: Hgt,
      Usuario: this.Usuario

    }
    
    this.presentAlertConfirm(form);

  }





}



