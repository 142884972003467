import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';



@Component({
  selector: 'app-bioquimica',
  templateUrl: './bioquimica.component.html',
  styleUrls: ['./bioquimica.component.scss'],
})
export class BioquimicaComponent implements OnInit {
  @ViewChild('ancoraIdbioquimica', null) ancoraIdbioquimica: ElementRef;
  @ViewChild('ancoraDatalancamentobioquimica', null) ancoraDatalancamentobioquimica: ElementRef;
  @ViewChild('ancoraGlicose', null) ancoraGlicose: ElementRef;
  @ViewChild('ancoraUreia', null) ancoraUreia: ElementRef;
  @ViewChild('ancoraCreatinina', null) ancoraCreatinina: ElementRef;
  @ViewChild('ancoraAcidourico', null) ancoraAcidourico: ElementRef;
  @ViewChild('ancoraSodio', null) ancoraSodio: ElementRef;
  @ViewChild('ancoraCloro', null) ancoraCloro: ElementRef;
  @ViewChild('ancoraPotassio', null) ancoraPotassio: ElementRef;
  @ViewChild('ancoraMagnesio', null) ancoraMagnesio: ElementRef;
  @ViewChild('ancoraFosforo', null) ancoraFosforo: ElementRef;
  @ViewChild('ancoraFerro', null) ancoraFerro: ElementRef;
  @ViewChild('ancoraCalcio', null) ancoraCalcio: ElementRef;
  @ViewChild('ancoraFerrentina', null) ancoraFerrentina: ElementRef;
  @ViewChild('ancoraTriglicerides', null) ancoraTriglicerides: ElementRef;
  @ViewChild('ancoraColesteroltotal', null) ancoraColesteroltotal: ElementRef;
  @ViewChild('ancoraColesterolhdl', null) ancoraColesterolhdl: ElementRef;
  @ViewChild('ancoraColesterolldl', null) ancoraColesterolldl: ElementRef;
  @ViewChild('ancoraColesterolvldl', null) ancoraColesterolvldl: ElementRef;
  @ViewChild('ancoraFosfatasealcalina', null) ancoraFosfatasealcalina: ElementRef;
  @ViewChild('ancoraAmilase', null) ancoraAmilase: ElementRef;
  @ViewChild('ancoraLipase', null) ancoraLipase: ElementRef;
  @ViewChild('ancoraInsulina', null) ancoraInsulina: ElementRef;
  @ViewChild('ancoraDesidrogenaselatica', null) ancoraDesidrogenaselatica: ElementRef;
  @ViewChild('ancoraTgoast', null) ancoraTgoast: ElementRef;
  @ViewChild('ancoraTgpalt', null) ancoraTgpalt: ElementRef;
  @ViewChild('ancoraBilirrubinatotal', null) ancoraBilirrubinatotal: ElementRef;
  @ViewChild('ancoraBilirrubinadireta', null) ancoraBilirrubinadireta: ElementRef;
  @ViewChild('ancoraBilirrubinaindireta', null) ancoraBilirrubinaindireta: ElementRef;
  @ViewChild('ancoraProcalcitonina', null) ancoraProcalcitonina: ElementRef;
  @ViewChild('ancoraGamagt', null) ancoraGamagt: ElementRef;
  @ViewChild('ancoraCktotal', null) ancoraCktotal: ElementRef;
  @ViewChild('ancoraCkmbenzimatico', null) ancoraCkmbenzimatico: ElementRef;
  @ViewChild('ancoraCkmbmassa', null) ancoraCkmbmassa: ElementRef;
  @ViewChild('ancoraMioglobina', null) ancoraMioglobina: ElementRef;
  @ViewChild('ancoraTroponina', null) ancoraTroponina: ElementRef;
  @ViewChild('ancoraProteinastotais', null) ancoraProteinastotais: ElementRef;
  @ViewChild('ancoraGlobulina', null) ancoraGlobulina: ElementRef;
  @ViewChild('ancoraAlbumina', null) ancoraAlbumina: ElementRef;
  @ViewChild('ancoraHemoglobinaglicolisada', null) ancoraHemoglobinaglicolisada: ElementRef;
  @ViewChild('ancoraBnp', null) ancoraBnp: ElementRef;
  private Datalancamentobioquimica:string='';
  private IdGeral:number=0;
  private Exames:any;
  private Usuario:number = 0;
  private Paciente: number = 0;
  private Idade:string = "";
  private Nome:string = "";
  private Internacao: number = 0;
  private Historiaclinica:string = "";
  private Setor:string = "";
  private Leito:any = "";
  public bioquimicaForm: FormGroup;
  public Botaobioquimica:string = "Cadastrar";
  private Datainternacao:string = "";
  private Dias:string = "";
  private bioquimicaCollection: any = [];
  public dadosListMed:any;
  private Logado:number=0;
  public Glicose:number=0;
  public Ureia:number=0;
  public Creatinina:number=0;
  public Acidourico:number=0;
  public Sodio:number=0;
  public Cloro:number=0;
  public Potassio:number=0;
  public Magnesio:number=0;
  public Fosforo:number=0;
  public Ferro:number=0;
  public Calcio:number=0;
  public Ferrentina:number=0;
  public Triglicerides:number=0;
  public Colesteroltotal:number=0;
  public Colesterolhdl:number=0;
  public Colesterolldl:number=0;
  public Colesterolvldl:number=0;
  public Fosfatasealcalina:number=0;
  public Amilase:number=0;
  public Lipase:number=0;
  public Insulina:number=0;
  public Desidrogenaselatica:number=0;
  public Tgoast:number=0;
  public Tgpalt:number=0;
  public Bilirrubinatotal:number=0;
  public Bilirrubinadireta:number=0;
  public Bilirrubinaindireta:number=0;
  public Procalcitonina:number=0;
  public Gamagt:number=0;
  public Cktotal:number=0;
  public Ckmbenzimatico:number=0;
  public Ckmbmassa:number=0;
  public Mioglobina:number=0;
  public Troponina:number=0;
  public Proteinastotais:number=0;
  public Globulina:number=0;
  public Albumina:number=0;
  public Hemoglobinaglicolisada:number=0;
  public Bnp:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService : LaboratorioService,
    private alertController:AlertController
    ) {

      this.bioquimicaForm = formBuilder.group({
        
        Idbioquimica: ['', ''],
        Datalancamentobioquimica: ['', ''],
        Glicose: ['', ''],
        Ureia:['', ''],
        Creatinina:['', ''],
        Acidourico:['', ''],
        Sodio:['', ''],
        Cloro:['', ''],
        Potassio:['', ''],
        Magnesio:['', ''],
        Fosforo:['', ''],
        Ferro:['', ''],
        Calcio:['', ''],
        Ferrentina:['', ''],
        Triglicerides:['', ''],
        Colesteroltotal:['', ''],
        Colesterolhdl:['', ''],
        Colesterolldl:['', ''],
        Colesterolvldl:['', ''],
        Fosfatasealcalina:['', ''],
        Amilase:['', ''],
        Lipase:['', ''],
        Insulina:['', ''],
        Desidrogenaselatica:['', ''],
        Tgoast:['', ''],
        Tgpalt:['', ''],
        Bilirrubinatotal:['', ''],
        Bilirrubinadireta:['', ''],
        Bilirrubinaindireta:['', ''],
        Procalcitonina:['', ''],
        Gamagt:['', ''],
        Cktotal:['', ''],
        Ckmbenzimatico:['', ''],
        Ckmbmassa:['', ''],
        Mioglobina:['', ''],
        Troponina:['', ''],
        Proteinastotais:['', ''],
        Globulina:['', ''],
        Albumina:['', ''],
        Hemoglobinaglicolisada:['', ''],
        Bnp:['', ''],
  
  
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
     
    }

    ngOnInit() {
      this.selecionaDadosGerais();
      this.selecionarBioquimica(0);
      this.selecionarExames()
    }

    async selecionaDadosGerais() {
      this.Internacao=  this.dadosListMed.Internacao.Id;
      this.Usuario =this.dadosListMed.Internacao[0].Usuario;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.IdGeral = 0;
      this.limpaDadosBioquimica();
    
    }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
     let valor:number = eval('this.bioquimicaForm.value.' + this.Exames[i].nome);
     if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax ) {
      x = x + 1
      if (x == 1) {
        cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
        parametro = this.Exames[i].nome;
      }
     // this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg , 2000, null)
      this.bioquimicaForm.controls[this.Exames[i].nome].setValue(valor);
      cValores =  cValores + '-> '+ this.Exames[i].msg + ": " + valor +  ' (' + this.Exames[i].vmin + '-' +  this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
     }
  }

  
    let tipo = parseInt(this.bioquimicaForm.value.Idcoagulograma);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'BIOQUÍMICA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroBioquimica();
          }
        }
      ]
    });

    await alert.present();
  }


  cadastroBioquimica() {
    let form = {
      Id: this.bioquimicaForm.value.Idbioquimica,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.bioquimicaForm.value.Datalancamentobioquimica),
      Usuario: this.Usuario,
      Glicose: this.bioquimicaForm.value.Glicose,
      Ureia: this.bioquimicaForm.value.Ureia,
      Creatinina:this.bioquimicaForm.value.Creatinina,
      Acidourico:this.bioquimicaForm.value.Acidourico,
      Sodio:this.bioquimicaForm.value.Sodio,
      Cloro:this.bioquimicaForm.value.Cloro,
      Potassio:this.bioquimicaForm.value.Potassio,
      Magnesio:this.bioquimicaForm.value.Magnesio,
      Fosforo:this.bioquimicaForm.value.Fosforo,
      Ferro:this.bioquimicaForm.value.Ferro,
      Calcio:this.bioquimicaForm.value.Calcio,
      Ferrentina:this.bioquimicaForm.value.Ferrentina,
      Triglicerides:this.bioquimicaForm.value.Triglicerides,
      Colesteroltotal:this.bioquimicaForm.value.Colesteroltotal,
      Colesterolhdl:this.bioquimicaForm.value.Colesterolhdl,
      Colesterolldl:this.bioquimicaForm.value.Colesterolldl,
      Colesterolvldl:this.bioquimicaForm.value.Colesterolvldl,
      Fosfatasealcalina:this.bioquimicaForm.value.Fosfatasealcalina,
      Amilase:this.bioquimicaForm.value.Amilase,
      Lipase:this.bioquimicaForm.value.Lipase,
      Insulina:this.bioquimicaForm.value.Insulina,
      Desidrogenaselatica:this.bioquimicaForm.value.Desidrogenaselatica,
      Tgoast:this.bioquimicaForm.value.Tgoast,
      Tgpalt:this.bioquimicaForm.value.Tgpalt,
      Bilirrubinatotal:this.bioquimicaForm.value.Bilirrubinatotal,
      Bilirrubinadireta:this.bioquimicaForm.value.Bilirrubinadireta,
      Bilirrubinaindireta:this.bioquimicaForm.value.Bilirrubinaindireta,
      Procalcitonina:this.bioquimicaForm.value.Procalcitonina,
      Gamagt:this.bioquimicaForm.value.Gamagt,
      Cktotal:this.bioquimicaForm.value.Cktotal,
      Ckmbenzimatico:this.bioquimicaForm.value.Ckmbenzimatico,
      Ckmbmassa:this.bioquimicaForm.value.Ckmbmassa,
      Mioglobina:this.bioquimicaForm.value.Mioglobina,
      Troponina:this.bioquimicaForm.value.Troponina,
      Proteinastotais:this.bioquimicaForm.value.Proteinastotais,
      Globulina:this.bioquimicaForm.value.Globulina,
      Albumina:this.bioquimicaForm.value.Albumina,
      Hemoglobinaglicolisada:this.bioquimicaForm.value.Hemoglobinaglicolisada,
      Bnp:this.bioquimicaForm.value.Bnp,
    }

    
    if (this.bioquimicaForm.value.Idbioquimica == 0 || this.bioquimicaForm.value.Idbioquimica == undefined || this.bioquimicaForm.value.Idbioquimica == null || this.bioquimicaForm.value.Idbioquimica == '') {
      this.laboratorioService.adicionarBioquimica(form).then((response) => {
        this.selecionarBioquimica(response[0].Id);
        this.utilService.showToastCallBack("Bioquimica incluido com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Bioquimica", 2000, null);

      });
    } else {
      this.laboratorioService.atualizarBioquimica(form).then((response) => {
        this.utilService.showToastCallBack("Bioquimica atualizado com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Bioquimica", 2000, null);

      });
    }

  }

  async selecionarBioquimicaUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosBioquimica();
      return ;
    }
    this.laboratorioService.selecionarBioquimicaUnico(Id).then((response) => {
      this.getBalancoBioquimica(response);
    }).catch((response) => {

    });
  }

  async limpaDadosBioquimica() {
    this.Botaobioquimica = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentobioquimica = dtHoje;
    this.bioquimicaForm.controls['Datalancamentobioquimica'].setValue(dtHoje);
    this.bioquimicaForm.controls['Glicose'].setValue(0);
    this.bioquimicaForm.controls['Ureia'].setValue(0);
    this.bioquimicaForm.controls['Creatinina'].setValue(0);
    this.bioquimicaForm.controls['Acidourico'].setValue(0);
    this.bioquimicaForm.controls['Sodio'].setValue(0);
    this.bioquimicaForm.controls['Cloro'].setValue(0);
    this.bioquimicaForm.controls['Potassio'].setValue(0);
    this.bioquimicaForm.controls['Magnesio'].setValue(0);
    this.bioquimicaForm.controls['Fosforo'].setValue(0);
    this.bioquimicaForm.controls['Ferro'].setValue(0);
    this.bioquimicaForm.controls['Calcio'].setValue(0);
    this.bioquimicaForm.controls['Ferrentina'].setValue(0);
    this.bioquimicaForm.controls['Triglicerides'].setValue(0);
    this.bioquimicaForm.controls['Colesteroltotal'].setValue(0);
    this.bioquimicaForm.controls['Colesterolhdl'].setValue(0);
    this.bioquimicaForm.controls['Colesterolldl'].setValue(0);
    this.bioquimicaForm.controls['Colesterolvldl'].setValue(0);
    this.bioquimicaForm.controls['Fosfatasealcalina'].setValue(0);
    this.bioquimicaForm.controls['Amilase'].setValue(0);
    this.bioquimicaForm.controls['Lipase'].setValue(0);
    this.bioquimicaForm.controls['Insulina'].setValue(0);
    this.bioquimicaForm.controls['Desidrogenaselatica'].setValue(0);
    this.bioquimicaForm.controls['Tgoast'].setValue(0);
    this.bioquimicaForm.controls['Tgpalt'].setValue(0);
    this.bioquimicaForm.controls['Bilirrubinatotal'].setValue(0);
    this.bioquimicaForm.controls['Bilirrubinadireta'].setValue(0);
    this.bioquimicaForm.controls['Bilirrubinaindireta'].setValue(0);
    this.bioquimicaForm.controls['Procalcitonina'].setValue(0);
    this.bioquimicaForm.controls['Gamagt'].setValue(0);
    this.bioquimicaForm.controls['Cktotal'].setValue(0);
    this.bioquimicaForm.controls['Ckmbenzimatico'].setValue(0);
    this.bioquimicaForm.controls['Ckmbmassa'].setValue(0);
    this.bioquimicaForm.controls['Mioglobina'].setValue(0);
    this.bioquimicaForm.controls['Troponina'].setValue(0);
    this.bioquimicaForm.controls['Proteinastotais'].setValue(0);
    this.bioquimicaForm.controls['Globulina'].setValue(0);
    this.bioquimicaForm.controls['Albumina'].setValue(0);
    this.bioquimicaForm.controls['Hemoglobinaglicolisada'].setValue(0);
    this.bioquimicaForm.controls['Bnp'].setValue(0);
    this.utilService.focusInput(eval('this.ancoraGlicose'));
  }

  async getBalancoBioquimica(response: any) {
    this.Botaobioquimica = "Alterar";
    let Datalancamentobioquimica = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.bioquimicaForm.controls['Datalancamentobioquimica'].setValue(Datalancamentobioquimica);
    this.bioquimicaForm.controls['Glicose'].setValue(response[0].Glicose);
    this.bioquimicaForm.controls['Ureia'].setValue(response[0].Ureia);
    this.bioquimicaForm.controls['Creatinina'].setValue(response[0].Creatinina.replace(",", "."));
    this.bioquimicaForm.controls['Acidourico'].setValue(response[0].Acidourico.replace(",", "."));
    this.bioquimicaForm.controls['Sodio'].setValue(response[0].Sodio.replace(",", "."));
    this.bioquimicaForm.controls['Cloro'].setValue(response[0].Cloro.replace(",", "."));
    this.bioquimicaForm.controls['Potassio'].setValue(response[0].Potassio.replace(",", "."));
    this.bioquimicaForm.controls['Magnesio'].setValue(response[0].Magnesio.replace(",", "."));
    this.bioquimicaForm.controls['Fosforo'].setValue(response[0].Fosforo.replace(",", "."));
    this.bioquimicaForm.controls['Ferro'].setValue(response[0].Ferro.replace(",", "."));
    this.bioquimicaForm.controls['Calcio'].setValue(response[0].Calcio.replace(",", "."));
    this.bioquimicaForm.controls['Ferrentina'].setValue(response[0].Ferrentina.replace(",", "."));
    this.bioquimicaForm.controls['Triglicerides'].setValue(response[0].Triglicerides.replace(",", "."));
    this.bioquimicaForm.controls['Colesteroltotal'].setValue(response[0].Colesteroltotal.replace(",", "."));
    this.bioquimicaForm.controls['Colesterolhdl'].setValue(response[0].Colesterolhdl.replace(",", "."));
    this.bioquimicaForm.controls['Colesterolldl'].setValue(response[0].Colesterolldl.replace(",", "."));
    this.bioquimicaForm.controls['Colesterolvldl'].setValue(response[0].Colesterolvldl.replace(",", "."));
    this.bioquimicaForm.controls['Fosfatasealcalina'].setValue(response[0].Fosfatasealcalina.replace(",", "."));
    this.bioquimicaForm.controls['Amilase'].setValue(response[0].Amilase.replace(",", "."));
    this.bioquimicaForm.controls['Lipase'].setValue(response[0].Lipase.replace(",", "."));
    this.bioquimicaForm.controls['Insulina'].setValue(response[0].Insulina.replace(",", "."));
    this.bioquimicaForm.controls['Desidrogenaselatica'].setValue(response[0].Desidrogenaselatica.replace(",", "."));
    this.bioquimicaForm.controls['Tgoast'].setValue(response[0].Tgoast.replace(",", "."));
    this.bioquimicaForm.controls['Tgpalt'].setValue(response[0].Tgpalt.replace(",", "."));
    this.bioquimicaForm.controls['Bilirrubinatotal'].setValue(response[0].Bilirrubinatotal.replace(",", "."));
    this.bioquimicaForm.controls['Bilirrubinadireta'].setValue(response[0].Bilirrubinadireta.replace(",", "."));
    this.bioquimicaForm.controls['Bilirrubinaindireta'].setValue(response[0].Bilirrubinaindireta.replace(",", "."));
    this.bioquimicaForm.controls['Procalcitonina'].setValue(response[0].Procalcitonina.replace(",", "."));
    this.bioquimicaForm.controls['Gamagt'].setValue(response[0].Gamagt.replace(",", "."));
    this.bioquimicaForm.controls['Cktotal'].setValue(response[0].Cktotal.replace(",", "."));
    this.bioquimicaForm.controls['Ckmbenzimatico'].setValue(response[0].Ckmbenzimatico.replace(",", "."));
    this.bioquimicaForm.controls['Ckmbmassa'].setValue(response[0].Ckmbmassa.replace(",", "."));
    this.bioquimicaForm.controls['Mioglobina'].setValue(response[0].Mioglobina.replace(",", "."));
    this.bioquimicaForm.controls['Troponina'].setValue(response[0].Troponina.replace(",", "."));
    this.bioquimicaForm.controls['Proteinastotais'].setValue(response[0].Proteinastotais.replace(",", "."));
    this.bioquimicaForm.controls['Globulina'].setValue(response[0].Globulina.replace(",", "."));
    this.bioquimicaForm.controls['Albumina'].setValue(response[0].Albumina.replace(",", "."));
    this.bioquimicaForm.controls['Hemoglobinaglicolisada'].setValue(response[0].Hemoglobinaglicolisada.replace(",", "."));
    this.bioquimicaForm.controls['Bnp'].setValue(response[0].Bnp.replace(",", "."));
  }

  async selecionarBioquimica(seek:number) {
    this.laboratorioService.selecionarBioquimica(this.Internacao, this.Paciente).then((response) => {
      this.bioquimicaCollection = response;
      this.IdGeral = seek;
    }).catch((response) => {

    });
  }



  async mostrarValor(campo:string) {
    let valor: any = eval('this.bioquimicaForm.value.' + campo);
    if (valor == null || valor == 'NaN' || isNaN(valor) || valor == '') {
      this.bioquimicaForm.controls[campo].setValue(valor);
    }

    
    if (campo == "Glicose") {
      this.Glicose = valor;
    } else if (campo == "Ureia") {
      this.Ureia = valor;
    } else if (campo == "Creatinina") {
      this.Creatinina = valor;
    } else if (campo == "Acidourico") {
      this.Acidourico = valor;
    } else if (campo == "Sodio") {
      this.Sodio = valor;
    } else if (campo == "Cloro") {
      this.Cloro = valor;
    } else if (campo == "Potassio") {
      this.Potassio = valor;
    } else if (campo == "Magnesio") {
      this.Magnesio = valor;
    } else if (campo == "Fosforo") {
      this.Fosforo = valor;
    } else if (campo == "Ferro") {
      this.Ferro = valor;
    } else if (campo == "Calcio") {
      this.Calcio = valor;
    } else if (campo == "Ferrentina") {
      this.Ferrentina = valor;
    } else if (campo == "Ferrentina") {
      this.Ferrentina = valor;
    } else if (campo == "Triglicerides") {
      this.Triglicerides = valor;
    } else if (campo == "Colesteroltotal") {
      this.Colesteroltotal = valor;
    } else if (campo == "Colesteroltotal") {
      this.Colesteroltotal = valor;
    } else if (campo == "Colesterolhdl") {
      this.Colesterolhdl = valor;
    } else if (campo == "Colesterolhdl") {
      this.Colesterolhdl = valor;
    } else if (campo == "Colesterolldl") {
      this.Colesterolldl = valor;
    } else if (campo == "Colesterolvldl") {
      this.Colesterolvldl = valor;
    } else if (campo == "Fosfatasealcalina") {
      this.Fosfatasealcalina = valor;
    } else if (campo == "Amilase") {
      this.Amilase = valor;
    } else if (campo == "Lipase") {
      this.Lipase = valor;
    } else if (campo == "Insulina") {
      this.Insulina = valor;
    } else if (campo == "Desidrogenaselatica") {
      this.Desidrogenaselatica = valor;
    } else if (campo == "Fosfatasealcalina") {
      this.Fosfatasealcalina = valor;
    } else if (campo == "Tgoast") {
      this.Tgoast = valor;
    } else if (campo == "Tgoast") {
      this.Tgoast = valor;
    } else if (campo == "Tgpalt") {
      this.Tgpalt = valor;
    } else if (campo == "Bilirrubinatotal") {
      this.Bilirrubinatotal = valor;
    } else if (campo == "Bilirrubinadireta") {
      this.Bilirrubinadireta = valor;
    } else if (campo == "Bilirrubinaindireta") {
      this.Bilirrubinaindireta = valor;
    } else if (campo == "Procalcitonina") {
      this.Procalcitonina = valor;
    } else if (campo == "Gamagt") {
      this.Gamagt = valor;
    } else if (campo == "Cktotal") {
      this.Cktotal = valor;
    } else if (campo == "Cktotal") {
      this.Cktotal = valor;
    } else if (campo == "Ckmbenzimatico") {
      this.Ckmbenzimatico = valor;
    } else if (campo == "Ckmbmassa") {
      this.Ckmbmassa = valor;
    } else if (campo == "Mioglobina") {
      this.Mioglobina = valor;
      this.Ckmbmassa = valor;
    } else if (campo == "Troponina") {
      this.Troponina = valor;
    } else if (campo == "Proteinastotais") {
      this.Proteinastotais = valor;
    } else if (campo == "Globulina") {
      this.Globulina = valor;
    } else if (campo == "Albumina") {
      this.Albumina = valor;
    } else if (campo == "Hemoglobinaglicolisada") {
      this.Hemoglobinaglicolisada = valor;
    } else if (campo == "Bnp") {
      this.Bnp = valor;
    }
  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresBioquimica()
    .then((response) => {
      this.Exames = response;

    })
    .catch((response) => {

      this.Exames = [];
    });
  }
  

}
