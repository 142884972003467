import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-fasthug',
  templateUrl: './fasthug.component.html',
  styleUrls: ['./fasthug.component.scss'],
})
export class FasthugComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  Id: number = -1;
  lancamentosCollection: any = [];
  Feeding: any;
  Analgesia: any;
  Sedation: any;
  Thromboembolic: any;
  Head: any;
  Ulcer: any;
  Glucose: any;

  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  fasthugForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datainternacao: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number = 0;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Botao: string = "CALCULAR";
  itensColection: any = [];
  NomePaciente: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Sistema: number = 0
  Comentario: string = ""
  Calculo: number = 0;
  aFeeding: any = [];
  aAnalgesia: any = [];
  aSedation: any = [];
  aThromboembolic: any = [];
  aHead: any = [];
  aUlcer: any = [];
  aGlucose: any = [];
  aFASTHUG: any = [
    { Id: 28, Nome: "Dieta", Vetor: this.aFeeding },
    { Id: 7, Nome: "Analgesia", vetor: this.aAnalgesia },
    { Id: 8, Nome: "Sedacao", vetor: this.aSedation },
    { Id: 47, Nome: "Troboembolismo", vetor: this.aThromboembolic },
    { Id: 2, Nome: "Cabeceira", vetor: this.aHead },
    { Id: 55, Nome: "Úlcera gastrica", vetor: this.aUlcer },
    { Id: 36, Nome: "Glicemia", vetor: this.aGlucose },
  ]

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {
    this.fasthugForm = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Feeding: ['', ''],
      Analgesia: ['', ''],
      Sedation: ['', ''],
      Thromboembolic: ['', ''],
      Head: ['', ''],
      Ulcer: ['', ''],
      Glucose: ['', ''],
      Comentario: ['', ''],
      Observacao: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() {
    this.Calculo = 45;

  }

  ionViewDidEnter() {
    for (let i = 0; i < this.aFASTHUG.length; i++) {
      this.selecionarEscoreRelacao(this.aFASTHUG[i].Id, this.aFASTHUG[i].Nome);
    }
    this.selecionarDadosCalculoUnico()
    this.testaAcessoSistema();

  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarEscoreRelacao(Escore: number, Nome: string) {
    this.checklistService.selecionarEscoreRelacao(Escore)
      .then((RelEscore: any) => {
        if (Escore == 28) {
          this.aFeeding = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Feeding = RelEscore[z].Id.toString();
              break;
            }
          }

        }
        if (Escore == 7) {
          this.aAnalgesia = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Analgesia = RelEscore[z].Id.toString();
              break;
            }
          }



        }
        if (Escore == 8) {
          this.aSedation = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Sedation = RelEscore[z].Id.toString();
              break;
            }
          }
        }
        if (Escore == 47) {
          this.aThromboembolic = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Thromboembolic = RelEscore[z].Id.toString();
              break;
            }
          }
        }
        if (Escore == 2) {
          this.aHead = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Head = RelEscore[z].Id.toString();
              break;
            }
          }
        }
        if (Escore == 55) {
          this.aUlcer = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Ulcer = RelEscore[z].Id.toString();
              break;
            }
          }
        }
        if (Escore == 36) {
          this.aGlucose = RelEscore;
          for (let z = 0; z < RelEscore.length; z++) {
            if (RelEscore[z].Padrao == 1) {
              this.Glucose = RelEscore[z].Id.toString();
              break;
            }
          }
        }

        this.limpaDados();

      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore: ' + Nome);
      });
  }


  async selecionarDadosCalculoUnico() {
    let loading = await this.loadingController.create(
      {
        message: "Selecionando os dados...",
        mode: 'ios'
      }
    );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
      .then((response: any) => {
        this.Sigla = response[0].Sigla;
        this.Nome = response[0].Nome
        this.Componente = response[0].Componente
        this.Valornormal = response[0].Valornormal;
        this.Descricaounidade = response[0].Descricaounidade
        this.Descricaocalculo = response[0].Descricaocalculo
        this.Observacao = response[0].Observacao
        this.Valorminimo = response[0].Valorminimo
        this.Valormaximo = response[0].Valormaximo


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível selecionar os dados da calculadora')
      }).finally(() => {
        loading.dismiss();
      });

  }




  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;
    }).catch((response) => {
      this.utilService.showToastCallBack("Ainda não existem lançamento lançamento", 2000, null)
    });
  }




  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularFasthug() {
    this.Valor = 0;
    let Feeding: any = this.fasthugForm.value.Feeding;
    if (Feeding == null || Feeding == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de ALIMENTAÇÃO", null)
      return;

    }

    
    this.Valor++;
    this.Feeding = Feeding;
   

    let Analgesia: any = this.fasthugForm.value.Analgesia;
    if (Analgesia == null || Analgesia == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de ANALGESIAF", null)
      return;

    }

    
    this.Analgesia = Analgesia;
    this.Valor++


    let Sedation: any = this.fasthugForm.value.Sedation;
    if (Sedation == null || Sedation == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de SEDAÇÃO", null)
      return;

    }

    this.Sedation = Sedation;
    this.Valor++;
   

    let Thromboembolic: any = this.fasthugForm.value.Thromboembolic;
    if (Thromboembolic == null || Thromboembolic == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de PROFILAXIA DE TVP", null)
      return;
    }

    this.Thromboembolic = Thromboembolic;
    this.Valor++;
  


    let Head: any = this.fasthugForm.value.Head;
    if (Head == null || Head == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CABECEIRA", null)
      return;

    }

    this.Head = Head;
    this.Valor++;
    

    let Ulcer: any = this.fasthugForm.value.Ulcer;
    if (Ulcer == null || Ulcer == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de PROFILAXIA DE ÚLCERA", null)
      return;

    }

  
    this.Ulcer = Ulcer;
    this.Valor++
   

    let Glucose: any = this.fasthugForm.value.Glucose;
    if (Glucose == null || Glucose == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CONTROLE GLICEMICO", null)
      return;

    }

    this.Glucose = Glucose;
    this.Valor++;
   


    let Range: string = " (FORA DA NORMALIIDADE)"
    if (this.Valor == 7) {
      Range = " (TODOS ÍTENS CHECADOS)"
    }
    this.Valor = this.utilService.formateNumber(this.Valor, 2);
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Paciente > 0 && this.Logado > 0 && this.Valor == 7) {
      this.presentAlertConfirm()
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.fasthugForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: string = "";


    Relacao = Relacao + this.Feeding + '*';
    Relacao = Relacao + this.Analgesia + '*';
    Relacao = Relacao + this.Sedation + '*';
    Relacao = Relacao + this.Thromboembolic + '*'; //p/f
    Relacao = Relacao + this.Head + '*';
    Relacao = Relacao + this.Ulcer + '*';
    Relacao = Relacao + this.Glucose;

    let Datalancamento: any = this.fasthugForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.fasthugForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.fasthugForm.value.Comentario

    }


    if (this.fasthugForm.value.Id == undefined ||
      this.fasthugForm.value.Id == -1 || this.fasthugForm.value.Id == null || this.fasthugForm.value.Id == undefined) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso", null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.fasthugForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível INCLUIR o " + this.Nome, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso", null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async limpaDados() {
    this.Id = -1;
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    
    this.fasthugForm.controls['Datalancamento'].setValue(Datalancamento);
    setTimeout(() => {
      this.fasthugForm.controls['Feeding'].setValue(['84']);
      this.fasthugForm.controls['Analgesia'].setValue(["138"]);
      this.fasthugForm.controls['Sedation'].setValue(["231"]);
      this.fasthugForm.controls['Thromboembolic'].setValue(["125"]);
      this.fasthugForm.controls['Head'].setValue(["4"]);
      this.fasthugForm.controls['Ulcer'].setValue(["174"]);
      this.fasthugForm.controls['Glucose'].setValue(["515"]);
      
    }, 1000);
    this.Botao = "CALCULAR"
    this.Valor = 0;
    this.fasthugForm.controls['Id'].setValue(this.Id);


  }



  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Feeding = Relacao[0].split(',');
    this.Analgesia = Relacao[1].split(','); 
    this.Sedation = Relacao[2].split(','); 
    this.Thromboembolic = Relacao[3].split(',');
    this.Head = Relacao[4].split(','); 
    this.Ulcer = Relacao[5].split(',');
    this.Glucose = Relacao[6].split(',');
    this.fasthugForm.controls['Datalancamento'].setValue(Datalancamento);
    this.fasthugForm.controls['Feeding'].setValue(this.Feeding);
    this.fasthugForm.controls['Analgesia'].setValue(this.Analgesia);
    this.fasthugForm.controls['Sedation'].setValue(this.Sedation);
    this.fasthugForm.controls['Thromboembolic'].setValue(this.Thromboembolic);
    this.fasthugForm.controls['Head'].setValue(this.Head);
    this.fasthugForm.controls['Ulcer'].setValue(this.Ulcer);
    this.fasthugForm.controls['Glucose'].setValue(this.Glucose);
    this.fasthugForm.controls['Comentario'].setValue(response[0].Comentario);

  }

  async apagarPadrao(clicados: any, Sel: string, Vetor:any) {
    let Padrao:number=0;
    for (let z=0; z<Vetor.length; z++) {
      if (Vetor[z].Padrao == 1) {
        Padrao = Vetor[z].Id;
        break;
      }
    }
    let Novolista: any = [];
    for (let i = 0; i < clicados.length; i++) {
      if (clicados[i] != 0 && clicados[i] != Padrao) {
        Novolista.push(
          clicados[i]
        )
      }
    }

    if (Novolista.length > 0) {
      this.fasthugForm.controls[Sel].setValue(Novolista);
    }

  }

}

