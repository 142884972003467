import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';
import { TabelaService } from '../../services/tabela.service';

@Component({
  selector: 'app-geral',
  templateUrl: './geral.component.html',
  styleUrls: ['./geral.component.scss'],
})
export class GeralComponent implements OnInit {
  @ViewChild('ancoraCampo0', null) ancoraCampo0: ElementRef;
  @ViewChild('ancoraCampo1', null) ancoraCampo1: ElementRef;
  @ViewChild('ancoraCampo2', null) ancoraCampo2: ElementRef;
  @ViewChild('ancoraCampo3', null) ancoraCampo3: ElementRef;
  @ViewChild('ancoraCampo4', null) ancoraCampo4: ElementRef;
  @ViewChild('ancoraCampo5', null) ancoraCampo5: ElementRef;
  @ViewChild('ancoraCampo6', null) ancoraCamp6: ElementRef;

  Sigla: string = "";
  geralForm: FormGroup;
  Paciente: number = 0;
  Usuario: number = 0;
  Nome: string = "";
  botao: string = "CALCULAR";
  Datainternacao: string = "";
  lancamentosCollection: any = [];
  dadosListMed: any;
  Logado: number = 0;
  Internacao: number;
  Valor: any;
  Calculo: number;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  DescricaoCalculo: string = "";
  Observacao: any;
  Resultado: string = '';
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Datalancamento: any;
  Id: number = -1;
  Botao: string = "CALCULAR";
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  Idade: number = 0;
  Descricaocalculo: string = "";
  Valorminimo: string = "";
  Valormaximo: string = "";
  Altura: any = 0;
  Peso: any = 0;
  Bsa: any = 0;
  Mostrar: number = 1;
  PesoIdeal: any = 0;
  Sistema: number = 0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController
  ) {

    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.geralForm = formBuilder.group({

      // GERAL
      Id: ['', ''],
      Datalancamento: ['', ''],
      Campo0: ['', ''],
      Campo1: ['', ''],
      Campo2: ['', ''],
      Campo3: ['', ''],
      Campo4: ['', ''],
      Campo5: ['', ''],
      Campo6: ['', ''],
      Sexo: ['', ''],
      Observacao: ['', ''],
      Resultado: ['', ''],

    })
    this.Calculo = this.navParams.get('Calculo');
    this.Sigla = this.navParams.get('Sigla');
    this.Nome = this.navParams.get('Nome');
    this.Componente = this.navParams.get('Componente');

    this.Valornormal = this.navParams.get('Valornormal');
    this.Descricaounidade = this.navParams.get('Descricaounidade');
    this.DescricaoCalculo = this.navParams.get('DescricaoCalculo');
    this.Observacao = this.navParams.get('Observacao');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
    this.Logado = this.navParams.get('Logado');
    this.Paciente = this.navParams.get('Paciente');
    this.Idade = this.navParams.get('Idade');
    this.Bsa = this.navParams.get('Bsa');
    this.Sistema = this.navParams.get('Sistema');

    //

    this.Valorminimo = this.navParams.get('Valorminimo');
    this.Valormaximo = this.navParams.get('Valormaximo');
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Altura = this.navParams.get('Altura');
    this.Peso = this.navParams.get('Peso');
    this.PesoIdeal = this.navParams.get('PesoIdeal');
    this.Usuario = parseInt(this.navParams.get('Usuario'));

    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }

  }



  ngOnInit() {
    this.limpaDados();

  }



  ionViewDidEnter() {
    this.testaAcessoSistema();
  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }

    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

    }
  }

  async calculaFormula() {
    let C0 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo0, 2));
    let C1 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo1, 2));
    let C2 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo2, 2));
    let C3 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo3, 2));
    let C4 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo4, 2));
    let C5 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo5, 2));
    let C6 = parseFloat(this.utilService.formateNumberNew(this.geralForm.value.Campo6, 2));
    let Sexo = this.geralForm.value.Sexo;
    let Range: string = " (FORA DA NORMALIIDADE)"
    if (this.Sigla == "AG") { // Anio Gap
      this.Valor = C0 - (C1 + C2);
      
    } else if (this.Sigla == "CaO2") {
      //CaO2 = Hb * 1.34 * (StO2/100)) + (0.0031 * PaO2)
      this.Valor = C0 * 1.24 * (C1 / 100) + (0.0031 * C2)
    } else if (this.Sigla == "PPC") {
      //PPC = PAM - PIC
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a PAM em mmHg", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C1 / 1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe a PIC em mmHg", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }
      this.Valor = C0 - C1
    } else if (this.Sigla == "FSC") {
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a DÉBITO CARDÍACO em litros", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }
      if (isNaN(C1 / 1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o PESO em Kg", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }
      let pesocerebral: number = (0.02 * C1) * 1000; // em gramas
      let debitoml: number = C0 * 1000 * 0.25; // 25% do DC em mL
      // dc -------------> peso
      // fsc ------------> 100
      let FSC = (debitoml * 100) / pesocerebral
      //ml/100g/min
      this.Valor = FSC;
    } else if (this.Sigla == "DpCO2") {
      // DpCO2 = PCO2v - PCO2a
      this.Valor = C0 - C1

    } else if (this.Sigla == "IC") {
      this.Valor = C0 / (C1 + C2);
    } else if (this.Sigla == "AVM") {
      // AVM =  220 / PHT ou equação da continuidade
      this.Valor = 220 / C0;
    } else if (this.Sigla == "AVA") {
      //AVA = Avsve * VTIvsve / VTIao
      this.Valor = C0 * C1 / C2;
    } else if (this.Sigla == "DP") {
      //AVA = Avsve * VTIvsve / VTIao
      this.Valor = C0 - C1;
    } else if (this.Sigla == "INSULINA") {
      this.Valor = (C1 * C2) / C0;
      if (C1 <= 70) {
        this.Valor = '0';
        Range = '\nDesligar a Insulina para TODOS os pacientes. Chamar intensivista. Administrar 40 mL ';
        Range = Range + 'de Glicose 50%. Glicemia em 15 minutos.';
        Range = Range + 'Pacientes Diabéticos: após a primeira glicemia maior que 90 mg/dL, reiniciar insulina ';
        Range = Range + 'IV, como 0,5 mL/h (0,5 unidades/h). Após a primeira glicemia maior que 150 mg/dL ',
        Range = Range + 'ajustar a insulina para 50% da dose que o paciente usava no momento da hipoglicemia.'
        Range = Range + 'Pacientes NÃO Diabéticos: religar a insulina apenas após a primeira glicemia maior '
        Range = Range + 'que 150 mg/dL, ajustando a vazão para para 50% da dose que o paciente usava no '
        Range = Range + 'momento da hipoglicemia. '

      }
      if (C1 > 70 && C1 <= 90) {
        Range = '\nPacientes Diabéti cos: ajustar infusão de insulina para 0,5 mL/h (0,5 unidades/h). Após ';
        Range = Range + 'a primeira glicemia maior que 90mg/dL, reiniciar insulina IV, com a dose anterior, ';
        Range = Range + 'diminuindo-se a infusão em 1 unidade/h (1,0mL/h).';
        Range = Range + 'Pacientes NÃO Diabéti cos: Desligar a insulina e após a primeira glicemia maior que ';
        Range = Range + '150mg/dL, reiniciar insulina IV, com a dose anterior, diminuindo-se a infusão em 1 ';
        Range = Range + 'unidade/h (1,0mL/h).'
      }

      if (C1 > 90 && C1 <= 150) {
        Range = '\nFaixa alvo. Sem mudanças.'
      }

      if (C1 > 150 && C1 <= 180) {
        Range = '\nSe a glicemia for menor que o teste anterior (esti ver caindo), não mude. Se a glicemia for igual ou maior que o teste anterior, aumente 1 mL/h (1 unidade/h).'
      }

      if (C1 > 180 && C1 <= 250) {
        Range = '\nSe a glicemia for menor que o teste anterior (esti ver caindo), não mude.  Se a glicemia for igual ou maior que o teste anterior, aumente 1,5 mL/h (1,5'
      }
      if (C1 > 250 && C1 <= 395) {
        Range = '\nAumente a infusão 2 mL/h (2 unidades/h). Se a glicemia for maior que 250 mg/dL por três testes consecuti vos, aumente 50% a taxa de infusão da insulina. Glicemia em 30 m'
      }

      if (C1 > 395) {
        Range = 'n\Chame o intensivista para prescrição de bolus IVv**. **Bolus de insulina IV sugerido: 50 % da t'
      }


    } else if (this.Sigla == "S1->S2") { 
      // CF*VF=CI*(VI-VP) + CP*VP
      // 10*500 = 5*(500 - VP) + 25*VP
      // 5000 = 2500 - 5VP + 25VP
      // 5000 -2500 = 20VP
      // 20VP = 2500
      // VP = 2500/ 20
      // VP = 125
      //Componente = 'Concentração Inicial(%),Volume Inicial(mL),Concentração Ponte(%),Volume Ponte(mL),Concentração Final(%),Volume final(mL)'
      let CI = C0;
      let VI = C1;
      let CP = C2;
      let VP = C3;
      let CF = C4;
      let VF = C5;

      if (C3 > 0) {
        this.utilService.showAlertCallback('Essse VOLUME DE PONTE é o que estamos calculando', ()=>{
          this.utilService.focusInput(this.ancoraCampo3);
        })
        return;
      }
      if (CI == 0 || CI == undefined || CI == null || isNaN(CI/1)) {
        CI = 0.0001;
      }

      if (CP == 0 || CP == undefined || CP == null || isNaN(CP/1)) {
        CP = 0.0001;
      }
      let a= CF*VF
      let b= CI*VI
      let c = a - b
      let d = CP - CI
      VP =  c/d
      VP = this.utilService.formateNumberNew(VP,0);
      this.geralForm.controls['Campo3'].setValue(VP);
      Range = '\nRetire ' + VP + ' mL da solução inical à ' + CI + ' % e acrescente ' + VP + ' mL da solução ' + CP + ' % para formar ' + VF + ' mL de solução a ' + CF + ' %'

    } else if (this.Sigla == "PVC") {
      //
      if (isNaN(C0 / 1) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a DIAMETRO VCI mínimo em mm", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C1 / 1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe a DIAMETRO VCI máximo em mm", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C2 / 1) || C2 == undefined || C2 == null) {
        this.utilService.showAlertCallback("Informe o percentual de colabamento (0, 50 ou 100%)", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }

      let Pvc: string = '';
      let Dvcii: number = C0;
      let Dvcie: number = C1;
      let Cvci: number = C2;
      let Pvci: number = 0;
      let Pvcf: number = 0;
      let Pvcm: number = 0;
      let Responsividade: string = "NÃO. Use outros métodos com ECO a beira leito, delta PP, elevação passoba das pernas."
      let Idvci = ((Dvcie - Dvcii) / Dvcii) * 100
      if (Idvci >= 13) {
        Responsividade = "É possível que o paciente seja responsivo a volume. Verique essa informação com outros métodos."
      }
      if (Dvcie < 1.5) {
        if (Cvci == 100) {
          Pvc = "0 a 5 mmHg";
          Pvci = 0;
          Pvcf = 5;
          Pvcm = 2.5;
        } else {
          this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido',
            () => {
              this.utilService.focusInput(this.ancoraCampo2)
            }
          );
          return;
        }
      }
      if (Dvcie >= 1.5 && Dvcie <= 2.5) {
        if (Cvci == 50) {
          Pvc = "6 a 10 mmHg";
          Pvci = 6;
          Pvcf = 10;
          Pvcm = 8;
        }
        else if (Cvci == -50) {
          Pvc = "11 a 15 mmHg";
          Pvci = 11;
          Pvcf = 15;
          Pvcm = 13;
        } else {
          this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido 2',
            () => {
              this.utilService.focusInput(this.ancoraCampo2)
            }
          );
          return;
        }
      }
      if (Dvcie > 2.5) {
        if (Cvci == -50) {
          Pvc = "16 a 20 mmHg";
          Pvci = 16;
          Pvcf = 20;
          Pvcm = 18;
        }
        else if (Cvci == 0) {
          Pvc = "> 20 mmHg";
          Pvci = 21;
          Pvcf = 29;
          Pvcm = 25;
        } else {
          this.utilService.showAlertCallback('O percentual de colabamento está inadequado para o valor inserido',
            () => {
              this.utilService.focusInput(this.ancoraCampo2)
            }
          );
          return;
        }
      }

      this.Mostrar = 0;
      this.Resultado =
        "01. A PVC para este caso poderá variar entre " + Pvci + " e " + Pvcf + " mmHg\n" +
        "02. O Índice de distensibilidade da VCI é de " + Idvci + " %\n" +
        "03. Avaliar a possibilidade de responsividade a volume: " + Responsividade

      this.Valor = Pvcm;
    } else if (this.Sigla == "IRVS") {
      //IRVS = 80 * (PAM - PVC )/ IC
      this.Valor = 80 * (C0 - C1) / C2;
    } else if (this.Sigla == "PmAP") {
      //PAPm= 0,61 * PAPs + 2
      this.Valor = 0.61 * C0 + 2;
    } else if (this.Sigla == "Ccre") {
      //Ccre= [(140 - idade) * peso ] / [creatinina*72] * K
      //Ccre= [(140 - idade) * peso ] / [creatinina*72] * K
      //K = 1 para homens ou 0,85 para mulheres
      let K = 1;
      if (Sexo == 2) {
        K = 0.85;
      }
      this.Valor = ((140 - C0) * C1) / (C2 * 72 * K);
    } else if (this.Sigla == "OsmPla") {
      //OsmP = 2 * Sódio + Glicose/18 + Ureia/6
      this.Valor = 2 * C0 + C1 / 18 + C2 / 6;
    } else if (this.Sigla == "Ca->Albumina") {
      //Cálcio corrigido = Cálcio medido +((4 - Albumina medida) * 0,8)

      this.Valor = C0 + (4 - C1) * 0.8;

    } else if (this.Sigla == "Na->Glicose") {
      //Na Corrigido = Sódio + 0,0016 * (glicemia - 100)
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe o SÓDIO medido", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C1 / 1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe a GLICEMIA", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }
      let a: number = C0;
      let b: number = 0.0016 * (C1 - 100);
      this.Valor = a + b;
     
    } else if (this.Sigla == "CHADS") {
      //CHADS2  =  soma de eventos de risco
    } else if (this.Sigla == "VCReal") {
      //VCReal=  volume corrente/peso predito
      //console.log("C0:" + C0);
      //console.log("C2:" + C2);
      this.Valor = C0 / C2;
    } else if (this.Sigla == "H²OL") {
      // Deficit de Água = ( (A*B*( (C/D)-1 ) ) ) -- HIPERNATREMIA
      // Deficit de água livre em função do sódio medido, desejado, peso do paciente e seu sexo
      // Deficit de Água = ((A * B * ((C / D) - 1)))
      // A = sexo(0, 6 ou 0, 5 ou 0, 45)
      // B = peso
      // C = sódio sérico medido
      // D = sódio sérico desejado
      // No homem jovem o fator é 0,6
      // Na mulher jovem ou homem idoso o fator é 0, 5
      // Na mulher idosa o deficit de água é 0, 45
      // O cálculo deve ser restrito a uma correção no máximo de 10 a 12 mEq / L em 24 horas.
      //Déficit de Água = ACT* x [(Naplasma / 140) - 1] ...
      if (Sexo == '' || Sexo == undefined || Sexo == null) {
        this.utilService.showAlertCallback("Escola o SEXO", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a IDADE", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C1 / 1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o PESO", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }


      if (isNaN(C2 / 1) || C2 == undefined || C2 == null || C2 == 0) {
        this.utilService.showAlertCallback("Informe o SÓDIO MEDIDO NO LABORATÓRIO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }

      if (isNaN(C3 / 1) || C3 == undefined || C3 == null || C3 == 0) {
        this.utilService.showAlertCallback("Informe o SÓDIO DESEJADO APÓS A CORREÇÃO", () => { this.utilService.focusInput(this.ancoraCampo3) });
        return;
      }

      if (C3 > C2) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MENOR QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;

      }

      if (C2 == C3) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MENOR QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;

      }
      if ((C2 - C3) > 10) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MENOR NO MAXIMO 10 mEq QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo3) });
        return;

      }
      let K = 0.6;
      if (Sexo == 1) {
        if (C0 >= 60) {
          K = 0.5;
        }
      }
      if (Sexo == 2) {
        K = 0.5;
        if (C0 >= 60) {
          K = 0.45;
        }
      }

      let ACT: number = C1 * K;
      let DeltaNa: number = (C2 / C3) - 1;
      this.Valor = ACT * DeltaNa;
      // Taxa infusão intravenosa = (1000 * Soro Na Alteração Por Hr * ((Frac Água * Peso) + 1)) / (IV Na + IV K - Soro Na)
      let Volume: number = this.utilService.formateNumberNew((this.Valor * 1000), 2);
      let Taxa: number = this.utilService.formateNumberNew((Volume / 24), 2);
      Taxa = this.utilService.formateNumberNew(Taxa, 2);
      this.Mostrar = 0;
      this.Resultado =
        "01. Correção de Hipernatremia de " + C2 + " para " + C3 + " mEq/L.\n" +
        "02. Deficit de água livre para este paciente foi de: " + Volume + " mL.\n" +
        "03. Infusão de NaCl 0,45% " + Taxa + " mL durante 24 horas"
    } else if (this.Sigla == "DHCO³") {
      // Deficit = fator * peso * (24 - HCO³)
      // Deficit de bicarbonado. Resultado em mEq. Lembrar que a solução de bicarbonato a 8,4% tem 1 mEq/mL. Então um paciente que tenha um deficit de bicarbonato de 300 mEg, deverá receber 100 mEq na primeira hora e os 200 mEq subsequentes em 23 horas.
      // No homem jovem o fator é 0, 6
      // Na mulher jovem ou homem idoso o fator é 0, 5
      // Na mulher idosa o fato é 0, 45
      let K = 0.6;
      if (Sexo == 1) {
        if (C0 >= 60) {
          K = 0.5;
        }
      }
      if (Sexo == 2) {
        K = 0.5;
        if (C0 >= 60) {
          K = 0.45;
        }
      }
      let a: number = K * C0;
      let b: number = (24 - C1);
      this.Valor = a * b;
      this.Valor = this.utilService.formateNumberNew(this.Valor, 2);
      let restante = this.Valor - 100;
      let taxa = this.utilService.formateNumberNew(restante / 23, 2);
      if (this.Valor > 0) {
        this.Mostrar = 0;

      }
      this.Resultado =
        "01. Para este paciente o deficit de bicarbonato de sódio foi de " + this.Valor + " " + this.Descricaounidade + ".\n" +
        "02. Observar o Sódio sérico antes de iniciar a infusão de NaHCO3.\n" +
        "03. Fazer 100 mEq que equivale a 100 mL NaHCO3 a 8,4% (1 mEq por mL) na primeira hora \n" +
        "04. O restante de " + restante + " mL fazer nas 23 horas seguintes.\n" +
        "05. Infusão em BOMBA em " + taxa + " mL/hora"
    } else if (this.Sigla == "DefNa+") {
      //Deficit = volemia * (140 - sódio medido)
      if (Sexo == '' || Sexo == undefined || Sexo == null) {
        this.utilService.showAlertCallback("Escola o SEXO", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a IDADE", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o PESO", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }

      if (isNaN(C2) || C2 == undefined || C2 == null || C2 == 0) {
        this.utilService.showAlertCallback("Informe o SÓDIO MEDIDO NO LABORATÓRIO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }

      if (isNaN(C3) || C3 == undefined || C3 == null || C3 == 0) {
        this.utilService.showAlertCallback("Informe o SÓDIO DESEJADO APÓS A CORREÇÃO", () => { this.utilService.focusInput(this.ancoraCampo3) });
        return;
      }

      if (C2 > C3) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MAIOR QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;

      }

      if (C2 == C3) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MAIOR QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;

      }
      if ((C3 - C2) > 10) {
        this.utilService.showAlertCallback("O SÓDIO DESEJADO TEM QUE SER MAIOR NO MAXIMO 10 mEq QUE O MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo3) });
        return;

      }
      let K = 0.6;
      if (Sexo == 1) {
        if (C0 >= 60) {
          K = 0.5;
        }
      }
      if (Sexo == 2) {
        K = 0.5;
        if (C0 >= 60) {
          K = 0.45;
        }
      }
      // K - FATOR RELACIONADO IDADE E SEXO
      // CO IDADE
      // C1 PESO
      // C3  SÓDI DESEJADO 
      // C2 SODIO MEDIDO
      let Fator: number = (K * C1);
      let Diferenca: number = C3 - C2;
      let Calculo: number = Fator * Diferenca;
      let Volume: any = (Calculo * 1000) / 510;
      Volume = this.utilService.formateNumberNew(Volume, 2)
      this.Valor = this.utilService.formateNumberNew(Calculo, 2);
      let mLHora: any = Volume / 24;
      mLHora = this.utilService.formateNumberNew(mLHora, 2);
      this.Mostrar = 0;
      this.Resultado =
        "01. Reposição de " + Calculo + " " + this.Descricaounidade + "de Na " + String.fromCharCode(10) +
        "02. Solução salina a 3% (890 mL de SF 0,9% + 110 mL de NaCl 20%), total de 1000 mL" + String.fromCharCode(10) +
        "03. Volume a ser infudido " + Volume + " mL" + String.fromCharCode(10) +
        "04. Bomba de infusão em " + mLHora + " mL/hora" + String.fromCharCode(10) +
        "05. Tempo: 24 horas \n" +
        "06. Cuidado com edema cerebral e desmielinolise."

    } else if (this.Sigla == "Def2Na+") {
      //Acréscimo de Na+(Fórmula Adrogue-Madias)
      //Deficit = (513 - Sódio corrigido) /  (Peso x fator) +1
      // No homem jovem o deficit de água é 0,6 x peso x (sódio sérico medido/140 -1)
      // Na mulher jovem ou homem idoso o deficit de água é 0,5 x peso x (sódio sérico medido/140 -1)
      // Na mulher idosa o deficit de água é 0,45 x peso x (sódio sérico medido/140 -1)
      // O cálculo deve ser restrito a uma correção no máximo de 10 a 12 mEq/L em 24 horas.
      // Cloreto de Sódio:
      // NaCl 5% 855 mEq/L
      // NaCl 3% 513 mEq/L
      // NaCl 0,9 % 154 mEq/L
      // Lactate Ringer 130 mEq/L
      // NaCl 0,45% 77 mEq/L
      // NaCl 0,2% 34 mEq/L 
      // Glicose a 5% 0 mEq/L
      if (Sexo == '' || Sexo == undefined || Sexo == null) {
        this.utilService.showAlertCallback("Escola o SEXO", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe o PESO", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }

      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o IDADE do paciente", () => {
          this.utilService.focusInput(this.ancoraCampo1);

        });
        return;
      }

      if (isNaN(C2) || C2 == undefined || C2 == null || C2 == 0) {
        this.utilService.showAlertCallback("Informe a SÓDIO SÉRICO MEDIDO", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }

      let Fator: number = 0;
      let Idade: number = C1
      if (Sexo == 1) {
        if (Idade < 60) {
          Fator = 0.6
        }

        if (Idade > 59) {
          Fator = 0.5
        }

      }

      if (Sexo == 2) {
        if (Idade < 60) {
          Fator = 0.5
        }

        if (Idade > 59) {
          Fator = 0.45
        }

      }
      let a: number = 513 - C2;
      let b: number = C0 * Fator; //ACT
      b = b + 1; // Ajuste para 1 litro
      this.Valor = a / b; // numero de mEq elevará o Na plasmatico do paciente especificado
      this.Valor = parseFloat(this.utilService.formateNumberNew(this.Valor, 2));
      let NaPrevisto: number = C2 + this.Valor;
      NaPrevisto = this.utilService.formateNumberNew(NaPrevisto, 2);
      // Taxa infusão intravenosa = (1000 * Soro Na Alteração Por Hr * ((Frac Água * Peso) + 1)) / (IV Na + IV K - Soro Na)
      let Taxa: number = (1000 * 0.5 * b);
      Taxa = Taxa / a;
      Taxa = this.utilService.formateNumberNew(Taxa, 2);
      this.Mostrar = 0;
      this.Resultado = "01. Uma solução de 1000 mL de NaCl 3% aumentara em " +
        this.Valor + " " + this.Descricaounidade + '\n02. O sódio plasmático do paciente passará a ser de ' +
        NaPrevisto + " " + this.Descricaounidade + " com a infusão de 1000 mL de SF 3%.\n03. Cuidado com a taxa de infusão que não deve exceder 0.5 mEq/L/Hora e não ultrapassar 10 mEq/L/24 horas.\n04. A taxa de infusão para esse caso será de " + Taxa + " mL/Hora considerando 0.5 mEq/Hora."
    } else if (this.Sigla == "DC2") {
      //DC=VS x FC VS = área da VSVE x VTIVSVE FC = frequência cardíaca

    } else if (this.Sigla == "DC") {
      //DC = VS*FC
      this.Valor = C0 * C1 / 1000;
    } else if (this.Sigla == "DO2") {
      // DO2 = CaO2 x IC x 10
      this.Valor = C0 * C1 * 10
    } else if (this.Sigla == "P/F") {
      // P/F = PaO2/FiO2
      this.Valor = C0 / (C1/100)
    } else if (this.Sigla == "Diurese") {
      //Diurese indexada= Diurese/(Peso x Horas)
      if (Sexo == '' || Sexo == undefined || Sexo == null) {
        this.utilService.showAlertCallback("Escola o SEXO", () => { this.utilService.focusInput(this.ancoraCampo0) });
        return;
      }

      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe o PESO", () => { this.utilService.focusInput(this.ancoraCampo1) });
        return;
      }

      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o IDADE do paciente", () => {
          this.utilService.focusInput(this.ancoraCampo1);

        });
        return;
      }

      if (isNaN(C2) || C2 == undefined || C2 == null || C2 == 0) {
        this.utilService.showAlertCallback("Informe a quantidade de HORAS de Diurese", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }

      if (isNaN(C3) || C3 == undefined || 3 == null || C3 == 0) {
        this.utilService.showAlertCallback("Informe a DIURESE em mL no perído informado", () => { this.utilService.focusInput(this.ancoraCampo2) });
        return;
      }
      this.Valor = C3 / (C0 * C2)
    } else if (this.Sigla == "Volemia") {
      //Para homens:
      // Volemia = 0.3669 x altura³ + 0.03219  x  Peso  + 0.6041 
      // Para mulheres:
      // Volemia =  0.3561 x altura³ + 0.03308 x Peso + 0.1833
      // altura: metros
      // peso: kg
      let Altura: number = C0 / 100;
      let Peso: number = C1;
      Altura = 0.3561 * Math.pow(Altura, 3);
      Peso = 0.03308 * (Peso + 0.1833);
      this.Valor = Altura + Peso;
    } else if (this.Sigla == "Gotas") {
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe o volume a ser infundido", () => {
          this.utilService.focusInput(this.ancoraCampo0)
        });
        return;
      }
      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o a vazão em mL", () => {
          this.utilService.focusInput(this.ancoraCampo1)
        });
        return;
      }
      let tempo: number = (C0 / C1) * 60;
      let gotas: any = (C0 * 20) / tempo;
      this.Valor = this.utilService.formateNumberNew(gotas, 2);
    } else if (this.Sigla == "PAM") {
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a Pressão arterial sistólica", () => {
          this.utilService.focusInput(this.ancoraCampo0)
        });
        return;
      }
      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o a Pressão arterial diastólica", () => {
          this.utilService.focusInput(this.ancoraCampo1)
        });
        return;
      }
      let PAM: number = (C0 + (2 * C1)) / 3;
      //console.log("PAM "+ PAM)
      this.Valor = this.utilService.formateNumberNew(PAM, 2, 1);

    } else if (this.Sigla == "qSOFA") {
      if (isNaN(C0) || C0 == undefined || C0 == null || C0 == 0) {
        this.utilService.showAlertCallback("Informe a PRESSÃO ARTERIAL SISTÓLICA", () => {
          this.utilService.focusInput(this.ancoraCampo1)
        });
        return;
      }

      if (isNaN(C1) || C1 == undefined || C1 == null || C1 == 0) {
        this.utilService.showAlertCallback("Informe o FREQUÊNCIA RESPIRATÓRIA do paciente", () => {
          this.utilService.focusInput(this.ancoraCampo1);

        });
        return;
      }

      if (isNaN(C2) || C2 == undefined || C2 == null || C2 < 4) {
        this.utilService.showAlertCallback("Informe a ECG do Panciente", () => {
          this.utilService.focusInput(this.ancoraCampo2)
        });
        return;
      }

      let Pas: number = C0;
      let Fr: number = C1;
      let Glasgow: number = C2;
      let Pontos: number = 0;
      let Risco = " BAIXO RISCO"
      if (Pas <= 100) {
        Pontos++
        Risco = "BAIXO RISCO. Continue a monitorização."

      }
      if (Fr >= 22) {
        Pontos++
        Risco = "ALTO RISCO. Faça o SOFA completo e dose o Lactato."
      }

      if (Glasgow < 15) {
        Pontos++
        Risco = "ALTO RISCO. Faça o SOFA completo e dose o Lactato. Adote as medidas iniciais para SEPSE caso hipotensão e/ou lactato > 4 mMol/L"
      }

      this.Valor = Pontos;
      this.Mostrar = 0;
      this.Resultado =
        "01. qSOFA " + this.Valor + " pontos.\n" +
        '02. ' + Risco + '\n' +
        '03. No caso de COVID 19 busque orientações complementares.\n' +
        '04. Marcadores com Lactato, SvO2, Delta Pv-aCO2, Sa02, Débito Cardíaco, PVC, CaO2, IRVS e Hb podem ajudar na sua conduta.\n' +
        '05. A Sepse é mais comum com \n' +
        '-> Lactato > 2 mmoL/L \n' +
        '-> SvO2 < 70% \n' +
        '-> Delta Pv-aCO2 <=6 mmHg \n' +
        '-> DC maior 4L/min \n' +
        '-> SatO2 menor que 94% \n' +
        '-> IRVS menor que 15OO dina/s/cm(5).m² \n'
    }

    if (isNaN(this.Valor / 1) || this.Valor == Infinity || this.Valor == undefined) {
      this.utilService.showAlertCallback("Cálculo inválido. Comunique ao administrador do sistema", null)
      return;
    }


    if (this.Valor >= this.Valorminimo && this.Valor <= this.Valormaximo) {
      Range = " (NORMAL)"
    }
    this.Valor = this.utilService.formateNumberNew(this.Valor, 2);
    if (this.Mostrar == 1) {
      this.Resultado = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    }
    if (this.Internacao > 0 && this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.geralForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }


  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    this.geralForm.controls['Datalancamento'].setValue(dtHoje);
    this.Datalancamento = dtHoje;
    // this.utilService.focusInput(this.ancoraCampo0)
  }

  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;
      if (this.lancamentosCollection.length == 0) {
        this.utilService.showToastCallBack("Ainda não existem dados lançados para o cálculo " + this.Nome, 2000, null)
      }


    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível acessar os lançamento", 2000, null)
    });
  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível acessar o ítem do lançamento", 2000, null)
    });
  }

  async limpaDados() {

    this.selecionarDataHoje();
    this.Valor = 0;
    this.Id = -1;
    for (let i = 0; i < this.Vetor.length; i++) {
      this.geralForm.controls['Campo' + i.toString()].setValue('');

    }
    this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, () => {
      this.utilService.focusInput(this.ancoraCampo0);
    });
    this.PreparaDados();

  }

  async PreparaDados() {
    this.Vetor = this.Componente.split(','); // relacao de campos
    if (this.Componente.indexOf('Sexo') > -1) {
      this.Sexoexistente = 1;
      this.Vetor.splice(0, 1); // retirar o sexo dos campos
    }


    for (let i = 0; i < this.Vetor.length; i++) {
      if (this.Vetor[i].indexOf('Idade') > -1) {
        this.geralForm.controls["Campo" + i.toString()].setValue(this.Idade);
      }
      if (this.Vetor[i].indexOf('Peso') > -1) {
        this.Peso = this.utilService.formateNumberNew(this.Peso, 3);
        this.geralForm.controls["Campo" + i.toString()].setValue(this.Peso);
      }

      if (this.Vetor[i].indexOf('Peso Ideal Predito') > -1) {
        this.PesoIdeal = this.utilService.formateNumberNew(this.PesoIdeal, 1);
        this.geralForm.controls["Campo" + i.toString()].setValue(this.PesoIdeal);
      }
      if (this.Vetor[i].indexOf('ASC') > -1) {
        this.Bsa = this.utilService.formateNumberNew(this.Bsa, 3);
        this.geralForm.controls["Campo" + i.toString()].setValue(this.Bsa);
      }

      if (this.Vetor[i].indexOf('Altura') > -1) {
        this.Altura = this.utilService.formateNumberNew(this.Altura, 3);
        this.geralForm.controls["Campo" + i.toString()].setValue(this.Altura);
      }

      this.geralForm.controls['Id'].setValue(this.Id);
      this.geralForm.controls['Sexo'].setValue(this.Sexo);
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.Botao = "CADASTRAR"
      } else {
        this.Botao = "CALCULAR"
      }
    }
  }
  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async mostrarDados(response: any) {
    this.Botao = "ALTERAR";
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.substr(0, Relacao.length - 1);
    Relacao = Relacao.split('*')
    for (let i = 0; i < Relacao.length; i++) {
      this.geralForm.controls['Campo' + i.toString()].setValue(Relacao[i]);
    }
    let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.geralForm.controls['Datalancamento'].setValue(Datalancamento);
    this.Resultado = response[0].Comentario;
    this.geralForm.controls['Resultado'].setValue(this.Resultado);


  }
  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];
    let Valor: any = [];
    for (let i = 0; i < this.Vetor.length; i++) {
      Valor = eval('this.geralForm.value.Campo' + i.toString())
      Relacao = Relacao + Valor + '*'
    }

    let Datalancamento: any = this.geralForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.geralForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.geralForm.value.Resultado


    }

    if (this.geralForm.value.Id == null || this.geralForm.value.Id == undefined ||
      this.geralForm.value.Id == '-1' || this.geralForm.value.Id == -1 || this.geralForm.value.Id == '') {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showToastCallBack(this.Nome + " INCLUIDO com sucesso", 2000, null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.geralForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível INCLUIR o " + this.Nome, 2000, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showToastCallBack(this.Nome + " ALTERADO com sucesso", 2000, null);
        this.selecionarCalculolancamento();
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível ATUALIZAR o " + this.Nome, 2000, null);

      });
    }

  }
}
