import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';
import { JSDocCommentStmt } from '@angular/compiler';


@Component({
  selector: 'app-saps3',
  templateUrl: './saps3.component.html',
  styleUrls: ['./saps3.component.scss'],
})
export class Saps3Component implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraIdade', null) ancoraIdade: ElementRef;
  @ViewChild('ancoraGlasgow', null) ancoraGlasgow: ElementRef;
  @ViewChild('ancoraFc', null) ancoraFc: ElementRef;
  @ViewChild('ancoraPs', null) ancoraPs: ElementRef;
  @ViewChild('ancoraTemperatura', null) ancoraTemperatura: ElementRef;
  @ViewChild('ancoraLeucocitos', null) ancoraLeucocitos: ElementRef;
  @ViewChild('ancoraPlaqueta', null) ancoraPlaqueta: ElementRef;
  @ViewChild('ancoraPh', null) ancoraPh: ElementRef;
  @ViewChild('ancoraCreatinina', null) ancoraCreatinina: ElementRef;
  @ViewChild('ancoraBilirrubina', null) ancoraBilirrubina: ElementRef;
  saps3Form: FormGroup;

  Id: number = -1;
  Comentario: string = "";
  lancamentosCollection: any = [];
  // SAPS III
  Idade: number = 0;
  Comorbidade: number = 0;
  sComorbidade: any;
  Internacaoprevio: number = 0;
  Procedencia: number = 0;
  Droga: number = 0;
  Admissao: number = 0;
  TipoInternacao: number = 0;
  Cirurgia: number = 0;
  Uti: number = 0;
  Neurologico: number = 0;
  Cardiaco: number = 0;
  Abdomen: number = 0;
  Infeccao: number = 0;
  Glasgow: number = 0;
  Fc: number = 0;
  Ps: number = 0;
  O2: number = 0;
  Temperatura: number = 0;
  Leucocito: number = 0;
  Plaqueta: number = 0;
  Ph: number = 0;
  Creatinina: number = 0;
  Bilirrubina: number = 0;
  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number = 0;
  Calculo: number;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Internacaoprevios: string = "";
  itensColection: any = [];
  NomePaciente: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Altura: any;
  Peso: any;
  Bsa: any;
  Sistema: number = 0;
  IdadeAtual: number = 0;
  Mortalidade: number = 0;
  aComorbidade: any = [];
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController: LoadingController
  ) {
    this.saps3Form = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      // SAPS III
      Idade: ['', ''],
      Comorbidade: ['', ''],
      Internacaoprevio: ['', ''],
      Procedencia: ['', ''],
      Droga: ['', ''],
      Admissao: ['', ''],
      TipoInternacao: ['', ''],
      Cirurgia: ['', ''],
      Uti: ['', ''],
      Neurologico: ['', ''],
      Cardiaco: ['', ''],
      Abdomen: ['', ''],
      Infeccao: ['', ''],
      Glasgow: ['', ''],
      Fc: ['', ''],
      Ps: ['', ''],
      O2: ['', ''],
      Temperatura: ['', ''],
      Leucocito: ['', ''],
      Plaqueta: ['', ''],
      Ph: ['', ''],
      Creatinina: ['', ''],
      Bilirrubina: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
   


   

    this.aComorbidade = [
      { Nome: 'Outros/Nenhuma', Valor: '0A' },
      { Nome: 'Cancer em Terapia', Valor: '3A' },
      { Nome: 'Chron', Valor: '6A' },
      { Nome: 'ICC NYHA IV', Valor: '6B' },
      { Nome: 'Neoplasia hematológica', Valor: '7A' },
      { Nome: 'Cirrose', Valor: '8A' },
      { Nome: 'AIDS', Valor: '8B' },
      { Nome: 'Cancer com Metástase', Valor: '11A' },


    ]

    this.NomePaciente = this.navParams.get('NomePaciente');
    if (this.NomePaciente != '' && this.NomePaciente != undefined && this.NomePaciente != null) {
      this.utilService.Descriptografar(this.NomePaciente).then((n:string)=>{
        this.NomePaciente = n.toUpperCase();
      })
    }
    

  }
  ngOnInit() {
  }

  exibirPaciente(paciente:number){
    this.Paciente = paciente

  }

  exibirInternacao(internacao:number){
    this.Internacao = internacao

  }

  exibirSexo(sexo:number){
    this.Sexo = sexo

  }

  exibirIdade(idade:number){
    this.Idade = idade
    if (this.Idade < 40) {
      this.IdadeAtual = 0;
    } else if (this.Idade >= 40 && this.Idade <= 59) {
      this.IdadeAtual = 5;
    } else if (this.Idade >= 60 && this.Idade <= 69) {
      this.IdadeAtual = 9;
    } else if (this.Idade >= 70 && this.Idade <= 74) {
      this.IdadeAtual = 13;
    } else if (this.Idade >= 75 && this.Idade <= 79) {
      this.IdadeAtual = 15;
    } else if (this.Idade > 79) {
      this.IdadeAtual = 18;

    }

  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico();
    this.testaAcessoSistema();
    this.selecionarCalculolancamento();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico() {
    let loading = await this.loadingController.create(
      {
        message: "Selecionando od dados...",
        mode: 'ios'
      }
    );
    loading.present();
    this.Calculo = 35;
    this.checklistService.selecionarCalculoUnico(this.Calculo)
      .then((response: any) => {
        this.Sigla = response[0].Sigla;
        this.Nome = response[0].Nome
        this.Componente = response[0].Componente
        this.Valornormal = response[0].Valornormal;
        this.Descricaounidade = response[0].Descricaounidade
        this.Descricaocalculo = response[0].Descricaocalculo
        this.Observacao = response[0].Observacao
        this.Valorminimo = response[0].Valorminimo
        this.Valormaximo = response[0].Valormaximo


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a calculadora')
      }).finally(() => {
        loading.dismiss();
      });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, 35).then((response) => {
      this.lancamentosCollection = response;
      if (this.lancamentosCollection.length == 0) {
        this.utilService.showToastCallBack("Ainda não existem dados lançados para o cálculo " + this.Nome, 2000, null)
      }

    }).catch((response) => {
      // this.utilService.showToastCallBack("Não foi possível acessar os lançamento", 2000, null)
    });
  }





  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularSaps3() {
    let Idade: number = parseInt(this.saps3Form.value.Idade);
    if (Idade == null || Idade == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de IDADE", null)
      return;

    }
    this.Idade = Idade;

    let Comorbidade: any = this.saps3Form.value.Comorbidade;
    if (Comorbidade.length == 0 || Comorbidade == null || Comorbidade == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de COMORBIDADE", null)
      return;

    }

    if (isNaN(Comorbidade/1) || Comorbidade == 'NaN' || Comorbidade == '0A') {
      Comorbidade =0;
    }

    this.sComorbidade = Comorbidade;

    this.Comorbidade = 0;
    let ValorComorbidade: string = '';
    for (let i = 0; i < Comorbidade.length; i++) {
      ValorComorbidade = Comorbidade[i].toString();
      ValorComorbidade = ValorComorbidade.replace('A', '');
      ValorComorbidade = ValorComorbidade.replace('B', '');
      this.Comorbidade = this.Comorbidade + parseInt(ValorComorbidade);
    }


    let Internacaoprevio: number = parseInt(this.saps3Form.value.Internacaoprevio);
    if (Internacaoprevio == null || Internacaoprevio == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de INTERNAMENTO PRÉVIO", null)
      return;
    }
    this.Internacaoprevio = Internacaoprevio;

    let Procedencia: number = parseInt(this.saps3Form.value.Procedencia);
    if (Procedencia == null || Procedencia == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de PROCEDÊNCIA", null)
      return;

    }
    this.Procedencia = Procedencia;

    let Droga: number = parseInt(this.saps3Form.value.Droga);
    if (Droga == null || Droga == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de DROGA", null)
      return;

    }
    this.Droga = Droga;

    let Admissao: number = parseInt(this.saps3Form.value.Admissao);
    if (Admissao == null || Admissao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de ADMISSÃO", null)
      return;

    }
    this.Admissao = Admissao;

    let TipoInternacao: number = parseInt(this.saps3Form.value.TipoInternacao);
    if (TipoInternacao == null || TipoInternacao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de TIPO DE INTERNAÇÃO", null)
      return;

    }
    this.TipoInternacao = TipoInternacao;

    let Cirurgia: number = parseInt(this.saps3Form.value.Cirurgia);
    if (Cirurgia == null || Cirurgia == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CIRURGIA", null)
      return;

    }
    this.Cirurgia = Cirurgia;

    let Uti: number = parseInt(this.saps3Form.value.Uti);
    if (Uti == null || Uti == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de UTI", null)
      return;

    }
    this.Uti = Uti;

    let Neurologico: number = parseInt(this.saps3Form.value.Neurologico);
    if (Neurologico == null || Neurologico == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de NEUROLÓGICO", null)
      return;

    }
    this.Neurologico = Neurologico;


    let Cardiaco: number = parseInt(this.saps3Form.value.Cardiaco);
    if (Cardiaco == null || Cardiaco == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CARDÍACO", null)
      return;

    }
    this.Cardiaco = Cardiaco;

    let Abdomen: number = parseInt(this.saps3Form.value.Abdomen);
    if (Abdomen == null || Abdomen == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de ABDOMEN", null)
      return;

    }
    this.Abdomen = Abdomen;

    let Infeccao: number = parseInt(this.saps3Form.value.Infeccao);
    if (Infeccao == null || Infeccao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de INFECÇÃO", null)
      return;

    }
    this.Infeccao = Infeccao;

    let Glasgow: number = parseInt(this.saps3Form.value.Glasgow);
    if (Glasgow == null || Glasgow == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de GLASGOW", null)
      return;

    }
    this.Glasgow = Glasgow;

    let Fc: number = parseInt(this.saps3Form.value.Fc);
    if (Fc == null || Fc == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de FREQUÊNCIA CARDÍACO", null)
      return;

    }
    this.Fc = Fc;

    let Ps: number = parseInt(this.saps3Form.value.Ps);
    if (Ps == null || Ps == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de PRESSÃO SISTÓLICA", null)
      return;

    }
    this.Ps = Ps;

    let O2: number = parseInt(this.saps3Form.value.O2);
    if (O2 == null || O2 == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de O2", null)
      return;

    }
    this.O2 = O2;

    let Temperatura: number = parseInt(this.saps3Form.value.Temperatura);
    if (Temperatura == null || Temperatura == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de TEMPERATURA", null)
      return;

    }
    this.Temperatura = Temperatura;

    let Leucocito: number = parseInt(this.saps3Form.value.Leucocito);
    if (Leucocito == null || Leucocito == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de LEUCÓCITOS", null)
      return;

    }
    this.Leucocito = Leucocito;

    let Plaqueta: number = parseInt(this.saps3Form.value.Plaqueta);
    if (Plaqueta == null || Plaqueta == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de PLAQUETAS", null)
      return;

    }
    this.Plaqueta = Plaqueta;

    let Ph: number = parseInt(this.saps3Form.value.Ph);
    if (Ph == null || Ph == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de Ph", null)
      return;

    }
    this.Ph = Ph;

    let Creatinina: number = parseInt(this.saps3Form.value.Creatinina);
    if (Creatinina == null || Creatinina == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CREATINA", null)
      return;

    }
    this.Creatinina = Creatinina;

    let Bilirrubina: number = parseInt(this.saps3Form.value.Bilirrubina);
    if (Bilirrubina == null || Bilirrubina == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de BILIRRUBINA", null)
      return;

    }
    this.Bilirrubina = Bilirrubina;


    this.Valor = this.Idade +
      this.Comorbidade +
      this.Internacaoprevio +
      this.Procedencia +
      this.Droga +
      this.Admissao +
      this.TipoInternacao +
      this.Cirurgia +
      this.Uti +
      this.Neurologico +
      this.Cardiaco +
      this.Abdomen +
      this.Infeccao +
      this.Glasgow +
      this.Fc +
      this.Ps +
      this.O2 +
      this.Temperatura +
      this.Leucocito +
      this.Plaqueta +
      this.Ph +
      this.Creatinina +
      this.Bilirrubina;

    // //console.log("Idade: " + this.Idade)
    // //console.log("Comorbidade: " + this.Comorbidade)
    // //console.log("Internacaoprevio: " + this.Internacaoprevio)

    // //console.log("Procedencia: " + this.Procedencia)
    // //console.log("Droga: " + this.Droga)
    // //console.log("Admissao: " + this.Admissao)

    // //console.log("TipoInternacao: " + this.TipoInternacao)
    // //console.log("Cirurgia: " + this.Cirurgia)
    // //console.log("Uti: " + this.Uti)

    // //console.log("Neurologico: " + this.Neurologico)
    // //console.log("Cardiaco: " + this.Cardiaco)
    // //console.log("Abdomen: " + this.Abdomen)

    // //console.log("Infeccao: " + this.Infeccao)
    // //console.log("Glasgow: " + this.Glasgow)
    // //console.log("Fc: " + this.Fc)

    // //console.log("Ps: " + this.Ps)
    // //console.log("O2: " + this.O2)
    // //console.log("Temp: " + this.Temperatura)

    // //console.log("Leucocito: " + this.Leucocito)
    // //console.log("Plaqueta: " + this.Plaqueta)
    // //console.log("Ph: " + this.Ph)

    // //console.log("Creatinina: " + this.Creatinina)
    // //console.log("Bilirrubina: " + this.Bilirrubina)

    // //console.log("Valor: " + this.Valor)
    //http://www2.uesb.br/computacao/wp-content/uploads/2014/09/UMA-FERRAMENTA-PARA-AN%C3%81LISE-DA-QUALIDADE-DE-UNIDADES-DE-TERAPIA-INTENSIVA.pdf
    let logit = -64.5990 + ((Math.log(this.Valor + 71.0599)) * 13.2322)
    this.Mortalidade = Math.exp(logit) / (1 + Math.exp(logit)) * 100
    this.Mortalidade = this.utilService.formateNumber(this.Mortalidade, 2)
    this.Valor = this.utilService.formateNumber(this.Valor, 2);
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + '\n' +
      "Mortalidade: " + this.Mortalidade + " %"
      // //console.log("Paciente:" +  this.Paciente )
      // //console.log("Internacao:" +  this.Internacao )
      // //console.log("Valor:" +  this.Valor )

    if (this.Paciente > 0 && this.Internacao > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.saps3Form.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }


    let Relacao: any = [];
    Relacao =
      this.Uti + '*' +
      this.Idade + '*' +
      this.sComorbidade + '*' +
      this.Internacaoprevio + '*' +
      this.Procedencia + '*' +
      this.Droga + '*' +
      this.Admissao + '*' +
      this.TipoInternacao + '*' +
      this.Cirurgia + '*' +
      this.Neurologico + '*' +
      this.Cardiaco + '*' +
      this.Abdomen + '*' +
      this.Infeccao + '*' +
      this.Glasgow + '*' +
      this.Fc + '*' +
      this.Ps + '*' +
      this.O2 + '*' +
      this.Temperatura + '*' +
      this.Leucocito + '*' +
      this.Plaqueta + '*' +
      this.Ph + '*' +
      this.Creatinina + '*' +
      this.Bilirrubina;
    let Datalancamento: any = this.saps3Form.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);


    let formCalculo = {
      Id: this.saps3Form.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.saps3Form.value.Comentario

    }

 
    let Comorbidade: any = "[" + this.saps3Form.value.Comorbidade + "]";

    let formSaps = {
      Paciente: this.Paciente,
      Internacao: this.Internacao,
      Idade: this.Idade,
      Comorbidades: Comorbidade,
      Internacaoprevio: this.Internacaoprevio,
      Procedencia: this.Procedencia,
      Droga: this.Droga,
      Admissao: this.Admissao,
      TipoInternacao: this.TipoInternacao,
      Cirurgia: this.Cirurgia,
      Uti: this.Uti,
      Neurologico: this.Neurologico,
      Cardiaco: this.Cardiaco,
      Abdomen: this.Abdomen,
      Infeccao: this.Infeccao,
      Glasgow: this.Glasgow,
      Fc: this.Fc,
      Ps: this.Ps,
      O2: this.O2,
      Temperatura: this.Temperatura,
      Leucocitos: this.Leucocito,
      Plaquetas: this.Plaqueta,
      pH: this.Ph,
      Creatinina: this.Creatinina,
      Bilirrubina: this.Bilirrubina,
      Pontos: this.Valor,
      Mortalidade: this.Mortalidade,
      Usuario: this.Usuario
    }


    if (this.saps3Form.value.Id == null || this.saps3Form.value.Id == undefined ||
      this.saps3Form.value.Id == '-1' || this.saps3Form.value.Id == -1 || this.saps3Form.value.Id == '') {
      this.checklistService.adicionarCalculolancamento(formCalculo).then((response) => {
        this.adicionarSaps3(formSaps)
        this.selecionarCalculolancamento();
        setTimeout(() => {
          formSaps
          this.Id = response[0].Id;
          this.saps3Form.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(formCalculo).then((response: any) => {
        this.atualizarSaps3(formSaps)

        this.Id = response[0].Id;
        this.selecionarCalculolancamentoUnico(this.Id);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

      });
    }

  }

  async adicionarSaps3(formSaps3: any) {
    this.pacienteService.adicionarSaps3(formSaps3).then((response: any) => {
      this.utilService.showAlertCallback("SAPS3 ADICIONADO com sucesso");
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível ADICIONAR o SAPS3");

    });
  }

  async atualizarSaps3(formSaps3: any) {
    this.pacienteService.atualizarSaps3(formSaps3).then((response: any) => {
      this.utilService.showAlertCallback("SAPS3 ATUALIZADO com sucesso");
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível ATUALIZAR o SAPS3");

    });
  }
  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível acessar o ítem do lançamento", 2000, null)
    });
  }

  async limpaDados() {
    let dtHoje = this.utilService.dataHoje();
    // em caso de cadastro novo
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.saps3Form.controls['Datalancamento'].setValue(Datalancamento);
    this.saps3Form.controls['Idade'].setValue(this.IdadeAtual.toString());
    this.saps3Form.controls['Uti'].setValue('0');
    this.saps3Form.controls['Comorbidade'].setValue('0A');
    this.saps3Form.controls['Internacaoprevio'].setValue('0');
    this.saps3Form.controls['Procedencia'].setValue('5');
    this.saps3Form.controls['Droga'].setValue('3');
    this.saps3Form.controls['Admissao'].setValue('3');
    this.saps3Form.controls['TipoInternacao'].setValue('5');
    this.saps3Form.controls['Cirurgia'].setValue('0');
    this.saps3Form.controls['Neurologico'].setValue('0');
    this.saps3Form.controls['Cardiaco'].setValue('0');
    this.saps3Form.controls['Abdomen'].setValue('0');
    this.saps3Form.controls['Infeccao'].setValue('0');
    this.saps3Form.controls['Glasgow'].setValue('0');
    this.saps3Form.controls['Fc'].setValue('0');
    this.saps3Form.controls['Ps'].setValue('0');
    this.saps3Form.controls['O2'].setValue('0');
    this.saps3Form.controls['Temperatura'].setValue('0');
    this.saps3Form.controls['Leucocito'].setValue('0');
    this.saps3Form.controls['Plaqueta'].setValue('0');
    this.saps3Form.controls['Ph'].setValue('0');
    this.saps3Form.controls['Creatinina'].setValue('0');
    this.saps3Form.controls['Bilirrubina'].setValue('0');
    this.Botao = "CALCULAR"
    this.Id = -1;
    this.Valor = 0;
    this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, () => {
    });
    this.saps3Form.controls['Id'].setValue(this.Id);
    if ( this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }


  }

  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }

    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    this.sComorbidade = [];
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Uti = Relacao[0];
    this.Idade = Relacao[1];
    this.sComorbidade = Relacao[2].split(',');
    this.Internacaoprevio = Relacao[3];
    this.Procedencia = Relacao[4];
    this.Droga = Relacao[5];
    this.Admissao = Relacao[6];
    this.TipoInternacao = Relacao[7];
    this.Cirurgia = Relacao[8];
    this.Neurologico = Relacao[9];
    this.Cardiaco = Relacao[10];
    this.Abdomen = Relacao[11];
    this.Infeccao = Relacao[12];
    this.Glasgow = Relacao[13];
    this.Fc = Relacao[14];
    this.Ps = Relacao[15];
    this.O2 = Relacao[16];
    this.Temperatura = Relacao[17];
    this.Leucocito = Relacao[18];
    this.Plaqueta = Relacao[19];
    this.Ph = Relacao[20];
    this.Creatinina = Relacao[21];
    this.Bilirrubina = Relacao[22];

    this.saps3Form.controls['Datalancamento'].setValue(Datalancamento);

    this.saps3Form.controls['Uti'].setValue(this.Uti);
    this.saps3Form.controls['Idade'].setValue(this.Idade);
    this.saps3Form.controls['Comorbidade'].setValue(this.sComorbidade);
    this.saps3Form.controls['Internacaoprevio'].setValue(this.Internacaoprevio);
    this.saps3Form.controls['Procedencia'].setValue(this.Procedencia);
    this.saps3Form.controls['Droga'].setValue(this.Droga);
    this.saps3Form.controls['Admissao'].setValue(this.Admissao);
    this.saps3Form.controls['TipoInternacao'].setValue(this.TipoInternacao);
    this.saps3Form.controls['Cirurgia'].setValue(this.Cirurgia);
    this.saps3Form.controls['Neurologico'].setValue(this.Neurologico);
    this.saps3Form.controls['Cardiaco'].setValue(this.Cardiaco);
    this.saps3Form.controls['Abdomen'].setValue(this.Abdomen);
    this.saps3Form.controls['Infeccao'].setValue(this.Infeccao);
    this.saps3Form.controls['Glasgow'].setValue(this.Glasgow);
    this.saps3Form.controls['Fc'].setValue(this.Fc);
    this.saps3Form.controls['Ps'].setValue(this.Ps);
    this.saps3Form.controls['O2'].setValue(this.O2);
    this.saps3Form.controls['Temperatura'].setValue(this.Temperatura);
    this.saps3Form.controls['Leucocito'].setValue(this.Leucocito);
    this.saps3Form.controls['Plaqueta'].setValue(this.Plaqueta);
    this.saps3Form.controls['Ph'].setValue(this.Ph);
    this.saps3Form.controls['Creatinina'].setValue(this.Creatinina);
    this.saps3Form.controls['Bilirrubina'].setValue(this.Bilirrubina);

    this.Comentario = response[0].Comentario;
    this.saps3Form.controls['Comentario'].setValue(this.Comentario);

  }

  async apagarPadrao(clicados: any) {
    let Novolista: any = [];
    for (let i = 0; i < clicados.length; i++) {
      if (clicados[i] != '0A') {
        Novolista.push(
          clicados[i]
        )
      }
    }

    if (Novolista.length > 0) {
      this.saps3Form.controls['Comorbidade'].setValue(Novolista);
    }

  }

}

