import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import {LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';
@Component({
  selector: 'app-historiaclinica',
  templateUrl: './historiaclinica.component.html',
  styleUrls: ['./historiaclinica.component.scss'],
})
export class HistoriaclinicaComponent implements OnInit {
  Paciente: number = 0;
  Usuario: number = 0;
  Idade: number = 0;
  Header: string = "";
  Nome: string = "";
  Internacao: number = 0;
  historiaclinicaForm: FormGroup;
  Botao: string = "Cadastrar";
  Historiaclinica: string = "";
  Setor: string = "";
  Leito: string = "";
  Datainternacao: string = "";
  Dias: string = "";
  dadosListMed: any;
  Logado: number = 0;
  idUsuario: number = 0;
  DescricaoSetor:string='';
  Creatinina:number=0;
  DescricaoHospital:string='';
  Admissao:any=[];
  @ViewChild('ancoraQueixaprincipal', null) ancoraQueixaprincipal: ElementRef;
  @ViewChild('ancoraHistoriaclinica', null) ancoraHistoriaclinica: ElementRef;
  @ViewChild('ancoraExamefisico', null) ancoraExamefisico: ElementRef;
  @ViewChild('ancoraMedicamento', null) ancoraMedicamento: ElementRef;
  @ViewChild('ancoraProblema', null) ancoraProblema: ElementRef;
  @ViewChild('ancoraPlanoterapeutico', null) ancoraPlanoterapeutico: ElementRef;

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private alertController:AlertController,
    private loadingController:LoadingController,
    private checklistService:ChecklistService

    ) {
    this.historiaclinicaForm = formBuilder.group({
      Queixaprincipal: ['', ''],
      Historiaclinica: ['', ''],
      Examefisico: ['', ''],
      Medicamento: ['', ''],
      Problema: ['', ''],
      Planoterapeutico: ['', ''],
    
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Header= this.navParams.get('header');
    this.idUsuario= this.navParams.get('usuario');
    this.Paciente= this.navParams.get('paciente');
    this.Idade= this.navParams.get('idade');
    this.Nome= this.navParams.get("Nome");
    this.Internacao= this.navParams.get('internacao')
    this.Creatinina= this.navParams.get('creatinina');
    this.Historiaclinica= this.navParams.get('historiaclinica');
    this.Setor= this.navParams.get('setor');
    this.Leito= this.navParams.get('leito');
    this.DescricaoSetor= this.navParams.get('descricaosetor');
    this.DescricaoHospital= this.navParams.get('descricaohospital')
    this.Nome= this.navParams.get('nome')
 
  }

  ngOnInit() {
    this.utilService.Descriptografar( this.Nome).then((nP:string)=>{
      this.Nome = nP;
    })
    this.utilService.focusInput(this.ancoraQueixaprincipal);

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
  }

  

  exibirIdUsuario(usuario: number) {
    this.idUsuario = usuario;


  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.selecionarIntenacaoUnica();
    }
    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;
    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pacienterelacao/" +this.Usuario);
      return;

    }
  }
  async cadastarHistoriaClinica() {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados da Admissão...',
    });
    loading.present();
    if (this.Internacao == undefined ||
      this.Internacao == null) {
      this.utilService.showAlertCallback('Você precisa informar a Internação', () => {
        (this.ancoraQueixaprincipal as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    if (this.Usuario == undefined ||
      this.Usuario == null) {
      this.utilService.showAlertCallback('Você precisa informar o Usuário', () => {
        (this.ancoraQueixaprincipal as any).setFocus(true);
      });
      loading.dismiss();
      return;
    }

    if (this.historiaclinicaForm.value.Queixaprincipal == undefined ||
      this.historiaclinicaForm.value.Queixaprincipal == '') {
      this.utilService.showAlertCallback('Você precisa informar a Queixa principal',  () => {
        this.utilService.focusInput(this.ancoraQueixaprincipal);
      });
      loading.dismiss();
      return;
    }
    
    if (this.historiaclinicaForm.value.Historiaclinica == undefined ||
      this.historiaclinicaForm.value.Historiaclinica == '') {
      this.utilService.showAlertCallback('Você precisa informar a história clínica', () => {
        this.utilService.focusInput(this.ancoraHistoriaclinica);
      });
      loading.dismiss();
      return;
    }

    if (this.historiaclinicaForm.value.Examefisico == undefined ||
      this.historiaclinicaForm.value.Examefisico == '') {
      this.utilService.showAlertCallback('Você precisa informar a Exame físico', () => {
        this.utilService.focusInput(this.ancoraExamefisico);
      });
      loading.dismiss();
      return;
    }

    if (this.historiaclinicaForm.value.Medicamento == undefined ||
      this.historiaclinicaForm.value.Medicamento == '') {
      this.utilService.showAlertCallback('Você precisa informar os Medicamentos em uso pelo paciente', () => {
        this.utilService.focusInput(this.ancoraMedicamento);
      });
      loading.dismiss();
      return;
    }

    if (this.historiaclinicaForm.value.Problema == undefined ||
      this.historiaclinicaForm.value.Problema == '') {
      this.utilService.showAlertCallback('Você precisa informar os Problemas do paciente',() => {
        this.utilService.focusInput(this.ancoraProblema);
      });
      loading.dismiss();
      return;
    }

    if (this.historiaclinicaForm.value.Planoterapeutico == undefined ||
      this.historiaclinicaForm.value.Planoterapeutico == '') {
      this.utilService.showAlertCallback('Você precisa informar os Plano terapeutico do paciente', () => {
        this.utilService.focusInput(this.ancoraPlanoterapeutico);
      });
      loading.dismiss();
      return;
    }

    let Queixaprincipal:string=this.historiaclinicaForm.value.Queixaprincipal;
    let Historiaclinica:string=this.historiaclinicaForm.value.Historiaclinica;
    let Examefisico:string=this.historiaclinicaForm.value.Examefisico;
    let Medicamento:string=this.historiaclinicaForm.value.Medicamento;  
    let Problema:string=this.historiaclinicaForm.value.Problema;
    let Planoterapeutico:string=this.historiaclinicaForm.value.Planoterapeutico;
   
    this.utilService.Criptografar(Queixaprincipal).then((Qp)=>{
      Queixaprincipal = Qp;
    })

    this.utilService.Criptografar(Historiaclinica).then((Hc)=>{
      Historiaclinica = Hc;
    })

    this.utilService.Criptografar(Examefisico).then((Ef)=>{
      Examefisico = Ef;
    })

    this.utilService.Criptografar(Examefisico).then((Ef)=>{
      Examefisico = Ef;
    })

    this.utilService.Criptografar(Medicamento).then((Md)=>{
      Medicamento = Md;
    })

    this.utilService.Criptografar(Problema).then((Pb)=>{
      Problema = Pb;
    })

    this.utilService.Criptografar(Planoterapeutico).then((Pt)=>{
      Planoterapeutico = Pt;
      this.Admissao = {
        Id: this.Internacao,
        Queixaprincipal:  Queixaprincipal,
        Historiaclinica:  Historiaclinica,
        Examefisico:  Examefisico,
        Medicamento:  Medicamento,  
        Problema:Problema,
        Planoterapeutico: Planoterapeutico,
        Usuario:  this.Usuario
      }
      loading.dismiss();
      this.presentAlertConfirm();
  
  
    })

   
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja ALTERAR esses dados?'
       const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
              this.atualizarInternacaoAdmissao();
          }
        }
      ]
    });

    await alert.present();
  }

  async atualizarInternacaoAdmissao() {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados da Admissão...',
    });
    loading.present();
    this.pacienteService.atualizarInternacaoAdmissao(this.Admissao)
      .then((response) => {
        this.utilService.showAlertCallback('Admissão atualizado com sucesso', null)
      })
      .catch((response) => {

        this.utilService.showAlertCallback('Não foi possível atualizar a Admissão', null)
      }).finally(()=>{
        loading.dismiss();
      });

  }

  async selecionarIntenacaoUnica() {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados da Admissão...',
      mode: 'ios'
    });
    loading.present();
    this.pacienteService.selecionarInternacaoUnico(this.Internacao).then((response) => {
      setTimeout(() => {
        this.getInternacaoUnico(response);
      }, 500);
    }).catch((response) => {
      //console.log("erro")
    }).finally(()=>{
      loading.dismiss();
    });
   
  }

  async getInternacaoUnico(response: any) {
      this.utilService.Descriptografar(response[0].Queixaprincipal).then((Qp) => {
      this.historiaclinicaForm.controls['Queixaprincipal'].setValue(Qp);
    })
    
    this.utilService.Descriptografar(response[0].Historiaclinica).then((Hc) => {
      this.historiaclinicaForm.controls['Historiaclinica'].setValue(Hc);
    })

    this.utilService.Descriptografar(response[0].Examefisico).then((Ef) => {
      this.historiaclinicaForm.controls['Examefisico'].setValue(Ef);
    })

    this.utilService.Descriptografar(response[0].Medicamento).then((Md) => {
      this.historiaclinicaForm.controls['Medicamento'].setValue(Md);
    })
    

    this.utilService.Descriptografar(response[0].Problema).then((Pb) => {
      this.historiaclinicaForm.controls['Problema'].setValue(Pb);
    })

    this.utilService.Descriptografar(response[0].Planoterapeutico).then((Pt) => {
      this.historiaclinicaForm.controls['Planoterapeutico'].setValue(Pt);
    })
  }
    
}
