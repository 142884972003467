import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService }from '../../services/checklist.service';


@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.scss'],
})
export class DispositivoComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDispositivo',  null) ancoraDispositivo: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraDatasuspensao', null) ancoraDatasuspensao: ElementRef;
  @ViewChild('ancoraDiasproposto', null) ancoraDiasproposto: ElementRef;
  @ViewChild('ancoraPosicao', null) ancoraPosicao: ElementRef;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;
  @ViewChild('ancoraDispositivoacesso', null) ancoraDispositivoacesso: ElementRef;
  private Id:number=0
  private Logado:number = 0;
  private Usuario:number=0;
  private Paciente:number=0;
  private Internacao:number=0;
   dispositivoForm: FormGroup;
   Botao: string = "Cadastrar";
   itensColection:any=[]
   Datainternacao:string="";
   Dias:string="";
   Diasproposto:number=0;
   Dispositivo:number=0;
   Dispositivoacesso: number = 0;
   dispositivoColection:any=[];
   dispositivousoColection:any=[];
   posicaoColection:any=[];
   acessoColection:any=[];
   lRet:any;
   dadosListMed:any;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService:LancamentoService,
    private alertController:AlertController,
    private checklistService:ChecklistService) { 
      this.dispositivoForm = formBuilder.group({
        Id: ['', ''],
        Dispositivo:  ['', ''],
        Datalancamento:  ['', ''],
        Datasuspensao:  ['', ''],
        Diasproposto: ['', ''],
        Observacao:['', ''],
        Posicao:['', ''],
        Acesso:['','']
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
        
      
    }
  ngOnInit() {
    
    this.posicaoColection=[
      {Nome: 'Em curso', Id: 1},
      {Nome: 'Suspenso por infecção', Id: 2},
      {Nome: 'Suspenso posicionamento', Id: 3},
      {Nome: 'Tratamento concluído', Id: 4},
      {Nome: 'Suspenso por troca', Id: 5},

    ]

    this.testaAcessoSistema();

  }

  ionViewDidEnter() {
      this.limpaDados()
   
      this.selecionarDispositivo();
      this.selecionarDispositivoUso(0);
      this.selecionarDispositivoAcesso();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

 async testaAcessoSistema() {

  if (this.dadosListMed != null) {
    this.Logado = this.dadosListMed.Usuario[0].Logado;
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.Internacao = this.dadosListMed.Internacao.Id;;
  }
  
    if (this.Logado == 0 ) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login",  null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;
      
    }
    if (this.Paciente == 0 ) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar",  null)
      this.closeModal();
      this.utilService.Movimentar("pagina");
      return;
      
    }
  }

  async calculaDiasProposto() {
    let Diasproposto: number = this.dispositivoForm.value.Diasproposto
    if (Diasproposto <= 0) {
      this.utilService.showAlertCallback("Informe o número de dias");
      this.utilService.focusInput(this.ancoraDiasproposto);
      return;
    }
    let dtHoje = this.utilService.dataHoje();
    let Datasuspensao = this.utilService.somaDiaData(dtHoje, Diasproposto);
    this.dispositivoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    //this.utilService.focusInput(this.ancoraObservacao);

  
  }




  
  async selecionarDispositivoUsoUnico(Id:number){
   
    if (Id == -1) {
      this.limpaDados();
      return ;
    }
 
    this.lancamentoService.selecionarDispositivoUsoUnico(Id).then((response) => {
      this.getDispositivo(response);
    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível selecionar o dispositivo", 2000, null);

    });
  }

  
  async limpaDados() {
    //console.log("limpaDados")
    this.Botao= "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    let Datasuspensao = this.utilService.somaDiaData(dtHoje,10);
    this.dispositivoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.dispositivoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.dispositivoForm.controls['Diasproposto'].setValue(10);
    this.Dispositivo = 0;
    this.dispositivoForm.controls['Dispositivo'].setValue( 0);
    this.dispositivoForm.controls['Observacao'].setValue('');
    this.dispositivoForm.controls['Posicao'].setValue(1);
    this.dispositivoForm.controls['Acesso'].setValue(0);
    this.dispositivoForm.controls['Id'].setValue(-1);
  }

  async getDispositivo(response:any) {
    if (response.length == 0){
      return;
    }
    this.Botao= "Alterar";
    this.dispositivoForm.controls['Id'].setValue(response[0].Id);
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Datasuspensao = this.utilService.checaData(response[0].Datasuspensao);
    this.dispositivoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.dispositivoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.dispositivoForm.controls['Diasproposto'].setValue(response[0].Diasproposto);
    this.dispositivoForm.controls['Observacao'].setValue(response[0].Observacao);
    this.dispositivoForm.controls['Posicao'].setValue(response[0].Posicao);
    this.dispositivoForm.controls['Acesso'].setValue(response[0].Acesso);
    this.dispositivoForm.controls['Dispositivo'].setValue(response[0].Dispositivo);
   
    
  }

 
  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async presentAlertConfirm() {
    let tipo = parseInt(this.dispositivoForm.value.Id);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " 
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" 
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'DISPOSITIVO',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraObservacao'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrarDispositivo();
          }
        }
      ]
    });

    await alert.present();
  }


  cadastrarDispositivo() {
    this.lRet = this.utilService.validarCampo('Dispositivo',  this.dispositivoForm.value.Dispositivo, () => {
      (this.ancoraObservacao as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     this.lRet = this.utilService.validarCampo('Acesso',  this.dispositivoForm.value.Acesso, () => {
      (this.ancoraObservacao as any).setFocus(true);
    
     });
     if (this.lRet == false) {
       return;
     }

     let Datalancamento = this.utilService.checaData(this.dispositivoForm.value.Datalancamento);
     let Datasuspensao = this.utilService.checaData(this.dispositivoForm.value.Datasuspensao);
 
  
     
    let form = {
      Id:  this.dispositivoForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Dispositivo:  this.dispositivoForm.value.Dispositivo,
      Datalancamento:   Datalancamento,
      Datasuspensao:  Datasuspensao,
      Usuario:  this.Usuario,
      Acesso:this.dispositivoForm.value.Acesso,
      Observacao:this.dispositivoForm.value.Observacao,
      Posicao:this.dispositivoForm.value.Posicao,
      Diasproposto :this.dispositivoForm.value.Diasproposto
      }

      
      if ( this.dispositivoForm.value.Id ==-1) {
        this.lancamentoService.adicionarDispositivoUso(form).then((response) => {
          this.utilService.showAlertCallback("Dispositivo ADICIONADO com sucesso", ()=>{
            this.selecionarDispositivoUso(response[0].Id);
          });
        
        
        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível ADICIONAR o dispositivo");
  
        });
      }else{
        this.lancamentoService.atualizarDispositivoUso(form).then((response) => {
          this.utilService.showAlertCallback("Dispositivo ATUALIZADO com sucesso");
          
        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível ATUALIZAR o dispositivo");
  
        });
      }
    
  }

  async selecionarDispositivo(){
    this.lancamentoService.selecionarDispositivo().then((response) => {
      this.dispositivoColection= response;
    }).catch((response) => {

    });
  }

  async selecionarDispositivoUso(Id:number){
    this.lancamentoService.selecionarDispositivoUso(this.Internacao, this.Paciente).then((response) => {
      this.dispositivousoColection= response;
      this.Id= Id;
     
    }).catch((response) => {

    });
  }

  async selecionarDispositivoAcesso(){
    this.lancamentoService.selecionarDispositivoAcesso().then((response) => {
      this.acessoColection= response;
    }).catch((response) => {

    });
  }
  async mostrarValor(campo:string, mensagem:string, vmin:number, vmax:number) {
    let valor:any = eval('this.dispositivoForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == '' || valor == 0) {
      this.utilService.showToastCallBack(mensagem, 2000, null);
      valor = null;
      this.dispositivoForm.controls[campo].setValue(valor);
      this.utilService.focusInput(eval('this.ancora' + campo))
      return;
    }

    if (valor < vmin ) {
      this.utilService.showToastCallBack("Dispositivo ainda não concluído", 2000, null)
      valor = null;
      this.dispositivoForm.controls[campo].setValue(valor);
      this.utilService.focusInput(eval('this.ancora' + campo))
      return;
     
    }

    if ( valor > vmax) {
      this.utilService.showToastCallBack('Dispositivo usado acima do programado', 2000, null)
      this.dispositivoForm.controls[campo].setValue(valor);
      this.utilService.focusInput(eval('this.ancora' + campo));
      return;
    }
    if (campo == "Diasproposto") {
      this.Diasproposto = valor;
      let Datasuspensao = this.utilService.somaDiaData(this.dispositivoForm.value.Datalancamento, valor);
      this.dispositivoForm.controls['Datasuspensao'].setValue(Datasuspensao);

    }

  }

  async calcularDiasSuspensao(Datasuspensao:any){
    Datasuspensao = new Date(Datasuspensao);
    let Datalancamento = new Date(this.dispositivoForm.value.Datalancamento);
    let diff = Math.abs(Datasuspensao.getTime() - Datalancamento.getTime());
    this.Diasproposto = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }
  
  

}