import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EvolucaoService {

  constructor(
    private http: HttpClient, 
    private utilService: UtilService,
    private loadingController: LoadingController,

    ) { }


  // pacientes

  adicionarEvolucao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/adicionarEvolucao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarEvolucao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/atualizarEvolucao';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarEscorerelacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/atualizarEscorerelacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  adicionarEscorerelacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/adicionarEscorerelacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }


  selecionarEvolucaoUnico(Internacao: number, Paciente: number, Sistema: number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/SelecionarEvolucaoUnico/' + Internacao + '/' + Paciente + '/' + Sistema;
    return this.http.get(url).toPromise();
  }

  selecionarEvolucaoPacienteSistema(Usuario: number, Busca: string) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/SelecionarEvolucaoPacienteSistema/' + Usuario + '/' + Busca;
    return this.http.get(url).toPromise();
  }

  selecionarEvolucaoInternacaoData(Internacao: number, Paciente: number, Data: string) {
   
    let form = {
      Internacao: Internacao,
      Paciente: Paciente,
      Dataevolucao: Data
    }

    let url = this.utilService.obterUrlDaApi() + 'Evolucao/SelecionarEvolucaoInternacaoData';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  selecionarEvolucaoPacienteInternacao(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/SelecionarEvolucaoPacienteInternacao/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

    // Padrao

    // pacientes

  adicionarPadrao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/adicionarPadrao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarPadrao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/atualizarPadrao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }


  selecionarPadraoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/SelecionarPadraoUnico/' + Id ;
    return this.http.get(url).toPromise();
  }
  
  selecionarPadraoUsuario(Usuario: number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolucao/selecionarPadraoUsuario/' + Usuario ;
    return this.http.get(url).toPromise();
  }
  

}


