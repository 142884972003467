import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class EvolutionService {

  constructor(
    private http: HttpClient, 
    private utilService: UtilService,
    private loadingController: LoadingController
    ) { }


  // inclusão geral dos sistemas

  adicionarGeral(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/AdicionarGeral';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarGeral(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/AtualizarGeral';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  

  selecionarGeralUnico(Id: number, Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/SelecionarGeralUnico/' + Id + '/' + Sistema ;
    return this.http.get(url).toPromise();
  }

  selecionarGeralInternacao(Internacao:number, Paciente:number, Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/SelecionarGeralInternacao/' + Internacao +'/' + Paciente + '/' + Sistema;
    return this.http.get(url).toPromise();
  }

  SelecionarGeralUltimo(Internacao:number, Paciente:number, Usuario:number, Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/SelecionarGeralUltimo/' + Internacao + '/' + Paciente + '/' + Usuario + '/' + Sistema;
    return this.http.get(url).toPromise();
  }

  SelecionarGeralEvolucao(Internacao:number, Paciente:number, Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/SelecionarGeralEvolucao/' + Internacao + '/' + Paciente + '/' + Sistema;
    return this.http.get(url).toPromise();
  }

  SelecionarEscoreRelacaoUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Evolution/SelecionarEscoreRelacaoUnico/' + Id;
    return this.http.get(url).toPromise();
  }


}



