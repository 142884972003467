import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-hormonio',
  templateUrl: './hormonio.component.html',
  styleUrls: ['./hormonio.component.scss'],
})
export class HormonioComponent implements OnInit {
  @ViewChild('ancoraIdhormonio', null) ancoraIdhormonio: ElementRef;
  @ViewChild('ancoraDatalancamentohormonio', null) ancoraDatalancamentohormonio: ElementRef;
  @ViewChild('ancoraT3', null) ancoraT3: ElementRef;
  @ViewChild('ancoraT4', null) ancoraT4: ElementRef;
  @ViewChild('ancoraTshultrassensivel', null) ancoraTshultrassensivel: ElementRef;
  @ViewChild('ancoraT3livre', null) ancoraT3livre: ElementRef;
  @ViewChild('ancoraT4livre', null) ancoraT4livre: ElementRef;
  @ViewChild('ancoraLh', null) ancoraLh: ElementRef;
  @ViewChild('ancoraFsh', null) ancoraFsh: ElementRef;
  @ViewChild('ancoraProgesterona', null) ancoraProgesterona: ElementRef;
  @ViewChild('ancoraProlactina', null) ancoraProlactina: ElementRef;
  @ViewChild('ancoraEstrogeno', null) ancoraTestosterona: ElementRef;
  @ViewChild('ancoraTestosterona', null) ancoraCalcio: ElementRef;
  @ViewChild('ancoraTestosteronalivre', null) ancoraTestosteronalivre: ElementRef;

  private IdGeral: number = 0;
  private Exames: any;
  private Datalancamentohormonio: string = "";
  private Usuario: number = 0;
  private Paciente: number = 0;
  private Idade: string = "";
  private Nome: string = "";
  private Internacao: number = 0;
  private Historiaclinica: string = "";
  private Setor: string = "";
  private Leito: string = "";
  public hormonioForm: FormGroup;
  public Botaohormonio: string = "Cadastrar";
  private Datainternacao: string = "";
  private Dias: string = "";
  private hormonioCollection: any = [];
  public dadosListMed: any;
  private Logado: number = 0;
  public T3: number = 0;
  public T4: number = 0;
  public Tempocoagulacao: number = 0;
  public Tshultrassensivel: number = 0;
  public T3livre: number = 0;
  public T4livre: number = 0;
  public Lh: number = 0;
  public Fsh: number = 0;
  public Progesterona: number = 0;
  public Prolactina: number = 0;
  public Estrogeno: number = 0;
  public Testosterona: number = 0;
  public Testosteronalivre: number = 0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService: LaboratorioService,
    private alertController: AlertController,
  ) {

    this.hormonioForm = formBuilder.group({

      Idhormonio: ['', ''],
      Datalancamentohormonio: ['', ''],
      T3: ['', ''],
      T4: ['', ''],
      Tempocoagulacao: ['', ''],
      Tshultrassensivel: ['', ''],
      T3livre: ['', ''],
      T4livre: ['', ''],
      Lh: ['', ''],
      Fsh: ['', ''],
      Progesterona: ['', ''],
      Prolactina: ['', ''],
      Estrogeno: ['', ''],
      Testosterona: ['', ''],
      Testosteronalivre: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarHormonio(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao = this.dadosListMed.Internacao.Id;
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosHormonio();

  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: any = eval('this.hormonioForm.value.' + this.Exames[i].nome);
      if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax || valor == null) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
          parametro = this.Exames[i].nome;
        }
        if (valor == null) {
          this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg, 2000, null)
          this.utilService.focusInput(eval('this.ancora' + parametro));
          valor = 0;
        }
        this.hormonioForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + ": " + valor + ' (' + this.Exames[i].vmin + '-' + this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
      }
    }

    

    let tipo = parseInt(this.hormonioForm.value.Idhormonio);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'COAGULOGRAMA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroHormonio();
          }
        }
      ]
    });

    await alert.present();
   
  }

  cadastroHormonio() {
    let form = {
      Id: this.hormonioForm.value.Idhormonio,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.hormonioForm.value.Datalancamentohormonio),
      Usuario: this.Usuario,
      T3: this.hormonioForm.value.T3,
      T4: this.hormonioForm.value.T4,
      Tempocoagulacao: this.hormonioForm.value.Tempocoagulacao,
      Tshultrassensivel: this.hormonioForm.value.Tshultrassensivel,
      T3livre: this.hormonioForm.value.T3livre,
      T4livre: this.hormonioForm.value.T4livre,
      Lh: this.hormonioForm.value.Lh,
      Fsh: this.hormonioForm.value.Fsh,
      Progesterona: this.hormonioForm.value.Progesterona,
      Prolactina: this.hormonioForm.value.Prolactina,
      Estrogeno: this.hormonioForm.value.Estrogeno,
      Testosterona: this.hormonioForm.value.Testosterona,
      Testosteronalivre: this.hormonioForm.value.Testosteronalivre,

    }

    if (this.hormonioForm.value.Idhormonio == 0 || this.hormonioForm.value.Idhormonio == undefined || this.hormonioForm.value.Idhormonio == null || this.hormonioForm.value.Idhormonio == '') {
      this.laboratorioService.adicionarHormonio(form).then((response) => {
        this.selecionarHormonio(response[0].Id);
        this.utilService.showToastCallBack("Hormonio incluido com sucesso", 2000, null);


      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Hormonio", 2000, null);

      });
    } else {
      this.laboratorioService.atualizarHormonio(form).then((response) => {
        this.utilService.showToastCallBack("Hormonio atualizado com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Hormonio", 2000, null);

      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresHormonio()
      .then((response) => {
        this.Exames = response;

      })
      .catch((response) => {

        this.Exames = [];
      });
  }
  async selecionarHormonioUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosHormonio();
      return;
    }
    this.laboratorioService.selecionarHormonioUnico(Id).then((response) => {
      this.getBalancoHormonio(response);
    }).catch((response) => {

    });
  }



  async limpaDadosHormonio() {
    this.Botaohormonio = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentohormonio = dtHoje;
    this.hormonioForm.controls['Datalancamentohormonio'].setValue(dtHoje);
    this.hormonioForm.controls['T3'].setValue(0);
    this.hormonioForm.controls['T4'].setValue(0);
    this.hormonioForm.controls['Tempocoagulacao'].setValue(0);
    this.hormonioForm.controls['Tshultrassensivel'].setValue(0);
    this.hormonioForm.controls['T3livre'].setValue(0);
    this.hormonioForm.controls['T4livre'].setValue(0);
    this.hormonioForm.controls['Lh'].setValue(0);
    this.hormonioForm.controls['Fsh'].setValue(0);
    this.hormonioForm.controls['Progesterona'].setValue(0);
    this.hormonioForm.controls['Prolactina'].setValue(0);
    this.hormonioForm.controls['Estrogeno'].setValue(0);
    this.hormonioForm.controls['Testosterona'].setValue(0);
    this.hormonioForm.controls['Testosteronalivre'].setValue(0);
    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraT4'));
  }

  async getBalancoHormonio(response: any) {
    this.Botaohormonio = "Alterar";
    let Datalancamentohormonio = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.hormonioForm.controls['Datalancamentohormonio'].setValue(Datalancamentohormonio);
    this.hormonioForm.controls['T3'].setValue(response[0].T3.replace(",", "."));
    this.hormonioForm.controls['T4'].setValue(response[0].T4.replace(",", "."));
    this.hormonioForm.controls['Tshultrassensivel'].setValue(response[0].Tshultrassensivel.replace(",", "."));
    this.hormonioForm.controls['T3livre'].setValue(response[0].T3livre.replace(",", "."));
    this.hormonioForm.controls['T4livre'].setValue(response[0].T4livre.replace(",", "."));
    this.hormonioForm.controls['Lh'].setValue(response[0].Lh.replace(",", "."));
    this.hormonioForm.controls['Fsh'].setValue(response[0].Fsh.replace(",", "."));
    this.hormonioForm.controls['Progesterona'].setValue(response[0].Progesterona.replace(",", "."));
    this.hormonioForm.controls['Prolactina'].setValue(response[0].Prolactina.replace(",", "."));
    this.hormonioForm.controls['Estrogeno'].setValue(response[0].Estrogeno.replace(",", "."));
    this.hormonioForm.controls['Testosterona'].setValue(response[0].Testosterona.replace(",", "."));
    this.hormonioForm.controls['Testosteronalivre'].setValue(response[0].Testosteronalivre.replace(",", "."));

  }

  async selecionarHormonio(seek: number) {
    this.laboratorioService.selecionarHormonio(this.Internacao, this.Paciente).then((response) => {
      this.hormonioCollection = response;
      this.IdGeral = seek;

    }).catch((response) => {

    });
  }



  async mostrarValor(campo: string) {
    let valor: any = eval('this.hormonioForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == NaN || valor == '') {
      this.hormonioForm.controls[campo].setValue(valor);
    }


    if (campo == "T4") {
      this.T4 = valor;
    } else if (campo == "Tempocoagulacao") {
      this.Tempocoagulacao = valor;
    } else if (campo == "Tshultrassensivel") {
      this.Tshultrassensivel = valor;
    } else if (campo == "Lh") {
      this.Lh = valor;
    } else if (campo == "T3livre") {
      this.T3livre = valor;
    } else if (campo == "T4livre") {
      this.T4livre = valor;
    } else if (campo == "Fsh") {
      this.Fsh = valor;
    } else if (campo == "Progesterona") {
      this.Progesterona = valor;
    } else if (campo == "Prolactina") {
      this.Prolactina = valor;
    } else if (campo == "Estrogeno") {
      this.Estrogeno = valor;
    } else if (campo == "Testosterona") {
      this.Testosterona = valor;
    } else if (campo == "Testosteronalivre") {
      this.Testosteronalivre = valor;

    }
  }


}


