import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SistemaModule {
  Usuario: Array<
    {
      Id:number,
      Nome:string,
      Datanascimento:string,
      Sexo:number,
      Email:string,
      Celular:string,
      Senha:string,
      Cep:string,
     Logradouro:string,
     Complemento:string,
     Bairro:string,
     Estado:number,
     Municipio:number,
     Localidade:string,
     Termosdeuso:number,
     Imagem:string,
     Ativo:number,
     Administrador:number,
     Curriculo:string,
     Cpf:string,
     Conselho:number,
     Numeroconselho:string,
     Codigovalidacao:string,
     Retorno:number,
     Idade:number,
     Siglaconselho:string,
     Logado:number

    }> = [];

    Tabela: Array<
    {
      Nome: string,
      Colunas: any,
      Id:number
    }> = [];

  Internacao: Array<
    {
       Id :number,
       Paciente :number,
       Peso :any,
       NomePaciente :string,
       Datainternacao :string,
       Hospital :number,
       DescricaoHospital :string,
       Setor :number,
       DescricaoSetor :string,
       Leito :string,
       Creatininabasal :string,
       Balancoacumulado :string,
       Usuario :number,
       Dias :number,
       Idade :number,
       Comorbidade :string,
       Queixaprincipal :string,
       Historiaclinica :string,
       Examefisico :string,
       Medicamento :string,
       Problema :string,
       Planoterapeutico :string,
       Pontos :number,  // SAP3
       Mortalidade :string, // MORTALIDADE
       Altura   :string,
       Bsa   :string,
       PesoIdealMasculino :string,
       PesoIdealFeminino :string,
       VolemiaMasculino  :string,
       VolemiaFemenino :string,
       Imc   :string,
       Imagem :string,
       Sexo :number,
       PaO2Ideal :string, 
       SiglaEstado :string,
       FASTHUG: number,
       FASTHUGrelacao: string,
       SOFA: number,
       SOFArelacao: string
    }> = [];
  
    Hospital: Array<
    {
     Id:number,
     Nome:string,
     Responsavel:string,
     Email:string,
     Celular:string,
     Cep:string,
     Logradouro:string,
     Complemento:string,
     Bairro:string,
     Estado:number,
     Municipio:number,
     Localidade:string,
     ImagemHospital:string,
     Ativo:number,
     Cnpj:string,
     Usuario:number,
     Retorno:number,
     SiglaEstado:string,
     Internados:number
    }> = [];

    Sofa: Array<
    {
       Internacao :number,
       Paciente :number,
       Neurologico: number,
       Cardiaco: number,
       Respiratorio:number,
       Digestivo:number,
       Renal: number,
       Hematologico: number
    }> = [];
    Fasthug: Array<
    {
       Internacao :number,
       Paciente :number,
       Cabeceira: string,
       Analgesia: string,
       Sedacao:string,
       Dieta:string,
       Troboembolismo: string, 
       Ulcera: string,
       Glicemia:string
    }> = [];
   //Id	Data	Nome	Email	Facebook	Instagram	Celular	Pesquisa	Resultado	Ativo	Codigo
    Questionario: Array<
    {
       Id :number,
       Data :string,
       Nome: string,
       Email: string,
       Facebook:string,
       Instagram:string,
       Celular: string, 
       Pesquisa: number,
       Resultado:string,
       Ativo: number,
       Codigo: number
    }> = [];

    Exame: Array<
    {
      Internacao :number,
      Paciente :number,
      Usuario: number,
      Exame: number,
    }> = [];
    
}
