import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChecklistService } from '../../services/checklist.service';
import { PacienteService } from '../../services/paciente.service';
import { UtilpdfService } from '../../services/utilpdf.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@ionic-native/File/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
  selector: 'app-hemodinamica',
  templateUrl: './hemodinamica.component.html',
  styleUrls: ['./hemodinamica.component.scss'],
})
export class HemodinamicaComponent implements OnInit {
  @ViewChild('ancoraLactato', null) ancoraLactato: ElementRef;
  @ViewChild('ancoraSvo2', null) ancoraSvo2: ElementRef;
  @ViewChild('ancoraPvaco2', null) ancoraPvaco2: ElementRef;
  @ViewChild('ancoraDc', null) ancoraDc: ElementRef;
  @ViewChild('ancoraSato2', null) ancoraSato2: ElementRef;
  dadosListMed: any
  hemodinamicaForm: FormGroup;
  icone: string = 'thumbs-up'
  Internacao: number = 0;
  Paciente: number = 0;
  Usuario: number = 0;
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  Sigla: string = '';
  Nome: string = '';
  Componente: string = '';
  Valornormal: string = '';
  Descricaounidade: string = '';
  Descricaocalculo: string = '';
  Observacao: any;
  aObservacao: any = [];
  Valormaximo: string = '';
  Valorminimo: string = '';
  Sexo: number = 0;
  Sistema: number = 0;
  Logado: number = 0;
  lancamentosCollection: any;
  Botao: string = ""
  Datalancamento: string = ""
  Lactato: string = "";
  Svo2: string = "";
  Pvaco2: string = "";
  Dc: string = "";
  Sato2: number = 0;
  Calculo: number = 0;

  Peso: number = 0;
  DataHoje: string = "";
  docDefinition: any;
  pdfObj = null;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private checklistService: ChecklistService,
    private alertController: AlertController,
    private utilpdfService: UtilpdfService,
    private file: File,
    private fileOpener: FileOpener,
    private platform: Platform,
    private clipboard: Clipboard,
    private pacienteService: PacienteService
  ) {



    this.hemodinamicaForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Lactato: ['', ''],
      Svo2: ['', ''],
      Pvaco2: ['', ''],
      Dc: ['', ''],
      Sato2: ['', ''],
      Comentario: ['', ''],
      Observacao: ['', ''],

    })

    this.Peso = this.navParams.get('Peso');
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Sistema = this.navParams.get('Sistema');
    this.dadosListMed = this.utilService.obterDadosDoSistema();


  }

  ngOnInit() {
    this.Calculo = 47;

  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
  }

  async selecionarDadosCalculoUnico() {
    let loading = await this.loadingController.create(
      {
        message: "Selecionando od dados...",
        mode: 'ios'
      }
    );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
      .then((response: any) => {
        this.Sigla = response[0].Sigla;
        this.Nome = response[0].Nome
        this.Componente = response[0].Componente
        this.Valornormal = response[0].Valornormal;
        this.Descricaounidade = response[0].Descricaounidade
        this.Descricaocalculo = response[0].Descricaocalculo
        this.Observacao = response[0].Observacao
        this.Valorminimo = response[0].Valorminimo
        this.Valormaximo = response[0].Valormaximo


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona os dados da calculadora')
      }).finally(() => {
        loading.dismiss();
      });

  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento", null)
    });
  }

  async closeModal() {

    this.modalController.dismiss();
  }

  async limpaDados() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.hemodinamicaForm.controls['Id'].setValue(-1);
    this.hemodinamicaForm.controls['Datalancamento'].setValue(Datalancamento);
    this.hemodinamicaForm.controls['Lactato'].setValue('A');
    this.hemodinamicaForm.controls['Svo2'].setValue('D');
    this.hemodinamicaForm.controls['Pvaco2'].setValue('E');
    this.hemodinamicaForm.controls['Dc'].setValue('H');
    this.hemodinamicaForm.controls['Sato2'].setValue('L');
    this.hemodinamicaForm.controls['Comentario'].setValue('');
    //ADEHL [normal]

    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CADASTRAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }


  }
  async calcularAlteracao() {
    this.Lactato = this.hemodinamicaForm.value.Lactato.toString();
    this.Svo2 = this.hemodinamicaForm.value.Svo2.toString()
    this.Pvaco2 = this.hemodinamicaForm.value.Pvaco2.toString();
    this.Dc = this.hemodinamicaForm.value.Dc.toString();
    this.Sato2 = this.hemodinamicaForm.value.Sato2.toString();

    let soma: string = this.Lactato + this.Svo2 + this.Pvaco2 + this.Dc + this.Sato2;
    let Diagnostico: string = "";

    if (soma == "BCEIJ") {
      Diagnostico = "ANEMIA"
    } else if (soma == "BCFGJ") {
      Diagnostico = "DISÓXIA"
    } else if (soma == "BDEIJ") {
      Diagnostico = "SEPSE"
    } else if (soma == "BDFIJ") {
      Diagnostico = "ALTERAÇÃO MITOCONDRIAL"
    } else if (soma == "ACEIL") {
      Diagnostico = "ANEMIA"
    } else if (soma == "ACFGL") {
      Diagnostico = "ICC"
    } else if (soma == "ADEHL") {
      Diagnostico = "NORMAL"
    } else if (soma == "ADFGL") {
      Diagnostico = "ALTERAÇÃO MITOCONDRIAL"
    } else {
      Diagnostico = "DADOS INCORRETOS"
    }

    this.hemodinamicaForm.controls['Comentario'].setValue(Diagnostico);

    if (this.Logado > 0 && this.Internacao > 0 && this.Paciente > 0) {
      this.presentAlertConfirm()

    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja ADICIONAR esses dados para ' + this.Nome
    let Id: number = this.hemodinamicaForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ATUALIZAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {
    if (this.Logado == 0 || this.Paciente == 0) {
      return;
    }
    let Relacao: any = [];
    Relacao = Relacao + this.Lactato + '*';
    Relacao = Relacao + this.Svo2 + '*';
    Relacao = Relacao + this.Pvaco2 + '*';
    Relacao = Relacao + this.Dc + '*';
    Relacao = Relacao + this.Sato2;

    let Datalancamento: any = this.hemodinamicaForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.hemodinamicaForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: 0,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.hemodinamicaForm.value.Comentario

    }



    if (this.hemodinamicaForm.value.Id == -1) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso", null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.hemodinamicaForm.controls['Id'].setValue(response[0].Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso", null);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Lactato = Relacao[0].toString();
    this.Svo2 = Relacao[1].toString();
    this.Pvaco2 = Relacao[2].toString();
    this.Dc = Relacao[3].toString();
    this.Sato2 = Relacao[4].toString();
    this.hemodinamicaForm.controls['Datalancamento'].setValue(Datalancamento);
    this.hemodinamicaForm.controls['Lactato'].setValue(this.Lactato);
    this.hemodinamicaForm.controls['Svo2'].setValue(this.Svo2);
    this.hemodinamicaForm.controls['Pvaco2'].setValue(this.Pvaco2);
    this.hemodinamicaForm.controls['Dc'].setValue(this.Dc);
    this.hemodinamicaForm.controls['Sato2'].setValue(this.Sato2);
    this.hemodinamicaForm.controls['Comentario'].setValue(response[0].Comentario);

  }

  async presentAlertConfirmPdf() {
    let message: string = 'Deseja gerar um PDF desse checklist?'
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.gerarPdfRelatorio();

          }
        }
      ]
    });
    await alert.present();
  }

  //






  async gerarPdfRelatorio() {
    if (this.hemodinamicaForm.value.Comentario == '') {
      this.utilService.showAlertCallback("Primeiro você precisa gerar o cálculo");
      return;
    }
    // 0-6 (zero=domingo)
    this.DataHoje = this.utilService.dataHojeBrasil();
    let aInternacao: any = this.dadosListMed.Internacao
    let Comentario = this.hemodinamicaForm.value.Comentario; this.utilpdfService.criaDefinicaoPdfEvolucao(Comentario, this.DataHoje.substring(0, 9), this.DataHoje.substring(10, 15), aInternacao).then((response: any[]) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.downloadPdf();
      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })
  }

  downloadPdf() {
    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });
        // Save the PDF to the data Directory of our App
        this.file.writeFile(this.file.dataDirectory, 'melhormente.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          this.fileOpener.open(this.file.dataDirectory + 'melhormente.pdf', 'application/pdf');
          this.utilService.showAlertCallback("PDF gerado com sucesso. Aguarde ele vai ser aberto.");
        })
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download();
      this.utilService.showAlertCallback("PDF gerado com sucesso na internet");
    }
  }



}
