import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import {LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

  constructor(private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController

    ) { }

  // - Hemograma

  selecionarHemograma(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarHemograma/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarHemogramaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarHemogramaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarHemograma(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarHemograma';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarHemograma(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarHemograma';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // - Coagulograma

  selecionarCoagulograma(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarCoagulograma/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarCoagulogramaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarCoagulogramaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarCoagulograma(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarCoagulograma';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarCoagulograma(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarCoagulograma';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  //  Bioquimica

  selecionarBioquimica(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarBioquimica/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarBioquimicaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarBioquimicaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarBioquimica(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarBioquimica';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarBioquimica(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarBioquimica';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // - Gasometria

  selecionarGasometria(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarGasometria/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarGasometriaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarGasometriaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarGasometria(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarGasometria';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarGasometria(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarGasometria';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // - Imunologia

  selecionarImunologia(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarImunologia/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarImunologiaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarImunologiaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarImunologia(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarImunologia';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarImunologia(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarImunologia';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

   // - Fezes

   selecionarFezes(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarFezes/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarFezesUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarFezesUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarFezes(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarFezes';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarFezes(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarFezes';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // - Urina

  selecionarUrina(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarUrina/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarUrinaUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarUrinaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarUrina(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarUrina';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarUrina(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarUrina';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

   // - Hormonio

   selecionarHormonio(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarHormonio/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarHormonioUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarHormonioUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarHormonio(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarHormonio';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarHormonio(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarHormonio';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // - Marcador

  selecionarMarcador(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarMarcador/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarMarcadorUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/selecionarMarcadorUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarMarcador(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/adicionarMarcador';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarMarcador(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Laboratorio/atualizarMarcador';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  

  
}
