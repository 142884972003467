import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ExameService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController

    
    ) { }


  
  

  // exames ultrassonografia, raio x, tomografia, ressonância

  selecionarExame(tipoexame: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ultrasson/selecionarExame/' + tipoexame;
    return this.http.get(url).toPromise();
  }




  selecionarExameTipo() {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameTipo' ;
    return this.http.get(url).toPromise();
  }

  selecionarExameSolicitante(usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameSolicitante/' + usuario;
    return this.http.get(url).toPromise();
  }

  selecionarExameTopicoPadrao(exametipo:number,usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameTopicoPadrao/' + exametipo + '/' + usuario;
    return this.http.get(url).toPromise();
  }
  selecionarExameOrgao() {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameOrgao';
    return this.http.get(url).toPromise();
  }

  selecionarExameOrgaoUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameOrgaoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarExameRelacaoTipo(exametipo: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameRelacaoTipo/' + exametipo;
    return this.http.get(url).toPromise();
  }

  selecionarExameRelacaoUnico(id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameRelacaoUnico/' + id;
    return this.http.get(url).toPromise();
  }

  selecionarExameEmissao(internacao: number, paciente:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameEmissao/' + internacao + '/' + paciente;
    return this.http.get(url).toPromise();
  }

  selecionarExameEmissaoRelatorio(hospital:number, exametipo:number, usuario:number, datainicial:string, datafinal:string) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameEmissaoRelatorio/' + hospital + '/' + exametipo+ '/' + usuario+ '/' + datainicial+ '/' + datafinal ;
    return this.http.get(url).toPromise();

  }
  


  selecionarExameImagem(tabela:string, idtabela:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameImagem/' + tabela + '/' + idtabela;
    return this.http.get(url).toPromise();
  }

  selecionarExamePadraoRelacao(examerelacao:number, usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExamePadraoRelacao/' + examerelacao + '/' + usuario;
    return this.http.get(url).toPromise();
  }

  selecionarExameTopicoUnico(id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameTopicoUnico/' + id ;
    return this.http.get(url).toPromise();
  }

  selecionarExameTopico(tipo:number, orgao:number, usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameTopico/' + tipo + '/' + orgao + '/' + usuario ;
    return this.http.get(url).toPromise();
  }

  selecionarExameConclusaoRelacao(examerelacao:number, usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameConclusaoRelacao/' + examerelacao + '/' + usuario;
    return this.http.get(url).toPromise();
  }

  selecionarExameSolicitanteUsuario(usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameSolicitanteUsuario/' + usuario ;
    return this.http.get(url).toPromise();
  }

  
  selecionarExameEmissaoUnico(id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameEmissaoUnico/' + id;
    return this.http.get(url).toPromise();
  }

  selecionarExameConclusaoUnico(id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameConclusaoUnico/' + id;
    return this.http.get(url).toPromise();
  }

  selecionarExamePadraoUnico(id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExamePadraoUnico/' + id;
    return this.http.get(url).toPromise();
  }

  selecionarExameMedida() {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameMedida' ;
    return this.http.get(url).toPromise();
  }


  

  selecionarExameSolicitanteUnico(id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/selecionarExameSolicitanteUnico/' + id;
    return this.http.get(url).toPromise();
  }

  adicionarExameTopico(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameTopico';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameEmissao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameEmissao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameMedida(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameMedida';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameConclusao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameConclusao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameOrgao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameOrgao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameSolicitante(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameSolicitante';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExamePadrao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExamePadrao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarExameRelacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/adicionarExameRelacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarExameTopico(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameTopico';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameEmissaoTexto(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameEmissaoTexto';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameMedida(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameMedida';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameOrgao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameOrgao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameEmissao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameEmissao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameEmissaoMedida(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameEmissaoMedida';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameRelacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameRelacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameConclusao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameConclusao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExameSolicitante(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExameSolicitante';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarExamePadrao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Exame/atualizarExamePadrao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

   
}
