import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-marcador',
  templateUrl: './marcador.component.html',
  styleUrls: ['./marcador.component.scss'],
})
export class MarcadorComponent implements OnInit {
  @ViewChild('ancoraIdmarcador', null) ancoraIdmarcador: ElementRef;
  @ViewChild('ancoraDatalancamentomarcador', null) ancoraDatalancamentomarcador: ElementRef;
  @ViewChild('ancoraAlfafetoproteina', null) ancoraAlfafetoproteina: ElementRef;
  @ViewChild('ancoraCea', null) ancoraCea: ElementRef;
  @ViewChild('ancoraCa153', null) ancoraCa153: ElementRef;
  @ViewChild('ancoraCa199', null) ancoraCa199: ElementRef;
  @ViewChild('ancoraCa125', null) ancoraCa125: ElementRef;
  @ViewChild('ancoraLh', null) ancoraLh: ElementRef;
  @ViewChild('ancoraCyfra21', null) ancoraCyfra21: ElementRef;
  @ViewChild('ancoraPsa', null) ancoraPsa: ElementRef;
  @ViewChild('ancoraPsalivre', null) ancoraPsalivre: ElementRef;
 
  private IdGeral: number = 0;
  private Exames: any;
  private Datalancamentomarcador: string = "";
  private Usuario: number = 0;
  private Paciente: number = 0;
  private Idade: string = "";
  private Nome: string = "";
  private Internacao: number = 0;
  private Historiaclinica: string = "";
  private Setor: string = "";
  private Leito: string = "";
  public marcadorForm: FormGroup;
  public Botaomarcador: string = "Cadastrar";
  private Datainternacao: string = "";
  private Dias: string = "";
  private marcadorCollection: any = [];
  public dadosListMed: any;
  private Logado: number = 0;
  public Alfafetoproteina: number = 0;
  public Cea: number = 0;
  public Tempocoagulacao: number = 0;
  public Ca153: number = 0;
  public Ca199: number = 0;
  public Ca125: number = 0;
  public Ca724: number = 0;
  public Cyfra21: number = 0;
  public Psa: number = 0;
  public Psalivre: number = 0;
  public Estrogeno: number = 0;
  public Testosterona: number = 0;
  public Testosteronalivre: number = 0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService: LaboratorioService,
    private alertController: AlertController,
  ) {

    this.marcadorForm = formBuilder.group({

      Idmarcador: ['', ''],
      Datalancamentomarcador: ['', ''],
      Alfafetoproteina: ['', ''],
      Cea: ['', ''],
      Ca153: ['', ''],
      Ca199: ['', ''],
      Ca125: ['', ''],
      Ca724: ['', ''],
      Cyfra21: ['', ''],
      Psa: ['', ''],
      Psalivre: ['', ''],
     

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarMarcador(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao = this.dadosListMed.Internacao.Id;
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosMarcador();

  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: any = eval('this.marcadorForm.value.' + this.Exames[i].nome);
      if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax || valor == null) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
          parametro = this.Exames[i].nome;
        }
        if (valor == null) {
          this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg, 2000, null)
          this.utilService.focusInput(eval('this.ancora' + parametro));
          valor = 0;
        }
        this.marcadorForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + ": " + valor + ' (' + this.Exames[i].vmin + '-' + this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
      }
    }

    

    let tipo = parseInt(this.marcadorForm.value.Idmarcador);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'MARCADOR',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroMarcador();
          }
        }
      ]
    });

    await alert.present();
   
  }

  cadastroMarcador() {
    let form = {
      Id: this.marcadorForm.value.Idmarcador,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: new Date(this.marcadorForm.value.Datalancamentomarcador),
      Alfafetoproteina: this.marcadorForm.value.Alfafetoproteina,
      Cea: this.marcadorForm.value.Cea,
      Ca153: this.marcadorForm.value.Ca153,
      Ca199: this.marcadorForm.value.Ca199,
      Ca125: this.marcadorForm.value.Ca125,
      Ca724: this.marcadorForm.value.Ca724,
      Cyfra21: this.marcadorForm.value.Cyfra21,
      Psa: this.marcadorForm.value.Psa,
      Psalivre: this.marcadorForm.value.Psalivre,
      Usuario: this.Usuario

    }

    if (this.marcadorForm.value.Idmarcador == 0 || this.marcadorForm.value.Idmarcador == undefined || this.marcadorForm.value.Idmarcador == null || this.marcadorForm.value.Idmarcador == '') {
      this.laboratorioService.adicionarMarcador(form).then((response) => {
        this.selecionarMarcador(response[0].Id);
        this.utilService.showToastCallBack("Marcador incluido com sucesso", 2000, null);


      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Marcador", 2000, null);

      });
    } else {
      this.laboratorioService.atualizarMarcador(form).then((response) => {
        this.utilService.showToastCallBack("Marcador atualizado com sucesso", 2000, null);

      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Marcador", 2000, null);

      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresMarcador()
      .then((response) => {
        this.Exames = response;

      })
      .catch((response) => {

        this.Exames = [];
      });
  }
  async selecionarMarcadorUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosMarcador();
      return;
    }
    this.laboratorioService.selecionarMarcadorUnico(Id).then((response) => {
      this.getBalancoMarcador(response);
    }).catch((response) => {

    });
  }



  async limpaDadosMarcador() {
    this.Botaomarcador = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentomarcador = dtHoje;
    this.marcadorForm.controls['Datalancamentomarcador'].setValue(dtHoje);
    this.marcadorForm.controls['Alfafetoproteina'].setValue(0);
    this.marcadorForm.controls['Cea'].setValue(0);
    this.marcadorForm.controls['Ca153'].setValue(0);
    this.marcadorForm.controls['Ca199'].setValue(0);
    this.marcadorForm.controls['Ca125'].setValue(0);
    this.marcadorForm.controls['Ca724'].setValue(0);
    this.marcadorForm.controls['Cyfra21'].setValue(0);
    this.marcadorForm.controls['Psa'].setValue(0);
    this.marcadorForm.controls['Psalivre'].setValue(0);
    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraAlfafetoproteina'));
  }

  async getBalancoMarcador(response: any) {
    this.Botaomarcador = "Alterar";
    let Datalancamentomarcador = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.marcadorForm.controls['Datalancamentomarcador'].setValue(Datalancamentomarcador);
    this.marcadorForm.controls['Alfafetoproteina'].setValue(response[0].Alfafetoproteina.replace(",", "."));
    this.marcadorForm.controls['Cea'].setValue(response[0].Cea.replace(",", "."));
    this.marcadorForm.controls['Ca153'].setValue(response[0].Ca153.replace(",", "."));
    this.marcadorForm.controls['Ca199'].setValue(response[0].Ca199.replace(",", "."));
    this.marcadorForm.controls['Ca125'].setValue(response[0].Ca125.replace(",", "."));
    this.marcadorForm.controls['Ca724'].setValue(response[0].Ca724.replace(",", "."));
    this.marcadorForm.controls['Cyfra21'].setValue(response[0].Cyfra21.replace(",", "."));
    this.marcadorForm.controls['Psa'].setValue(response[0].Psa.replace(",", "."));
    this.marcadorForm.controls['Psalivre'].setValue(response[0].Psalivre.replace(",", "."));
  
  }

  async selecionarMarcador(seek: number) {
    this.laboratorioService.selecionarMarcador(this.Internacao, this.Paciente).then((response) => {
      this.marcadorCollection = response;
      this.IdGeral = seek;

    }).catch((response) => {

    });
  }



  async mostrarValor(campo: string) {
    let valor: any = eval('this.marcadorForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == NaN || valor == '') {
      this.marcadorForm.controls[campo].setValue(valor);
    }


    if (campo == "Alfafetoproteina") {
      this.Alfafetoproteina = valor;
    } else if (campo == "Cea") {
      this.Cea = valor;
    } else if (campo == "Ca153") {
      this.Ca153 = valor;
    } else if (campo == "Ca199") {
      this.Ca199 = valor;
    } else if (campo == "Ca125") {
      this.Ca125 = valor;
    } else if (campo == "Ca724") {
      this.Ca724 = valor;
    } else if (campo == "Cyfra21") {
      this.Cyfra21 = valor;
    } else if (campo == "Psa") {
      this.Psa = valor;
    } else if (campo == "Psalivre") {
      this.Psalivre = valor;

    }
  }


}



