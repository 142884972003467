import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-obstetricia',
  templateUrl: './obstetricia.component.html',
  styleUrls: ['./obstetricia.component.scss'],
})
export class ObstetriciaComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDia', null) ancoraDia: ElementRef;
  @ViewChild('ancoraMes', null) ancoraMes: ElementRef;
  @ViewChild('ancoraAno', null) ancoraAno: ElementRef;
  @ViewChild('ancoraSemana', null) ancoraSemana: ElementRef;

  Id: number = -1;
  Comentario: string = "";
  lancamentosCollection: any = [];
  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  obstetriciaForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number = 0;
  Calculo: number = 41;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Renals: string = "";
  itensColection: any = [];
  Valorminimo: any;
  Valormaximo: any;
  Sistema: number = 0;
  Leito: number;
  constructor(private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController
  ) {
    this.obstetriciaForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Dum: ['', ''],
      Dpp: ['', ''],
      Due: ['', ''],
      Semana: ['', ''],
      Dbp: ['', ''],
      Dof: ['', ''],
      Ic: ['', ''],
      Comentario: ['', ''],
      Dia: ['', ''],
      Mes: ['', ''],
      Ano: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }

  ngOnInit() {
    this.testaAcessoSistema();
  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })
  }

  async selecionarDadosCalculoUnico() {
    let loading = await this.loadingController.create(
      {
        message: "Selecionando od dados...",
        mode: 'ios'
      }
    );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
      .then((response: any) => {
        this.Sigla = response[0].Sigla;
        this.Nome = response[0].Nome
        this.Componente = response[0].Componente
        this.Valornormal = response[0].Valornormal;
        this.Descricaounidade = response[0].Descricaounidade
        this.Descricaocalculo = response[0].Descricaocalculo
        this.Observacao = response[0].Observacao
        this.Valorminimo = response[0].Valorminimo
        this.Valormaximo = response[0].Valormaximo


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona as calculadoreas')
      }).finally(() => {
        loading.dismiss();
      });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();


    }
  }

  async limpaDados() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.obstetriciaForm.controls['Id'].setValue(-1);
    this.obstetriciaForm.controls['Datalancamento'].setValue(Datalancamento);
    this.obstetriciaForm.controls['Dia'].setValue('');
    this.obstetriciaForm.controls['Mes'].setValue('');
    this.obstetriciaForm.controls['Ano'].setValue('');
    this.obstetriciaForm.controls['Dum'].setValue('');
    this.utilService.focusInput(this.ancoraDia);


  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento", null)
    });
  }

  async closeModal() {
    this.modalController.dismiss();
  }

  async ajustaData() {

    let Dia: any = this.obstetriciaForm.value.Dia;
    let Mes: any = this.obstetriciaForm.value.Mes;
    let Ano: any = this.obstetriciaForm.value.Ano;
    let lRet: any = true


    if (Dia == '' || Dia == undefined || Dia == null) {
      this.utilService.showAlertCallback("Dia não informado", () => {
        this.utilService.focusInput(this.ancoraDia);

      })
      return;
    }

    if (Mes == '' || Mes == undefined || Mes == null) {
      this.utilService.showAlertCallback("Mês não informado", () => {
        this.utilService.focusInput(this.ancoraMes);

      })
      return;
    }

    if (Ano == '' || Ano == undefined || Ano == null) {
      this.utilService.showAlertCallback("Ano não informado", () => {
        this.utilService.focusInput(this.ancoraAno);

      })
      return;
    }

    if (Dia > 31 || Dia < 1) {
      this.utilService.showAlertCallback("Dia inválido", () => {
        this.utilService.focusInput(this.ancoraDia);

      })
      return;
    }

    if (Mes > 12 || Mes < 1) {
      this.utilService.showAlertCallback("Mês inválido", () => {
        this.utilService.focusInput(this.ancoraMes);

      })
      return;
    }

    if (Dia < 10) {
      Dia = "0" + Dia;
    }

    if (Mes < 10) {
      Mes = "0" + Mes;
    }

    let data: any = new Date();
    let ano: any = data.getFullYear();

    if (Ano > ano) {
      this.utilService.showAlertCallback("Ano inválido ou maior que " + ano, () => {
        this.utilService.focusInput(this.ancoraAno);
      })

      return;
    }

    let Dum:any = Ano + "-" + Mes + "-" + Dia
    this.obstetriciaForm.controls['Dum'].setValue(Dum);
    let dataHoje:any  = this.utilService.dataHojeSemHora();
    let DumDt:any = new Date(Dum);
    dataHoje= new Date(dataHoje);
    let diffInMs   = dataHoje - DumDt;
    let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    let diffInWeek = diffInDays/7;
    diffInWeek = this.utilService.formateNumberNew(diffInWeek,2,1);
    let vet:any = diffInWeek.toString();
    let vet1:any;
    let vet0:any;
    if (vet.indexOf('.') > -1) {
      vet = vet.split('.');
      vet1 = vet[1];
      vet1 = parseInt(vet1);
      vet1 = (vet1*7)/100
      vet1 = parseInt(vet1);
      vet0 =  vet[0];
      vet = vet0.toString() + ' semanas e ' + vet1 + ' dias'
      this.Valor = vet

    }
   
    this.obstetriciaForm.controls['Semana'].setValue(diffInWeek);
    let Semanas:any = 40 - diffInWeek;
    let Dpp= this.utilService.somaDiaData(Dum, 280);
    this.obstetriciaForm.controls['Dpp'].setValue(Dpp);

  }

  async limparCampo(campo: string) {

    this.obstetriciaForm.controls[campo].setValue('');
    if (campo == "Semana") {
      this.utilService.focusInput(this.ancoraSemana);
    }



    if (campo == "Dum") {
      this.obstetriciaForm.controls["Dia"].setValue('');
      this.obstetriciaForm.controls["Mes"].setValue('');
      this.obstetriciaForm.controls["Ano"].setValue('');
      this.utilService.focusInput(this.ancoraDia);

    }


  }

}
