import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {

  constructor(
    private http: HttpClient, 
    private utilService: UtilService,
    private loadingController: LoadingController
    ) { }

 
 // pacientes

   adicionarPaciente(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/adicionarPaciente';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarPaciente(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarPaciente';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  

  selecionarPacienteUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarPacienteUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarInternacaoPaciente(Paciente:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarInternacaoPaciente/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarPacienteUsuario(Usuario:number, Busca:string) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarPacienteUsuario/' + Usuario +'/' + Busca;
    return this.http.get(url).toPromise();
  }

  //// internacao

  SelecionarInternacaoHospital(Hospital:number, Busca:string) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/SelecionarInternacaoHospital/' + Hospital +'/' + Busca;
    return this.http.get(url).toPromise();
  }

  selecionarInternacaoHospitalUsuarioTipo(Hospital:number, Usuario:number, Ambulatorio:number, Busca:string) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarInternacaoHospitalUsuarioTipo/' + Hospital +'/' + Usuario +'/' + Ambulatorio +'/' + Busca;
    return this.http.get(url).toPromise();
  }

  adicionarInternacao(form: any) {
    console.log(JSON.stringify(form));
    let url = this.utilService.obterUrlDaApi() + 'Paciente/adicionarInternacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }


  atualizarInternacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarInternacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  selecionarInternacaoUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarInternacaoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarInternacaoUsuario(Usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarInternacaoUsuario/' + Usuario;
    return this.http.get(url).toPromise();
  }
  
  //// saps3

  adicionarSaps3(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/adicionarSaps3';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }


  atualizarSaps3(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarSaps3';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  

  selecionarSaps3Unico(Internacao:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarSaps3Unico/' + Internacao;
    return this.http.get(url).toPromise();
  }

  selecionarSaps3Usuario(Usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarSaps3Usuario/' + Usuario;
    return this.http.get(url).toPromise();
  }

  
  
  atualizarInternacaoComorbidade(form: any) {
    //console.log("Form: " + JSON.stringify(form))
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarInternacaoComorbidade';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarInternacaoAdmissao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarInternacaoAdmissao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  
  // comorbidade

  selecionarComorbidade() {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarComorbidade' ;
    return this.http.get(url).toPromise();
  }

  // comorbidade

  async selecionarSistema() {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarSistema' ;
    return this.http.get(url).toPromise();
  
  }

  //

  // pacientes

  adicionarAlta(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/adicionarAlta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarAlta(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/atualizarAlta';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  

  selecionarAltaInternacao(Internacao:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarAltaInternacao/' + Internacao;
    return this.http.get(url).toPromise();
  }

  deletarAltaInternacao(Internacao:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/deletarAltaInternacao/' + Internacao;
    return this.http.get(url).toPromise();
  }

  selecionarAltaUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarAltaUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarAltaUsuario(Usuario:number) {
    let url = this.utilService.obterUrlDaApi() + 'Paciente/selecionarAltaUsuario/' + Usuario;
    return this.http.get(url).toPromise();
  }
 
 

}

