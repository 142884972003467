import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, NavParams } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-termodeuso',
  templateUrl: './termodeuso.component.html',
  styleUrls: ['./termodeuso.component.scss'],
})
export class TermodeusoComponent implements OnInit {
  Usuario:number=0;
  constructor(
    private modalController:ModalController,
    private checklistService:ChecklistService,
    private alertController:AlertController,
    private utilService:UtilService



  ) { }

  ngOnInit() {}


  async closeModal() {

    this.modalController.dismiss();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

}
