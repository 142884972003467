import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-sofa',
  templateUrl: './sofa.component.html',
  styleUrls: ['./sofa.component.scss'],
})
export class SofaComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  Id: number = -1;
  Comentario:string="";
  lancamentosCollection: any = [];
  Neurologico: number = 0;
  Cardiaco: number = 0;
  Respiratorio: number = 0;
  Digestivo: number = 0;
  Renal: number = 0;
  Coagulacao: number = 0;
  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  sofaForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number=0;
  Calculo: number=35;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Renals: string = "";
  itensColection: any = [];
  Valorminimo: any;
  Valormaximo: any;
  Sistema:number=0;
  Leito:number;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController:LoadingController
  ) {
    this.sofaForm = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Neurologico: ['', ''],
      Cardiaco: ['', ''],
      Respiratorio: ['', ''],
      Digestivo: ['', ''],
      Renal: ['', ''],
      Coagulacao: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Sexo = parseInt(this.navParams.get('Sexo'));
  


  }
  ngOnInit() {
  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico();
    this.testaAcessoSistema();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível seleciona as calculadoreas')
    }).finally(() => {
      loading.dismiss();
    });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }


 





  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularSofa() {
    let Neurologico: number = this.sofaForm.value.Neurologico;
    if (Neurologico == null || Neurologico == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de NEUROLÓGICO", null)
      return;

    }
    this.Neurologico = Neurologico;

    let Cardiaco: number = this.sofaForm.value.Cardiaco;
    if (Cardiaco == null || Cardiaco == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CARDÍACO", null)
      return;

    }
    this.Cardiaco = Cardiaco;

    let Respiratorio: number = this.sofaForm.value.Respiratorio;
    if (Respiratorio == null || Respiratorio == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de RESPIRATORIO", null)
      return;
    }
    this.Respiratorio = Respiratorio;

    let Digestivo: number = this.sofaForm.value.Digestivo;
    if (Digestivo == null || Digestivo == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de DIGESTIVO P/F", null)
      return;
    }
    this.Digestivo = Digestivo;

    let Renal: number = this.sofaForm.value.Renal;
    if (Renal == null || Renal == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de RENAL", null)
      return;

    }
    this.Renal = Renal;

    let Coagulacao: number = this.sofaForm.value.Coagulacao;
    if (Coagulacao == null || Coagulacao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de COAGULAÇÃO", null)
      return;

    }
    this.Coagulacao = Coagulacao;
    this.Valor = this.Neurologico + this.Cardiaco + this.Respiratorio + this.Digestivo + this.Renal + this.Coagulacao
    let Range:string= ""
    if (this.Valor <=1) {
      Range= " (MORTALIDADE DE 0 %)"
    }else if (this.Valor >= 2 && this.Valor <= 3) {
      Range= " (MORTALIDADE DE 1,5 %)"
    }else if (this.Valor >= 4 && this.Valor <= 5) {
      Range= " (MORTALIDADE DE 6,7 %)"
    }else if (this.Valor >= 6 && this.Valor <= 7) {
      Range= " (MORTALIDADE DE 18,2 %)"
    }else if (this.Valor >= 8 && this.Valor <= 9) {
      Range= " (MORTALIDADE DE 26,3 %)"
    }else if (this.Valor >= 10 && this.Valor <= 11) {
      Range= " (MORTALIDADE DE 45,8 %)"
    }else if (this.Valor >= 12 && this.Valor <= 14) {
      Range= " (MORTALIDADE DE 80 %)"
    }else if (this.Valor >= 15) {
      Range= " (MORTALIDADE DE 89,75 %)"
    }
    this.Valor = this.utilService.formateNumber(this.Valor, 2);
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }

    if (this.Leito > 0) {
      let Sofalista=[{
        Leito: this.Leito,
        Neurologico: this.Neurologico,
        Cardiaco:this.Cardiaco,
        Respiratorio: this.Respiratorio,
        Digestivo: this.Digestivo,
        Renal: this.Renal,
        Coagulacao: this.Coagulacao,
        Pontos:  this.Valor,
        Mortalidade: Range
      }]
      this.utilService.publishEvent("Sofalista", Sofalista);
      this.closeModal();
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja INCLUIR esses dados para ' + this.Nome
    let Id: number = this.sofaForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];


    Relacao = Relacao + this.Neurologico + '*';
    Relacao = Relacao + this.Cardiaco + '*';
    Relacao = Relacao + this.Respiratorio + '*';
    Relacao = Relacao + this.Digestivo + '*'; //p/f
    Relacao = Relacao + this.Renal + '*';
    Relacao = Relacao + this.Coagulacao;
    let Datalancamento:any = this.sofaForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.sofaForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.sofaForm.value.Comentario

    }

    if (this.sofaForm.value.Id == null || this.sofaForm.value.Id == undefined ||
       this.sofaForm.value.Id == -1 ) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showToastCallBack(this.Nome + " INCLUIDO com sucesso", 2000, null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.sofaForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível ADICIONAR o " + this.Nome, 2000, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ALTERADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome,  null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento",  null)
    });
  }

  async limpaDados() {
    this.Valor = 0;
    this.utilService.showToastCallBack("Tudo pronto para ADICIONAR um novo registro", 2000, () => {
    });
    this.Id= -1;
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.sofaForm.controls['Datalancamento'].setValue(dtHoje);
    this.sofaForm.controls['Neurologico'].setValue(0);
    this.sofaForm.controls['Cardiaco'].setValue(0);
    this.sofaForm.controls['Respiratorio'].setValue(0);
    this.sofaForm.controls['Digestivo'].setValue(0);
    this.sofaForm.controls['Renal'].setValue(0);
    this.sofaForm.controls['Coagulacao'].setValue(0);
    this.sofaForm.controls['Comentario'].setValue('');
    this.Botao = "CALCULAR"
    this.sofaForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }

  }

 

  async mostrarDados(response: any) {
    this.Botao = "ALTERAR";
    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Neurologico = parseInt(Relacao[0]);
    this.Cardiaco = parseInt(Relacao[1]);
    this.Respiratorio = parseInt(Relacao[2]);
    this.Digestivo = parseInt(Relacao[3]);
    this.Renal = parseInt(Relacao[4]);
    this.Coagulacao = parseInt(Relacao[5]);

    
    this.sofaForm.controls['Datalancamento'].setValue(response[0].Datalancamento);
    this.sofaForm.controls['Neurologico'].setValue(this.Neurologico);
    this.sofaForm.controls['Cardiaco'].setValue(this.Cardiaco);
    this.sofaForm.controls['Respiratorio'].setValue(this.Respiratorio);
    this.sofaForm.controls['Digestivo'].setValue(this.Digestivo);
    this.sofaForm.controls['Renal'].setValue(this.Renal);
    this.sofaForm.controls['Coagulacao'].setValue(this.Coagulacao);
    this.Comentario = response[0].Comentario;
    this.sofaForm.controls['Comentario'].setValue(this.Comentario);

  }

}

