import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-glasgow',
  templateUrl: './glasgow.component.html',
  styleUrls: ['./glasgow.component.scss'],
})
export class GlasgowComponent implements OnInit {
  dadosListMed:any
  glasgowForm: FormGroup;
  ecg_o: Array<{ resposta: string, descricao: string, pontuacao: number, grupo: string }> = [];
  ecg_v: Array<{ resposta: string, descricao: string, pontuacao: number, grupo: string }> = [];
  ecg_m: Array<{ resposta: string, descricao: string, pontuacao: number, grupo: string }> = [];
  icone: string = 'thumbs-up'
  Internacao: number = 0;
  Paciente: number = 0;
  Usuario: number = 0;
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  aEcg: any = []
  Sigla: string = '';
  Nome: string = '';
  Componente: string = '';
  Valornormal: string = '';
  Descricaounidade: string = '';
  Descricaocalculo: string = '';
  Observacao: any;
  aObservacao:any=[];
  Valormaximo:string='';
  Valorminimo:string='';
  Sexo:number=0;
  Sistema:number=0;
  Logado:number=0;
  lancamentosCollection:any;
  Botao:string=""
  Id:number=0;
  Valor:number=0;
  Datalancamento:string=""
  Comentario:string="";
  Ocular:number=0;
  Verbal:number=0;
  Motora:number=0;
  Calculo:number=0;
  Campo:number=-1
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private loadingController:LoadingController,
    private checklistService:ChecklistService,
    private alertController:AlertController
  ) {
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Sistema = this.navParams.get('Sistema');
    this.Campo = this.navParams.get('Campo');

    let resposta = '';
    let pontuacao = 0;
    let descricao = '';
    let grupo = '';
    for (let i = 1; i < 16; i++) {
      pontuacao++
      descricao = ''
      if (i == 1) {
        resposta = "OCULAR";
        descricao = 'Não abre os olhos'
        grupo = 'o';
      } else if (i == 2) {
        resposta = "";
        descricao = 'Abre os olhos em resposta a estímulo de dor'
      } else if (i == 3) {
        resposta = "";
        descricao = 'Abre os olhos em resposta a um chamado'
        grupo = 'o';
      } else if (i == 4) {
        resposta = "";
        descricao = 'Abre os olhos espontaneamente'
        grupo = 'o';
      } else if (i == 5) {
        pontuacao = 1;
        resposta = "VERBAL";
        descricao = 'Emudecido'
        grupo = 'v';
      } else if (i == 6) {
        resposta = "";
        descricao = 'Emite sons incompreensíveis'
      } else if (i == 7) {
        resposta = "";
        descricao = 'Pronuncia palavras desconexas'
        grupo = 'v';
      } else if (i == 8) {
        resposta = "";
        descricao = 'Palavras confusas, desorientado'
        grupo = 'v';
      } else if (i == 9) {
        resposta = "";
        descricao = 'Orientado, conversa normalmente'
        grupo = 'v';
      } else if (i == 10) {
        pontuacao = 1;
        resposta = "MOTORA";
        descricao = 'Não se movimenta'
        grupo = 'm';
      } else if (i == 11) {
        resposta = "";
        descricao = 'Extensão a estímulos dolorosos (descerebração)';
        grupo = 'm';
      } else if (i == 12) {
        resposta = "";
        descricao = 'Flexão anormal a estímulos dolorosos (decorticação)';
        grupo = 'm';
      } else if (i == 13) {
        resposta = "";
        descricao = 'Flexão inespecífica (normal)/ Reflexo de retirada a estímulos dolorosos';
        grupo = 'm';
      } else if (i == 14) {
        resposta = "";
        descricao = 'Localiza estímulos dolorosos';
        grupo = 'm';
      } else if (i == 15) {
        resposta = "";
        descricao = 'Obedece a comandos';
        grupo = 'm';
      }

      if (grupo == 'o') {
        this.ecg_o.push({
          resposta: resposta,
          descricao: descricao,
          pontuacao: pontuacao,
          grupo: grupo
        })
      }

      if (grupo == 'v') {
        this.ecg_v.push({
          resposta: resposta,
          descricao: descricao,
          pontuacao: pontuacao,
          grupo: grupo
        })
      }
      if (grupo == 'm') {
        this.ecg_m.push({
          resposta: resposta,
          descricao: descricao,
          pontuacao: pontuacao,
          grupo: grupo
        })
      }

      this.glasgowForm = formBuilder.group({
        Id: ['', ''],
        Datalancamento: ['', ''],
        Ocular: ['', ''],
        Verbal: ['', ''],
        Motora: ['', ''],
        Comentario: ['', ''],
        Observacao: ['', ''],
      
      })
    }
  }

  ngOnInit() {
    this.Calculo= 26;

  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível seleciona os dados da calculadora')
    }).finally(() => {
      loading.dismiss();
    });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }

  async closeModal() {

    this.modalController.dismiss();
  }

  async limpaDados() {
    this.Id= -1;
    this.Valor = 0;

    let dtHoje = this.utilService.dataHoje();
    let Datalancamento= this.utilService.checaData(dtHoje);
    this.glasgowForm.controls['Ocular'].setValue(4);
    this.glasgowForm.controls['Verbal'].setValue(5);
    this.glasgowForm.controls['Motora'].setValue(6);
    this.Botao = "CALCULAR"
    this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, () => {
    });
    this.glasgowForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }
    this.Datalancamento = Datalancamento;
    this.glasgowForm.controls['Datalancamento'].setValue(Datalancamento);


  }
  async calcularGlasgow() {
    this.Ocular = this.glasgowForm.value.Ocular;
    if (this.Ocular == null || this.Ocular == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de ABERTURA OCULAR", null)
      return;

    }
    this.Verbal = this.glasgowForm.value.Verbal;

    if (this.Verbal == null || this.Verbal == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de RESPOSTA VERBAL", null)
      return;

    }
    this.Motora = this.glasgowForm.value.Motora;

    if (this.Motora == null || this.Motora == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de RESPOSTA MOTORA", null)
      return;

    }

    this.Valor = this.Ocular + this.Verbal + this.Motora;
    //this.utilService.showAlertCallback("Escala de Coma de Glasgow: " + this.vecg_t.toString(), null)
    if (this.aEcg != null) {
      for (let i = 0; i < this.aEcg.length; i++) {
        if (parseInt(this.aEcg[i].Valor) == this.Valor) {
        
          break;
        }
      }
    }

    let ObsGlasow = "AO:" + this.Ocular + "/RV:" + this.Verbal +  "/RM:" +  this.Motora;
    this.utilService.publishEvent("Glasgow", this.Valor);
    this.utilService.publishEvent("ObsGlasgow", ObsGlasow);


    let Range: string = " (NORMAL > 12 PONTOS)"
    if (this.Valor >9 &&  this.Valor < 13) {
      Range= " (REBAIXAMENTO DO NÍVEL DE CONSCIÊNCIA)"
    }

    if (this.Valor <= 9) {
      Range= " (REBAIXAMENTO DO NÍVEL DE CONSCIÊNCIA SEM PROTEÇÃO DE VIAS AÉREAS)"
    }

    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = '';
    }

    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      
     
      this.presentAlertConfirm()
    }
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja ADICIONAR esses dados para ' + this.Nome
    let Id: number = this.glasgowForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ATUALIZAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];


    Relacao = Relacao + this.Ocular + '*';
    Relacao = Relacao + this.Verbal + '*';
    Relacao = Relacao + this.Motora;
    let Datalancamento:any = this.glasgowForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.glasgowForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.glasgowForm.value.Comentario

    }

   
    if (this.glasgowForm.value.Id == -1 || this.glasgowForm.value.Id == undefined ||
      this.glasgowForm.value.Id == null ) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso",  null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.glasgowForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome,  null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome,  null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento",  null)
    });
  }

  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);

    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Ocular = parseInt(Relacao[0]);
    this.Verbal = parseInt(Relacao[1]);
    this.Motora = parseInt(Relacao[2]);
    this.glasgowForm.controls['Datalancamento'].setValue(Datalancamento);
    this.glasgowForm.controls['Ocular'].setValue(this.Ocular);
    this.glasgowForm.controls['Verbal'].setValue(this.Verbal);
    this.glasgowForm.controls['Motora'].setValue(this.Motora);
    this.Comentario = response[0].Comentario;
    this.glasgowForm.controls['Comentario'].setValue(this.Comentario);

  }


}
