import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, LoadingController, NavParams } from '@ionic/angular';
import { PacienteService } from '../../services/paciente.service';
import { UtilService } from '../../services/util.service';
import { ChecklistService } from '../../services/checklist.service';
@Component({
  selector: 'app-fotopaciente',
  templateUrl: './fotopaciente.component.html',
  styleUrls: ['./fotopaciente.component.scss'],
})
export class FotopacienteComponent implements OnInit {
  Internacao: number = 0;
  NomePaciente: string = "";
  Foto: string = '';
  Idade: string = '';
  Peso: number = 0;
  Altura: number = 0;
  Sexo: any;
  Pesopredito: number = 0;
  Volemia: number = 0;
  Bsa: number = 0;
  Imc: number = 0;
  PaO2Ideal: number = 0;
  VCPesopreditoMax: number = 0;
  VCPesopreditoMim: number = 0;
  PeMax: any;
  PiMax: any;
  Usuario:number=0;
  constructor(private modalController: ModalController,
    private navParams: NavParams,
    private loadingController: LoadingController,
    private pacienteService: PacienteService,
    private utilService: UtilService,
    private alertController:AlertController,
    private checklistService:ChecklistService
) {
    this.Internacao = parseInt(this.navParams.get('Internacao'));

  }

  ngOnInit() {

    this.SelecionarInternacaoUnica();

  }

  async closeModal() {

    this.modalController.dismiss();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async SelecionarInternacaoUnica() {

    let loading = await this.loadingController.create({
      message: 'Selecionando dados do paciente internado...',
    });

    loading.present();
    this.pacienteService.selecionarInternacaoUnico(this.Internacao).then((response: any) => {

      this.utilService.Descriptografar(response[0].NomePaciente)
        .then((nP: string) => {
          this.NomePaciente = nP
        })

      this.Foto = this.utilService.obterUrlDaImagemDaApi() + response[0].Imagem;
      this.Peso = response[0].Peso;
      this.Altura = response[0].Altura;
      this.Sexo = response[0].Sexo;
      if (this.Sexo == 1) {
        this.Pesopredito = response[0].PesoIdealMasculino;
        this.Volemia = response[0].VolemiaMasculino;
        this.PeMax = 0.81 * (response[0].Idade + 165.3); 
        this.PiMax = 0.80 * (response[0].Idade + 155.3);
      } else {
        this.Pesopredito = response[0].PesoIdealFeminino;
        this.Volemia = response[0].VolemiaFeminino;
        this.PeMax = 0.61 * (response[0].Idade + 115.6);
        this.PiMax = 0.49 * (response[0].Idade + 110.4);
      }

      this.PeMax = this.PeMax.toString();
      this.PeMax = this.PeMax.split('.');
      this.PeMax = this.PeMax[0];

      this.PiMax = this.PiMax.toString();
      this.PiMax = this.PiMax.split('.');
      this.PiMax = this.PiMax[0];

      // Pressão Inspiratória Máxima – (PiMáx)

      // Homens = 0, 80 x(idade) + 155, 3
      // Mulheres = 0, 49 x(idade) + 110, 4

      // Pressão Expiratória Máxima – (PeMáx)

      // Homens = 0, 81 x(idade) + 165, 3
      // Mulheres = 0, 61 x(idade) + 115, 6
      // capacidade vital
      // Homens: CV = 0,05211 – 0,022 x idade – 3,60 x altura (cm)

      // Mulheres: CV = 0,4111 – 0,018 x idade – 2,69 x altura (cm)

      this.Imc = response[0].Imc;
      this.Bsa = response[0].Bsa;
      this.PaO2Ideal = response[0].PaO2Ideal;
      this.VCPesopreditoMim = parseFloat(this.Pesopredito.toString()) * 4
      this.VCPesopreditoMax = parseFloat(this.Pesopredito.toString()) * 6


    }).catch((response) => {
      this.utilService.showToastCallBack("Não foi possível selecionar os pacientes", 2000, null);
    });

    loading.dismiss();

  } // fim do pega usuário único

}
