import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-hemograma',
  templateUrl: './hemograma.component.html',
  styleUrls: ['./hemograma.component.scss'],
})
export class HemogramaComponent implements OnInit {
  @ViewChild('ancoraIdhemograma', null) ancoraIdhemograma: ElementRef;
  @ViewChild('ancoraDatalancamentohemograma', null) ancoraDatalancamentohemograma: ElementRef;
  @ViewChild('ancoraHemacias', null) ancoraHemacias: ElementRef;
  @ViewChild('ancoraHemoglobina', null) ancoraHemoglobina: ElementRef;
  @ViewChild('ancoraHematocrito', null) ancoraHematocrito: ElementRef;
  @ViewChild('ancoraVcm', null) ancoraVcm: ElementRef;
  @ViewChild('ancoraHcm', null) ancoraHcm: ElementRef;
  @ViewChild('ancoraChcm', null) ancoraChcm: ElementRef;
  @ViewChild('ancoraRdw', null) ancoraRdw: ElementRef;
  @ViewChild('ancoraLeucocitos', null) ancoraLeucocitos: ElementRef;
  @ViewChild('ancoraMielocitos', null) ancoraMielocitos: ElementRef;
  @ViewChild('ancoraMetamielocitos', null) ancoraMetamielocitos: ElementRef;
  @ViewChild('ancoraBastonetes', null) ancoraBastonetes: ElementRef;
  @ViewChild('ancoraSegmentados', null) ancoraSegmentados: ElementRef;
  @ViewChild('ancoraEosinofilos', null) ancoraEosinofilos: ElementRef;
  @ViewChild('ancoraLinfocitos', null) ancoraLinfocitos: ElementRef;
  @ViewChild('ancoraLinfocitosatipicos', null) ancoraLinfocitosatipicos: ElementRef;
  @ViewChild('ancoraMonocitos', null) ancoraMonocitos: ElementRef;
  @ViewChild('ancoraBasofilos', null) ancoraBasofilos: ElementRef;
  @ViewChild('ancoraPlaquetas', null) ancoraPlaquetas: ElementRef;
 
  private IdGeral: number = 0;
  private Exames: any;
  private Datalancamentohemograma: string = "";
  private Usuario: number = 0;
  private Paciente: number = 0;
  private Idade: string = "";
  private Nome: string = "";
  private Internacao: number = 0;
  private Historiaclinica: string = "";
  private Setor: string = "";
  private Leito: string = "";
  public hemogramaForm: FormGroup;
  public Botaohemograma: string = "Cadastrar";
  private Datainternacao: string = "";
  private Dias: string = "";
  private hemogramaCollection: any = [];
  public dadosListMed: any;
  private Logado: number = 0;
  public Hemacias: number = 0;
  public Tempocoagulacao: number = 0;
  public Hemoglobina: number = 0;
  public Hematocrito: number = 0;
  public Vcm: number = 0;
  public Hcm:number=0;
  public Chcm: number = 0;
  public Rdw: number = 0;
  public Leucocitos: number = 0;
  public Mielocitos: number = 0;
  public Metamielocitos: number = 0;
  public Bastonetes: number = 0;
  public Segmentados: number = 0;
  public Eosinofilos: number = 0;
  public Linfocitos: number = 0;
  public Linfocitosatipicos: number = 0;
  public Monocitos: number = 0;
  public Basofilos: number = 0;
  public Plaquetas: number = 0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService: LaboratorioService,
    private alertController: AlertController,
  ) {

    this.hemogramaForm = formBuilder.group({

      Idhemograma: ['', ''],
      Datalancamentohemograma: ['', ''],
      Hemacias: ['', ''],
      Hemoglobina: ['', ''],
      Hematocrito: ['', ''],
      Vcm: ['', ''],
      Hcm: ['', ''],
      Chcm: ['', ''],
      Rdw: ['', ''],
      Leucocitos: ['', ''],
      Mielocitos: ['', ''],
      Metamielocitos: ['', ''],
      Bastonetes: ['', ''],
      Segmentados:['', ''],
      Eosinofilos: ['', ''],
      Linfocitos: ['', ''],
      Linfocitosatipicos:['', ''],
      Monocitos:['', ''],
      Basofilos: ['', ''],
      Plaquetas: ['', ''],
     
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }

  ngOnInit() {
    this.selecionaDadosGerais();
    this.selecionarHemograma(0);
    this.selecionarExames()
  }

  async selecionaDadosGerais() {
    this.Internacao = this.dadosListMed.Internacao.Id;
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.IdGeral = 0;
    this.limpaDadosHemograma();

  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: number = eval('this.hemogramaForm.value.' + this.Exames[i].nome);
      if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
          parametro = this.Exames[i].nome;
        }
        // this.utilService.showToastCallBack('Dado incorreto em ' + this.Exames[i].msg , 2000, null)
        this.hemogramaForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + ": " + valor + ' (' + this.Exames[i].vmin + '-' + this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
      }
    }

    let Hemacias:any= this.utilService.formateNumber(this.hemogramaForm.value.Hemacias,2); 
    let Hemoglobina:any=this.utilService.formateNumber(this.hemogramaForm.value.Hemoglobina,2);
    let Hematocrito:any= this.utilService.formateNumber(this.hemogramaForm.value.Hematocrito,2);
    let Vcm:any=this.utilService.formateNumber(this.hemogramaForm.value.Vcm,2);
    let Hcm:any= this.utilService.formateNumber(this.hemogramaForm.value.Hcm,2);
    let Chcm:any=this.utilService.formateNumber(this.hemogramaForm.value.Chcm,2);
    let Rdw:any= this.utilService.formateNumber(this.hemogramaForm.value.Rdw,2);
    let Leucocitos:any= this.utilService.formateNumber(this.hemogramaForm.value.Leucocitos,2);
    let Mielocitos:any= this.utilService.formateNumber(this.hemogramaForm.value.Mielocitos,2);
    let Metamielocitos:any= this.utilService.formateNumber(this.hemogramaForm.value.Metamielocitos,2);
    let Bastonetes:any= this.utilService.formateNumber(this.hemogramaForm.value.Bastonetes,2);
    let Segmentados:any=this.utilService.formateNumber(this.hemogramaForm.value.Segmentados,2);
    let Eosinofilos:any= this.utilService.formateNumber(this.hemogramaForm.value.Eosinofilos,2);
    let Linfocitos:any= this.utilService.formateNumber(this.hemogramaForm.value.Linfocitos,2);
    let Linfocitosatipicos:any=this.utilService.formateNumber(this.hemogramaForm.value.Linfocitosatipicos,2);
    let Monocitos:any=this.utilService.formateNumber(this.hemogramaForm.value.Monocitos,2);
    let Basofilos:any= this.utilService.formateNumber(this.hemogramaForm.value.Basofilos,2);
    let Plaquetas:any= this.utilService.formateNumber(this.hemogramaForm.value.Plaquetas,2);
  
    let form:any;

       form = {
        Id: this.hemogramaForm.value.Idhemograma,
        Internacao: this.Internacao,
        Paciente: this.Paciente,
        Datalancamento: new Date(this.hemogramaForm.value.Datalancamentohemograma),
        Usuario: this.Usuario,
        Hemacias: parseFloat(Hemacias),
        Hemoglobina: parseFloat(Hemoglobina),
        Hematocrito: parseFloat(Hematocrito),
        Vcm: parseFloat(Vcm),
        Hcm: parseFloat(Hcm),
        Chcm: parseFloat(Chcm),
        Rdw: parseFloat(Rdw),
        Leucocitos: parseFloat(Leucocitos),
        Mielocitos: parseFloat(Mielocitos),
        Metamielocitos: parseFloat(Metamielocitos),
        Bastonetes: parseFloat(Bastonetes),
        Segmentados: parseFloat(Segmentados),
        Eosinofilos: parseFloat(Eosinofilos),
        Linfocitos: parseFloat(Linfocitos),
        Linfocitosatipicos: parseFloat(Linfocitosatipicos),
        Monocitos:parseFloat(Monocitos),
        Basofilos: parseFloat(Basofilos),
        Plaquetas: parseFloat(Plaquetas),
        
      }

   
   


    let tipo = parseInt(this.hemogramaForm.value.Idhemograma);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'HEMOGRAMA',
      mode: 'ios',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroHemograma(form);
          }
        }
      ]
    });

    await alert.present();
  }

  cadastroHemograma(form:any) {

    if (this.hemogramaForm.value.Idhemograma == 0 || this.hemogramaForm.value.Idhemograma == undefined || this.hemogramaForm.value.Idhemograma == null || this.hemogramaForm.value.Idhemograma == '') {
      this.laboratorioService.adicionarHemograma(form).then((response) => {
        this.selecionarHemograma(response[0].Id);
        this.utilService.showAlertCallback("Hemograma adicionado com sucesso", null);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível adicionar o Hemograma", null);

      });
    } else {
      this.laboratorioService.atualizarHemograma(form).then((response) => {
        this.utilService.showAlertCallback("Hemograma atualizado com sucesso", null);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível atualizar o Hemograma", null);

      });
    }

  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresHemograma()
      .then((response) => {
        this.Exames = response;

      })
      .catch((response) => {

        this.Exames = [];
      });
  }
  async selecionarHemogramaUnico(Id: number) {
    if (Id == 0) {
      this.limpaDadosHemograma();
      return;
    }

    this.laboratorioService.selecionarHemogramaUnico(Id).then((response) => {
      this.getBalancoHemograma(response);
    }).catch((response) => {

    });
  }

  
  async limpaDadosHemograma() {
    this.Botaohemograma = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamentohemograma = dtHoje;
    this.hemogramaForm.controls['Datalancamentohemograma'].setValue(dtHoje);
    this.hemogramaForm.controls['Hemacias'].setValue(0);
    this.hemogramaForm.controls['Hemoglobina'].setValue(0);
    this.hemogramaForm.controls['Hematocrito'].setValue(0);
    this.hemogramaForm.controls['Vcm'].setValue(0);
    this.hemogramaForm.controls['Hcm'].setValue(0);
    this.hemogramaForm.controls['Chcm'].setValue(0);
    this.hemogramaForm.controls['Rdw'].setValue(0);
    this.hemogramaForm.controls['Leucocitos'].setValue(0);
   
    this.hemogramaForm.controls['Mielocitos'].setValue(0);
    this.hemogramaForm.controls['Metamielocitos'].setValue(0);
    this.hemogramaForm.controls['Bastonetes'].setValue(0);
    this.hemogramaForm.controls['Segmentados'].setValue(0);
    this.hemogramaForm.controls['Eosinofilos'].setValue(0);
    this.hemogramaForm.controls['Linfocitos'].setValue(0);
    this.hemogramaForm.controls['Linfocitosatipicos'].setValue(0);
    this.hemogramaForm.controls['Monocitos'].setValue(0);
    this.hemogramaForm.controls['Basofilos'].setValue(0);
    this.hemogramaForm.controls['Plaquetas'].setValue(0);
    this.IdGeral = 0;
    this.utilService.focusInput(eval('this.ancoraHemacias'));
  }

  async getBalancoHemograma(response: any) {
    this.Botaohemograma = "Alterar";
    let Datalancamentohemograma = this.utilService.getDataBrowser(response[0].Datalancamento);
    this.hemogramaForm.controls['Datalancamentohemograma'].setValue(Datalancamentohemograma);
    this.hemogramaForm.controls['Hemacias'].setValue(response[0].Hemacias.replace(",", '.'));
    this.hemogramaForm.controls['Hemoglobina'].setValue(response[0].Hemoglobina.replace(",", '.'));
    this.hemogramaForm.controls['Hematocrito'].setValue(response[0].Hematocrito.replace(",", '.'));
    this.hemogramaForm.controls['Vcm'].setValue(response[0].Vcm.replace(",", '.'));
    this.hemogramaForm.controls['Hcm'].setValue(response[0].Hcm.replace(",", '.'));
    this.hemogramaForm.controls['Chcm'].setValue(response[0].Chcm.replace(",", '.'));
    this.hemogramaForm.controls['Rdw'].setValue(response[0].Rdw.replace(",", '.'));
    this.hemogramaForm.controls['Leucocitos'].setValue(response[0].Leucocitos.replace(",", '.'));
    this.hemogramaForm.controls['Mielocitos'].setValue(response[0].Mielocitos.replace(",", '.'));
    this.hemogramaForm.controls['Metamielocitos'].setValue(response[0].Metamielocitos.replace(",", '.'));
    this.hemogramaForm.controls['Bastonetes'].setValue(response[0].Bastonetes.replace(",", '.'));
    this.hemogramaForm.controls['Segmentados'].setValue(response[0].Segmentados.replace(",", '.'));
    this.hemogramaForm.controls['Eosinofilos'].setValue(response[0].Eosinofilos.replace(",", '.'));
    this.hemogramaForm.controls['Linfocitos'].setValue(response[0].Linfocitos.replace(",", '.'));
    this.hemogramaForm.controls['Linfocitosatipicos'].setValue(response[0].Linfocitosatipicos.replace(",", '.'));
    this.hemogramaForm.controls['Monocitos'].setValue(response[0].Monocitos.replace(",", '.'));
    this.hemogramaForm.controls['Basofilos'].setValue(response[0].Basofilos.replace(",", '.'));
    this.hemogramaForm.controls['Plaquetas'].setValue(response[0].Plaquetas.replace(",", '.'));

  }

  async selecionarHemograma(seek: number) {
    this.laboratorioService.selecionarHemograma(this.Internacao, this.Paciente).then((response) => {
      this.hemogramaCollection = response;
      this.IdGeral = seek;

    }).catch((response) => {

    });
  }



  async mostrarValor(campo: string) {
    let valor: any = eval('this.hemogramaForm.value.' + campo);
    if (valor == null || valor == 'NaN' || valor == NaN || valor == '') {
      this.hemogramaForm.controls[campo].setValue(valor);
    }

    if (campo == "Hemacias") {
      this.Hemacias = valor;
    } else if (campo == "Hemoglobina") {
      this.Hemoglobina = valor;
    } else if (campo == "Hcm") {
      this.Hcm = valor;
    } else if (campo == "Chcm") {
      this.Chcm = valor;
    } else if (campo == "Hematocrito") {
      this.Hematocrito = valor;
    } else if (campo == "Vcm") {
      this.Vcm = valor;
    } else if (campo == "Rdw") {
      this.Rdw = valor;
    } else if (campo == "Leucocitos") {
      this.Leucocitos = valor;
    } else if (campo == "Mielocitos") {
      this.Mielocitos = valor;
    } else if (campo == "Metamielocitos") {
      this.Metamielocitos = valor;
    } else if (campo == "Bastonetes") {
      this.Bastonetes = valor;
    } else if (campo == "Segmentados") {
      this.Segmentados = valor;
    } else if (campo == "Eosinofilos") {
      this.Eosinofilos = valor;
    } else if (campo == "Linfocitos") {
      this.Linfocitos = valor;
    } else if (campo == "Linfocitosatipicos") {
      this.Linfocitosatipicos = valor;
    } else if (campo == "Monocitos") {
      this.Monocitos = valor;
    } else if (campo == "Basofilos") {
      this.Basofilos = valor;
    } else if (campo == "Plaquetas") {
      this.Plaquetas = valor;
    }


      
  }


}


