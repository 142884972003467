import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LancamentoService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController
    ) { }


  // lancamento - Cpis

  selecionarCpis(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Cpis/selecionarCpis/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarselecionarCpisUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Cpis/selecionarselecionarCpisUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarCpis(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Cpis/adicionarCpis';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarCpis(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Cpis/atualizarCpis';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // lancamento - Balanco

  selecionarDialise(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Dialise/selecionarDialise/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarselecionarDialiseUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Dialise/selecionarselecionarDialiseUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarDialise(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Dialise/adicionarDialise';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarDialise(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Dialise/atualizarDialise';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // lancamento - Dispositivo

  selecionarDispositivo() {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/selecionarDispositivo';
    return this.http.get(url).toPromise();
  }

  selecionarDispositivoAcesso() {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/selecionarDispositivoAcesso' ;
    return this.http.get(url).toPromise();
  }

  selecionarDispositivoUso(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/selecionarDispositivoUso/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarDispositivoUsoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/selecionarDispositivoUsoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarDispositivoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/adicionarDispositivoUso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarDispositivoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Dispositivo/atualizarDispositivoUso';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

 

  // lancamento - Medicamento e drogas

  


  selecionarMedicamento() {
    let url = this.utilService.obterUrlDaApi() + 'Medicamento/selecionarMedicamento'
    return this.http.get(url).toPromise();
  }

  
  //

  selecionarMedicamentoUso(Internacao:number, Paciente:number) {
    let url = this.utilService.obterUrlDaApi() + 'Medicamento/selecionarMedicamentoUso/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarMedicamentoUsoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Medicamento/selecionarMedicamentoUsoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarMedicamentoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Medicamento/adicionarMedicamentoUso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarMedicamentoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Medicamento/atualizarMedicamentoUso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

   // lancamento - Nutric

   selecionarNutric(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Nutric/selecionarNutric/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarNutricUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Nutric/selecionarNutricUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarNutric(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Nutric/adicionarNutric';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarNutric(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Nutric/atualizarNutric';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // lancamento - Ventilacao

  selecionarVentilacao(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ventilacao/selecionarVentilacao/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarVentilacaoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ventilacao/selecionarVentilacaoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarVentilacaoUltimos(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ventilacao/selecionarVentilacaoUtimos/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  adicionarVentilacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Ventilacao/adicionarVentilacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarVentilacao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Ventilacao/atualizarVentilacao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  
 
  // lancamento - Ecocardio

  selecionarEcocardio(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ecocardio/selecionarEcocardio/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarEcocardioUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Ecocardio/selecionarEcocardioUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarEcocardio(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Ecocardio/adicionarEcocardio';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarEcocardio(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Ecocardio/atualizarEcocardio';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  
  // lancamento - Fasthug

  selecionarFasthug(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Fasthug/selecionarFasthug/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarFasthugUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Fasthug/selecionarFasthugUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarFasthug(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Fasthug/adicionarFasthug';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarFasthug(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Fasthug/atualizarFasthug';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // gasometria

 

  // lancamento - Vital

  selecionarVital(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Vital/selecionarVital/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarVitalUltimos(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Vital/selecionarVitalUltimos/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  

  selecionarVitalUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Vital/selecionarVitalUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarVital(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Vital/adicionarVital';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarVital(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Vital/atualizarVital';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }
 

 



  
}
