import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './guards/authorize-guard.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  
  { path: 'tab4', loadChildren: './pages/tab4/tab4.module#Tab4PageModule' },
  { path: 'sobre', loadChildren: './pages/sobre/sobre.module#SobrePageModule' },
  { path: 'cadastro', loadChildren: './pages/cadastro/cadastro.module#CadastroPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'fale', loadChildren: './pages/fale/fale.module#FalePageModule' },
  { path: 'cadastro', loadChildren: './pages/cadastro/cadastro.module#CadastroPageModule' },
  { path: 'foto', loadChildren: './pages/foto/foto.module#FotoPageModule' },
  { path: 'hospital/:hospital', loadChildren: './pages/hospital/hospital.module#HospitalPageModule' },
  { path: 'paciente/:paciente', loadChildren: './pages/paciente/paciente.module#PacientePageModule' },
  { path: 'pagina/:hospital', loadChildren: './pages/pagina/pagina.module#PaginaPageModule' },
  { path: 'validar', loadChildren: './pages/validar/validar.module#ValidarPageModule' },
  { path: 'internar/:internacao/:paciente', loadChildren: './pages/internar/internar.module#InternarPageModule' },
  { path: 'evolucao', loadChildren: './pages/evolucao/evolucao.module#EvolucaoPageModule' },
  { path: 'picker', loadChildren: './pages/picker/picker.module#PickerPageModule' },
  { 
    path: 'hospitaluso', 
    canActivate: [AuthorizeGuard],
    loadChildren: './pages/hospitaluso/hospitaluso.module#HospitalusoPageModule' 
  },
  { path: 'tabela', loadChildren: './pages/tabela/tabela.module#TabelaPageModule' },
  { path: 'upload-file/:tabela/:id/:coluna/:retorno', loadChildren: './pages/upload-file/upload-file.module#UploadFilePageModule' },
  { path: 'upload-file-camara/:tabela/:id/:coluna/:retorno', loadChildren: './pages/upload-file-camara/upload-file-camara.module#UploadFileCamaraPageModule' },
  { path: 'tabela-lista', loadChildren: './pages/tabela-lista/tabela-lista.module#TabelaListaPageModule' },
  { path: 'senha/:email', loadChildren: './pages/senha/senha.module#SenhaPageModule' },
  { path: 'pacienterelacao/:usuario', loadChildren: './pages/pacienterelacao/pacienterelacao.module#PacienterelacaoPageModule' },
  { path: 'calculadora/:sistema/:nomesistema', loadChildren: './pages/calculadora/calculadora.module#CalculadoraPageModule' },
  { path: 'patologia/:sistema/:nomesistema', loadChildren: './pages/patologia/patologia.module#PatologiaPageModule' },
  { path: 'privacidade', loadChildren: './pages/privacidade/privacidade.module#PrivacidadePageModule' },
  { path: 'fluxograma', loadChildren: './pages/fluxograma/fluxograma.module#FluxogramaPageModule' },
  { path: 'configuracao', loadChildren: './pages/configuracao/configuracao.module#ConfiguracaoPageModule' },
  { path: 'audio/:hospital', loadChildren: './pages/audio/audio.module#AudioPageModule' },
  { path: 'audiomidia/:audio', loadChildren: './pages/audiomidia/audiomidia.module#AudiomidiaPageModule' },
  { path: 'audiolista', loadChildren: './pages/audiolista/audiolista.module#AudiolistaPageModule' },
  { path: 'audiosistema/:sistema/:nomesistema', loadChildren: './pages/audiosistema/audiosistema.module#AudiosistemaPageModule' },
  { path: 'sofalista', loadChildren: './pages/sofalista/sofalista.module#SofalistaPageModule' },
  { path: 'telemedicina', loadChildren: './pages/telemedicina/telemedicina.module#TelemedicinaPageModule' },
  { path: 'atendimento', loadChildren: './pages/atendimento/atendimento.module#AtendimentoPageModule' },
  { path: 'neurologico', loadChildren: './pages/neurologico/neurologico.module#NeurologicoPageModule' },
  { path: 'cardiaco', loadChildren: './pages/cardiaco/cardiaco.module#CardiacoPageModule' },
  { path: 'respiratorio', loadChildren: './pages/respiratorio/respiratorio.module#RespiratorioPageModule' },
  { path: 'digestivo', loadChildren: './pages/digestivo/digestivo.module#DigestivoPageModule' },
  { path: 'renal', loadChildren: './pages/renal/renal.module#RenalPageModule' },
  { path: 'infeccao', loadChildren: './pages/infeccao/infeccao.module#InfeccaoPageModule' },
  { path: 'endocrino', loadChildren: './pages/endocrino/endocrino.module#EndocrinoPageModule' },
  { path: 'genitourinario', loadChildren: './pages/genitourinario/genitourinario.module#GenitourinarioPageModule' },
  { path: 'tegumentar', loadChildren: './pages/tegumentar/tegumentar.module#TegumentarPageModule' },
  { path: 'extremidade', loadChildren: './pages/extremidade/extremidade.module#ExtremidadePageModule' },
  { path: 'obstetricia', loadChildren: './pages/obstetricia/obstetricia.module#ObstetriciaPageModule' },
  { path: 'psicosocial', loadChildren: './pages/psicosocial/psicosocial.module#PsicosocialPageModule' },
  { path: 'hematologico', loadChildren: './pages/hematologico/hematologico.module#HematologicoPageModule' },
  { path: 'estudo', loadChildren: './pages/estudo/estudo.module#EstudoPageModule' },
  { path: 'hospitalselecao', loadChildren: './pages/hospitalselecao/hospitalselecao.module#HospitalselecaoPageModule' },
  { path: 'exame', loadChildren: './pages/exame/exame.module#ExamePageModule' },
  { path: 'evento', loadChildren: './pages/evento/evento.module#EventoPageModule' },
  { path: 'eventocadastro', loadChildren: './pages/eventocadastro/eventocadastro.module#EventocadastroPageModule' },
  { path: 'eventousuario/:evento/:validacao/:email', loadChildren: './pages/eventousuario/eventousuario.module#EventousuarioPageModule' },
  { path: 'comentario/:estudo/:codigo', loadChildren: './pages/comentario/comentario.module#ComentarioPageModule' },
  { path: 'agenda/:hospital', loadChildren: './pages/agenda/agenda.module#AgendaPageModule' },
  { path: 'eventopagamento/:id/:validacao/:email', loadChildren: './pages/eventopagamento/eventopagamento.module#EventopagamentoPageModule' },
  { path: 'dica', loadChildren: './pages/dica/dica.module#DicaPageModule' },
  { path: 'dicacadastro', loadChildren: './pages/dicacadastro/dicacadastro.module#DicacadastroPageModule' },
  { path: 'relatorio/:hospital/:exametipo', loadChildren: './pages/relatorio/relatorio.module#RelatorioPageModule' },
  { path: 'estudoescolha', loadChildren: './pages/estudoescolha/estudoescolha.module#EstudoescolhaPageModule' },
  { path: 'estudoedicao/:pergunta', loadChildren: './pages/estudoedicao/estudoedicao.module#EstudoedicaoPageModule' },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
