import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LaboratorioService } from '../../services/laboratorio.service';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.scss'],
})
export class LaboratorioComponent implements OnInit {
  private Usuario:number = 0;
  private Paciente: number = 0;
  private Idade:string = "";
  private Nome:string = "";
  private Internacao: number = 0;
  private Historiaclinica:string = "";
  private Setor:string = "";
  private Leito:any = "";
 
  private Datainternacao:string = "";
  private Dias:string = "";
  
  public dadosListMed:any;
  private exameColection: Array<{ Nome:string, Div:string }> = [];
  private Logado:number=0;
  Div:string='';
  Datalancamento:string='';
  NomePaciente:string='';
  FotoAtualPaciente:string='';
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService: LaboratorioService,
    private checklistService:ChecklistService,
    private alertController:AlertController) {
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Div = this.navParams.get('Div');
    this.Datalancamento = this.navParams.get('Dataevolucao');
    this.NomePaciente= this.navParams.get('NomePaciente');
    this.FotoAtualPaciente= this.navParams.get('FotoAtualPaciente');
  }
  ngOnInit() {
    this.exameColection = [
      { Nome: 'Bioquímica', Div: 'bioquimica' },
      { Nome: 'Coagulograma', Div: 'coagulograma' },
      { Nome: 'Fezes', Div: 'fezes' },
      { Nome: 'Hemograma', Div: 'hemograma' },
      { Nome: 'Hormônios', Div: 'hormonio' },
      { Nome: 'Imunologia', Div: 'imunologia' },
      { Nome: 'Marcadores', Div: 'marcador' },
      { Nome: 'Urina', Div: 'urina' },
     
    ]
  }


  ionViewDidEnter() {
    this.testaAcessoSistema() ;
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Idade = this.dadosListMed.Internacao.Idade;
      this.Nome =  this.dadosListMed.Internacao.Nome;
      this.Internacao = this.dadosListMed.Internacao.Id;;
      this.Setor =  (this.dadosListMed.Internacao[0].Setor);
      this.Leito =  (this.dadosListMed.Internacao[0].Leito);
      this.Datainternacao = this.dadosListMed.Internacao[0].Datainternacao;
      this.Dias = this.dadosListMed.Internacao[0].Dias;
    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pagina");
      return;

    }
  }
 

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async selecionaDiv(div:string) {
    for (let i = 0; i < this.exameColection.length; i++) {
      if (this.exameColection[i].Div == div) {
        this.utilService.mostrarDiv(this.exameColection[i].Div);
      } else {
        this.utilService.esconderDiv(this.exameColection[i].Div);
      }
    }
  }
 

}
