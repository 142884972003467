import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AntibioticoService {

  constructor(private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController,

    ) { }

    // lancamento - Antibioticos

  selecionarInfeccaoMicrobiologia() {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarInfeccaoMicrobiologia';
    return this.http.get(url).toPromise();
  }

selecionarInfeccaoCultura() {
  let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarInfeccaoCultura';
  return this.http.get(url).toPromise();
}

  selecionarAntibioticoUso(Internacao: number, Paciente: number) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarAntibioticoUso/' + Internacao + '/' + Paciente;
    return this.http.get(url).toPromise();
  }

  selecionarAntibioticoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarAntibioticoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarAntibioticoGrupo() {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarAntibioticoGrupo' ;
    return this.http.get(url).toPromise();
  }

  

  selecionarAntibioticoGrupoIndicado(grupo:number) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarAntibioticoGrupoIndicado/' + grupo ;
    return this.http.get(url).toPromise();
  }

  selecionarselecionarAntibioticoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/selecionarselecionarAntibioticoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarAntibioticoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/adicionarAntibioticouso';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarAntibioticoUso(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Antibiotico/atualizarAntibioticouso';

    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

}
