import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChecklistService } from '../../services/checklist.service';
import { PacienteService } from '../../services/paciente.service';
import { UtilpdfService } from '../../services/utilpdf.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@ionic-native/File/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

@Component({
  selector: 'app-intubacao',
  templateUrl: './intubacao.component.html',
  styleUrls: ['./intubacao.component.scss'],
})
export class IntubacaoComponent implements OnInit {
  @ViewChild('ancoraPeso', null) ancoraPeso: ElementRef;

  dadosListMed:any
  intubacaoForm: FormGroup;
  icone: string = 'thumbs-up'
  Internacao: number = 0;
  Paciente: number = 0;
  Usuario: number = 0;
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  aEcg: any = []
  Sigla: string = '';
  Nome: string = '';
  Componente: string = '';
  Valornormal: string = '';
  Descricaounidade: string = '';
  Descricaocalculo: string = '';
  Observacao: any;
  aObservacao:any=[];
  Valormaximo:string='';
  Valorminimo:string='';
  Sexo:number=0;
  Sistema:number=0;
  Logado:number=0;
  lancamentosCollection:any;
  Botao:string=""
  Id:number=0;
  Valor:number=0;
  Datalancamento:string=""
  Comentario:string="";
  Pretratamento:number=0;
  Opioide:number=0;
  Hipnotico:number=0;
  Bloqueador:number=0;
  Calculo:number=0;
  aPretratamento:any =[];
  aOpioide:any =[];
  aHipnotico:any =[];
  aBloqueador:any =[];
  Peso:number=0;
  DataHoje:string="";
  docDefinition: any;
  pdfObj = null;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private loadingController:LoadingController,
    private checklistService:ChecklistService,
    private alertController:AlertController,
    private utilpdfService: UtilpdfService,
    private file: File,
    private fileOpener: FileOpener,
    private platform: Platform,
    private clipboard: Clipboard,
    private pacienteService:PacienteService
  ) {
    
   
   
      this.intubacaoForm = formBuilder.group({
        Id: ['', ''],
        Datalancamento: ['', ''],
        Peso: ['', ''],
        Opioide: ['', ''],
        Pretratamento: ['', ''],
        Hipnotico: ['', ''],
        Bloqueador: ['', ''],
        Comentario: ['', ''],
        Observacao: ['', ''],
      
      })

      this.Peso =this.navParams.get('Peso');
      this.Sexo = parseInt(this.navParams.get('Sexo'));
      this.Sistema = this.navParams.get('Sistema');
      this.dadosListMed = this.utilService.obterDadosDoSistema();

    }

  ngOnInit() {
    this.Calculo= 46;
    this.aPretratamento.push({Nome: 'Lindocaína sem vasoconstrictor 2%' , Apresentacao: 'Frasco ampola com 10mL', Posologia: 20, Range1: 1, Range2: 2, Unidade: "mg", Inicio:"60-120" , Duracao: "60-120"}) 
    this.aOpioide.push({Nome: 'Fentanil (50mcg/mL)' , Apresentacao: 'Frasco ampola com 2/5/10mL', Posologia: 50, Range1: 1, Range2: 3, Unidade: "mcg", Inicio:"60-90" , Duracao: "30-60"}) 
    this.aOpioide.push({Nome: 'Remifentanil (2mg em pó pra preparação em 10 mL)' , Apresentacao: 'Frasco ampola 2/5mg de pó', Posologia: 200, Range1: 0.5, Range2: 1, Unidade: "mcg", Inicio:"60-90" , Duracao: "2-5"}) 

    this.aHipnotico.push({Nome: 'Etomidado (2mg/mL)' , Apresentacao: 'Frasco ampola com 10 mL', Posologia: 2, Range1: 0.3, Range2: 0.6, Unidade: "mg", Inicio:"15-45" , Duracao: "3-15"}) 
    this.aHipnotico.push({Nome: 'Cetamina (50mg/mL)' , Apresentacao: 'Frasco ampola com 2/5/10 mL', Posologia: 50, Range1: 1, Range2: 2, Unidade: "mg", Inicio:"45-60" , Duracao: "10-20"}) 
    this.aHipnotico.push({Nome: 'Midazolam (5mg/mL)' , Apresentacao: 'Frasco ampola com 1/3/5/10 mL', Posologia: 5, Range1: 0.1, Range2: 0.3, Unidade: "mg", Inicio:"30-90" , Duracao: "15-30"}) 
    this.aHipnotico.push({Nome: 'Propofol 1% (10mg/mL)' , Apresentacao: 'Frasco ampola com 5/10/50 mL', Posologia: 10, Range1: 1, Range2: 2, Unidade: "mg", Inicio:'15-45' , Duracao: "5-10"}) 
    this.aBloqueador.push({Nome: 'Succinilcolina (100 mg em pó para preparação em 10 mL)' , Apresentacao: 'Frasco ampola com 100 mg de pó', Posologia: 10, Range1: 1, Range2: 3, Unidade: "mg", Inicio:"45-60" , Duracao: "6-10"}) 
    this.aBloqueador.push({Nome: 'Rocurônio (10mg/mL)' , Apresentacao: 'Frasco ampola com 5mL', Posologia: 10, Range1: 0.6, Range2: 1.2, Unidade: "mg", Inicio:"60-75" , Duracao: "40-60"}) 
    this.aBloqueador.push({Nome: 'Pancurônop (2mg/mL)' , Apresentacao: 'Frasco ampola com 1mL', Posologia: 2, Range1: 0.04, Range2: 0.06, Unidade: "mg", Inicio:"60-75" , Duracao: "22-35"}) 
    this.aBloqueador.push({Nome: 'Cisatracúrio (2mg/mL)' , Apresentacao: 'Frasco ampola com 10 mL', Posologia: 2, Range1: 0.10, Range2: 0.30, Unidade: "mg", Inicio:"90-120" , Duracao: "15-20"}) 

  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
  }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível seleciona os dados da calculadora')
    }).finally(() => {
      loading.dismiss();
    });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }

  async closeModal() {

    this.modalController.dismiss();
  }

  async limpaDados() {
    this.Id= -1;
    this.Valor = 0;

    let dtHoje = this.utilService.dataHoje();
    let Datalancamento= this.utilService.checaData(dtHoje);
    this.intubacaoForm.controls['Pretratamento'].setValue(0);
    this.intubacaoForm.controls['Opioide'].setValue(0);
    this.intubacaoForm.controls['Hipnotico'].setValue(0);
    this.intubacaoForm.controls['Bloqueador'].setValue(0);
    this.Peso = this.utilService.formateNumberNew(this.Peso, 2)
    this.intubacaoForm.controls['Peso'].setValue(this.Peso);

    this.utilService.focusInput(this.ancoraPeso);
    this.Botao = "CALCULAR"
    this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, () => {
    });
    this.intubacaoForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }
    this.Datalancamento = Datalancamento;
    this.intubacaoForm.controls['Datalancamento'].setValue(Datalancamento);


  }
  async calcularDoses() {

    this.Peso = this.utilService.formateNumberNew(this.intubacaoForm.value.Peso,2);
    if (this.Peso == 0 || isNaN(this.Peso/1) ||this.Peso == null || this.Peso == undefined ) {
      this.utilService.showAlertCallback("Informe o peso do paciente", ()=>{
        this.utilService.focusInput(this.ancoraPeso);
      })
      return;

    }

    this.Pretratamento = this.intubacaoForm.value.Pretratamento;
    if (this.Pretratamento == null || this.Pretratamento == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de Pretratamento", null)
      return;

    }

    this.Opioide = this.intubacaoForm.value.Opioide;
    if (this.Opioide == null || this.Opioide == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de Opioide", null)
      return;

    }

    this.Hipnotico = this.intubacaoForm.value.Hipnotico;
    if (this.Hipnotico == null || this.Hipnotico == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de Hipnótico", null)
      return;

    }

    this.Bloqueador = this.intubacaoForm.value.Bloqueador;
    if (this.Bloqueador == null || this.Bloqueador == undefined ) {
      this.utilService.showAlertCallback("Escolha um ítem de Bloqueador neuromucular", null)
      return;

    }

    // // PRÉ-TRATAMENTO
  
    let Pretratamento:any= this.aPretratamento[this.Pretratamento];
    let Pretratamento_Range1:number = Pretratamento.Range1 * this.Peso;
    let Pretratamento_Range2:number = Pretratamento.Range2 * this.Peso;
    let Pretratamento_Range3:number = ((Pretratamento.Range1 + Pretratamento.Range2)/2)*this.Peso;
    
    let Pretratamento_Range1_mL:number = Pretratamento_Range1/Pretratamento.Posologia;
    let Pretratamento_Range2_mL:number = Pretratamento_Range2/Pretratamento.Posologia;
    let Pretratamento_Range3_mL:number = Pretratamento_Range3/Pretratamento.Posologia;

   
    this.Comentario =  "Peso do Paciente: " +this.Peso + "kg\n"
    this.Comentario =   this.Comentario + "======================================\n"
    this.Comentario =   this.Comentario + "Pré-tratamento: " + Pretratamento.Nome + "\n";
    this.Comentario =   this.Comentario + "Apresentação: " + Pretratamento.Apresentacao + "\n";
    this.Comentario =   this.Comentario + "Posologia: " + Pretratamento.Posologia + Pretratamento.Unidade + "/mL" + "\n";
    this.Comentario =   this.Comentario + "Range mínimo: " + Pretratamento.Range1 + " " + Pretratamento.Unidade + "/kg"+ "\n";
    this.Comentario =   this.Comentario + "Range máximo: " + Pretratamento.Range2 + " " + Pretratamento.Unidade + "/kg"+ "\n";
    this.Comentario =   this.Comentario + "Dose mínima: " + Pretratamento_Range1_mL + " mL"+ "\n"
    this.Comentario =   this.Comentario + "Dose máxima: " + Pretratamento_Range2_mL + " mL"+ "\n"
    this.Comentario =   this.Comentario + "Dose média: " + this.utilService.formateNumber(Pretratamento_Range3_mL,1) + " mL"+ "\n"
    this.Comentario =   this.Comentario + "Inicio da ação em segundos: " + Pretratamento.Inicio +"\n"
    this.Comentario =   this.Comentario + "Duração da ação em minutos: " + Pretratamento.Duracao +"\n"
    this.Comentario =  this.Comentario + "======================================\n"


    //  // OPIOIDE
  
     let Opioide:any= this.aOpioide[this.Opioide];
     let Opioide_Range1:number = Opioide.Range1 * this.Peso;
     let Opioide_Range2:number = Opioide.Range2 * this.Peso;
     let Opioide_Range3:number = ((Opioide.Range1 + Opioide.Range2)/2)*this.Peso;
     
     let Opioide_Range1_mL:number = Opioide_Range1/Opioide.Posologia;
     let Opioide_Range2_mL:number = Opioide_Range2/Opioide.Posologia;
     let Opioide_Range3_mL:number = Opioide_Range3/Opioide.Posologia;
 
    
     this.Comentario =   this.Comentario + "Opioide: " + Opioide.Nome + "\n";
     this.Comentario =   this.Comentario + "Apresentação: " + Opioide.Apresentacao + "\n";
     this.Comentario =   this.Comentario + "Posologia: " + Opioide.Posologia + Opioide.Unidade + "/mL" + "\n";
     this.Comentario =   this.Comentario + "Range mínimo: " + Opioide.Range1 + " " + Opioide.Unidade + "/kg"+ "\n";
     this.Comentario =   this.Comentario + "Range máximo: " + Opioide.Range2 + " " + Opioide.Unidade + "/kg"+ "\n";
     this.Comentario =   this.Comentario + "Dose mínima: " + Opioide_Range1_mL + " mL"+ "\n"
     this.Comentario =   this.Comentario + "Dose máxima: " + Opioide_Range2_mL + " mL"+ "\n"
     this.Comentario =   this.Comentario + "Dose média: " + this.utilService.formateNumber(Opioide_Range3_mL,1) + " mL"+ "\n"
     this.Comentario =   this.Comentario + "Inicio da ação em segundos: " + Opioide.Inicio +"\n"
     this.Comentario =   this.Comentario + "Duração da ação em minutos: " + Opioide.Duracao +"\n"
     this.Comentario =   this.Comentario + "======================================\n"

      // HIPNÓTICO
  
      let Hipnotico:any= this.aHipnotico[this.Hipnotico];
      let Hipnotico_Range1:number = Hipnotico.Range1 * this.Peso;
      let Hipnotico_Range2:number = Hipnotico.Range2 * this.Peso;
      let Hipnotico_Range3:number = ((Hipnotico.Range1 + Hipnotico.Range2)/2)*this.Peso;
      
      let Hipnotico_Range1_mL:number = Hipnotico_Range1/Hipnotico.Posologia;
      let Hipnotico_Range2_mL:number = Hipnotico_Range2/Hipnotico.Posologia;
      let Hipnotico_Range3_mL:number = Hipnotico_Range3/Hipnotico.Posologia;
  
     
      this.Comentario =   this.Comentario + "Hipnótico: " + Hipnotico.Nome + "\n";
      this.Comentario =   this.Comentario + "Apresentação: " + Hipnotico.Apresentacao + "\n";
      this.Comentario =   this.Comentario + "Posologia: " + Hipnotico.Posologia + Hipnotico.Unidade + "/mL" + "\n";
      this.Comentario =   this.Comentario + "Range mínimo: " + Hipnotico.Range1 + " " + Hipnotico.Unidade + "/kg"+ "\n";
      this.Comentario =   this.Comentario + "Range máximo: " + Hipnotico.Range2 + " " + Hipnotico.Unidade + "/kg"+ "\n";
      this.Comentario =   this.Comentario + "Dose mínima: " + Hipnotico_Range1_mL + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Dose máxima: " + Hipnotico_Range2_mL + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Dose média: " + this.utilService.formateNumber(Hipnotico_Range3_mL,1) + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Inicio da ação em segundos: " + Hipnotico.Inicio +"\n"
      this.Comentario =   this.Comentario + "Duração da ação em minutos: " + Hipnotico.Duracao +"\n"
      this.Comentario =   this.Comentario + "======================================\n"

      // BLOQUEADOR
  
      let Bloqueador:any= this.aBloqueador[this.Bloqueador];
      let Bloqueador_Range1:number = Bloqueador.Range1 * this.Peso;
      let Bloqueador_Range2:number = Bloqueador.Range2 * this.Peso;
      let Bloqueador_Range3:number = ((Bloqueador.Range1 + Bloqueador.Range2)/2)*this.Peso;
      
      let Bloqueador_Range1_mL:number = Bloqueador_Range1/Bloqueador.Posologia;
      let Bloqueador_Range2_mL:number = Bloqueador_Range2/Bloqueador.Posologia;
      let Bloqueador_Range3_mL:number = Bloqueador_Range3/Bloqueador.Posologia;
  
     
      this.Comentario =   this.Comentario + "Bloqueador: " + Bloqueador.Nome + "\n";
      this.Comentario =   this.Comentario + "Apresentação: " + Bloqueador.Apresentacao + "\n";
      this.Comentario =   this.Comentario + "Posologia: " + Bloqueador.Posologia + Bloqueador.Unidade + "/mL" + "\n";
      this.Comentario =   this.Comentario + "Range mínimo: " + Bloqueador.Range1 + " " + Bloqueador.Unidade + "/kg"+ "\n";
      this.Comentario =   this.Comentario + "Range máximo: " + Bloqueador.Range2 + " " + Bloqueador.Unidade + "/kg"+ "\n";
      this.Comentario =   this.Comentario + "Dose mínima: " + Bloqueador_Range1_mL + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Dose máxima: " + Bloqueador_Range2_mL + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Dose média: " + this.utilService.formateNumber(Bloqueador_Range3_mL,1) + " mL"+ "\n"
      this.Comentario =   this.Comentario + "Inicio da ação em segundos: " + Bloqueador.Inicio +"\n"
      this.Comentario =   this.Comentario + "Duração da ação em minutos: " + Bloqueador.Duracao +"\n"
      this.Comentario =   this.Comentario + "======================================\n"
     if (this.Logado>0 && this.Paciente>0 && this.Internacao>0) {
        this.presentAlertConfirm()
     }
    }

  async presentAlertConfirm() {
    let message: string = 'Deseja ADICIONAR esses dados para ' + this.Nome
    let Id: number = this.intubacaoForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ATUALIZAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {
    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];


    Relacao = Relacao + this.Pretratamento + '*';
    Relacao = Relacao + this.Opioide + '*';
    Relacao = Relacao + this.Hipnotico + '*';
    Relacao = Relacao + this.Bloqueador;

    let Datalancamento:any = this.intubacaoForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.intubacaoForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.intubacaoForm.value.Comentario

    }


    if (this.intubacaoForm.value.Id == -1 || this.intubacaoForm.value.Id == undefined ||
      this.intubacaoForm.value.Id == null ) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso",  null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.intubacaoForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome,  null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome,  null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento",  null)
    });
  }

  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);

    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Pretratamento = parseInt(Relacao[0]);
    this.Opioide = parseInt(Relacao[1]);
    this.Hipnotico = parseInt(Relacao[2]);
    this.Bloqueador = parseInt(Relacao[2]);
    this.intubacaoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.intubacaoForm.controls['Pretratamento'].setValue(this.Pretratamento);
    this.intubacaoForm.controls['Opioide'].setValue(this.Opioide);
    this.intubacaoForm.controls['Hipnotico'].setValue(this.Hipnotico);
    this.intubacaoForm.controls['Bloqueador'].setValue(this.Bloqueador);
    this.Comentario = response[0].Comentario;
    this.intubacaoForm.controls['Comentario'].setValue(this.Comentario);

  }

  async presentAlertConfirmPdf() {
    let message: string = 'Deseja gerar um PDF desse checklist?'
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.gerarPdfRelatorio();

          }
        }
      ]
    });
    await alert.present();
  }

  //

  

  

  
  async gerarPdfRelatorio() {
    if (this.Comentario == '') {
      this.utilService.showAlertCallback("Primeiro você precisa gerar o cálculo");
      return;
    }
    // 0-6 (zero=domingo)
    this.DataHoje = this.utilService.dataHojeBrasil();
    let aInternacao:any = this.dadosListMed.Internacao

    this.Comentario = this.Comentario + '\nAs doses sao dependente do peso e dos medicamentos a serem utilizados.\n\n'
    this.Comentario + this.Comentario + 'Recomendamos seguir os 7P da IOT:.\n\n'
    this.Comentario = this.Comentario + 'P1 - Preparação.\n'
    this.Comentario = this.Comentario + '   -> MOV (monitor, oxigênio e veia).\n'
    this.Comentario = this.Comentario + '   -> ATLAS (ambu, tubo, laringoscópio, aspirador e segintas).\n'
    this.Comentario = this.Comentario + '   -> Bougie, Guedel, máscara laringea, cabo de aspiração, materia de critotireoidostomia\n'
    this.Comentario = this.Comentario + 'P2 - Pré-oxigenação.\n'
    this.Comentario = this.Comentario + '   -> Checar fonte de O2, a Ventilador.\n'
    this.Comentario = this.Comentario + 'P3 - Pré-tratamento.\n'
    this.Comentario = this.Comentario + '   -> Lindocaína, necessidade de drogas vasoativas, volume etc\n'
    this.Comentario = this.Comentario + 'P4 - Indução e Paralisia.\n'
    this.Comentario = this.Comentario + '   -> Seringa 1 (opióide), Seringa 2 (hipnótico), Seginga 3 (Bloqueador), Seginga 4 (Solução de adrenalina 1mg/20 mL).\n'
    this.Comentario = this.Comentario + 'P5 - Posicionamento.\n' 
    this.Comentario = this.Comentario + '   -> Prévio (alinhamento do tragus com esterno e elevação da cabeceira).\n' 
    this.Comentario = this.Comentario + '   -> Após BNM (sniffing position).\n' 
    this.Comentario = this.Comentario + 'P6 - Laringiscopia e Passagem do tubo.\n'
    this.Comentario = this.Comentario + '   -> Abertura da boca, introdução do laringo, posicionar na valévula, visualizar a epiglote, cordas vocais e passar o tudo.\n'
    this.Comentario = this.Comentario + 'P7 - Cuidados Pós-intubação.\n'
    this.Comentario = this.Comentario + '   -> auscuta, ultrassonografia pulmonar, parâmetros iniciais do ventilador, fixação do tubo.\n'



    this.utilpdfService.criaDefinicaoPdfEvolucao(this.Comentario, this.DataHoje.substring(0,9), this.DataHoje.substring(10,15),  aInternacao).then((response: any[]) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.downloadPdf();
      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })
  }

  downloadPdf() {
    if (this.platform.is('cordova')) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });
        // Save the PDF to the data Directory of our App
        this.file.writeFile(this.file.dataDirectory, 'melhormente.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          this.fileOpener.open(this.file.dataDirectory + 'melhormente.pdf', 'application/pdf');
          this.utilService.showAlertCallback("PDF gerado com sucesso. Aguarde ele vai ser aberto.");
        })
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download();
      this.utilService.showAlertCallback("PDF gerado com sucesso na internet");
    }
  }



}
