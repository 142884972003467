import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { AntibioticoService } from '../../services/antibiotico.service';
import { AlertController } from '@ionic/angular';
import { ChecklistescoreComponent } from '../../componentes/checklistescore/checklistescore.component';
import { ChecklistService } from '../../services/checklist.service';
import { ActivatedRoute } from '@angular/router';
import { GraficoComponent } from '../../componentes/grafico/grafico.component';
import { DrogaComponent } from '../../componentes/droga/droga.component';
import { VitalComponent } from '../../componentes/vital/vital.component';
import { LancamentoService } from '../../services/lancamento.service';
import { VentilacaoComponent } from '../../componentes/ventilacao/ventilacao.component';
import { LaboratorioComponent } from '../../componentes/laboratorio/laboratorio.component';


@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
})
export class ChecklistComponent implements OnInit {
  @ViewChild('ancoraCampo0', null) ancoraCampo0: ElementRef;
  @ViewChild('ancoraCampo1', null) ancoraCampo1: ElementRef;
  @ViewChild('ancoraCampo2', null) ancoraCampo2: ElementRef;
  @ViewChild('ancoraCampo3', null) ancoraCampo3: ElementRef;
  @ViewChild('ancoraCampo4', null) ancoraCampo4: ElementRef;
  @ViewChild('ancoraCampo5', null) ancoraCampo5: ElementRef;
  @ViewChild('ancoraCampo6', null) ancoraCampo6: ElementRef;
  @ViewChild('ancoraCampo7', null) ancoraCampo7: ElementRef;
  @ViewChild('ancoraCampo8', null) ancoraCampo8: ElementRef;
  @ViewChild('ancoraCampo9', null) ancoraCampo9: ElementRef;
  @ViewChild('ancoraCampo10', null) ancoraCampo10: ElementRef;
  @ViewChild('ancoraCampo11', null) ancoraCampo11: ElementRef;
  @ViewChild('ancoraCampo12', null) ancoraCampo12: ElementRef;
  @ViewChild('ancoraCampo13', null) ancoraCampo13: ElementRef;
  @ViewChild('ancoraCampo14', null) ancoraCampo14: ElementRef;
  @ViewChild('ancoraCampo15', null) ancoraCampo15: ElementRef;
  @ViewChild('ancoraCampo16', null) ancoraCampo16: ElementRef;
  @ViewChild('ancoraCampo17', null) ancoraCampo17: ElementRef;
  @ViewChild('ancoraCampo18', null) ancoraCampo18: ElementRef;
  @ViewChild('ancoraCampo19', null) ancoraCampo19: ElementRef;
  @ViewChild('ancoraValor0', null) ancoraValor0: ElementRef;
  @ViewChild('ancoraValor1', null) ancoraValor1: ElementRef;
  @ViewChild('ancoraValor2', null) ancoraValor2: ElementRef;
  @ViewChild('ancoraValor3', null) ancoraValor3: ElementRef;
  @ViewChild('ancoraValor4', null) ancoraValor4: ElementRef;
  @ViewChild('ancoraValor5', null) ancoraValor5: ElementRef;
  @ViewChild('ancoraValor6', null) ancoraValor6: ElementRef;
  @ViewChild('ancoraValor7', null) ancoraValor7: ElementRef;
  @ViewChild('ancoraValor8', null) ancoraValor8: ElementRef;
  @ViewChild('ancoraValor9', null) ancoraValor9: ElementRef;
  @ViewChild('ancoraValor10', null) ancoraValor10: ElementRef;
  @ViewChild('ancoraValor11', null) ancoraValor11: ElementRef;
  @ViewChild('ancoraValor12', null) ancoraValor12: ElementRef;
  @ViewChild('ancoraValor13', null) ancoraValor13: ElementRef;
  @ViewChild('ancoraValor14', null) ancoraValor14: ElementRef;
  @ViewChild('ancoraValor15', null) ancoraValor15: ElementRef;
  @ViewChild('ancoraValor16', null) ancoraValor16: ElementRef;
  @ViewChild('ancoraValor17', null) ancoraValor17: ElementRef;
  @ViewChild('ancoraValor18', null) ancoraValor18: ElementRef;
  @ViewChild('ancoraValor19', null) ancoraValor19: ElementRef;

  checklistForm: FormGroup;
  lRet: any;
  dadosListMed: any;
  sistemaCollection: any = [];
  Sistema: number;
  checklistCollection: any = [];
  Usuario: number = 0;
  Paciente: number = 0;
  Nome: string = "";
  Internacao: number = 0;
  Botao: string = "Cadastrar";
  Checklist: number = 0;
  Datalancamento: any;
  Id: number = -1;
  lancamentosCollection: any = [];
  Peso: number = 0;
  Logado: number;
  escoreColection: any = [];
  Padrao: any = 0;
  NomePadrao: string = "";
  Posicao: number = 0;
  Tempo: number = 0;
  Minuto: number = 0;
  Segundo: number = 0;
  ContaTempo: any;
  DescricaoEscore: string = '';
  Quantidade: number = 0;
  Feito: number = 0
  Percentual: any = 0;
  NomePaciente: string = "";
  FotoAtualPaciente = "";
  Dataevolucao: string = "";
  feitosCollection: any = [];
  chColection: any = [];
  ValorPadrao: any = 0; // para os valor escoretipo 1
  drogaCollection: any = [];
  Droga: number = 0;

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private antibioticoService: AntibioticoService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private route: ActivatedRoute,
    private lancamentoService:LancamentoService,

  ) {
    this.checklistForm = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Sistema: ['', ''],
      Droga: ['', ''],
      Campo0: ['', ''],
      Campo1: ['', ''],
      Campo2: ['', ''],
      Campo3: ['', ''],
      Campo4: ['', ''],
      Campo5: ['', ''],
      Campo6: ['', ''],
      Campo7: ['', ''],
      Campo8: ['', ''],
      Campo9: ['', ''],
      Campo10: ['', ''],
      Campo11: ['', ''],
      Campo12: ['', ''],
      Campo13: ['', ''],
      Campo14: ['', ''],
      Campo15: ['', ''],
      Campo16: ['', ''],
      Campo17: ['', ''],
      Campo18: ['', ''],
      Campo19: ['', ''],
      Valor0: ['', ''],
      Valor1: ['', ''],
      Valor2: ['', ''],
      Valor3: ['', ''],
      Valor4: ['', ''],
      Valor5: ['', ''],
      Valor6: ['', ''],
      Valor7: ['', ''],
      Valor8: ['', ''],
      Valor9: ['', ''],
      Valor10: ['', ''],
      Valor11: ['', ''],
      Valor12: ['', ''],
      Valor13: ['', ''],
      Valor14: ['', ''],
      Valor15: ['', ''],
      Valor16: ['', ''],
      Valor17: ['', ''],
      Valor18: ['', ''],
      Valor19: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.NomePaciente = this.route.snapshot.paramMap.get('NomePaciente');
    this.FotoAtualPaciente = this.route.snapshot.paramMap.get('FotoAtualPaciente');
    this.Dataevolucao = this.route.snapshot.paramMap.get('Dataevolucao');
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.selecionarSistema(() => {
      this.Sistema = 1;
      this.Quantidade = this.sistemaCollection[0].Quantidade
      this.selecionarChecklist(1);
      this.selecionarDroga();

    });

    this.limpaDados()
    this.testaAcessoSistema();
    this.selecionarDataHoje();
    this.selecionarChecklistTodos();

  }

  async selecionarDroga() {
    this.checklistService.selecionarDroga().then((response: any) => {
      this.drogaCollection = response;


    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar as drogas")
    });
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarChecklistlancamento(0);
      }

    }
  }

  async closeModal() {
    if (this.Quantidade != this.feitosCollection.length) {
      this.verificaChecklist();

      return;
    }
    this.modalCtrl.dismiss();
  }
  async verificaChecklist() {
    let Sair: number = 0;
    let Id: number = 0;
    let Sistema: number = 0;
    let Nome: string = '';
    for (let i = 0; i < this.chColection.length; i++) {
      Id = this.chColection[i].Id;
      Sistema = this.chColection[i].Sistema;
      Nome = this.chColection[i].Nome;
      if (this.chColection[i].Feito == 0) {
        Sair = 1;
        break;

      }
    }

    if (Sair == 1) {
      this.utilService.showAlertCallback("Você esqueceu de informar " + Nome,
        () => {
          this.selecionarChecklist(Sistema);
          this.presentAlertSair(Id);
        })



    }
  }

  async selecionarChecklistTodos() {
    this.checklistService.selecionarChecklistTodos()
      .then((response: any) => {
        this.chColection = response;
      }).catch((response) => {
        this.utilService.showAlertCallback('Não foi possível acessar o checklist total')
      });
  }

  async presentAlertSair(Id: number) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'CHECKLIST INCOMPLETO. TOTAL: ' + this.Quantidade + ' FEITOS: ' + this.feitosCollection.length,
      message: '<strong>Você deseja realmente sair?</strong>',
      mode: 'ios',

      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.ficarNoCheckList(Id)
          }
        }, {
          text: 'Quero',
          handler: (value) => {
            this.modalCtrl.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }

  async ficarNoCheckList(Id: number) {
    let Posicao: number = 0;

    for (let z = 0; z < this.checklistCollection.length; z++) {
      if (this.checklistCollection[z].Id == Id) {
        Posicao = z;
        break;
      }
    }

    let campo = eval('this.ancoraCampo' + Posicao.toString());
    this.utilService.focusInput(campo);


  }

  async mostrarDados(response: any) {
    //let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    let Datalancamento = this.Dataevolucao;
    this.checklistForm.controls['Datalancamento'].setValue(Datalancamento);
    let Relacao: any = response[0].Relacao;
    if (Relacao == '' || Relacao == null || Relacao == undefined) {
      return;
    }
    Relacao = Relacao.split('*')
    for (let i = 0; i < 20; i++) {
      this.checklistForm.controls['Campo' + i.toString()].setValue('');

    }
  }

  exibirPesoPaciente(Peso: number) {
    this.Peso = Peso
  }

  async ShowChecklistescoreComponent(x: number, PadraoAtual: number) {
    let Id: number = this.checklistCollection[x].Id;
    let Incluir = 1;
    for (let f = 0; f < this.feitosCollection.length; f++) {
      if (this.feitosCollection[f].Id == Id && this.feitosCollection[f].Sistema == this.Sistema) {
        Incluir = 0;
        break
      }
    }
    if (Incluir == 1) {
      this.feitosCollection.push({
        Id: Id,
        Sistema: this.Sistema,
        Internacao: this.Internacao

      })


      this.Feito++;
      this.Percentual = 0;
      this.Percentual = (this.Feito * 100 / this.Quantidade).toString();
      this.Percentual = this.Percentual.split('.');
      if (this.Percentual.length > 9) {
        this.Percentual = this.Percentual[0] + ',' + this.Percentual[1].substring(0, 2);

      }

      for (let c = 0; c < this.chColection.length; c++) {
        if (this.chColection[c].Id == Id) {
          this.chColection[c].Feito = 1;
          break;
        }
      }

    }
    this.feitosCollection = this.feitosCollection.sort((n1, n2) => n1.Id - n2.Id);

    let campo: any;
    let campovalor: any;
    if (x < this.checklistCollection.length - 1) {
      campo = eval('this.ancoraCampo' + (x + 1).toString());
      if (this.checklistCollection[x + 1].Obrigatorio == 1) {
        campo = eval('this.ancoraValor' + (x + 1).toString());
      }
    } else {
      campo = eval('this.ancoraCampo0');
    }

    this.utilService.focusInput(campo);

    if (this.Tempo == 0) {
      this.contaTempo();
    }
    let Cor = "primary";
    let c: number = this.checklistCollection[x].Id;
    this.utilService.mostrarDiv('c' + c.toString());
    this.utilService.esconderDiv('h' + c.toString());
    let Escore = this.checklistCollection[x].Escore;
    let Escoretipo = this.checklistCollection[x].Escoretipo;
    let NomeEscore = this.checklistCollection[x].NomeEscore;
    let Rangeminimo = this.checklistCollection[x].Rangeminimo;
    let Rangemaximo = this.checklistCollection[x].Rangemaximo;
    let Siglamedida = this.checklistCollection[x].Siglamedida;
    let Padrao = this.checklistCollection[x].Padrao;
    let Checklist = this.checklistCollection[x].Id;
    let DescricaoEscore = this.checklistCollection[x].DescricaoEscore;


    if (Padrao == PadraoAtual) {
      this.cadastrarChecklistPadrao(Checklist, Padrao, x, NomeEscore,
        Escoretipo, Escore, Rangeminimo, Rangemaximo, DescricaoEscore, Siglamedida)
      return;
    }

    if (Escoretipo == 1) { // numerico ou infusões
      let Valor: any = eval('this.checklistForm.value.Valor' + x.toString())
      let Campo: any = eval('this.ancoraValor' + x.toString());
      if (Valor == undefined || Valor == null || Valor == '') {
        this.utilService.showAlertCallback("Informe o valor: " + NomeEscore, () => {
          this.utilService.focusInput(Campo);
        });
        return;
      }
      // roland
      this.cadastrarChecklistPadrao(Checklist, Padrao, x, NomeEscore,
        Escoretipo, Escore, Rangeminimo, Rangemaximo, DescricaoEscore, Siglamedida)
      return;
    }
    if (Escoretipo == 6) { // infusões
      this.ShowDrogaComponent(this.Droga);
    }else if  (Escoretipo == 7) { // dados vitais
      this.ShowDadosVitaisComponent();
    }else if  (Escoretipo == 8) { // ventilação mecânica
      this.ShowDadosVentilacaoComponent();
    }else if  (Escoretipo == 9) { // gasometria
      this.ShowDadosLaboratorioComponent('Gasometria');
    }else if  (Escoretipo == 10) { // Bioquimica
      this.ShowDadosLaboratorioComponent('Bioquimica');
      
    } else {  // demais tipos de campos
      this.ShowEscoreComponent(Escore, Escoretipo, NomeEscore,
        Rangeminimo, Rangemaximo, Padrao, Siglamedida, Checklist);
    }
  } // fim showModal

  async selecionaDroga(x: number) {
    this.Droga = x;
  }

  async ShowEscoreComponent(Escore: number, Escoretipo: number, NomeEscore: string,
    Rangeminimo: any, Rangemaximo: any, Padrao: any, Siglamedida: string, Checklist: number) {
    let modal = await this.modalCtrl.create({
      component: ChecklistescoreComponent,
      componentProps:
      {
        Escore: Escore,
        Escoretipo: Escoretipo,
        NomeEscore: NomeEscore,
        Rangeminimo: Rangeminimo,
        Rangemaximo: Rangemaximo,
        Siglamedida: Siglamedida,
        Checklist: Checklist,
        Internacao: this.Internacao,
        Paciente: this.Paciente,
        Datalancamento: this.Datalancamento,
        Valor: Padrao,
        Usuario: this.Usuario,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente: this.FotoAtualPaciente,
        cssClass: 'modalEscore',
      }
    });
    return await modal.present();
  }

  async ShowDrogaComponent(x: number) {
    this.Droga = x;
    let modal = await this.modalCtrl.create({
      component: DrogaComponent,
      componentProps:
      {
        Droga: this.drogaCollection[x].Id,
        Nome: this.drogaCollection[x].Nome,
        Ampolaml: this.drogaCollection[x].Ampolaml,
        Ampoladoseml: this.drogaCollection[x].Ampoladoseml,
        Doseminima: this.drogaCollection[x].Doseminima,
        Dosemaxima: this.drogaCollection[x].Dosemaxima,
        Fator: this.drogaCollection[x].Fator,
        SiglaUnidadeMedidaAmpola: this.drogaCollection[x].SiglaUnidadeMedidaInfusao.split('/')[0],
        SiglaUnidadeMedidaInfusao: this.drogaCollection[x].SiglaUnidadeMedidaInfusao,
        SiglaUnidadeMedidaConcentracao: this.drogaCollection[x].SiglaUnidadeMedidaConcentracao,
        Qtdampolapadrao: this.drogaCollection[x].Qtdampolapadrao,
        Diluicaopadrao: this.drogaCollection[x].Diluicaopadrao,
        Concentracaopadrao: this.drogaCollection[x].Concentracaopadrao,
        Kgbaseado: this.drogaCollection[x].Kgbaseado,
        Dataevolucao: this.Dataevolucao,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente:  this.FotoAtualPaciente
      }
    });
    return await modal.present();

  } // fim showModal

  async ShowDadosVitaisComponent() {
    let modal = await this.modalCtrl.create({
      component: VitalComponent,
      componentProps:
      {
        Dataevolucao: this.Dataevolucao,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente:  this.FotoAtualPaciente
      }
    });
    return await modal.present();

  } // fim showModal

  


  async ShowDadosLaboratorioComponent(Div:string) {
    let modal = await this.modalCtrl.create({
      component: LaboratorioComponent,
      componentProps:
      {
        Dataevolucao: this.Dataevolucao,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente:  this.FotoAtualPaciente,
        Div: Div
      }
    });
    return await modal.present();

  } // fim showModal


  async ShowDadosVentilacaoComponent() {
    let modal = await this.modalCtrl.create({
      component: VentilacaoComponent,
      componentProps:
      {
        Dataevolucao: this.Dataevolucao,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente:  this.FotoAtualPaciente
      }
    });
    return await modal.present();

  } // fim showModal

  async showModalGrafico(x: number) {
    let Escore = this.checklistCollection[x].Escore;
    let Escoretipo = this.checklistCollection[x].Escoretipo;
    let NomeEscore = this.checklistCollection[x].NomeEscore;
    let Rangeminimo = this.checklistCollection[x].Rangeminimo;
    let Rangemaximo = this.checklistCollection[x].Rangemaximo;
    let Siglamedida = this.checklistCollection[x].Siglamedida;
    let Padrao = this.checklistCollection[x].Padrao;
    let Checklist = this.checklistCollection[x].Id;
    let DescricaoEscore = this.checklistCollection[x].DescricaoEscore;
    let modal = await this.modalCtrl.create({
      component: GraficoComponent,
      componentProps:
      {
        Escore: Escore,
        Escoretipo: Escoretipo,
        NomeEscore: NomeEscore,
        Rangeminimo: Rangeminimo,
        Rangemaximo: Rangemaximo,
        Siglamedida: Siglamedida,
        Checklist: Checklist,
        Internacao: this.Internacao,
        Paciente: this.Paciente,
        Datalancamento: this.checklistForm.value.Datalancamento,
        Valor: Padrao,
        Usuario: this.Usuario,
        NomePaciente: this.NomePaciente,
        FotoAtualPaciente: this.FotoAtualPaciente,
        Droga: this.drogaCollection[this.Droga].Id,
        NomeDroga: this.drogaCollection[this.Droga].Nome,
        cssClass: 'modalEscore',
      }
    }); this.drogaCollection
    return await modal.present();

  } // fim showModal




  async cadastrarChecklistPadrao(Checklist: number, Padrao: number, x: number,
    NomeEscore: string, Escoretipo: number, Escore: number,
    Rangeminimo: number, Rangemaximo: number, DescricaoEscore: string, Siglamedida: string) {

    this.escoreColection = [];
    if (Escoretipo == 3 || Escoretipo == 5) { // lista
      this.checklistService.selecionarEscoreRelacao(Escore)
        .then((response: any) => {
          this.Padrao = 0;
          for (let i = 0; i < response.length; i++) {
            if (response[i].Padrao == 1) { //default do escore
              this.Padrao = response[i].Id;
              this.NomePadrao = NomeEscore.trim() + ' ' + response[i].Nome.trim()
              this.ValorPadrao = response[i].Valor;
              break;
            }
          }
        })
        .catch((response) => {
          this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore: ' + NomeEscore)
        });
    }

    if (Escoretipo == 1) { // numerico
      let Valor: number = eval('this.checklistForm.value.Valor' + x.toString())
      this.NomePadrao = NomeEscore + " " + Valor + " " + Siglamedida;
      this.Padrao = Valor.toString().replace(',', '.');
      this.ValorPadrao = Valor.toString().replace(',', '.');
    }

    if (Escoretipo == 2) { // lista 
      this.NomePadrao = DescricaoEscore;
    }


    if (Escoretipo == 4) { // range ou 
      this.NomePadrao = DescricaoEscore;
    }

    if (Escoretipo == 6) { // infusões
      this.NomePadrao = DescricaoEscore;
      this.ValorPadrao = -1

    }




    if (isNaN(this.Padrao / 1)) {
      let Campo: any = eval('this.ancoraValor' + x.toString());
      this.utilService.showAlertCallback("Este valor não é numerico", () => {
        this.checklistForm.controls['Valor' + x.toString()].setValue('');
        this.utilService.focusInput(Campo);

      })
      return;
    }

    setTimeout(() => {
      let Id = eval('this.checklistForm.value.Campo' + x.toString());
      let form = {
        Id: Id,
        Internacao: this.Internacao,
        Paciente: this.Paciente,
        Datalancamento: this.Datalancamento,
        Checklist: Checklist,
        Valor: this.ValorPadrao.toString().replace(',', '.'),
        Usuario: this.Usuario,
        Relacao: this.Padrao,
        Comentario: this.NomePadrao

      }

      if (Id == null || Id == undefined || Id == '') {
        this.checklistService.adicionarChecklistlancamento(form).then((response) => {
          // this.utilService.showToastCallBack(NomeEscore + " INCLUIDO com sucesso", 2000, null);
          setTimeout(() => {
            this.Id = response[0].Id;
            this.checklistForm.controls['Campo' + x.toString()].setValue(this.Id);
          }, 1000);

        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível INCLUIR o " + NomeEscore, null);

        });
      } else {
        this.checklistService.atualizarChecklistlancamento(form).then((response: any) => {
          this.utilService.showAlertCallback(NomeEscore + " ALTERADO com sucesso", null);
          // this.selecionarChecklistlancamento();
          this.Id = response[0].Id;
        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

        });
      }
    }, 1000);

    if (x == this.checklistCollection.length - 1) {
      // nesse caso vai para o proximo sistema 
      // se último vou para o primeiro
      if (this.Sistema == this.sistemaCollection.length) {
        this.Sistema = 0;
      }
      this.Sistema++;
      this.selecionarChecklist(this.Sistema);
    }
  }

  async mostraPergunta(x: number, pergunta: string) {
    this.utilService.showToastCallBack((x + 1) + ') ' + pergunta, 4000, null)
  }

  async mostrarUltimoLancamentoCadastrado(x: number) {
    this.Checklist = this.checklistCollection[x].Id;
    this.DescricaoEscore = this.checklistCollection[x].DescricaoEscore;
    let Escoretipo = this.checklistCollection[x].Escoretipo;
    if (Escoretipo == 6) { // infusões
      this.selecionarDrogalancamento();
    }else if (Escoretipo == 7) { // dados vitais
      this.selecionarVitalUltimos();
    }else if (Escoretipo == 8) { // ventilacao
      this.selecionarVentilacaoUltimos();
    }else if (Escoretipo == 9) { // obstetricia
      this.selecionarVentilacaoUltimos();
    }else if (Escoretipo == 10) { // laboratorio
      this.selecionarVentilacaoUltimos();
    } else {
      this.selecionarChecklistlancamento(x);
    }
  }

  async selecionarDrogalancamento() {
    let Droga = this.checklistForm.value.Droga;
    if (Droga == undefined || Droga == null || Droga == '') {
      Droga = 0;
    }
    this.selecionarInfusoesUltimo(Droga);
  }

  async selecionarInfusoesUltimo(Droga:number) {
    let Id = this.drogaCollection[Droga].Id;
    let Ultimo:string='';
    let Data:string='';
    let Valor:any='';
    let NomeDroga= this.drogaCollection[Droga].Nome;
    let Unidade= this.drogaCollection[Droga].SiglaUnidadeMedidaInfusao
    this.checklistService.selecionarDrogalancamento(this.Internacao, this.Paciente, Id)
    .then((response: any) => {
      response = response.sort((n1, n2) => n1.Id - n2.Id);
      Valor= response[0].Valor;
      Data= response[0].Datalancamento;
      Valor = Valor.split(',') ;
      Valor = Valor[0] + ',' + Valor[1].substr(0,2);
      Ultimo = NomeDroga + ": " + Data + " -> " + Valor  + ' '+ Unidade ;
      this.utilService.showToastCallBack(Ultimo, 4000, null);
     
    })  .catch((response) => {
      this.utilService.showToastCallBack(NomeDroga + " sem dados cadastrados", 4000, null)
    });
  }
  async selecionarChecklistlancamento(x: number) {
    this.Botao = "CADASTRAR";
    this.lancamentosCollection = [];
    this.checklistService.selecionarChecklistlancamento(this.Internacao, this.Paciente, this.Checklist).then((response) => {
      this.lancamentosCollection = response;
      if (this.lancamentosCollection.length == 0) {
        let Comentario = this.DescricaoEscore;
        if (Comentario.length > 0) {
          this.utilService.showToastCallBack(Comentario, 3000, null);
        }

      } else {
        this.DescricaoEscore = '';
        let len = this.lancamentosCollection.length - 1;
        let Comentario = this.lancamentosCollection[len].Comentario;
        let Valor: any = this.lancamentosCollection[len].Valor;
        Valor = Valor.replace(',', '.');
        Valor = Valor.split('.');
        if (Valor[1] == 0) {
          Valor = Valor[0]
        } else {
          Valor = Valor[0] + '.' + Valor[1].substr(0, 2);
        }
        this.checklistForm.controls['Valor' + x.toString()].setValue(Valor);
        this.utilService.showToastCallBack(Comentario, 3000, null);

      }
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento", null)
    });
  }

  async selecionarChecklistlancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarChecklistlancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async limpaDados() {
    this.selecionarDataHoje();
    this.Id = -1;
    for (let i = 0; i < 20; i++) {
      this.checklistForm.controls['Campo' + i.toString()].setValue('');
      this.checklistForm.controls['Valor' + i.toString()].setValue('');
    }
    // this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, null);
  }

  async selecionarDataHoje() {
    // let dtHoje = this.utilService.dataHoje();
    //this.Datalancamento = dtHoje;
    this.Datalancamento = this.Dataevolucao;
    // this.checklistForm.controls['Datalancamento'].setValue(this.Datalancamento);
  }

  async selecionarSistema(callback = null) {
    this.pacienteService.selecionarSistema().then((response) => {
      this.sistemaCollection = response;
      if (callback != null) {
        callback();
      }
    }).catch((response) => {
      this.sistemaCollection = [];
      //console.log("erro")
    });
  }


  async contaTempo() {
    this.ContaTempo = setInterval(() => {
      this.Tempo++;
      if (this.Tempo == 60) {
        this.Minuto++;
        this.Tempo = 0;
        this.Segundo = 0;
        clearInterval(this.ContaTempo);
        // this.contaTempo();
      }
      if (this.Tempo < 60) {
        this.Segundo = this.Tempo;
      }

    }, 1000)

  }

  async movimentaSistema(info: string) {
    this.limpaDados();

    if (info == 'i') {
      this.Sistema = 1
    } else if (info == '-') {
      this.Sistema--;
      if (this.Sistema < 0) {
        this.Sistema = 1
      }

    } else if (info == '+') {
      this.Sistema++;
      if (this.Sistema > this.sistemaCollection[this.sistemaCollection.length - 1]) {
        this.Sistema = this.sistemaCollection[this.sistemaCollection.length - 1].Id
      }
    } else if (info == 'f') {
      this.Sistema = this.sistemaCollection[this.sistemaCollection.length - 1].Id
    }


    this.selecionarChecklist(this.Sistema);

  }
  async selecionarChecklist(sistema: number) {
    if (sistema != undefined && sistema != null) {
      this.Sistema = sistema;
    }

    this.limpaDados();

    this.checklistService.selecionarChecklist(sistema).then((response) => {
      this.checklistCollection = response;
      setTimeout(() => {
        this.checaFeitos()

      }, 500)


    }).catch((response) => {
      this.sistemaCollection = [];
      //console.log("erro")
    });
  }

  async checaFeitos() {

    if (this.checklistCollection.length == 0) {
      return;
    }
    if (this.checklistCollection[0].Obrigatorio == 1) {
      this.utilService.focusInput(this.ancoraValor0)
    } else {
      this.utilService.focusInput(this.ancoraCampo0);
    }

    let c: number;
    let s: number;
    for (let f = 0; f < this.checklistCollection.length; f++) {
      c = this.checklistCollection[f].Id;
      if (this.Sistema == this.checklistCollection[f].Sistema) {
        this.utilService.esconderDiv('c' + c.toString());
        this.utilService.mostrarDiv('h' + c.toString());
      }

    }

    for (let f = 0; f < this.feitosCollection.length; f++) {
      c = this.feitosCollection[f].Id;
      if (this.Sistema == this.feitosCollection[f].Sistema) {
        this.utilService.mostrarDiv('c' + c.toString());
        this.utilService.esconderDiv('h' + c.toString());
      }
    }



  }

  async selecionarVitalUltimos() {
    this.lancamentoService.selecionarVitalUltimos(this.Internacao, this.Paciente).then((response:any) => {
      let cString = "DATA: " + response[0].Datalancamento;
      cString = cString + '\n' + "PS: " + response[0].Ps + ' mmHg';
      cString = cString + '\n' + "PD: " + response[0].Pd + ' mmHg';
      let Pam:any =   (response[0].Ps + (2*response[0].Pd))/3;
      Pam = parseInt(Pam); 
      cString = cString + '\n' + "PAM: " + Pam + ' mmHg';
      cString = cString + '\n' + "FC: " + response[0].Fc + ' bmp';
      cString = cString + '\n' + "FR: " + response[0].Fr + ' ipm';
      cString = cString + '\n' + "Temp: " + response[0].Temp + ' oC';
      cString = cString + '\n' + "SatO2: " + response[0].O2 + ' %';
      cString = cString + '\n' + "Hgt: " + response[0].Pd + ' mg/dL';

      this.utilService.showToastCallBack(cString, 6000, null);
    }).catch((response) => {
      this.utilService.showToastCallBack('Não foi possível acessar o últimos Dados vitais', 4000, null);
    });
  }

  async selecionarVentilacaoUltimos() {
    this.lancamentoService.selecionarVentilacaoUltimos(this.Internacao, this.Paciente).then((response:any) => {
      let cString = "DATA: " + response[0].Datalancamento;
      if (response[0].Sexo == 1) {
        cString = cString + '\n' + "Peso predito: " + response[0].PesoIdealMasculino + ' Kg';
      }else{
        cString = cString + '\n' + "Peso predito: " + response[0].PesoIdealFeminino + ' Kg';
      }
      cString = cString + '\n' + "Altura: " + response[0].Altura + ' mmHg';
      cString = cString + '\n' + "Modalidade: " + response[0].Modalidade  ;
      cString = cString + '\n' + "PI/PS: " + response[0].Pressaoinspiratoria + ' cmH2O';
      cString = cString + '\n' + "Peep: " + response[0].Peep + + ' cmH20';
      cString = cString + '\n' + "Platô: " + response[0].Pressaoplato + ' cmH20';
      cString = cString + '\n' + "FR: " + response[0].Frpaciente + ' ipm';
      cString = cString + '\n' + "FiO2: " + response[0].FiO2 + ' %';
      cString = cString + '\n' + "VC: " + response[0].Volumeexpiratorio + ' mL';
      cString = cString + '\n' + "VC: " + response[0].Volumeexpiratorio + ' mL';
      cString = cString + '\n' + "TI: " + response[0].Tempoinspiratorio + ' s';
      cString = cString + '\n' + "I/E: " + response[0].RelacaoI + ":" + response[0].RelacaoE +' s';

      this.utilService.showToastCallBack(cString, 6000, null);
    }).catch((response) => {
      this.utilService.showToastCallBack('Não foi possível acessar o últimos Dados vitais', 4000, null);
    });
  }
}
