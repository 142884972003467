import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-exameorgao',
  templateUrl: './exameorgao.component.html',
  styleUrls: ['./exameorgao.component.scss'],
})
export class ExameorgaoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraPadrao', null) ancoraPadrao: ElementRef;
  @ViewChild('ancoraOrdem', null) ancoraOrdem: ElementRef;

  exameorgaoForm:any;
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0;
  Botao:string="Cadastrar";
  orgaoColection:any=[];
  collectionMedida:any=[];
  Exameorgao:number=0;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private alertController: AlertController,
    private checklistService:ChecklistService
) {
    this.exameorgaoForm = formBuilder.group({
      
      Exameorgao: ['', ''],
      Nome: ['', ''],
      Padrao: ['', ''],
      Examemedida: ['', ''],
      Ordem: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() { 

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.limpaDados();
    this.selecionarExameOrgao();
    this.selecionarExameMedida();

  }

  async selecionarExameOrgao() {
    this.exameService.selecionarExameOrgao().then((response:any)=>{
      this.orgaoColection = response;
      if (response.length == 0){
        this.utilService.showAlertCallback("Naõ existem ainda órgãos cadastrados para você")

      }

    }).catch(()=>{
      this.utilService.showAlertCallback("Naõ existem ainda orgãos cadastrados para você")
    });



  }

  

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
         
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

   async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
   
  }

 

  

  async testaDados() {

    if (this.exameorgaoForm.value.Nome == null || this.exameorgaoForm.value.Nome == undefined || this.exameorgaoForm.value.Nome == '') {
      this.utilService.showAlertCallback("Informe o NOME DO ÓRGÃO para continuar", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      });
      return;
    }

    if (this.exameorgaoForm.value.Padrao == null || this.exameorgaoForm.value.Padrao == undefined || this.exameorgaoForm.value.Padrao == '') {
      this.utilService.showAlertCallback("Informe o NOME DO PADRÃO para continuar", ()=>{
        this.utilService.focusInput(this.ancoraPadrao);
      });
      return;
    }

    if (this.exameorgaoForm.value.Ordem == null || this.exameorgaoForm.value.Ordem == undefined || this.exameorgaoForm.value.Ordem == '') {
      this.utilService.showAlertCallback("Informe a ORDEM para continuar", ()=>{
        this.utilService.focusInput(this.ancoraOrdem);
      });
      return;
    }

    let form = {
      Id: this.exameorgaoForm.value.Exameorgao,
      Nome: this.exameorgaoForm.value.Nome,
      Padrao: this.exameorgaoForm.value.Padrao,
      Examemedida: this.exameorgaoForm.value.Examemedida.toString(),
      Ordem: this.exameorgaoForm.value.Ordem,
    }


    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async selecionarExameMedida() {
    this.exameService.selecionarExameMedida().then((response: any) => {
      this.collectionMedida = response
    })

  }
 

  async presentAlertConfirm(form: any) {
    let tipo = this.exameorgaoForm.value.Exameorgao;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitante',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


   async cadastrar(form: any) {
    if (this.exameorgaoForm.value.Exameorgao ==  -1) {
      this.exameService.adicionarExameOrgao(form).then((response) => {
        if (response[0].Id > 0) {
          this.selecionarExameOrgao();
          this.utilService.showAlertCallback("Órgão ADICIONADO com sucesso", null);
          setTimeout(() => {
            this.Exameorgao = response[0].Id;
            this.exameorgaoForm.controls['Exameorgao'].setValue(this.Exameorgao);

          }, 500);
        }
     
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o Órgão", null);

      });
    } else {
      this.exameService.atualizarExameOrgao(form).then((response:any) => {
        this.selecionarExameOrgao();
        this.utilService.showAlertCallback("Órgão ATUALIZADO com sucesso", null);
        setTimeout(() => {
          this.exameorgaoForm.controls['Exameorgao'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Órgão", null);

      });
    }

  }

  a
  async selecionarExameOrgaoUnico(Id:number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.exameService.selecionarExameOrgaoUnico(Id).then((response:any) => {
      if (response[0].Id > 0) {
        this.getExameOrgao(response);
      }
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar o ÓRGÃO")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.Exameorgao = -1;
    this.exameorgaoForm.controls['Exameorgao'].setValue(-1);
    this.exameorgaoForm.controls['Nome'].setValue('');
    this.exameorgaoForm.controls['Padrao'].setValue('');
    this.exameorgaoForm.controls['Examemedida'].setValue('');
    this.exameorgaoForm.controls['Ordem'].setValue('');

    this.utilService.focusInput(this.ancoraNome);
  }

  async getExameOrgao(response: any) {

    if (response.length == 0) {
      return;
    }

      this.Botao = "Alterar";
      
      setTimeout(() => {
        let aMedidas: string = response[0].Examemedida;
        let aMed: any;
        if (aMedidas.indexOf(",") > -1) {
          aMed = JSON.parse('[' + aMedidas + ']')
        } else {
          aMed = aMedidas;
        }
        this.exameorgaoForm.controls['Examemedida'].setValue(aMed);
        this.exameorgaoForm.controls['Nome'].setValue(response[0].Nome);
        this.exameorgaoForm.controls['Padrao'].setValue(response[0].Padrao);
        this.exameorgaoForm.controls['Ordem'].setValue(response[0].Ordem);

      }, 250)

  }


}
