import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { EstudoService } from '../../services/estudo.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-estudoresposta',
  templateUrl: './estudoresposta.component.html',
  styleUrls: ['./estudoresposta.component.scss'],
})
export class EstudorespostaComponent implements OnInit {

  estudorespostaForm: any
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0
  Botao:string="Responder"
  aPergunta:any=[]; 
  aResposta:any=[];  
  Pergunta:number=0;
  aCor:any=[];
  aLetras:any=[];
  Resposta:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private estudoService: EstudoService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    ) {
      this.estudorespostaForm = formBuilder.group({
        Resposta: ['', ''],
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();

     }

  ngOnInit() {
    this.aPergunta = this.navParams.get('aPergunta');
    this.aPergunta = [this.aPergunta]
    this.Pergunta =  this.aPergunta[0].Id
    if (this.aPergunta[0].Imagem != '' && this.aPergunta[0].Imagem != undefined && this.aPergunta[0].Imagem != null ){
      this.aPergunta[0].Imagem  = this.utilService.obterUrlDaImagemDaApi() + this.aPergunta[0].Imagem;
    }
    this.aLetras.push('A')
    this.aLetras.push('B')
    this.aLetras.push('C ')
    this.aLetras.push('D')
    this.aLetras.push('E')

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.e_selecionarRespostaPergunta();
  

  }

  async e_selecionarRespostaPergunta() {
    this.estudoService.e_selecionarRespostaPergunta(this.Pergunta).then((response:any)=>{
      this.aResposta= response;
      this.aCor = this.utilService.montaCorItem( this.aResposta.length, 'light')
    })

   
  }




  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }

  }

  async pronptResposta() {
    let Letra:string = ''
    let Correta:number =  0
    this.Resposta=this.estudorespostaForm.value.Resposta;
    
    for (let i=0; i<this.aResposta.length; i++){
       if (this.aResposta[i].Id == this.Resposta) {
         Letra = this.aLetras[i]
         if (this.aResposta[i].Correta == 1) {
          Correta = 1
         }
       }
     
    }
    const alert = await this.alertController.create({
      header: "PENGUNTA: " + this.Pergunta,
      subHeader: "RESPOSTA: " + Letra,
      message: '<strong>Deseja concluir essa pergunda?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.cadastrarResposta(Correta);
          }
        }
      ]
    });
  
    await alert.present();
   }
  

  async cadastrarResposta(Correta:number) {
    let sCorreta:string= "INCORRETA."
    if (Correta == 1) {
      sCorreta = "CORRETA!"
    }
    let form={
      Pergunta: this.Pergunta,
      Resposta: this.Resposta,
      Usuario: this.Usuario,
      Correta: Correta
    }
  
    this.estudoService.e_adicionarEstudo(form).then((response:any)=>{
      this.utilService.showAlertCallback("Estudo adicionado com sucesso. Resposta estava: " + sCorreta, ()=>{
        this.closeModal();
      })
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o estudo")
    })

  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  
 
}
