import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChecklistService } from '../../services/checklist.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-checklistescore',
  templateUrl: './checklistescore.component.html',
  styleUrls: ['./checklistescore.component.scss'],
})
export class ChecklistescoreComponent implements OnInit {
  @ViewChild('ancoraComentario', null) ancoraComentario: ElementRef;
  @ViewChild('ancoraValor', null) ancoraValor: ElementRef;
  Escoretipo: number = 0;
  NomeEscore: string = '';
  Rangeminimo: number = 0;
  Rangemaximo: number = 0;
  checklistescoreForm: FormGroup;
  lRet: any;
  dadosListMed: any;
  escoreColection: any = [];
  Escore: number = 0;
  Valor: number = 0;
  Siglamedida: string = '';
  multiple: string = "false"
  Padrao: number = 0;
  Checklist: number;
  Internacao: number = 0;
  Paciente: number = 0;
  Datalancamento: any;
  Usuario: number = 0;
  Id: number = -1;
  Botao: string = 'CADASTRAR';
  lancamentosCollection: any = [];
  Logado: number = 0;
  NomePadrao: string = "";
  NomePaciente: string = "";
  FotoAtualPaciente: string = "";
  Comentario: string = '';
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private checklistService: ChecklistService,
    private utilService: UtilService,
    private alertController: AlertController
  ) {
    this.Escore = parseInt(this.navParams.get('Escore'));
    this.Escoretipo = parseInt(this.navParams.get('Escoretipo'));
    this.NomeEscore = this.navParams.get('NomeEscore');
    this.Rangeminimo = parseInt(this.navParams.get('Rangeminimo'));
    this.Rangemaximo = parseInt(this.navParams.get('Rangemaximo'));
    this.Siglamedida = this.navParams.get('Siglamedida');
    this.Checklist = this.navParams.get('Internacao');
    this.Internacao = this.navParams.get('Checklist');
    this.Paciente = this.navParams.get('Paciente');
    this.Datalancamento = this.navParams.get('Datalancamento');
    this.Valor = this.navParams.get('Valor');
    this.Usuario = this.navParams.get('Usuario');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
    this.checklistescoreForm = formBuilder.group({
      Id: ['', ''],
      Escore: ['', ''],
      Comentario: ['', ''],
      Valor: ['', ''],  // em caso de informações numericas
    });
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.limpaDados()

  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async limpaDados() {
    this.Id = -1; // para identificar como uma nova inclusao
    this.checklistescoreForm.controls['Comentario'].setValue('');
    this.checklistescoreForm.controls['Valor'].setValue('');
    this.checklistescoreForm.controls['Escore'].setValue('');
    this.checklistescoreForm.controls['Id'].setValue(this.Id);
    this.Botao = 'CADASTRAR';
    if (this.Escoretipo == 5) { // lista multipla
      this.multiple = "true";
    }
    await this.selecionarEscoreRelacao();
    await this.selecionarChecklistlancamento();
  }

  async selecionarEscoreRelacao() {
    this.checklistService.selecionarEscoreRelacao(this.Escore)
      .then((response: any) => {
        if (response.length > 0) {
          this.montaVetorEscoreRelacao(response);
        }
      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore: ' + this.NomeEscore)
      });
  }

  async montaVetorEscoreRelacao(response:any) {
    this.escoreColection=[];
    this.Padrao = 0;
    for (let i = 0; i < response.length; i++) {
      if (response[i].Padrao == 1) {
        this.Padrao = response[i].Id;
        this.NomePadrao = response[i].Nome;
      }else{

        this.escoreColection.push({
          Id: response[i].Id,
          Nome: response[i].Nome,
          Valor: response[i].Valor,
        });
      }
    }
  }

  async presentAlertConfirm(tipo: number) {
    // 1 para padrão // 2 para outros
    let message: string = 'Deseja INCLUIR esses dados para ' + this.NomeEscore
    let Id: number = this.checklistescoreForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ALTERAR esses dados para ' + this.NomeEscore
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
             this.cadastrarChecklist(tipo); // se padrao ou não

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarChecklist(tipo: number) {
    let Id = this.checklistescoreForm.value.Id;
    let Valor:number=0;
    let Relacao: any = this.checklistescoreForm.value.Escore.toString();
    let cRelacao: string = '';
    let aRel: any = []
    if (Relacao.indexOf(',') > -1) {
      aRel = Relacao.split(',');
    } else {
      aRel.push(Relacao);
    }


    for (let i = 0; i < aRel.length; i++) {
      let conj = ', ' ;
      if (i == aRel.length -2 ) {
        conj = ' e ';
      }
      for (let z = 0; z < this.escoreColection.length; z++) {
        if (this.escoreColection[z].Id == aRel[i]) {
          cRelacao = cRelacao + this.escoreColection[z].Nome +  conj;
          Valor= Valor + parseFloat(this.escoreColection[z].Valor.replace(',', '.'));
        }
      }
    }
    cRelacao = cRelacao.substr(0, cRelacao.length - 2);
    let Comentario: string = this.checklistescoreForm.value.Comentario;
    Comentario = Comentario.replace( this.NomePadrao, '');
    if (Comentario != '' && Comentario != null && Comentario != undefined) {
      Comentario = cRelacao + " " + Comentario ;
    } else {
      Comentario = cRelacao;
      if (this.Escoretipo == 1) {
        Comentario = Comentario + ' ' + this.Siglamedida
      }
      
    }
    Relacao = this.checklistescoreForm.value.Escore;
   

    if (Relacao == '' || Relacao == null || Relacao == undefined || Relacao.length == 0) {
      this.utilService.showAlertCallback("Você precisa escolher pelo menos um ítem", 
      ()=> { this.utilService.focusInput(this.ancoraComentario)});
      return;
    }

    Relacao = JSON.stringify(Relacao);
    let form = {
      Id: Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: this.Datalancamento,
      Checklist: this.Checklist,
      Valor: Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Comentario: cRelacao
    }

    if (Id == null || Id == undefined || Id == '' || Id == -1) {
      this.checklistService.adicionarChecklistlancamento(form).then((response) => {
       // this.utilService.showToastCallBack(this.NomeEscore + " INCLUIDO com sucesso", 2000, null);
        this.closeModal();  // configuração do usuario
        this.selecionarChecklistlancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.checklistescoreForm.controls['Id'].setValue(this.Id);

        }, 1000);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível INCLUIR o " + this.NomeEscore,  null);

      });
    } else {
      this.checklistService.atualizarChecklistlancamento(form).then((response: any) => {
        //this.utilService.showToastCallBack(this.NomeEscore + " ALTERADO com sucesso", 2000, null);
        this.selecionarChecklistlancamento();
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.NomeEscore, null);
      });
    }


  }

  async closeModal() {
    this.modalController.dismiss();
  }

  async selecionarOpcaoEscoreRelacao(event:number) {

    if (event != this.Padrao && this.Padrao > 0) {
      this.presentAlertConfirm(2);
    }
  }
  async selecionarChecklistlancamento() {
    this.lancamentosCollection=[];
    this.Botao = "CADASTRAR";
    this.checklistService.selecionarChecklistlancamento(this.Internacao, this.Paciente, this.Checklist).then((response) => {
      this.lancamentosCollection = response;
      if (this.lancamentosCollection.length == 0) {
       // this.utilService.showToastCallBack("Ainda não existem dados lançados para a Checklist" + this.NomeEscore, 2000, null)
      }

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento", null);
    });
  }

  async selecionarChecklistlancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }

    this.checklistService.selecionarChecklistlancamentoUnico(Id).then((response: any) => {
      this.Botao = "ALTERAR"
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async mostrarDados(response: any) {
    let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    let Relacao: any = response[0].Relacao;
    if (Relacao != '') {
      Relacao = JSON.parse(Relacao);
      this.checklistescoreForm.controls['Escore'].setValue(Relacao);

    }

    let Comentario: string = response[0].Comentario;
    let Valor: number = response[0].Valor.replace(',', '.');
    this.checklistescoreForm.controls['Comentario'].setValue(Comentario);
    this.checklistescoreForm.controls['Valor'].setValue(Valor);


  }


}
