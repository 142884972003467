import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import {LoadingController, ModalController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { TabelaService } from '../../services/tabela.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-cpis',
  templateUrl: './cpis.component.html',
  styleUrls: ['./cpis.component.scss'],
})
export class CpisComponent implements OnInit {
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  Id: number = -1;
  Calculo:number=0
  Comentario:string="";
  lancamentosCollection: any = [];
  Secrecao: number = 0;
  Leucocitos: number = 0;
  Temperatura: number = 0;
  Raiox: number = 0;
  Cultura: number = 0;
  Relacao: number = 0;
  Logado: number = 0;
  Usuario: string = "";
  Sigla: string = "";
  cpisForm: FormGroup;
  Paciente: number = 0;
  Nome: string = "";
  botao: string = "Cadastrar";
  Datalancamento: string = "";
  itensColecton: any = [];
  dadosListMed: any;
  Internacao: number;
  Valor: number=0;
  Componente: any;
  Vetor: any = [];
  Descricaounidade: string = "";
  Valornormal: string = "";
  Descricaocalculo: string = "";
  Observacao: string = "";
  Sexo: number = 1;
  Sexoexistente: number = 0;
  aSexo: any = [];
  Botao: string = "CALCULAR";
  Dias: string = "";
  itensColection: any = [];
  NomePaciente: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Altura: any;
  Peso: any;
  Bsa: any;
  Sistema:number=0;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private checklistService: ChecklistService,
    private tabelaService: TabelaService,
    private alertController: AlertController,
    private loadingController:LoadingController
  ) {
    this.cpisForm = formBuilder.group({
      Datalancamento: ['', ''],
      Id: ['', ''],
      Secrecao: ['', ''],
      Leucocitos: ['', ''],
      Relacao: ['', ''],
      Temperatura: ['', ''],
      Raiox: ['', ''],
      Cultura: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Sistema = this.navParams.get('Sistema');

  }
  ngOnInit() {
    this.Calculo= 25;
  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico();
    this.testaAcessoSistema();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico(){
    let loading = await this.loadingController.create(
      { message: "Selecionando od dados..." ,
        mode: 'ios'
      }
      );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
    .then((response:any) => {
      this.Sigla = response[0].Sigla;
      this.Nome =response[0].Nome
      this.Componente = response[0].Componente
      this.Valornormal = response[0].Valornormal;
      this.Descricaounidade = response[0].Descricaounidade
      this.Descricaocalculo = response[0].Descricaocalculo
      this.Observacao = response[0].Observacao
      this.Valorminimo = response[0].Valorminimo
      this.Valormaximo = response[0].Valormaximo
  
  
    })
    .catch((response) => {
      this.utilService.showAlertCallback('Não foi possível selecionar os dados da calculadora')
    }).finally(() => {
      loading.dismiss();
    });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento",  null)
    });
  }


 


  async closeModal() {

    this.modalCtrl.dismiss();
  }

  calcularCpis() {
    let Secrecao: number = this.cpisForm.value.Secrecao;
    if (Secrecao == null || Secrecao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de SECREÇÃO", null)
      return;

    }

    this.Secrecao = Secrecao;

    let Leucocitos: number = this.cpisForm.value.Leucocitos;
    if (Leucocitos == null || Leucocitos == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de LEUCÓCITOS", null)
      return;

    }

    this.Leucocitos = Leucocitos;

    let Temperatura: number = this.cpisForm.value.Temperatura;
    if (Temperatura == null || Temperatura == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de TEMPERATURA", null)
      return;
    }

    this.Temperatura = Temperatura;

    let Relacao: number = this.cpisForm.value.Relacao;
    if (Relacao == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de RELAÇÃO P/F", null)
      return;

    }

    this.Relacao = Relacao;

    let Raiox: number = this.cpisForm.value.Raiox;
    if (Raiox == null || Raiox == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de RAIO X DE TÓRAX", null)
      return;

    }

    this.Raiox = Raiox;

    let Cultura: number = this.cpisForm.value.Cultura;
    if ( Cultura == null || Cultura == undefined) {
      this.utilService.showAlertCallback("Escolha um ítem de CULTURA DA SECREÇÃO", null)
      return;

    }

    this.Cultura = Cultura;

    this.Valor = Secrecao + Temperatura + Leucocitos + Relacao + Raiox + Cultura
   
    let Range:string= " (BAIXO RISCO DE PAV)"
    if (this.Valor > this.Valormaximo) {
      Range= " (ALTO RISCO DE PAV)"
    }
    this.Valor = parseInt(this.Valor.toString());
    
    if (this.Descricaounidade == 'su') {
      this.Descricaounidade = "";
    }
    this.Comentario = this.Nome + ": " + this.Valor + " " + this.Descricaounidade + Range
    if (this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }

  }

  async presentAlertConfirm() {
    let message: string = 'Deseja ADICIONAR esses dados para ' + this.Nome
    let Id: number = this.cpisForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ATUALIZAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];


    Relacao = Relacao + this.Secrecao + '*';
    Relacao = Relacao + this.Leucocitos + '*';
    Relacao = Relacao + this.Temperatura + '*';
    Relacao = Relacao + this.Relacao + '*'; //p/f
    Relacao = Relacao + this.Raiox + '*';
    Relacao = Relacao + this.Cultura;
    let Datalancamento:any = this.cpisForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);


    let form = {
      Id: this.cpisForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.cpisForm.value.Comentario

    }


    if (this.cpisForm.value.Id == -1 || this.cpisForm.value.Id == undefined ||
      this.cpisForm.value.Id == null ) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso",  null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.cpisForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome,  null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso",  null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome,  null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento",  null)
    });
  }

  async limpaDados() {
    this.Id= -1;
    this.Valor = 0;

    let dtHoje = this.utilService.dataHoje();
    let Datalancamento= this.utilService.checaData(dtHoje);
    this.Datalancamento = Datalancamento;
    this.cpisForm.controls['Secrecao'].setValue(0);
    this.cpisForm.controls['Relacao'].setValue(0);
    this.cpisForm.controls['Raiox'].setValue(0);
    this.cpisForm.controls['Cultura'].setValue(0);
    this.cpisForm.controls['Leucocitos'].setValue(0);
    this.cpisForm.controls['Temperatura'].setValue(0);
    this.Botao = "CALCULAR"
    this.utilService.showToastCallBack("Tudo pronto para ADICIONAR novo registro", 2000, () => {
    });
    this.cpisForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }
    this.Datalancamento = Datalancamento;
    this.cpisForm.controls['Datalancamento'].setValue(Datalancamento);


  }



  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);

    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')
    this.Secrecao = parseInt(Relacao[0]);
    this.Leucocitos = parseInt(Relacao[1]);
    this.Temperatura = parseInt(Relacao[2]);
    this.Relacao = parseInt(Relacao[3]);
    this.Raiox = parseInt(Relacao[4]);
    this.Cultura = parseInt(Relacao[5]);
    
    
    this.cpisForm.controls['Datalancamento'].setValue(Datalancamento);
    this.cpisForm.controls['Secrecao'].setValue(this.Secrecao);
    this.cpisForm.controls['Relacao'].setValue(this.Relacao);
    this.cpisForm.controls['Raiox'].setValue(this.Raiox);
    this.cpisForm.controls['Cultura'].setValue(this.Cultura);
    this.cpisForm.controls['Leucocitos'].setValue(this.Leucocitos);
    this.cpisForm.controls['Temperatura'].setValue(this.Temperatura);
    this.Comentario = response[0].Comentario;
    this.cpisForm.controls['Comentario'].setValue(this.Comentario);

  }

}

