import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { Platform, LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ExameService } from '../../services/exame.service';
import { UsuarioService } from '../../services/usuario.service';
import { TabelaService } from '../../services/tabela.service';
import { UtilpdfService } from '../../services/utilpdf.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@ionic-native/File/ngx';
import { ChecklistService } from '../../services/checklist.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { EstudoService } from '../../services/estudo.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eventoedicao',
  templateUrl: './eventoedicao.component.html',
  styleUrls: ['./eventoedicao.component.scss'],
})
export class EventoedicaoComponent implements OnInit {
  @ViewChild('ancoraEmail', null) ancoraEmail: ElementRef;
  @ViewChild('ancoraValidacao', null) ancoraValidacao: ElementRef;
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraCelular', null) ancoraCelular: ElementRef;
  @ViewChild('ancoraDia', null) ancoraDia: ElementRef;
  @ViewChild('ancoraMes', null) ancoraMes: ElementRef;
  @ViewChild('ancoraAno', null) ancoraAno: ElementRef;
  @ViewChild('ancoraSexo', null) ancoraSexo: ElementRef;

  dadosListMed: any = [];
  Usuario: number = 0;
  Botao: string = "CADASTRAR"
  eventoedicaoForm: FormGroup;
  Fale: any;
  pdfObj: any;
  collectionEvento: any = [];
  Logado: number = 0;
  Administrador: number = 0;
  Validacao: number = 0;
  lRet: boolean = true;
  stringBase64Pdf: any;
  isCordova: boolean = false;
  Evento: number = -1;
  ImagemEvento: string = "";
  Email: string = "";
  ValidacaoOK: number = 0;
  Sexo: number = 0;
  aSexo: any = [];
  Mensagem: string = '';
  Link: string = '';
  aConselho: any = [];
  IdCadastro: number = 0;
  IdEvento: number = 0
  MostrarHelp: number = 0;
  Checado: number = 0;
  aEventoUsuarioTodos: any = [];
  NomeEvento: string = "";
  usuarioColection: any = [];
  usuarioColectionAtual: any = [];
  chdPagamento: string = "false";
  chdAtivo: string = "false";
  chdCertificado: string = "false";
  Imagem: string = "";
  Imagempagamento: string = "";
  aInscricao: any = [];
  Datainicial: string = "";
  Datafinal: string = "";
  aCurso: any = [];
  aResumo: any = [];
  sBase64Logo: string = ""
  aUsuarioRelatorio: any = [];
  Id:number;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private exameService: ExameService,
    private checklistService: ChecklistService,
    private utilpdfService: UtilpdfService,
    private file: File,
    private fileOpener: FileOpener,
    private base64: Base64,
    private clipboard: Clipboard,
    private estudoService: EstudoService,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private tabelaService: TabelaService,
    private platform: Platform

  ) {
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.eventoedicaoForm = formBuilder.group({
      Id: ['', ''],
      Nome: ['', ''],
      Evento: ['', ''],
      Datainscricao: ['', ''],
      Datapagamento: ['', ''],
      Datanascimento: ['', ''],
      Sexo: ['', ''],
      Email: ['', ''],
      Celular: ['', ''],
      Validacao: ['', ''],
      Pagamento: ['', ''],
      Valor: ['', ''],
      Ativo: ['', ''],
      Certificado: ['', ''],
      Conselho: ['', ''],
      Observacao: ['', ''],
      Curso: ['', ''],

      Dia: ['', ''],
      Mes: ['', ''],
      Ano: ['', ''],
    })

  }

  ngOnInit(
  ) {
    this.isCordova = this.platform.is('cordova');

    this.IdEvento = this.navParams.get('IdEvento');
    this.NomeEvento = this.navParams.get('NomeEvento');
    this.Datainicial = this.navParams.get('Datainicial');
    this.Datafinal = this.navParams.get('Datafinal');
    this.ImagemEvento = this.navParams.get('ImagemEvento');
    this.stringBase64Logo();
    this.selecionarSexo();
    this.selecionarConselho();
    this.selecionarCursoEvento();
    this.selecionarEventoUsuarioConselho();
    this.selecionarEventoUsuario(0);
  }

  async selecionarCursoEvento() {
    this.estudoService.selecionarCursoEvento(this.IdEvento).then((response: any) => {
      if (response.length > 0) {
        this.aCurso = response;
      }
    })

  }

  async selecionarSexo() {
    this.tabelaService.selecionarTabela('Sexo', 'Id-Nome').then((response: any) => {
      this.aSexo = response;
    });

  }

  async stringBase64Logo() {
    if (this.utilService.obterUrlDaApi() == "http://localhost:53622/api/" ){
      return;
    }
    this.utilService.obterFotoEmStringBase64('logo.png').then((stringbase64: any) => {
      this.sBase64Logo = 'data:image/jpeg;base64,' + stringbase64
      this.selecionarEventoUsuario(-1);

    })

  }

  async abrirPdf(pdf: any) {
    window.open(pdf)
  }

  async selecionarConselho() {
    this.tabelaService.selecionarTabela('Conselho', 'Id-Nome').then((response: any) => {
      this.aConselho = response;
    });
  }

  async testarDados() {

    let loading = await this.loadingController.create({ message: "Verificando dados...", mode: 'ios' });
    loading.present();
    this.lRet = this.utilService.validarCampo("Nome", this.eventoedicaoForm.value.Nome, () => {
      this.utilService.focusInput(this.ancoraNome);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.validarCampo("Celular", this.eventoedicaoForm.value.Celular.toString(), () => {
      this.utilService.focusInput(this.ancoraCelular);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }


    this.lRet = this.utilService.validarCampo("Datanascimento", this.eventoedicaoForm.value.Datanascimento, () => {
      this.utilService.focusInput(this.ancoraDia);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }



    this.lRet = this.utilService.validarCampo("Datapagamento", this.eventoedicaoForm.value.Datapagamento, () => {
      this.utilService.focusInput(this.ancoraDia);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.validarCampo("Dataemissao", this.eventoedicaoForm.value.Dataemissao, () => {
      this.utilService.focusInput(this.ancoraDia);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }

    this.lRet = this.utilService.validarCampo("Sexo", this.eventoedicaoForm.value.Sexo, () => {
      this.utilService.focusInput(this.ancoraEmail);
    });

    if (this.lRet == false) {
      loading.dismiss();
      return;
    }


    this.lRet = this.utilService.checkMail(this.eventoedicaoForm.value.Email, () => {
      (this.ancoraEmail as any).setFocus(true);
    });

    if (this.eventoedicaoForm.value.Curso == '' ||
      this.eventoedicaoForm.value.Curso == undefined ||
      this.eventoedicaoForm.value.Curso == null
    ) {
      this.utilService.showAlertCallback('Você precisa escolher pelo menos um curso do evento')
      loading.dismiss();
      return;
    }

    let Nome = '';
    let Celular = '';
    let Email = '';
    let Observacao = this.eventoedicaoForm.value.Observacao;;

    let Datanascimento = this.utilService.checaData(this.eventoedicaoForm.value.Datanascimento)
    let Datainscricao = this.utilService.checaData(this.eventoedicaoForm.value.Datainscricao)
    let Datapagamento = this.utilService.checaData(this.eventoedicaoForm.value.Datapagamento)
    let Pagamento = this.eventoedicaoForm.value.Pagamento;
    let Ativo = this.eventoedicaoForm.value.Ativo;
    let Certificado = this.eventoedicaoForm.value.Certificado;

    if (Pagamento == true) {
      Pagamento = 1
    } else {
      Pagamento = 0
    }

    if (Ativo == true) {
      Ativo = 1
    } else {
      Ativo = 0
    }

    if (Certificado == true) {
      Certificado = 1
    } else {
      Certificado = 0
    }

    this.utilService.Criptografar(this.eventoedicaoForm.value.Nome)
      .then((response) => {
        Nome = response;

      })

    this.utilService.Criptografar(this.eventoedicaoForm.value.Email)
      .then((response) => {
        Email = response;

      })

    this.utilService.Criptografar(this.eventoedicaoForm.value.Celular.toString())
      .then((celular) => {
        Celular = celular;

      })
    if (Observacao != '' && Observacao != null && Observacao != undefined) {
      this.utilService.Criptografar(this.eventoedicaoForm.value.Observacao.toString())
        .then((observacao) => {
          Observacao = observacao;

        })
    }



    setTimeout(() => {
      let form = {
        Id: this.eventoedicaoForm.value.Id,//
        Nome: Nome, //
        Evento: this.eventoedicaoForm.value.Evento,//
        Datainscricao: Datainscricao,//
        Datapagamento: Datapagamento,//
        Datanascimento: Datanascimento,//
        Sexo: this.eventoedicaoForm.value.Sexo,//
        Email: Email,//
        Celular: Celular,//
        Validacao: this.eventoedicaoForm.value.Validacao,//
        Pagamento: Pagamento,//
        Valor: this.eventoedicaoForm.value.Valor,//
        Ativo: Ativo,//
        Certificado: Certificado,//
        Conselho: this.eventoedicaoForm.value.Conselho,//
        Observacao: Observacao, //
        Curso: this.eventoedicaoForm.value.Curso.toString()
      }





      loading.dismiss();
      this.presentAlertConfirm(form);


    }, 2000); //1 seg

  }

  

  async presentAlertConfirm(form: any) {
    let Adicionar:number=1;
    let message: string = 'Confirma a ADIÇÃO nesse evento desse usuário?'
    
    if (this.eventoedicaoForm.value.Id > 0) {
      message = 'Confirma a ATUALIZAÇÃO desse usuário?'
      Adicionar=2;
    }
    const alert = await this.alertController.create({
      header: 'INSCRIÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            if (Adicionar == 1) {
              this.adicionarEventoUsuario2(form)

            }else{
              this.atualizarEventoUsuario2(form)

            }

          }
        }
      ]
    });
    await alert.present();
  }

  async adicionarEventoUsuario2(form: any) {
    console.log(JSON.stringify(form))
    this.estudoService.adicionarEventoUsuario2(form).then((response: any) => {
      this.utilService.showAlertCallback("Inscrição ADICIONADA com sucesso", () => {
        this.selecionarEventoUsuario(response[0].Id)
      })

    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível ADICIONAR sua inscrição")
    })
  }

  async selecionaToggle() {

  }
  async atualizarEventoUsuario2(form: any) {
    this.estudoService.atualizarEventoUsuario2(form).then((response: any) => {
      this.utilService.showAlertCallback("Inscrição ATUALIZADA com sucesso", () => {
        this.selecionarEventoUsuario(response[0].Id)
      })

    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível concluir sua inscrição")
    })
  }

  async selecionarEventoUsuarioUnico(id: number) {

    if (id == -1) {
      this.limpaDados();
      return;
    }

    this.Botao="ATUALIZAR";
    let aUsuarioUnico = this.usuarioColection.filter((a: any) => {
      return a.Id == id;
    });
    this.Evento = aUsuarioUnico[0].Evento;
    this.aInscricao = [];
    this.aInscricao = {
      Id: aUsuarioUnico[0].Id,
      NomeInscrito: aUsuarioUnico[0].Nome,
      NomeEvento: this.NomeEvento,
      DescricaoEvento: '',
      ProgramacaoEvento: '',
      PalestranteEvento: '',
      DataInicial: this.Datainicial,
      DataFinal: this.Datafinal,
      ImagemUsuario: aUsuarioUnico[0].Imagem,
      Imagem64: aUsuarioUnico[0].Imagem64,
      Valor: aUsuarioUnico[0].Valor,
      Carga: aUsuarioUnico[0].Carga,
    }
    let Datanascimento = aUsuarioUnico[0].Datanascimento;
    let Dia = Datanascimento.substring(0, 2);
    let Mes = Datanascimento.substring(3, 5);
    let Ano = Datanascimento.substring(6, 10);
    Datanascimento = this.utilService.getDataBrowser(Datanascimento);
    let Datainscricao = "";

    if (aUsuarioUnico[0].Datainscricao != '') {
      Datainscricao = this.utilService.getDataBrowser(aUsuarioUnico[0].Datainscricao);
    }

    let Datapagamento = "";

    if (aUsuarioUnico[0].Datapagamento != '') {
      Datapagamento = this.utilService.getDataBrowser(aUsuarioUnico[0].Datapagamento);
    }
    let Pagamento = false;
    let Ativo = false;
    let Certificado = false;
    if (aUsuarioUnico[0].Pagamento == 1) {
      Pagamento = true;
    }
    if (aUsuarioUnico[0].Certificado == 1) {
      Certificado = true;
    }
    if (aUsuarioUnico[0].Ativo == 1) {
      Ativo = true;
    }

    let Curso: any = [];
    Curso = JSON.parse('[' + aUsuarioUnico[0].Curso + ']')

    let Valor: any = aUsuarioUnico[0].Valor;
    Valor = this.utilService.formateNumberNew(Valor, 2, 1);
    this.eventoedicaoForm.controls['Nome'].setValue(aUsuarioUnico[0].Nome);
    this.eventoedicaoForm.controls['Evento'].setValue(aUsuarioUnico[0].Evento);
    this.eventoedicaoForm.controls['Datainscricao'].setValue(Datainscricao);
    this.eventoedicaoForm.controls['Datapagamento'].setValue(Datapagamento);
    this.eventoedicaoForm.controls['Datanascimento'].setValue(Datanascimento);
    this.eventoedicaoForm.controls['Sexo'].setValue(aUsuarioUnico[0].Sexo);
    this.eventoedicaoForm.controls['Email'].setValue(aUsuarioUnico[0].Email);
    this.eventoedicaoForm.controls['Celular'].setValue(aUsuarioUnico[0].Celular);
    this.eventoedicaoForm.controls['Validacao'].setValue(aUsuarioUnico[0].Validacao);
    this.eventoedicaoForm.controls['Pagamento'].setValue(Pagamento);
    this.eventoedicaoForm.controls['Valor'].setValue(Valor);
    this.eventoedicaoForm.controls['Ativo'].setValue(Ativo);
    this.eventoedicaoForm.controls['Certificado'].setValue(Certificado);
    this.eventoedicaoForm.controls['Conselho'].setValue(aUsuarioUnico[0].Conselho);
    this.eventoedicaoForm.controls['Observacao'].setValue(aUsuarioUnico[0].Observacao);
    this.eventoedicaoForm.controls['Curso'].setValue(Curso);
    this.eventoedicaoForm.controls['Dia'].setValue(Dia);
    this.eventoedicaoForm.controls['Mes'].setValue(Mes);
    this.eventoedicaoForm.controls['Ano'].setValue(Ano);
    this.utilService.focusInput(this.ancoraNome);
    if (aUsuarioUnico[0].Imagem != '' && aUsuarioUnico[0].Imagem != undefined && aUsuarioUnico[0].Imagem != null) {
      this.Imagem = this.utilService.obterUrlDaImagemDaApi() + aUsuarioUnico[0].Imagem;
    } else {
      this.Imagem = '';
    }
    if (aUsuarioUnico[0].Imagempagamento != '' && aUsuarioUnico[0].Imagempagamento != undefined && aUsuarioUnico[0].Imagempagamento != null) {
      this.Imagempagamento = this.utilService.obterUrlDaImagemDaApi() + aUsuarioUnico[0].Imagempagamento;
    } else {
      this.Imagempagamento = '';
    }

  }


  async limpaDados() {
    console.log("No limpa dados");
   // this.eventoedicaoForm.controls['Id'].setValue('-1');
   this.Id = -1;
   let Datainscricao:any = this.utilService.dataHoje();
   this.eventoedicaoForm.controls['Nome'].setValue('');
    this.eventoedicaoForm.controls['Evento'].setValue(this.IdEvento);
    this.eventoedicaoForm.controls['Datainscricao'].setValue(Datainscricao);
    this.eventoedicaoForm.controls['Datapagamento'].setValue(Datainscricao);
    this.eventoedicaoForm.controls['Datanascimento'].setValue('');
    this.eventoedicaoForm.controls['Sexo'].setValue('');
    this.eventoedicaoForm.controls['Email'].setValue('');
    this.eventoedicaoForm.controls['Celular'].setValue('');
    this.eventoedicaoForm.controls['Validacao'].setValue('');
    this.eventoedicaoForm.controls['Pagamento'].setValue(false);
    this.eventoedicaoForm.controls['Valor'].setValue(0);
    this.eventoedicaoForm.controls['Ativo'].setValue(false);
    this.eventoedicaoForm.controls['Certificado'].setValue(false);
    this.eventoedicaoForm.controls['Conselho'].setValue('');
    this.eventoedicaoForm.controls['Observacao'].setValue('');

    this.eventoedicaoForm.controls['Dia'].setValue('');
    this.eventoedicaoForm.controls['Mes'].setValue('');
    this.eventoedicaoForm.controls['Ano'].setValue('');
    this.utilService.focusInput(this.ancoraNome);
  }

  async selecionarNaoBaixados() {
    this.usuarioColectionAtual = this.usuarioColection.filter((a: any) => {
      return (a.Pagamento == 0 && a.Imagempagamento != '' && a.Imagempagamento != null && a.Imagempagamento != undefined)
    })
  }



  async selecionarEventoUsuario(Id: number) {
    let loading = await this.loadingController.create({ message: "aguarde a api...", mode: 'ios' });
    loading.present();
    let x: number = 0;
    this.estudoService.selecionarEventoUsuario(this.IdEvento).then((response: any) => {
      for (let i = 0; i < response.length; i++) {
        this.utilService.Descriptografar(response[i].Nome).then((n: string) => {
          response[i].Nome = n;
        })
        this.utilService.Descriptografar(response[i].Email).then((e: string) => {
          response[i].Email = e;
        })
        this.utilService.Descriptografar(response[i].Celular).then((c: string) => {
          response[i].Celular = c;
        })

        this.utilService.Descriptografar(response[i].Observacao).then((o: string) => {
          response[i].Observacao = o;
        })

        response[i].Imagem64 = this.sBase64Logo;
        // imagens dos inscritos


      }
      setTimeout(() => {
        response = response.filter((a: any) => {
          return a.Nome != '' && a.Nome != null && a.Nome != undefined
        })
        this.usuarioColection = response;
        this.usuarioColectionAtual = response;
        if (x == response.length - 1) {
          this.utilService.showAlertCallback("Imagens geradas")
        }

        if (Id > 0) {
          this.eventoedicaoForm.controls['Id'].setValue(Id);
          this.selecionarEventoUsuarioUnico(Id)
        }else{
          this.limpaDados();

        }


      }, 1000);

    }).finally(() => {
      loading.dismiss();

    })

  }


  async adicionarBase64Todos() {
    let loading = await this.loadingController.create({ message: "preparando dados...", mode: 'ios' });
    loading.present();
    let imgUsuario: any;
    let lFazer: boolean = true
    let x: number = 0;
    for (let i = 0; i < this.usuarioColection.length; i++) {
      imgUsuario = this.usuarioColection[i].Imagem
      lFazer = true
      if (imgUsuario == '' || imgUsuario == null || imgUsuario == undefined) {
        lFazer = false;
      }
      imgUsuario = this.utilService.obterUrlDaImagemDaApi() + imgUsuario
      if (imgUsuario.indexOf('localhost') > -1) {
        lFazer = false;
      }
      this.utilService.verificarArquivo(imgUsuario, (achou) => {
        if (achou == false) {
          lFazer = false;
        }
      });
      if (lFazer == true) {
        imgUsuario = imgUsuario.split('/');
        imgUsuario = imgUsuario[imgUsuario.length - 1]
        if (imgUsuario.toUpperCase().indexOf("PNG") > 0 || imgUsuario.toUpperCase().indexOf("JPEG") > 0 || imgUsuario.toUpperCase().indexOf("JPG") > 0) {
          this.utilService.obterFotoEmStringBase64(imgUsuario).then((stringbase64: any) => {
            this.usuarioColection[i].Imagem64 = 'data:image/jpeg;base64,' + stringbase64
            x++;
            this.utilService.showToastCallBack("Imagem gerda com sucesso: " + x, 1000, null)
          })
        }
      }
    }
    loading.dismiss();
  }

  async selecionarEventoUsuarioConselho() {
    let loading = await this.loadingController.create({ message: "aguarde a api...", mode: 'ios' });
    loading.present();
    this.estudoService.selecionarEventoUsuarioConselho(this.IdEvento).then((response: any) => {
      this.aResumo = response;


    }).catch(() => {


    }).finally(() => {
      loading.dismiss();
    })

  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {
    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }




    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async gerarPdfPagamento() {
    // 0-6 (zero=domingo)
    let dtHoje = this.utilService.dataHojeSemHoraBrasil();
    if (this.aInscricao.Imagem == '') {
      this.utilService.showAlertCallback("o PDF do pagamento não poderá ser gerado sem a imagem do inscrito")
      return;
    }

    // converter a imagem aqui
    this.utilpdfService.criaDefinicaoPdfPagamento(this.aInscricao, dtHoje, dtHoje.substring(10, 16)).then((response: any) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.stringBase64Pdf = stringBase64Pdf;
        this.downloadPdf(stringBase64Pdf);
        this.presentAlertConfirmEmailPagamento();

      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })

  }

  async gerarPdfUsuariosEvento() {

    let dtHoje = this.utilService.dataHojeSemHoraBrasil();
    let aUsuario: any = this.usuarioColection.sort((x, y) => {
      let a = x.Nome.toUpperCase();
      let b = y.Nome.toUpperCase();
      return a == b ? 0 : a > b ? 1 : -1;
    })


    this.utilpdfService.criaDefinicaoPdfRelatorioEventoUsuarios(aUsuario, this.aResumo, this.NomeEvento, dtHoje).then((response: any) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.stringBase64Pdf = stringBase64Pdf;
        this.downloadPdf(stringBase64Pdf);

      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })

  }

  async gerarPdfCertificado() {
    // 0-6 (zero=domingo)

    if (this.aInscricao.ImagemUsuario != '' && this.aInscricao.ImagemUsuario != undefined && this.aInscricao.ImagemUsuario != null) {
      this.utilService.obterFotoEmStringBase64(this.aInscricao.ImagemUsuario).then((stringbase64: any) => {
        this.aInscricao.Imagem64 = 'data:image/jpeg;base64,' + stringbase64
        this.preparaPdfCertificado();
      })
    }else{
      this.preparaPdfCertificado();
    }



  }

  async preparaPdfCertificado() {
    let dtHoje = this.utilService.dataHojeSemHoraBrasil();

    this.utilpdfService.criaDefinicaoPdfCertificado(this.aInscricao, dtHoje, dtHoje.substring(10, 16)).then((response: any) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.stringBase64Pdf = stringBase64Pdf;
        this.downloadPdf(stringBase64Pdf);

      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })
  }

  downloadPdf(stringBase64Pdf: any) {
    if (this.isCordova) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });
        // Save the PDF to the data Directory of our App
        this.file.writeFile(this.file.dataDirectory, 'listmed.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          this.fileOpener.open(this.file.dataDirectory + 'listmed.pdf', 'application/pdf');
          this.utilService.showAlertCallback("Geramos um PDF do certificado para você");
        })
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download();

      this.utilService.showAlertCallback("Geramos um PDF do certificado para você");
    }
  }

  async presentAlertConfirmEmailPagamento() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'EMAIL DE CONFIRMAÇÃO DE PAGAMENTO',
      message: 'Confirma envio?',
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraObservacao'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.EnviarEmailListMedPagamento();
          }
        }
      ]
    });
    await alert.present();
  }

  async presentAlertConfirmEmailCobrancaUnico() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'EMAIL DE COBRANÇA ÚNICO',
      message: 'Confirma envio?',
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.EnviarEmailListMedCobrancaUnico();
          }
        }
      ]
    });
    await alert.present();
  }

  async presentAlertConfirmEmailCobrancaTodos() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'EMAIL DE COBRANÇA PARA TODOS',
      message: 'Confirma envio?',
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.EnviarEmailListMedCobrancaTodos();
          }
        }
      ]
    });
    await alert.present();
  }

  async presentAlertConfirmParticipantes() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'RELATÓRIO DE TODOS INSCRITOS',
      message: 'Confirma emissão?',
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',

          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.gerarPdfUsuariosEvento();
          }
        }
      ]
    });
    await alert.present();
  }

  async EnviarEmailListMedPagamento() {
    // essa rotina no roda com a API em produção
    let loading = await this.loadingController.create({ message: "Enviando...", mode: 'ios' });
    loading.present();

    let Email: string = this.eventoedicaoForm.value.Email;
    let lRet = this.utilService.checkMail(Email, () => {
      (this.ancoraEmail as any).setFocus(true);
    });

    if (lRet == false) {
      loading.dismiss();
      return;
    }

    this.Link = 'https://listmed.com.br/#/eventousuario/' + this.IdEvento.toString() + '/' + this.Validacao.toString() + '/' + Email;
    let Mensagem: string = 'Olá <strong>' + this.eventoedicaoForm.value.Nome + '</strong>:<br><br>'
    Mensagem = Mensagem + 'Ficamos muito felizes por você ter finalizado sua inscrição conosco para o evento:<br><br>'
    Mensagem = Mensagem + '<strong>' + this.NomeEvento.toUpperCase() + '</strong>.<br><br>'
    Mensagem = Mensagem + '<strong>Agora é só se preparar para o evento que terá um teste no final com os temas do simpósio</strong><br><br>'
    Mensagem = Mensagem + '<strong>Caso você tenha nota maior ou igual a sete você vai recuperar seu dinheiro investido em créditos no ListMed.</strong><br><br>'
    Mensagem = Mensagem + 'O PDF em anexo contém todos os detalhes do evento que você irá participar. Nos ajude a divulgar<br><br>'
    this.Mensagem = Mensagem;

    if (this.stringBase64Pdf == "") {
      this.utilService.showAlertCallback("É necessário gerar o PDF para enviar por email")
      loading.dismiss();
      return;
    }

    this.utilService.EnviarEmailListMed(
      Email,
      'CONFIRMAÇÃO DE PAGAMENTO',
      'Meu pagamento LISTMED',
      this.Mensagem,
      this.ImagemEvento,
      this.stringBase64Pdf,
      this.Link
    )
      .then((response: any) => {
        this.utilService.showAlertCallback("Foi enviado um email para você com seu pagamento", () => {
          this.utilService.focusInput(this.ancoraNome);
          // this.MostrarHelp = 1;
        });
      }).catch((response) => {
        this.utilService.showAlertCallback('Não foi possivel enviar o email para o email indicado.', null);
      }).finally(() => {
        loading.dismiss()
        this.Checado = 1;
      });
  }

  async EnviarEmailListMedCobrancaUnico() {
    // essa rotina no roda com a API em produção
    let loading = await this.loadingController.create({ message: "Enviando...", mode: 'ios' });
    loading.present();

    let Email: string = this.eventoedicaoForm.value.Email;
    let lRet = this.utilService.checkMail(Email, () => {
      (this.ancoraEmail as any).setFocus(true);
    });

    if (lRet == false) {
      loading.dismiss();
      return;
    }
    let Pagamento: boolean = this.eventoedicaoForm.value.Pagamento;

    if (Pagamento == true) {
      this.utilService.showAlertCallback("Pagamento já informado;")
      loading.dismiss();
      return;
    }

    this.Link = 'https://listmed.com.br/#/eventousuario/' + this.IdEvento.toString() + '/' + this.Validacao.toString() + '/' + Email;
    let Mensagem: string = 'Olá <strong>' + this.eventoedicaoForm.value.Nome + '</strong>:<br><br>'
    Mensagem = Mensagem + 'Ficamos muito felizes por você ter INICIADO sua inscrição conosco para o evento:<br><br>'
    Mensagem = Mensagem + '<strong>' + this.NomeEvento.toUpperCase() + '</strong>.<br><br>'
    Mensagem = Mensagem + '<strong>Você precisa concluir o processo de inscrição para garantir sua vaga. Clique na imagem para concluir sua incrição.</strong><br><br>'
    Mensagem = Mensagem + 'Caso você já tenha realizado essa etapa, desconsidere essa memnsagem.<br><br>'
    this.Mensagem = Mensagem;

    this.utilService.EnviarEmailListMed(
      Email,
      'CONFIRME SUA INSCRIÇÃO',
      'Inscrição LISTMED',
      this.Mensagem,
      this.ImagemEvento,
      '',
      this.Link
    )
      .then((response: any) => {
        this.utilService.showAlertCallback("Foi enviado um email solicitando conclusão da inscrição", () => {
          this.utilService.focusInput(this.ancoraNome);
          // this.MostrarHelp = 1;
        });
      }).catch((response) => {
        this.utilService.showAlertCallback('Não foi possivel enviar o email para o email indicado.', null);
      }).finally(() => {
        loading.dismiss()
        this.Checado = 1;
      });
  }

  async EnviarEmailListMedCobrancaTodos() {
    // essa rotina no roda com a API em produção
    let loading = await this.loadingController.create({ message: "Enviando...", mode: 'ios' });
    loading.present();


    let aCobranca: any = this.usuarioColection.filter((a: any) => {
      return a.Pagamento == 0 && (a.Imagempagamento == '' || a.Imagempagamento == undefined || a.Imagempagamento == null)
    })

    let Email: string = '';
    let Nome: string = '';
    let x: number = 0;
    let Validacao: number = 0;
    for (let i = 0; i < aCobranca.length; i++) {
      Email = aCobranca[i].Email;
      Nome = aCobranca[i].Nome;
      Validacao = aCobranca[i].Validacao;
      if (Nome != '' && Email != '' && Validacao > 0) {
        this.enviarEmailParametros(Email, Nome, Validacao);
        x++;
      }
    }
    loading.dismiss();
    this.utilService.showAlertCallback("Foram enviados: " + x + " email de cobrança")
  }

  async enviarEmailParametros(Email: string, Nome: string, Validacao: number) {
    this.Link = 'https://listmed.com.br/#/eventousuario/' + this.IdEvento.toString() + '/' + Validacao.toString() + '/' + Email;
    let Mensagem: string = 'Olá <strong>' + Nome + '</strong>:<br><br>'
    Mensagem = Mensagem + 'Ficamos muito felizes por você ter INICIADO sua inscrição conosco para o evento:<br><br>'
    Mensagem = Mensagem + '<strong>' + this.NomeEvento.toUpperCase() + '</strong>.<br><br>'
    Mensagem = Mensagem + '<strong>Você precisa concluir o processo de inscrição para garantir sua vaga. Clique na imagem para concluir sua incrição.</strong><br><br>'
    Mensagem = Mensagem + 'Caso você já tenha realizado essa etapa, desconsidere essa memnsagem.<br><br>'
    this.Mensagem = Mensagem;

    this.utilService.EnviarEmailListMed(
      Email,
      'CONFIRME SUA INSCRIÇÃO',
      'Inscrição LISTMED',
      this.Mensagem,
      this.ImagemEvento,
      '',
      this.Link
    )
      .then((response: any) => {
        this.utilService.showToastCallBack("Foi enviado um email solicitando conclusão da inscrição", 1000, null)
      }).catch((response) => {
        this.utilService.showToastCallBack('Não foi possivel enviar o email para o email indicado.', 1000, null);
      })

  }

  async selecionarTodos() {
    this.usuarioColectionAtual = this.usuarioColection;
  }

  async ajustaData() {

    let Dia: any = this.eventoedicaoForm.value.Dia;
    let Mes: any = this.eventoedicaoForm.value.Mes;
    let Ano: any = this.eventoedicaoForm.value.Ano;
    let lRet: any = true


    if (Dia == '' || Dia == undefined || Dia == null) {
      this.utilService.showAlertCallback("Dia não informado", () => {
        this.utilService.focusInput(this.ancoraDia);

      })
      return;
    }

    if (Mes == '' || Mes == undefined || Mes == null) {
      this.utilService.showAlertCallback("Mês não informado", () => {
        this.utilService.focusInput(this.ancoraMes);

      })
      return;
    }

    if (Ano == '' || Ano == undefined || Ano == null) {
      this.utilService.showAlertCallback("Ano não informado", () => {
        this.utilService.focusInput(this.ancoraAno);

      })
      return;
    }

    if (Dia > 31 || Dia < 1) {
      this.utilService.showAlertCallback("Dia inválido", () => {
        this.utilService.focusInput(this.ancoraDia);

      })
      return;
    }

    if (Mes > 12 || Mes < 1) {
      this.utilService.showAlertCallback("Mês inválido", () => {
        this.utilService.focusInput(this.ancoraMes);

      })
      return;
    }

    if (Dia < 10) {
      Dia = "0" + Dia;
    }

    if (Mes < 10) {
      Mes = "0" + Mes;
    }

    let data: any = new Date();
    let ano: any = data.getFullYear();

      if (Ano > ano) {
        this.utilService.showAlertCallback("Ano inválido ou maior que " + ano, () => {
          this.utilService.focusInput(this.ancoraAno);
        })

        return;
      }

      let Datanascimento = Ano + "-" + Mes + "-" + Dia

      this.eventoedicaoForm.controls['Datanascimento'].setValue(Datanascimento);


    }
  }



