import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient, private utilService: UtilService) { }

 

  adicionarUsuario(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/adicionarUsuario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarEmail(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/adicionarEmail';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarUsuario(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/atualizarUsuario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarUsuarioSenha(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/atualizarUsuarioSenha';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  validarUsuario(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/validarUsuario';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // esse é o token
  fazerLogin(email:string, senha:string) { // usuario só contem o código de validação e o id
    let url = "";
    url = this.utilService.obterUrlDaApi() + 'token'
    let request = "grant_type=password&username=" + email + "&password=" + senha  ;
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(url, request, headers).toPromise();
  }

  selecionarUsuarioUnico(Id:number) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/SelecionarUsuarioUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  selecionarUsuarioDatanascimento(Datanascimento:string) {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/SelecionarUsuarioDatanascimento/' + Datanascimento;
    return this.http.get(url).toPromise();
  }

  selecionarUsuarioEmail() {
    let url = this.utilService.obterUrlDaApi() + 'Usuario/selecionarUsuarioEmail' ;
    return this.http.get(url).toPromise();
  }
  

}
