import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LancamentoService } from '../../services/lancamento.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-ventilacao',
  templateUrl: './ventilacao.component.html',
  styleUrls: ['./ventilacao.component.scss'],
})
export class VentilacaoComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraDatasuspensao', null) ancoraDatasuspensao: ElementRef;
  @ViewChild('ancoraModo', null) ancoraModo: ElementRef;
  @ViewChild('ancoraModalidade', null) ancoraModalidade: ElementRef;
  @ViewChild('ancoraVia', null) ancoraVia: ElementRef;
  @ViewChild('ancoraPressaoinspiratoria', null) ancoraPressaoinspiratoria: ElementRef;
  @ViewChild('ancoraPeep', null) ancoraPeep: ElementRef;
  @ViewChild('ancoraPeepi', null) ancoraPeepi: ElementRef;
  @ViewChild('ancoraFiO2', null) ancoraFiO2: ElementRef;
  @ViewChild('ancoraPressaoplato', null) ancoraPressaoplato: ElementRef;
  @ViewChild('ancoraFrventilador', null) ancoraFrventilador: ElementRef;
  @ViewChild('ancoraFrpaciente', null) ancoraFrpaciente: ElementRef;
  @ViewChild('ancorFiO2', null) ancorFiO2: ElementRef;
  @ViewChild('ancoraVolumeinspiratorio', null) ancoraVolumeinspiratorio: ElementRef;
  @ViewChild('ancoraVolumeexpiratorio', null) ancoraVolumeexpiratorio: ElementRef;
  @ViewChild('ancoraFluxo', null) ancoraFluxo: ElementRef;
  @ViewChild('ancoraTempoinspiratorio', null) ancoraTempoinspiratorio: ElementRef;
  @ViewChild('ancoraRelacaoI', null) ancoraRelacaoI: ElementRef;
  @ViewChild('ancoraRelacaoE', null) ancoraRelacaoE: ElementRef;
  @ViewChild('ancoraAssincronia', null) ancoraAssincronia: ElementRef;
  @ViewChild('ancoraSensibilidade', null) ancoraSensibilidade: ElementRef;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;
  @ViewChild('ancoraDiasproposto', null) ancoraDiasproposto: ElementRef;
  Id: number = 0;
  Exames: any;
  Datalancamento: string = "";
  Usuario: number = 0;
  Paciente: number = 0;
  Idade: string = "";
  Nome: string = "";
  Internacao: number = 0;
  Setor: string = "";
  Leito: string = "";
  ventilacaoForm: FormGroup;
  Botaoventilacao: string = "Cadastrar";
  Datainternacao: string = "";
  Dias: string = "";
  ventilacaoCollection: any = [];
  dadosListMed: any;
  Logado: number = 0;
  Modo: number = 0;
  Modalidade: number = 0;
  Via: number = 0;
  Pressaoinspiratoria: number = 0;
  Peep: number = 0;
  Peepi: number = 0;
  Pressaoplato: number = 0;
  Frventilador: number = 0;
  Frpaciente: number = 0;
  FiO2: number = 0;
  Volumeinspiratorio: number = 0;
  Volumeexpiratorio: number = 0;
  Fluxo: number = 0;
  Tempoinspiratorio: number = 0;
  RelacaoI: number = 0;
  RelacaoE: number = 0;
  Diasproposto: number = 0;
  Sensibilidade: number = 0;
  Observacao: string = "";
  Peso: number = 0;
  Altura: number = 0;
  Sexo: number = 0;
  Pesopredito: number = 0;
  Volumecorrenteminimo: number = 0;
  Volumecorrentemaximo: number = 0;
  Pressaoinspiratoriamaxima: number = 0;
  Pressaoexpiratoriamaxima: number = 0;
  Pressaopico: number = 0;
  Complacenciaestatica: number = 0;
  Complacenciadinamica: number = 0;
  Drivepressure: number = 0;
  Volumeofertado: number = 0;
  posicaoColection: any = [];
  modoColection: any = [];
  modalidadeColection: any = [];
  assincroniaColection: any = [];
  viaColection: any = [];
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  PesoIdealMasculino: string = '';
  PesoIdealFeminino: string = '';
  Assincronia:any;

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private lancamentoService: LancamentoService,
    private alertController: AlertController,
    private checklistService:ChecklistService

  ) {

    this.ventilacaoForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Datasuspensao: ['', ''],
      Modo: ['', ''],
      Modalidade: ['', ''],
      Via: ['', ''],
      Pressaoinspiratoria: ['', ''],
      Peep: ['', ''],
      Peepi: ['', ''],
      Pressaoplato: ['', ''],
      Frventilador: ['', ''],
      Frpaciente: ['', ''],
      FiO2: ['', ''],
      Volumeinspiratorio: ['', ''],
      Volumeexpiratorio: ['', ''],
      Fluxo: ['', ''],
      Tempoinspiratorio: ['', ''],
      RelacaoI: ['', ''],
      RelacaoE: ['', ''],
      Assincronia: ['', ''],
      Posicao: ['', ''],
      Diasproposto: ['', ''],
      Sensibilidade: ['', ''],
      Observacao: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Datalancamento = this.navParams.get('Dataevolucao');
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.FotoAtualPaciente = this.navParams.get('FotoAtualPaciente');
  }

  ngOnInit() {
    this.posicaoColection = [
      { Nome: 'Em curso', Id: 1 },
      { Nome: 'Contra-indicado', Id: 2 },
      { Nome: 'Suspenso temporariamento', Id: 3 },
      { Nome: 'Tratamento concluído', Id: 4 },
    ]
    this.modoColection = [
      { Nome: 'VM Controlado', Id: 1 },
      { Nome: 'VM Assistido', Id: 2 },
      { Nome: 'VM Assistido controlado', Id: 3 },
      { Nome: 'VM Não invasiva', Id: 4 },
    ]
    this.modalidadeColection = [
      { Nome: 'PCV', Id: 1 },
      { Nome: 'VCV', Id: 2 },
      { Nome: 'PSV', Id: 3 },
      { Nome: 'PSV-AV', Id: 4 },
      { Nome: 'Bilavel', Id: 5 },
      { Nome: 'CPAP', Id: 6 },
      { Nome: 'NAVA', Id: 7 },
      { Nome: 'SIMV', Id: 8 },
      { Nome: 'MMV', Id: 9 },
      { Nome: 'Outra', Id: 10 },
    ]
    this.viaColection = [
      { Nome: 'Orotraqueal', Id: 1 },
      { Nome: 'Traqueostomia', Id: 2 },
      { Nome: 'Nasotraqueal', Id: 3 },
      { Nome: 'Máscara fascial', Id: 4 },
      { Nome: 'Helmet', Id: 5 },
      { Nome: 'Máscara laringea', Id: 6 },
      { Nome: 'Tubo laringeo', Id: 7 },
      { Nome: 'Cricotireodostomia', Id: 8 },
    ]
    this.assincroniaColection = [
      { Nome: 'Sem assincronias', Id: 11 },
      { Nome: 'Duplo disparo', Id: 1 },
      { Nome: 'Disparo reverso', Id: 2 },
      { Nome: 'Ciclagem precoce', Id: 3 },
      { Nome: 'Fluxo excessivo', Id: 4 },
      { Nome: 'Secreção na via aerea', Id: 5 },
      { Nome: 'Vazamento no circuito', Id: 6 },
      { Nome: 'Disparo insuficiênte', Id: 7 },
      { Nome: 'Ciclagem tardia', Id: 8 },
      { Nome: 'Fluxo insuficiênte', Id: 9 },
      { Nome: 'Auto-PEEP', Id: 10 },
    ]

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.limpaDados();
    this.selecionarVentilacao(0);
  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Idade = this.dadosListMed.Internacao.Idade;
      this.Nome = this.dadosListMed.Internacao[0].Nome;
      this.Internacao = this.dadosListMed.Internacao.Id;;
      this.Setor = (this.dadosListMed.Internacao[0].Setor);
      this.Leito = (this.dadosListMed.Internacao[0].Leito);
      this.Datainternacao = this.dadosListMed.Internacao[0].Datainternacao;
      this.Dias = this.dadosListMed.Internacao[0].Dias;
      this.PesoIdealMasculino = this.dadosListMed.Internacao[0].PesoIdealMasculino;
      this.PesoIdealFeminino = this.dadosListMed.Internacao[0].PesoIdealFeminino;

    }
  }

  async presentAlertConfirm() {
    if (this.ventilacaoForm.value.Modo == '' || this.ventilacaoForm.value.Modo == undefined || this.ventilacaoForm.value.Modo == null) {
      this.utilService.showAlertCallback("Você precisa informar o Moda da ventilação mecânica", null);
      return;
    }
    if (this.ventilacaoForm.value.Modalidade == '' || this.ventilacaoForm.value.Modalidade == undefined || this.ventilacaoForm.value.Modalidade == null) {
      this.utilService.showAlertCallback("Você precisa informar a Modalidade da ventilação mecânica", null);
      return;
    }

    if (this.ventilacaoForm.value.Modalidade == '' || this.ventilacaoForm.value.Modalidade == undefined || this.ventilacaoForm.value.Modalidade == null) {
      this.utilService.showAlertCallback("Você precisa informar a Modalidade da ventilação mecânica", null);
      return;
    }

    if (this.ventilacaoForm.value.Via == '' || this.ventilacaoForm.value.Via == undefined || this.ventilacaoForm.value.Via == null) {
      this.utilService.showAlertCallback("Você precisa informar a Via da ventilação mecânica", null);
      return;
    }

    if (this.ventilacaoForm.value.Pressaoinspiratoria == '' || this.ventilacaoForm.value.Pressaoinspiratoria == undefined || this.ventilacaoForm.value.Pressaoinspiratoria == null) {
      this.utilService.showAlertCallback("Você precisa informar a Pressão inspiratória da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraPressaoinspiratoria) });
      return;
    }

    if (this.ventilacaoForm.value.Peep == '' || this.ventilacaoForm.value.Peep == undefined || this.ventilacaoForm.value.Peep == null) {
      this.utilService.showAlertCallback("Você precisa informar a PEEP da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraPeep) });
      return;
    }

    if (this.ventilacaoForm.value.Peepi == '' || this.ventilacaoForm.value.Peepi == undefined || this.ventilacaoForm.value.Peepi == null) {
      this.utilService.showAlertCallback("Você precisa informar a PEEP instrínseca da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraPeepi) });
      return;
    }

    if (this.ventilacaoForm.value.Pressaoplato == '' || this.ventilacaoForm.value.Pressaoplato == undefined || this.ventilacaoForm.value.Pressaoplato == null) {
      this.utilService.showAlertCallback("Você precisa informar a Pressão de platô instrínseca da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraPressaoplato) });
      return;
    }

    if (this.ventilacaoForm.value.Frventilador == '' || this.ventilacaoForm.value.Frventilador == undefined || this.ventilacaoForm.value.Frventilador == null) {
      this.utilService.showAlertCallback("Você precisa informar a Frequência respiatória setada na ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraFrventilador) });
      return;
    }

    if (this.ventilacaoForm.value.Frpaciente == '' || this.ventilacaoForm.value.Frpaciente == undefined || this.ventilacaoForm.value.Frpaciente == null) {
      this.utilService.showAlertCallback("Você precisa informar a Frequência respiatória do paciente na ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraFrpaciente) });
      return;
    }

    if (this.ventilacaoForm.value.FiO2 == '' || this.ventilacaoForm.value.FiO2 == undefined || this.ventilacaoForm.value.FiO2 == null) {
      this.utilService.showAlertCallback("Você precisa informar a FiO2 da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraFiO2) });
      return;
    }

    if (this.ventilacaoForm.value.Volumeinspiratorio == '' || this.ventilacaoForm.value.Volumeinspiratorio == undefined || this.ventilacaoForm.value.Volumeinspiratorio == null) {
      this.utilService.showAlertCallback("Você precisa informar a Volume inspiratorio da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraVolumeinspiratorio) });
      return;
    }

    if (this.ventilacaoForm.value.Volumeexpiratorio == '' || this.ventilacaoForm.value.Volumeexpiratorio == undefined || this.ventilacaoForm.value.Volumeexpiratorio == null) {
      this.utilService.showAlertCallback("Você precisa informar a Volume expiratório da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraVolumeexpiratorio) });
      return;
    }

    if (this.ventilacaoForm.value.Fluxo == '' || this.ventilacaoForm.value.Fluxo == undefined || this.ventilacaoForm.value.Fluxo == null) {
      this.utilService.showAlertCallback("Você precisa informar O Fluxo da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraFluxo) });
      return;
    }

    if (this.ventilacaoForm.value.Tempoinspiratorio == '' || this.ventilacaoForm.value.Tempoinspiratorio == undefined || this.ventilacaoForm.value.Tempoinspiratorio == null) {
      this.utilService.showAlertCallback("Você precisa informar a Tempo inspiratorio da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraTempoinspiratorio) });
      return;
    }

    if (this.ventilacaoForm.value.RelacaoI == '' || this.ventilacaoForm.value.RelacaoI == undefined || this.ventilacaoForm.value.RelacaoI == null) {
      this.utilService.showAlertCallback("Você precisa informar Relacao I da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraRelacaoI) });
      return;
    }

    if (this.ventilacaoForm.value.RelacaoE == '' || this.ventilacaoForm.value.RelacaoE == undefined || this.ventilacaoForm.value.RelacaoE == null) {
      this.utilService.showAlertCallback("Você precisa informar Relacao E da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraRelacaoE) });
      return;
    }

    if (this.ventilacaoForm.value.Assincronia == '' || this.ventilacaoForm.value.Assincronia == undefined || this.ventilacaoForm.value.Assincronia == null) {
      this.utilService.showAlertCallback("Você precisa informar se existe ou não Assincronia na ventilação mecânica", null);
      return;
    }

    if (this.ventilacaoForm.value.Sensibilidade == '' || this.ventilacaoForm.value.Sensibilidade == undefined || this.ventilacaoForm.value.Sensibilidade == null) {
      this.utilService.showAlertCallback("Você precisa informar a Sensibilidade da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraSensibilidade) });
      return;
    }

    if (this.ventilacaoForm.value.Sensibilidade == '' || this.ventilacaoForm.value.Sensibilidade == undefined || this.ventilacaoForm.value.Sensibilidade == null) {
      this.utilService.showAlertCallback("Você precisa informar a Sensibilidade da ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraSensibilidade) });
      return;
    }

    if (this.ventilacaoForm.value.Diasproposto == '' || this.ventilacaoForm.value.Diasproposto == undefined || this.ventilacaoForm.value.Diasproposto == null) {
      this.utilService.showAlertCallback("Você precisa informar a quantidade de Dias proposto para a ventilação mecânica",
        () => { this.utilService.focusInput(this.ancoraDiasproposto) });
      return;
    }

    if (this.ventilacaoForm.value.Posicao == '' || this.ventilacaoForm.value.Posicao == undefined || this.ventilacaoForm.value.Posicao == null) {
      this.utilService.showAlertCallback("Você precisa informar a Posição da ventilação mecânica", null);
      return;
    }
    let tipo = parseInt(this.ventilacaoForm.value.Id);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: ' VENTILAÇÃO MECÂNICA',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraPressaoinspiratoria'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroVentilacao();
          }
        }
      ]
    });

    await alert.present();

  }

  cadastroVentilacao() {
    let Assincronia = this.ventilacaoForm.value.Assincronia;
    Assincronia = "[" + Assincronia + "]";

    let Datalancamento = this.utilService.checaData(this.ventilacaoForm.value.Datalancamento);
    let Datasuspensao = this.utilService.checaData(this.ventilacaoForm.value.Datasuspensao);

    let form = {
      Id: this.ventilacaoForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Datasuspensao: Datasuspensao,
      Modo: this.ventilacaoForm.value.Modo,
      Modalidade: this.ventilacaoForm.value.Modalidade,
      Via: this.ventilacaoForm.value.Via,
      Pressaoinspiratoria: this.ventilacaoForm.value.Pressaoinspiratoria,
      Peep: this.ventilacaoForm.value.Peep,
      Peepi: this.ventilacaoForm.value.Peepi,
      Pressaoplato: this.ventilacaoForm.value.Pressaoplato,
      Frventilador: this.ventilacaoForm.value.Frventilador,
      Frpaciente: this.ventilacaoForm.value.Frpaciente,
      FiO2: this.ventilacaoForm.value.FiO2,
      Volumeinspiratorio: this.ventilacaoForm.value.Volumeinspiratorio,
      Volumeexpiratorio: this.ventilacaoForm.value.Volumeexpiratorio,
      Fluxo: this.ventilacaoForm.value.Fluxo,
      Tempoinspiratorio: this.ventilacaoForm.value.Tempoinspiratorio,
      RelacaoI: this.ventilacaoForm.value.RelacaoI,
      RelacaoE: this.ventilacaoForm.value.RelacaoE,
      Assincronia: Assincronia,
      Sensibilidade: this.ventilacaoForm.value.Sensibilidade,
      Observacao: this.ventilacaoForm.value.Observacao,
      Posicao: this.ventilacaoForm.value.Posicao,
      Usuario: this.Usuario
    }

    if (this.ventilacaoForm.value.Id == -1 || this.ventilacaoForm.value.Id == undefined || this.ventilacaoForm.value.Id == null) {
      this.lancamentoService.adicionarVentilacao(form).then((response) => {
        this.selecionarVentilacao(response[0].Id);
        this.utilService.showAlertCallback("Ventilação ADICIONADA com sucesso", null);

      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o Ventilação", null);

      });
    } else {
      this.lancamentoService.atualizarVentilacao(form).then((response) => {
        this.utilService.showAlertCallback("Ventilação ATUALIZADA com sucesso", null);
        this.selecionarVentilacaoUnico(response[0].Id);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR a Ventilação", null);

      });
    }

  }


  async selecionarVentilacaoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.lancamentoService.selecionarVentilacaoUnico(Id).then((response:any) => {
      this.getBalancoVentilacao(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar os parâmetros da ventilação escolhida");

    });
  }

  async limpaDados() {
    this.Botaoventilacao = "Cadastrar";
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    let Datasuspensao = this.utilService.somaDiaData(dtHoje, 5);
    Datasuspensao = this.utilService.checaData(Datasuspensao);
    this.Id = -1;
    this.Assincronia = ["11"];
    this.ventilacaoForm.controls['Id'].setValue(this.Id);
    this.ventilacaoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.ventilacaoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.ventilacaoForm.controls['Modo'].setValue(3);
    this.ventilacaoForm.controls['Modalidade'].setValue(1);
    this.ventilacaoForm.controls['Via'].setValue(1);
    this.ventilacaoForm.controls['Pressaoinspiratoria'].setValue('');
    this.ventilacaoForm.controls['Peep'].setValue('');
    this.ventilacaoForm.controls['Peepi'].setValue(1);
    this.ventilacaoForm.controls['Pressaoplato'].setValue('');
    this.ventilacaoForm.controls['Frventilador'].setValue('');
    this.ventilacaoForm.controls['Frpaciente'].setValue('');
    this.ventilacaoForm.controls['FiO2'].setValue('');
    this.ventilacaoForm.controls['Volumeinspiratorio'].setValue('');
    this.ventilacaoForm.controls['Volumeexpiratorio'].setValue('');
    this.ventilacaoForm.controls['Fluxo'].setValue('');
    this.ventilacaoForm.controls['Tempoinspiratorio'].setValue('');
    this.ventilacaoForm.controls['RelacaoI'].setValue('');
    this.ventilacaoForm.controls['RelacaoE'].setValue('');
    this.ventilacaoForm.controls['Assincronia'].setValue(this.Assincronia);
    this.ventilacaoForm.controls['Sensibilidade'].setValue(-3);
    this.ventilacaoForm.controls['Posicao'].setValue(1);
    this.ventilacaoForm.controls['Diasproposto'].setValue(5);
    this.ventilacaoForm.controls['Observacao'].setValue('');
    this.Id = 0;
    this.utilService.focusInput(eval('this.ancoraPressaoinspiratoria'));
  }

  async getBalancoVentilacao(response: any) {
    this.Botaoventilacao = "Alterar";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Datasuspensao = this.utilService.checaData(response[0].Datasuspensao);

    let Assincronia: any = response[0].Assincronia;
    if (Assincronia != '' && Assincronia != null && Assincronia != undefined) {
      Assincronia = Assincronia.replace("[", "");
      Assincronia = Assincronia.replace("]", "");
      Assincronia = Assincronia.split(",");
    }

    let RelacaoI: number = response[0].RelacaoI.replace(",", ".");
    let RelacaoE: number = response[0].RelacaoE.replace(",", ".");
    let Fluxo: number = this.utilService.formateNumber(response[0].Fluxo, 2);
    let Tempoinspiratorio: number = response[0].Tempoinspiratorio.replace(",", ".");
    let Sensibilidade: number = this.utilService.formateNumber(response[0].Sensibilidade, 0);

    this.ventilacaoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.ventilacaoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.ventilacaoForm.controls['Modo'].setValue(response[0].Modo);
    this.ventilacaoForm.controls['Modalidade'].setValue(response[0].Modalidade);
    this.ventilacaoForm.controls['Via'].setValue(response[0].Via);
    this.ventilacaoForm.controls['Pressaoinspiratoria'].setValue(response[0].Pressaoinspiratoria);
    this.ventilacaoForm.controls['Peep'].setValue(response[0].Peep);
    this.ventilacaoForm.controls['Peepi'].setValue(response[0].Peepi);
    this.ventilacaoForm.controls['Pressaoplato'].setValue(response[0].Pressaoplato);
    this.ventilacaoForm.controls['Frventilador'].setValue(response[0].Frventilador);
    this.ventilacaoForm.controls['Frpaciente'].setValue(response[0].Frpaciente);
    this.ventilacaoForm.controls['FiO2'].setValue(response[0].FiO2);
    this.ventilacaoForm.controls['Volumeinspiratorio'].setValue(response[0].Volumeinspiratorio);
    this.ventilacaoForm.controls['Volumeexpiratorio'].setValue(response[0].Volumeexpiratorio);
    this.ventilacaoForm.controls['Fluxo'].setValue(Fluxo);
    this.ventilacaoForm.controls['Tempoinspiratorio'].setValue(Tempoinspiratorio);
    this.ventilacaoForm.controls['RelacaoI'].setValue(RelacaoI);
    this.ventilacaoForm.controls['RelacaoE'].setValue(RelacaoE);

    this.ventilacaoForm.controls['Sensibilidade'].setValue(Sensibilidade);
    this.ventilacaoForm.controls['Observacao'].setValue(response[0].Observacao);
    this.ventilacaoForm.controls['Posicao'].setValue(response[0].Posicao);
    this.ventilacaoForm.controls['Assincronia'].setValue(Assincronia);
  
    this.calculaVolumeMinuto();

    this.calculaPressaoPico(response);
    this.calculaComplacenciaEstatica(response);
    this.calculaComplacenciaDinamica(response);
    this.calculaRelacaoIE();
    this.volumeOfertado(response);
  }

  async apagarPadrao(clicados:any) {
    let Novolista:any=[];
      
    for (let i=0; i<clicados.length; i++){
        if (clicados[i] != 11) { // sem assincronias
          Novolista.push(
            clicados[i]
            )
        }
      }

      if (Novolista.length > 0) {
        this.ventilacaoForm.controls["Assincronia"].setValue(Novolista);
      }

  }

  async selecionarVentilacao(seek: number) {
    this.lancamentoService.selecionarVentilacao(this.Internacao, this.Paciente).then((response) => {
      this.ventilacaoCollection = response;
      this.Peso = response[0].Peso;
      this.Altura = response[0].Altura;
      this.Sexo = response[0].Sexo;
      if (this.Sexo == 1) {
        //this.Pesopredito = 50 + 0, 91 * (this.Altura - 152.4);
        this.Pesopredito = this.utilService.formateNumber(this.PesoIdealMasculino, 2);
        this.Pressaoinspiratoriamaxima = (0.80 * parseInt(this.Idade)) + 155.3;
      } else {
        //this.Pesopredito = 45.5 + 0, 91 * (this.Altura - 152.4);
        this.Pesopredito = this.utilService.formateNumber(this.PesoIdealFeminino, 2);

        this.Pressaoinspiratoriamaxima = (0.49 * parseInt(this.Idade)) + 110.4;
      }

      this.Volumecorrenteminimo = 4 * this.Pesopredito;
      this.Volumecorrentemaximo = 6 * this.Pesopredito;

      this.Volumecorrenteminimo = this.utilService.formateNumber(this.Volumecorrenteminimo, 0);
      this.Volumecorrentemaximo = this.utilService.formateNumber(this.Volumecorrentemaximo, 0);

      this.Id = seek;

    }).catch((response) => {

    });
  }

  async volumeOfertado(response:any){

   let Vc:number = response[0].Volumeinspiratorio
   let Pesopredito:any = this.Pesopredito.toString().replace(',', '.');
   this.Volumeofertado = this.utilService.formateNumber((Vc/ Pesopredito),2);
   this.Volumecorrenteminimo = this.utilService.formateNumber((Pesopredito * 4),0);
   this.Volumecorrentemaximo = this.utilService.formateNumber((Pesopredito * 6),0);
  
  }

  async calculaPressaoPico(response:any) {
    let Pi:number =  response[0].Pressaoinspiratoria ;
    let Peep:number =  response[0].Peep ;
    let Peepi:number=  response[0].Peepi;
    this.Pressaopico = Pi + Peep + Peepi ;

  }

  async calculaComplacenciaEstatica(response) {

    
    let Vi:number=  response[0].Volumeinspiratorio;
    let Plato:number = response[0].Pressaoplato;
    let Peep:number =  response[0].Peep ;
    let Peepi:number=  response[0].Peepi;
    this.Complacenciaestatica = Vi/(Plato - (Peep + Peepi)) ;

  }

  async calculaComplacenciaDinamica(response) {
   let Vi:number=  response[0].Volumeinspiratorio;
   let Pinsp:number = response[0].Pressaoinspiratoria;
   let Peep:number =  response[0].Peep ;
   let Peepi:number=  response[0].Peepi;
   this.Complacenciadinamica = Vi/(Pinsp - (Peep + Peepi)) ;

 }

  async calculaDiasProposto() {
    let Diasproposto: number = this.ventilacaoForm.value.Diasproposto
    if (Diasproposto <= 0) {
      this.utilService.showAlertCallback("Informe o número de dias");
      this.utilService.focusInput(this.ancoraDiasproposto);
      return;
    }
    let dtHoje = this.utilService.dataHoje();
    let Datasuspensao = this.utilService.somaDiaData(dtHoje, Diasproposto);
    this.ventilacaoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.utilService.focusInput(this.ancoraObservacao);

  
  }
  async calculaVolumeMinuto() {
    let VC: any = this.ventilacaoForm.value.Volumeinspiratorio;
    VC = this.utilService.formateNumber(VC, 2);
    if (VC <= 0) {
      this.utilService.showAlertCallback("Informe o valor do VC inspiratório", () => {
        this.utilService.focusInput(this.ancoraVolumeinspiratorio);
      })
      return;
    }



    let FR: any = this.ventilacaoForm.value.Frpaciente;
    FR = this.utilService.formateNumber(FR, 2);

    if (FR <= 0) {
      this.utilService.showAlertCallback("Informe o valor do FR do paciente", () => {
        this.utilService.focusInput(this.ancoraFrpaciente);
      })
      return;
    }
    let VM: number = VC * FR;
    VM = this.utilService.formateNumber(VM, 2);
    this.Fluxo = VM / 1000;
    this.Fluxo = this.utilService.formateNumber(this.Fluxo, 2);
    this.ventilacaoForm.controls['Fluxo'].setValue(this.Fluxo);
    this.Volumeexpiratorio = VC;
    this.ventilacaoForm.controls['Volumeexpiratorio'].setValue(this.Volumeexpiratorio);
    this.utilService.focusInput(this.ancoraTempoinspiratorio);
  }

  async calculaRelacaoIE() {
    let FR: any = this.ventilacaoForm.value.Frpaciente;
    FR = this.utilService.formateNumber(FR, 2);

    if (FR <= 0) {
      this.utilService.showAlertCallback("Informe o valor do FR do paciente", () => {
        this.utilService.focusInput(this.ancoraFrpaciente);
      })
      return;
    }


    let TI: any = this.ventilacaoForm.value.Tempoinspiratorio;
    TI = this.utilService.formateNumber(TI, 2);
    if (TI <= 0) {
      this.utilService.showAlertCallback("Informe o valor do TEMPO inspiratório", () => {
        this.utilService.focusInput(this.ancoraTempoinspiratorio);
      })
      return;
    }

    let JT: number = 60 / FR;
    JT = this.utilService.formateNumber(JT, 2);

    let TE: number = JT - TI;
    let RI:number= 0;
    let RE:number= 0;
    if (TE > TI) {
      RE= TE/TI;
      RI = 1;
    }else if (TI>TE){
     
      RI = TI/TE;
      RE = 1
    }else{
      RI = 1;
      RE = 1;
    }
   
    

    this.RelacaoE = RE
    this.RelacaoI = RI;
    this.RelacaoI = this.utilService.formateNumber(this.RelacaoI, 2);
    this.RelacaoE = this.utilService.formateNumber(this.RelacaoE, 2);
    this.ventilacaoForm.controls['RelacaoI'].setValue(this.RelacaoI);
    this.ventilacaoForm.controls['RelacaoE'].setValue(this.RelacaoE);
    this.utilService.focusInput(this.ancoraDiasproposto);

  }



  async igualaFrequencia() {
    let FRv: any = this.ventilacaoForm.value.Frventilador;
    FRv = this.utilService.formateNumber(FRv, 0);
    if (FRv <= 0) {
      this.utilService.showAlertCallback("Informe o valor do FR no ventilador", () => {
        this.utilService.focusInput(this.ancoraFrventilador);
      })
      return;
    }
    this.Frventilador = FRv;
    this.ventilacaoForm.controls['Frpaciente'].setValue(this.Frventilador);
    this.utilService.focusInput(this.ancoraFiO2);
  }


}



