import { Injectable, PACKAGE_ROOT_URL } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { SistemaModule } from '../sistema/sistema.module';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  loading: HTMLIonLoadingElement;
  timer: any;
  lCnpj: any;
  public dadosListMed = new SistemaModule();
  aExame: Array<
  {
     Internacao :number,
     Paciente :number,
     Usuario: number,
     Exame: number
  }> = [];
  aFasthug: Array<
  {
     Internacao :number,
     Paciente :number,
     Cabeceira: string,
     Analgesia: string,
     Sedacao:string,
     Dieta:string,
     Troboembolismo: string,
     Ulcera: string,
     Glicemia:string
  }> = [];
  aUsuario: Array<
  {
    Id:number,
    Nome:string,
    Datanascimento:string,
    Sexo:number,
    Email:string,
    Celular:string,
    Senha:string,
    Cep:string,
   Logradouro:string,
   Complemento:string,
   Bairro:string,
   Estado:number,
   Municipio:number,
   Localidade:string,
   Termosdeuso:number,
   Imagem:string,
   Ativo:number,
   Administrador:number,
   Curriculo:string,
   Cpf:string,
   Conselho:number,
   Numeroconselho:string,
   Codigovalidacao:string,
   Retorno:number,
   Idade:number,
   Siglaconselho:string,
   Logado:number
  }> = [];
  aInternacao: Array<
    {
       Id :number,
       Paciente :number,
       Peso :any,
       NomePaciente :string,
       Datainternacao :string,
       Hospital :number,
       DescricaoHospital :string,
       Setor :number,
       DescricaoSetor :string,
       Leito :string,
       Creatininabasal :string,
       Balancoacumulado :string,
       Usuario :number,
       Dias :number,
       Idade :number,
       Comorbidade :string,
       Queixaprincipal :string,
       Historiaclinica :string,
       Examefisico :string,
       Medicamento :string,
       Problema :string,
       Planoterapeutico :string,
       Pontos :number,
       Mortalidade :string,
       Altura   :string,
       Bsa   :string,
       PesoIdealMasculino :string,
       PesoIdealFeminino :string,
       VolemiaMasculino  :string,
       VolemiaFemenino :string,
       Imc   :string,
       Imagem :string,
       Sexo :number,
       PaO2Ideal :string,
       SiglaEstado :string,
       FASTHUG:number,
       FASTHUGrelacao: string,
       SOFA:number,
       SOFArelacao: string,
       Gestante: number,
       Convenio: number,
       Ambulatorio: number
    }> = [];
  aTabela: Array<
    {
      Nome: string,
      Colunas: any,
      Id: number
    }> = [];
 
    aSofa: Array<
    {
       Internacao :number,
       Paciente :number,
       Neurologico: number,
       Cardiaco: number,
       Respiratorio:number,
       Digestivo:number,
       Renal: number,
       Hematologico: number
    }> = []
    aQuestionario: Array<
    {
       Id :number,
       Data :string,
       Nome: string,
       Email: string,
       Facebook:string,
       Instagram:string,
       Celular: string, 
       Pesquisa: number,
       Resultado:string,
       Ativo: number,
       Codigo: number
    }> = [];
  constructor(
    private navController: NavController,
    private alertController: AlertController,
    private toastController: ToastController,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
  ) { }

  obterUrlDaApi() {
   //  return "http://localhost:53622/api/";
    return "https://api.listmed.com.br/api/"

  }

  

  obterUrlDaImagemDaApi() {
    //return "http://192.168.0.105:7777/Uploads/files/"; //rodando apontando para api hospedada localmente
   // return "http://localhost:53622/Uploads/files/" //rodando local direto da api
    return "https://api.listmed.com.br/Uploads/files/"; //rodando apontando para producao
  }

  obterUrlDoAudioDaApi() {
    //return "http://192.168.0.105:7777/Uploads/audio/"; //rodando apontando para api hospedada localmente
    //return "http://localhost:53622/Uploads/audio/" //rodando local direto da api
    return "https://api.listmed.com.br/Uploads/audio/"; //rodando apontando para producao
  }

  static getHeaderComToken() {
    let token = localStorage.getItem('tokenApi');

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('Content-Type', `application/json`)
    }

    return header;
  }

  getDataBrowser(data: string) {
    if (data == null || data == undefined || data == '') {
      return ;
    }
    data = data.substring(6, 10) + "-" + data.substring(3, 5) + "-" + data.substring(0, 2) + " " +
      data.substring(11, 20);

    return data;

  }

  getDataBrowserBrasil(data: string) {
    let hoje = new Date(data);
    let dia: any = hoje.getDate();
    let mes: any = hoje.getMonth() + 1
    let ano: any = hoje.getFullYear();
    let hora: any = hoje.getHours();          // 0-23
    let minuto: any = hoje.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = dia.toString() + "/" + mes.toString() + "/" + ano.toString() + " " + hora.toString() + ':' + minuto.toString() + " h";

    return data
  }

  mostrarDiv(div: string) {
    var objLoader = document.getElementById(div);
    objLoader.style.display = "block";
  }

  esconderDiv(div: string) {
    var objLoader = document.getElementById(div);
    objLoader.style.display = "none";
  }

  focusInput(input: any) {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      (input as any).setFocus(true);
    }, 1000);
  }

  verificarArquivo(url, callback) {
    var img = new Image();
    img.onload = function () { callback(true); };
    img.onerror = function () { callback(false); };
    img.src = url;

  }

  obterEndereco(cep: string) {
    let url = 'https://viacep.com.br/ws/' + cep + '/json/'

    return this.http.get(url).toPromise();
  }

  async Movimentar(pagina: string) {
    this.navController.navigateForward(pagina);
  }

  async logoff() {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: 'Tem certeza que deseja sair do sistema!',
      mode: 'ios',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Sim, quero sair',
          handler: () => {
            this.publishEvent("Logado", "0");
            this.publishEvent("Usuario", []);
            this.publishEvent("Hospital",[]);
            this.publishEvent("Internacao",[]);
            localStorage.removeItem('dadosListMed');
            localStorage.removeItem('tokenApi');

            this.Movimentar('tabs/tab1');
          }
        }
      ]
    });

    alert.present();

  }

  zerarDadosSistema() {

    this.publishEvent("Logado", "0");
    this.publishEvent("Usuario", []);
    this.publishEvent("Hospital",[]);
    this.publishEvent("Internacao",[]);
    localStorage.removeItem('dadosListMed');
    localStorage.removeItem('tokenApi');

  }

  enviarEmailVelho(para: string, paraNome: string, assunto: string, mensagem: string,
    imagem: string = '', stringBase64Pdf: any = '', link: string) { // usuario contem todos os campos para o cadastro
    if (link == null || link == '' || link == undefined) {
      link = 'https://listmed.com.br'
    }
    let parametro =
    {
      para: para,
      assunto: assunto,
      mensagem: mensagem,
      paraNome: paraNome,
      imagem: imagem,
      anexoPdf: stringBase64Pdf,
      link: link
    };
    let url = this.obterUrlDaApi() + 'Util/EnviarEmailListMed';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(url, parametro, headers).toPromise();
  }

  EnviarEmailListMed(para: string, paraNome: string, assunto: string, mensagem: string,
   
    imagem: string, stringBase64Pdf: any = '', link: string) { // usuario contem todos os campos para o cadastro
    if (link == null || link == '' || link == undefined) {
      link = 'https://listmed.com.br'
    }
    let parametro =
    {
      para: para,
      assunto: assunto,
      mensagem: mensagem,
      paraNome: paraNome,
      imagem: imagem,
      anexoPdf: stringBase64Pdf,
      link: link
    };
    let url = this.obterUrlDaApi() + 'Util/EnviarEmailListMed';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, parametro, { headers: headers }).toPromise();
  }

  EnviarEmail(para: string, paraNome: string, assunto: string, mensagem: string,
    imagem: string, stringBase64Pdf: any = '', link: string) { // usuario contem todos os campos para o cadastro
    if (link == null || link == '' || link == undefined) {
      link = 'https://listmed.com.br'
    }
    let parametro =
    {
      para: para,
      assunto: assunto,
      mensagem: mensagem,
      paraNome: paraNome,
      imagem: imagem,
      anexoPdf: stringBase64Pdf,
      link: link
    };
    let url = this.obterUrlDaApi() + 'Util/EnviarEmail';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, parametro, { headers: headers }).toPromise();
  }


  validarCampo(campo: string, valor: string, toastCallback: any = null) {

    let lRet = true;
    if (campo == "Codigo") { // código verificador de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o código verificador', toastCallback);
        return lRet
      }
    }

    if (campo == "Sistema") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o sistema',  toastCallback);
        return lRet
      }
    }

    if (campo == "Nomepadrao") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa o nome do padrão',  toastCallback);
        return lRet
      }
    }

    if (campo == "Mensagem") { // mensagem de email
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar sua mensagem',  toastCallback);
        return lRet
      }
    }

    if (campo == "Validacao") { // reinvio de senha
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação',  toastCallback);
        return lRet
      }
    }
    if (campo == "Codigovalidacao") { // após realizar o cadastro
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação', toastCallback);
        return lRet
      }

      let dNow = new Date();
      let dia = dNow.getDate()
      let mes = dNow.getMonth() + 1

      let Validacao = dia.toString() + mes.toString();
      valor = valor.toString();
      if (valor.indexOf(Validacao) == - 1) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu código de validação corretamente', toastCallback);
        return lRet

      }

    }

    if (campo == "Curriculo") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o seu currículo',  toastCallback);
        return lRet
      }
    }

    if (campo == "Complemento") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o complemento de seu logradouro', toastCallback);
        return lRet
      }
    }

    if (campo == "Medicamento") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a o medicamento',  toastCallback);
        return lRet
      }
    }

    if (campo == "Ampola") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a quantidade de ampolas',  toastCallback);
        return lRet
      }
    }

    if (campo == "Vazao") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a vazão', toastCallback);
        return lRet
      }
    }

    if (campo == "Solucao") {
      if (valor == null || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a quantidade de solução',  toastCallback);
        return lRet
      }
    }
    if (campo == "Posicao") {
      if (valor == null || valor == '' || valor == undefined || parseInt(valor) == 0) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a posição',  toastCallback);
        return lRet
      }
    }

    if (campo == "Dispositivo") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o dispositivo',  toastCallback);
        return lRet
      }
    }

    if (campo == "Acesso") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a via de acesso',  toastCallback);
        return lRet
      }
    }

    if (campo == "Posicao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa a posicao', toastCallback);
        return lRet
      }
    }

    
    if (campo == "Grupoantibiotico") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o GRUPO',  toastCallback);
        return lRet
      }
    }

    if (campo == "Antibiotico") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o ANTIBIÓTICO', toastCallback);
        return lRet
      }
    }

    if (campo == "Posologia") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a POSOLOGIA', toastCallback);
        return lRet
      }
    }

    if (campo == "Unidademedida") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a UNIDADE DE MEDIDA', toastCallback);
        return lRet
      }
    }

    if (campo == "Tipoapresentacao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a APRESENTAÇÃO',  toastCallback);
        return lRet
      }
    }

    if (campo == "Intervalo") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o INTERVALO',  toastCallback);
        return lRet
      }
    }

    if (campo == "Via") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a VIA DE ADMINISTRAÇÃO',  toastCallback);
        return lRet
      }
    }
    
    if (campo == "Infeccaocultura") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a CULTURA',  toastCallback);
        return lRet
      }
    }
    
    if (campo == "Infeccaomicrobiologia") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o MICROORGANISMO',  toastCallback);
        return lRet
      }
    }

    if (campo == "Posicao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a POSIÇÃO do antibiótico',  toastCallback);
        return lRet
      }
    }
    if (campo == "Droga") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar a DROGA',  toastCallback);
        return lRet
      }
    }

    if (campo == "Neurologico") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
        this.showAlertCallback('Você precisa informar o texto do módulo neurológico', toastCallback);
        return lRet
      }
    }




    if (campo == "Cardiaco") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Cardíaco', toastCallback);
        return lRet
      }
    }

    if (campo == "Respiratorio") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Respiratório',  toastCallback);
        return lRet
      }
    }

    if (campo == "Digestivo") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Digestivo', toastCallback);
        return lRet
      }
    }

    if (campo == "Renal") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Renal', toastCallback);
        return lRet
      }
    }

    if (campo == "Infeccao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Infecção', toastCallback);
        return lRet
      }
    }

    if (campo == "Endocrino") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Endócrino', toastCallback);
        return lRet
      }
    }

    if (campo == "Genitourinario") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Genitourinário', toastCallback);
        return lRet
      }
    }

    if (campo == "Extremidade") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Extremidade', toastCallback);
        return lRet
      }
    }



    if (campo == "Tegumentar") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Tegumentar', toastCallback);
        return lRet
      }
    }

    if (campo == "Psicosocial") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Psicosocial', toastCallback);
        return lRet
      }
    }

    if (campo == "Dispositivo") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Dispositivo', toastCallback);
        return lRet
      }
    }

    if (campo == "Laboratorio") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Laboratorio', toastCallback);
        return lRet
      }
    }

    if (campo == "Gasometria") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Gasometria', toastCallback);
        return lRet
      }
    }

    if (campo == "Enfermagem") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Enfermagem', toastCallback);
        return lRet
      }
    }

    if (campo == "Fisioterapia") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Fisioterapia', toastCallback);
        return lRet
      }
    }

    if (campo == "Nutricao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Nutricao', toastCallback);
        return lRet
      }
    }

    if (campo == "Exame") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Exame', toastCallback);
        return lRet
      }
    }

    if (campo == "Ventilacao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Ventilacao', toastCallback);
        return lRet
      }
    }

    if (campo == "Ventilacao") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o texto do módulo Infusao', toastCallback);
        return lRet
      }
    }






    if (campo == "Setor") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o setor que o paciente esta internado', toastCallback);
        return lRet
      }
    }

    if (campo == "Leito") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o leito que o paciente esta internado', toastCallback);
        return lRet
      }
    }

    if (campo == "Hospital") {
      if (valor == null || valor == '' || parseInt(valor) == 0 || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o HOSPITAL do paciente', toastCallback);
        return lRet
      }
    }

    if (campo == "Convenio") {
      if (valor == null || valor == '' || parseInt(valor) == 0 || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa informar o CONVÊNIO do paciente esta internado', toastCallback);
        return lRet
      }
    }

    if (campo == "Comorbidades") {
      if (valor == null || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Você precisa indicar que não existe comorbidade', toastCallback);
        return lRet
      }
    }

    if (campo == "Gasglow") {
      if (parseInt(valor) > 15 || parseInt(valor) < 3 || valor == '' || valor == undefined) {
        lRet = false;
         this.showAlertCallback('Glasgow tem que ser entre 3 e 15', toastCallback);
        return lRet
      }
    }
    if (campo == "Fc") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Frequência cardíaca em bpm não informado', toastCallback);
        return lRet
      }
    }

    if (campo == "Ps") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Frequência cardíaca em bpm não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Temperatura") {
      if (parseFloat(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Temperatura em graus centígrados não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Leucocitos") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Leucocitos não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Plaquetas") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Plaquetas não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Ph") {
      if (parseFloat(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('pH não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Creatininabasal") {
      if (parseFloat(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Creatinina basal em mg/dL não informado', toastCallback);
        return lRet
      }
    }

    if (campo == "Creatinina") {
      if (parseFloat(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Creatinina em mg/dL não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Bilirrubina") {
      if (parseFloat(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Bilirrubina em mg/dL não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Peso") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null || parseInt(valor) < 5 || parseInt(valor) > 220) {
        lRet = false;
         this.showAlertCallback('Peso em kg não informado ou inválido', toastCallback);
        return lRet
      }
    }

    if (campo == "Idade") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null || parseInt(valor) < 5 || parseInt(valor) > 120) {
        lRet = false;
         this.showAlertCallback('Idade em anis não informado ou inválida', toastCallback);
        return lRet
      }
    }

    if (campo == "Sexoestudo") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('Sexo não informado', toastCallback);
        return lRet
      }
    }
    if (campo == "Balanco") {
      let balanco = parseInt(valor);
      if (balanco.toString() == 'NaN' || balanco == undefined || balanco == null) {
        lRet = false;
        this.showAlertCallback('Balanço hidrico mL do paciente não informado',  toastCallback);
        return lRet
      }
    }
    if (campo == "Altura") {
      if (valor.indexOf('.') > -1 || valor.indexOf(',') > -1 ) {
        lRet = false;
         this.showAlertCallback('Altura em cm do paciente. Não há necessidade de ponto ou virgula', toastCallback);
        return lRet
      }

      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null || parseInt(valor) < 90 || parseInt(valor) > 250 ) {
        lRet = false;
        this.showAlertCallback('Altura em cm não informado ou inválida',  toastCallback);
        return lRet
      }

      
    }

    if (campo == "Nomehospital") {
      if (valor == '' || valor == undefined || valor.length < 3 || valor == null) {
        lRet = false;
        this.showAlertCallback('Nome do hospital não informado. Use pelo menos 3 caracteres',  toastCallback);
        return lRet
      }
    }

    if (campo == "Usuario") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Usuário não selecionado. Você precisa validar seu cadastro',  toastCallback);
        return lRet
      }
    }

    if (campo == "Paciente") {
      if (parseInt(valor) == 0 || valor == '' || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Paciente não selecionado. Você precisa primeiro cadastrar o paciente',  toastCallback);
        return lRet
      }
    }


    if (campo == "Nome") {
      if (valor == "" || valor.indexOf(" ") == -1) {
        lRet = false;
        this.showAlertCallback('Nome obrigatório com sobrenome',  toastCallback);
        return lRet
      }
    }

    if (campo == "Nomeestudo") {
      if (valor == "" || valor == undefined || valor == null) {
        lRet = false;
        this.showAlertCallback('Nome obrigatório',  toastCallback);
        return lRet
      }
    }

    if (campo == "Apresentacao") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('Apresentação inválida. Obrigatório mínimo de informar sua formação', toastCallback);
        return lRet
      }
    }

    if (campo == "Numeroconselho") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('Número do conselho inválido. Obrigatório informar o número do seu conselho', toastCallback);
        return lRet
      }
    }

    if (campo == "Cnpj") {
      let cnpj = valor;
      this.lCnpj = true;
      if (cnpj == '' || cnpj == undefined || cnpj == null || cnpj.length == 0) {
        this.showAlertCallback('CNPJ em branco', toastCallback);
        lRet = false;
        return lRet
      }

      if (cnpj.length != 14) {
        this.showAlertCallback('CNPJ deve ter 14 dígitos', toastCallback);
        lRet = false;
        return lRet
      }

      // numeros repetidos
      //137797070001 => 12
      let n = '';

      for (let i = 0; i < 10; i++) {
        n = '';

        if (this.lCnpj == false) {
          break;
        }
        for (let z = 0; z < 15; z++) {
          n = n + i.toString();
          if (cnpj == n) {
            this.showAlertCallback('CNPJ inválido. Números iguais', toastCallback);
            lRet = false;
            return lRet

          }
        }

      }



      let soma: any = 0


      soma = 0  // verificador do primeiro digito do
      let fator: any = [];
      fator = '5,4,3,2,9,8,7,6,5,4,3,2';
      fator = fator.split(',');
      for (let t = 0; t < 12; t++) {
        soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
      }

      soma = soma / 11;
      soma = soma.toString();
      let sobra1: any = '';
      if (soma.indexOf('.') > 0) {
        sobra1 = soma.split(".");
        sobra1 = sobra1[1].toString();
        sobra1 = parseFloat(sobra1.substring(0, 1) + '.' + sobra1.substring(1));
        sobra1 = sobra1.toFixed(1);
        let x: number = 9
        for (let p = 1; p < 10; p++) {
          if (sobra1 > x) {
            sobra1 = x + 1
            break;
          }
          x--
        }
      } else {
        sobra1 = 0;
      }
      if (sobra1 >= 2) {
        sobra1 = 11 - sobra1
      } else {
        sobra1 = 0
      }

      let dv1: string = sobra1.toString();
      let sobra2: any = '';

      soma = 0  // verificador do segundo digito do
      fator = '6,5,4,3,2,9,8,7,6,5,4,3,2';
      fator = fator.split(',');    // acrescento o primeiro digito verificador aos 9 primeiros número
      for (let t = 0; t < 13; t++) {
        soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
      }
      ;
      soma = soma / 11;
      soma = soma.toString();
      if (soma.indexOf('.') > 0) {
        sobra2 = soma.split(".");
        sobra2 = sobra2[1].toString();
        sobra2 = parseFloat(sobra2.substring(0, 1) + '.' + sobra2.substring(1));
        sobra2 = sobra2.toFixed(1);
        let x: number = 9
        for (let p = 1; p < 10; p++) {
          if (sobra2 > x) {
            sobra2 = x + 1
            break;
          }
          x--
        }

      } else {
        sobra2 = 0
      }

      if (sobra2 >= 2) {
        sobra2 = 11 - sobra2
      } else {
        sobra2 = 0
      }

      let dv2: string = sobra2.toString();
      let dv: string = dv1 + dv2;
      let dvcnpj: string = cnpj.substring(12, 14)
      if (dv != dvcnpj) {
        this.showAlertCallback('CNPJ inválido. Dígito não confere', toastCallback);
        lRet = false;
        return lRet
      }
      return lRet;
      // fim do valida CNPJ
    }

    if (campo == "Cpf") {
      if (valor.length < 11 || valor.length > 12) {
        lRet = false;
         this.showAlertCallback('CPF inválido ', toastCallback);
        return lRet
      }

      let POSICAO, I, SOMA, DV, DV_INFORMADO;
      let DIGITO = new Array(10);
      let cpf = valor;
      DV_INFORMADO = cpf.toString().substr(9, 2); // Retira os dois últimos dígitos do número informado

      // Desemembra o número do CPF na array DIGITO
      for (I = 0; I <= 8; I++) {
        DIGITO[I] = cpf.toString().substr(I, 1);
      }

      // Calcula o valor do 10º dígito da verificação
      POSICAO = 10;
      SOMA = 0;
      for (I = 0; I <= 8; I++) {
        SOMA = SOMA + DIGITO[I] * POSICAO;
        POSICAO = POSICAO - 1;
      }
      DIGITO[9] = SOMA % 11;
      if (DIGITO[9] < 2) {
        DIGITO[9] = 0;
      }
      else {
        DIGITO[9] = 11 - DIGITO[9];
      }

      // Calcula o valor do 11º dígito da verificação
      POSICAO = 11;
      SOMA = 0;
      for (I = 0; I <= 9; I++) {
        SOMA = SOMA + DIGITO[I] * POSICAO;
        POSICAO = POSICAO - 1;
      }
      DIGITO[10] = SOMA % 11;
      if (DIGITO[10] < 2) {
        DIGITO[10] = 0;
      }
      else {
        DIGITO[10] = 11 - DIGITO[10];
      }

      // Verifica se os valores dos dígitos verificadores conferem
      DV = DIGITO[9] * 10 + DIGITO[10];
      if (DV != DV_INFORMADO) {
         this.showAlertCallback('CPF inválido ', toastCallback);
        lRet = false;
      }

    }

    if (campo == "Dataalta") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data da alta obrigatório', toastCallback);
        return lRet
      }

      let data = new Date();
      let cdia: string = "";
      let cmes: string = "";
      let mes = data.getMonth() + 1;
      let cano = data.getFullYear().toString();
      let dia = data.getDate();
      if (dia < 10) {
        cdia = "0" + dia.toString();
      } else {
        cdia = dia.toString()
      }
      if (mes < 10) {
        cmes = "0" + mes.toString();
      } else {
        cmes = mes.toString();
      }

      let datahoje = cano + '-' + cmes + '-' + cdia;
      dn = dn.substr(0, 10);
      if (dn > datahoje) {
        lRet = false;
         this.showAlertCallback('Data da alta não pode ser maior que hoje', toastCallback);
        return lRet
      }
    }

    if (campo == "Datainternacao") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data de internação obrigatório', toastCallback);
        return lRet
      }

    }

    if (campo == "Datainscricao") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data de inscricao obrigatório', toastCallback);
        return lRet
      }

    }

    if (campo == "Datapagamento") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data do pagamento obrigatório', toastCallback);
        return lRet
      }

    }

    if (campo == "Data") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data obrigatório', toastCallback);
        return lRet
      }

    }

    if (campo == "Dataevolucao") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data de evolução obrigatório', toastCallback);
        return lRet
      }

      let data = new Date();
      let cdia: string = "";
      let cmes: string = "";
      let mes = data.getMonth() + 1;
      let cano = data.getFullYear().toString();
      let dia = data.getDate();
      if (dia < 10) {
        cdia = "0" + dia.toString();
      } else {
        cdia = dia.toString()
      }
      if (mes < 10) {
        cmes = "0" + mes.toString();
      } else {
        cmes = mes.toString();
      }

      let datahoje = cano + '-' + cmes + '-' + cdia;
      dn = dn.substr(0, 10);
      if (dn > datahoje) {
        lRet = false;
         this.showAlertCallback('Data de evolução não pode ser maior que hoje', toastCallback);
        return lRet
      }
    }
    if (campo == "Datanascimento") {
      let dn: any = valor;
      if (dn == true || dn == '' || dn == '0' || dn == null || dn == undefined || dn == '1900/01/01') {
        lRet = false;
         this.showAlertCallback('Data de nascimento obrigatório', toastCallback);
        return lRet
      }

      let data = new Date();
      let cdia: string = "";
      let cmes: string = "";
      let mes = data.getMonth() + 1;
      let cano = data.getFullYear().toString();
      let dia = data.getDate();
      if (dia < 10) {
        cdia = "0" + dia.toString();
      } else {
        cdia = dia.toString()
      }
      if (mes < 10) {
        cmes = "0" + mes.toString();
      } else {
        cmes = mes.toString();
      }

      let datahoje = cano + '-' + cmes + '-' + cdia;
      dn = dn.substr(0, 10);
      if (dn > datahoje) {
        lRet = false;
         this.showAlertCallback('Data de nascimento não pode ser maior que hoje', toastCallback);
        return lRet
      }
    }
    if (campo == "Sexo") {
      if (valor != '1' && valor != '2') {
        lRet = false;
         this.showAlertCallback('Sexo obrigatório', toastCallback);
        return lRet
      }

      
    }


    if (campo == "Celular") {
      if (valor == '' || valor == null || valor == undefined){
        lRet = false;
        this.showAlertCallback('CELULAR em branco. Obrigatório com 10 ou 11 digitos. Use o DDD  (ex:11,21,71,73) e número sem traços e espaços', toastCallback);
        return lRet
      }
      let cel: string = valor.toString();
      let lengthAtual = cel.length;
      if (lengthAtual != 11 && lengthAtual != 10) {
        lRet = false;
         this.showAlertCallback('CELULAR inválido. Obrigatório com 10 ou 11 digitos. Use o DDD  (ex:11,21,71,73) e número sem traços', toastCallback);
        return lRet
      }
    }

    if (campo == "Celularestudo") {
      if (valor == '' || valor == null || valor == undefined){
        lRet = false;
        this.showAlertCallback('CELULAR em branco. Obrigatório com 8 ou 9 digitos. Escolha o DDD ao lado', toastCallback);
        return lRet
      }
      let cel: string = valor.toString();
      let lengthAtual = cel.length;
      if (lengthAtual != 8 && lengthAtual != 9) {
        lRet = false;
         this.showAlertCallback('CELULAR inválido. Obrigatório com 8 ou 9 digitos. Escolha o DDD ao lado', toastCallback);
         return lRet
      }
      if (lengthAtual == 9 && valor.substring(0,1) != '9' ) {
        lRet = false;
         this.showAlertCallback('CELULAR inválido. Em caso de nove dígitos deve começar com o número 9. Escolha o DDD ao lado', toastCallback);
         return lRet
      }
    }
    if (campo == "Senha") {
      if (valor == '' || valor == undefined || valor == null) {
        lRet = false;
         this.showAlertCallback('SENHA obrigatório. Obrigatório de 6 a 12 digitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 6 || lengthAtual > 12) {
          lRet = false;
           this.showAlertCallback('SENHA inválida. Obrigatório de 6 a 12 digitos', toastCallback);
          return lRet
        }
      }
    }

    if (campo == "Cep") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('CEP obrigatório. Obrigatório 8 digitos', toastCallback);
        return lRet
      } else {
        let cep: string = valor.toString();
        let lengthAtual = cep.length;
        if (lengthAtual != 8) {
          lRet = false;
           this.showAlertCallback('CEP inválido. Obrigatório 8 digitos', toastCallback);
          return lRet
        }
      }
    }

    if (campo == "Logradouro") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('Logradouro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
           this.showAlertCallback('Logradouro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    if (campo == "Bairro") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('Bairro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
           this.showAlertCallback('Bairro inválido. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    if (campo == "Estado") {
      if (parseInt(valor) == 0 || valor == '') {
        lRet = false;
         this.showAlertCallback('Estado inválido. Escolha o estado na lista', toastCallback);
        return lRet
      }
    }

    if (campo == "Conselho") {
      if (parseInt(valor) == 0 || valor == '') {
        lRet = false;
         this.showAlertCallback('Conselho inválido. Escolha o conselho na lista', toastCallback);
        return lRet
      }
    }

    if (campo == "Localidade") {
      if (valor == '') {
        lRet = false;
         this.showAlertCallback('Cidade inválida. Obrigatório mínimo de 3 dígitos', toastCallback);
        return lRet
      } else {
        let lengthAtual = valor.length;
        if (lengthAtual < 3) {
          lRet = false;
           this.showAlertCallback('Cidade inválida. Obrigatório mínimo de 3 dígitos', toastCallback);
          return lRet
        }
      }

    }
    return lRet
  }

  async showToastCallBack(message: string, duration = 4000, callback) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: 'light',
      position: "top",
      mode: 'ios',
      buttons: [
        {
          side: 'end',
          icon: 'close',
          text: '',
          handler: () => {
            //console.log('Favorite clicked');
          }
        }
      ]

    });

    toast.present();

    toast.onDidDismiss().then(() => {
      if (callback != null) {
        callback();
      }
    });
  }

  isOnline(): boolean {
    return navigator.onLine;
  }

  async showAlertCallback(message: string, callback = null) {
    const alert = await this.alertController.create({
      message: message,
      mode: 'ios',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if (callback != null) {
              callback();
            }
          }
        }
      ]
    });
    await alert.present()

  }

  checkMail(mail: string, toastCallBack) {
    if (mail != '') {
      mail = mail.trim();
    }
    let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
    let retorno = true;
   
    if (er.test(mail)) {
      if (mail.match(/^[a-zA-Z0-9]{1}([\._a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+){1,3}$/)) {
        retorno = true;
      } else {
        retorno = false;
        this.showAlertCallback('Email está inválido',  toastCallBack);

      }
    } else {
      retorno = false;
      this.showAlertCallback('Email está inválido',  toastCallBack);
    }
    return retorno;

  }

  async selecionaEstados() {
    let aEstados = [
      { nome: "Acre", Sigla: "AC", Ibge: 12, Id: 1 },
      { nome: "Alagoas", Sigla: "AL", Ibge: 27, Id: 2 },
      { nome: "Amapá", Sigla: "AP", Ibge: 16, Id: 3 },
      { nome: "Amazonas", Sigla: "AM", Ibge: 13, Id: 4 },
      { nome: "Bahia", Sigla: "BA", Ibge: 29, Id: 5 },
      { nome: "Ceará", Sigla: "CE", Ibge: 23, Id: 6 },
      { nome: "Distrito Federal", Sigla: "DF", Ibge: 53, Id: 7 },
      { nome: "Espírito Santo", Sigla: "ES", Ibge: 32, Id: 8 },
      { nome: "Goiás", Sigla: "GO", Ibge: 52, Id: 9 },
      { nome: "Maranhão", Sigla: "MA", Ibge: 21, Id: 10 },
      { nome: "Mato Grosso", Sigla: "MT", Ibge: 51, Id: 11 },
      { nome: "Mato Grosso do Sul", Sigla: "MS", Ibge: 50, Id: 12 },
      { nome: "Minas Gerais", Sigla: "MG", Ibge: 31, Id: 13 },
      { nome: "Pará", Sigla: "PA", Ibge: 15, Id: 14 },
      { nome: "Paraíba", Sigla: "PB", Ibge: 25, Id: 15 },
      { nome: "Paraná", Sigla: "PR", Ibge: 41, Id: 16 },
      { nome: "Pernambuco", Sigla: "PE", Ibge: 26, Id: 17 },
      { nome: "Piauí", Sigla: "PI", Ibge: 22, Id: 18 },
      { nome: "Rio de Janeiro", Sigla: "RJ", Ibge: 33, Id: 19 },
      { nome: "Rio Grande do Norte", Sigla: "RN", Ibge: 24, Id: 20 },
      { nome: "Rio Grande do Sul", Sigla: "RS", Ibge: 43, Id: 21 },
      { nome: "Rondônia", Sigla: "RO", Ibge: 11, Id: 22 },
      { nome: "Roraima", Sigla: "RR", Ibge: 14, Id: 23 },
      { nome: "Santa Catarina", Sigla: "SC", Ibge: 42, Id: 24 },
      { nome: "São Paulo", Sigla: "SP", Ibge: 35, Id: 25 },
      { nome: "Sergipe", Sigla: "SE", Ibge: 28, Id: 26 },
      { nome: "Tocantins", Sigla: "TO", Ibge: 17, Id: 27 },

    ]

    return aEstados;
  } // fim seleciona estados


  async selecionaExamesValoresBioquimica() {
    let aExames = [
      { nome: "Glicose", Sigla: "Gl", vmin: 60, vmax: 110, msg: "Glicose", unidade: "mg/dL" },
      { nome: "Ureia", Sigla: "Ur", vmin: 15, vmax: 45, msg: "Uréia", unidade: "mg/dL" },
      { nome: "Creatinina", Sigla: "Cr", vmin: 0.3, vmax: 1.2, msg: "Creatinina", unidade: "mg/dL" },
      { nome: "Acidourico", Sigla: "AU", vmin: 2.4, vmax: 7.0, msg: "Ácido Úrico", unidade: "mg/dL" },
      { nome: "Sodio", Sigla: "Na", vmin: 135, vmax: 145, msg: "Sódio", unidade: "mmol/L" },
      { nome: "Cloro", Sigla: "Cl", vmin: 95, vmax: 105, msg: "Cloro", unidade: "mmol/L" },
      { nome: "Potassio", Sigla: "K", vmin: 3.5, vmax: 5.5, msg: "Potássio", unidade: "mmol/L" },
      { nome: "Magnesio", Sigla: "Mg", vmin: 1.8, vmax: 2.2, msg: "Magnésio", unidade: "mg/dL" },
      { nome: "Fosforo", Sigla: "P", vmin: 2.5, vmax: 4.8, msg: "Fósforo", unidade: "mg/dL" },
      { nome: "Ferro", Sigla: "Fe", vmin: 50, vmax: 170, msg: "Ferro", unidade: "μg/dL" },
      { nome: "Calcio", Sigla: "Ca", vmin: 8.6, vmax: 10, msg: "Cálcio", unidade: "mg/dL" },
      { nome: "Ferrentina", Sigla: "FER", vmin: 15, vmax: 300, msg: "Ferretina", unidade: "mg/dL" },
      { nome: "Triglicerides", Sigla: "TRI", vmin: 50, vmax: 199, msg: "Triglicerídeos", unidade: "mg/dL" },
      { nome: "Colesteroltotal", Sigla: "CT", vmin: 50, vmax: 239, msg: "Colesterol total", unidade: "mg/dL" },
      { nome: "Colesterolhdl", Sigla: "HDL", vmin: 35, vmax: 60, msg: "Colesterol HDL", unidade: "mg/dL" },
      { nome: "Colesterolldl", Sigla: "LDL", vmin: 100, vmax: 129, msg: "Colesterol LDL", unidade: "mg/dL" },
      { nome: "Colesterolvldl", Sigla: "VLDL", vmin: 15, vmax: 50, msg: "Colesterol VDRL", unidade: "mg/dL triglicérides x 0,20." },
      { nome: "Fosfatasealcalina", Sigla: "FA", vmin: 50, vmax: 250, msg: "Fosfatase alcalina", unidade: "U/L" },
      { nome: "Amilase", Sigla: "AMILASE", vmin: 15, vmax: 115, msg: "Amilase", unidade: "U/L" },
      { nome: "Lipase", Sigla: "LIPASE", vmin: 0, vmax: 60, msg: "Lipase", unidade: "U/L" },
      { nome: "Insulina", Sigla: "INSULINA", vmin: 2, vmax: 23, msg: "Insulina", unidade: "mU/L" },
      { nome: "Desidrogenaselatica", Sigla: "LDH", vmin: 100, vmax: 190, msg: "LDH", unidade: "mg/dL" },
      { nome: "Tgoast", Sigla: "TGO", vmin: 50, vmax: 250, msg: "TGO", unidade: "U/L" },
      { nome: "Tgpalt", Sigla: "TGP", vmin: 100, vmax: 190, msg: "TGP", unidade: "U/L" },
      { nome: "Bilirrubinatotal", Sigla: "BT", vmin: 0.4, vmax: 1.3, msg: "Bilirrubina total", unidade: "mg/dL" },
      { nome: "Bilirrubinadireta", Sigla: "BD", vmin: 0, vmax: 0.4, msg: "Bilirrubina direta", unidade: "mg/dL" },
      { nome: "Bilirrubinaindireta", Sigla: "BI", vmin: 0.6, vmax: 0.9, msg: "Bilirrubina indireta", unidade: "mg/dL" },
      { nome: "Procalcitonina", Sigla: "PCT", vmin: 0.25, vmax: 1, msg: "Procalcitonina", unidade: "ug/L" },
      { nome: "Gamagt", Sigla: "GGT", vmin: 7, vmax: 50, msg: "Gama glutamil transferase", unidade: "U/L" },
      { nome: "Cktotal", Sigla: "CK", vmin: 50, vmax: 200, msg: "CK total", unidade: "U/L" },
      { nome: "Ckmbenzimatico", Sigla: "CKE", vmin: 32, vmax: 200, msg: "Ckmb enzimático", unidade: "U/L" },
      { nome: "Ckmbmassa", Sigla: "CKMB", vmin: 0, vmax: 24, msg: "Ckmb massa", unidade: "U/L" },
      { nome: "Mioglobina", Sigla: "MIO", vmin: 10, vmax: 90, msg: "Mioglobina", unidade: "ng/L" },
      { nome: "Troponina", Sigla: "TRO", vmin: 0, vmax: 0.04, msg: "Troponina", unidade: "U/L" },
      { nome: "Proteinastotais", Sigla: "PT", vmin: 6.4, vmax: 8.1, msg: "Proteinas totais", unidade: "g/dL" },
      { nome: "Globulina", Sigla: "GLO", vmin: 2, vmax: 4, msg: "Globulina", unidade: "g/dL" },
      { nome: "Albumina", Sigla: "ALB", vmin: 3, vmax: 5, msg: "Albumina", unidade: "g/dL" },
      { nome: "Hemoglobinaglicolisada", Sigla: "HG", vmin: 4.5, vmax: 5.7, msg: "Hemoglobina glicolisada", unidade: "%" },
      { nome: "Bnp", Sigla: "BNP", vmin: 0, vmax: 70, msg: "Bnp", unidade: "pg/mL" },




    ]

    return aExames;
  } // 
  async selecionaExamesValoresFezes() {
    let aExames = [

      { nome: "Parasitologico", Sigla: "PARAS", vmin: 0, vmax: 0, msg: "Parasitológico", unidade: "" },
      { nome: "Sangueoculto", Sigla: "SO", vmin: 0, vmax: 0, msg: "Sangue oculto", unidade: "" },
      { nome: "Coprologicofuncional", Sigla: "CF", vmin: 0, vmax: 0, msg: "Coprologico funcional", unidade: "" },
      { nome: "Katokatz", Sigla: "KA", vmin: 0, vmax: 0, msg: "Kato Katz", unidade: "" },
      { nome: "Pesquisaleucocitos", Sigla: "PL", vmin: 0, vmax: 0, msg: "Pesquisa leucócitos", unidade: "" },

    ]
    return aExames;
  } // 

  async selecionaExamesValoresUrina() {
    let aExames = [

      { nome: "UrinatipoI", Sigla: "URINA I", vmin: 0, vmax: 0, msg: "Urina I", unidade: "" },
      { nome: "Dimorfismoeritrocitario", Sigla: "DIFOR", vmin: 0, vmax: 0, msg: "Dimorfismo eritrocitario", unidade: "" },
      { nome: "Proteinabencejones", Sigla: "PBJ", vmin: 0, vmax: 0, msg: "Proteína Bence Jones", unidade: "" },

    ]
    return aExames;
  } //

  async selecionaExamesValoresGasometria() {
    let aExames = [
      { nome: "Sexo", Sigla: "Sexo", vmin: 1, vmax: 2, msg: "Sexo", unidade: "" },
      { nome: "Peso", Sigla: "Peso", vmin: 10, vmax: 250, msg: "Peso", unidade: "Kg" },
      { nome: "Altura", Sigla: "Altura", vmin: 100, vmax:200, msg: "Altura", unidade: "cm" },
      { nome: "Idade", Sigla: "Idade", vmin:1, vmax:120, msg: "Idade", unidade: "anos" },
      { nome: "Ph", Sigla: "Ph", vmin: 7.35, vmax: 7.45, msg: "Ph", unidade: "" },
      { nome: "Paco2", Sigla: "PaCO2", vmin: 35, vmax: 45, msg: "PaCO2", unidade: "mmHg" },
      { nome: "Pvco2", Sigla: "PvCO2", vmin: 40, vmax: 50, msg: "PvCO2", unidade: "mmHg" },
      { nome: "Pao2", Sigla: "PaO2", vmin: 80, vmax: 100, msg: "PaO2", unidade: "mmHg" },
      { nome: "Lactato", Sigla: "Lactato", vmin: 0, vmax: 1, msg: "Lactato", unidade: "" },
      { nome: "Hco3", Sigla: "HCO3", vmin: 22, vmax: 26, msg: "HCO3", unidade: "mmol/L" },
      { nome: "Be", Sigla: "BE", vmin: -3, vmax: 3, msg: "Base excess", unidade: "" },
      { nome: "Fio2", Sigla: "FiO2", vmin: 21, vmax: 100, msg: "FiO2", unidade: "%" },
      { nome: "Pf", Sigla: "P/F", vmin: 300, vmax: 600, msg: "Relação P/F", unidade: "mmHg" },
      { nome: "Sato2", Sigla: "SatO2", vmin: 94, vmax: 100, msg: "Saturação de O2 arterial", unidade: "%" },
      { nome: "Svto2", Sigla: "SvtO2", vmin: 65, vmax: 70, msg: "Saturação de O2 venoso", unidade: "%" },

    ]
    return aExames;
  } //


  async selecionaExamesValoresCoagulograma() {
    let aExames = [
      { nome: "Temposangramento", Sigla: "TS", vmin: 1, vmax: 6, msg: "Tempo Sangramento", unidade: "min" },
      { nome: "Tempocoagulacao", Sigla: "TC", vmin: 6, vmax: 10, msg: "Tempo Coagulacao", unidade: "min" },
      { nome: "Tempoprotrombina", Sigla: "TP", vmin: 11, vmax: 13, msg: "Tempo Protrombina", unidade: "seg" },
      { nome: "Rni", Sigla: "RNI", vmin: 0.8, vmax: 1.2, msg: "RNI", unidade: "seg" },
      { nome: "Tempotromboplastina", Sigla: "TTPa", vmin: 15, vmax: 30, msg: "Tempo de tromboplastina parcial ativado", unidade: "%" },
      { nome: "Tempotrombina", Sigla: "TT", vmin: 5, vmax: 15, msg: "Tempo Trombina", unidade: "seg" },
      { nome: "Fibrinogenio", Sigla: "FIB", vmin: 100, vmax: 1000, msg: "Fibrinogênio", unidade: "mg/dL" },
      { nome: "AntitrombinaIII", Sigla: "ATIII", vmin: 20, vmax: 24, msg: "Antitrombina III", unidade: "mg/100 mL" },
      { nome: "Agregacaoplaquetaria", Sigla: "AP", vmin: 56, vmax: 74, msg: "Agregacao Plaquetária", unidade: "%" },
      { nome: "Tempocoaguloativado", Sigla: "TCA", vmin: 30, vmax: 60, msg: "Tempo Coagulo Ativado", unidade: "seg" },
      { nome: "Ddimero", Sigla: "DD", vmin: 68, vmax: 494, msg: "Dímero D", unidade: "ng/ml" },
      { nome: "Reticulocitos", Sigla: "MS", vmin: 0.5, vmax: 1.5, msg: "Reticulócitos", unidade: "%" },
      { nome: "Vhs", Sigla: "VHS", vmin: 15, vmax: 46, msg: "Valor de hemossedimendação", unidade: "seg" },
      { nome: "Pdf", Sigla: "PDF", vmin: 0, vmax: 10, msg: "PDF", unidade: "mg/mL" },
      { nome: "Falcizacao", Sigla: "FAL", vmin: 0, vmax: 20, msg: "Teste de falcização", unidade: "+ ou -" },
      { nome: "Provadolaco", Sigla: "PL", vmin: 0, vmax: 1, msg: "Prova do laço", unidade: "+ ou - " },

    ]

    return aExames;
  } // 

  async selecionaExamesValoresHemograma() {
    let aExames = [
      { nome: "Hemacias", Sigla: "Hemácias", vmin: 3.9, vmax: 6.5, msg: "Hemácias", unidade: "milhões/mm³" },
      { nome: "Hemoglobina", Sigla: "Hb", vmin: 11.5, vmax: 18, msg: "Hb", unidade: "g/dL" },
      { nome: "Hematocrito", Sigla: "Ht", vmin: 36, vmax: 74, msg: "Hematócrito", unidade: "%" },
      { nome: "Vcm", Sigla: "Vcm", vmin: 80, vmax: 96, msg: "Vcm", unidade: "fl" },
      { nome: "Hcm", Sigla: "Hcm", vmin: 27, vmax: 32, msg: "Hcm", unidade: "pg" },
      { nome: "Chcm", Sigla: "Chcm", vmin: 32, vmax: 35.5, msg: "Chcm", unidade: "g/dL" },
      { nome: "Rdw", Sigla: "Rdw", vmin: 12.1, vmax: 16.1, msg: "Rdw", unidade: "" },
      { nome: "Leucocitos", Sigla: "Leucocitos", vmin: 4000, vmax: 10000, msg: "Leucócitos", unidade: "cel/mm³" },
      { nome: "Mielocitos", Sigla: "Mielócitos", vmin: 0, vmax: 0, msg: "Mielócitos", unidade: "%" },
      { nome: "Metamielocitos", Sigla: "Metamielócitos", vmin: 0, vmax: 0, msg: "Metamielócitos", unidade: "%" },
      { nome: "Bastonetes", Sigla: "Bastonetes", vmin: 0, vmax: 5, msg: "Bastonetes", unidade: "%" },
      { nome: "Segmentados", Sigla: "Segmentados", vmin: 40, vmax: 70, msg: "Segmentados", unidade: "%" },
      { nome: "Eosinofilos", Sigla: "Eosinofilos", vmin: 0, vmax: 7, msg: "Eosinofilos", unidade: "%" },
      { nome: "Linfocitos", Sigla: "Linfocitos", vmin: 0, vmax: 7, msg: "Linfócitos", unidade: "%" },
      { nome: "Linfocitosatipicos", Sigla: "Linfocitosatipicos", vmin: 20, vmax: 50, msg: "Linfocitos atípicos", unidade: "%" },
      { nome: "Monocitos", Sigla: "Monócitos", vmin: 3, vmax: 14, msg: "Monócitos", unidade: "%" },
      { nome: "Basofilos", Sigla: "Basófilos", vmin: 0, vmax: 2, msg: "Basófilos", unidade: "%" },
      { nome: "Plaquetas", Sigla: "Plaquetas", vmin: 150, vmax: 450, msg: "Plaquetas", unidade: "mil/mm³" },

    ]

    return aExames;
  } // 

  async selecionaExamesValoresHormonio() {
    let aExames = [
      { nome: "T3", Sigla: "T3", vmin: 70, vmax: 204, msg: "T3", unidade: "ng/dL" },
      { nome: "T4", Sigla: "T4", vmin: 4.5, vmax: 12.6, msg: "T4", unidade: "ng/dL" },
      { nome: "Tshultrassensivel", Sigla: "TSH", vmin: 0.3, vmax: 4, msg: "TSH", unidade: "mU/L" },
      { nome: "T3livre", Sigla: "T3livre", vmin: 2.5, vmax: 4.0, msg: "T3 livre", unidade: "pg/ml" },
      { nome: "T4livre", Sigla: "T4livre", vmin: 0.7, vmax: 1.8, msg: "T4 livre", unidade: "ng/dl" },
      { nome: "Lh", Sigla: "LH", vmin: 0.6, vmax: 89.1, msg: "LH", unidade: "U/L" },
      { nome: "Fsh", Sigla: "FSH", vmin: 0.2, vmax: 80, msg: "FSH", unidade: "mUI/mL" },
      { nome: "Progesterona", Sigla: "Progesterona", vmin: 5, vmax: 90, msg: "Progesterona", unidade: "ng/mL" },
      { nome: "Prolactina", Sigla: "Prolactina", vmin: 0, vmax: 20, msg: "Prolactina", unidade: "ng/mL" },
      { nome: "Estrogeno", Sigla: "Estrogeno", vmin: 20, vmax: 450, msg: "Estrogeno", unidade: "pg/mL" },
      { nome: "Testosterona", Sigla: "Testosterona", vmin: 300, vmax: 1000, msg: "Testosterona", unidade: "ng/dL" },
      { nome: "Testosteronalivre", Sigla: "Testosteronalivre", vmin: 14, vmax: 827, msg: "Testosterona livre", unidade: "ng/dL" },

    ]

    return aExames;
  } // 

  async selecionaExamesValoresImunologia() {
    let aExames = [

      { nome: "Antihbctotal", Sigla: "Anti-HBc", vmin: 0, vmax: 1, msg: "Anti-Hbc total", unidade: "Positivo ou negativo" },
      { nome: "Antihbcigm", Sigla: "ANTI HBC IgM", vmin: 0, vmax: 1, msg: "ANTI HBC IgM", unidade: "Positivo ou negativo" },
      { nome: "Hbeag", Sigla: "HBsAg", vmin: 0, vmax: 1, msg: "HBsAg", unidade: "Positivo ou negativo" },
      { nome: "Antihbe", Sigla: "Anti-HBc", vmin: 0, vmax: 1, msg: "Anti-HBc", unidade: "Positivo ou negativo" },
      { nome: "Antihavigg", Sigla: "ANTI-HAV IgG", vmin: 0, vmax: 1, msg: "ANTI-HAV IgG", unidade: "Positivo ou negativo" },
      { nome: "Antihavigm", Sigla: "ANTI-HAV IgM", vmin: 0, vmax: 1, msg: "ANTI-HAV IgM", unidade: "Positivo ou negativo" },
      { nome: "Antihcv", Sigla: "Anti-HCV", vmin: 0, vmax: 1, msg: "Anti-HCV", unidade: "Positivo ou negativo" },
      { nome: "Lincd4", Sigla: "Linfócitos CD4", vmin: 0, vmax: 1, msg: "Linfócitos CD4", unidade: "Positivo ou negativo" },
      { nome: "Lincd8", Sigla: "Linfócitos CD8", vmin: 0, vmax: 1, msg: "Linfócitos CD8", unidade: "Positivo ou negativo" },
      { nome: "Ftaabs", Sigla: "FTA-abs", vmin: 0, vmax: 1, msg: "FTA-abs", unidade: "Positivo ou negativo" },
      { nome: "Tpha", Sigla: "TPHA", vmin: 0, vmax: 1, msg: "TPHA", unidade: "Positivo ou negativo" },
      { nome: "Antihiv", Sigla: "Anti-HIV", vmin: 0, vmax: 1, msg: "Anti-HIV", unidade: "Positivo ou negativo" },
      { nome: "Westemblothiv", Sigla: "Western Blot hiv", vmin: 0, vmax: 1, msg: "Western Blot hiv", unidade: "Positivo ou negativo" },
      { nome: "Paulbunnell", Sigla: "Paul-Bunnell-Davidsohn", vmin: 0, vmax: 7, msg: " Paul-Bunnell-Davidsohn", unidade: "Positivo ou negativo" },
      { nome: "Hgsag", Sigla: "HBsAg", vmin: 0, vmax: 1, msg: "HBsAg", unidade: "Positivo ou negativo" },
      { nome: "Fatorantinuclear", Sigla: "FAN", vmin: 0, vmax: 1, msg: "Fatorantinuclear", unidade: "Positivo (>1/300)" },
      { nome: "Chagasifihma", Sigla: "Chagas ifi hma", vmin: 0, vmax: 1, msg: "Chagas ifi hma", unidade: "Positivo ou negativo" },
      { nome: "Vdrl", Sigla: "VDRL", vmin: 0, vmax: 1, msg: "VDRL", unidade: "Positivo (>1/16 títulos)" },

      { nome: "Aslo", Sigla: "ASLO", vmin: 0, vmax: 200, msg: "ASLO", unidade: "U/mL" },
      { nome: "Prcultrassensivel", Sigla: "PCR", vmin: 0, vmax: 3, msg: "PCR", unidade: "mg/L" },
      { nome: "Fatorreumatoide", Sigla: "FR", vmin: 0, vmax: 29, msg: "Fator Reumatóide", unidade: "Ul/mL" },
      { nome: "Alfa1glicoproteinaacida", Sigla: "AGP", vmin: 44, vmax: 113, msg: "Alfa1 glicoproteina ácida", unidade: "mg/dL" },
      { nome: "C3", Sigla: "C3", vmin: 78, vmax: 180, msg: "C3", unidade: "mg/dL" },
      { nome: "C4", Sigla: "C4", vmin: 16, vmax: 39, msg: "C4", unidade: "mg/dL" },
      { nome: "Iggtotal", Sigla: "IGG", vmin: 730, vmax: 1620, msg: "IgG Total", unidade: "mg/dL" },
      { nome: "Igmtotal", Sigla: "IGM", vmin: 51, vmax: 195, msg: "IgM Total", unidade: "mg/dL" },
      { nome: "igatotal", Sigla: "IGA", vmin: 81, vmax: 387, msg: "IgA Total", unidade: "mg/dL" },
      { nome: "Igetotal", Sigla: "IGE", vmin: 0, vmax: 160, msg: "IgE Total", unidade: "kU/L" },
      { nome: "Antiperoxidase", Sigla: "Anti-Peroxidade", vmin: 0, vmax: 60, msg: "Anti-Peroxidade", unidade: "U/mL" },
      { nome: "Antitireoglobulina", Sigla: "Anti-tireoglobulina", vmin: 0, vmax: 100, msg: "Anti-tireoglobulina", unidade: "U/ml" },
      { nome: "Antihbs", Sigla: "Anti-HBS ", vmin: 0, vmax: 10, msg: "Anti-HBS", unidade: "mUI" },
      { nome: "Citomegalovirusiga", Sigla: "Citomegalovirus IgA", vmin: 0, vmax: 10, msg: "Citomegalovirus IgA", unidade: "U/ml" },
      { nome: "Citomegalovirusigm", Sigla: "Citomegalovirus IgM", vmin: 0, vmax: 10, msg: "Citomegalovirus IgM", unidade: "U/ml" },
      { nome: "Rubeolaigg", Sigla: "Rubeola IgG", vmin: 5, vmax: 15, msg: "Rubeola IgG", unidade: "UI/mL" },
      { nome: "Rubeolaigm", Sigla: "Rubeola IgM", vmin: 0.6, vmax: 0.79, msg: "Rubeola IgM", unidade: "UI/mL" },
      { nome: "Toxoplasmoseigg", Sigla: "Toxoplasmose IgG", vmin: 1.6, vmax: 3, msg: "Toxoplasmose IgG", unidade: "UI/mL" },
      { nome: "Toxoplasmoseigm", Sigla: "Toxoplasmose IgM", vmin: 0.5, vmax: 0.6, msg: "Toxoplasmose IgM", unidade: "UI/mL" },


    ]


    return aExames;
  } // 

  async selecionaExamesValoresMarcador() {
    let aExames = [
      { nome: "Alfafetoproteina", Sigla: "Alfafetoproteina", vmin: 0, vmax: 8.9, msg: "Alfafetoproteina", unidade: "ng/ml" },
      { nome: "Cea", Sigla: "Cea", vmin: 0, vmax: 30, msg: "CEA", unidade: "ng/dL" },
      { nome: "Ca153", Sigla: "Ca153", vmin: 0, vmax: 30, msg: "CA 15-3", unidade: "mU/L" },
      { nome: "Ca199", Sigla: "Ca199", vmin: 0, vmax: 37, msg: "CA 19-9", unidade: "mU/L" },
      { nome: "Ca125", Sigla: "Ca125", vmin: 0, vmax: 35, msg: "CA 12-5", unidade: "mU/L" },
      { nome: "Ca724", Sigla: "Ca724", vmin: 0, vmax: 6.9, msg: "CA 72-4", unidade: "mU/L" },
      { nome: "Cyfra21", Sigla: "Cyfra21", vmin: 0.2, vmax: 80, msg: "Cyfra21", unidade: "mU/mL" },
      { nome: "Psa", Sigla: "Psa", vmin: 0, vmax: 5, msg: "PSA", unidade: "ng/mL" },
      { nome: "Psalivre", Sigla: "Psalivre", vmin: 0, vmax: 5, msg: "PSA Livre", unidade: "mU/mL" },

    ]

    return aExames;
  } // 

  async selecionaExamesValoresVital() {
    let aExames = [
      { nome: "Ps", Sigla: "Ps", vmin: 100, vmax: 120, msg: "Ps", unidade: "mmHg" },
      { nome: "Pd", Sigla: "PD", vmin: 60, vmax: 80, msg: "PD", unidade: "mmHg" },
      { nome: "Fc", Sigla: "Fc", vmin: 60, vmax: 90, msg: "Fc", unidade: "bpm" },
      { nome: "Fr", Sigla: "FR", vmin: 10, vmax: 22, msg: "FR", unidade: "ipm" },
      { nome: "Temp", Sigla: "Temp", vmin: 36, vmax: 37.5, msg: "Temp", unidade: "oC" },
      { nome: "O2", Sigla: "SatO2", vmin: 93, vmax: 100, msg: "SatO2", unidade: "%" },
      { nome: "Hgt", Sigla: "Hgt", vmin: 70, vmax: 140, msg: "Hgt", unidade: "mg/dL" },

    ]

    return aExames;
  } // 

  async Criptografar(valor: string) {
    if (valor.length == 0 || valor == '' || valor == undefined || valor == null) {
      return '';
    }
    let key = "UHJlcGF280462ZSBmb3Igc2209775jZQ=";
    let encrypted: string = CryptoJS.AES.encrypt(valor, key).toString();
    return encrypted;
  }

  async Descriptografar(valor: string) {
    if (valor == null || valor == '' || valor == undefined) {
      return;
    }
    let key = "UHJlcGF280462ZSBmb3Igc2209775jZQ=";
    let decrypted: any = CryptoJS.AES.decrypt(valor, key);
    if (decrypted == null || decrypted == undefined) {
      return;
    }
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    return decrypted

  }

  async selecionaTabelaNomeCombo(tabela: string = null) {
    let aTabelaacessoria = [
      { Nome: "Antibioticogrupo", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Apresentacao", Colunas: 'Id-Sigla', Cripto: 0 },
      { Nome: "Antibiotico", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Conselho", Colunas: 'Id-Sigla', Cripto: 0 },
      { Nome: "Escore", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Estado", Colunas: 'Id-Sigla', Cripto: 0 },
      { Nome: "Hospital", Colunas: 'Id-Nome', Cripto: 1 },
      { Nome: "Infeccaocultura", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Infeccaomicrobiologia", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Intervalo", Colunas: 'Id-Sigla', Cripto: 0 },
      { Nome: "Internacao", Colunas: 'Id-Paciente', Cripto: 0 },
      { Nome: "Paciente", Colunas: 'Id-Nome', Cripto: 1 },
      { Nome: "Posicao", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Sistema", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Setor", Colunas: 'Id-Nome', Cripto: 1 },
      { Nome: "Sexo", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Usuario", Colunas: 'Id-Nome', Cripto: 1 },
      { Nome: "Via", Colunas: 'Id-Sigla', Cripto: 0 },
      { Nome: "Escoretipo", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Unidademedida", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Tipopergunta", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Pergunta", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Pesquisa", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Entrevistado", Colunas: 'Id-Nome', Cripto: 0 },
      { Nome: "Resposta", Colunas: 'Id-Nome', Cripto: 0 },

    ]

    if (tabela != null) {
      aTabelaacessoria = aTabelaacessoria.filter(function (e) {
        e.Nome = tabela;
      })

    }


    return aTabelaacessoria
  } // 

  // tabelas que são criptografadas
  async selecionaColunaTabelaCripto(tabela: string) {
    let aTabela = [
      { Tabela: "Usuario", Coluna: "Nome" },
      { Tabela: "Usuario", Coluna: 'Email' },
      { Tabela: "Usuario", Coluna: "Celular" },
      { Tabela: "Usuario", Coluna: "Localidade" },
      { Tabela: "Usuario", Coluna: "Bairro" },
      { Tabela: "Usuario", Coluna: "Logradouro" },
      { Tabela: "Usuario", Coluna: 'Complemento' },
      { Tabela: "Usuario", Coluna: "Cpf" },
      { Tabela: "Usuario", Coluna: "Cep" },
      { Tabela: "Usuario", Coluna: "Numeroconselho" },
      { Tabela: "Usuario", Coluna: "Senha" },
      { Tabela: "Usuario", Coluna: "Curriculo" },

      // Hospital
      { Tabela: "Hospital", Coluna: "Nome" },
      { Tabela: "Hospital", Coluna: 'Email' },
      { Tabela: "Hospital", Coluna: "Celular" },
      { Tabela: "Hospital", Coluna: "Localidade" },
      { Tabela: "Hospital", Coluna: "Bairro" },
      { Tabela: "Hospital", Coluna: "Logradouro" },
      { Tabela: "Hospital", Coluna: 'Complemento' },
      { Tabela: "Hospital", Coluna: 'Cnpj' },
      { Tabela: "Hospital", Coluna: "Cep" },
      { Tabela: "Hospital", Coluna: 'Responsavel' },

      //

      // Paciente
      { Tabela: "Paciente", Coluna: "Nome" },
      { Tabela: "Paciente", Coluna: 'Email' },
      { Tabela: "Paciente", Coluna: "Celular" },
      { Tabela: "Paciente", Coluna: "Localidade" },
      { Tabela: "Paciente", Coluna: "Bairro" },
      { Tabela: "Paciente", Coluna: "Logradouro" },
      { Tabela: "Paciente", Coluna: 'Complemento' },
      { Tabela: "Paciente", Coluna: "Cpf" },
      { Tabela: "Paciente", Coluna: "Cep" },


      // setor
      { Tabela: "Setor", Coluna: "Nome" },

      // Email externo

      { Tabela: "Email", Coluna: "Nome" },
      { Tabela: "Email", Coluna: 'Email' },
      { Tabela: "Email", Coluna: "Celular" },
      { Tabela: "Email", Coluna: "Mensagem" },
      { Tabela: "Email", Coluna: "Resposta" }
    ]

    // if (tabela != null) {
    //   aTabela = aTabela.filter( function (e) 
    //   {
    //     e.Tabela = tabela;
    //   })

    // }
    let aTabelaNova = [];

    for (let i = 0; i < aTabela.length; i++) {
      if (aTabela[i].Tabela == tabela) {
        aTabelaNova.push({ Tabela: aTabela[i].Tabela, Coluna: aTabela[i].Coluna });
      }
    }

    return aTabelaNova
  } // 



  async selecionaTermodeUso() {
    let texto: string =
      "1. ACEITAÇÃO" + String.fromCharCode(10) +
      "Este é um contrato firmado entre você, de agora em diante denominado como usuário, e LISTMED," +
      "empresa cadastrada no CNPJ sob nº 40.666.866/0001-50 com sede no município de Ilhéus, estado da Bahia," +
      "a Av. Osvaldo Cruz, 74, Boa Vista, sala 09, Ilhéus-Bahia, CEP 45.652.130 e de agora em diante denominada simplesmente de Melhormente Clipsis." + String.fromCharCode(10) +
      "Este Termo de Uso de Aplicativo rege o uso de todos os aplicativos" +
      "disponibilizados gratuitamente pela Melhormente Clipsis sejam para dispositivos móveis (Android, IOS, Windows Mobile)," +
      "servidores, computadores pessoais (desktops) ou serviços web. Se você não concordar com estes termos não" +
      "use este aplicativo." + String.fromCharCode(10) +
      "Você reconhece que analisou e aceitou as condições de uso. Leia-as atentamente pois o uso deste aplicativo" +
      "significa que você aceitou todos os termos e concorda em cumpri-los. Se você, usuário, for menor de idade ou" +
      "declarado incapaz em quaisquer aspectos, precisará da permissão de seus pais ou responsáveis que também" +
      "deverão concordar com estes mesmos termos e condições." + String.fromCharCode(10) +
      "2. LICENÇA LIMITADA" + String.fromCharCode(10) +
      "Você recebeu uma licença limitada, não transferível, não exclusiva, livre de royalties e revogável para baixar," +
      "instalar, executar e utilizar este aplicativo em seu dispositivo. Você reconhece e concorda que a Melhormente Clipsis" +
      "concede ao usuário uma licença exclusiva para uso e desta forma não lhe transfere os direitos sobre o produto." +
      "O aplicativo deverá ser utilizado por você, usuário. A venda, transferência, modificação, engenharia reversa ou" +
      "distribuição bem como a cópia de textos, imagens ou quaisquer partes nele contido é expressamente proibida." + String.fromCharCode(10) +
      "3. ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO" + String.fromCharCode(10) +
      "A Melhormente Clipsis reserva-se no direito de, a qualquer tempo, modificar estes termos seja incluindo, removendo ou" +
      "alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato. Após publicadas tais alterações," +
      "ao continuar com o uso do aplicativo você terá aceitado e concordado em cumprir os termos modificados." +
      "A Melhormente Clipsis pode, de tempos em tempos, modificar ou descontinuar (temporária ou permanentemente) a" +
      "distribuição ou a atualização deste aplicativo." + String.fromCharCode(10) +
      "A Melhormente Clipsis não é obrigada a fornecer nenhum serviço de suporte para este aplicativo." +
      "O usuário não poderá responsabilizar a Melhormente Clipsis nem seus diretores, executivos, funcionários, afiliados, agentes," +
      "contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidade do aplicativo." + String.fromCharCode(10) +
      "CONSENTIMENTO PARA COLETA E USO DE DADOS" + String.fromCharCode(10) +
      "Você concorda que a Melhormente Clipsis pode coletar e usar dados técnicos de seu dispositivo tais como especificações, configurações, versões de sistema operacional, tipo de conexão à internet e afins." + String.fromCharCode(10) +
      "4. ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE" + String.fromCharCode(10) +
      "Este aplicativo estará em contínuo desenvolvimento e pode conter erros e, por isso, o uso é fornecido no" +
      "estado em que se encontra e sob risco do usuário final. Na extensão máxima permitida pela legislação aplicável" +
      "a Melhormente Clipsis e seus fornecedores isentam-se de quaisquer garantias e condições expressas ou implícitas incluindo," +
      "sem limitação, garantias de comercialização, adequação a um propósito específico, titularidade e não violação no" +
      "que diz respeito ao aplicativo e qualquer um de seus componentes ou ainda à prestação ou não de serviços de" +
      "suporte. A Melhormente Clipsis não garante que a operação deste aplicativo seja contínua e sem defeitos." +
      "Exceto pelo estabelecido neste documento não há outras garantias, condições ou promessas aos aplicativos," +
      "expressas ou implícitas, e todas essas garantias, condições e promessas podem ser excluídas de acordo com o" +
      "que é permitido por lei sem prejuízo à Melhormente Clipsis e seus colaboradores." +
      "I. A Melhormente Clipsis não garante, declara ou assegura que o uso deste aplicativo será ininterrupto ou livre de erros" +
      "e você concorda que a Melhormente Clipsis poderá remover por períodos indefinidos ou cancelar este aplicativo a qualquer" +
      "momento sem que você seja avisado." + String.fromCharCode(10) +
      "II. A Melhormente Clipsis não garante, declara nem assegura que este aplicativo esteja livre de perda, interrupção," +
      "ataque, vírus, interferência, pirataria ou outra invasão de segurança e isenta-se de qualquer responsabilidade em" +
      "relação à essas questões. Você é responsável pelo backup do seu próprio dispositivo." + String.fromCharCode(10) +
      "III. Em hipótese alguma a Melhormente Clipsis, bem como seus diretores, executivos, funcionários, afiliadas, agentes," +
      "contratados ou licenciadores responsabilizar-se-ão por perdas ou danos causados pelo uso do aplicativo." +
      "IV. Os conteúdos transmitidos pelos aplicativos tais como a transmissão da programação de rádios AM, FM," +
      "programações televisivas e afins são de responsabilidade de seus idealizadores. A Melhormente Clipsis isenta-se de qualquer" + String.fromCharCode(10) +
      "responsabilidade direta ou indireta sobre estes conteúdos e o acesso aos mesmos é facultativo ao usuário." + String.fromCharCode(10) +
      "Ilhéus, 28 de abril de 2020" + String.fromCharCode(10)
    let atexto = texto.split(String.fromCharCode(10));
    return atexto;
  }



  async atualizarDadosDoSistema(dadosListMed: any, callback = null) {
    let loading = await this.loadingCtrl.create({
      message: 'atualizando dados do sistema..',
    });
    loading.present();
    let dadosCripto = JSON.stringify(dadosListMed); //String para base64
    localStorage.setItem('dadosListMed', dadosCripto);
    if (callback != null) {
      loading.dismiss();
      callback();
    } else {
      loading.dismiss();
    }
    loading.dismiss();
  }

  obterDadosDoSistema() {
    let dados = localStorage.getItem('dadosListMed');
    if (dados == null) {
      return null;
    }


    let dadosListMed: SistemaModule = (JSON.parse(dados) as SistemaModule);
    return dadosListMed
  }

  somaDiaData(data: string, dias: number) {
    let hoje = new Date(data);
    let dataVenc = new Date(hoje.getTime() + (dias * 24 * 60 * 60 * 1000));
    let dia: any = dataVenc.getDate();
    let mes: any = dataVenc.getMonth() + 1
    let ano: any = dataVenc.getFullYear();
    let hora: any = dataVenc.getHours();          // 0-23
    let minuto: any = dataVenc.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = ano.toString() + "-" + mes.toString() + "-" + dia.toString() + " " + hora.toString() + ':' + minuto.toString();

    return data
  }

 

  dataHoje() {
    let data: any = new Date();
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }
    data = ano.toString() + "-" + mes.toString() + "-" + dia.toString() + " " + hora.toString() + ':' + minuto.toString();
    return data
  }

  dataHojeSemHora() {
    let data: any = new Date();
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = ano.toString() + "-" + mes.toString() + "-" + dia.toString();

    return data
  }

  dataHojeSemHoraBrasil() {
    let data: any = new Date();
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = dia.toString() + "/" + mes.toString() + "/" + ano.toString();

    return data
  }

  dataRelatorio(dt: string) { // tipo inicial ou final
    let data: any = new Date(dt);
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

      data = ano.toString() + "-" + mes.toString() + "-" + dia.toString() ;   
    
   
    return data
  }

  dataNascimento(datanascimento: string) {
    let data: any = new Date(datanascimento);
    let dia: any = data.getDate();
    let mes: any = data.getMonth() + 1
    let ano: any = data.getFullYear();
    let hora: any = data.getHours();          // 0-23
    let minuto: any = data.getMinutes();        // 0-59

    if (dia < 10) {
      dia = "0" + dia.toString();
    }

    if (mes < 10) {
      mes = "0" + mes.toString();;
    }

    if (hora < 10) {
      hora = "0" + hora.toString();;
    }

    if (minuto < 10) {
      minuto = "0" + minuto.toString();;
    }

    data = ano.toString() + "/" + mes.toString() + "/" + dia.toString();

    return data
  }

  dataSistema(data: string=null) {
    if (data == null) {
      return "";
    }
    data = data.substring(6, 10) + "-" + data.substring(3, 5) + "-" + data.substring(0, 2)
    return data
  }

  dataHojeBrasil() {
    let data: any = new Date();
    let mes: any = data.getMonth() + 1;          // 0-11 (zero=janeiro)
    let ano: any = data.getFullYear();       // 4 dígitos
    let hora: any = data.getHours();          // 0-23
    let min: any = data.getMinutes();
  
    let dia: any = data.getDate();           // 1-31
    let dia_sem: any = data.getDay();                  // 0-59
    if (dia < 10) {
      dia = "0" + dia;
    }
    if (mes < 10) {
      mes = "0" + mes;
    }
    if (hora < 10) {
      hora = "0" + hora;
    }
    if (min < 10) {
      min = "0" + min;
    }

    // Formata a data e a hora (note o mês + 1)
    let str_data = dia + '/' + mes + '/' + ano;
    let str_hora = hora + ':' + min;
    return str_data + " " + str_hora;
  }

  atualizarImagemTabela(imagem: any) {
    // fazer a partir do atualizar imagem usuario na api
    let url = this.obterUrlDaApi() + 'Util/atualizarImagemTabela';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(url, imagem, { headers: headers }).toPromise();
  }

  async validaCNPJ(cnpj: string, toastCallback) {
    this.lCnpj = true;
    if (cnpj == '' || cnpj == undefined || cnpj == null || cnpj.length == 0) {
      this.showAlertCallback('CNPJ em branco', toastCallback);
      this.lCnpj = false;
      return this.lCnpj;
    }

    if (cnpj.length != 14) {
      this.showAlertCallback('CNPJ deve ter 14 dígitos', toastCallback);
      this.lCnpj = false;
      return this.lCnpj;
    }

    // numeros repetidos
    //137797070001 => 12
    let n = '';

    for (let i = 0; i < 10; i++) {
      n = '';

      if (this.lCnpj == false) {
        break;
      }
      for (let z = 0; z < 15; z++) {
        n = n + i.toString();
        if (cnpj == n) {
          this.showAlertCallback('CNPJ inválido. Números iguais', toastCallback);
          this.lCnpj = false;
          break;
        }
      }

    }


    if (this.lCnpj == false) {
      return this.lCnpj;
    }

    let soma: any = 0


    soma = 0  // verificador do primeiro digito do
    let fator: any = [];
    fator = '5,4,3,2,9,8,7,6,5,4,3,2';
    fator = fator.split(',');
    for (let t = 0; t < 12; t++) {
      soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
    }

    soma = soma / 11;
    soma = soma.toString();
    let sobra1: any = '';
    if (soma.indexOf('.') > 0) {
      sobra1 = soma.split(".");
      sobra1 = sobra1[1].toString();
      sobra1 = parseFloat(sobra1.substring(0, 1) + '.' + sobra1.substring(1));
      sobra1 = sobra1.toFixed(1);
      let x: number = 9
      for (let p = 1; p < 10; p++) {
        if (sobra1 > x) {
          sobra1 = x + 1
          break;
        }
        x--
      }
    } else {
      sobra1 = 0;
    }
    if (sobra1 >= 2) {
      sobra1 = 11 - sobra1
    } else {
      sobra1 = 0
    }

    let dv1: string = sobra1.toString();
    let sobra2: any = '';

    soma = 0  // verificador do segundo digito do
    fator = '6,5,4,3,2,9,8,7,6,5,4,3,2';
    fator = fator.split(',');    // acrescento o primeiro digito verificador aos 9 primeiros número
    for (let t = 0; t < 13; t++) {
      soma = soma + parseInt(cnpj.substring(t, t + 1)) * fator[t];
    }
    ;
    soma = soma / 11;
    soma = soma.toString();
    if (soma.indexOf('.') > 0) {
      sobra2 = soma.split(".");
      sobra2 = sobra2[1].toString();
      sobra2 = parseFloat(sobra2.substring(0, 1) + '.' + sobra2.substring(1));
      sobra2 = sobra2.toFixed(1);
      let x: number = 9
      for (let p = 1; p < 10; p++) {
        if (sobra2 > x) {
          sobra2 = x + 1
          break;
        }
        x--
      }

    } else {
      sobra2 = 0
    }

    if (sobra2 >= 2) {
      sobra2 = 11 - sobra2
    } else {
      sobra2 = 0
    }

    let dv2: string = sobra2.toString();
    let dv: string = dv1 + dv2;
    let dvcnpj: string = cnpj.substring(12, 14)
    if (dv != dvcnpj) {
      this.showAlertCallback('CNPJ inválido. Dígito não confere', toastCallback);
      this.lCnpj = false;
      return this.lCnpj;
    }
    return this.lCnpj;
  }

  async checaDados() {

    let dados = localStorage.getItem('dadosListMed');
    if (dados == null) {
      this.aExame.push({
        Internacao :0,
        Paciente :0,
        Usuario: 0,
        Exame: 0,
      }) ;
      this.aFasthug.push({
         Internacao :0,
         Paciente :0,
         Cabeceira: '',
         Analgesia: '',
         Sedacao:'',
         Dieta:'',
         Troboembolismo: '', 
         Ulcera: '',
         Glicemia:''
      });

      this.aUsuario.push({
        Id:0,
        Nome:'',
        Datanascimento:'',
        Sexo:0,
        Email:'',
        Celular:'',
        Senha:'',
        Cep:'',
       Logradouro:'',
       Complemento:'',
       Bairro:'',
       Estado:0,
       Municipio:0,
       Localidade:'',
       Termosdeuso:0,
       Imagem:'',
       Ativo:0,
       Administrador:0,
       Curriculo:'',
       Cpf:'',
       Conselho:0,
       Numeroconselho:'',
       Codigovalidacao:'',
       Retorno:0,
       Idade:0,
       Siglaconselho:'',
       Logado:0
      });

      this.aInternacao.push(
        {
       Id :0,
       Paciente :0,
       Peso :'',
       NomePaciente :'',
       Datainternacao :'',
       Hospital :0,
       DescricaoHospital :'',
       Setor :0,
       DescricaoSetor :'',
       Leito :'',
       Creatininabasal :'',
       Balancoacumulado :'',
       Usuario :0,
       Dias :0,
       Idade :0,
       Comorbidade :'',
       Queixaprincipal :'',
       Historiaclinica :'',
       Examefisico :'',
       Medicamento :'',
       Problema :'',
       Planoterapeutico :'',
       Pontos :0,
       Mortalidade :'',
       Altura   :'',
       Bsa   :'',
       PesoIdealMasculino :'',
       PesoIdealFeminino :'',
       VolemiaMasculino  :'',
       VolemiaFemenino :'',
       Imc   :'',
       Imagem :'',
       Sexo :0,
       PaO2Ideal :'',
       SiglaEstado :'',
       FASTHUG: 0,
       FASTHUGrelacao: '',
       SOFA: 0,
       SOFArelacao: '',
       Gestante: 0,
       Convenio: 0,
       Ambulatorio: 0
        });
      // SOFA
      this.aSofa.push(
        {
       Internacao :0,
       Paciente :0,
       Neurologico : 0,
       Cardiaco: 0,
       Respiratorio:0,
       Digestivo:0,
       Renal: 0,
       Hematologico:0
        });


      //
      this.aTabela.push(
        {
          Nome: '',
          Colunas: [],
          Id: 0
        });

        this.aQuestionario.push(
        {
           Id :0,
           Data :'',
           Nome: '',
           Email: '',
           Facebook:'',
           Instagram:'',
           Celular: '', 
           Pesquisa: 0,
           Resultado:'',
           Ativo: 0,
           Codigo: 0
        })

        this.aExame.push(
          {
             Internacao :0,
             Paciente :0,
             Usuario: 0,
             Exame: 0
          })
  

      this.dadosListMed.Tabela = this.aTabela;
      this.dadosListMed.Usuario = this.aUsuario;
      this.dadosListMed.Internacao = this.aInternacao;
      this.dadosListMed.Sofa = this.aSofa;
      this.dadosListMed.Fasthug = this.aFasthug;
      this.dadosListMed.Questionario = this.aQuestionario;
      this.dadosListMed.Exame = this.aExame;

      this.atualizarDadosDoSistema(this.dadosListMed, null)
    }
  }
  private eventSubject = new Subject<any>();
  
  publishEvent(key: any, value: any) {
    let data = { key: key, value: value };
    this.eventSubject.next(data);
  }

  getEvent(): Subject<any> {
    return this.eventSubject;
  }

  formateNumber(valor: any, decimal: number, s:number=0) {
    if (valor == '' || valor == undefined && valor == null) {
      return '';
    }
    let Inteirio:any = ''

    valor = valor.toString();
    let valornovo: any;

    if (valor.indexOf(',') > -1) {
      valornovo = valor.split(',');
      if (decimal > 0) {
        valornovo = valornovo[0] + '.' + valornovo[1].substr(0, decimal);
        //valornovo = parseFloat(valornovo);
        if (s > 0) {
          let sNumero:string = valornovo[1].substr(0, 1) ;
          let Numero:number = parseInt(sNumero)
          if (Numero >=5) {
            let NumeroNovo:number = parseInt(Inteirio) + 1;
            valor =  NumeroNovo; 
          }else{
            let NumeroNovo:number = parseInt(Inteirio) ;
            valor =  NumeroNovo; 
          }
        }

        
      } else {
        valor = parseInt(valor);
      }
    } else if (valor.indexOf('.') > -1) {
      if (decimal > 0) {
        valornovo = valor.split('.');
        Inteirio = valornovo[0]
        valornovo = valornovo[0] + '.' + valornovo[1].substr(0, decimal);
        valor = parseFloat(valornovo);
        if (s > 0) {
          let sNumero:string = valornovo[1].substr(0, 1) ;
          let Numero:number = parseInt(sNumero)
          if (Numero >=5) {
            let NumeroNovo:number = parseInt(Inteirio) + 1;
            valor =  NumeroNovo; 
          }else{
            let NumeroNovo:number = parseInt(Inteirio) ;
            valor =  NumeroNovo; 
          }
        }
      } else {
        valor = parseInt(valor);
      }
    }

   
    return valor;
  }

  formateNumberNew(valor: any, decimal: number, s:number=0) { // refere a algorismo significativo
   if (valor == undefined || valor == null || valor == '' || valor == 0 || valor== '0' ) {
    return '0.0';
   }

   
    let aVet:any = [];
    let valorNovo:any;
    if (valor.toString().indexOf('.') > -1) {
      valor = valor.toString().replace(".", ",");
    }
    if (valor.toString().indexOf(',') > -1 ) {
      
      let aVetor:any = valor.split(',');
        let v1:string = aVetor[0];
        let v2:string = aVetor[1];
        v2= v2.substring(0,decimal)
        let zero:boolean= true;
        for (let i=0; i<v2.length; i++) {
          if (v2.substring(i,i+1) != '0') {
            zero= false;
            break;
          }
        }
        if (zero == true) {
          valorNovo= parseInt(valor);
        }else{
          if (s == 0) { // não quero algarismo significativo
            valorNovo = parseFloat(v1 + '.' + v2); // apenas troco a virgula por ponto
          }else{

            valorNovo = parseFloat(valor.replace(",", '.'));

            let p:number= 0;
            p= decimal + v1.length ;
            valorNovo = valorNovo.toPrecision(p);


          }
        
        }
    }else{  
      valorNovo = parseInt(valor)
    }
   // //console.log("S:" + s + " valor: " + valor + '->' + valorNovo)

    return valorNovo;

  }


  checaCampo(valor: any) {
    if (valor.toString().indexOf(',') > -1 && valor != '') {
      valor = valor.replace(',', '.');
    }

    return valor;
  }


  checaData(data: any, formato:string='') {
    if (data.toString().indexOf('T') > -1) {
      data = data.replace("T", " ");
      data = data.substr(0, data.length - 6);
      if (formato == '1') { // somente ano-mes-dia
        data = data.substr(0, 10);

      }
    }

    //13/12/2021 14:54:00
    if (data.substr(2, 1) == '/' || data.substr(2, 1) == '-') {
      let Dia = data.substr(0, 2);
      let Mes = data.substr(3, 2);
      let Ano = data.substr(6, 4);
      let Hora = data.substr(11, 5);
      data = Ano + '/' + Mes + '/' + Dia + ' ' + Hora
    }



    return data;
  }



   CalculaDias(d1, d2){;
    if ( d1 != ''  && d2 != '') {;
      var S1 = d1, D;
      S1 = S1.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1");
      S1 = S1.replace(/^(\d\d\/)/, "20$1");
      d1 = new Date(S1);
      
      var S2 = d2, D;
      S2 = S2.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1");
      S2 = S2.replace(/^(\d\d\/)/, "20$1");
      d2 = new Date(S2);
      
      let dias:number= d2 - d1 ;
       dias = Math.ceil(dias / (60000 * 60 * 24) );
     // dias= parseInt(dias)  
      return dias
    }
  }
  
  CalculaDPPeDUM(dum:string, de:string, semanas:number) {
   
    let expReg = /^(([0-2]\d|[3][0-1])\/([0]\d|[1][0-2])\/[1-2][0-9]\d{2})$/;
    let hoje=new Date() ;
    var t= 0;
    if ((de.match(expReg)) && (de!='')) {
    }else{
      alert('Data exame não informado!')
      return false;
    }
    
    if ( de != ''  ) {;
      var txtData = de;
      let d = new Date();
      var d2 = new String();
      d.setTime(Date.parse(txtData.split("/").reverse().join("/")));
      let mes=0;
      mes=  d.getMonth() + 1 ;
      de=  mes.toString() + "/"+  d.getDate() + "/"+ d.getFullYear() ;
    }
    let r = semanas/1;
    if (isNaN(r)) {
      alert('Semanas informada inválido!');
      return false;
    }

    
    }
      
    montaCorItem(n:number, cor:string) {
      let Cor:string="";
      let aCor:any=[];
       for (let i=0; i<n; i++) {
        if (Cor == '') {
          Cor=  cor;
        }else{
          Cor= "";
        }
        aCor.push(
          {
          Cor: Cor
        })     
      
       }
    
       return aCor;
  }

  async showToastWithButton(message: string, callback = null) {
    const toast = await this.toastController.create({
      message: message,
      color: 'primary',
      position: 'bottom',
      buttons: [{ text: 'OK', icon: 'okay', handler: callback }]
    });
    toast.present();
  }
  
  async obterFotoEmStringBase64(nomeImagem: string) {
    let loading = await this.loadingCtrl.create({ message: "gerando imagem...", mode: 'ios' });
    loading.present();
    let url = this.obterUrlDaApi() + 'Util/ObterFotoEmStringBase64?nomeImagem=' + nomeImagem;
    loading.dismiss();
    return this.http.get(url).toPromise();
  }
}
