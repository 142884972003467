import { Component, OnInit, ViewChild, ElementRef,  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UtilService } from '../../services/util.service';
import { NavParams, ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { UsuarioService } from '../../services/usuario.service';
import { TabelaService } from '../../services/tabela.service';
import { UtilpdfService } from '../../services/utilpdf.service';
import { ChecklistService } from '../../services/checklist.service';
import { EstudoService } from '../../services/estudo.service';
import { EventoedicaoComponent } from 'src/app/componentes/eventoedicao/eventoedicao.component';

@Component({
  selector: 'app-curso.component',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss'],
})
export class CursoComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraDescricao', null)   ancoraDescricao: ElementRef;
  @ViewChild('ancoraPalestrante', null) ancoraLocalizacao: ElementRef;
  @ViewChild('ancoraLocalizacao', null) ancoraPalestrante: ElementRef;

  Botao:string="CADASTRAR"
  cursoForm: FormGroup;
  dadosListMed:any=[]
  Usuario:number=0;
  Logado:number=0;
  Administrador:number=0;
  isCordova:boolean=false;
  IdEvento:number=0;
  collectionCurso:any=[];
  NomeEvento:string="";
  Datainicial:string="";
  Datafinal:string="";
  constructor( private formBuilder: FormBuilder,
    private utilService: UtilService,
    private loadingController: LoadingController,
    private modalController: ModalController,

    private usuarioService: UsuarioService,
    private alertController: AlertController,
    private platform: Platform, 
    private utilpdfService: UtilpdfService,
    private checklistService: ChecklistService,
    private estudoService:EstudoService,
    private navParams:NavParams
) { 
      this.dadosListMed = this.utilService.obterDadosDoSistema();
      this.cursoForm = formBuilder.group({
        Id: ['', ''],
        Nome: ['', ''],
        Datalancamento: ['', ''],
        Datainicial: ['', ''],
        Datafinal: ['', ''],
        Descricao: ['', ''],
        Palestrante: ['', ''],
        Localizacao: ['', ''],
        Ativo: ['', ''],
        Usuario: ['', ''],

      })

      this.dadosListMed = this.utilService.obterDadosDoSistema();
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.IdEvento = this.navParams.get('Evento');
      this.NomeEvento = this.navParams.get('NomeEvento');
      this.Datainicial = this.navParams.get('Datainicial');
      this.Datafinal = this.navParams.get('Datafinal');
    }

  ngOnInit() {
    this.isCordova = this.platform.is('cordova') ;

  }

  ionViewDidEnter() {
    this.testaAcessoSistema();
    this.limpaDados();
    this.selecionarCursoEvento();
  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Administrador = this.dadosListMed.Usuario[0].Administrador;
      if (this.Logado == 0 || this.Administrador == 0) {
        this.utilService.Movimentar("login");
      }
    }
  }
  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
    })
   }

   async selecionarCursoUnico(id:number=null) {
    if (id == 0 ||id == null || id == -1 || id == undefined) {
      this.limpaDados();
      return;
    }
    this.estudoService.selecionarCursoUnico(id).then((response:any)=>{
        this.getDados(response)
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível selecionar o CURSO")
    })


   }


   async limpaDados() {
    this.Botao= "CADASTRAR";
    let Datalancamento = this.utilService.dataHoje();
    this.cursoForm.controls['Id'].setValue(-1);
    this.cursoForm.controls['Nome'].setValue('');
    this.cursoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.cursoForm.controls['Datainicial'].setValue('');
    this.cursoForm.controls['Datafinal'].setValue('');
    this.cursoForm.controls['Descricao'].setValue('');
    this.cursoForm.controls['Palestrante'].setValue('');
    this.cursoForm.controls['Localizacao'].setValue('');
    this.cursoForm.controls['Ativo'].setValue(false);
  }

  async closeModal() {

    this.modalController.dismiss();
  }
  
  async getDados(response:any) {
    this.Botao= "ALTERAR";
    console.log('Datalancamento ' + response[0].Datalancamento)
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Datainicial = this.utilService.checaData(response[0].Datainicial);
    let Datafinal = this.utilService.checaData(response[0].Datafinal);
    let Ativo:any = response[0].Ativo;
    if (Ativo == 1) {
      Ativo = true
    }else{
      Ativo= false
    }
    this.cursoForm.controls['Nome'].setValue(response[0].Nome);
    this.cursoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.cursoForm.controls['Datainicial'].setValue(Datainicial);
    this.cursoForm.controls['Datafinal'].setValue(Datafinal);
    this.cursoForm.controls['Descricao'].setValue(response[0].Descricao);
    this.cursoForm.controls['Palestrante'].setValue(response[0].Palestrante);
    this.cursoForm.controls['Localizacao'].setValue(response[0].Localizacao);
    this.cursoForm.controls['Ativo'].setValue(Ativo);
  
  }

  
  
async testarDados() {
  if (this.cursoForm.value.Datalancamento == '') {
    this.utilService.showAlertCallback("Informe o DATA DE LANÇAMENTO do curso", ()=>{
      this.utilService.focusInput(this.ancoraNome);
     
    })
    return;
  }

  if (this.cursoForm.value.Datainicial == '') {
    this.utilService.showAlertCallback("Informe o DATA INICIAL do curso", ()=>{
      this.utilService.focusInput(this.ancoraNome);
     
    })
    return;
  }

  if (this.cursoForm.value.Datafinal == '') {
    this.utilService.showAlertCallback("Informe o DATA FINAL do curso", ()=>{
      this.utilService.focusInput(this.ancoraNome);
     
    })
    return;
  }

  if (this.cursoForm.value.Nome == '') {
    this.utilService.showAlertCallback("Informe o NOME do curso", ()=>{
      this.utilService.focusInput(this.ancoraNome);
     
    })
    return;
  }

  if (this.cursoForm.value.Descricao == '') {
    this.utilService.showAlertCallback("Informe a DESCRIÇÃO do curso", ()=>{
      this.utilService.focusInput(this.ancoraDescricao);
     
    })
    return;
  }

  

  if (this.cursoForm.value.Palestrante == '') {
    this.utilService.showAlertCallback("Informe o PALESTRANTE do curso", ()=>{
      this.utilService.focusInput(this.ancoraPalestrante);
     
    })
    return;
  }

  if (this.cursoForm.value.Localizacao == '') {
    this.utilService.showAlertCallback("Informe a LOCALIZAÇÃO do curso", ()=>{
      this.utilService.focusInput(this.ancoraLocalizacao);
     
    })
    return;
  }

  let Ativo = 0;
  if (this.cursoForm.value.Ativo == true) {
    Ativo = 1;
  }

 
  let form={
    Id: this.cursoForm.value.Id,
    Evento: this.IdEvento,
    Nome: this.cursoForm.value.Nome,
    Datalancamento: this.utilService.checaData(this.cursoForm.value.Datalancamento),
    Datainicial: this.utilService.checaData(this.cursoForm.value.Datainicial),
    Datafinal:this.utilService.checaData(this.cursoForm.value.Datafinal),
    Descricao: this.cursoForm.value.Descricao,
    Palestrante: this.cursoForm.value.Palestrante,
    Localizacao: this.cursoForm.value.Localizacao,
    Ativo:  Ativo,
    Usuario: this.Usuario,

  }

  this.promptAlertCadadastro(form)

  }

  async selecionarCursoEvento(){
    this.estudoService.selecionarCursoEvento(this.IdEvento).then((response:any)=>{
      if (response.length > 0) {
        this.collectionCurso = response;
      }
    })

  }

  async promptAlertCadadastro(form:any) {
    let msg = "DESEJA INCLUIR ESSE NOVO CURSO?"
    if (this.cursoForm.value.Id > 0) {
       msg = "DESEJA ATUALIZAR ESSE CURSO?"

    }
    const alert = await this.alertController.create({
      header: 'EVENTO',
      subHeader: "",
      message: '<strong> ' + msg + '</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
     
      buttons: [
        {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'CONFIRMO',
          handler: () => {
            this.cadastrar(form)
          }
        }
      ]
    });
    await alert.present();
   }

   async cadastrar(form:any) { 
    if (this.cursoForm.value.Id == -1) {
        this.estudoService.adicionarCurso(form).then((response:any)=>{
          this.utilService.showAlertCallback("Curso ADICIONAR com sucesso")
          this.selecionarCursoEvento();
          setTimeout(()=>{
            this.cursoForm.controls['Id'].setValue(response[0].Id);

          },500)
        }).catch(()=>{
          this.utilService.showAlertCallback("Não foi possível ADICIONAR o curso")
        })

    }else{
      this.estudoService.atualizarCurso(form).then((response:any)=>{
        this.utilService.showAlertCallback("Evento ATUALIZADO com sucesso", ()=>{
          this.selecionarCursoEvento();
        })
        
      }).catch(()=>{
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o evento")
      })
    }

   }

   



 


}
