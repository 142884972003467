import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { PacienteService } from '../../services/paciente.service';

@Component({
  selector: 'app-fluxogramasistema',
  templateUrl: './fluxogramasistema.component.html',
  styleUrls: ['./fluxogramasistema.component.scss'],
})
export class FluxogramasistemaComponent implements OnInit {
  @ViewChild('ancoraNome', null) ancoraNome: ElementRef;
  @ViewChild('ancoraCampo', null) ancoraCampo: ElementRef;
  @ViewChild('ancoraOrdem', null) ancoraOrdem: ElementRef;
  @ViewChild('ancoraPadrao', null) ancoraPadrao: ElementRef;

  fluxogramasistemaForm:any;
  dadosListMed:any=[];
  Logado:number=0;
  Usuario:number=0;
  Botao:string="Cadastrar";
  Sistema:number=0;
  chdAtivo:boolean=false;
  corAtivo:string= "primary";
  sistemaCollection:any=[];
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private alertController: AlertController,
    private checklistService:ChecklistService,
    private pacienteService:PacienteService
) {
    this.fluxogramasistemaForm = formBuilder.group({
      Sistema: ['', ''],
      Nome: ['', ''],
      Padrao: ['', ''],
      Campo: ['', ''],
      Ordem: ['', ''],
      Ativo: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() { 
    this.Sistema = this.navParams.get('Sistema');
  }

  ionViewDidEnter() {
    this.selecionarSistema()
    this.testaAcessoSistema();
    this.limpaDados();
  }

  async selecionarSistema() {
    this.pacienteService.selecionarSistema().then((response) => {
      this.sistemaCollection = response;
      setTimeout(() => {
        this.fluxogramasistemaForm.controls['Sistema'].setValue(this.Sistema);
  
      }, 500);
    }).catch((response) => {
      //console.log("erro")
    });
  }
  

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
         
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

   async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Internacao.Usuario;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
   
  }

 

  

  async testaDados() {

    if (this.fluxogramasistemaForm.value.Nome == '') {
      this.utilService.showAlertCallback("Nome não pode ser em branco", ()=>{
        this.utilService.focusInput(this.ancoraNome);
      })
    }

    if (this.fluxogramasistemaForm.value.Padrao == '') {
      this.utilService.showAlertCallback("Texto não pode ser em branco", ()=>{
        this.utilService.focusInput(this.ancoraPadrao);
      })
    }

    if (this.fluxogramasistemaForm.value.Campo == '') {
      this.utilService.showAlertCallback("Campo não pode ser em branco", ()=>{
        this.utilService.focusInput(this.ancoraCampo);
      })
    }

    let Ativo:number= 0;

    if (this.fluxogramasistemaForm.value.Ativo == true){
      Ativo = 1
    } 


    let form = {
      Id: this.fluxogramasistemaForm.value.Sistema,
      Nome:this.fluxogramasistemaForm.value.Nome,
      Padrao: this.fluxogramasistemaForm.value.Padrao,
      Campo: this.fluxogramasistemaForm.value.Campo,
      Ordem: this.fluxogramasistemaForm.value.Ordem,
      Ativo: Ativo
    }

    console.log(JSON.stringify(form))

    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

  
 

  async presentAlertConfirm(form: any) {
    let tipo = this.fluxogramasistemaForm.value.Sistema;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitante',
      message: msg,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraNome);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrar(form);
          }
        }
      ]
    });

    await alert.present();
  }


   async cadastrar(form: any) {
    if (this.fluxogramasistemaForm.value.Sistema ==  -1) {
      this.checklistService.adicionarSistema(form).then((response) => {
        if (response[0].Id > 0) {
          this.utilService.showAlertCallback("Sistema ADICIONADO com sucesso", null);
          this.selecionarSistema();
          setTimeout(() => {
            this.Sistema = response[0].Id;
            this.fluxogramasistemaForm.controls['Sistema'].setValue(this.Sistema);
          }, 500);
        }
     
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o Órgão", null);
      });
    } else {
      this.checklistService.atualizarSistema(form).then((response:any) => {
        this.utilService.showAlertCallback("Sistema ATUALIZADO com sucesso", null);
        this.selecionarSistema();
        setTimeout(() => {
          this.fluxogramasistemaForm.controls['Sistema'].setValue(response[0].Id);
        }, 500);
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o Órgão", null);
      });
    }

  }

  a
  async selecionarSistemaUnico(Id:number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.checklistService.selecionarSistemaUnico(Id).then((response:any) => {
      if (response[0].Id > 0) {
        this.getSistema(response);
      }
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possivel selecionar o ÓRGÃO")

    });
  }


  async limpaDados() {
    this.Botao = "Cadastrar";
    this.fluxogramasistemaForm.controls['Nome'].setValue('');
    this.fluxogramasistemaForm.controls['Padrao'].setValue('');
    this.fluxogramasistemaForm.controls['Ordem'].setValue('');
    this.fluxogramasistemaForm.controls['Ativo'].setValue(false);
    this.fluxogramasistemaForm.controls['Sistema'].setValue(-1);
    this.fluxogramasistemaForm.controls['Campo'].setValue('');
    this.utilService.focusInput(this.ancoraNome);
    this.chdAtivo = true;
    this.corAtivo = 'primary' ;
   

  }

 

  async getSistema(response: any) {

    if (response.length == 0) {
      return;
    }
      this.Botao = "Alterar";
      if (response[0].Ativo == 1){
        this.chdAtivo = true
        this.corAtivo= 'danger'
        
      }
        this.fluxogramasistemaForm.controls['Nome'].setValue(response[0].Nome);
        this.fluxogramasistemaForm.controls['Campo'].setValue(response[0].Campo);
        this.fluxogramasistemaForm.controls['Ordem'].setValue(response[0].Ordem);
        this.fluxogramasistemaForm.controls['Padrao'].setValue(response[0].Padrao);
        this.fluxogramasistemaForm.controls['Ativo'].setValue(this.chdAtivo);
        this.fluxogramasistemaForm.controls['Sistema'].setValue(response[0].Id);
        this.Sistema = response[0].Id;
  }

  async selecionaAtivo() {
    if (this.fluxogramasistemaForm.value.Ativo == true) {
      this.chdAtivo = true;
      this.corAtivo = 'danger';
    }else{
      this.chdAtivo = false;
      this.corAtivo = 'primary'; 
    }
  }

}
