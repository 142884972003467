import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { ChecklistService } from '../../services/checklist.service';


@Component({
  selector: 'app-comorbidade',
  templateUrl: './comorbidade.component.html',
  styleUrls: ['./comorbidade.component.scss'],
})
export class ComorbidadeComponent implements OnInit {
   Logado: number = 0;
   Paciente: number = 0;
   Usuario: number = 0;
   Internacao: number = 0;
   comorbidadeColection: any = [];
   neurologicoColection: any = [];
   cardiacoColection: any = [];
   respiratorioColection: any = [];
   digestivoColection: any = [];
   renalColection: any = [];
   infeccaoColection: any = [];
   endocrinoColection: any = [];
   genitoColection: any = [];
   tegumentarColection: any = [];
   extremidadeColection: any = [];
   psicosocialColection: any = [];
   comorbidadeForm: FormGroup;
   dadosListMed: any;
   aComorbidades: any;
   Nome:string;
  @ViewChild('ancoraComorbidade', null) ancoraComorbidade: ElementRef;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private checklistService:ChecklistService
  ) {

    this.comorbidadeForm = formBuilder.group({
      Neurologico: ['', ''],
      Cardiaco: ['', ''],
      Respiratorio: ['', ''],
      Digestivo: ['', ''],
      Renal: ['', ''],
      Infeccao: ['', ''],
      Endocrino: ['', ''],
      Genito: ['', ''],
      Tegumentar: ['', ''],
      Extremidade: ['', ''],
      Psicosocial: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.Paciente = this.navParams.get('paciente');
    this.Internacao = this.navParams.get('internacao')
    this.Nome = this.navParams.get('nome');
    this.utilService.Descriptografar(this.Nome).then((n:string)=>{
      this.Nome = n.toUpperCase();
    })

  }

  ngOnInit() {

   
    this.selecionarComorbidade();
    this.testaAcessoSistema();
    this.selecionarIntenacaoUnica();
  }

  ionViewDidEnter() {
  
    this.limpaDados()
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async limpaDados() {
   
    this.comorbidadeForm.controls['Neurologico'].setValue(0);
    this.comorbidadeForm.controls['Cardiaco'].setValue(0);
    this.comorbidadeForm.controls['Respiratorio'].setValue(0);
    this.comorbidadeForm.controls['Digestivo'].setValue(0);
    this.comorbidadeForm.controls['Renal'].setValue(0);
    this.comorbidadeForm.controls['Infeccao'].setValue(0);
    this.comorbidadeForm.controls['Endocrino'].setValue(0);
    this.comorbidadeForm.controls['Genito'].setValue(0);
    this.comorbidadeForm.controls['Tegumentar'].setValue(0);
    this.comorbidadeForm.controls['Extremidade'].setValue(0);
    this.comorbidadeForm.controls['Psicosocial'].setValue(0);



  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
     
    }
    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;
    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pacienterelacao/" + this.Usuario);
      return;

    }
  }

  async selecionarIntenacaoUnica() {

    let loading = await this.loadingController.create({
      message: 'Selecionando dados da Admissão...',
      mode: 'ios'
    });
    loading.present();
    this.pacienteService.selecionarInternacaoUnico(this.Internacao).then((response) => {
      setTimeout(() => {
        this.getInternacaoUnico(response);
      }, 500);
    }).catch((response) => {
      //console.log("erro")
    }).finally(() => {
      loading.dismiss();
    });

  }

  async getInternacaoUnico(response: any) {
    if (response[0].Comorbidade == '-1' || response[0].Comorbidade == '' || response[0].Comorbidade == null || response[0].Comorbidade == undefined) {
      return;
    }


    let Comorbidades:any = response[0].Comorbidade;
    Comorbidades = Comorbidades.split(";");

    let Neurologico: any = Comorbidades[0];
    Neurologico = eval(Neurologico);
    this.comorbidadeForm.controls['Neurologico'].setValue(Neurologico);

    let Cardiaco: any = Comorbidades[1];
    Cardiaco = eval(Cardiaco);
    this.comorbidadeForm.controls['Cardiaco'].setValue(Cardiaco);

    let Respiratorio: any = Comorbidades[2];
    Respiratorio = eval(Respiratorio);
    this.comorbidadeForm.controls['Respiratorio'].setValue(Respiratorio);

    let Digestivo: any = Comorbidades[3];
    Digestivo = eval(Digestivo);
    this.comorbidadeForm.controls['Digestivo'].setValue(Digestivo);

    let Renal: any = Comorbidades[4];
    Renal = eval(Renal);
    this.comorbidadeForm.controls['Renal'].setValue(Renal);
    let Infeccao: any = Comorbidades[5];
    Infeccao = eval(Infeccao);
    this.comorbidadeForm.controls['Infeccao'].setValue(Infeccao);

    let Endocrino: any = Comorbidades[6];
    Endocrino = eval(Endocrino);
    this.comorbidadeForm.controls['Endocrino'].setValue(Endocrino);

    let Genito: any = Comorbidades[7];
    Genito = eval(Genito);
    this.comorbidadeForm.controls['Genito'].setValue(Genito);

    let Tegumentar: any = Comorbidades[8];
    Tegumentar = eval(Tegumentar);
    this.comorbidadeForm.controls['Tegumentar'].setValue(Tegumentar);

    let Extremidade: any = Comorbidades[9];
    Extremidade = eval(Extremidade);
    this.comorbidadeForm.controls['Extremidade'].setValue(Extremidade);

    let Psicosocial: any = Comorbidades[10];
    Psicosocial = eval(Psicosocial);
    this.comorbidadeForm.controls['Psicosocial'].setValue(Psicosocial);










  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async selecionarComorbidade() {
    this.pacienteService.selecionarComorbidade()
      .then((response) => {
        this.comorbidadeColection = response;
        for (let i = 0; i < this.comorbidadeColection.length; i++) {
          if (this.comorbidadeColection[i].Sistema == 1) {
            this.neurologicoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 2) {
            this.cardiacoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 3) {
            this.respiratorioColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 4) {
            this.digestivoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 5) {
            this.renalColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 6) {
            this.infeccaoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 7) {
            this.endocrinoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 8) {
            this.genitoColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 9) {
            this.tegumentarColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 10) {
            this.extremidadeColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }
          if (this.comorbidadeColection[i].Sistema == 11) {
            this.psicosocialColection.push({
              Id: this.comorbidadeColection[i].Id,
              Nome: this.comorbidadeColection[i].Nome
            })
          }

        }
        this.utilService.showToastCallBack('Comorbidades selecionados com sucesso', 2000, null)

      })
      .catch((response) => {
        
        this.utilService.showToastCallBack('Comorbidades não poderam ser selecionadas', 2000, null)
      });

  }

  async cadastarComorbidades() {
    let neurologico = "[" + this.comorbidadeForm.value.Neurologico + "]";
    let cardiaco = "[" + this.comorbidadeForm.value.Cardiaco + "]";
    let respiratorio = "[" + this.comorbidadeForm.value.Respiratorio + "]";
    let digestivo = "[" + this.comorbidadeForm.value.Digestivo + "]";
    let renal = "[" + this.comorbidadeForm.value.Renal + "]";
    let infeccao = "[" + this.comorbidadeForm.value.Infeccao + "]";
    let endocrino = "[" + this.comorbidadeForm.value.Endocrino + "]";
    let genito = "[" + this.comorbidadeForm.value.Genito + "]";
    let tegumentar = "[" + this.comorbidadeForm.value.Tegumentar + "]";
    let extremidade = "[" + this.comorbidadeForm.value.Extremidade + "]";
    let psicosocial = "[" + this.comorbidadeForm.value.Psicosocial + "]";

    let comorbidades: string = neurologico + ';' + cardiaco + ';' + respiratorio + ';' +
      digestivo + ';' + renal + ';' + infeccao + ';' +
      endocrino + ';' + genito + ';' + tegumentar + ';' +
      extremidade + ';' + psicosocial
    this.aComorbidades=[];
    this.aComorbidades=
      {
        Id: this.Internacao,
        Comorbidade: comorbidades
      }
    
    this.presentAlertConfirmComborbidades();


  }

  async presentAlertConfirmComborbidades() {
    let message: string = 'Deseja ALTERAR esses dados?'
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.atualizarInternacaoComorbidade();
          }
        }
      ]
    });
    alert.present();
  }
  async atualizarInternacaoComorbidade() {
    this.pacienteService.atualizarInternacaoComorbidade(this.aComorbidades)
      .then((response) => {
        this.utilService.showAlertCallback('Comorbidades atualizadas com sucesso', null)
      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível atualizar as Comorbidades', null)
      });

  }

  async apagarPadrao(clicados:any, Sel:string) {
    let Novolista:any=[];
      for (let i=0; i<clicados.length; i++){
        if (clicados[i] != 0) {
          Novolista.push(
            clicados[i]
            )
        }
      }

      if (Novolista.length > 0) {
        this.comorbidadeForm.controls[Sel].setValue(Novolista);
      }

  }
}
