import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { AntibioticoService } from '../../services/antibiotico.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { TabelaService } from '../../services/tabela.service';


@Component({
  selector: 'app-antibiotico',
  templateUrl: './antibiotico.component.html',
  styleUrls: ['./antibiotico.component.scss'],
})
export class AntibioticoComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatacultura', null) ancoraDatacultura: ElementRef;
  @ViewChild('ancoraInfeccaocultura', null) ancoraInfeccaocultura: ElementRef;
  @ViewChild('ancoraMicrobiologia', null) ancoraMicrobiologia: ElementRef;
  @ViewChild('ancoraAntibiotico',  null) ancoraAntibiotico: ElementRef;
  @ViewChild('ancoraGrupo', null) ancoraGrupo: ElementRef;
  @ViewChild('ancoraQuantidade', null) ancoraQuantidade: ElementRef;
  @ViewChild('ancoraVia', null) ancoraVia: ElementRef;
  @ViewChild('ancoraPosologia', null) ancoraPosologia: ElementRef;
  @ViewChild('ancoraIntervalo', null) ancoraIntervalo: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraDatasuspensao', null) ancoraDatasuspensao: ElementRef;
  @ViewChild('ancoraDiasproposto', null) ancoraDiasproposto: ElementRef;
  @ViewChild('ancoraPosicao', null) ancoraPosicao: ElementRef;
  @ViewChild('ancoraObservacao', null) ancoraObservacao: ElementRef;
   Quantidade:number=0;
   Posologia:number=0;
   Intervalo:number=0;
   Id:number=0
   Logado:number = 0;
   Header:string="";
   Usuario:number=0;
   Paciente:number=0;
   Idade:string="";
   Nome:string="";
   Internacao:number=0;
   Setor:string="";
   Leito:any="";
   antibioticoForm: FormGroup;
   Botao: string = "Cadastrar";
   itensColection:any=[]
   Datainternacao:string="";
   Dias:string="";
   Diasproposto:number=0;
   Antibiotico:number=0;
   grupoColection:any=[];
   antibioticoColection:any=[];
   infeccaoculturaColection:any=[];
   infeccaomicrobiologiaColection:any=[];
   viaColection:any=[]
   tipoapresentacaoColection:any=[]
   posicaoColection:any=[]
   lRet:any;
   dadosListMed:any;
  Infeccaomicrobiologia:number=0;
  Infeccaocultura:number=0;
  aUnidadeMedida:any=[];
  Unidadeunidade:number=0;
  intervaloColection:any=[];
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private antibioticoService:AntibioticoService,
    private alertController:AlertController,
    private checklistService:ChecklistService,
    private tabelaService:TabelaService) { 
      this.antibioticoForm = formBuilder.group({
        Id: ['', ''],
        Datacultura:  ['', ''],
        Infeccaocultura:  ['', ''],
        Infeccaomicrobiologia:  ['', ''],
        Grupo:  ['', ''],
        Antibiotico:  ['', ''],
      
        Datalancamento:  ['', ''],
        Datasuspensao:  ['', ''],
        Diasproposto: ['', ''],
        Via:['', ''],
       
        Quantidade:['', ''],
        Tipoapresentacao:['', ''],
        Posologia:  ['', ''],
        Unidademedida:  ['', ''],
        Intervalo:['', ''],
        Observacao:['', ''],
        Posicao:['', ''],
      })
      this.dadosListMed = this.utilService.obterDadosDoSistema();
        
      
    }
  ngOnInit() {

    this.tabelaService.selecionarTabela("UNIDADEMEDIDA", "Id-Sigla-Antibiotico").then((response:any)=>{
      this.aUnidadeMedida = response.filter((a:any)=>{
        return a.Antibiotico == 1
      })
    })

    this.tabelaService.selecionarTabela("VIA", "Id-Sigla").then((response:any)=>{
      this.viaColection=response
      
    })

    this.tabelaService.selecionarTabela("APRESENTACAO", "Id-Sigla").then((response:any)=>{
      this.tipoapresentacaoColection=response
      
    })

    this.tabelaService.selecionarTabela("INTERVALO", "Id-Sigla").then((response:any)=>{
      this.intervaloColection=response
      
    })
   
   
  
    
    
    this.posicaoColection=[
      {Nome: 'Em curso', Id: 1},
      {Nome: 'Suspenso por falha  terapeutica', Id: 2},
      {Nome: 'Suspenso por intolerância', Id: 3},
      {Nome: 'Suspenso por falta', Id: 4},
      {Nome: 'Tratamento concluído', Id: 5},
      {Nome: 'Por outra causa', Id: 6},

    
    ]
  }

  ionViewDidEnter() {
      this.testaAcessoSistema();
      this.selecionarAntibioticoGrupo();
      this.selecionarDataHoje();
      this.selecionarInfeccaoCultura();
      this.selecionarInfeccaoMicrobiologia();
      this.selecionarAntibioticoUso(-1);

  
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

 async testaAcessoSistema() {

  if (this.dadosListMed != null) {
    this.Logado = this.dadosListMed.Usuario[0].Logado;
    this.Usuario = this.dadosListMed.Usuario[0].Id;
    this.Paciente = this.dadosListMed.Internacao.Paciente;
    this.Idade = this.dadosListMed.Internacao.Idade;
    this.Nome =  this.dadosListMed.Internacao.Nome;
    this.Internacao = this.dadosListMed.Internacao.Id;;
    this.Setor =  (this.dadosListMed.Internacao[0].Setor);
    this.Leito = (this.dadosListMed.Internacao[0].Leito);
    this.Datainternacao=  this.dadosListMed.Internacao[0].Datainternacao;
    this.Dias= this.dadosListMed.Internacao[0].Dias;
  }
  
    if (this.Logado == 0 ) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login",  null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;
      
    }
    if (this.Paciente == 0 ) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar",  null)
      this.closeModal();
      this.utilService.Movimentar("pagina");
      return;
      
    }
  }

  async selecionarDataHoje(){
    let dtHoje= this.utilService.dataHoje();
    this.antibioticoForm.controls['Datalancamento'].setValue(dtHoje);

  }

  async selecionarInfeccaoMicrobiologia(){
    this.antibioticoService.selecionarInfeccaoMicrobiologia().then((response) => {
      this.infeccaomicrobiologiaColection= response;
    }).catch((response) => {

    });
  }
  async selecionarInfeccaoCultura(){
    this.antibioticoService.selecionarInfeccaoCultura().then((response) => {
      this.infeccaoculturaColection= response;

      setTimeout(() => {
        this.limpaDadosAntibioticoUso();

      }, 2000); 
    }).catch((response) => {

    });
  }
  
  async selecionarAntibioticoUso(Id:number){
    this.antibioticoService.selecionarAntibioticoUso(this.Internacao, this.Paciente).then((response) => {
      this.itensColection= response;
     
      setTimeout(() => {
        this.Id = Id;
        this.antibioticoForm.controls['Id'].setValue(this.Id);
      }, 1000); 

    }).catch((response) => {
    }).finally(()=>{
      

    });
  }

  

  async selecionarAntibioticoUnico(Id:number) {
    if (Id == -1) {
      this.limpaDadosAntibioticoUso();
      return ;
    }
    this.antibioticoService.selecionarAntibioticoUnico(Id).then((response) => {
      this.getAntibiotico(response);
    }).catch((response) => {

    });
  }

  async limpaDadosAntibioticoUso() {
    this.Botao= "Cadastrar";
    this.Id=-1;
    let dtHoje = this.utilService.dataHoje();
    let Datasuspensao= this.utilService.somaDiaData(dtHoje, 7);
   
    this.Infeccaocultura = 16
    this.Infeccaomicrobiologia = 9
    this.Antibiotico = 0;
    this.Diasproposto = 7;
    this.antibioticoForm.controls['Id'].setValue(-1);
    this.antibioticoForm.controls['Grupo'].setValue(0);
    this.antibioticoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.antibioticoForm.controls['Datacultura'].setValue(dtHoje);
    this.antibioticoForm.controls['Datalancamento'].setValue(dtHoje);
    this.antibioticoForm.controls['Infeccaocultura'].setValue(16);
    this.antibioticoForm.controls['Infeccaomicrobiologia'].setValue(9);

    this.antibioticoForm.controls['Diasproposto'].setValue(7);
    this.antibioticoForm.controls['Via'].setValue(7);
    this.antibioticoForm.controls['Antibiotico'].setValue( 0);
    this.antibioticoForm.controls['Quantidade'].setValue(1);
    this.antibioticoForm.controls['Tipoapresentacao'].setValue(1);
    this.antibioticoForm.controls['Posologia'].setValue(1);

    this.antibioticoForm.controls['Unidademedida'].setValue(68);

    this.antibioticoForm.controls['Intervalo'].setValue(0);
    this.antibioticoForm.controls['Observacao'].setValue('');
    this.antibioticoForm.controls['Posicao'].setValue(1);
  }

  async getAntibiotico(response:any) {
    this.Botao= "Alterar";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    let Datacultura =  this.utilService.checaData(response[0].Datacultura);
    let Datasuspensao = this.utilService.checaData(response[0].Datasuspensao);
    let Quantidade:number= this.utilService.formateNumberNew(response[0].Quantidade,1,1);
    let Posologia:number= this.utilService.formateNumberNew(response[0].Posologia,1,1);
    let Intervalo:number= this.utilService.formateNumberNew(response[0].Intervalo,1,1);
    let Diasproposto:number= this.utilService.formateNumberNew(response[0].Diasproposto,1,1);

    if (response[0].Datacultura.indexOf("1900") > 0){
      Datacultura = '';
    }
    
    this.antibioticoForm.controls['Grupo'].setValue(response[0].Grupo);
    this.antibioticoForm.controls['Datasuspensao'].setValue(Datasuspensao);
    this.antibioticoForm.controls['Datacultura'].setValue(Datacultura);
    this.antibioticoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.antibioticoForm.controls['Posologia'].setValue(Posologia);
    this.antibioticoForm.controls['Unidademedida'].setValue(response[0].Unidademedida);

    this.antibioticoForm.controls['Infeccaocultura'].setValue(response[0].Infeccaocultura);
    this.antibioticoForm.controls['Infeccaomicrobiologia'].setValue(response[0].Infeccaomicrobiologia);
    this.antibioticoForm.controls['Diasproposto'].setValue(Diasproposto);
    this.antibioticoForm.controls['Via'].setValue(response[0].Via);
    this.antibioticoForm.controls['Quantidade'].setValue(Quantidade);
    this.antibioticoForm.controls['Tipoapresentacao'].setValue(response[0].Tipoapresentacao);
    this.antibioticoForm.controls['Intervalo'].setValue(Intervalo);
    this.antibioticoForm.controls['Observacao'].setValue(response[0].Observacao);
    this.antibioticoForm.controls['Posicao'].setValue(response[0].Posicao);

    setTimeout(() => {
      this.Antibiotico = response[0].Antibiotico;
      this.antibioticoForm.controls['Antibiotico'].setValue( this.Antibiotico);
      
    }, 1000); 
    
  }

  async calculaDiasProposto() {
    let Diasproposto:number= this.antibioticoForm.value.Diasproposto
    if (Diasproposto <=0) {
      this.utilService.showAlertCallback("Informe o número de dias");
      this.utilService.focusInput(this.ancoraDiasproposto);

      return;
    }
    
    let dtHoje = this.utilService.dataHoje();
    let Datasuspensao= this.utilService.somaDiaData(dtHoje, Diasproposto);
    this.antibioticoForm.controls['Datasuspensao'].setValue(Datasuspensao);
  }

  async selecionarAntibioticoGrupo(){
    this.antibioticoService.selecionarAntibioticoGrupo().then((response) => {
      this.grupoColection= response;
    }).catch((response) => {

    });
  }

  async selecionarAntibioticoGrupoIndicado(grupo:number){
    this.antibioticoService.selecionarAntibioticoGrupoIndicado(grupo).then((response) => {
      this.antibioticoColection= response;

    }).catch((response) => {

    });
  }
  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async presentAlertConfirm() {
    let tipo = parseInt(this.antibioticoForm.value.Id);
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " 
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" 
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'ANTIBIÓTICO',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraPosologia'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrarAntibiotico();
          }
        }
      ]
    });

    await alert.present();
  }


  cadastrarAntibiotico() {

    let Datalancamento:any = this.antibioticoForm.value.Datalancamento
     if ( Datalancamento != '' ){
      Datalancamento = this.utilService.checaData(Datalancamento)
     }else{
       this.utilService.showAlertCallback("Escola a data de lancamento", ()=>{
         this.utilService.focusInput(this.ancoraQuantidade)
       })
       return;
   
     }

     Datalancamento = this.utilService.checaData(Datalancamento);


    this.lRet = this.utilService.validarCampo('Grupoantibiotico',  this.antibioticoForm.value.Grupo, () => {
      (this.ancoraQuantidade as any).setFocus(true);
    
     });
     if (this.lRet == false) {
       return;
     }

    this.lRet = this.utilService.validarCampo('Antibiotico',  this.antibioticoForm.value.Antibiotico, () => {
      (this.ancoraQuantidade as any).setFocus(true);
    
     });
     if (this.lRet == false) {
       return;
     }

     let Quantidade:number = this.antibioticoForm.value.Quantidade;
     Quantidade= this.utilService.formateNumberNew(Quantidade,2);
     if (Quantidade <=0 ){
       this.utilService.showAlertCallback('Quantidade inválida', ()=>{
         this.utilService.focusInput(this.ancoraQuantidade);
       })
       return ;
     }

    this.lRet = this.utilService.validarCampo('Tipoapresentacao',  this.antibioticoForm.value.Tipoapresentacao, () => {
      (this.ancoraPosologia as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     this.lRet = this.utilService.validarCampo('Posologia',  this.antibioticoForm.value.Posologia, () => {
      (this.ancoraPosologia as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     this.lRet = this.utilService.validarCampo('Unidademedida',  this.antibioticoForm.value.Unidademedida, () => {
      (this.ancoraPosologia as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     this.lRet = this.utilService.validarCampo('Intervalo',  this.antibioticoForm.value.Intervalo, () => {
      (this.ancoraIntervalo as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

    this.lRet = this.utilService.validarCampo('Via',  this.antibioticoForm.value.Via, () => {
      (this.ancoraPosologia as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }


      let Datacultura:any = this.antibioticoForm.value.Datacultura
     if ( Datacultura != '' ){
      Datacultura = this.utilService.checaData(Datacultura)
     }else{
       this.utilService.showAlertCallback("Escola a data da cultura", ()=>{
         this.utilService.focusInput(this.ancoraQuantidade)
       })
       return;
   
     }

     Datacultura = this.utilService.checaData(Datacultura);


     this.lRet = this.utilService.validarCampo('Infeccaocultura',  this.antibioticoForm.value.Infeccaocultura, () => {
      (this.ancoraObservacao as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     this.lRet = this.utilService.validarCampo('Infeccaomicrobiologia',  this.antibioticoForm.value.Infeccaomicrobiologia, () => {
      (this.ancoraObservacao as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }

     let Datasuspensao:any = this.antibioticoForm.value.Datasuspensao;
     if ( Datasuspensao != '' ){
      Datasuspensao = this.utilService.checaData(Datasuspensao)
     }else{
       this.utilService.showAlertCallback("Escola a data de suspensão", ()=>{
         this.utilService.focusInput(this.ancoraQuantidade)
       })
       return;
   
     }

     

     let Posologia:number = this.antibioticoForm.value.Posologia;
     Posologia= this.utilService.formateNumberNew(Posologia,2);
     if (Posologia <=0 ){
       this.utilService.showAlertCallback('Posologia inválida', ()=>{
         this.utilService.focusInput(this.ancoraPosologia);
       })
       return ;
     }

     let Intervalo:number = this.antibioticoForm.value.Intervalo;
     Intervalo= this.utilService.formateNumberNew(Intervalo,2);
     if (Intervalo <=0 ){
       this.utilService.showAlertCallback('Intervalo inválido', ()=>{
         this.utilService.focusInput(this.ancoraIntervalo);
       })
       return ;
     }

     this.lRet = this.utilService.validarCampo('Posicao',  this.antibioticoForm.value.Posicao, () => {
      (this.ancoraObservacao as any).setFocus(true);
     });
     if (this.lRet == false) {
       return;
     }
     
    let form = {
      Id: this.antibioticoForm.value.Id,
      Internacao: this.Internacao ,
      Paciente: this.Paciente ,
      Datacultura: Datacultura ,
      Infeccaocultura: this.antibioticoForm.value.Infeccaocultura ,
      Infeccaomicrobiologia: this.antibioticoForm.value.Infeccaomicrobiologia,
      Antibiotico: this.antibioticoForm.value.Antibiotico ,
      Grupo: this.antibioticoForm.value.Grupo,
      Quantidade:Quantidade ,
      Tipoapresentacao: this.antibioticoForm.value.Tipoapresentacao ,
      Posologia: Posologia ,
      Unidademedida: this.antibioticoForm.value.Unidademedida,
      Intervalo: Intervalo ,
      Via: this.antibioticoForm.value.Via ,
      Observacao:this.antibioticoForm.value.Observacao,
      Datalancamento: Datalancamento ,
      Datasuspensao: Datasuspensao ,
      Posicao:this.antibioticoForm.value.Posicao ,
      Usuario: this.Usuario
      }
      
      if ( this.antibioticoForm.value.Id == -1 || this.antibioticoForm.value.Id == null || this.antibioticoForm.value.Id == undefined) {
        this.antibioticoService.adicionarAntibioticoUso(form).then((response) => {
          this.selecionarAntibioticoUso(response[0].Id);
          this.utilService.showAlertCallback("Antibiotico ADICIONADO com sucesso",  null);
        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível ADICIONAR o antibiotico",  null);
  
        });
      }else{
        this.antibioticoService.atualizarAntibioticoUso(form).then((response) => {
          this.utilService.showAlertCallback("Registro ALTERAR com sucesso",  null);
          
        }).catch((response) => {
          this.utilService.showAlertCallback("Não foi possível ALTERAR o antibiotico",  null);
  
        });
      }
    
  }

 
  
  
  

}