import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private loadingController: LoadingController
    ) { }

    selecionarEscoreRelacao(Escore:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarEscoreRelacao/' + Escore;
      return this.http.get(url).toPromise();
    }

    selecionarEscoreUnico(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarEscoreUnico/' + Id;
      return this.http.get(url).toPromise();
    }

    selecionarEscoreSistema(Sistema:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarEscoreSistema/' + Sistema;
      return this.http.get(url).toPromise();
    }

    

    selecionarCalculoSistema(Sistema:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCalculoSistema/' + Sistema;
      return this.http.get(url).toPromise();
    }

    selecionarCalculoUnico(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCalculoUnico/' + Id;
      return this.http.get(url).toPromise();
    }

    selecionarSistemaUnico(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarSistemaUnico/' + Id;
      return this.http.get(url).toPromise();
    }

    selecionarEscoreRelacaoUnico(Id:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarEscoreRelacaoUnico/' + Id;
      return this.http.get(url).toPromise();
    }

    selecionarCalculoQuantidade() {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCalculoQuantidade' ;
      return this.http.get(url).toPromise();
    }

    selecionarPatologiaSistema(Sistema:number) {
      let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarPatologiaSistema/' + Sistema;
      return this.http.get(url).toPromise();
    }

  // Calculos lancamento
 
  selecionarCalculolancamento(Internacao: number, Paciente: number, Calculo:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCalculolancamento/' + Internacao + '/' + Paciente + '/' + Calculo;
    return this.http.get(url).toPromise();
  }

  selecionarCalculolancamentoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCalculolancamentoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarManutencao(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarManutencao';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarCalculolancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarCalculolancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarSistema(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarSistema';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  adicionarEscore(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarEscore';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  // adicionarEscorerelacao(form: any) {
  //   let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarEscorerelacao';
  //   let headers: any = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.post(url, form, { headers: headers }).toPromise();
  // }

  atualizarSistema(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarSistema';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  atualizarEscore(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarEscore';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // atualizarEscorerelacao(form: any) {
  //   let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarEscorerelacao';
  //   let headers: any = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.put(url, form, { headers: headers }).toPromise();
  // }

  atualizarCalculolancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarCalculolancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // drogas lancamento

  selecionarDroga() {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarDroga'
    return this.http.get(url).toPromise();
  }

  selecionarSistema() {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarSisteme'
    return this.http.get(url).toPromise();
  }

  selecionarEscore(sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarEscore/' + sistema
    return this.http.get(url).toPromise();
  }



 
  selecionarDrogaSistema(Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarDrogaSistema/' + Sistema
    return this.http.get(url).toPromise();
  }

  selecionarDrogalancamentoInternacao(Internacao:number, Paciente:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarDrogalancamentoInternacao/' + Internacao + '/' + Paciente
    return this.http.get(url).toPromise();
  }
 
   selecionarDrogalancamento(Internacao: number, Paciente: number, Droga:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarDrogalancamento/' + Internacao + '/' + Paciente + '/' + Droga;
    return this.http.get(url).toPromise();
  }

  selecionarDrogalancamentoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarDrogalancamentoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarDrogalancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarDrogalancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarDrogalancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarDrogalancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  // checklistlancamento

   selecionarChecklistlancamento(Internacao: number, Paciente: number, Checklist:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarChecklisrlancamento/' + Internacao + '/' + Paciente + '/' + Checklist;
    return this.http.get(url).toPromise();
  }

  selecionarChecklistlancamentoUnico(Id: number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarChecklistlancamentoUnico/' + Id;
    return this.http.get(url).toPromise();
  }

  adicionarChecklistlancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/adicionarChecklistlancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, form, { headers: headers }).toPromise();
  }

  atualizarChecklistlancamento(form: any) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/atualizarChecklistlancamento';
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.put(url, form, { headers: headers }).toPromise();
  }

  selecionarChecklist(sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarCheckList/' + sistema ;
    return this.http.get(url).toPromise();
  }

  selecionarChecklistTodos() {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarChecklistTodos'  ;
    return this.http.get(url).toPromise();
  }

  selecionarChecklistlancamentoSistema(Internacao:number, Paciente:number, Sistema:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarChecklistlancamentoSistema/' + Internacao +'/'  + Paciente +'/' +Sistema ;
    return this.http.get(url).toPromise();
  }

  selecionarChecklistlancamentoGrafico(Internacao:number, Paciente:number, Checklist:number) {
    let url = this.utilService.obterUrlDaApi() + 'Checklist/selecionarChecklistlancamentoGrafico/' + Internacao +'/'  + Paciente +'/' +Checklist ;
    return this.http.get(url).toPromise();
  }
  

}

