import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { BalancoService } from '../../services/balanco.service';
import { Platform, AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';
import { UtilpdfService } from '../../services/utilpdf.service';
import { Base64 } from '@ionic-native/base64/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@ionic-native/File/ngx';


@Component({
  selector: 'app-balanco',
  templateUrl: './balanco.component.html',
  styleUrls: ['./balanco.component.scss'],
})
export class BalancoComponent implements OnInit {
  @ViewChild('ancoraId', null) ancoraId: ElementRef;
  @ViewChild('ancoraDatalancamento', null) ancoraDatalancamento: ElementRef;
  @ViewChild('ancoraValor', null) ancoraValor: ElementRef;

  Entradas: number = 0
  Saidas: number = 0;
  Balanco: any = 0;
  Logado: number = 0;
  Header: string = "";
  Usuario: number = 0;
  Paciente: number = 0;
  Idade: string = "";
  Nome: string = "";
  Internacao: number = 0;
  cTipolancamento: string = '';
  balancoForm: FormGroup;
  Botao: string = "Cadastrar";
  Datainternacao: string = "";
  itensColection: any = [];
  Balancotipo: number = 0;
  Valor: number = 0;
  aBalancotipo: any = [];
  dadosListMed: any;
  NomeLancamento: string = "";
  aBalancoRelacao: any = [];
  Grupo: number = 0;
  Ano: any;
  Mes: any;
  Dia: any
  aEntrada: any = [];
  aSaida: any = [];
  pdfObj: any;
  stringBase64Pdf: any;
  isCordova: boolean = false;

  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private balancoService: BalancoService,
    private alertController: AlertController,
    private checklistService: ChecklistService,
    private utilpdfService: UtilpdfService,
    private file: File,
    private fileOpener: FileOpener,
    private platform:Platform
  ) {
    this.balancoForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Entrada: ['', ''],
      Saida: ['', ''],
      Grupo: ['', ''],
      Valor: ['', ''],


    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

  }
  ngOnInit() { 

    this.isCordova = this.platform.is('cordova') ;

  }

  ionViewDidEnter() {
    this.selecionaBalancotipo()
    this.testaAcessoSistema();
    this.selecionarBalancoHoje(-1);
    this.limpaDados();

  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async selecionaNomeLancamento(Id: number) {
    let aVet: any = this.aBalancotipo.filter((a: any) => {
      return a.Id == Id;

    })

    this.NomeLancamento = aVet[0].Nome;
    this.utilService.focusInput(this.ancoraValor);

  }

  async selecionaBalancotipo() {
    this.balancoService.selecionarBalancotipo().then((response: any) => {
      this.aBalancotipo = response;
    })

    this.aEntrada = this.aBalancotipo.filter((a: any) => {
      return a.Grupo == 1
    });

    this.aSaida = this.aBalancotipo.filter((a: any) => {
      return a.Grupo == 2
    });

    //this.limpaDados();


  }

  async informaValor(Valor: number) {
    this.balancoForm.controls['Valor'].setValue(Valor);
  }
  async testaAcessoSistema() {

    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Nome = this.dadosListMed.Internacao.Nome;
      this.Internacao = this.dadosListMed.Internacao.Id;;

    }

    if (this.Logado == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa fazer login", null)
      this.closeModal();
      this.utilService.Movimentar("login");
      return;

    }
    if (this.Paciente == 0) {
      this.utilService.showAlertCallback("Você primeiro precisa escolher um paciente para trabalhar", null)
      this.closeModal();
      this.utilService.Movimentar("pagina");
      return;

    }
  }

  async selecionarDataHoje() {
    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.balancoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.Ano = dtHoje.substr(0, 4)
    this.Mes = dtHoje.substr(5, 2)
    this.Dia = dtHoje.substr(8, 2)


  }

  async testaDados() {

    let Datalancamento = this.balancoForm.value.Datalancamento.toString();
    Datalancamento = this.utilService.checaData(Datalancamento);
    // sem minuto, somente hora cheia

    Datalancamento = Datalancamento.substr(0, 13) + ":00";

    let Balancotipo = this.balancoForm.value.Grupo;
    if (Balancotipo == 1) {
      Balancotipo = this.balancoForm.value.Entrada;
    } else {
      Balancotipo = this.balancoForm.value.Saida;
    }

    if (Balancotipo == '' || Balancotipo == null || Balancotipo == undefined || Balancotipo < 0) {
      this.utilService.showAlertCallback('Escolha o tipo de lançamento ENTRADA ou SAÍDA',
        () => {
          this.utilService.focusInput(this.ancoraValor)
        }
      );
      return;
    }

    let Valor: number = this.balancoForm.value.Valor;
    Valor = this.utilService.formateNumberNew(Valor, 2);

    if (Valor == null || Valor == undefined || Valor <= 0) {
      this.utilService.showAlertCallback('VALOR inválido',
        () => {
          this.utilService.focusInput(this.ancoraValor)
        }
      );
      return;
    }

    let form = {
      Id: this.balancoForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Balancotipo: Balancotipo,
      Valor: Valor,
      Usuario: this.Usuario
    }

    this.presentAlertConfirm(form);

  }


  async closeModal() {

    this.modalCtrl.dismiss();
  }

  async presentAlertConfirm(form: any) {
    let dados = "";
    let grupo: number = this.balancoForm.value.Grupo;
    if (grupo == 1) {
      let ret: any = this.aEntrada.filter((a: any) => {
        return a.Id == this.balancoForm.value.Entrada
      })
      this.NomeLancamento = ret[0].Nome;
    }
    if (grupo == 2) {
      let ret: any = this.aSaida.filter((a: any) => {
        return a.Id == this.balancoForm.value.Saida;
      })
      this.NomeLancamento = ret[0].Nome;
    }
    let Datalancamento = this.utilService.getDataBrowserBrasil(this.balancoForm.value.Datalancamento);
    dados = dados + "Data: " + Datalancamento + "<br>";
    dados = dados + "Nome do lançamento: " + this.NomeLancamento + "<br>";

    dados = dados + "Valor: " + this.balancoForm.value.Valor + " mL <br>";

    let id = this.balancoForm.value.Id;

    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> "

    if (id > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>"
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'BALANÇO HIDRÍCO',
      message: msg + dados,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(this.ancoraValor);
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastrarBalanco(form);
          }
        }
      ]
    });

    await alert.present();
  }

  async selecionaGrupo(grupo: number) {
    this.balancoForm.controls['Grupo'].setValue(grupo);

  }

  cadastrarBalanco(form: any) {

    if (this.balancoForm.value.Id == undefined || this.balancoForm.value.Id == null || this.balancoForm.value.Id == -1) {
      this.balancoService.adicionarBalanco(form).then((response) => {
        this.utilService.showAlertCallback("Balanço hídrico ADICIONADO com sucesso", null);
        this.selecionarBalancoHoje(response[0].Id);
        this.calculaBalanco();
        setTimeout(()=>{
          this.balancoForm.controls['Id'].setValue(response[0].Id);
        },500)
       
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o balanço hídrico", null);

      });
    } else {
      this.balancoService.atualizarBalanco(form).then((response) => {
        this.utilService.showAlertCallback("Balanço hidrico ATUALIZADO com sucesso", null);
        this.selecionarBalancoHoje(response[0].Id);
        this.calculaBalanco();
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o balanço hídrico", null);

      });
    }

  }



  async selecionarBalancoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.balancoService.selecionarBalancoUnico(Id).then((response) => {
      this.getBalanco(response);
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar o registro")
    });
  }

  async limpaDados() {
    this.Botao = "Cadastrar";
    this.selecionarDataHoje();
    this.balancoForm.controls['Id'].setValue(-1);
    this.balancoForm.controls['Valor'].setValue('');
    this.balancoForm.controls['Grupo'].setValue(1);

    this.utilService.focusInput(this.ancoraValor);
    setTimeout(() => {
      this.selecionaBalancotipo();

    }, 500);

  }

  async getBalanco(response: any) {
    if (response.length == 0) {
      return;
    }

    this.Botao = "Alterar";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);
    this.balancoForm.controls['Datalancamento'].setValue(Datalancamento);
    this.balancoForm.controls['Grupo'].setValue(response[0].Grupo);
    if (response[0].Grupo == 1) {
      this.balancoForm.controls['Entrada'].setValue(response[0].Balancotipo);
    } else {
      this.balancoForm.controls['Saida'].setValue(response[0].Balancotipo);
    }

    let valor: number = this.utilService.formateNumberNew(response[0].Valor, 2);
    this.balancoForm.controls['Valor'].setValue(valor);



  }

  async selecionarBalancoHoje(Id: number) {
    this.balancoService.selecionarBalancoHoje(this.Internacao, this.Paciente).then((response) => {
      this.itensColection = response;
      if (this.itensColection.length > 0) {
        this.calculaBalanco();
        this.verRelacao(this.Grupo);
      }

    }).catch((response) => {
      this.utilService.showAlertCallback("Não existem lançamentos no BALANÇO para hoje")

    });


  }

  async calculaBalanco() {
    this.Entradas = 0;
    this.Saidas = 0;
    for (let i = 0; i < this.itensColection.length; i++) {
      if (this.itensColection[i].Grupo == 1) {
        this.Entradas += parseFloat(this.itensColection[i].Valor);
      } else {
        this.Saidas += parseFloat(this.itensColection[i].Valor);
      }
    }
    this.Grupo = this.balancoForm.value.Grupo;
    this.Balanco = this.Entradas - this.Saidas;
    setTimeout(() => {
      this.verRelacao(this.Grupo);
    }, 500)


  }

  async verRelacao(Grupo: number) {
    this.aBalancoRelacao = this.itensColection.filter((a: any) => {
      return a.Grupo == Grupo;

    })
    this.Grupo = Grupo;
  }

  async presentAlertExcluir(Id: number) {
    let aVet: any = this.aBalancoRelacao.filter((a: any) => {
      return a.Id == Id
    });

    let dados = "";
    let Datalancamento = aVet[0].Datalancamento;
    dados = dados + "Data: " + Datalancamento + "<br>";
    dados = dados + "Nome do lançamento: " + aVet[0].NomeBalancoTipo + "<br>";
    dados = dados + "Valor: " + aVet[0].Valor + " mL <br>";


    let msg = "Confirma a <strong>EXCLUSÃO</strong> desses dados? <br> "


    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'BALANÇO HIDRÍCO',
      message: msg + dados,
      mode: 'ios',
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancoraEntrada'));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.excluirRegistro(Id);
          }
        }
      ]
    });

    await alert.present();
  }

  async excluirRegistro(Id: number) {
    let x: number = 0;
    for (let i = 0; this.aBalancoRelacao.length; i++) {
      if (this.aBalancoRelacao[i].Id == Id) {
        x = i;
        break;
      }
    }
    this.balancoService.deletarBalancoUnico(Id).then((response) => {
      this.utilService.showAlertCallback("Registro deletado com sucesso", () => {
        this.aBalancoRelacao.splice(x, 1);
        this.selecionarBalancoHoje(-1);
      })

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível deletar o registro")

    });

  }

  async gerarPdfBalancoHidrico() {
    // 0-6 (zero=domingo)
    let dtHoje = this.utilService.dataHojeSemHoraBrasil();
    let aInternacao = this.dadosListMed.Internacao;
    let aEntrada:any = [];
    aEntrada = this.itensColection.filter((a:any)=>{
      return  a.Grupo == 1;
    })
    let aSaida:any = [];
    aSaida = this.itensColection.filter((a:any)=>{
      return  a.Grupo == 2;
    })
   // return;
    this.utilpdfService.criaDefinicaoPdfBalancoHidrico(aInternacao, aEntrada, aSaida, dtHoje).then((response: any) => {
      this.pdfObj = pdfMake.createPdf(response);
      this.pdfObj.getBase64((stringBase64Pdf: any) => {
        this.stringBase64Pdf = stringBase64Pdf;
         this.downloadPdf(stringBase64Pdf);

      })
    })
      .catch((response) => {
        this.utilService.showAlertCallback('Delculpe, mas não conseguimos criar o pdf.');
      })

  }

  
  downloadPdf(stringBase64Pdf: any) {
    if (this.isCordova) {
      this.pdfObj.getBuffer((buffer) => {
        var blob = new Blob([buffer], { type: 'application/pdf' });
        // Save the PDF to the data Directory of our App
        this.file.writeFile(this.file.dataDirectory, 'listmed.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          this.fileOpener.open(this.file.dataDirectory + 'listmed.pdf', 'application/pdf');
          this.utilService.showAlertCallback("Geramos um PDF do certificado para você");
        })
      });
    } else {
      // On a browser simply use download!
      this.pdfObj.download();

      this.utilService.showAlertCallback("Geramos um PDF do certificado para você");
    }
  }

 
}