import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PacienteService } from '../../services/paciente.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { AlertController } from '@ionic/angular';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-gasometria',
  templateUrl: './gasometria.component.html',
  styleUrls: ['./gasometria.component.scss'],
})
export class GasometriaComponent implements OnInit {
  @ViewChild('ancoraIdgasometria', null) ancoraIdgasometria: ElementRef;
  @ViewChild('ancoraDatalancamentogasometria', null) ancoraDatalancamentogasometria: ElementRef;
  @ViewChild('ancoraPh', null) ancoraPh: ElementRef;
  @ViewChild('ancoraPaco2', null) ancoraPaco2: ElementRef;
  @ViewChild('ancoraPvco2', null) ancoraPvco2: ElementRef;
  @ViewChild('ancoraPao2', null) ancoraPao2: ElementRef;
  @ViewChild('ancoraLactato', null) ancoraLactato: ElementRef;
  @ViewChild('ancoraHco3', null) ancoraHco3: ElementRef;
  @ViewChild('ancoraBe', null) ancoraBe: ElementRef;
  @ViewChild('ancoraFio2', null) ancoraFio2: ElementRef;
  @ViewChild('ancoraPf', null) ancoraPf: ElementRef;
  @ViewChild('ancoraNa', null) ancoraNa: ElementRef;
  @ViewChild('ancoraCl', null) ancoraCl: ElementRef;
  @ViewChild('ancoraK', null) ancoraK: ElementRef;
  @ViewChild('ancoraCa', null) ancoraCa: ElementRef;
  @ViewChild('ancoraPeso', null) ancoraPeso: ElementRef;
  @ViewChild('ancoraIdade', null) ancoraIdade: ElementRef;
  @ViewChild('ancoraAltura', null) ancoraAltura: ElementRef;
  @ViewChild('ancoraSato2', null) ancoraSato2: ElementRef;
  @ViewChild('ancoraSvto2', null) ancoraSvto2: ElementRef;
  @ViewChild('ancoraTemperatura', null) ancoraTemperatura: ElementRef;
  
  
  Id: number = -1;
  Exames: any;
  Datalancamento: any;
  Usuario: number = 0;
  Paciente: number = 0;
  Idade: any;
  Nome: string = "";
  Internacao: number = 0;
  gasometriaForm: FormGroup;
  Botao: string = "CALCULAR";
  Datainternacao: string = "";
  Dias: string = "";
  gasometriaCollection: any = [];
  dadosListMed: any;
  Logado: number = 0;
  Ph: any;
  Paco2: any;
  Pao2: any;
  Lactato: any;
  Hco3: any;
  Be: any;
  Fio2: any;
  Pf: any;
  Sato2: any
  Peso: any;
  lo: any;
  NomePaciente: string = "";
  Sigla: string;
  Componente: any;
  Valornormal: string = "";
  Descricaounidade: string = "";
  Descricaocalculo: string;
  Observacao: string = "";
  Valorminimo: any;
  Valormaximo: any;
  Sexo: number = 0;
  Altura: any;
  Bsa: any;
  Pao2e: any;
  Go2e: any;
  GAaO2: any;
  PAro2: any;
  Patm: number = 760;
  aPatm: any = [];
  Paco2e: any;
  Pvaco2: any;
  Svto2: any;
  Na: any;
  Cl: any;
  K: any;
  Ca: any;
  Agap: any;
  Dgap: any;
  Temperatura: number = 37;
  Pvco2: any;
  Resultado: string = ""
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private navParams: NavParams,
    private pacienteService: PacienteService,
    private laboratorioService: LaboratorioService,
    private alertController: AlertController,
    private checklistService: ChecklistService

  ) {

    this.gasometriaForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Patm: ['', ''],
      Temperatura: ['', ''],
     Sexo: ['', ''],
      Peso: ['', ''],
      Idade: ['', ''],
      Altura: ['', ''],
      Ph: ['', ''],
      Paco2: ['', ''],
      Pvco2: ['', ''],
      Paco2e: ['', ''],
      Pvaco2: ['', ''],
      Dpvcco2: ['', ''],
      Pao2: ['', ''],
      Pao2e: ['', ''],
      PAro2: ['', ''],
      Go2e: ['', ''],
      GAaO2: ['', ''],
      Lactato: ['', ''],
      Hco3: ['', ''],
      Be: ['', ''],
      Fio2: ['', ''],
      Pf: ['', ''],
      Sato2: ['', ''],
      Svto2: ['', ''],
      Na: ['', ''],
      Cl: ['', ''],
      K: ['', ''],
      Ca: ['', ''],
      Agap: ['', ''],
      Dgap: ['', ''],
      Resultado: ['', ''],
    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.NomePaciente = this.navParams.get('NomePaciente');
    this.Internacao = this.navParams.get('Internacao');
    this.Paciente = this.navParams.get('Internacao');
    this.lo = this.navParams.get('lo');
    this.Sigla = this.navParams.get('Sigla');
    this.Nome = this.navParams.get('Nome');
    this.Componente = this.navParams.get('Componente');
    this.Valornormal = this.navParams.get('Valornormal');
    this.Descricaounidade = this.navParams.get('Internacao');
    this.Descricaocalculo = this.navParams.get('Internacao');
    this.Observacao = this.navParams.get('Observacao');
    this.Valorminimo = this.navParams.get('Valorminimo');
    this.Valormaximo = this.navParams.get('Valormaximo');
    this.Observacao = this.navParams.get('Observacao');
    this.Sexo = parseInt(this.navParams.get('Sexo'));
    this.Altura = this.navParams.get('Altura');
    this.Peso = this.navParams.get('Peso');
    this.Bsa = this.navParams.get('Bsa');
    this.Usuario = parseInt(this.navParams.get('Usuario'));
    this.Peso = this.utilService.formateNumberNew(this.Peso, 3);
  }

  ngOnInit() {

    this.aPatm = [
      { Altitude: 0, Pressao: 760 },
      { Altitude: 500, Pressao: 680 },
      { Altitude: 1000, Pressao: 600 },
      { Altitude: 2000, Pressao: 480 },
      { Altitude: 3000, Pressao: 390 },
      { Altitude: 4000, Pressao: 300 },
      { Altitude: 5000, Pressao: 235 },
      { Altitude: 6000, Pressao: 170 },
      { Altitude: 7000, Pressao: 145 },
      { Altitude: 8000, Pressao: 120 },
      { Altitude: 9000, Pressao: 110 },
      { Altitude: 10000, Pressao: 100 },
    ]
    this.testaAcessoSistema();
    this.selecionarExames();
  }

  async selecionarPressaoAtmosferica(Patm: number) {
    this.Patm = Patm;
  }

  async calculaAnionGap() {
    let Hco3: any = this.utilService.checaCampo(this.gasometriaForm.value.Hco3);
    Hco3 = Hco3 / 1;
    if (Hco3 == 0 || isNaN(Hco3) || Hco3 == null || Hco3 == undefined) {
      this.utilService.showAlertCallback("Informe a HCO3 corretamento", () => {
        this.utilService.focusInput(this.ancoraHco3)
      })
      return;
    }

    let Cl: any = this.utilService.checaCampo(this.gasometriaForm.value.Cl);
    Cl = Cl / 1;
    if (Cl == 0 || Cl == '' || isNaN(Cl) || Cl == null || Cl == undefined) {
      this.utilService.showAlertCallback("Informe o Cloro corretamento", () => {
        this.utilService.focusInput(this.ancoraCl)
      })
      return;
    }

    let Na: number = this.utilService.checaCampo(this.gasometriaForm.value.Na);
    Na = Na / 1;
    if (Na == 0 || isNaN(Na) || Na == null || Na == undefined) {
      this.utilService.showAlertCallback("Informe a Sódio corretamento", () => {
        this.utilService.focusInput(this.ancoraNa)
      })
      return;
    }

    let Agap: number = Na - (Hco3 + Cl);
    Agap = this.utilService.formateNumberNew(Agap, 2);
    this.Agap = Agap;

    this.Dgap = (this.Agap - 12) - (24 - this.Hco3);

  }

  async fazerInterpretacao() {
    this.Resultado = "";
    // ph
    let sPh: string = "";
    if (this.Ph > 7.45) {
      sPh = " (Alcalemia)"
    } else if (this.Ph < 7.35) {
      sPh = " (Acidemia)"
    } else {
      sPh = " (Normal)"
    }
    this.Resultado = this.Resultado + "pH: " + this.Ph + sPh + '\n'

    // bicarbonato

    let sHco3: string = "";

    if (this.Hco3 > 26) {
      sHco3 = " (Alcalose metabólica)"
    } else if (this.Hco3 < 22) {
      sHco3 = " (Acidose metabólica)"
      if (this.Agap > 16) {
        sHco3 = sHco3 + ' => Com ânio gap elevado, normoclorêmica'
      } else {
        sHco3 = sHco3 + ' => Com ânio gap normal, hiperclorêmica'
      }
    } else {
      sHco3 = " (Normal, sem distúrbio metabólico)"
    }


    if (this.Ph >= 7.35 && this.Ph <= 7.46) {
      sHco3 = sHco3 + ' => Compensada'
    }

    this.Resultado = this.Resultado + "HCO3: " + this.Hco3 + ' mmol/L.' + sHco3 + '\n'


    // anio gap
    let sAgap: string = ""
    if (this.Agap > 16) {
      sAgap = " (Aumentado)"
    } else if (this.Agap < 8) {
      sAgap = " (Diminuido)"
    } else {
      sAgap = " (Normal)"
    }
    this.Resultado = this.Resultado + "Agap: " + this.Agap + ' mmol/L.' + sAgap + '\n'


    // delta gap
    let sDgap: string = "";

    if (this.Hco3 < 22 && this.Agap > 16) {
      if (this.Dgap < -6) {
        sDgap = " (há uma segunda acidose hiperclorêmica associado a primeira)"
      } else if (this.Dgap > 6) {
        sDgap = " (há uma alcalose metabólica associado ao primeiro distúrbio)"
      } else {

        sDgap = " (não há um distúrbio metabólico secundário. Delta Gap normal)"
      }
    } else if (this.Hco3 < 22 && this.Agap <= 16) {
      sDgap = " (use AG Urinário para verificar o mecanismo de acidose, perda de bicarbonato ou ganho de ácido.)"
    } else {
      sDgap = " ( normal, não se aplica neste caso)"

    }

    this.Resultado = this.Resultado + "Dgap: " + this.Dgap + ' mmol/L.' + sDgap + '\n'

    // PaCO2 esperada

    let sPaco2e: string = ""

    if (this.Paco2 < this.Paco2e) {
      sPaco2e = " (Alcalose respiratória)"
    } else if (this.Paco2 > this.Paco2e) {
      sPaco2e = " (Acidose respiratória)"
    } else {
      sPaco2e = " (Normal)"
    }
    this.Resultado = this.Resultado + "PaCO2 Medido: " + this.Paco2 + ' mmHg.' + '\n'

    this.Resultado = this.Resultado + "PaCO2 Esperado: " + this.Paco2e + ' mmHg.' + sPaco2e + '\n'

    // delta PvcCO2

    let sPvaco2: string = "";

    if (this.Pvaco2 < 6) {
      sPvaco2 = " (Normal)"
    } else if (this.Pvaco2 >= 6) {
      sPvaco2 = " (Aumentado, isso pode esta relacionado com IC baixo)"
    }
    this.Resultado = this.Resultado + "Pvaco2: " + this.Pvaco2 + ' mmHg.' + sPvaco2 + '\n'

    // PaO2 x PaO2esperada - relação PF, saturação

    let sPao2: string = ""
    let lHopoxemia: boolean = false;
    if (this.Pao2 < this.Pao2e) {
      sPao2 = " (Esse paciente poderá estar hipoxemico)";
      lHopoxemia = true;
    } else {
      sPao2 = " (Normal)"
    }
    this.Resultado = this.Resultado + "PaO2 esperada: " + this.Pao2e + ' mmHg'  + '\n'

    this.Resultado = this.Resultado + "PaO2: " + this.Pao2 + ' mmHg' + sPao2 + '\n'

    // PF
    let sPf: string = ""

    if (lHopoxemia == true) {
      if (this.Pf < 100) {
        sPf = " (hipoxemia grave)";
      } else if (this.Pf < 200) {
        sPf = " (hipoxemia moderada)";
      } else if (this.Pf < 300) {
        sPf = " (hipoxemia leve)";
      } else if (this.Pf >= 300) {
        sPf = " (oxigenação normal)";
     
      }
    } else {
      sPf = " (oxigenação normal)";
    }

    this.Resultado = this.Resultado + "Relação P/F: " + this.Pf  + ' mmHg.' + sPf + '\n'

    // Lactato
    let sLactato: string = ""
    if (this.Lactato <= 2) {
      sLactato = " (Normal)";
    }else if (this.Lactato > 2 && this.Lactato <= 5) {
      sLactato = " (Aumento leve)";
    }else if (this.Lactato > 5 && this.Lactato <= 10) {
      sLactato = " (Aumento moderado)";
    }else if (this.Lactato >  10) {
      sLactato = " (Aumento grave)";
    }

    this.Resultado = this.Resultado + "Lactato: " + this.Lactato  + ' mmol/L. ' + sLactato + '\n'


  }



  async calculaPaCO2Esperada() {

    let Hco3: number = this.utilService.checaCampo(this.gasometriaForm.value.Hco3);
    Hco3 = Hco3 / 1;
    if (Hco3 == 0 || isNaN(Hco3) || Hco3 == null || Hco3 == undefined) {
      this.utilService.showAlertCallback("Informe a HCO3 corretamento", () => {
        this.utilService.focusInput(this.ancoraHco3)
      })
      return;
    }
    if (this.Hco3 < 22) {
      this.Paco2e = 1.5 * Hco3 + 8;
    } else if (this.Hco3 > 26) {
      this.Paco2e = Hco3 + 15;
    } else {
      this.Paco2e = this.Paco2;
    }
    this.Paco2e = this.utilService.formateNumberNew(this.Paco2e, 2);


    this.calculaDeltaPaCO2();

  }

  async calculaDeltaPaCO2() {
    let Paco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Paco2);
    Paco2 = Paco2 / 1;

    if (Paco2 == 0 || isNaN(Paco2) || Paco2 == null || Paco2 == undefined) {
      this.utilService.showAlertCallback("Informe a PaCo2 corretamento", () => {
        this.utilService.focusInput(this.ancoraPaco2)
      })
      return;
    }

    let Pvco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Pvco2);
    Pvco2 = Pvco2 / 1;

    if (Pvco2 == 0 || isNaN(Pvco2) || Pvco2 == null || Pvco2 == undefined) {
      this.utilService.showAlertCallback("Informe a PvCo2 corretamento", () => {
        this.utilService.focusInput(this.ancoraPvco2)
      })
      return;
    }

    let Pvaco2 = Pvco2 - Paco2;
    Pvaco2 = this.utilService.formateNumberNew(Pvaco2, 2);
    this.Pvaco2 = Pvaco2;

    let sPvaco2: string = ""


  }

  async calculaPaO2Esperada() {
    let Idade: number = this.utilService.checaCampo(this.gasometriaForm.value.Idade);
    Idade = Idade / 1;
    if (Idade == 0 || isNaN(Idade) || Idade == null || Idade == undefined) {
      this.utilService.showAlertCallback("Informe a Idade corretamento", () => {
        this.utilService.focusInput(this.ancoraIdade)
      })
      return;
    }

    this.Idade = Idade;

    this.Pao2e = 109 - (0.43 * this.Idade);
    this.Pao2e = this.utilService.formateNumberNew(this.Pao2e, 2);
    this.Go2e = 2.5 + (0.21 * this.Idade);
    this.Go2e = this.utilService.formateNumberNew(this.Go2e, 2);
    this.Pao2e = this.utilService.formateNumberNew(this.Pao2e, 2);

  }

  async informaErro(pagina: string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [

        {

          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },


      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados: any) => {
            this.adicionarErro(Dados.Descricao, pagina);
          }
        }
      ]
    });

    await alert.present();
  }

  async adicionarErro(descricao: string, pagina: string) {


    let form = {
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }


    this.checklistService.adicionarManutencao(form).then((response: any) => {
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(() => {
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")

    })

  }

  async calculaPAO2() {
    let Fio2: any = this.utilService.checaCampo(this.gasometriaForm.value.Fio2) // o cálculo só pode ser com 21% ou 100 %
    Fio2 = Fio2 / 100;
    if (isNaN(Fio2) || Fio2 == null || Fio2 == undefined) {
      this.utilService.showAlertCallback("Informe a FiO2 corretamento em 21 ou 100 %", () => {
        this.utilService.focusInput(this.ancoraFio2)
      })
      return;
    }

    if (Fio2 < 0.21 || Fio2 > 1) {
      this.utilService.showAlertCallback("Informe a FiO2 corretamento em 21 ou 100 %", () => {
        this.utilService.focusInput(this.ancoraFio2)
      })
      return;
    }



    let Paco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Paco2);
    Paco2 = Paco2 / 1;

    if (isNaN(Paco2) || Paco2 == null || Paco2 == undefined) {
      this.utilService.showAlertCallback("Informe a PaCo2 corretamento", () => {
        this.utilService.focusInput(this.ancoraPaco2)
      })
      return;
    }

    let Pao2: any = this.utilService.checaCampo(this.gasometriaForm.value.Pao2);
    Pao2 = Pao2 / 1;
    if (isNaN(Pao2) || Pao2 == null || Pao2 == undefined) {
      this.utilService.showAlertCallback("Informe a PaO2 corretamento", () => {
        this.utilService.focusInput(this.ancoraPao2)
      })
      return;
    }

    let Patm = this.Patm // 760 // pressãoa parometrica local ao nível do mar
    let Pah20 = 47 // pressão do vapor dágua alveolar a 37oC em mmHg
    let R = 0.8 // coeficiente respiratório relacionado com produção de CO2 e consumo de O2
    let PAro2: any = Fio2 * (Patm - 47)
    PAro2 = PAro2 - (Paco2 / 0.8)
    this.PAro2 = this.utilService.formateNumberNew(PAro2, 2);
    this.GAaO2 = this.PAro2 - Pao2
    this.GAaO2 = this.utilService.formateNumberNew(this.GAaO2, 2);

    this.Pf = Pao2 / Fio2
    this.Pf = this.utilService.formateNumberNew(this.Pf, 2);

    this.Pao2 = Pao2;
    this.Paco2 = Paco2;

    let Pvaco2 = this.Pvco2 - Paco2;
    Pvaco2 = this.utilService.formateNumberNew(Pvaco2, 2);
    this.Pvaco2 = Pvaco2;

    let Ph: any = this.utilService.checaCampo(this.gasometriaForm.value.Ph)
    Ph = Ph / 1;

    if (isNaN(Ph) || Ph == null || Ph == undefined) {
      this.utilService.showAlertCallback("Informe a pH corretamento", () => {
        this.utilService.focusInput(this.ancoraPh)
      })
      return;
    }

    Ph = this.utilService.formateNumberNew(Ph, 2);

    this.Ph = Ph;

    let Hco3: any = this.utilService.checaCampo(this.gasometriaForm.value.Hco3) // o cálculo só pode ser com 21% ou 100 %
    Hco3 = Hco3 / 1;

    if (isNaN(Hco3) || Hco3 == null || Hco3 == undefined) {
      this.utilService.showAlertCallback("Informe a HCO3 corretamento", () => {
        this.utilService.focusInput(this.ancoraHco3)
      })
      return;
    }

    Hco3 = this.utilService.formateNumberNew(Hco3, 2);

    this.Hco3 = Hco3;

    let Lactato: any = this.utilService.checaCampo(this.gasometriaForm.value.Lactato) // o cálculo só pode ser com 21% ou 100 %
    Lactato = Lactato / 1;

    if (isNaN(Lactato) || Lactato == null || Lactato == undefined) {
      this.utilService.showAlertCallback("Informe a Lactato corretamento", () => {
        this.utilService.focusInput(this.ancoraLactato)
      })
      return;
    }

    Lactato = this.utilService.formateNumberNew(Lactato, 2);

    this.Lactato = Lactato;


  }

  async closeModal() {
    this.modalCtrl.dismiss();
  }




  async calculos() {
    //pH = 6,10 + log [HCO3]/0,03 x PCO2
    // Gradientes A-a altos estão associados a problemas de transferência de oxigênio ou de trocas gasosas. Geralmente estão associados a doenças da membrana alveolar, doenças intersticiais ou desequilíbrio V/Q.
    // Hipóxia na presença de um gradiente A-a normal implica em hipoventilação com deslocamento do O2 alveolar por CO2 ou outra substância.
    // AFIO2 (fração de oxigênio inspirado) é igual aopercentual de O2 inspirado / 100.
    // Nesta calculadora, apATM é estimada pela elevação acima do nível do mar usando-se esta fórmula: pAtm = 760 * eTo(elevação / -7000)
    // A pH2O depende da temperatura corporal e é calculada com a seguinte fórmula: pH2O = 47 * eTo((T do paciente - 37) / 18.4)
    // A equação para o gradiente A-a esperado pressupõe que o paciente está respirando ar ambiental; portanto tem menor precisão em porcentagens mais altas de oxigênio inspirado.
    // pAtm = 760 * e(Paciente/-7000)
    // pH2O = 47 * e((Elevação de Tempdo-37)/18.4)
    // FIO2 = PercentualInspiradoO2 / 100
    // IdadeAaG = 2.5 + (0.21 * Esperada)
    // MedidaAaG = (FIO2 * (pAtm - pH2O)) - (pCO2 / CoefResp) + (pCO2 * FIO2 * (1 - CoefResp) / CoefResp) - paO2
    // https://www.slideshare.net/resenfe2013/monitorizao-da-oxigenao-arterial
  }

  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Idade = this.dadosListMed.Internacao.Idade;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Internacao > 0) {
        this.Peso = this.dadosListMed.Internacao.Peso;
        this.Peso = this.utilService.formateNumberNew(this.Peso, 2);
        this.Botao = 'CADASTRAR';
      }

      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarGasometria(-1)
      }


    }
  }

  async iniciarGasometria() {
    let lRet:boolean= false;
    let valor: number;
    let ancora:any
    let sigla:string="";
    for (let i=0; i<this.Exames.length; i++) {
      valor = eval('this.gasometriaForm.value.' + this.Exames[i].nome);
      sigla =this.Exames[i].Sigla;
      if (sigla == "Sexo") {
        ancora = eval('this.ancoraPeso' );

      }else{
        ancora = eval('this.ancora' +  this.Exames[i].nome);

      }
      if (isNaN(valor/1) || valor == undefined || valor == null || valor == 0){
        lRet = true;
        break;
      } 
    }
    if (lRet == true) {
      this.utilService.showAlertCallback("Você precisa informar o " +sigla, ()=>{
        this.utilService.focusInput(ancora);
      });
      return;
    }
    this.fazerInterpretacao();
    if (this.Logado>0 && this.Paciente > 0 && this.Internacao > 0) {
      this.presentAlertConfirm();
    }

  }

  async presentAlertConfirm() {
    let cValores = '';
    let x = 0;
    let parametro = '';
    for (let i = 0; i < this.Exames.length; i++) {
      let valor: number = eval('this.gasometriaForm.value.' + this.Exames[i].nome);
      if (valor < this.Exames[i].vmin || valor > this.Exames[i].vmax) {
        x = x + 1
        if (x == 1) {
          cValores = '<strong>DADOS FORA DA NORMALIDADE</strong><br>';
          parametro = this.Exames[i].nome;
        }
        this.gasometriaForm.controls[this.Exames[i].nome].setValue(valor);
        cValores = cValores + '-> ' + this.Exames[i].msg + ": " + valor + ' (' + this.Exames[i].vmin + '-' + this.Exames[i].vmax + ' ' + this.Exames[i].unidade + ')<br>'
      }
    }



    let tipo = this.gasometriaForm.value.Id;
    let msg = "Confirma a <strong>ADIÇÃO</strong> desses dados? <br> " + cValores
    if (tipo > 0) {
      msg = "Confirma a <strong>ALTERAÇÃO</strong> desses dados? <br>" + cValores
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'GASOMETRIA',
      message: msg,
      buttons: [
        {
          text: 'NÃO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.utilService.focusInput(eval('this.ancora' + parametro));
          }
        }, {
          text: 'SIM',
          handler: () => {
            this.cadastroGasometria();
          }
        }
      ]
    });

    await alert.present();
  }



  cadastroGasometria() {

    if (this.Paciente == 0 || this.Internacao == 0) {
      this.utilService.showAlertCallback("Sem paciente escolhido. Vamos fazer o cálculo análitico", () => {
        this.utilService.focusInput(this.ancoraPeso);
      })
      // fazer somente cálculo analiticos
      return;
    }

    let Datalancamento: any = this.utilService.checaData(this.gasometriaForm.value.Datalancamento);
    let Sexo: number = this.gasometriaForm.value.Sexo;
    if (Sexo == null || Sexo == undefined) {
      this.utilService.showAlertCallback("Escolha o sexo do paciente", () => {
        this.utilService.focusInput(this.ancoraPeso);
      })
      return;

    }

    let Peso: any = this.utilService.checaCampo(this.gasometriaForm.value.Peso);
    if (this.Peso == 0 && isNaN(Peso / 1) || Peso == "" || Peso == null || Peso == undefined) {
      this.utilService.showAlertCallback("Escolha o Peso do paciente", () => {
        this.utilService.focusInput(this.ancoraPeso);
      })
      return;

    }

    let Idade: number = this.utilService.checaCampo(this.gasometriaForm.value.Idade);

    if (isNaN(Idade / 1) || Idade == null || Idade == undefined) {
      this.utilService.showAlertCallback("Escolha o Idade do paciente", () => {
        this.utilService.focusInput(this.ancoraIdade);
      })
      return;
    }


    let Altura: number = this.utilService.checaCampo(this.gasometriaForm.value.Altura);
    if (this.Altura == 0 && isNaN(Altura / 1) || Altura == null || Altura == undefined) {
      this.utilService.showAlertCallback("Escolha o Altura do paciente", () => {
        this.utilService.focusInput(this.ancoraAltura);
      })
      return;

    }

    let Temperatura: any = this.utilService.checaCampo(this.gasometriaForm.value.Temperatura);
    if (Temperatura == 0 || isNaN(Temperatura) || Temperatura == null || Temperatura == undefined) {
      this.utilService.showAlertCallback("Informe a Temperatura do paciente", () => {
        this.utilService.focusInput(this.ancoraTemperatura);
      })
      return;
    }

    let Go2e: any = this.utilService.checaCampo(this.gasometriaForm.value.Go2e);
    if (Go2e == 0 || isNaN(Go2e) || Go2e == null || Go2e == undefined) {
      this.utilService.showAlertCallback("le o GAP de O2 esperado do paciente", () => {
        this.utilService.focusInput(this.ancoraIdade);
      })
      return;
    }



    let Pao2e: any = this.utilService.checaCampo(this.gasometriaForm.value.Pao2e);
    if (Pao2e == 0 || isNaN(Pao2e) || Pao2e == null || Pao2e == undefined) {
      this.utilService.showAlertCallback("le o PaO2 esperada do paciente", () => {
        this.utilService.focusInput(this.ancoraIdade);
      })
      return;
    }

    let Ph: any = this.utilService.checaCampo(this.gasometriaForm.value.Ph);
    if (Ph == 0 || isNaN(Ph) || Ph == null || Ph == undefined) {
      this.utilService.showAlertCallback("Escolha o Ph do paciente", () => {
        this.utilService.focusInput(this.ancoraPh);
      })
      return;
    }

    let Paco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Paco2);
    if (Paco2 == 0 || isNaN(Paco2) || Paco2 == "" || Paco2 == null || Paco2 == undefined) {
      this.utilService.showAlertCallback("Escolha o PaCO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraPaco2);
      })
      return;
    }

    let Pao2: any = this.utilService.checaCampo(this.gasometriaForm.value.Pao2);
    if (Pao2 == 0 || isNaN(Pao2) || Pao2 == null || Pao2 == undefined) {
      this.utilService.showAlertCallback("Escolha o PaO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraPao2);
      })

    }

    let Lactato: any = this.utilService.checaCampo(this.gasometriaForm.value.Lactato);
    if (isNaN(Lactato / 1) || Lactato == "" || Lactato == null || Lactato == undefined) {
      this.utilService.showAlertCallback("Escolha o Lactato do paciente", () => {
        this.utilService.focusInput(this.ancoraLactato);
      })
      return;

    }

    let Hco3: any = this.utilService.checaCampo(this.gasometriaForm.value.Hco3);
    if (isNaN(Hco3) || Hco3 == "" || Hco3 == null || Hco3 == undefined) {
      this.utilService.showAlertCallback("Escolha o HCO3 do paciente", () => {
        this.utilService.focusInput(this.ancoraHco3);
      })
      return;
    }

    let Be: any = this.utilService.checaCampo(this.gasometriaForm.value.Be);
    if (isNaN(Be / 1) || Be == "" || Be == null || Be == undefined) {
      this.utilService.showAlertCallback("Escolha o BE do paciente", () => {
        this.utilService.focusInput(this.ancoraBe);
      })
      return;
    }

    let Fio2: any = this.utilService.checaCampo(this.gasometriaForm.value.Fio2);
    if (isNaN(Fio2) || Fio2 == "" || Fio2 == null || Fio2 == undefined) {
      this.utilService.showAlertCallback("Escolha o FiO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraFio2);
      })
      return;
    }

    let PAro2: any = this.utilService.checaCampo(this.gasometriaForm.value.PAro2);
    if (isNaN(PAro2 / 1) || PAro2 == "" || PAro2 == null || PAro2 == undefined) {
      this.utilService.showAlertCallback("Escolha o PAO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraFio2);
      })
      return;
    }

    let GAaO2: any = this.utilService.checaCampo(this.gasometriaForm.value.GAaO2);
    if (isNaN(GAaO2 / 1) || GAaO2 == "" || GAaO2 == null || GAaO2 == undefined) {
      this.utilService.showAlertCallback("Escolha o G(A-a) O2 do paciente", () => {
        this.utilService.focusInput(this.ancoraFio2);
      })
      return;
    }

    let Pf: any = this.utilService.checaCampo(this.gasometriaForm.value.Pf);
    if (isNaN(Pf / 1) || Pf == "" || Pf == null || Pf == undefined) {
      this.utilService.showAlertCallback("Escolha o P/F do paciente", () => {
        this.utilService.focusInput(this.ancoraFio2);
      })
      return;
    }

    let Sato2: any = this.utilService.checaCampo(this.gasometriaForm.value.Sato2);
    if (isNaN(Sato2 / 1) || Sato2 == null || Sato2 == undefined) {
      this.utilService.showAlertCallback("Escolha o SatO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraSato2);
      })
      return;

    }

    let Na: any = this.utilService.checaCampo(this.gasometriaForm.value.Na);
    if (isNaN(Na / 1) || Na == "" || Na == null || Na == undefined) {
      this.utilService.showAlertCallback("Escolha o Sódio do paciente", () => {
        this.utilService.focusInput(this.ancoraNa);
      })
      return;
    }

    let Cl: any = this.utilService.checaCampo(this.gasometriaForm.value.Cl);
    if (isNaN(Cl / 1) || Cl == "" || Cl == null || Cl == undefined) {
      this.utilService.showAlertCallback("Escolha o Cloro do paciente", () => {
        this.utilService.focusInput(this.ancoraCl);
      })
      return;

    }

    let Agap: any = this.utilService.checaCampo(this.gasometriaForm.value.Agap);
    if (isNaN(Agap / 1) || Agap == "" || Agap == null || Agap == undefined) {
      this.utilService.showAlertCallback("Informe o Ânio Gap do paciente", () => {
        this.utilService.focusInput(this.ancoraCl);
      })
      return;
    }

    let Dgap: any = this.utilService.checaCampo(this.gasometriaForm.value.Dgap);
    if (isNaN(Dgap / 1) || Dgap == "" || Dgap == null || Dgap == undefined) {
      this.utilService.showAlertCallback("Informe o Delta Gap do paciente", () => {
        this.utilService.focusInput(this.ancoraCl);
      })
      return;
    }

    let K: any = this.utilService.checaCampo(this.gasometriaForm.value.K);
    if (isNaN(K / 1) || K == "" || K == null || K == undefined) {
      this.utilService.showAlertCallback("Informe o Potassio do paciente", () => {
        this.utilService.focusInput(this.ancoraK);
      })
      return;
    }

    let Ca: any = this.utilService.checaCampo(this.gasometriaForm.value.Ca);
    if (isNaN(Ca / 1) || Ca == "" || Ca == null || Ca == undefined) {
      this.utilService.showAlertCallback("Informe o Cálcio do paciente", () => {
        this.utilService.focusInput(this.ancoraCa);
      })
      return;
    }

    let Pvco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Pvco2);
    if (this.Pvco2 == 0 && isNaN(Pvco2 / 1) || Pvco2 == "" || Pvco2 == null || Ca == undefined) {
      this.utilService.showAlertCallback("Informe o PvCO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraCa);
      })
      return;
    }

    let Paco2e: any = this.utilService.checaCampo(this.gasometriaForm.value.Paco2e);
    if (isNaN(Paco2e / 1) || Paco2e == "" || Paco2e == null || Paco2e == undefined) {
      this.utilService.showAlertCallback("Informe o Delta Pv-aCO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraPvco2);
      })
      return;

    }

    let Pvaco2: any = this.utilService.checaCampo(this.gasometriaForm.value.Pvaco2);
    if (this.Pvaco2 == 0 && isNaN(Pvaco2 / 1) || Pvaco2 == "" || Pvaco2 == null || Pvaco2 == undefined) {
      this.utilService.showAlertCallback("Informe o Delta Pv-aCO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraPvco2);
      })
      return;
    }

    let Svto2: any = this.utilService.checaCampo(this.gasometriaForm.value.Svto2);
    if (isNaN(Svto2 / 1) || Svto2 == "" || Svto2 == null || Svto2 == undefined) {
      this.utilService.showAlertCallback("Informe o SvtO2 do paciente", () => {
        this.utilService.focusInput(this.ancoraSvto2);
      })
      return;
    }



    let form = {
      Id: this.gasometriaForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Sexo: Sexo,
      Peso: Peso,
      Idade: Idade,
      Altura: Altura,
      Ph: Ph,
      Paco2: Paco2,
      Pao2: Pao2,
      Lactato: Lactato,
      Hco3: Hco3,
      Be: Be,
      Fio2: Fio2,
      PAro2: PAro2,
      GAaO2: GAaO2,
      Pf: Pf,
      Sato2: Sato2,
      Na: Na,
      Cl: Cl,
      Agap: Agap,
      Dgap: Dgap,
      K: K,
      Ca: Ca,
      Pvco2: Pvco2,
      Pvaco2: Pvaco2,
      Paco2e: Paco2e,
      Svto2: Svto2,
      Pao2e: Pao2e,
      Go2e: Go2e,
      Temperatura: Temperatura,
      Usuario: this.Usuario,
    }

    if (this.gasometriaForm.value.Id == -1 || this.gasometriaForm.value.Id == 0 || this.gasometriaForm.value.Id == undefined || this.gasometriaForm.value.Id == null || this.gasometriaForm.value.Id == '') {
      this.laboratorioService.adicionarGasometria(form).then((response) => {
        this.selecionarGasometria(response[0].Id);
        this.utilService.showToastCallBack("Gasometria ADICIONADA com sucesso", 2000,
          () => { this.Id = response[0].Id; });
      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível incluir o Gasometria", 2000, null);
      });
    } else {
      this.laboratorioService.atualizarGasometria(form).then((response) => {
        this.selecionarGasometria(response[0].Id);
        this.utilService.showToastCallBack("Gasometria ATUALIZADA com sucesso", 2000,
          () => {
            this.Id = response[0].Id;
            this.gasometriaForm.controls['Id'].setValue(this.Id);

          });
      }).catch((response) => {
        this.utilService.showToastCallBack("Não foi possível atualizar o Gasometria", 2000, null);
      });
    }
  }

  async selecionarExames() {
    this.utilService.selecionaExamesValoresGasometria()
      .then((response:any) => {
        this.Exames = response;
        this.limpaDados();
      })
      .catch((response) => {

        this.Exames = [];
      });
  }
  async selecionarGasometriaUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return;
    }
    this.laboratorioService.selecionarGasometriaUnico(Id).then((response: any) => {
      if (response.length > 0) {
        this.getDados(response);
      }

    }).catch((response) => {

    });
  }




  async limpaDados() {
    this.Botao = "CALCULAR";
    let dtHoje = this.utilService.dataHoje();
    this.Datalancamento = dtHoje;
    this.Id = -1;
    this.gasometriaForm.controls['Datalancamento'].setValue(dtHoje);
    this.gasometriaForm.controls['Ph'].setValue('');
    this.gasometriaForm.controls['Paco2'].setValue('');
    this.gasometriaForm.controls['Pao2'].setValue('');
    this.gasometriaForm.controls['Lactato'].setValue('');
    this.gasometriaForm.controls['Hco3'].setValue('');
    this.gasometriaForm.controls['Be'].setValue('');
    this.gasometriaForm.controls['Fio2'].setValue('');
    this.gasometriaForm.controls['Pf'].setValue('');
    this.Id = -1;
    this.gasometriaForm.controls['Idade'].setValue(this.Idade);
    this.gasometriaForm.controls['Peso'].setValue(this.Peso);
    this.gasometriaForm.controls['Altura'].setValue(this.Altura);
    this.gasometriaForm.controls['Sexo'].setValue(this.Sexo);

    this.gasometriaForm.controls['PAro2'].setValue('');
    this.gasometriaForm.controls['GAaO2'].setValue('');
    this.gasometriaForm.controls['Sato2'].setValue('');
    this.gasometriaForm.controls['Na'].setValue('');
    this.gasometriaForm.controls['Cl'].setValue('');
    this.gasometriaForm.controls['Agap'].setValue('');
    this.gasometriaForm.controls['Dgap'].setValue('');
    this.gasometriaForm.controls['K'].setValue('');
    this.gasometriaForm.controls['Ca'].setValue('');
    this.gasometriaForm.controls['Pvco2'].setValue('');
    this.gasometriaForm.controls['Pvaco2'].setValue('');
    this.gasometriaForm.controls['Paco2e'].setValue('');
    this.gasometriaForm.controls['Svto2'].setValue('');

    this.gasometriaForm.controls['Go2e'].setValue('');
    this.gasometriaForm.controls['Pao2e'].setValue('');
    this.gasometriaForm.controls['Temperatura'].setValue(this.Temperatura);


    this.utilService.focusInput(this.ancoraIdade);
  }

  async getDados(response: any) {
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.getDataBrowser(response[0].Datalancamento);
    let Ph: any = this.utilService.formateNumberNew(response[0].Ph, 2);
    let Paco2: any = this.utilService.formateNumberNew(response[0].Paco2, 2);
    let Pao2: any = this.utilService.formateNumberNew(response[0].Pao2, 2);
    let Lactato: any = this.utilService.formateNumberNew(response[0].Lactato, 2);
    let Hco3: any = this.utilService.formateNumberNew(response[0].Hco3, 2);
    let Be: any = this.utilService.formateNumberNew(response[0].Be, 2);
    let Fio2: any = this.utilService.formateNumberNew(response[0].Fio2, 2);
    let Pf: any = this.utilService.formateNumberNew(response[0].Pf, 2);
    let Idade: any = this.utilService.formateNumberNew(response[0].Idade, 2);
    let Peso: any = this.utilService.formateNumberNew(response[0].Peso, 3);
    let Altura: any = this.utilService.formateNumberNew(response[0].Altura, 2);
    let Sexo: any = this.utilService.formateNumberNew(response[0].Sexo, 0);
    let PAro2: any = this.utilService.formateNumberNew(response[0].PAro2, 2);
    let GAaO2: any = this.utilService.formateNumberNew(response[0].GAaO2, 2);
    let Sato2: any = this.utilService.formateNumberNew(response[0].Sato2, 2);
    let Na: any = this.utilService.formateNumberNew(response[0].Na, 2);
    let Cl: any = this.utilService.formateNumberNew(response[0].Cl, 2);
    let K: any = this.utilService.formateNumberNew(response[0].K, 2);
    let Ca: any = this.utilService.formateNumberNew(response[0].Ca, 2);
    let Agap: any = this.utilService.formateNumberNew(response[0].Agap, 2);
    let Dgap: any = this.utilService.formateNumberNew(response[0].Dgap, 2);
    let Pvco2: any = this.utilService.formateNumberNew(response[0].Pvco2, 2);
    let Pvaco2: any = this.utilService.formateNumberNew(response[0].Pvaco2, 2);
    let Paco2e: any = this.utilService.formateNumberNew(response[0].Paco2e, 2);
    let Svto2: any = this.utilService.formateNumberNew(response[0].Svto2, 2);
    let Go2e: any = this.utilService.formateNumberNew(response[0].Go2e, 2);
    let Pao2e: any = this.utilService.formateNumberNew(response[0].Pao2e, 2);

    let Temperatura = this.utilService.formateNumberNew(response[0].Temperatura, 2);
    this.gasometriaForm.controls['Datalancamento'].setValue(Datalancamento);
    this.gasometriaForm.controls['Ph'].setValue(Ph);
    this.gasometriaForm.controls['Paco2'].setValue(Paco2);
    this.gasometriaForm.controls['Pao2'].setValue(Pao2);
    this.gasometriaForm.controls['Lactato'].setValue(Lactato);
    this.gasometriaForm.controls['Hco3'].setValue(Hco3);
    this.gasometriaForm.controls['Be'].setValue(Be);
    this.gasometriaForm.controls['Fio2'].setValue(Fio2);
    this.gasometriaForm.controls['Pf'].setValue(Pf);
    this.gasometriaForm.controls['Idade'].setValue(Idade);
    this.gasometriaForm.controls['Peso'].setValue(Peso);
    this.gasometriaForm.controls['Altura'].setValue(Altura);
    this.gasometriaForm.controls['Sexo'].setValue(Sexo);

    this.gasometriaForm.controls['PAro2'].setValue(PAro2);
    this.gasometriaForm.controls['GAaO2'].setValue(GAaO2);
    this.gasometriaForm.controls['Sato2'].setValue(Sato2);
    this.gasometriaForm.controls['Na'].setValue(Na);
    this.gasometriaForm.controls['Cl'].setValue(Cl);
    this.gasometriaForm.controls['Agap'].setValue(Agap);
    this.gasometriaForm.controls['Dgap'].setValue(Dgap);
    this.gasometriaForm.controls['K'].setValue(K);
    this.gasometriaForm.controls['Ca'].setValue(Ca);
    this.gasometriaForm.controls['Pvco2'].setValue(Pvco2);
    this.gasometriaForm.controls['Pvaco2'].setValue(Pvaco2);
    this.gasometriaForm.controls['Paco2e'].setValue(Paco2e);
    this.gasometriaForm.controls['Svto2'].setValue(Svto2);
    this.gasometriaForm.controls['Paco2e'].setValue(Paco2e);

    this.gasometriaForm.controls['Go2e'].setValue(Go2e);
    this.gasometriaForm.controls['Pao2e'].setValue(Pao2e);
    this.gasometriaForm.controls['Temperatura'].setValue(Temperatura);
  }

  async selecionarGasometria(Id: number) {
    if (Id < 0) {
      return;
    }
    this.laboratorioService.selecionarGasometria(this.Internacao, this.Paciente).then((response) => {
      this.gasometriaCollection = response;
    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível selecionar a gasometria", null)
    });
  }







}


