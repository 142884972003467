import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { UsuarioService } from '../../services/usuario.service';
import { NavController, PopoverController, ModalController, LoadingController } from '@ionic/angular';
import { FotousuarioComponent } from '../fotousuario/fotousuario.component';
import { FotopacienteComponent } from '../fotopaciente/fotopaciente.component';
import { PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'app-taginfo',
  templateUrl: './taginfo.component.html',
  styleUrls: ['./taginfo.component.scss'],
})
export class TaginfoComponent implements OnInit {
  @Input("Menu")
  Menu: any;
  @Input("Retorno")
  Retorno: string;
  @Output() exibirIdUsuario = new EventEmitter<number>();
  @Output() exibirFotoUsuario = new EventEmitter<string>();
  @Output() exibirNomePaciente = new EventEmitter<string>();
  @Output() exibirFotoPaciente = new EventEmitter<string>();
  @Output() exibirPaciente = new EventEmitter<number>();
  @Output() exibirInternacao = new EventEmitter<number>();
  @Output() exibirSexo = new EventEmitter<number>();
  @Output() exibirIdade = new EventEmitter<number>();


  dadosListMed: any
  IdUsuario: number = 0;
  Logado: number = 0;
  NomeUsuario: any = "";
  DadosUsuario: any = [];
  FotoAtualUsuario: string = '';
  DataNascimento: any;
  EmailUsuario: any = '';
  Numeroconselho: any = '';
  IdadeUsuario: string = '';
  Siglaconselho: string = '';
  CurriculoUsuario: any = ""
  CelularUsuario: any = ""
  IdHospital: number = 0;
  NomePaciente: string = '';
  Administrador: number = 0;
  Internacao: number = 0;
  FotoPaciente: string = "";
  Dias: number = 0;
  Idade: number = 0;
  Peso: number = 0;
  Datainternacao: string = "";
  DescricaoHospital: string = "";
  DescricaoSetor: string = "";
  Leito: string = "";
  Paciente: number = 0;
  aComponentesEscore: any = [];
  Sexo: number = 0;
  Altura: number = 0;
  Bsa: number = 0;
  PesoIdealMasculino: string = '';
  PesoIdealFeminino: string = '';
  PacienteExiste: number = 0;
  Pontos: number = 0;
  SOFA: number = 0;
  FASTHUG: number = 0;
  Gestante: number = 0;
  Mortalidade: number=0;
  Imc:number=0;
  Mostrar:number=0;
  constructor(private utilService: UtilService,
    private usuarioService: UsuarioService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private popoverController: PopoverController,
    private navController: NavController

  ) {

    this.dadosListMed = this.utilService.obterDadosDoSistema();
    this.utilService.getEvent().subscribe(data => {
      if (data.key == "Mostrar") {
        this.Mostrar = data.value;
      }

    });
  }

  ngOnInit() {

    


    let aUsuario = this.dadosListMed.Usuario
    this.utilService.getEvent().subscribe(data => {
      if (data.key == "Usuario") {
        aUsuario = data.value;
      }

    });
    this.checaDadosUsuario(aUsuario);

     // Internacao
     let aInternacao = this.dadosListMed.Internacao
    
     if (aInternacao.length == 0  || aInternacao == '' || aInternacao == null) {
       return;
     }
 
    
     if (JSON.stringify(aInternacao).substring(0,1) == "{") {
       aInternacao = aInternacao;
     }

    if (aInternacao == undefined || aInternacao == '' || aInternacao == null) {
      return;
    }
    if (aInternacao.NomePaciente != null && aInternacao.NomePaciente != "" && aInternacao.NomePaciente.length > 0) {
      this.checaDadosInternacao(aInternacao);
    }
  }

  ionViewDidEnter() {
  }

  async showPopover(event: any) {
    let popover = await this.popoverController.create({
      component: PopoverComponent,
      event: event,
      cssClass: 'popover',
      mode: "ios",
      componentProps:
      {
        Retorno: this.Retorno
      }
    });

    return await popover.present();
  }




  async checaDadosInternacao(aInternacao: any) {

    this.NomePaciente = aInternacao.NomePaciente;
    this.DescricaoHospital = aInternacao.DescricaoHospital;
    this.DescricaoSetor = aInternacao.DescricaoSetor;
    this.Leito = aInternacao.Leito;
    
    // this.utilService.Descriptografar(this.NomePaciente)
    //   .then((nP: string) => {
    //     this.NomePaciente = nP;
    //   })
    //   .catch((response) => {
    //     this.NomePaciente = '';
    //   });

    //   this.utilService.Descriptografar(this.DescricaoHospital)
    //   .then((nH: any) => {
    //     this.DescricaoHospital = nH;
    //   })
    //   .catch((response) => {
    //     this.DescricaoHospital = '';
    //   });

    //   this.utilService.Descriptografar(this.DescricaoSetor)
    //   .then((nS: any) => {
    //     this.DescricaoSetor = nS;
    //   })
    //   .catch((response) => {
    //     this.DescricaoSetor = '';
    //   });

    //   this.utilService.Descriptografar(this.Leito)
    //   .then((nL: any) => {
    //     this.Leito = nL;
    //   })
    //   .catch((response) => {
    //     this.Leito = '';
    //   });

    this.Internacao = aInternacao.Id;
    this.Paciente = aInternacao.Paciente;
    this.Sexo = aInternacao.Sexo;
    this.Altura = this.utilService.formateNumber(aInternacao.Altura,2);
    this.Bsa = this.utilService.formateNumber(aInternacao.Bsa,2);
    this.PesoIdealMasculino = this.utilService.formateNumber(aInternacao.PesoIdealMasculino,2);
    this.PesoIdealFeminino = this.utilService.formateNumber(aInternacao.PesoIdealFeminino,2);
    this.Pontos = this.utilService.formateNumber(aInternacao.Pontos,0);
    this.Mortalidade = this.utilService.formateNumber(aInternacao.Mortalidade,2);
    this.Imc = this.utilService.formateNumber(aInternacao.Imc,2);
    this.FotoPaciente = aInternacao.Imagem;
    this.Idade = aInternacao.Idade;
    this.Dias = aInternacao.Dias;
    this.Peso = this.utilService.formateNumber(aInternacao.Peso, 1);
    this.Datainternacao = aInternacao.Datainternacao;
   
    this.SOFA = this.utilService.formateNumber(aInternacao.SOFA,0);
    this.FASTHUG = this.utilService.formateNumber(aInternacao.FASTHUG,0);
    this.Gestante = aInternacao.Gestante;
    this.exibirPaciente.emit(this.Paciente);
    this.exibirInternacao.emit(this.Internacao);
    this.exibirSexo.emit(this.Sexo);
    this.exibirIdade.emit(this.Idade);


    //this.FotoPaciente= this.utilService.obterUrlDaImagemDaApi() +  this.FotoPaciente;
    this.exibirNomePaciente.emit(this.NomePaciente);
    this.exibirFotoPaciente.emit(this.FotoPaciente);
    this.PacienteExiste = 1;
    this.utilService.verificarArquivo(this.FotoPaciente, (achou) => {
      if (achou == false) {
        this.FotoPaciente = '/assets/icone32x32.svg';
      }
    });

  }


  async checaDadosUsuario(aUsuario: any) {
    if (aUsuario.length > 0) {
      this.getDadosUsuario(aUsuario);
    } else {
      this.selecionarUsuarioUnico();
    }



  }

  async selecionarUsuarioUnico() {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados do usuário...',
      mode: 'ios'
    });

    loading.present();
    this.IdUsuario = this.dadosListMed.Usuario[0].Id
    if (this.dadosListMed != null && this.dadosListMed.Usuario != null && this.dadosListMed.Usuario[0].Id > 0) {
      this.usuarioService.selecionarUsuarioUnico(this.IdUsuario).then(response => {
        if (response[0].Id > 0) {
          this.getDadosUsuarioSql(response);
          loading.dismiss();
        } else {
          this.utilService.showAlertCallback('Dados não localizados do usuário.');

        }

      }).catch(response => {
        this.utilService.showAlertCallback('Dados não localizados do usuario.');
        this.utilService.Movimentar('login');
        loading.dismiss();
      })

    } else {
      loading.dismiss();
    }





  } // fim do pega usuário único

  async getDadosUsuarioSql(response: any) {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados do usuário...',
      mode: 'ios'
    });
    let Usuario = {
      Id: response[0].Id,
      Nome: response[0].Nome,
      Email: response[0].Email,
      Senha: response[0].Senha,
      Ativo: response[0].Ativo,
      Logado: 1,
      Datanascimento: response[0].Datanascimento,
      Administrador: response[0].Administrador,
      Siglaconselho: response[0].Siglaconselho,
      Numeroconselho: response[0].Numeroconselho,
      Imagem: response[0].Imagem
    }

    this.Administrador = response[0].Administrador;

    this.dadosListMed.Usuario = Usuario;
    this.utilService.atualizarDadosDoSistema(this.dadosListMed);
    this.IdUsuario = response[0].Id;
    this.DataNascimento = response[0].Datanascimento;
    this.IdadeUsuario = response[0].Idade;

    this.utilService.Descriptografar(response[0].Nome)
      .then((response: any) => {
        this.NomeUsuario = response;
      })
      .catch((response) => {
        this.NomeUsuario = '';
      });

    this.utilService.Descriptografar(response[0].Email)
      .then((response: any) => {
        this.EmailUsuario = response;
      })
      .catch((response) => {
        this.EmailUsuario = '';
      });



    this.utilService.Descriptografar(response[0].Curriculo)
      .then((response: any) => {
        this.CurriculoUsuario = response;

      })
      .catch((response) => {
        this.CurriculoUsuario = '';
      });

    this.utilService.Descriptografar(response[0].Celular)
      .then((response: any) => {
        this.CelularUsuario = response;
      })
      .catch((response) => {
        this.CelularUsuario = '';
      });

    this.utilService.Descriptografar(response[0].Numeroconselho)
      .then((response: any) => {
        this.Numeroconselho = response;
      })
      .catch((response) => {
        this.Numeroconselho = '';
      });



    this.Siglaconselho = response[0].Siglaconselho;
    this.FotoAtualUsuario = this.utilService.obterUrlDaImagemDaApi() + response[0].Imagem;
    this.exibirIdUsuario.emit(this.IdUsuario);
    this.exibirFotoUsuario.emit(this.FotoAtualUsuario);
    this.utilService.verificarArquivo(this.FotoAtualUsuario, (achou) => {
      if (achou == false) {
        this.FotoAtualUsuario = '/assets/icone32x32.svg';
      }
    });


    loading.dismiss();


  }

  //

  async getDadosUsuario(aUsuario: any) {
    let loading = await this.loadingController.create({
      message: 'Selecionando dados do usuário...',
      mode: 'ios'
    });

    this.utilService.Descriptografar(aUsuario[0].Nome)
      .then((response: any) => {
        this.NomeUsuario = response;
      })
      .catch((response) => {
        this.NomeUsuario = '';
      });

    this.utilService.Descriptografar(aUsuario[0].Email)
      .then((response: any) => {
        this.EmailUsuario = response;
      })
      .catch((response) => {
        this.EmailUsuario = '';
      });



    this.utilService.Descriptografar(aUsuario[0].Curriculo)
      .then((response: any) => {
        this.CurriculoUsuario = response;

      })
      .catch((response) => {
        this.CurriculoUsuario = '';
      });

    this.utilService.Descriptografar(aUsuario[0].Celular)
      .then((response: any) => {
        this.CelularUsuario = response;
      })
      .catch((response) => {
        this.CelularUsuario = '';
      });

    this.utilService.Descriptografar(aUsuario[0].Numeroconselho)
      .then((response: any) => {
        this.Numeroconselho = response;
        this.mostrarDados(aUsuario);
      })
      .catch((response) => {
        this.Numeroconselho = '';
      });


    loading.dismiss();


  }

  async mostrarDados(aUsuario: any) {
    this.IdUsuario = aUsuario[0].Id;
    this.DataNascimento = aUsuario[0].Datanascimento;
    this.IdadeUsuario = aUsuario[0].Idade;
    this.exibirNomePaciente.emit(this.NomePaciente);
    this.Administrador = aUsuario[0].Administrador;
    this.Siglaconselho = aUsuario[0].Siglaconselho;
    this.FotoAtualUsuario = this.utilService.obterUrlDaImagemDaApi() + aUsuario[0].Imagem;
    this.exibirIdUsuario.emit(this.IdUsuario);
    this.exibirFotoUsuario.emit(this.FotoAtualUsuario);
    this.utilService.verificarArquivo(this.FotoAtualUsuario, (achou) => {
      if (achou == false) {
        this.FotoAtualUsuario = '/assets/icone32x32.svg';
      }
    });
  }


  async ShowFotousuarioComponent() {
    let Cor = "primary";
    let modal = await this.modalController.create({
      component: FotousuarioComponent,
      mode: 'ios',
      componentProps:
      {
        NomeUsuario: this.NomeUsuario.toLocaleUpperCase(),
        EmailUsuario: this.EmailUsuario,
        CelularUsuario: this.CelularUsuario,
        CurriculoUsuario: this.CurriculoUsuario,
        FotoAtualUsuario: this.FotoAtualUsuario,
        Menu: this.Menu,
        cssClass: 'my-custom-modal-css',
        mode: 'ios'
      }
    });
    return await modal.present();

  } // fim showModal



  async ShowMenuusuarioComponent() {
    let opcao: any = [];
    opcao.push(
      {
        text: 'Meus dados',
        icon: "/assets/icone32x32.svg",
        mode: 'ios',
        handler: () => {
          this.utilService.Movimentar('cadastro');
        }
      })
    opcao.push(
      {
        text: 'Cadastros',
        icon: "/assets/icone32x32.svg",
        handler: () => {
          this.utilService.Movimentar('zapcadastro2');
        }

      })
    if (this.Administrador == 1) {
      opcao.push(
        {
          text: 'Meus pacientes',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('pacienterelacao/' + this.IdUsuario);
          }

        })
      opcao.push(
        {
          text: 'Novo Paciente',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('paciente/0');
          }

        })


      opcao.push(
        {
          text: 'Novo Internamento',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('pacienterelacao/' + this.IdUsuario);
          }

        })
      opcao.push(
        {
          text: 'Novo Hospital',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('hospital/0');
          }
        })

      opcao.push(
        {
          text: 'Fluxograma',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('fluxograma');
          }

        })
      opcao.push(
        {
          text: 'Administrativo',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('tabela-lista');
          }

        })
      opcao.push(
        {
          text: 'Gravar Áudios',
          icon: "/assets/icone32x32.svg",
          handler: () => {
            this.utilService.Movimentar('audio/' + this.IdHospital);
          }

        })



    }




    const actionSheet = document.createElement('ion-action-sheet');
    actionSheet.header = 'Opções';
    actionSheet.cssClass = 'my-custom-class';
    actionSheet.buttons = opcao;
    actionSheet.mode = 'md';
    actionSheet.translucent = false;
    document.body.appendChild(actionSheet);
    return actionSheet.present();
  }

  async pegaDadosUltimoPaciente() {
    this.Internacao = this.dadosListMed.Internacao.Id;
    if (this.dadosListMed != undefined && this.dadosListMed.Internacao != undefined) {
      this.utilService.Descriptografar(this.dadosListMed.Internacao.NomePaciente)
        .then((nH: string) => {
          this.NomePaciente = nH;
        })

    }

  }

  async ShowFotopacienteComponent() {
    let Cor = "primary";
    let modal = await this.modalController.create({
      component: FotopacienteComponent,
      mode: 'ios',
      componentProps:
      {
        Internacao: this.Internacao,
        cssClass: 'my-custom-modal-css',
      }
    });
    return await modal.present();

  } // fim showModal



}
