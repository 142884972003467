import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ChecklistService } from '../../services/checklist.service';

@Component({
  selector: 'app-delirium',
  templateUrl: './delirium.component.html',
  styleUrls: ['./delirium.component.scss'],
})
export class DeliriumComponent implements OnInit {
  dadosListMed: any;
  Logado: number = 0;
  deliriumForm: FormGroup;
  icone: string = 'thumbs-up'
  Internacao: number = 0;
  Paciente: number = 0;
  Usuario: number = 0;
  NomePaciente: string = '';
  FotoAtualPaciente: string = '';
  aRass: any = []
  Calculo: number=0;
  Sigla: string = '';
  Nome: string = '';
  Componente: string = '';
  Valornormal: string = '';
  Descricaounidade: string = '';
  Descricaocalculo: string = '';
  Observacao: any;
  aObservacao: any = [];
  Valormaximo: string = '';
  Valorminimo: string = '';
  aDelirium: any = [];
  sInicioagudo: string = "NÃO";
  sInatencao: string = "NÃO";
  sPensamento: string = "NÃO";
  Botao: string = "";
  lancamentosCollection: any = [];
  Id: number = 0;
  Valor: number = 0;
  Datalancamento: string = "";
  Rassinicial: number
  Inicioagudo: any
  Inatencao: any
  Rassfinal: number
  Pensamento: number = 0
  Sistema: number = 1;
  Comentario: string = "";
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private checklistService: ChecklistService,
    private alertController: AlertController
  ) {
    this.aRass = this.navParams.get('aRass');
    this.aDelirium = this.navParams.get('aDelirium');
   
    this.deliriumForm = formBuilder.group({
      Id: ['', ''],
      Datalancamento: ['', ''],
      Rassinicial: ['', ''],
      Inicioagudo: ['', ''],
      Inatencao: ['', ''],
      Rassfinal: ['', ''],
      Pensamento: ['', ''],
      Observacao: ['', ''],
      Comentario: ['', ''],

    })
    this.dadosListMed = this.utilService.obterDadosDoSistema();

    //https://www.latamdelirium.org/post/cam-e-cam-icu-cam-y-cam-icu
  }

  ngOnInit() {
    this.Calculo = 27;
    this.selecionarEscoreRelacao() ;//RASS

  }

  ionViewDidEnter() {
    this.selecionarDadosCalculoUnico
    this.testaAcessoSistema();
  }

  async informaErro(pagina:string) {
    const alert = await this.alertController.create({
      header: pagina,
      subHeader: "INFORMAR ERRO",
      message: '<strong>Deseja informar erro dessa página?</strong>',
      cssClass: 'danger',
      mode: 'ios',
      backdropDismiss: true,
      inputs: [
      
        {
         
          name: 'Descricao',
          type: 'text',
          placeholder: 'Descrição do erro',
          value: ''
        },
       
       
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (Dados:any) => {
            this.adicionarErro(Dados.Descricao,pagina);
          }
        }
      ]
    });
  
    await alert.present();
   }
  
   async adicionarErro(descricao:string, pagina:string) {
  
  
    let form={
      Pagina: pagina,
      Descricao: descricao,
      Usuario: this.Usuario
    }
  
  
    this.checklistService.adicionarManutencao(form).then((response:any)=>{
      this.utilService.showAlertCallback("Registro adicionado com sucesso. Oportunamente vamos corrigir o erro relatado")
    }).catch(()=>{
      this.utilService.showAlertCallback("Não foi possível adicionar o erro!")
  
    })

   }

  async selecionarDadosCalculoUnico() {
    let loading = await this.loadingController.create(
      {
        message: "Selecionando od dados...",
        mode: 'ios'
      }
    );
    loading.present();
    this.checklistService.selecionarCalculoUnico(this.Calculo)
      .then((response: any) => {
        this.Sigla = response[0].Sigla;
        this.Nome = response[0].Nome
        this.Componente = response[0].Componente
        this.Valornormal = response[0].Valornormal;
        this.Descricaounidade = response[0].Descricaounidade
        this.Descricaocalculo = response[0].Descricaocalculo
        this.Observacao = response[0].Observacao
        this.Valorminimo = response[0].Valorminimo
        this.Valormaximo = response[0].Valormaximo


      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona as calculadoreas')
      }).finally(() => {
        loading.dismiss();
      });

  }
  async testaAcessoSistema() {
    if (this.dadosListMed != null) {
      this.Logado = this.dadosListMed.Usuario[0].Logado;
      this.Usuario = this.dadosListMed.Usuario[0].Id;
      this.Paciente = this.dadosListMed.Internacao.Paciente;
      this.Internacao = this.dadosListMed.Internacao.Id;
      if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
        this.selecionarCalculolancamento()
      }

      this.limpaDados();

    }
  }


  async selecionarCalculolancamento() {
    this.checklistService.selecionarCalculolancamento(this.Internacao, this.Paciente, this.Calculo).then((response) => {
      this.lancamentosCollection = response;

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar os lançamento", null)
    });
  }

  async limpaDados() {
    this.Id = -1;
    this.Valor = 0;


    let dtHoje = this.utilService.dataHoje();
    let Datalancamento = this.utilService.checaData(dtHoje);
    this.deliriumForm.controls['Rassinicial'].setValue('');
    this.deliriumForm.controls['Inicioagudo'].setValue(false);
    this.deliriumForm.controls['Inatencao'].setValue(false);
    this.deliriumForm.controls['Rassfinal'].setValue('');
    this.deliriumForm.controls['Pensamento'].setValue(false);

    this.Botao = "CALCULAR"
    this.utilService.showToastCallBack("Tudo pronto para incluir um novo registro", 2000, () => {
    });
    this.deliriumForm.controls['Id'].setValue(this.Id);
    if (this.Logado > 0 && this.Paciente > 0 && this.Internacao > 0) {
      this.Botao = "CALCULAR"
      this.selecionarCalculolancamento();
    } else {
      this.Botao = "CALCULAR"
    }
    this.Datalancamento = Datalancamento;
    this.deliriumForm.controls['Datalancamento'].setValue(Datalancamento);


  }
  async closeModal() {
    this.modalController.dismiss();
  }

  async selecionaRassInicial(Posicao: any) {
    let Rass: number;
    for (let i = 0; this.aRass.length; i++) {
      if (i == Posicao) {
        Rass = this.aRass[i].Valor;
        break;
      }
    }
    Rass = this.utilService.formateNumber(Rass, 0);

    this.Rassinicial = Rass
    if (Rass < -3) {
      this.utilService.showAlertCallback("Paciente com sedação importante. O CAM-ICU não poderá ser realizado.")
      return;
    }
  }

  async selecionaRassFinal(Posicao: number) {
    let Rass: number;
    for (let i = 0; this.aRass.length; i++) {
      if (i == Posicao) {
        Rass = this.aRass[i].Valor;
        break;
      }
    }
    Rass = this.utilService.formateNumber(Rass, 0);
    this.Rassfinal = Rass
    if (Rass != 0 && this.Valor == 2) {
      this.Valor++;
      this.Comentario = "Paciente com CAM-ICU Positivo. PONTOS: " + this.Valor
      return;
    }
  }

  async calcularDelirium() {
    this.Valor = 0;
    //console.log("this.RassInicial:"+ this.Rassinicial)
    if (this.Rassinicial == null || this.Rassinicial == undefined) {
      this.utilService.showAlertCallback("Escolha o RASS INICIAL para o inicio do teste", null);
      return;
    }


    if (this.Rassinicial < -3) {
      this.utilService.showAlertCallback("RASS INICIAL para o inicio do teste tem que ser maior que -3", null);
      return;
    }


    let Inicioagudo = this.deliriumForm.value.Inicioagudo;

    if (Inicioagudo == false) {
      this.utilService.showAlertCallback("Para continuar o teste o INICIO deve ser agudo ou curso flutuante", null);
      return;
    }

    if (Inicioagudo == true) {
      this.Inicioagudo = 1
    }


    this.Valor++;

    let Inatencao = this.deliriumForm.value.Inatencao;

    if (Inatencao == false) {
      this.utilService.showAlertCallback("Para continuar o teste no ítem INATENÇAÕ deve existir 3 erros ou mais", null);
      return;
    }

    if (Inatencao == true) {
      this.Inatencao = 1
    }

    this.Valor++;

    if (this.Rassfinal == null || this.Rassfinal == undefined) {
      this.utilService.showAlertCallback("Informe o RASS FINAL para continuar", null);
      return;
    }

    if (this.Rassfinal != 0) {
      if (this.Rassfinal < 0) {
        this.Comentario = this.Comentario + " POSITIVO para Delirium HIPOATIVO";
      }
      if (this.Rassfinal > 0) {
        this.Comentario = this.Comentario + " POSITIVO para Delirium HIPERATIVO";
      }
      this.Valor++;
    } else { // em caso de RASS ser zero
      this.utilService.showAlertCallback("Faça o passo (4) PENSAMENTO DESORGANIZADO", null);

    }


    let Pensamento = this.deliriumForm.value.Pensamento;

    if (Pensamento == true) {
      this.Comentario = "POSITIVO devido o PENSAMENTO DESORGANIZADO com 2 erros ou mais. PONTOS: " + this.Valor

    }
    
    if (Pensamento == true) {
      this.Pensamento = 1
    }
  ;

    this.Valor++;


    // 22	hiperativo	10	0	-1.0000
    // 23	hipoativo	10	0	-1.0000
    // 24	misto	10	0	-1.0000
    // 26	com CAM-ICU negativo	10	1	1.0000

    let IdEscoreRelacao: number = 26; // negativo

    if (this.Valor > 2 && this.Rassfinal < 0) {
      IdEscoreRelacao = 23;
    }

    if (this.Valor > 2 && this.Rassfinal > 0) {
      IdEscoreRelacao = 22;
    }

    if (this.Valor > 2 && this.Rassfinal == 0) {
      IdEscoreRelacao = 24;
    }
    this.utilService.publishEvent("Delirium", IdEscoreRelacao);
    this.utilService.publishEvent("ObsDelirium", this.Comentario);

    if (this.Paciente > 0 && this.Logado > 0 && this.Valor != undefined) {
      this.presentAlertConfirm()
    }
  }

  async presentAlertConfirm() {
    let message: string = 'Deseja ADICIONAR esses dados para ' + this.Nome
    let Id: number = this.deliriumForm.value.Id;
    if (Id > 0) {
      message = 'Deseja ATUALIZAR esses dados para ' + this.Nome
    }
    const alert = await this.alertController.create({
      header: 'ATENÇÃO',
      message: message,
      cssClass: 'my-confirm-class',
      mode: 'ios',
      keyboardClose: true,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Confirmo',
          handler: () => {
            this.cadastrarCalculo()

          }
        }
      ]
    });
    await alert.present();
  }

  cadastrarCalculo() {

    if (this.Logado == 0 || this.Paciente == 0) {
      this.Valor = 0
      return;
    }

    let Relacao: any = [];
    Relacao = Relacao + this.Rassinicial + '*';
    Relacao = Relacao + this.Inicioagudo + '*';
    Relacao = Relacao + this.Inatencao + '*';
    Relacao = Relacao + this.Rassfinal + '*';
    Relacao = Relacao + this.Pensamento;

    let Datalancamento: any = this.deliriumForm.value.Datalancamento;
    Datalancamento = this.utilService.checaData(Datalancamento);

    let form = {
      Id: this.deliriumForm.value.Id,
      Internacao: this.Internacao,
      Paciente: this.Paciente,
      Datalancamento: Datalancamento,
      Calculo: this.Calculo,
      Valor: this.Valor,
      Usuario: this.Usuario,
      Relacao: Relacao,
      Sistema: this.Sistema,
      Comentario: this.deliriumForm.value.Comentario

    }


    if (this.deliriumForm.value.Id == -1 || this.deliriumForm.value.Id == undefined ||
      this.deliriumForm.value.Id == null) {
      this.checklistService.adicionarCalculolancamento(form).then((response) => {
        this.utilService.showAlertCallback(this.Nome + " ADICIONADO com sucesso", null);
        this.selecionarCalculolancamento();
        setTimeout(() => {
          this.Id = response[0].Id;
          this.deliriumForm.controls['Id'].setValue(this.Id);
        }, 1000);


      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ADICIONAR o " + this.Nome, null);

      });
    } else {
      this.checklistService.atualizarCalculolancamento(form).then((response: any) => {
        this.utilService.showAlertCallback(this.Nome + " ATUALIZADO com sucesso", null);
        this.Id = response[0].Id;
      }).catch((response) => {
        this.utilService.showAlertCallback("Não foi possível ATUALIZAR o " + this.Nome, null);

      });
    }

  }

  async selecionarCalculolancamentoUnico(Id: number) {
    if (Id == -1) {
      this.limpaDados();
      return
    }
    this.checklistService.selecionarCalculolancamentoUnico(Id).then((response: any) => {
      this.mostrarDados(response);

    }).catch((response) => {
      this.utilService.showAlertCallback("Não foi possível acessar o ítem do lançamento", null)
    });
  }

  async mostrarDados(response: any) {
    if (response.length == 0) {
      return;
    }
    this.Botao = "ALTERAR";
    let Datalancamento = this.utilService.checaData(response[0].Datalancamento);

    let Relacao: any = response[0].Relacao;
    Relacao = Relacao.split('*')



    this.Rassinicial = parseInt(Relacao[0]);
    this.Inicioagudo = parseInt(Relacao[1]);
    this.Inatencao = parseInt(Relacao[2]);
    this.Rassfinal = parseInt(Relacao[3]);
    this.Pensamento = parseInt(Relacao[4]);

    this.deliriumForm.controls['Datalancamento'].setValue(Datalancamento);
    this.deliriumForm.controls['Rassinicial'].setValue(this.Rassinicial);
    this.deliriumForm.controls['Inicioagudo'].setValue(this.Inicioagudo);
    this.deliriumForm.controls['Inatencao'].setValue(this.Inatencao);
    this.deliriumForm.controls['Rassfinal'].setValue(this.Rassfinal);
    this.deliriumForm.controls['Pensamento'].setValue(this.Pensamento);

    this.Comentario = response[0].Comentario;
    this.deliriumForm.controls['Comentario'].setValue(this.Comentario);

  }


  async selecionaInicioAgudo() {
    if (this.deliriumForm.value.Inicioagudo == true) {
      this.sInicioagudo = "SIM";
    } else {
      this.sInicioagudo = "NÃO";
    }


  }



  async selecionaInatencao() {

    if (this.deliriumForm.value.Inatencao == true) {
      this.sInatencao = "SIM, >=3 ERROS";
    } else {
      this.sInatencao = "NÃO";
    }

  }

  async selecionaPensamento() {
    if (this.deliriumForm.value.Pensamento == true) {
      this.sPensamento = "SIM, >=2 ERROS";
    } else {
      this.sPensamento = "NÃO";
    }
  }

  selecionarEscoreRelacao() {
    this.checklistService.selecionarEscoreRelacao(9) //RASS
      .then((response: any) => {
        this.aRass = response;
      })
      .catch((response) => {
        this.utilService.showAlertCallback('Não foi possível seleciona a lista do escore de RASS');
      });
  }


}
